import React from "react";
import Grid from "@mui/material/Grid";

function Footer(props) {
    return (
        <div>
            <Grid container className="ftrBx">
              <Grid item xs>
               <span>© GlaxoSmithKline</span>
              </Grid>
              <Grid item>
               <span>Reimagine Audit V1.1</span>
              </Grid>
            </Grid>
            
        </div>
    );
}

export default Footer;