import React, { Fragment, useContext } from "react";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./breadcrums.css";

import { BreadcrumbsContext } from "../context/breadcrumsContext";

import HomeIcon from "../../../assets/images/u1060.svg";
// import AdminIcon from '../../assets/images/u1048.svg';
// import ReportsIcon from '../../assets/images/u1052.svg';
// import MenuDivider from '../../assets/images/u1061.svg';
import ConfirmDialog from "../../Common/confirmDailog";
import { useHistory } from "react-router-dom";

const Breadcrumbs1 = () => {
  let history = useHistory();
  const { breadcrumbs, setBreadcrumbs } = useContext(BreadcrumbsContext);
  const [nevigareToDashboard, setNevigareToDashboard] = React.useState(false);
  const setBreadCrumbsOnClick = () => {
    setBreadcrumbs((prevBreadcrumbs) => {
      prevBreadcrumbs.parentMenu = "Dashboard";
      prevBreadcrumbs.childMenu = "";
      return { ...prevBreadcrumbs };
    });
  };

  const handleClose = (isRedirect = false, parentMenu) => {
    if (parentMenu === "Email Template") {
      if (isRedirect) {
        setNevigareToDashboard(false);
        setBreadCrumbsOnClick();
        history.push("/admin/dashboard");
      } else {
        setNevigareToDashboard(true);
      }
    } else {
      setBreadCrumbsOnClick();
      history.push("/admin/dashboard");
    }
  };

  return (
    <>
      <Breadcrumbs
        style={{ width: "100%" }}
        separator={
          <NavigateNextIcon
            style={{ color: "#000", fontSize: "unset" }}
            fontSize="small"
          />
        }
        aria-label="breadcrumb"
      >
        <Link underline="hover" color="inherit" to="/">
          <img src={HomeIcon} alt="Home" style={{ height: "12px" }} />
        </Link>
        <div
          onClick={() => {
            handleClose(false, breadcrumbs.parentMenu);
          }}
          style={{
            fontSize: "12px",
            color: "#169BD5",
            cursor: "pointer",
            textDecoration: "underline",
          }}
          underline="hover"
          color="inherit"
          // to="/admin/dashboard"
          // onClick={handleClick}
        >
          Admin
        </div>

        {breadcrumbs.parentMenu !== "" && (
          <Typography
            style={{
              fontWeight: "400",
              fontStyle: "normal",
              fontSize: "12px",
              textAlign: "left",
            }}
            color="text.primary"
          >
            {breadcrumbs.parentMenu}
          </Typography>
        )}

        {breadcrumbs.childMenu !== "" && (
          <Typography
            style={{
              fontWeight: "400",
              fontStyle: "normal",
              fontSize: "12px",
              textAlign: "left",
            }}
            color="text.primary"
          >
            {breadcrumbs.childMenu}
          </Typography>
        )}
      </Breadcrumbs>
      <ConfirmDialog
        title={""}
        open={nevigareToDashboard}
        setOpen={setNevigareToDashboard}
        onConfirm={() => handleClose(true, breadcrumbs.parentMenu)}
        handleNo={() => setNevigareToDashboard(false)}
      >
        Do you want to navigate Admin Dashboard?
      </ConfirmDialog>
    </>
  );
};

export default function Breadcrums(props) {
  return (
    <div>
      <Box sx={{ display: "flex", flexDirection: "row", p: 1 }}>
        <Breadcrumbs1 />
      </Box>
    </div>
  );
}
