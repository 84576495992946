import * as React from "react";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#554F41", //theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#554F41", //theme.palette.common.black,
    // maxWidth: "240px",
    padding: "15px",
  },
}));

const renderHtml = (html) => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

const Tooltips = (props) => {
  return (
    <div>
      <BootstrapTooltip
        placement={props.placement ? props.placement : "top"}
        title={props.html ? renderHtml(props.title) : props.title}
      >
        <IconButton
          size="small"
          sx={{
            height: "0px",
            width: "10px !important",
            "& .MuiSvgIcon-root": {
              color: `${props.iconColor ? props.iconColor : "#E07200 !important"}`,
            },
          }}
        >
          <InfoOutlinedIcon />
        </IconButton>
      </BootstrapTooltip>
    </div>
  );
};

export default Tooltips;
