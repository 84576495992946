import * as rimaConstants from '../../../src/rimaconstants';
import axios from "axios";

const containerName =rimaConstants.ASM_ROOT_CONTAINER;
const targetedFolderName = "RIMA_UI"

const filePathResolutionFolder = async (file, storageAccountName, sasToken, regulation) =>{
  console.log(file);
  return `https://${storageAccountName}.blob.core.windows.net/${containerName}/${targetedFolderName}/${regulation}/${file.name}?${sasToken}`
}


const uploadFileToRegulationBlob = async (file, regulation, storageAccountName, sasToken, user ) => {
  if (!file) return [];
  var req_obj = {};
  req_obj = new FormData();
  req_obj.append("upfile", file);
  await axios
            .post(
              `${rimaConstants.REACT_APP_API_URL}/api/v1/upload?folderName=${containerName}&subFolderName=${targetedFolderName}/${regulation}&fileName=${encodeURIComponent(file.name)}`,req_obj
            )
            .then((res) => {
              console.log(res)
            })
  
  console.log("uploaded file completed");

  //console.log(filePathResolutionFolder(file))
  return filePathResolutionFolder(file, storageAccountName, sasToken, regulation)
};


const filePath = async (file, storageAccountName, user_id, sasToken) => {
  return `https://${storageAccountName}.blob.core.windows.net/${containerName}/${"stage/"+user_id}/${file.name}?${sasToken}`;
};

const uploadFileToBlob = async (file, storageAccountName, sasToken, user) => {
  if (!file) return [];
  var req_obj = {};
  req_obj = new FormData();
  req_obj.append("upfile", file);
  await axios
            .post(
              `${rimaConstants.REACT_APP_API_URL}/api/v1/upload?folderName=${containerName}&subFolderName=${"stage/"+user.user_id}&fileName=${encodeURIComponent(file.name)}`,req_obj
            )
            .then((res) => {
              console.log(res)
            })
  
  console.log("uploaded file completed");

  return filePath(file, storageAccountName,  user.user_id, sasToken);
};


export {
  uploadFileToBlob,
  uploadFileToRegulationBlob 
} ;
