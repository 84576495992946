import React, { useState, useEffect, useContext } from "react";
import "./searchdocs.css";
import axios from "axios";
import { Link, Route } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

import SearchBar from "../../SearchBar/SearchBar";
import AdvanceSearch from "../../SearchBar/AdvanceSearch";
import DataTable from "../../DataTable/DataTable";
import Footer from "../../Footer/Footer";
import Mark from "mark.js";
import ReactTooltip from "react-tooltip";
import HelpIcon from "@mui/icons-material/Help";
import HelpOutlineTwoToneIcon from "@mui/icons-material/HelpOutlineTwoTone";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReactHtmlParser from "react-html-parser";
import noData from "../../../assets/images/u96.png";
import { TagDataContextProvider } from "../../Context/TagDataContext";
import { RegulationDataContextProvider } from "../../Context/RegulationDataContext";
import { v4 as uuidv4 } from "uuid";
import DocListViewer from "./doclistviewer";
import * as rimaConstants from "../../../rimaconstants";
import getHiddenDocList from "../../Common/hiddenDocs";
import { UserDataContext } from "../../Context/UserDataContext";
import Loader from "../../Loader/Loader";
import Tooltips from "../../Common/Tooltips";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../applicationInsights/AppInsights';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function SearchDocs() {
  const ref = React.useRef(null);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [finalData, setFinalData] = useState([]);

  const [final, setFinal] = useState("");
  const [top, setTop] = useState(1000);
  const [skip, setSkip] = useState(0);
  const [isShowing, setIsShowing] = useState(true);
  const [docSource, setDocSource] = useState([]);
  const [docType, setDocType] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [locationTag, setLocationTag] = useState([]);
  const [productTag, setProductTag] = useState([]);
  const [serviceTag, setServiceTag] = useState([]);
  const [agreementTag, setAgreementTag] = useState([]);
  const [qmsTag, setQmsTag] = useState([]);
  const [regulationTag, setRegulationTag] = useState([]);
  const [auditeeTag, setAuditeeTag] = useState([]);
  const [doseTag, setDoseTag] = useState([]);
  const [applicableTag, setApplicableTag] = useState([]);
  const tabnames = ["Search", "Assembly", "Translator", "Review"];
  const [
    adminUser,
    setAdminUser,
    user,
    setUser,
    userSASToken,
    setUserSASToken,
  ] = useContext(UserDataContext);

  const internalExternalReset = () => {
    setDocSource([]);
  };

  const reSet = () => {
    searchAsmresult(null);
    setDocSource([]);
    setLocationTag([]);
    setProductTag([]);
    setServiceTag([]);
    setAgreementTag([]);
    setQmsTag([]);
    setRegulationTag([]);
    setAuditeeTag([]);
    setDoseTag([]);
    setApplicableTag([]);
    setDocType(-1);
  };

  function toggle() {
    setIsShowing(!isShowing);
    // if (!isShowing) {
    //   searchAsmresult(null);
    // }
    setDocSource([]);
    setDocType(-1);
  }

  const searchInputHandler = (e) => {
    setFinal(e.target.value);

    // var markInstance = new Mark(document.querySelector("#search-node"));

    // markInstance.unmark({
    //   done:() =>{
    //     markInstance.mark(final);
    //   }
    // })
  };

  const searchInputHandlerDocSource = (e) => {
    setDocSource(e.target.value);
  };

  const searchInputHandlerDocType = (e) => {
    setDocType(e.target.value);
  };
  const searchInputHandlerLocationTag = (e) => {
    setLocationTag(e.target.value);
  };
  const searchInputHandlerProductTag = (e) => {
    setProductTag(e.target.value);
  };
  const searchInputHandlerServiceTag = (e) => {
    setServiceTag(e.target.value);
  };
  const searchInputHandlerAgreementTag = (e) => {
    setAgreementTag(e.target.value);
  };
  const searchInputHandlerQmsTag = (e) => {
    setQmsTag(e.target.value);
  };
  const searchInputHandlerRegulationTag = (e) => {
    setRegulationTag(e.target.value);
  };
  const searchInputHandlerAuditeeTag = (e) => {
    setAuditeeTag(e.target.value);
  };
  const searchInputHandlerDoseTag = (e) => {
    setDoseTag(e.target.value);
  };
  const searchInputHandlerApplicableTag = (e) => {
    setApplicableTag(e.target.value);
  };

  // const finalSearchString = () => setFinal()

  useEffect((e) => {
    searchAsmresult(e);
  }, []);

  const mkQry = (field, values) => {
    //auditee_type_tag/any (t: search.in(t,'External','|'))
    return `${field}/any (t: search.in(t, '${values.join("|")}','|'))`;
  };

  const mkQry2 = (field, values) => {
    //auditee_type_tag/any (t: search.in(t,'External','|'))
    return `search.in(${field}, '${values.join("|")}','|')`;
  };
  const refreshFn = (postRefresh = null) => {
    searchAsmresult(null, postRefresh);
  };
  const searchAsmresult = (e, postRefresh = null) => {
    if (e != null) e.preventDefault();

    if (final === undefined || final === null || final.trim() === "") return;

    window.showLoader();

    var qryParams = {
      speller: "lexicon",
      queryLanguage: "en-us",
      search: final,
      $count: "true",
      "api-version": "2021-04-30-Preview",
      highlight:
        "page_content, document_name, document_source_description, document_title",
      $top: top,
      $skip: skip,
      highlightPreTag: "<strong>",
      highlightPostTag: "</strong>",
    };
    var filterStr = "";
    var filters = [];
    // if (docSource.length != 0)
    // filters = `search.in(document_source, '${docSource.toString()}', '|')`
    //$filter=search.in(document_source , 'USFDA,ABC',',') and is_external eq 1
    if (docSource.length != 0)
      filters.push(mkQry2("document_source", docSource));
    if (locationTag.length != 0)
      filters.push(mkQry("market_location_tag", locationTag));
    if (productTag.length != 0)
      filters.push(mkQry("product_type_tag", productTag));
    if (serviceTag.length != 0) filters.push(mkQry("service_tag", serviceTag));
    if (agreementTag.length != 0)
      filters.push(mkQry("agreement_tag", agreementTag));
    if (qmsTag.length != 0) filters.push(mkQry("qms_policy_tag", qmsTag));
    if (regulationTag.length != 0)
      filters.push(mkQry("regulation_tag", regulationTag));
    if (auditeeTag.length != 0)
      filters.push(mkQry("auditee_type_tag", auditeeTag));
    if (doseTag.length != 0) filters.push(mkQry("dose_form_tag", doseTag));
    if (applicableTag.length != 0)
      filters.push(mkQry("applicable_tag", applicableTag));

    filterStr = "";
    for (var i = 0; i < filters.length; i++) {
      if (i == 0) filterStr = filters[i];
      else filterStr = filterStr + " and " + filters[i];
    }

    // if (filterStr != '' && docType != -1)
    //   filterStr = filterStr + ' and '

    if (docType != -1)
      filterStr =
        filterStr +
        (filterStr.trim() !== "" ? "and" : "") +
        ` is_external eq ${docType}`;

    if (filterStr != "") qryParams["$filter"] = filterStr;
    setLoading(true);
    axios
      .post(`${rimaConstants.REACT_APP_API_URL}/api/v1/docprops/search`, {
        queryString: qryParams,
      })
      .then(async (resJSONData) => {
        const extractData = extractValues(
          resJSONData?.data?.value,
          "page_content",
          "document_name",
          "document_storage_url",
          "page_number",
          "document_source",
          "document_source_description",
          "is_external",
          "document_title",
          "document_id"
        );

        //Get hidden documents
        var hiddendocs = [
          ...new Set(
            (await getHiddenDocList()).map((x) => {
              return x.document_storage_url.split(".").slice(0, -1).join(".");
            })
          ),
        ];
        //Remove duplicate from search result
        var searchResult = extractData.filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                t.filepath === value.filepath &&
                !hiddendocs.includes(
                  t.filepath.split(".").slice(0, -1).join("."),
                  0
                )
            )
        );
        setFinalData(searchResult);
        if (postRefresh !== null) postRefresh();
        setLoading(false);
        window.hideLoader();
      });
  };

  function extractValues(
    arr,
    prop,
    prop2,
    prop3,
    prop4,
    prop5,
    prop6,
    prop7,
    prop8,
    document_id
  ) {
    let extractedValue = arr.map((item) => {
      const File_Path = item[prop3];
      const sourceStore = userSASToken.storageUri;

      const sasKey = "?" + userSASToken.storageAccessToken;

      //TODO: Remove Quickfix on filepath for .docx files.
      //
      let filePath = "raw/" + File_Path; //.replace(".docx", ".pdf"); // /dbfs/mnt/testpdf1/ASM/eSOP - Vaccines/SOP000000000000000900000274503000.pdf u
      if (["docx", "doc"].includes( window.getExt(filePath).toLowerCase())) {
        filePath = window.changeExt(filePath, "pdf");
      }

      let str2 = sourceStore + encodeURI(filePath) + sasKey;
      let referrerid = uuidv4();

      let content = Array.isArray(item["@search.highlights"][prop])
        ? item["@search.highlights"][prop].join("")
        : item["@search.highlights"][prop];
      let obj = {
        //metadata_storage_name: <Link target="_blank" to={{pathname: "/pdf", state: {urlpath: str2}}}>{item[prop2]}</Link>,
        document_id: item[document_id],
        document_title: (
          <p>
            {item[prop8]}, <strong>Page No.</strong> {item[prop4]} &nbsp;{" "}
            {item[prop5]} &nbsp; {item[prop7] === 1 ? "External" : "Internal"}
          </p>
        ),
        title: item[prop8],
        type: item[prop7] === 1 ? "External" : "Internal",
        regulation: item[prop5],
        filepath: item[prop3],
        file_name: (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              let url = `${str2}#page=${item[prop4]}`;
              let a = document.createElement("a");
              a.target = "_blank";
              a.href = url;
              a.click();
            }}
          >
            {item[prop2]}
          </a>
        ),

        // page_content: ReactHtmlParser(content === undefined ? "" : content),

        // page_content: Array.isArray(item['@search.highlights'][prop]) ? item['@search.highlights'][prop].join('') : item['@search.highlights'][prop]
      };

      return obj;
    });
    return extractedValue;
  }

  return (
    <div className="container">
      {loading ? window.showLoader() : window.hideLoader()}
      <RegulationDataContextProvider>
        <TagDataContextProvider>
          <Box
            style={{
              marginLeft: "10px",
              borderTop: "1px solid #d7d7d7",
              borderRadius: "7px",
            }}
            sx={{ flexGrow: 1, display: "flex" }}
          >
            <Box
              style={{ paddingTop: "5px" }}
              sx={{ width: 1, bgcolor: "background.paper" }}
            >
              <div className="wrapper">
                <div className="searchBar1">
                  {/* <ReactTooltip /> */}
                  <form className="searchFoorm" onSubmit={searchAsmresult}>
                    <SearchBar
                      final={final}
                      searchInputHandler={searchInputHandler}
                    />
                    <IconButton
                      style={{
                        position: "absolute",
                        right: "213px",
                        // color: "rgb(22, 155, 213)",
                        // backgroundColor: "transparent"
                      }}
                      ref={(node) => {
                        if (node) {
                          node.style.setProperty(
                            "color",
                            "rgb(22, 155, 213)",
                            "important"
                          );
                          node.style.setProperty(
                            "background",
                            "transparent",
                            "important"
                          );
                        }
                      }}
                      onClick={searchAsmresult}
                      disabled={final === ""}
                    >
                      <SearchIcon />
                    </IconButton>
                    {/* <InfoOutlinedIcon
                      fontSize="tiny"
                      data-html={true}
                      data-tip='<h3>Sample queries:</h3> <hr/> <li><b>water</b> -> <i>Word search</i></li> <li><b>"Water for injection"</b> -> <i>Specific text</i></li> <li><b>water + injection</b> -> <i>AND operation</i></li> <li><b>water | injection</b> -> <i>OR operation</i></li> <li><b>"data management" + "vaccine"</b> -> <i>Complex search</i></li>'
                    ></InfoOutlinedIcon> */}
                    <IconButton>
                      <Tooltips
                        html={true}
                        iconColor="#2D2D69"
                        placement="top"
                        title='<h3>Sample queries:</h3> <hr/> <li><b>water</b> -> <i>Word search</i></li> <li><b>"Water for injection"</b> -> <i>Specific text</i></li> <li><b>water + injection</b> -> <i>AND operation</i></li> <li><b>water | injection</b> -> <i>OR operation</i></li> <li><b>"data management" + "vaccine"</b> -> <i>Complex search</i></li>'
                      />
                    </IconButton>
                    <Button
                      variant="contained"
                      onClick={toggle}
                      // className="gsk-btn primary pill"
                    >
                      Advanced Search
                    </Button>
                  </form>
                  <AdvanceSearch
                    finalData={finalData}
                    final={final}
                    docSource={docSource}
                    locationTag={locationTag}
                    productTag={productTag}
                    serviceTag={serviceTag}
                    agreementTag={agreementTag}
                    qmsTag={qmsTag}
                    regulationTag={regulationTag}
                    auditeeTag={auditeeTag}
                    doseTag={doseTag}
                    applicableTag={applicableTag}
                    docType={docType}
                    searchInputHandlerDocSource={searchInputHandlerDocSource}
                    searchInputHandlerLocationTag={
                      searchInputHandlerLocationTag
                    }
                    searchInputHandlerProductTag={searchInputHandlerProductTag}
                    searchInputHandlerServiceTag={searchInputHandlerServiceTag}
                    searchInputHandlerAgreementTag={
                      searchInputHandlerAgreementTag
                    }
                    searchInputHandlerQmsTag={searchInputHandlerQmsTag}
                    searchInputHandlerRegulationTag={
                      searchInputHandlerRegulationTag
                    }
                    searchInputHandlerAuditeeTag={searchInputHandlerAuditeeTag}
                    searchInputHandlerDoseTag={searchInputHandlerDoseTag}
                    searchInputHandlerApplicableTag={
                      searchInputHandlerApplicableTag
                    }
                    searchInputHandlerDocType={searchInputHandlerDocType}
                    searchAsmresult={searchAsmresult}
                    searchInputHandler={searchInputHandler}
                    isShowing={isShowing}
                    toggle={toggle}
                    reSet={reSet}
                    internalExternalReset={internalExternalReset}
                  />
                </div>
                {
                  // <div
                  //   className={`nodata ${
                  //     loading === true ? "disactive" : "active"
                  //   }`}
                  // >
                  //   {(finalData === undefined || finalData.length === 0 && final !== "") && (
                  //     <p className="noDatap">
                  //       <img className="nodataIcon" src={noData} /> No Data found
                  //     </p>
                  //   )}
                  // </div>
                }
                {/* <DataTable
                    final={final}
                    data={finalData}
                    setTop={setTop}
                    skip={setSkip}
                  /> */}

                <DocListViewer rows={finalData} refreshFn={refreshFn} showFooterNote={true} noteText="ASM is a non-regulated tool. User is responsible to ensure the most current, original source is referenced from results generated with this tool.">
                  {" "}
                </DocListViewer>
              </div>
            </Box>
          </Box>
        </TagDataContextProvider>
      </RegulationDataContextProvider>
    </div>
  );
}
// export default SearchDocs;
export default withAITracking(reactPlugin, SearchDocs);

