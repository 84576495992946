import React from "react";
import { TextField, Divider } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { styled, lighten, darken } from "@mui/system";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "0px",
  // height: "3px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

export default function SearchableDropdownGroup(props) {
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => (option.label ? option.label : ""),
  });

  const options = props.options.map((option) => {
    const firstLetter = option.usersIDs.includes(props.user_id) ? true : false;
    // console.log(
    //   "option.usersIDs ",
    //   option.usersIDs,
    //   props.user_id,
    //   firstLetter
    // );
    return {
      firstLetter: firstLetter ? "Current" : "Other",
      ...option,
    };
    // const firstLetter = option.label[0].toUpperCase();
    // return {
    //   firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
    //   ...option,
    // };
  });

  return (
    <Autocomplete
      getOptionLabel={(option) => (option.label ? option.label : "")}
      filterOptions={filterOptions}
      value={props.value}
      // options={options}
      // options={options.sort((a, b) => parseInt(b.firstLetter) - parseInt(a.firstLetter))}
      options={options.sort(
        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
      )}
      groupBy={(option) => option.firstLetter}
      // loading={props.loading}
      loading={typeof props.loading === "boolean" ? props.loading : false}
      componentsProps={{
        paper: {
          sx: {
            width: props.paperWidth ? props.paperWidth : "100%",
            whiteSpace: "nowrap",
            // fontSize: "0.8rem",
            // fontWeight: 400,
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          // variant="standard"
          // size="small"
          variant={props.variant ? props.variant : "standard"}
          size={props.size ? props.size : "small"}
          {...params}
          label={props.label}
          placeholder={props.placeholder}
          // loading={props.loading}
          loading={typeof props.loading === "boolean" ? props.loading : false}
          // loadingText={props.loadingText ? props.loadingText : "Loading..."}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {props.loading ? <CircularProgress sx={{ position: "absolute", right: "25px" }} size={15} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          sx={{ background: props.background ? "" : "#fff" }}
        />
      )}
      renderGroup={(params) => (
        <li key={params.key}>
          {params.group === "Other" && (
            <>
              <GroupHeader>
                {params.group === "Other" && <Divider />}
              </GroupHeader>
            </>
          )}
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
      onChange={(event, newValue) => {
        if (newValue) {
          props.setResetOptions(newValue);
          props.handleChange(newValue.id);
          props.setAuditStartDate(newValue.startDate);
          props.setAuditEndDate(newValue.endDate);
        }
        if (!newValue) {
          props.setResetOptions(null);
          props.handleChange("");
          props.setAuditStartDate("");
          props.setAuditEndDate("");
          //props.setInputValue("");
        }
      }}
      //inputValue={props.inputValue}
      // onInputChange={(event, newInputValue) => {
      //   //if (newInputValue) props.setInputValue(newInputValue);
      // }}
      sx={props.customStyle ? props.customStyle : { width: "200px" }}
    />
  );
}
