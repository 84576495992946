import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
// import Admintemplate from "../../admintemplate/admintemplate";

import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import MaterialUIPickers from "../../datefield/datefield";
import AutocompleteInput from "../../../Common/autocomplete";
import AutocompleteInput1 from "../../../Common/autocomplete1";
import AutocompleteInput2 from "../../../Common/autocomplete2";
import axios from "axios";

import { AuditContext } from "../../context/auditContext";
import { BreadcrumsContext } from "../../../ara/context/breadcrumsContext";
import * as rimaConstants from "../../../../rimaconstants";
import SearchableDropdown from "../../../Common/searchableDropdown";

import ConfirmDialog from "../../../Common/confirmDailog";
import CommonHeading from "../../../Common/CommonHeading";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../../applicationInsights/AppInsights';

const Addaudit = () => {
  const { breadcrums, setBreadcrums } = React.useContext(BreadcrumsContext);
  React.useEffect(() => {
    console.log(breadcrums);
    setBreadcrums((prevBreadcrums) => {
      prevBreadcrums.vaccine = false;
      prevBreadcrums.pharma = false;
      prevBreadcrums.parentMenu = "Manage Audits";
      prevBreadcrums.childMenu = "";
      prevBreadcrums.tableColumnData = [];
      prevBreadcrums.tableRowData = [];
      return { ...prevBreadcrums };
    });
  }, []);

  const [loading1, setLoading1] = React.useState(false);
  const [resetOptions1, setResetOptions1] = React.useState(null);
  const [inputValue1, setInputValue1] = React.useState("");
  const { auditValue, setAudit } = React.useContext(AuditContext);
  const [auditStatus, setAuditStatus] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  // const [open, setOpen] = React.useState(false);
  const [startPopup, setStartPopup] = React.useState(false);
  // const [stopPopup, setStopPopup] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener("keypress", (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
      }
    });
  }, []);

  function handleClick(event) {
    event.preventDefault();
    let err = [];
    if (!auditNumber.trim().length) err.push("Audit Number is required");
    if (!auditTitle.trim().length) err.push("Audit Title is required");
    if (!dataSource.length) err.push("Data Source is required");
    if (auditTypeValue === "") err.push("Audit Type is required");
    if (auditSite === "") err.push("Site is required");
    if (!leadAuditor.length) err.push("Lead Auditor is required");
    // if (!auditTeam.length) err.push("Audit Team is required");
    if (!auditee.length) err.push("Auditee Contact is required");

    if (!startDate.length) err.push("Start Date is required");
    if (!endDate.length) err.push("End Date is required");
    let stDate = startDate.split("-");
    var newstDate = new Date(stDate[0], stDate[1] - 1, stDate[2]);
    var selected_time = newstDate.getTime();
    let enDate = endDate.split("-");
    var newenDate = new Date(enDate[0], enDate[1] - 1, enDate[2]);
    var selected_en_time = newenDate.getTime();
    var curr_date = new Date();
    curr_date.setHours(0, 0, 0, 0);
    var curr_time = curr_date.getTime();
    if (
      startDate !== "" &&
      ((!auditValue.editForm && curr_time > selected_time) ||
        isNaN(selected_time))
    ) {
      err.push("Invalid Start Date");
    }
    if (
      endDate !== "" &&
      ((!auditValue.editForm && curr_time > selected_en_time) ||
        isNaN(selected_en_time))
    ) {
      err.push("Invalid End Date");
    }
    if (err.length) {
      //setSuccessMsg("* " + err.join("\n\r* "));
      //setAlertClass("warning");
      //setOpen(true);
      window.showMessage("* " + err.join("\n\r* "), "warning");
      return false;
    }
    setLoading(true);

    let payload = {
      audit_number: auditNumber,
      audit_type_id: auditTypeValue,
      audit_site_id: auditSite,
      audit_title: auditTitle,
      planned_fieldwork_start_date: startDate,
      planned_fieldwork_end_date: endDate,
      lead_auditor: leadAuditor,
      audit_team: auditTeam,
      auditee: auditee,
      data_source: dataSource.replace("X", "x"),
    };

    //console.log("leadAuditor");
    //console.log(leadAuditor);
    //console.log("on save ", payload);
    if (auditValue.editForm) {
      let editauditdata = JSON.parse(sessionStorage.getItem("editauditdata"));
      axios
        .put(
          `${rimaConstants.REACT_APP_API_URL}/api/v1/audit/update/${editauditdata.audit_id}`,
          payload
        )
        .then((response) => {
          //console.log(response.data);

          //setSuccessMsg("");
          //setAlertClass("");
          if (response?.data?.status === "success") {
            //setAlertClass("success");
            //setSuccessMsg("Audit updated successfully!");
            window.showMessage("Audit updated successfully!", "success");
            setLoading(false);
            setDataSource("");
            setAuditNumber("");
            setAuditTitle("");
            setAuditTypeValue(1);
            setAuditSite("");
            setAuditTeam("");
            setAuditee("");
            setLeadAuditor("");
            setStartDate("");
            setEndDate("");
            setAudit((prevAudit) => {
              prevAudit.editForm = false;
              prevAudit.addForm = false;
              prevAudit.addFormButton = true;
              prevAudit.loadAuditList = auditValue.loadAuditList + 1;
              return { ...prevAudit };
            });
          } else {
            //setAlertClass("warning");
            if (response?.data?.msg) {
              //setSuccessMsg(response.data.msg);
              window.showMessage(response.data.msg, "warning");
            } else {
              let err = [];
              response?.data?.data.map((v) => {
                for (const property in v) {
                  err.push(v[property]);
                }
                return true;
              });
              //console.log("err ", err);
              //setSuccessMsg("* " + err.join("\n\r* "));
              window.showMessage("* " + err.join("\n\r* "), "warning");
            }
          }

          //setOpen(true);
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      axios
        .post(`${rimaConstants.REACT_APP_API_URL}/api/v1/audit/add`, payload)
        .then((response) => {
          //console.log(response.data);

          //setSuccessMsg("");
          //setAlertClass("");
          if (response?.data?.status === "success") {
            //setAlertClass("success");
            //setSuccessMsg("Audit added successfully!");

            setStartPopup(true);
            setLoading(false);
            setDataSource("");
            // setAuditNumber("");
            setAuditTitle("");
            setAuditTypeValue(1);
            setAuditSite("");
            setAuditTeam("");
            setAuditee("");
            setLeadAuditor("");
            setStartDate("");
            setEndDate("");
            setAudit((prevAudit) => {
              prevAudit.editForm = false;
              prevAudit.addForm = false;
              prevAudit.addFormButton = true;
              // prevAudit.loadAuditList = auditValue.loadAuditList + 1;
              return { ...prevAudit };
            });
          } else {
            //setAlertClass("warning");
            if (response.data.msg) {
              //setSuccessMsg(response.data.msg);
              window.showMessage(response.data.msg, "warning");
            } else {
              let err = [];
              response.data["data"].map((v) => {
                for (const property in v) {
                  err.push(v[property]);
                }
                return true;
              });
              //console.log("err ", err);
              //setSuccessMsg("* " + err.join("\n\r* "));
              window.showMessage("* " + err.join("\n\r* "), "warning");
            }
          }

          //setOpen(true);
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  const cancelAddAuditForm = (event) => {
    setAudit((prevAudit) => {
      prevAudit.addForm = false;
      prevAudit.addFormButton = true;
      prevAudit.editForm = false;
      return { ...prevAudit };
    });
  };

  //const [siteValue, setSiteValue] = React.useState({});
  const [aduitType, setAuditType] = React.useState({});
  const [siteValueVx, setSiteValueVx] = React.useState({});
  const [siteValueRx, setSiteValueRx] = React.useState({});
  const [dataSource, setDataSource] = React.useState("VX");

  const handleChangeDataSoruce = (event) => {
    if (auditValue.editForm) {
      window.showMessage(
        "Audit data source changed. Please re-select site.",
        "info"
      );
    }
    setAuditSite("");
    setResetOptions1(null);

    setDataSource(event.target.value);
  };
  const [auditNumber, setAuditNumber] = React.useState("");
  const handleChangeAuditNumber = (event) => {
    if (event.target.value.match(new RegExp("/"))) {
      //setAlertClass("warning");
      //setSuccessMsg("Forward slash is not allowed");
      window.showMessage("Forward slash is not allowed", "warning");
      //setOpen(true);
    } else {
      setAuditNumber(event.target.value);
    }
  };
  const [auditTitle, setAuditTitle] = React.useState("");
  const handleChangeAuditTitle = (event) => {
    setAuditTitle(event.target.value);
  };
  const [auditTypeValue, setAuditTypeValue] = React.useState(1);
  const handleChangeAuditType = (event) => {
    setAuditTypeValue(event.target.value);
  };
  const [auditSite, setAuditSite] = React.useState("");
  const handleChangeAuditSite = (value) => {
    setAuditSite(value);
  };
  const [auditTeam, setAuditTeam] = React.useState("");
  const [auditee, setAuditee] = React.useState("");
  const [leadAuditor, setLeadAuditor] = React.useState("");

  const formatDate = (date) => {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const formatDateConditionally = (date) => {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    let d1 = new Date(),
      month1 = "" + (d1.getMonth() + 1),
      day1 = "" + d1.getDate(),
      year1 = d1.getFullYear();

    if (month1.length < 2) month1 = "0" + month1;
    if (day1.length < 2) day1 = "0" + day1;

    if (
      parseInt(month1) === parseInt(month) &&
      parseInt(year1) === parseInt(year) &&
      parseInt(day) <= parseInt(day1)
    ) {
      return [year, month, day].join("-");
    }

    return [year1, month1, day1].join("-");
  };

  const [startDate, setStartDate] = React.useState("");
  const handleChangeStartDate = (date) => {
    //console.log("date ==> ", formatDate(date));
    setStartDate(formatDate(date));
  };
  const [endDate, setEndDate] = React.useState("");
  const [minDate, setMinDate] = React.useState(new Date());
  const handleChangeEndDate = (date) => {
    //console.log("date ==>> ", formatDate(date));
    setEndDate(formatDate(date));
  };

  React.useEffect(() => {
    if (auditValue.editForm) {
      window.scrollTo(0, 0);
    }
  }, [auditValue]);

  React.useEffect(() => {
    setLoading1(true);
    axios
      .get(`${rimaConstants.REACT_APP_API_URL}/api/v1/audit/sites`)
      .then((response) => {
        // console.log('/api/v1/audit/sites ',response);
        // setSiteValue(response.data);
        let Vx = response?.data.filter(
          (Vx) => Vx.data_source.toUpperCase() === "VX"
        );
        ////console.log(Vx)
        // setSiteValueVx(Vx);
        let options = [];
        Vx.map((val) => {
          options.push({
            label: `${window.customEscape(
              val.country_name
            )} - ${window.customEscape(val.site_description)}`,
            id: val.audit_site_id,
          });
          return true;
        });
        setSiteValueVx(options);
        let Rx = response?.data.filter(
          (Vx) => Vx.data_source.toUpperCase() === "RX"
        );
        // setSiteValueRx(Rx);
        let options1 = [];
        Rx.map((val) => {
          options1.push({
            label: `${window.customEscape(
              val.country_name
            )} - ${window.customEscape(val.site_description)}`,
            id: val.audit_site_id,
          });
          return true;
        });
        setSiteValueRx(options1);
        //console.log("siteValueVx ", siteValueVx);
        setLoading1(false);
      });
    axios
      .get(`${rimaConstants.REACT_APP_API_URL}/api/v1/audit/audit_type`)
      .then((response) => {
        ////console.log(response.data);
        setAuditType(response.data);
      });
  }, []);

  React.useEffect(() => {
    if (auditValue.showErrorMsg) {
      if (auditValue.msgType === "success") {
        //setAlertClass("success");
        window.showMessage(auditValue.showErrorMsg, "success");
      } else {
        //setAlertClass("warning");
        window.showMessage(auditValue.showErrorMsg, "warning");
      }
      //setSuccessMsg(auditValue.showErrorMsg);
      //setOpen(true);
      setAudit((prevAudit) => {
        prevAudit.showErrorMsg = "";
        return { ...prevAudit };
      });
    }
  }, [auditValue]);

  const showAddAuditForm = (event) => {
    setDataSource("");
    setAuditNumber("");
    setAuditTitle("");
    setAuditTypeValue(1);
    setAuditSite("");
    setAuditTeam("");
    setAuditee("");
    setLeadAuditor("");
    setStartDate("");
    setEndDate("");
    setAudit((prevAudit) => {
      prevAudit.addForm = true;
      prevAudit.addFormButton = false;
      prevAudit.editForm = false;
      return { ...prevAudit };
    });
  };

  React.useEffect(() => {
    if (auditValue.editForm) {
      setDataSource("");
      setAuditNumber("");
      setAuditTitle("");
      setAuditTypeValue("");
      setAuditSite("");
      setAuditTeam("");
      setAuditee("");
      setLeadAuditor("");
      setStartDate("");
      setEndDate("");
      //console.log("useEffect");
      let editauditdata = JSON.parse(sessionStorage.getItem("editauditdata"));
      //console.log(editauditdata);
      setDataSource(editauditdata.data_source.toUpperCase());
      setAuditNumber(editauditdata.audit_number);
      setAuditTitle(editauditdata.audit_title);
      setAuditTypeValue(editauditdata.audit_type_id);
      setAuditSite(editauditdata.audit_site_id);
      setResetOptions1({
        label: `${editauditdata.country_name} - ${editauditdata.site_description}`,
        id: editauditdata.audit_site_id,
      });
      setAuditTeam(
        editauditdata.audit_team ? editauditdata.audit_team.slice(0, -1) : ""
      );
      setAuditee(
        editauditdata.auditee ? editauditdata.auditee.slice(0, -1) : ""
      );
      setLeadAuditor(
        editauditdata.lead_auditor
          ? editauditdata.lead_auditor.slice(0, -1)
          : ""
      );
      setStartDate(formatDate(editauditdata.planned_fieldwork_start_date));
      setEndDate(formatDate(editauditdata.planned_fieldwork_end_date));
      setAuditStatus(editauditdata.audit_status);
      setMinDate(
        formatDateConditionally(editauditdata.planned_fieldwork_start_date)
      );
    } else {
      setMinDate(new Date());
      setAuditStatus(0);
      setResetOptions1(null);
    }
  }, [auditValue]);

  const OnClickEnd = () => {
    setAudit((prevAudit) => {
      prevAudit.loadAuditList = auditValue.loadAuditList + 1;
      return { ...prevAudit };
    });
    setStartPopup(false);
    setAuditNumber("");
    window.showMessage(
      "Audit is created and is in Created state.\r\nStart the audit when you are ready from the Created list.",
      "success"
    );
  };
  const onClickStart = () => {
    let audit_number = auditNumber;
    axios
      .put(
        `${
          rimaConstants.REACT_APP_API_URL
        }/api/v1/audit/start/${window.base64_encode(audit_number)}`
      )
      .then((response) => {
        setAuditNumber("");
        if (response?.data?.status === "success") {
          setAudit((prevAudit) => {
            prevAudit.loadAuditList = auditValue.loadAuditList + 1;
            prevAudit.showErrorMsg = "Audit is created and initiated. ";
            prevAudit.msgType = "success";
            prevAudit.loadAuditList = auditValue.loadAuditList + 1;
            return { ...prevAudit };
          });
        } else {
          setAudit((prevAudit) => {
            prevAudit.showErrorMsg = response?.data?.msg;
            prevAudit.msgType = "warning";
            prevAudit.loadAuditList = auditValue.loadAuditList + 1;
            return { ...prevAudit };
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <ConfirmDialog
        title=""
        open={startPopup}
        setOpen={setStartPopup}
        onConfirm={() => onClickStart()}
        handleNo={() => OnClickEnd()}
      >
        Audit has been created, Do you want to initiate the risk analysis?
      </ConfirmDialog>

      {auditValue.addForm && (
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "100%" },
          }}
          noValidate
          autoComplete="off"
          style={{
            border: "1px solid #ddd",
            padding: "15px",
            borderRadius: "4px",
          }}
          //onSubmit={handleSubmit}
        >
          <div
            style={{
              display: "flex",
              borderBottom: "1px solid #ddd",
              padding: "10px",
            }}
          >
            <CommonHeading
              heading={
                auditValue.editForm
                  ? "Modify Audit Details"
                  : "Create New Audit"
              }
            />
            {/* <Typography variant="h5" gutterBottom component="div">
              {auditValue.editForm
                ? "Modify Audit Details"
                : "Create New Audit"}
            </Typography> */}
          </div>
          <div style={{ display: "flex" }}>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              sx={{ ml: 1, mt: 2, mb: 0, pb: 0, fontSize: 12 }}
            >
              Audit Details
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            <TextField
              id="audit_numb"
              label="Audit Number *"
              multiline
              // inputType="text"
              name="audit_number"
              maxRows={4}
              inputProps={{ maxLength: 120 }}
              onChange={handleChangeAuditNumber}
              value={auditNumber}
              // InputProps={{
              //   style: { width: "40ch" },
              // }}
              disabled={auditValue.editForm}
            />
            <TextField
              id="audit_title"
              label="Audit Title *"
              multiline
              // inputType="text"
              name="audit_title"
              maxRows={4}
              onChange={handleChangeAuditTitle}
              value={auditTitle}
              inputProps={{ maxLength: 200 }}
              // InputProps={{
              //   style: { width: "40ch" },
              //   maxLength: 80,
              // }}
            />

            <FormControl required sx={{ width: "100%", m: 1 }}>
              <InputLabel id="demo-simple-select-required-label">
                Audit Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="audit_type"
                value={auditTypeValue}
                label="Audit Type *"
                onChange={handleChangeAuditType}
                sx={{ height: "55px" }}
                name="audit_type"
                disabled={true}
              >
                <MenuItem key={0} value="">
                  <em>-- Select --</em>
                </MenuItem>
                {aduitType.map((audittype) => {
                  // //console.log("tks123 ", audittype.audit_type);
                  return (
                    <MenuItem
                      key={audittype.audit_type_id}
                      value={audittype.audit_type_id}
                    >
                      {audittype.audit_type}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {/* <FormHelperText>Required</FormHelperText> */}
          </div>
          <div style={{ display: "flex" }}>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              sx={{ ml: 1, mt: 1, mb: 0, pb: 0, fontSize: 12 }}
            >
              Site Details
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            <FormControl required sx={{ width: "32%", m: 1 }}>
              <InputLabel id="demo-simple-select-required-label">
                Data Source
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="data_source"
                value={dataSource}
                label="Data source *"
                onChange={handleChangeDataSoruce}
                sx={{ height: "55px" }}
                name="data_source"
                disabled={auditStatus === 2 ? true : false}
              >
                {/* <MenuItem value="">
          <em>-- Select --</em>
        </MenuItem> */}
                <MenuItem key={"VX"} value="VX" selected>
                  Vx
                </MenuItem>
                <MenuItem key={"RX"} value="RX">
                  Rx
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl
              required
              sx={{ width: "32%", mr: 1 }} /*sx={{ m: 1, width: "100%" }}*/
            >
              <SearchableDropdown
                value={resetOptions1}
                options={
                  dataSource === "VX"
                    ? siteValueVx
                    : dataSource === "RX"
                    ? siteValueRx
                    : []
                }
                setResetOptions={setResetOptions1}
                inputValue={inputValue1}
                setInputValue={setInputValue1}
                loading={loading1}
                handleChange={handleChangeAuditSite}
                customStyle={{ height: "55px" }}
                variant="outlined"
                label="Site *"
                background="NO"
              />
            </FormControl>
          </div>
          <div style={{ display: "flex" }}>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              sx={{ ml: 1, mt: 1, mb: 0, pb: 0, fontSize: 12 }}
            >
              Audit Team Details
            </Typography>
          </div>
          <div style={{ display: "flex" }} className="auditForm">
            <FormControl required sx={{ width: "100%", mr: 2 }}>
              <AutocompleteInput2
                lable="Lead Auditor *"
                value={leadAuditor}
                setParentValues={setLeadAuditor}
                role={5}
                multiple={false}
                placeholder="Lead Auditor"
              />
            </FormControl>
            <FormControl sx={{ width: "100%", mr: 2 }}>
              <AutocompleteInput1
                lable="Audit Team"
                value={auditTeam}
                setParentValues={setAuditTeam}
                multiple={true}
                role={5} /*showing emails same as lead auditor */
                placeholder="Audit Team"
              />
            </FormControl>
            <FormControl required sx={{ width: "100%", mr: 2 }}>
              <AutocompleteInput1
                lable="Auditee Contact *"
                value={auditee}
                setParentValues={setAuditee}
                multiple={true}
                role={6}
                placeholder="Auditee Contact"
              />
            </FormControl>
          </div>
          <div style={{ display: "flex" }}>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              sx={{ ml: 1, mt: 1, mb: 0, pb: 0, fontSize: 12 }}
            >
              Audit Dates
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            <FormControl required sx={{ width: "32%", mr: 2 }}>
              <MaterialUIPickers
                onChangeFn={handleChangeStartDate}
                dpval={startDate}
                minDate={minDate}
                //sx={{ width: "40ch" }}
                label="Start Date *"
              />
            </FormControl>
            <FormControl required sx={{ width: "32%", mr: 2 }}>
              <MaterialUIPickers
                onChangeFn={handleChangeEndDate}
                dpval={endDate}
                sx={{ width: "55px" }}
                label="End Date *"
              />
            </FormControl>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
            }}
          >
            {" "}
            {/* style={{ textAlign: "right", paddingBottom: "50px" }} */}
            <Box>
              <FormControlLabel
                sx={{
                  display: "none",
                }}
                control={
                  <Switch
                    checked={loading}
                    onChange={() => setLoading(!loading)}
                    name="loading"
                    color="primary"
                  />
                }
                label="Loading"
              />
              <Box sx={{ "& > button": { m: 1 } }}>
                <Button variant="contained" onClick={cancelAddAuditForm}>
                  Cancel
                </Button>
                <LoadingButton
                  // color="secondary"
                  onClick={handleClick}
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  type="submit"
                  sx={{ textTransform: "capitalize" }}
                >
                  {auditValue.editForm ? "Update" : "Save"}
                </LoadingButton>
              </Box>
            </Box>
          </div>
        </Box>
      )}
      <div style={{ float: "right" }}>
        {auditValue.addFormButton && (
          <Button variant="contained" sx={{ ml: 2 }} onClick={showAddAuditForm}>
            Create New Audit
          </Button>
        )}
      </div>
    </div>
  );
};

// const Addaudit = () => {
//   return <MultilineTextFields />;
// };

// export default Addaudit;
export default withAITracking(reactPlugin, Addaudit);
