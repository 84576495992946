import React, { useContext, useEffect, useState } from "react";
import { BreadcrumsContext } from "../context/breadcrumsContext";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import DataView from "../../DataTable/DataView";
import axios from "axios";
import * as rimaConstants from "../../../rimaconstants";
import CommonHeading from "../../Common/CommonHeading";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../applicationInsights/AppInsights';

const DeviationHandling = (props) => {
  const { breadcrums, setBreadcrums } = useContext(BreadcrumsContext);
  // const [deviation, setDeviation] = useState("Major");
  
  const [realTimeData, setRealTimeData] = useState({ columns: [], rows: [] });
  const [rejectedData, setRejectedData] = useState({ columns: [], rows: [] });
/*
  useEffect(() => {
    if (breadcrums.pharma) getPharmaDeviations();
  }, []);

  const getPharmaDeviations = () => {
    const { audit_id } = breadcrums;

    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/deviations/pharma_deviations/${audit_id}`
      )
      .then((response) => {
        if (response?.data?.data) {
          setRejectedData(response.data.data.realtime_notifications);
          setRealTimeData(response.data.data.rejection_batch_details);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
*/
  // const handleDeviationStatus = (e) => {
  //   e.preventDefault();
  //   if (deviation !== e.target.value) {
  //     setDeviation(e.target.value);
  //     setBreadcrums((prevBreadcrums) => {
  //       prevBreadcrums.DeviationClassification = e.target.value;
  //       return { ...prevBreadcrums };
  //     });
  //   }
  // };
// kept pharma2 intentionally to keep exiting code be remain for future process - Venkatesh Bommu
  return (
    <div>
      {breadcrums.pharma2 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // height: "80px",
          }}
        >
          <CommonHeading heading="Deviation Handling" />
        </Box>
      )}

      {breadcrums.pharma && !!breadcrums.tableColumnData.length && (
        <Box>
          <DataView
            columns={breadcrums.tableColumnData}
            rows={breadcrums.tableRowData}
            menuName={
              breadcrums.childMenu !== undefined ? breadcrums.childMenu : ""
            }
            checkboxSelection={true}
            auditCheckBox={
              breadcrums.childMenu === "Stakeholder Feedback"
                ? breadcrums.closedAudits
                  ? false
                  : breadcrums.auditTitle
                  ? false
                  : true
                : undefined
            }
            auditCheckCall={(val) => {
              // setAuditCheckBox(val);
            }}
            setBreadcrums={setBreadcrums}
            breadcrums={breadcrums}
            user={props.user}
            valueStartDate={props.valueStartDate}
            setValueStartDate={props.setValueStartDate}
            valueEndDate={props.valueEndDate}
            setValueEndDate={props.setValueEndDate}
            datesHandler={props.datesHandler}
            ytd={props.ytd}
            setYtd={props.setYtd}
            deviation={props.deviation}
            setDeviation={props.setDeviation}
            deviationClassificationList={props.deviationClassificationList}
          />
        </Box>
      )}
       {breadcrums.vaccine && !!breadcrums.tableColumnData.length && (
        <Box>
          <DataView
            columns={breadcrums.tableColumnData}
            rows={breadcrums.tableRowData}
            menuName={
              breadcrums.childMenu !== undefined ? breadcrums.childMenu : ""
            }
            checkboxSelection={true}
            auditCheckBox={
              breadcrums.childMenu === "Stakeholder Feedback"
                ? breadcrums.closedAudits
                  ? false
                  : breadcrums.auditTitle
                  ? false
                  : true
                : undefined
            }
            auditCheckCall={(val) => {
              // setAuditCheckBox(val);
            }}
            setBreadcrums={setBreadcrums}
            breadcrums={breadcrums}
            user={props.user}
            valueStartDate={props.valueStartDate}
            setValueStartDate={props.setValueStartDate}
            valueEndDate={props.valueEndDate}
            setValueEndDate={props.setValueEndDate}
            datesHandler={props.datesHandler}
            ytd={props.ytd}
            setYtd={props.setYtd}
            deviation={props.deviation}
            setDeviation={props.setDeviation}
            deviationClassificationList={props.deviationClassificationList}
          />
        </Box>
      )}
      <Box>
        {props.children
          ? React.cloneElement(props.children, { menuName: props.deviation })
          : ""}
      </Box>
      <Box sx={{ mt: "20px" }}>
        {breadcrums.pharma2 &&
          realTimeData.columns &&
          !!realTimeData.columns.length && (
            <Box>
              <DataView
                columns={realTimeData.columns}
                rows={realTimeData.rows}
                menuName={"Real Time Notifications"}
                checkboxSelection={false}
                setBreadcrums={setBreadcrums}
                breadcrums={breadcrums}
                deviation={props.deviation}
                setDeviation={props.setDeviation}
                deviationClassificationList={props.deviationClassificationList}
              />
            </Box>
          )}
      </Box>
      <Box sx={{ mt: "20px" }}>
        {breadcrums.pharma2 &&
          rejectedData.columns &&
          !!rejectedData.columns.length && (
            <Box>
              <DataView
                columns={rejectedData.columns}
                rows={rejectedData.rows}
                menuName={"Rejected Batch Details"}
                checkboxSelection={false}
                setBreadcrums={setBreadcrums}
                breadcrums={breadcrums}
                deviation={props.deviation}
                setDeviation={props.setDeviation}
                deviationClassificationList={props.deviationClassificationList}
              />
            </Box>
          )}
      </Box>
    </div>
  );
};

// export default DeviationHandling;
export default withAITracking(reactPlugin, DeviationHandling);
