import React, { useState } from "react";
import { Grid, Button, Skeleton } from "@mui/material";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  Typography,
  TextField,
  ButtonGroup,
  IconButton,
  styled,
  Switch,
  Tooltip,
} from "@mui/material";
import * as rimaConstants from "../../../rimaconstants";
import axios from "axios";
import DataView from "./../../DataTable/DataView";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
// import { toast } from "react-toastify";
import FormControlLabel from "@mui/material/FormControlLabel";
// import Moment from "react-moment";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import ConfirmDialog from "../../Common/confirmDailog";
import CircularProgress from "@mui/material/CircularProgress";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Tooltips from "../../Common/Tooltips";
import CommonHeading from "../../Common/CommonHeading";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../applicationInsights/AppInsights';

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}

const ARARetention = () => {
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [preAraDocuemntID, setPreAraDocuemntID] = useState(0);
  const [araDocuemntID, setAraDocuemntID] = useState(0);
  const [auditDocuments, setAuditDocuments] = useState([]);
  const [auditID, setAuditID] = useState(0);
  const [audit, setAudit] = useState([]);
  const [auditList, setAuditList] = useState([]);
  const [deletePopup, setDeletePopup] = React.useState(false);
  const [deleteSelectedPopup, setDeleteSelectedPopup] = useState(false);
  const [siteData, setSiteData] = useState({
    source: "",
    number: "",
    documents: [
      { docType: "", doc: null, name: "", docTypeId: "", uploaded: false },
    ],
    type: "0",
  });
  const [auditReferenceDocumentId, setAuditReferenceDocumentId] =
    React.useState([]);
  const [deleteMultiple, setDeleteMultiple] = React.useState(false);
  const [loaderImg, setLoaderImg] = React.useState(false);
  const [activeContent, setActiveContent] = useState(
    "globalRetentionPeriodSetting"
  );
  const [loadingCompleteAudit, setLoadingCompleteAudit] = React.useState(false);
  const [resetOptions, setResetOptions] = useState(null);
  const [oldValues, setOldValues] = useState("");
  const [saveButton, setSaveButton] = useState(true);

  const [height, setHeight] = React.useState(0);
  const ref = React.useRef(null);

  React.useEffect(() => {
    setHeight(window.innerHeight - 210);
  }, []);

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const resetAudit = () => {
    setAuditDocuments([]);
    setAudit({});
    setAuditList([]);
    setResetOptions(null);
  };

  const ContentHandleChange = (event, activeTab) => {
    if (!activeTab) return false;
    setActiveContent(activeTab);
    resetAudit();
    setPreAraDocuemntID(0);
    setAraDocuemntID(0);
    setSiteData({
      ...siteData,
      source: "",
      number: "",
      documents: [
        {
          docType: "",
          doc: null,
          name: "",
          docTypeId: "",
          uploaded: false,
        },
      ],
      type: "0",
    });
  };

  const headers = [
    {
      field: "id",
      headerName: "#",
      width: 50,
      //"flex":1,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        const onClickView = async (
          e,
          filename = params.row.document_storage_url
        ) => {
          e.stopPropagation();
          let format = filename.split("/").pop(0).split(".")[1];
          let type = window.getDocumentTypeByFileExtention(format);
          let url = `${window.BLOB_URL}/${rimaConstants.ARA_ROOT_CONTAINER}/${
            filename + window.BLOB_CONATINER_SAS_READ_TOKEN
          }`;
          window.downloadFile(url, type, filename);
        };
        const onClickDeletePop = (
          e,
          id = params.row.audit_reference_document_id
        ) => {
          setDeleteMultiple(false);
          setAuditReferenceDocumentId([id]);
          e.stopPropagation();
          setDeletePopup(true);
        };
        return (
          <ButtonGroup
            size="small"
            variant="contained"
            aria-label="outlined primary button group"
            //sx={{ width: "10px" }}
          >
            <IconButton size="small" onClick={onClickView} title="View">
              <PreviewIcon />
            </IconButton>
            <IconButton size="small" title="Delete" onClick={onClickDeletePop}>
              <DeleteIcon />
            </IconButton>
            {loaderImg === params.row.audit_reference_document_id && (
              <Button>
                <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  sx={{
                    color: "#fff",
                    animationDuration: "550ms",
                    position: "absolute",
                    left: 10,
                  }}
                  size={20}
                  thickness={4}
                />
              </Button>
            )}
          </ButtonGroup>
        );
      },
    },
    {
      field: "audit_document_type_description",
      headerName: "Document Type",
      width: 260,
    },
    {
      field: "document_storage_url",
      headerName: "File Name",
      width: 260,
      renderCell: (params) => {
        return <>{params.value.split("/").pop(0)}</>;
      },
    },
    {
      field: "is_latest_document_text",
      headerName: "Latest",
      width: 150,
      renderCell: (params) => {
        return (
          <AntSwitch
            checked={params.row.is_latest_document}
            inputProps={{ "aria-label": "ant design" }}
            onClick={(e) => {}}
          ></AntSwitch>
        );
      },
      //"flex":1,
    },
    {
      field: "status_text",
      headerName: "Status",
      width: 150,
      // renderCell: (params) => {
      //   let status = ["Not Processed", "Processed", "Failed", "Deleted"];
      //   return <>{status[params.value] ? status[params.value] : "Unknown"}</>;
      // },
      //"flex":1,
    },
    {
      field: "created_date_time",
      headerName: "Date & Time",
      width: 200,
      type1: "date",
      type: "string",
      getApplyQuickFilterFn: (value) =>
        window.getApplyQuickFilterFn(value, true),
      valueGetter: ({ value }) => window.getDateGridBinding(value, true),
      renderCell: (params) => {
        return (
          <>
            {params.value ? (
              <Tooltip
                title={window.getDate(params.row.created_date_time, true)}
              >
                <span>
                  {window.getDate(params.row.created_date_time, true)}
                </span>
              </Tooltip>
            ) : (
              ""
            )}
          </>
        );
      },
      //"flex":1,
    },
  ];

  const getAdminSetting = async (data_source) => {
    return new Promise(async (resolve, reject) => {
      try {
        let file = "documentRetentionPeriod.txt";
        let url = `${window.BLOB_URL}/${
          rimaConstants.ARA_ROOT_CONTAINER
        }/${data_source}/AdminSetting/${file}${
          window.BLOB_CONATINER_SAS_READ_TOKEN
        }&time=${Date.now()}`;
        const response = await fetch(url, {
          method: "GET",
        });

        if (response.status === 404) {
          reject("error 404");
        }

        const blob = await response.blob();
        var newBlob = new Blob([blob], {
          type: "text/plain",
        });
        var myReader = new FileReader();
        myReader.addEventListener("loadend", function (e) {
          let result = e.srcElement.result;
          //console.log(result);
          URL.revokeObjectURL(newBlob);
          resolve(result);
        });
        myReader.readAsText(newBlob);
      } catch (e) {
        reject(e.message);
      }
    });
  };

  const updateAdminSetting = async (settingObj, file, showMsg = false) => {
    return new Promise(async (resolve, reject) => {
      try {
        let payload = {
          content: window.base64_encode(settingObj),
        };
        // let file = "documentRetentionPeriod.txt";
        axios
          .put(
            `${rimaConstants.REACT_APP_API_URL}/api/v1/upload/updatedocument?folderName=${rimaConstants.ARA_ROOT_CONTAINER}&subFolderName=${siteData.source}/AdminSetting&fileName=${file}&txt=ARA Retention Period Settings Updated&oldValues=${oldValues}`,
            payload
          )
          .then(async (response) => {
            if (response?.data?.status === "success") {
              setSaveButton(true);
              if (showMsg)
                window.showMessage(
                  "Document Retention Period Settings Updated Successfully",
                  "success"
                );
              if (activeContent === "globalRetentionPeriodSetting") {
                window.showLoader();
                let file = await getAdminSetting(siteData.source).catch(
                  (error) => {
                    // return error;
                  }
                );
                if (file !== "error 404") {
                  setOldValues(file);
                }
                window.hideLoader();
              }
            } else {
              if (showMsg)
                window.showMessage(
                  "There is some issue while updateing the Document Retention Period Settings",
                  "warning"
                );
            }
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
            if (showMsg)
              window.showMessage(
                "There is some issue while updateing the Document Retention Period Settings",
                "error"
              );
            reject(error);
          });
      } catch (e) {
        reject(e.message);
      }
    });
  };

  const handleUpdate = async () => {
    let err = [];
    let settingObj = {
      years: araDocuemntID,
      months: preAraDocuemntID,
      email: "##ADMIN_DOCUMENT_RETENTION_PERIOD_EMAIL##",
      date: window.getDate(Date.now(), true),
    };
    if (
      siteData.source === "" ||
      siteData.source === "0" ||
      siteData.source === 0
    ) {
      err.push("Documents Retention For");
    }
    if (araDocuemntID === "0" || araDocuemntID === 0) {
      err.push("ARA Documents Retention Period");
    }
    if (preAraDocuemntID === "0" || preAraDocuemntID === 0) {
      err.push("Site Specific Documents Retention Period");
    }
    if (err.length) {
      window.showMessage("* " + err.join("\n\r* "), "warning");
      return false;
    }

    const tmpOldValues = JSON.parse(oldValues);
    const m = tmpOldValues[0].months;
    const y = tmpOldValues[0].years;

    if (
      parseInt(preAraDocuemntID) === parseInt(m) &&
      parseInt(araDocuemntID) === parseInt(y)
    ) {
      setSaveButton(true);
      window.showMessage("Nothing to modify!", "warning");
      return false;
    }

    setLoading(true);

    let file = "documentRetentionPeriod.txt";
    await updateAdminSetting(JSON.stringify([settingObj]), file, true).catch(
      (error) => {
        return error;
      }
    );

    setLoading(false);
  };

  const getAuditNumbers = (value) => {
    setLoadingCompleteAudit(true);
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/audit/completed-audits/` +
          value
      )
      .then((response) => {
        //console.log(response.data);
        if (response?.data?.data) {
          let options = [];
          if (response.data.data.length) {
            setResetOptions(null);
            response.data.data.map((val) => {
              return options.push({
                label: window.customEscape(val.audit_number),
                id: val.audit_id + "@#@" + val.audit_number,
              });
            });
            setAuditList(options);
          }
          // setAuditList(response.data.data);
        }
        setLoadingCompleteAudit(false);
      });
  };

  const getAuditDetails = (value) => {
    //getAuditList(value);
    window.showLoader();
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/site_document_upload_page/audit/` +
          window.base64_encode(value)
      )
      .then((response) => {
        setAuditID(response?.data?.data[0].audit_id);
        setAudit(response?.data?.data[0]);
        window.hideLoader();
        //getAuditDocuments(response.data.data[0].audit_id, false);
      });
  };

  const handleChange = async (field, value) => {
    switch (field) {
      case "source":
        setSaveButton(true);
        setResetOptions(null);
        resetAudit();
        if (activeContent === "documentDeletion") {
          getAuditNumbers(value);
        }
        setSiteData({
          ...siteData,
          [field]: value,
          number: "",
          documents: [
            {
              docType: "",
              doc: null,
              name: "",
              docTypeId: "",
              uploaded: false,
            },
          ],
          type: "0",
        });
        if (activeContent === "globalRetentionPeriodSetting") {
          let file = await getAdminSetting(value).catch((error) => {
            return error;
          });
          if (file !== "error 404") {
            setOldValues(file);
            file = JSON.parse(file);
            setPreAraDocuemntID(!!file.length ? file[0].months : 0);
            setAraDocuemntID(!!file.length ? file[0].years : 0);
          }
        }
        break;
      case "number":
        let [audit_id, audit_number] = value.split("@#@");
        getAuditDetails(audit_number);
        setAuditID(audit_id);
        getAuditDocuments(audit_id);
        setResetOptions({
          label: window.customEscape(audit_number),
          id: audit_id + "@#@" + audit_number,
        });
        setSiteData({
          ...siteData,
          [field]: audit_number,
          documents: [
            {
              docType: "",
              doc: null,
              name: "",
              docTypeId: "",
              uploaded: false,
            },
          ],
        });
        break;
      case "preAraDocuemntID":
        setPreAraDocuemntID(value);
        setAuditDocuments([]);
        setSaveButton(false);
        break;
      case "araDocuemntID":
        setAraDocuemntID(value);
        setAuditDocuments([]);
        setSaveButton(false);
        break;
      case "reset":
        setResetOptions(null);
        setSiteData({
          ...siteData,
          number: "",
          documents: [
            {
              docType: "",
              doc: null,
              name: "",
              docTypeId: "",
              uploaded: false,
            },
          ],
          type: "0",
        });
        break;
      default:
        break;
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.label,
  });

  const comboBox = () => {
    return (
      <Autocomplete
        //disablePortal
        id="combo-box-demo"
        // disableClearable
        //clearOnBlur={false}
        filterOptions={filterOptions}
        value={resetOptions}
        options={auditList}
        loading={loadingCompleteAudit}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Audit Number"
            loading={loadingCompleteAudit}
            // loadingText="Loading..."
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loadingCompleteAudit ? <CircularProgress size={25} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        onChange={(event, newValue) => {
          if (newValue) handleChange("number", newValue.id);
          if (!newValue) handleChange("reset", "");
        }}
        sx={{ height: "50px", width: "230px !important" }}
      />
    );
  };

  const getAuditDocuments = (audit_id, showLoader = true) => {
    if (showLoader) window.showLoader();
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/site_document_upload_page/list/` +
          audit_id
      )
      .then((response) => {
        setAuditDocuments(response.data.data);
        window.hideLoader();
      });
  };

  const deleteDcudment = (collections) => {
    window.showLoader();
    try {
      axios
        .delete(
          `${
            rimaConstants.REACT_APP_API_URL
          }/api/v1/site_document_upload_page/admindocument/${window.base64_encode(
            collections
          )}`
        )
        .then((response) => {
          setLoaderImg(false);
          if (response?.data?.status === "success") {
            window.showMessage("Document(s) Deleted Successfully", "success");
            getAuditDocuments(auditID);
          } else {
            if (response?.data?.msg) {
              window.showMessage(response.data.msg, "warning");
            } else {
              window.showMessage(
                "There is some issue while deleting the Document(s)",
                "warning"
              );
            }            
          }
          window.hideLoader();
        })
        .catch(function (error) {
          console.log(error);
          window.showMessage(
            "There is some issue while deleting the Document",
            "error"
          );
          window.hideLoader();
        });
    } catch (e) {
      console.log(e.message);
      window.hideLoader();
    }
  };

  const handleDeleteAll = () => {
    if (selectionModel?.length) {
      setDeleteSelectedPopup(true);
    } else {
      setDeleteSelectedPopup(false);
    }
  };

  const onClickDeleteAll = () => {
    if (selectionModel?.length) {
      let ids = auditDocuments
        .filter((obj) => {
          return selectionModel.includes(obj.id) ? true : false;
        })
        .map((obj) => {
          return obj.audit_reference_document_id;
        });
      deleteDcudment(JSON.stringify(ids));
    } else {
      window.showMessage("Please choose the Document(s)", "warning");
    }
  };

  const onClickDelete = () => {
    if (deleteMultiple) {
      let ids = [];
      if (selectionModel)
        ids = selectionModel
          .toString()
          .split(",")
          .filter((el) => el !== "");
      if (ids && ids.length > 0) {
        ids = auditDocuments
          .filter((obj) => {
            return ids.includes(obj.id) ? true : false;
          })
          .map((obj) => {
            return obj.audit_reference_document_id;
          });
        deleteDcudment(JSON.stringify(ids));
        setSelectionModel([]);
      } else {
        window.showMessage("Please choose the Document(s)", "warning");
      }
    } else {
      setLoaderImg(auditReferenceDocumentId[0]);
      deleteDcudment(JSON.stringify(auditReferenceDocumentId));
    }
  };

  return (
    <div ref={ref} style={{ minHeight: height }}>
      <Grid container>
        <Grid item xs={12}>
          <CommonHeading heading="ARA Retention Period Settings" />
        </Grid>
      </Grid>
      <div className="css-1pqbxu3">
        <Box sx={{ m: 2 }}>
          <Grid container spacing={2}>
            <Box
              sx={{
                m: "15px",
                textAlign: "center",
                minWidth: "800px",
                margin: "0 auto",
                marginTop: "5px",
                marginBottom: "15px",
              }}
            >
              <ToggleButtonGroup
                fullWidth
                //   color="#15717d"
                value={activeContent}
                exclusive
                size="small"
                onChange={ContentHandleChange}
              >
                <ToggleButton
                  className={
                    activeContent === "globalRetentionPeriodSetting"
                      ? "gsk-btn primary"
                      : "gsk-btn"
                  }
                  value="globalRetentionPeriodSetting"
                  sx={{ minWidth: "400px", textTransform: "none !important" }}
                  // onClick="resultHandlerChange"
                >
                  Global Retention Period Settings &nbsp;&nbsp;&nbsp;
                  <Tooltips
                    iconColor="#2D2D69"
                    placement="top"
                    title="Respective documents will get auto-deleted for closed audits as per the selected retention period"
                  />
                </ToggleButton>
                <ToggleButton
                  className={
                    activeContent === "documentDeletion"
                      ? "gsk-btn primary"
                      : "gsk-btn"
                  }
                  value="documentDeletion"
                  sx={{ minWidth: "400px", textTransform: "none !important" }}
                >
                  Manual Deletion of Site Documents
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Grid>
        </Box>
        <Grid container /*sx={{ paddingRight: "5%" }}*/>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <Typography
                variant="body1"
                mt={3}
                mr={3}
                pl={18}
                sx={{ width: "40%" }}
                align="right"
              >
                Deletion of Site Documents For
              </Typography>
              <FormControl sx={{ m: 1, width: "60%" }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={siteData.source ? siteData.source : "0"}
                  //label="ARA Template For"
                  onChange={(e) => handleChange("source", e.target.value)}
                  sx={{ height: "55px", width: "35ch" }}
                >
                  <MenuItem key={"0"} value="0">
                    -- Select --
                  </MenuItem>
                  <MenuItem key={"Vx"} value="Vx">
                    Vx
                  </MenuItem>
                  <MenuItem key={"Vx"} value="Rx">
                    Rx
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        {activeContent === "globalRetentionPeriodSetting" && (
          <Grid container /*sx={{ paddingRight: "7%" }}*/>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Typography
                  variant="body1"
                  mt={3}
                  mr={3}
                  pl={18}
                  sx={{ width: "40%" }}
                  align="right"
                >
                  ARA Documents Retention Period
                </Typography>
                <FormControl sx={{ m: 1, width: "60%" }}>
                  {/* <InputLabel id="demo-simple-select-label" sx={{ ml: 5 }}>
                Documents Retention Period
              </InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={araDocuemntID}
                    // label="Select"
                    onChange={(e) =>
                      handleChange("araDocuemntID", e.target.value)
                    }
                    sx={{ height: "55px", width: "35ch" }}
                  >
                    <MenuItem key={0} value={0}>
                      -- Select --
                    </MenuItem>
                    {[...Array(10)].map((_, index) => (
                      <MenuItem key={index + 1} value={index + 1}>
                        {index === 0 ? "1 Year" : index + 1 + " Years"}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        )}
        {activeContent === "globalRetentionPeriodSetting" && (
          <Grid container /*sx={{ paddingRight: "12%" }}*/>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Typography
                  variant="body1"
                  mt={3}
                  mr={3}
                  pl={18}
                  sx={{ width: "40%" }}
                  align="right"
                >
                  Site Specific Documents Retention Period
                </Typography>
                <FormControl sx={{ m: 1, width: "60%" }}>
                  {/* <InputLabel id="demo-simple-select-label" sx={{ ml: 5 }}>
                Documents Retention Period
              </InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={preAraDocuemntID}
                    // label="Select"
                    onChange={(e) =>
                      handleChange("preAraDocuemntID", e.target.value)
                    }
                    sx={{ height: "55px", width: "35ch" }}
                  >
                    <MenuItem key={0} value={0}>
                      -- Select --
                    </MenuItem>
                    {[...Array(12)].map((_, index) => (
                      <MenuItem key={index + 1} value={index + 1}>
                        {index === 0 ? "1 Month" : index + 1 + " Months"}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        )}
        {activeContent === "documentDeletion" && (
          <Grid container /*sx={{ paddingLeft: "6%" }}*/>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Typography
                  variant="body1"
                  mt={3}
                  mr={3}
                  pl={18}
                  sx={{ width: "40%" }}
                  align="right"
                >
                  Closed Audits
                </Typography>
                <FormControl sx={{ m: 1, width: "60%" }}>
                  {comboBox()}
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        )}
        {activeContent === "globalRetentionPeriodSetting" && (
          <Grid
            container
            // direction="row"
            // justifyContent="flex-end"
            // alignItems="flex-end"
            // sx={{ mt: 2, pr: 11 }}
            // display="flex"
            // justifyContent="center"
          >
            <Grid item xs={9}>
              <Box display="flex" justifyContent="flex-end" sx={{ mr: 4 }}>
                <FormControlLabel
                  sx={{
                    display: "none",
                  }}
                  control={
                    <Switch
                      checked={loading}
                      onChange={() => setLoading(!loading)}
                      name="loading"
                      color="primary"
                    />
                  }
                  label="Loading"
                />
                <Box sx={{ "& > button": { mt: 2, mr: 2 } }}>
                  <LoadingButton
                    // color="secondary"
                    onClick={handleUpdate}
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    type="submit"
                    sx={{ textTransform: "capitalize" }}
                    disabled={saveButton}
                  >
                    Update
                  </LoadingButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </div>
      {siteData.number && (
        <Grid
          container
          sx={{
            pt: 3,
            pb: 3,
            backgroundColor: "#fff",
          }}
        >
          <Grid item xs={6} sx={{ pl: 7 }}>
            <Box
              sx={{
                height: 15,
                p: 2,
                display: "flex",
                gridAutoFlow: "row",
                gridTemplateColumns: "repeat(2, 1fr)",
                gridTemplateRows: "repeat(2,  30px)",
                gap: 1,
              }}
            >
              <Item
                sx={{
                  gridColumn: "1",
                  fontSize: "0.875rem",
                  fontWeight: "600",
                }}
              >
                Audit Title :
              </Item>
              {audit ? (
                <Item sx={{ fontSize: "0.875rem", fontWeight: "500" }}>
                  {audit.audit_title}
                </Item>
              ) : (
                <Skeleton
                  variant="rectangular"
                  sx={{ my: 0.2, mx: 0.5 }}
                  height={40}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ pl: 7 }}>
            <Box
              sx={{
                height: 15,
                p: 2,
                display: "flex",
                gridAutoFlow: "row",
                gridTemplateColumns: "repeat(2, 1fr)",
                gridTemplateRows: "repeat(2, 30px)",
                gap: 1,
              }}
            >
              <Item
                sx={{
                  gridColumn: "1",
                  fontSize: "0.875rem",
                  fontWeight: "600",
                }}
              >
                Site Name :
              </Item>
              <Item sx={{ fontSize: "0.875rem", fontWeight: "500" }}>
                {audit.site_description}
              </Item>
            </Box>
          </Grid>

          <Grid item xs={6} sx={{ pl: 7 }}>
            <Box
              sx={{
                height: 50,
                p: 2,
                display: "flex",
                gridAutoFlow: "row",
                gridTemplateColumns: "repeat(2, 1fr)",
                gridTemplateRows: "repeat(2, 30px)",
                gap: 1,
              }}
            >
              <Item
                sx={{
                  gridColumn: "1",
                  fontSize: "0.875rem",
                  fontWeight: "600",
                  ml: "0px",
                }}
              >
                Audit Start Date :
              </Item>
              <Item sx={{ fontSize: "0.875rem", fontWeight: "500" }}>
                {audit.planned_fieldwork_start_date !== undefined
                  ? window.getDate(audit.planned_fieldwork_start_date)
                  : ""}
              </Item>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ pl: 7 }}>
            <Box
              sx={{
                height: 50,
                p: 2,
                display: "flex",
                gridAutoFlow: "row",
                gridTemplateColumns: "repeat(2, 1fr)",
                gridTemplateRows: "repeat(2, 30px)",
                gap: 1,
              }}
            >
              <Item
                sx={{
                  gridColumn: "1",
                  fontSize: "0.875rem",
                  fontWeight: "600",
                }}
              >
                {" "}
                Audit End Date :
              </Item>
              <Item sx={{ fontSize: "0.875rem", fontWeight: "500" }}>
                {audit.planned_fieldwork_end_date !== undefined
                  ? window.getDate(audit.planned_fieldwork_end_date)
                  : ""}
              </Item>
            </Box>
          </Grid>
        </Grid>
      )}
      {siteData.number && (
        <div>
          <DataView
            columns={headers}
            rows={auditDocuments}
            menuName="Uploaded Documents"
            checkboxSelection={true}
            deleteAllButton={true}
            handleDeleteAll={handleDeleteAll}
            setSelectionModel={setSelectionModel}
            selectionModel={selectionModel}
            isRowSelectable={false}
            isRowSelectableSection={"Document_Management"}
            auditID={auditID}
          />
        </div>
      )}
      <ConfirmDialog
        title={""}
        open={deletePopup}
        setOpen={setDeletePopup}
        onConfirm={() => onClickDelete()}
        handleNo={() => setDeletePopup(false)}
      >
        Do you want to delete the document(s)?
      </ConfirmDialog>
      <ConfirmDialog
        title={""}
        open={deleteSelectedPopup}
        setOpen={setDeleteSelectedPopup}
        onConfirm={() => onClickDeleteAll()}
        handleNo={() => setDeleteSelectedPopup(false)}
      >
        Do you want to delete the document(s)?
      </ConfirmDialog>
    </div>
  );
};
// export default ARARetention;
export default withAITracking(reactPlugin, ARARetention);

