import * as React from "react";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
// import StopIcon from "@mui/icons-material/Stop";
import DoneIcon from "@mui/icons-material/Done";
import CircularProgress from "@mui/material/CircularProgress";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ButtonGroup from "@mui/material/ButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import { AuditContext } from "../../context/auditContext";
// import Admintemplate from "../../admintemplate/admintemplate";
import DataView from "../../../DataTable/DataView";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import * as rimaConstants from "../../../../rimaconstants";
import ConfirmDialog from "../../../Common/confirmDailog";
import { Box, IconButton } from "@mui/material";
// import { BreadcrumsContext } from "../../../ara/context/breadcrumsContext";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../../applicationInsights/AppInsights';

function DataGridDemo() {
  // const { breadcrums, setBreadcrums } = React.useContext(BreadcrumsContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [auditNumberReopenRemark, setAuditNumberReopenRemark] =
    React.useState("");
  const [remarksText, setRemarksText] = React.useState("");
  const [startPopup, setStartPopup] = React.useState(false);
  const [hideReopenRemarksColumn, setHideReopenRemarksColumn] =
    React.useState(false);
  const [stopPopup, setStopPopup] = React.useState(false);
  const [reopenPopup, setReopenPopup] = React.useState(false);
  const [deletePopup, setDeletePopup] = React.useState(false);
  const [auditNum, setAuditNum] = React.useState("");
  const [auditDataList, setAuditDataList] = React.useState({});
  const [loader, setLoader] = React.useState(false);
  const { auditValue, setAudit } = React.useContext(AuditContext);
  const [auditStatus, setAuditStatus] = React.useState(2);
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef(null);
  React.useEffect(() => {
    setHeight(window.innerHeight - 300);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const loadAuditList = () => {
    setLoader(true);
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/audit/list/${auditValue.auditStatus}`
      )
      .then((response) => {
        setLoader(false);
        //console.log(response.data);
        if (response?.data?.status === "success") {
          setAuditDataList(response.data["data"]);
        } else {
          setAuditDataList([]);
        }

        //setAuditDataList(rows);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCancle = () => {
    setOpen(false);
    setAuditNumberReopenRemark("");
    setRemarksText("");
  };

  const handleSave = () => {
    if (!remarksText.trim()) {
      setAudit((prevAudit) => {
        prevAudit.showErrorMsg = "Please enter reopen remarks";
        prevAudit.msgType = "warning";
        return { ...prevAudit };
      });
      return false;
    } else if (remarksText.length > 200) {
      setAudit((prevAudit) => {
        prevAudit.showErrorMsg = "Only 200 characters are allowed!";
        prevAudit.msgType = "warning";
        return { ...prevAudit };
      });
      return false;
    } else {
      handleClose();
      setLoaderImg(auditNumberReopenRemark);
      setLoader(true);
      axios
        .put(
          `${
            rimaConstants.REACT_APP_API_URL
          }/api/v1/audit/reopen/${window.base64_encode(
            auditNumberReopenRemark
          )}`,
          { reopen_remarks: remarksText }
        )
        .then((response) => {
          ////console.log(response.data);
          setLoader(false);
          setAuditNumberReopenRemark("");
          setRemarksText("");
          if (response?.data?.status === "success") {
            setAudit((prevAudit) => {
              prevAudit.editForm = false;
              prevAudit.loadAuditList = auditValue.loadAuditList + 1;
              prevAudit.showErrorMsg = "Audit reopened successfully!";
              prevAudit.msgType = "success";
              return { ...prevAudit };
            });
          } else {
            setAudit((prevAudit) => {
              prevAudit.editForm = false;
              prevAudit.showErrorMsg = response?.data?.msg;
              prevAudit.msgType = "warning";
              return { ...prevAudit };
            });
          }
          setLoaderImg(false);
        })
        .catch(function (error) {
          //console.log(error);
        });
    }
  };

  const [loaderImg, setLoaderImg] = React.useState(false);
  const columns = [
    { field: "id", headerName: "#", width: 50 },
    {
      field: "action",
      headerName: "Action",
      type: "string",
      width: 120,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        const onClickEdit = (e, id = params.row.audit_number) => {
          e.stopPropagation(); // don't select this row after clicking
          setLoader(true);
          axios
            .get(
              `${
                rimaConstants.REACT_APP_API_URL
              }/api/v1/audit/${window.base64_encode(id)}`
            )
            .then((response) => {
              //console.log(response.data);
              setLoader(false);
              if (response?.data?.status === "success") {
                sessionStorage.setItem(
                  "editauditdata",
                  JSON.stringify(response.data["data"][0])
                );
                setAudit((prevAudit) => {
                  prevAudit.editForm = true;
                  prevAudit.addForm = true;
                  prevAudit.addFormButton = false;
                  return { ...prevAudit };
                });
              } else {
                setAudit((prevAudit) => {
                  prevAudit.editForm = false;
                  prevAudit.showErrorMsg =
                    "There is some issue while loading data.";
                  prevAudit.msgType = "warning";
                  return { ...prevAudit };
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        };
        const onClickStartPop = (e, audit_number = params.row.audit_number) => {
          e.stopPropagation();
          setStartPopup(true);
          setAuditNum(audit_number);
        };

        const onClickStopPop = (e, audit_number = params.row.audit_number) => {
          e.stopPropagation();
          setAuditNum(audit_number);
          setStopPopup(true);
        };

        const onClickReopen = (e, audit_number = params.row.audit_number) => {
          e.stopPropagation(); // don't select this row after clicking
          setAuditNumberReopenRemark(audit_number);
          setReopenPopup(true);
          // let confirm = window.confirm("Do you want to Reopen the audit?");
          // if (!confirm) return false;
          // handleClickOpen();
        };
        const onClickDeletePop = (
          e,
          id = params.row.audit_number,
          audit_number = params.row.audit_number
        ) => {
          e.stopPropagation();
          setAuditNum(audit_number);
          setDeletePopup(true);
        };
        return (
          <React.Fragment>
            <ButtonGroup
              size="small"
              variant="contained"
              aria-label="outlined primary button group"
              // sx={{ width: "10px" }}
            >
              {params.row.status !== "Closed" && (
                <IconButton onClick={onClickEdit} title="Edit" size="small">
                  <EditIcon />
                </IconButton>
              )}
              {params.row.status === "Created" &&
                (setHideReopenRemarksColumn(true),
                (
                  <IconButton
                    onClick={onClickDeletePop}
                    title="Delete"
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                ))}
              {params.row.status === "Created" &&
                (setHideReopenRemarksColumn(true),
                (
                  <IconButton
                    onClick={onClickStartPop}
                    title="Initiate Audit"
                    size="small"
                  >
                    <PlayArrowIcon />
                  </IconButton>
                ))}
              {params.row.status === "Initiated" && (
                <IconButton
                  onClick={onClickStopPop}
                  title="Mark audit to close"
                  size="small"
                >
                  <DoneIcon />
                </IconButton>
              )}
              {params.row.status === "Closed" && (
                <IconButton
                  onClick={onClickReopen}
                  title="Reopen Audit"
                  size="small"
                >
                  <LockOpenIcon />
                </IconButton>
              )}
              {loaderImg === params.row.audit_number && (
                <IconButton>
                  <CircularProgress size={17} thickness={4} />
                </IconButton>
              )}
            </ButtonGroup>
          </React.Fragment>
        );
      },
    },
    {
      field: "audit_number",
      headerName: "Audit Number",
      width: 110,
      editable: false,
    },
    {
      field: "audit_title",
      headerName: "Audit Title",
      width: 110,
      editable: false,
    },
    {
      field: "data_source",
      headerName: "Data Source",
      width: 100,
      editable: false,
    },
    {
      field: "audit_type",
      headerName: "Audit Type",
      type: "string",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <Tooltip title={params.row.audit_type}>
          <span>{params.row.audit_type}</span>
        </Tooltip>
      ),
    },
    {
      field: "site",
      headerName: "Site",
      type: "string",
      width: 210,
      editable: false,
      renderCell: (params) => (
        <Tooltip title={params.row.site}>
          <span>{params.row.site}</span>
        </Tooltip>
      ),
    },
    {
      field: "lead_auditor",
      headerName: "Lead Auditor",
      sortable: true,
      width: 210,
      valueGetter: (params) => `${params.row.lead_auditor || ""}`,
      renderCell: (params) => (
        <Tooltip title={params.row.lead_auditor}>
          <span>{params.row.lead_auditor}</span>
        </Tooltip>
      ),
    },
    {
      field: "audit_team",
      headerName: "Audit Team",
      type: "string",
      width: 210,
      editable: false,
      renderCell: (params) => (
        <Tooltip title={params.row.audit_team}>
          <span>{params.row.audit_team}</span>
        </Tooltip>
      ),
    },
    {
      field: "auditee",
      headerName: "Auditee Contact",
      type: "string",
      width: 210,
      editable: false,
      renderCell: (params) => (
        <Tooltip title={params.row.auditee}>
          <span>{params.row.auditee}</span>
        </Tooltip>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      type: "string",
      type1: "date",
      width: 100,
      editable: false,
      getApplyQuickFilterFn: window.getApplyQuickFilterFn,
      valueGetter: ({ value }) => window.getDateGridBinding(value),
      renderCell: (params) => (
        <Tooltip title={window.getDate(params.row.date)}>
          <span>{window.getDate(params.row.date)}</span>
        </Tooltip>
      ),
    },
    {
      field: "start_date",
      headerName: "Start Date",
      width: 100,
      editable: false,
      type: "string",
      type1: "date",
      getApplyQuickFilterFn: window.getApplyQuickFilterFn,
      valueGetter: ({ value }) => window.getDateGridBinding(value),
      renderCell: (params) => (
        <Tooltip title={window.getDate(params.row.start_date)}>
          <span>{window.getDate(params.row.start_date)}</span>
        </Tooltip>
      ),
    },
    {
      field: "end_date",
      headerName: "End Date",
      width: 100,
      editable: false,
      type: "string",
      type1: "date",
      getApplyQuickFilterFn: window.getApplyQuickFilterFn,
      valueGetter: ({ value }) => window.getDateGridBinding(value),
      renderCell: (params) => (
        <Tooltip title={window.getDate(params.row.end_date)}>
          <span>{window.getDate(params.row.end_date)}</span>
        </Tooltip>
      ),
    },
    {
      field: "actual_start_date",
      headerName: "Actual Start Date",
      width: 150,
      editable: false,
      type: "string",
      type1: "date",
      getApplyQuickFilterFn: window.getApplyQuickFilterFn,
      valueGetter: ({ value }) => window.getDateGridBinding(value),
      renderCell: (params) => (
        <Tooltip title={window.getDate(params.row.actual_start_date)}>
          <span>{window.getDate(params.row.actual_start_date)}</span>
        </Tooltip>
      ),
    },
    {
      field: "actual_end_date",
      headerName: "Actual End Date",
      width: 150,
      editable: false,
      type: "string",
      type1: "date",
      getApplyQuickFilterFn: window.getApplyQuickFilterFn,
      valueGetter: ({ value }) => window.getDateGridBinding(value),
      renderCell: (params) => (
        <Tooltip title={window.getDate(params.row.actual_end_date)}>
          <span>{window.getDate(params.row.actual_end_date)}</span>
        </Tooltip>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      width: 110,
      editable: false,
    },
    {
      field: "reopen_remarks",
      headerName: "Reopen Remarks",
      type: "string",
      width: 160,
      editable: false,
      hide: hideReopenRemarksColumn,
      renderCell: (params) => (
        <Tooltip
          title={window.extratDateFromParagraph(params.row.reopen_remarks)}
        >
          <span>
            {window.extratDateFromParagraph(params.row.reopen_remarks)}
          </span>
        </Tooltip>
      ),
    },
  ];

  React.useEffect(() => {
    loadAuditList();
  }, [auditValue.auditStatus, auditValue.loadAuditList]);
  //}, [auditValue, auditStatus]);
  const onClickStop = () => {
    //e.stopPropagation(); // don't select this row after clicking

    // let confirm = window.confirm("Do you want to Close the audit?");
    // if (!confirm) return false;
    let audit_number = auditNum;
    setLoaderImg(audit_number);
    setLoader(true);
    axios
      .put(
        `${
          rimaConstants.REACT_APP_API_URL
        }/api/v1/audit/mark_complete/${window.base64_encode(audit_number)}`
      )
      .then((response) => {
        //console.log(response.data);
        setLoader(false);
        if (response?.data?.status === "success") {
          setAudit((prevAudit) => {
            prevAudit.editForm = false;
            prevAudit.loadAuditList = auditValue.loadAuditList + 1;
            prevAudit.showErrorMsg = "Audit closed successfully.";
            prevAudit.msgType = "success";
            return { ...prevAudit };
          });
        } else {
          setAudit((prevAudit) => {
            prevAudit.editForm = false;
            prevAudit.showErrorMsg = response?.data?.msg;
            prevAudit.msgType = "warning";
            return { ...prevAudit };
          });
        }
        setLoaderImg(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const onClickStart = () => {
    //e.stopPropagation(); // don't select this row after clicking

    // let confirm = window.confirm(
    //   "Do you want to initiate the risk analysis?"
    // );
    // if (!confirm) return false;
    let audit_number = auditNum;
    setLoaderImg(audit_number);
    setLoader(true);
    axios
      .put(
        `${
          rimaConstants.REACT_APP_API_URL
        }/api/v1/audit/start/${window.base64_encode(audit_number)}`
      )
      .then((response) => {
        //console.log(response.data);
        setLoader(false);
        if (response?.data?.status === "success") {
          setAudit((prevAudit) => {
            prevAudit.editForm = false;
            prevAudit.loadAuditList = auditValue.loadAuditList + 1;
            prevAudit.showErrorMsg = "Audit initiated successfully. ";
            prevAudit.msgType = "success";
            return { ...prevAudit };
          });
        } else {
          setAudit((prevAudit) => {
            prevAudit.editForm = false;
            prevAudit.showErrorMsg = response?.data?.msg;
            prevAudit.msgType = "warning";
            return { ...prevAudit };
          });
        }
        setLoaderImg(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const onClickDelete = () => {
    // e.stopPropagation(); // don't select this row after clicking

    // let confirm = window.confirm(
    //   "Do you want to delete the Audit Record?"
    // );
    // if (!confirm) return false;
    let audit_number = auditNum;
    setLoaderImg(audit_number);
    setLoader(true);
    axios
      .delete(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/audit/${window.base64_encode(
          audit_number
        )}`
      )
      .then((response) => {
        //console.log(response.data);
        setLoader(false);
        if (response?.data?.status === "success") {
          setAudit((prevAudit) => {
            prevAudit.editForm = false;
            prevAudit.loadAuditList = auditValue.loadAuditList + 1;
            prevAudit.showErrorMsg = "Audit deleted successfully!";
            return { ...prevAudit };
          });
        } else {
          setAudit((prevAudit) => {
            prevAudit.editForm = false;
            prevAudit.msgType = "warning";
            prevAudit.showErrorMsg = response?.data?.msg;
            return { ...prevAudit };
          });
        }
        setLoaderImg(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChangeAuditStatus = (e) => {
    console.log("handleChangeAuditStatus ", e.target.value);
    setAuditStatus(e.target.value);
    setAudit((prevAudit) => {
      prevAudit.auditStatus = e.target.value;
      return { ...prevAudit };
    });
  };

  return (
    <div ref={ref} style={{ minHeight: height }}>
      {loader ? window.showLoader() : window.hideLoader()}
      <ConfirmDialog
        title={""}
        open={deletePopup}
        setOpen={setDeletePopup}
        onConfirm={() => onClickDelete()}
        handleNo={() => setDeletePopup(false)}
      >
        Do you want to delete the audit record ?
      </ConfirmDialog>
      <ConfirmDialog
        title=""
        open={reopenPopup}
        setOpen={setReopenPopup}
        onConfirm={() => handleClickOpen()}
        handleNo={() => setReopenPopup(false)}
      >
        Do you want to Reopen the audit ?
      </ConfirmDialog>
      <ConfirmDialog
        title=""
        open={startPopup}
        setOpen={setStartPopup}
        onConfirm={() => onClickStart()}
        handleNo={() => setStartPopup(false)}
      >
        Do you want to initiate the risk analysis?
      </ConfirmDialog>
      <ConfirmDialog
        title=""
        open={stopPopup}
        setOpen={setStopPopup}
        onConfirm={() => onClickStop()}
        handleNo={() => setStopPopup(false)}
      >
        Do you want to close the audit ?
      </ConfirmDialog>
      <Box sx={{ mt: 5 }}>
        <DataView
          rows={auditDataList}
          columns={columns}
          menuName="Audit List"
          checkboxSelection={false}
          enableAuditListFilter={true}
          auditStatus={auditStatus}
          handleChangeAuditStatus={handleChangeAuditStatus}
        />
      </Box>

      {/* </div> */}
      <div>
        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
          <div className="apply-custom-btn">
            <DialogTitle>Reopen Remarks</DialogTitle>
            <DialogContent sx={{ width: "500px" }}>
              <TextField
                multiline
                rows={3}
                maxRows={4}
                autoFocus
                margin="dense"
                id="name"
                label="Please enter reopen remarks"
                fullWidth
                variant="standard"
                value={remarksText}
                onChange={(event) => {
                  setRemarksText(event.target.value);
                }}
              />
              <span style={{ fontWeight: "bold", fontSize: "11px" }}>
                (Character limit 200)
              </span>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleCancle}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSave}>
                Update
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    </div>
  );
}

const List = () => {
  return <DataGridDemo />;
};

// export default List;
export default withAITracking(reactPlugin, List);
