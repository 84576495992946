import React, { useContext } from "react";
import Admintemplate from "../admintemplate/admintemplate";
import ARARetention from "./ARARetention";
import { BreadcrumbsContext } from "../context/breadcrumsContext";
const Content = () => {
  // eslint-disable-next-line no-unused-vars
  const { breadcrumbs, setBreadcrumbs } = useContext(BreadcrumbsContext);
  React.useEffect(() => {
    setBreadcrumbs((prevBreadcrumbs) => {
      prevBreadcrumbs.parentMenu = "ARA Retention Period Settings";
      prevBreadcrumbs.childMenu = "";
      return { ...prevBreadcrumbs };
    });
  }, []);
  return (
    <div className="container-admindashboard">
      <ARARetention />
    </div>
  );
};

const GetContent = () => {
  return <Content />;
};

const ARARetentionMain = () => {
  return <Admintemplate content={GetContent()} />;
};

export default ARARetentionMain;
