import * as React from "react";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import * as rimaConstants from "../../../src/rimaconstants";
import { CircularProgress, IconButton, Skeleton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// import ConfirmDialog from "./confirmDailog";

const xhr = new XMLHttpRequest();

export default function FixedTags(props) {
  const [emails, setEmails] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [searchStart, setSearchStart] = React.useState("");
  const inputRef = React.useRef();

  React.useEffect(async () => {
    if (searchStart && search.length >= 3) {
      let token = await window.getAccessToken();
      xhr.abort();
      const method = "GET";
      const url = `${rimaConstants.REACT_APP_API_URL}/api/v1/user/emails/${props.role}?search=${search}`;
      xhr.onreadystatechange = function (e) {
        if (xhr.readyState === 4 && xhr.status === 200) {
          setSearchStart(false);
          let data = JSON.parse(JSON.parse(this.responseText));
          if (Array.isArray(data)) {
            setEmails(data);
            inputRef.current.querySelector("input").focus();
          } else {
            window.showMessage(
              "Unable to fetch user(s) from AD Group",
              "error"
            );
          }
        }
      };
      xhr.open(method, url);
      xhr.setRequestHeader("Content-type", "application/json; charset=utf-8");
      xhr.setRequestHeader("Accept", "application/json");
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      xhr.setRequestHeader("Currentmodule", `${window.getCurrentModuleName()}`);
      xhr.send();
    } else {
      if (searchStart) {
        window.showMessage("Atleast 3 characters are required", "warning");
        setSearchStart(false);
      }
    }
  }, [searchStart]);

  const fixedOptions = [];
  const [value, setValue] = React.useState([...fixedOptions]);
  React.useEffect(() => {
    if (props.value) {
      // alert(props.value)
      let arr = props.value.split(";");
      let arr1 = [];
      arr.map((val) => {
        arr1.push({ email_address: `${val}` });
        setValue({ email_address: `${val}` });
        return true;
      });
      // setValue([...fixedOptions, ...arr1]);
    } else {
      setValue([...fixedOptions]);
    }
  }, [props.value]);

  const funOnKeyUp = (e) => {
    setSearch(e.target.value);
  };
  const keyPressedCheck1 = (e) => {
    if (e.key === "Enter" && e.target.value !== "") {
      setSearchStart(true);
    }
  };

  const changeEmail = (event, newValue) => {
    // if (props.multiple) {
    let emails = newValue;
    if (emails && emails.email_address) {
      setValue(emails);
      props.setParentValues([emails["email_address"]].join(";"));
      // setEmails([emails]);
    }else{
      setValue([]);
    }
  };

  return (
    <>
      <Autocomplete
        noOptionsText={"Enter Name/Email and search"}
        limitTags={1}
        openOnFocus
        // multiple={false}
        // id="fixed-tags-demo"
        // filterSelectedOptions={true}
        // getOptionSelected={(option, value) =>
        //   option.email_address === value.email_address
        // }
        value={value}
        onChange={changeEmail}
        onKeyPress={keyPressedCheck1}
        onKeyUp={funOnKeyUp}
        options={emails}
        getOptionLabel={(option) => option.email_address ?? option}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.email_address}
              {...getTagProps({ index })}
              disabled={fixedOptions.indexOf(option) !== -1}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.lable}
            placeholder={props.placeholder}
            ref={inputRef}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {searchStart ? (
                    <CircularProgress
                      sx={{ position: "absolute", right: "45px" }}
                      size={15}
                    />
                  ) : (
                    <IconButton
                      color="primary"
                      aria-label="Search"
                      onClick={() => setSearchStart(true)}
                      disabled={searchStart ? true : false}
                      sx={{ position: "absolute", right: "40px" }}
                    >
                      <SearchIcon color="inherit" size={20} />
                    </IconButton>
                  )}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
}
