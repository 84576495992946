import React, { useEffect, useState, useContext } from "react";
// import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import EditData from "./userAccessManagementEdit";
import "./userAccessManagement.css";
import DataView from "../../../components/DataTable/DataView";
// import userLogo from "./../../../assets/images/u12.png";
// import editLogo from "./../../../assets/images/edit.png";
// import deleteLogo from "./../../../assets/images/delete.png";
import Admintemplate from "../admintemplate/admintemplate";
import axios from "axios";
import * as rimaConstants from "./../../../rimaconstants";
import Skeleton from "@mui/material/Skeleton";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ButtonGroup,
} from "@mui/material";
import { BreadcrumbsContext } from "./../context/breadcrumsContext";
// import { toast } from "react-toastify";
import { LoaderContext } from "./../../ara/main/LoaderComponent";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../applicationInsights/AppInsights';

const UserManagement = () => {
  return <Admintemplate content={UserAccessManagement()} />;
};

function UserAccessManagement() {
  const [editRow, setEditRow] = useState(null);
  const [updateFlag, setUpdateFlag] = useState(Math.random());
  const [openAddEditPopup, setOpenAddEditPopup] = useState(false);

  return (
    <div>
      {openAddEditPopup && (
        <EditData
          row={editRow}
          onCancel={() => {
            setEditRow(null);
            setOpenAddEditPopup(false);
          }}
          onSubmit={() => {
            setUpdateFlag(Math.random());
            setEditRow(null);
            setOpenAddEditPopup(false);
          }}
        />
      )}
      <DataViewList
        onEditCb={(row) => {
          setEditRow(row);
          setOpenAddEditPopup(true);
        }}
        updateFlag={updateFlag}
        onAddClick={() => {
          setEditRow(null);
          setOpenAddEditPopup(true);
        }}
        openAddEditPopup={openAddEditPopup}
      />
    </div>
  );
}

const DataViewList = (props) => {
  // const [pageSize, setPageSize] = useState(5);
  const [dataList, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRole, setSelectedRole] = useState("select");
  const [isAddBtn, setIsAddBtn] = useState(false);
  const [userRole, setUserRole] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const { show, setShow } = useContext(LoaderContext);
  // eslint-disable-next-line no-unused-vars
  const { breadcrumbs, setBreadcrumbs } = useContext(BreadcrumbsContext);

  useEffect(() => {
    axios
      .get(`${rimaConstants.REACT_APP_API_URL}/api/v1/user-management/userrole`)
      .then((res) => {
        setUserRole(res.data["data"]);
      });
    setBreadcrumbs((prevBreadcrumbs) => {
      prevBreadcrumbs.parentMenu = "User Management";
      prevBreadcrumbs.childMenu = "";
      return { ...prevBreadcrumbs };
    });
  }, []);
  useEffect(() => {
    getUsers();
  }, [props.updateFlag]);

  useEffect(() => {
    setIsAddBtn(props.openAddEditPopup);
  }, [props.openAddEditPopup]);

  const getUsers = () => {
    setLoading(true);
    axios
      .get(`${rimaConstants.REACT_APP_API_URL}/api/v1/user-management`)
      .then((res) => {
        // if(res.data.status === 'status'){
        let cls = [
          ...res.data.heading,
          ...[
            {
              field: "edit",
              headerName: "Edit Access",
              width: 100,
              sortable: false,
              renderCell: (params) => {
                return (
                  <ButtonGroup
                    size="small"
                    variant="contained"
                    aria-label="outlined primary button group"
                    sx={{ width: "10px" }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        props.onEditCb(params.row);
                      }}
                      title="Edit"
                    >
                      <EditIcon />
                    </Button>
                    <Button
                      variant="contained"
                      title="Delete"
                      onClick={() => {
                        deleteUser(params.row.user_id);
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </ButtonGroup>
                );
              },
            },
          ],
        ];
        setRows(res.data.data);
        setData(res.data.data);
        setColumns(cls);
        setLoading(false);
        // }
      });
  };

  const deleteUser = (id) => {
    setShow(true);
    axios
      .delete(`${rimaConstants.REACT_APP_API_URL}/api/v1/user-management/` + id)
      .then((res) => {
        setShow(false);
        if (res?.data?.status === 200) {
          window.showMessage("User deleted successfully.", "success");
          getUsers();
        }
        if (res?.data?.status === 405)
          window.showMessage(res.data.errorMsg, "error");
      });
  };

  // let headername = props.menuName | "User Access Management";

  const roleFilterHandler = (e) => {
    if (e?.target?.value) {
      setSelectedRole(e.target.value);
      if (e.target.value === "select") {
        setRows(dataList);
      } else {
        setRows(dataList.filter((i) => i.role_id === e.target.value));
      }
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="list-filter">
        <FormControl required sx={{ m: 1 }}>
          <InputLabel id="select-required-label">Select Role</InputLabel>
          <Select
            labelId="select-required-label"
            id="data_source"
            value={selectedRole}
            label="Data source *"
            onChange={roleFilterHandler}
            sx={{ height: "45px", width: "150px" }}
            name="data_source"
          >
            <MenuItem key={"select"} value="select">--Select--</MenuItem>
            {userRole.map((role) => {
              return <MenuItem key={role.role_id} value={role.role_id}>{role.role_name}</MenuItem>;
            })}
          </Select>
        </FormControl>
        {!isAddBtn && (
          <Button
            variant="contained"
            sx={{
              textTransform: "capitalize",
              fontWeight: "bold",
              height: "40px",
              width: "150px",
            }}
            onClick={props.onAddClick}
          >
            Add New User
          </Button>
        )}
      </div>
      {loading ? (
        <Box
          sx={{
            height: "max-content",
          }}
        >
          <Box
            width={"100%"}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Skeleton
              variant="rectangular"
              sx={{ my: 1, mx: 1 }}
              height={30}
              width={320}
              style={{ justifySelf: "flex-end" }}
            />
          </Box>
          <Skeleton variant="rectangular" sx={{ mb: 1, mx: 1 }} height={50} />
          {[...Array(5)].map((_, index) => (
            <Skeleton
              id={index}
              variant="rectangular"
              sx={{ my: 0.5, mx: 1 }}
              height={40}
            />
          ))}
          <Box width={"100%"}>
            <Skeleton
              variant="rectangular"
              sx={{ my: 1, mx: 1 }}
              height={50}
              style={{ justifySelf: "flex-end" }}
            />
          </Box>
        </Box>
      ) : (
        <DataView
          rows={rows}
          columns={columns}
          menuName="Users"
          checkboxSelection={false}
          loading={loading}
          getRowId={(row) => row.user_id}
        />
      )}
    </div>
  );
};
//   export default DataView;
// export default UserManagement;
export default withAITracking(reactPlugin, UserManagement);

