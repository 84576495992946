import React, { useState, useEffect, memo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Stack,
  TextField,
  IconButton,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Box,
  FormControlLabel,
  Switch,
  FormHelperText,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import AttachmentIcon from "@mui/icons-material/Attachment";
import SendIcon from "@mui/icons-material/Send";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import axios from "axios";
import * as rimaConstants from "../../../rimaconstants";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import Tooltips from "../../Common/Tooltips";
import moment from "moment";
import { UserDataContext } from "../../Context/UserDataContext";

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
const FeedBackResponse = (props) => {
  //console.log(props);

  //let auditInfo = props.auditTitle;
  //let audit_number = auditInfo.split("#")[0].split("-")[0].trim();
  //console.log(audit_number);
  // eslint-disable-next-line no-unused-vars
  const [adminUser, setAdminUser, user, setUser] =
    React.useContext(UserDataContext);
  let audit_number = props.audit_number;
  const [loadingSend, setLoadingSend] = useState(false);
  const [loadingTranslate, setLoadingTranslate] = useState(false);
  const [language, setLanguage] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [auditQuestions, setAuditQuestions] = useState([]);
  const [audit, setAudit] = useState({});
  const [answers, setAnswers] = useState([]);
  const [answersFiles, setAnswersFiles] = useState([]);
  const [adfiles, setAdfiles] = useState([]);
  const [additionalComment, setAdditionalComment] = useState("");
  const [role, setRole] = useState("");
  // const [value, setValue] = React.useState(new Date());
  const [uploadedFiles, setUploadedFiles] = useState([]);
  // const [timeValue, setTimeValue] = React.useState(new Date());
  const [auditorAttachments, setAuditorAttachments] = useState([]);
  // const [olderData, setOlderData] = useState("");
  const [adcommentsText, setAdcommentsText] = useState("Additional comments");
  const textFields = {
    header: "Stakeholder Feedback Response",
    data_source: "Data Source",
    audit: "Audit",
    translate: "Translate",
    characters: "characters",
    maxcharacters: "max characters",
    answer: "Answer",
    adcomments: "Additional comments",
    close: "Close",
    send: "Send",
    role: "Role",
    auditor_comments: "General Comments",
    info: "Response can't be edited after submission.",
  };
  const [translateText, setTranslateText] = useState(textFields);

  useEffect(() => {
    getQuestions();
  }, []);
  useEffect(() => {
    console.log(adfiles);
  }, [adfiles]);
  const getQuestions = () => {
    setLoading(true);
    // setOpen(true);
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/stakeholder_feedback_response/response_question/${audit_number}`
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status === "success") {
          if (response.data.data.length) {
            if (response.data.data[0]["feature_id"]) {
              window.setFeatureID(response.data.data[0]["feature_id"]);
              // alert(response.data.data[0]["feature_id"]);
            }
            setOpen(true);
            let prevData = {};
            prevData["audit_number"] = audit_number;
            let audit = response.data.data[0];

            // let questions = JSON.parse(
            //   response.data.data[0].feedback_questions
            // );
            let questions = JSON.parse(
              response.data.data[0].stakeholder_feedback_response
            );
            prevData["questions"] = questions;
            let files = JSON.parse(response.data.data[0].general_attachments);
            prevData["files"] = files;
            // setOlderData(prevData);
            console.log(questions);
            setAudit(audit);
            setQuestions(questions);
            setAuditQuestions(questions);
            setAuditorAttachments(files);
          } else {
            // props.text("Feedback has already been submitted for the audit");
            // props.text(response.data.msg);
            // setOpen(false);
            // props.close();
          }
        }

        if (response?.data?.status === "fail") {
          if (response.data.data[0]["feature_id"]) {
            window.setFeatureID(response.data.data[0]["feature_id"]);
            // alert(response.data.data[0]["feature_id"]);
          }
          window.showMessage(response.data.msg, "warning");
          props.text(response.data.msg);
          setOpen(false);
          props.close();
        }

        //console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleTranslate = () => {
    if (language) {
      setLoadingTranslate(true);
      let tmparr = [...questions, { additionalfeedback: additionalComment }];
      tmparr.push(
        { auditorcomments: "Auditor comments" }
      );
      if (audit.general_description !== "") {
        tmparr.push(
          { commenttext: audit.general_description }
        );
      }
      //translateText
      Object.keys(textFields).map((key) => {
        let obj = {};
        obj[key] = textFields[key];
        tmparr.push(obj);
        return true;
      });
      let payload = { text: tmparr, lang: language };
      console.log(payload);
      axios
        .post(
          `${rimaConstants.REACT_APP_API_URL}/api/v1/stakeholder_feedback_response/translate`,
          payload
        )
        .then((response) => {
          //setTranslations(response.data);
          setLoadingTranslate(false);
          if (response.data.length) {
            let list = response.data;
            console.log("auditQuestions.length", auditQuestions.length);
            let questions = list.splice(0, auditQuestions.length);
            console.log("questions", list, questions);
            // const text = list.slice(auditQuestions.length);
            // console.log(text);
            //let feedback = questions.pop();
            console.log(questions);
            setQuestions(questions);
            let transText = {};
            list.map((input, index) => {
              for (let key in input) {
                if (key === "auditorcomments") {
                  document.getElementById(`auditorcomments`).innerHTML =
                    input[key];
                } else if (key === "commenttext") {
                  document.getElementById(`commenttext`).innerHTML = input[key];
                } else if (key === "adcomments") {
                  setAdcommentsText(input[key]);
                } else {
                  transText[key] = input[key];
                }
                //else if(key === "adcomments"){
                //   document.getElementById(
                //     `adcomments-label`
                //   ).innerHTML = input[key];
                // }
              }
              return true;
            });
            console.log("transText======transText", transText);
            setTranslateText(transText);
          } else {
            window.showMessage("Failed to translate", "warning");
          }

          // response.data.map((input, index) => {
          //   let inputVal = "";
          //   for (let key in input) {
          //     inputVal = input[key];
          //     if (key == "additionalfeedback") {
          //       document.getElementById(
          //         `additionalfeedback`
          //       ).innerHTML = inputVal;
          //     } else {
          //       document.getElementById(
          //         `question${index}`
          //       ).innerHTML = inputVal;
          //     }
          //   }
          // });
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      window.showMessage("Please select language", "warning");

      return false;
    }
  };
  const handleUpload = async () => {
    setLoadingSend(true);
    await upload();
    await uploadAns();
    setTimeout(() => {
      handleSubmit();
    }, 3000);
  };

  const upload = async () => {
    let folderName = "ara";
    let subFolderName = `${audit.data_source}/${audit.feedback_title
      .split("-")[0]
      .trim()}/AuditSections/StakeHolderFeedback/StakeHolderResponse/${
      user.user_id
    }/${moment().utc().unix()}`;
    let promises = [];
    let files = [...adfiles];
    files.map((document) => {
      if (uploadedFiles.indexOf(document["name"]) === -1) {
        promises.push(
          processUpload(
            folderName,
            subFolderName,
            document["name"],
            document["file"]
          )
        );
      }
      return true;
    });
    let upRes = await Promise.all(promises);
    let tmpArr = [];
    upRes.map((value, index) => {
      if (value.status === "success") {
        tmpArr.push(`${subFolderName}/${value.fileName}`);
        if (files[index]) {
          files[index]["name"] = `${subFolderName}/${value.fileName}`;
        }
      }
      return true;
    });
    setAdfiles(files);
    return tmpArr;
  };
  const uploadAns = async () => {
    let folderName = "ara";
    let subFolderName = `${audit.data_source}/${audit.feedback_title
      .split("-")[0]
      .trim()}/AuditSections/StakeHolderFeedback/StakeHolderResponse/${
      user.user_id
    }/${moment().utc().unix()}`;
    console.log("tks answersFiles ", answersFiles);
    let promises = [];
    let files = [];
    for (let i in answersFiles) {
      if (
        typeof answersFiles[i] != "undefined" &&
        typeof answersFiles[i]["files"] != "undefined"
      ) {
        for (let j in answersFiles[i]["files"]) {
          answersFiles[i]["files"][j]["uuidv4"] = window.getUuidv4();
          files.push(answersFiles[i]["files"][j]);
        }
      }
    }
    files.map((document) => {
      if (uploadedFiles.indexOf(document["name"]) === -1) {
        promises.push(
          processUpload(
            folderName,
            subFolderName,
            document["name"],
            document["file"],
            document["uuidv4"]
          )
        );
      }
      return true;
    });
    let upRes = await Promise.all(promises);
    let tmpArr = [];
    let answersFilesTmp = answersFiles;
    upRes.map((value, index) => {
      if (value.status === "success") {
        tmpArr.push(`${subFolderName}/${value.fileName}`);
        answersFilesTmp.map((_, index3) => {
          if (answersFilesTmp[index3] && answersFilesTmp[index3]["files"]) {
            answersFilesTmp[index3]["files"].map((_, index1) => {
              if (
                answersFilesTmp[index3]["files"] &&
                answersFilesTmp[index3]["files"][index1] &&
                answersFilesTmp[index3]["files"][index1]["uuidv4"] ===
                  value.docType
              ) {
                answersFilesTmp[index3]["files"][index1][
                  "name"
                ] = `${value.filePath}`;
              }
              return true;
            });
          }
          return true;
        });
      }
      return true;
    });
    setAnswersFiles(answersFilesTmp);
    return tmpArr;
  };

  const processUpload = async (
    folderName,
    subFolderName,
    fileName,
    fileObject,
    docType = ""
  ) => {
    return new Promise((resolve) => {
      if (folderName && subFolderName && fileName && fileObject) {
        let req_obj = {};
        req_obj = new FormData();
        req_obj.append("upfile", fileObject);
        axios
          .post(
            `${
              rimaConstants.REACT_APP_API_URL
            }/api/v1/stakeholder_feedback_response/upload?folderName=${folderName}&subFolderName=${subFolderName}&fileName=${encodeURIComponent(
              fileName
            )}&docType=${docType}`,
            req_obj
          )
          .then((response) => {
            //console.log(response.data);
            setUploadedFiles((prev) => [...prev, response.data.fileName]);
            resolve(response.data);
          })
          .catch((error) => {
            //console.log(error);
            resolve(error);
          });
      } else {
        resolve([]);
      }
    });
  };
  const handleSubmit = () => {
    let errCount = 0;
    let errMsg = [];
    //setLoading(true);
    if (!role.length) {
      errMsg.push(`Role is required`);
      errCount++;
    }
    questions.map((que, index) => {
      if (typeof answers[index] == "undefined") {
        errMsg.push(`Question ${index + 1} answer is required`);
        errCount++;
      } else if (!answers[index].length) {
        errMsg.push(`Question ${index + 1} answer is required`);
        errCount++;
      }
      return true;
    });

    if (errCount > 0) {
      window.showMessage("* " + errMsg.join("\n\r* "), "warning");
      setLoadingSend(false);
      return false;
    } else {
      let data = {
        email: audit.email,
        stakeholder_feedback_response: [{ role_description: role }],
        general_remarks: additionalComment,
        general_attachments: [],
      };

      for (let i in auditQuestions) {
        let filesname = [];
        if (typeof answersFiles[i] != "undefined") {
          for (let j in answersFiles[i]["files"]) {
            filesname.push(answersFiles[i]["files"][j]["name"]);
          }
        }
        let obj = {
          question: auditQuestions[i][`question${parseInt(i) + 1}`],
          answer: answers[i],
          files: filesname,
        };
        data.stakeholder_feedback_response.push(obj);
      }
      for (let i in adfiles) {
        data.general_attachments.push(encodeURIComponent(adfiles[i].name));
      }

      axios
        .put(
          `${rimaConstants.REACT_APP_API_URL}/api/v1/stakeholder_feedback_response/update_question_response/${audit.audit_id}`,
          data
        )
        .then((response) => {
          setLoadingSend(false);
          if (response.data.status === "success") {
            window.showMessage("Feedback sent", "success");
            props.text("Feedback sent");
            setOpen(false);
            props.close();
            // console.log("success ", response.data);
          } else {
            // console.log("success error ", response.data);
            if (response?.data?.msg) {
              window.showMessage(response?.data?.msg, "error");
            } else {
              window.showMessage(
                "You are not authorize to send questionnaire response",
                "error"
              );
            }
          }
        })
        .catch(function (error) {
          setLoadingSend(false);
          console.log(error);
        });
    }
  };
  const removeCommentFile = (name, index) => {
    let files = adfiles.filter((item) => item.name !== name);
    setAdfiles(files);
  };
  const removeAnswersFile = (name, parentindex, index) => {
    let files = answersFiles;
    console.log(files);
    files[parentindex]["files"] = answersFiles[parentindex]["files"].filter(
      (item) => item.name !== name
    );
    console.log(files);
    setAnswersFiles([...files]);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
      props.close();
      props.text("form closed");
    }
  };
  const onViewClick = async (e, filename) => {
    let format = filename.split("/").pop(0).split(".")[1];
    let type = window.getDocumentTypeByFileExtention(format);
    let url = `${window.BLOB_URL}/${rimaConstants.ARA_ROOT_CONTAINER}/${filename}${window.BLOB_CONATINER_SAS_READ_TOKEN}`;
    window.downloadFile(url, type, filename);
  };
  const handleChange = (event, index) => {
    if (event.target.value.length > 1500) {
      event.target.value = event.target.value.slice(0, 1500);
    }
    let data = answers;
    data[index] = event.target.value;
    setAnswers([...data]);
  };
  const handleadCommentsChange = (event) => {
    if (event.target.value.length > 2000) {
      event.target.value = event.target.value.slice(0, 2000);
    }
    setAdditionalComment(event.target.value);
  };
  const stRoleChange = (e) => {
    if (e.target.value.length > 50) {
      e.target.value = e.target.value.slice(0, 50);
    }
    setRole(e.target.value);
  };
  const addAnswerAttachment = (event, index) => {
    let collections = answersFiles;

    if (typeof collections[index] == "undefined") {
      collections[index] = { files: [] };
    }
    let fileExists = [];
    console.log(collections);
    let supported = ["pdf", "xls", "docx", "doc", "pptx"];

    for (var k in event.target.files) {
      if (event.target.files[k]["type"] !== undefined) {
        let type = event.target.files[k]["type"].split("/")[1];
        if (
          type === "vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          type = "doc";
        } else if (
          type === "vnd.ms-excel" ||
          type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          type = "xls";
        } else if (
          type ===
          "vnd.openxmlformats-officedocument.presentationml.presentation"
        ) {
          type = "pptx";
        }
        if (supported.indexOf(type) !== -1) {
          if (
            event.target.files[k] &&
            event.target.files[k]["name"] !== undefined &&
            event.target.files[k]["name"] !== "item"
          ) {
            let files = answersFiles[index]["files"].filter(
              // eslint-disable-next-line no-loop-func
              (file) => file.name === event.target.files[k]["name"]
            );
            if (files.length) {
              fileExists.push(files[0]["name"]);
            } else {
              let attachment = {
                file: event.target.files[k],
                name: event.target.files[k]["name"],
              };
              collections[index]["files"].push(attachment);
            }
          }
        } else {
          window.showMessage("Invalid file type", "warning");
        }
      }
    }
    if (fileExists.length > 0) {
      window.showMessage(
        fileExists.join("\n\r") + " already exists !",
        "warning"
      );
    }
    document.getElementById(`contained-button-file-${index}`).value = null;
    console.log(collections);
    //setFilesCollection(collections);
    setAnswersFiles([...collections]);
  };
  const addAttachment = (event) => {
    let collections = adfiles;
    let fileExists = [];
    console.log(collections);
    for (let k in event.target.files) {
      if (
        event.target.files[k] &&
        event.target.files[k]["name"] !== undefined &&
        event.target.files[k]["name"] !== "item"
      ) {
        let files = adfiles.filter(
          (file) => file.name === event.target.files[k]["name"]
        );
        if (files.length) {
          fileExists.push(files[0]["name"]);
        } else {
          let attachment = {
            file: event.target.files[k],
            name: event.target.files[k]["name"],
          };
          collections.push(attachment);
        }
      }
    }
    if (fileExists.length > 0) {
      window.showMessage(
        fileExists.join("\n\r") + " already exists !",
        "warning"
      );
    }
    document.getElementById(`contained-button-file-ac`).value = null;
    console.log(collections);
    //setFilesCollection(collections);
    setAdfiles([...collections]);
  };

  const onkeyupHandler = (e) => {
    e.target.style.height = "1px";
    e.target.style.height = 5 + e.target.scrollHeight + "px";
  };

  return (
    <div>
      {loading ? window.showLoader() : window.hideLoader()}
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
        paperProps={{
          style: { borderRadius: 2 },
        }}
        aria-labelledby="responsive-dialog-title"
        disableBackdropClick
      >
        {/*<FeedBackForm 
                close={handleClose}
        />*/}
        <div className="apply-custom-btn">
          <DialogTitle sx={{ fontWeight: "bold", fontSize: "1.4rem" }}>
            {translateText["header"]}
          </DialogTitle>
          <DialogContent>
            <div className="modalContent">
              <Grid container>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      height: 15,
                      p: 2,
                      display: "flex",
                    }}
                  >
                    <Item
                      sx={{
                        gridColumn: "1",
                        fontSize: "0.875rem",
                        fontWeight: "600",
                      }}
                    >
                      {translateText["data_source"]} :
                    </Item>
                    <Item
                      sx={{
                        fontSize: "0.875rem",
                        fontWeight: "500",
                        ml: "10px",
                      }}
                    >
                      {audit.data_source !== undefined ? audit.data_source : ""}
                    </Item>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      height: 15,
                      p: 2,
                      display: "flex",
                    }}
                  >
                    <Item
                      sx={{
                        gridColumn: "1",
                        fontSize: "0.875rem",
                        fontWeight: "600",
                      }}
                    >
                      {translateText["audit"]} :
                    </Item>
                    <Item
                      sx={{
                        fontSize: "0.875rem",
                        fontWeight: "500",
                        ml: "10px",
                      }}
                    >
                      {audit.feedback_title !== undefined
                        ? window.customEscape(audit.feedback_title)
                        : ""}
                    </Item>
                  </Box>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={6}>
                  <FormControl sx={{ mt: 3 }}>
                    <InputLabel id="demo-simple-select-label">
                      Language
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={language}
                      label="Language"
                      onChange={(e) => setLanguage(e.target.value)}
                      sx={{
                        height: "55px",
                        width: "29ch !important",
                        ml: "0px !important",
                      }}
                    >
                      {Object.keys(window.translatorLanguageSupport()).map(
                        (key) => {
                          return (
                            <MenuItem key={key} value={key}>
                              {window.translatorLanguageSupport()[key]}
                            </MenuItem>
                          );
                        }
                      )}
                      {/*<MenuItem value="zh-hk">Chinese (Hong Kong)</MenuItem>*/}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      paddingTop: "25px",
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        display: "none",
                      }}
                      control={
                        <Switch
                          checked={loadingTranslate}
                          onChange={() =>
                            setLoadingTranslate(!loadingTranslate)
                          }
                          name="loading"
                          color="primary"
                        />
                      }
                      label="Loading"
                    />
                    <Box sx={{ "& > button": { m: 1 } }}>
                      <LoadingButton
                        onClick={handleTranslate}
                        endIcon={<SubtitlesIcon />}
                        loading={loadingTranslate}
                        loadingPosition="end"
                        variant="contained"
                        sx={{ textTransform: "none" }}
                      >
                        {translateText["translate"]}
                      </LoadingButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <div className="wrap">
                <FormControl sx={{ width: "90%", pt: 2 }}>
                  <TextField
                    fullWidth
                    multiline
                    label={translateText["role"] + " *"}
                    id="strole"
                    margin="normal"
                    onKeyUp={onkeyupHandler}
                    onChange={stRoleChange}
                    value={role}
                    sx={{ mt: "0px !important", mb: "0px !important" }}
                  />
                </FormControl>
                <FormHelperText
                  id="outlined-que-helper-text"
                  className="help-text"
                >
                  {!role.length ? "0" : role.length}{" "}
                  {translateText["characters"]} (
                  {translateText["maxcharacters"]}: 50)
                </FormHelperText>
                {questions.map((que, index) => {
                  return (
                    <div>
                      <h4 className="aud_que">
                        <Stack direction="row" spacing={1}>
                          <strong>{index + 1}.</strong>
                          <strong className="feedback-text">
                            {que[`question${index + 1}`]}
                          </strong>
                        </Stack>
                      </h4>
                      <FormControl sx={{ width: "98%" }}>
                        <Stack direction="row" spacing={1}>
                          <TextField
                            fullWidth
                            multiline
                            label={translateText["answer"] + " " + (index + 1)}
                            id="fullWidth"
                            margin="normal"
                            onKeyUp={onkeyupHandler}
                            onChange={(event) => handleChange(event, index)}
                            value={answers[index]}
                            sx={{ mt: "0px !important", mb: "0px !important" }}
                          />
                          <input
                            style={{ display: "none" }}
                            id={"contained-button-file-" + index}
                            type="file"
                            multiple
                            onChange={(event) =>
                              addAnswerAttachment(event, index)
                            }
                          />
                          <label htmlFor={"contained-button-file-" + index}>
                            <IconButton
                              component="span"
                              variant="contained"
                              aria-label="upload"
                              size="large"
                              sx={{ pt: 5 }}
                            >
                              <AttachmentIcon />
                            </IconButton>
                          </label>
                        </Stack>
                      </FormControl>
                      <FormHelperText
                        id="outlined-que-helper-text"
                        className="help-text"
                      >
                        {answers[index] === undefined
                          ? "0"
                          : answers[index].length}{" "}
                        {translateText["characters"]} (
                        {translateText["maxcharacters"]}: 1500)
                      </FormHelperText>
                      <div className="tags-container">
                        <ul>
                          {typeof answersFiles[index] !== "undefined" &&
                            typeof answersFiles[index]["files"] !==
                              "undefined" &&
                            answersFiles[index]["files"].map((file, i) => {
                              return (
                                <li key={window.getUuidv4()}>
                                  {/* {file.name} */}
                                  {file.name
                                    ? file.name.split("/")[
                                        file.name.split("/").length - 1
                                      ]
                                    : ""}
                                  <span
                                    sx={{ ml: 2 }}
                                    onClick={() =>
                                      removeAnswersFile(file.name, index, i)
                                    }
                                  >
                                    <IconButton
                                      component="span"
                                      variant="contained"
                                      aria-label="close"
                                      size="small"
                                    >
                                      <CancelRoundedIcon />
                                    </IconButton>
                                  </span>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  );
                })}

                <FormControl sx={{ width: "98%", pt: 4 }}>
                  <Stack direction="row" spacing={1}>
                    <TextField
                      fullWidth
                      multiline
                      label={adcommentsText}
                      id="adcomments"
                      margin="normal"
                      onKeyUp={onkeyupHandler}
                      onChange={handleadCommentsChange}
                      value={additionalComment}
                      sx={{ mt: "0px !important", mb: "0px !important" }}
                    />
                    <input
                      style={{ display: "none" }}
                      id={"contained-button-file-ac"}
                      type="file"
                      multiple
                      onChange={(event) => addAttachment(event)}
                    />
                    <label htmlFor={"contained-button-file-ac"}>
                      <IconButton
                        component="span"
                        variant="contained"
                        aria-label="upload"
                        size="large"
                        sx={{ pt: 5 }}
                      >
                        <AttachmentIcon />
                      </IconButton>
                    </label>
                  </Stack>
                </FormControl>
                <FormHelperText
                  id="outlined-que-helper-text"
                  className="help-text"
                >
                  {additionalComment.length} {translateText["characters"]} (
                  {translateText["maxcharacters"]}: 2000)
                </FormHelperText>
                <div className="tags-container">
                  <ul>
                    {adfiles.map((file, index) => {
                      return (
                        <li key={window.getUuidv4()}>
                          {/* {file.name} */}
                          {file.name
                            ? file.name.split("/")[
                                file.name.split("/").length - 1
                              ]
                            : ""}
                          <span
                            sx={{ ml: 2 }}
                            onClick={() => removeCommentFile(file.name, index)}
                          >
                            <IconButton
                              component="span"
                              variant="contained"
                              aria-label="close"
                              size="small"
                            >
                              <CancelRoundedIcon />
                            </IconButton>
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <FormControl sx={{ width: "98%", pt: 2 }}>
                  <h3>
                    <strong id="auditorcomments">
                      {translateText["auditor_comments"]}
                    </strong>
                  </h3>
                  <p id="commenttext" className="feedback-text">
                    {audit.general_description}
                  </p>
                  <div className="tags-container">
                    <ul>
                      {auditorAttachments.map((file) => (
                        <li
                          key={window.getUuidv4()}
                          className="file-view"
                          onClick={(event) => onViewClick(event, file)}
                        >
                          {file
                            ? file.split("/")[file.split("/").length - 1]
                            : ""}
                        </li>
                      ))}
                    </ul>
                  </div>
                </FormControl>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Box sx={{ mt: 1, mb: 2, ml: 1, mr: 75 }}>
              <Tooltips
                html={false}
                iconColor="#15717D"
                placement="top-end"
                title={translateText["info"]}
              />
            </Box>
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{ mr: 1, textTransform: "none" }}
            >
              {translateText["close"]}
            </Button>
            <LoadingButton
              onClick={handleUpload}
              endIcon={<SendIcon />}
              loading={loadingSend}
              loadingPosition="end"
              variant="contained"
              sx={{ textTransform: "capitalize", mr: 4 }}
              disabled={!questions.length}
            >
              {translateText["send"]}
            </LoadingButton>
            {/*<Button variant="contained" endIcon={<SendIcon />} sx={{mr:4}} onClick={handleUpload}>
          Send
              </Button>*/}
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};
export default memo(FeedBackResponse);
