import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiTableCell-root": {
    fontSize: "12px",
  },
  "& .MuiTableCell-head": {
    fontWeight: "bold",
    backgroundColor: "#15717D",
    color: "#fff",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  dialogRowData,
  setDialogRowData,
  data,
  columnsData,
  menu,
}) {
  const handleClose = () => {
    setDialogRowData(false);
  };

  const renderHtml = (html) => {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
        fullScreen={false}
        aria-labelledby="customized-dialog-title"
        open={dialogRowData}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ m: 1 }}
        ></BootstrapDialogTitle>
        <DialogContent gutterBottom>
          <BasicTable data={data} columnsData={columnsData} menu={menu} />
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export function BasicTable({ data, columnsData, menu }) {
  const showRemarks = (item) =>
    item.field === "reopen_remarks" && data.status !== "Initiated";
  const excludeHeading = ["feedback", "action"];
  return (
    <TableContainer component={Paper}>
      <Table
        className="dataframe"
        sx={{ minWidth: "auto", border: "1px solid #15717D !important" }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow
            sx={{
              "& td, & th": { border: "1px solid #15717D !important" },
              fontWeight: "bold",
            }}
          >
            <TableCell align="center" component="th" colspan="2">
              Details
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {columnsData.map((item, i) => {
            return (
              !excludeHeading.includes(item.headerName.toLowerCase()) && (
                <>
                  {showRemarks(item) ? (
                    <></>
                  ) : (
                    <TableRow
                      key={i}
                      sx={{
                        "& td, & th": {
                          border: "1px solid #15717D !important",
                        },
                      }}
                    >
                      <TableCell
                        align="center"
                        component="td"
                        scope="row"
                        sx={{ width: "25%", verticalAlign: "top" }}
                      >
                        <strong>{item.headerName}</strong>
                      </TableCell>
                      <TableCell align="left" sx={{ whiteSpace: "pre-wrap" }}>
                        {["file name"].includes(item.headerName.toLowerCase())
                          ? data[item["field"]]?.split("/")?.pop(0)
                          : item.field.toLowerCase().includes("date")
                          ? [
                              "Stakeholder Feedback",
                              "Uploaded Documents",
                              "Audit Trails",
                              "Site Management",
                            ].includes(menu)
                            ? window.getDate(data[item["field"]], true)
                            : window.getDate(data[item["field"]])
                          : menu === "Audit List" &&
                            item.headerName === "Reopen Remarks"
                          ? window.extratDateFromParagraph(data[item["field"]])
                          : window.decodeHtml(
                              data[item["field"]]
                                ?.toString()
                                ?.replace(/&(amp;)*amp;/g, "&")
                            )}
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
