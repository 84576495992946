/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  FormControlLabel,
  Switch,
  OutlinedInput,
  Checkbox,
  ListItemText,
  FormLabel,
  Stack,
  IconButton,
} from "@mui/material";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import * as rimaConstants from "../../../rimaconstants";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import CircularProgress from "@mui/material/CircularProgress";
import SaveIcon from "@mui/icons-material/Save";
import { UserDataContext } from "../../Context/UserDataContext";
import Tooltips from "../../Common/Tooltips";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../applicationInsights/AppInsights";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 145,
    },
  },
};
const selections_vx = [
  "Change Control",
  "Cleaning Validation & Verification",
  "Corrective & Preventive Actions",
  "Data Management & Control",
  "Deviation Handling",
  "Document Management & Control",
  "Incident  Management",
  "List of Products",
  "Maintenance & Calibration ",
  "Monitoring & Reviewing of Environmental Control & Utilities",
  "Overview of Facilities Design",
  "Product & Technology Transfer",
  "Product Lifecycle Management",
  "Quality Alerts and Bulletin Management",
  "Quality Managament System",
  "Quality Plan",
  "Regulatory and Quality Compliance",
  "Management Monitoring & Independent Business Monitoring",
  "SOP Index",
  "Sterile Process Validation Monitoring & Control",
  "Warehouse Management",
  "Laboratory Out of Specification",
];
const selections_rx = [
  "Change Control",
  "Cleaning Validation & Verification",
  "Corrective & Preventive Actions",
  "Data Management & Control",
  "Deviation Handling",
  "Document Management & Control",
  "Incident  Management",
  "List of Products",
  "Maintenance & Calibration ",
  "Monitoring & Reviewing of Environmental Control & Utilities",
  "Overview of Facilities Design",
  "Product & Technology Transfer",
  "Product Lifecycle Management",
  "Quality Alerts and Bulletin Management",
  "Quality Managament System",
  "Quality Plan",
  "Regulatory and Quality Compliance",
  "Management Monitoring & Independent Business Monitoring",
  "SOP Index",
  "Sterile Process Validation Monitoring & Control",
  "Warehouse Management",
  "Laboratory Out of Specification",
  "Repacking",
];

const FeedBackInfo = (props) => {
  //console.log(props);
  // eslint-disable-next-line no-unused-vars
  const [adminUser, setAdminUser, user, setUser] = useContext(UserDataContext);
  //let auditInfo = props.auditTitle;
  // let source = props.source;
  let audit_number = props.auditNumber; //auditInfo.split("@#@")[0].split("-")[0].trim();
  //console.log(audit_number);
  const [open, setOpen] = useState(props.open);
  const [language, setLanguage] = useState("");
  const [loadingTranslate, setLoadingTranslate] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [ratingSend, setRatingSend] = useState(false);
  const [selections, setSelections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [additionalCommentAttachments, setAdditionalCommentAttachments] =
    useState([]);
  const [data, setData] = useState([]);
  const [audit, setAudit] = useState({});
  const [generalRemarks, setGeneralRemarks] = useState("");
  const [generalDescription, setGeneralDescription] = useState("");
  const [bindings, setBindings] = useState(false);
  const [validUser, setValidUser] = useState(false);
  const [role, setRole] = useState("");
  const [ratingVal1, setRatingVal1] = useState("low");
  const [ratingVal2, setRatingVal2] = useState("high");
  const [ratingText, setRatingText] = useState("Severity");
  const [cancelText, setCancelText] = useState("Cancel");
  const [sendText, setSendText] = useState("Save");
  const [header, setHeader] = useState("Feedback");
  const [translateText, setTranslateText] = useState("Translate");
  const [impactedSection, setImpactSectionText] = useState("Impacted Sections");
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
      props.close();
    }
  };
  useEffect(() => {
    getResponse();
  }, []);
  useEffect(() => {
    if (
      data.length &&
      !bindings &&
      audit.data_source !== undefined &&
      !ratingSend
    ) {
      setBindings(true);
      getSections(data, audit.data_source);
    }
  }, [data, audit]);

  const handleTranslate = async () => {
    let req_arr = [];
    for (let i in data) {
      let index = parseInt(i) + 1;
      req_arr.push({ [`question ${index}`]: data[i]["question"] });
      req_arr.push({ [`answer ${index}`]: data[i]["answer"] });
      req_arr.push({ [`rating ${index}`]: data[i]["rating"] });
    }
    req_arr.push({ translate: "Translate" });
    req_arr.push({ header: "Feedback" });
    req_arr.push({ send: "Save" });
    req_arr.push({ cancel: "Cancel" });
    req_arr.push({ rating: "Severity" });
    req_arr.push({ ratingVal1: "low" });
    req_arr.push({ ratingVal2: "high" });
    req_arr.push({ impactedSec: "Impacted Sections" });
    if (audit.general_remarks !== "") {
      req_arr.push({ general_remarks: audit.general_remarks });
    }
    req_arr.push({ additionalfdlable: "Additional Comments" });
    if (audit.general_description !== "") {
      req_arr.push({ general_description: audit.general_description });
    }
    req_arr.push({ generalfdlable: "General Comments" });
    if (role !== "") {
      req_arr.push({ stkrole: "Role" }, { role: role });
    }

    //console.log(req_arr);
    handleTranslateApi(req_arr);
  };
  const handleTranslateApi = (req_arr) => {
    if (language) {
      setLoadingTranslate(true);

      //let tmparr = [...data,{ additionalfeedback: audit.general_remarks}];
      let payload = { text: req_arr, lang: language };

      //let payload = { text: tmparr, lang: language };
      //console.log(payload);
      axios
        .post(
          `${rimaConstants.REACT_APP_API_URL}/api/v1/stakeholder_feedback_common/translate`,
          payload
        )
        .then((response) => {
          setLoadingTranslate(false);
          //setTranslations(response.data);
          let info = data;
          //console.log(response.data);
          //let trans_data = response.data;
          response.data.map((item, i) => {
            // console.log(index);
            //console.log(item[Object.keys(item)[index]]);
            Object.keys(item).map((key) => {
              //console.log(item);
              if (
                key !== "general_remarks" &&
                key !== "general_description" &&
                key !== "additionalfdlable" &&
                key !== "generalfdlable" &&
                key !== "stkrole" &&
                key !== "role" &&
                key !== "rating" &&
                key !== "ratingVal1" &&
                key !== "ratingVal2" &&
                key !== "impactedSec" &&
                key !== "send" &&
                key !== "cancel" &&
                key !== "header" &&
                key !== "translate"
              ) {
                let type = key.split(" ");
                info[parseInt(type[1]) - 1][type[0]] = item[key];
              } else if (key === "additionalfdlable") {
                document.getElementById(`additionalfdlable`).innerHTML =
                  item[key];
              } else if (key === "generalfdlable") {
                document.getElementById(`generalfdlable`).innerHTML = item[key];
              } else if (key === "stkrole") {
                document.getElementById(`stkrole`).innerHTML = item[key];
              } else if (key === "role") {
                document.getElementById(`role_des`).innerHTML = item[key];
                setRole(item[key]);
              } else if (key === "rating") {
                setRatingText(item[key]);
                // document.getElementById(`rating`).innerHTML = item[key] + ':';
              } else if (key === `ratingVal1`) {
                setRatingVal1(item[key]);
              } else if (key === `send`) {
                setSendText(item[key]);
              } else if (key === `cancel`) {
                setCancelText(item[key]);
              } else if (key === `ratingVal2`) {
                setRatingVal2(item[key]);
              } else if (key === `impactedSec`) {
                setImpactSectionText(item[key]);
              } else if (key === `general_remarks`) {
                setGeneralRemarks(item[key]);
                // setAudit((prev) => ({
                //   ...audit,
                //   general_remarks: item[key],
                // }));
              } else if (key === `general_description`) {
                setGeneralDescription(item[key]);
                // setAudit((prev) => ({
                //   ...audit,
                //   general_description: item[key],
                // }));
              } else if (key === `translate`) {
                setTranslateText(item[key]);
              } else if (key === `header`) {
                setHeader(item[key]);
              } else {
                setAudit((prev) => ({ ...audit, key: item[key] }));
              }
              return true;
            });
            return true;
          });
          setData([...info]);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      window.showMessage("Please select language", "warning");

      return false;
    }
  };
  const onViewClick = async (e, filename) => {
    filename =
      filename.split("/").length === 1
        ? `${
            audit.data_source.toLowerCase() === "vx" ? "Vx" : "Rx"
          }/${audit_number}/AuditSections/StakeHolderFeedback/StakeHolderResponse/${filename}`
        : filename;
    let format = filename.split("/").pop(0).split(".")[1];
    let type = window.getDocumentTypeByFileExtention(format);
    let url = `${window.BLOB_URL}/${rimaConstants.ARA_ROOT_CONTAINER}/${filename}${window.BLOB_CONATINER_SAS_READ_TOKEN}`;
    window.downloadFile(url, type, filename);
  };
  const getResponse = () => {
    setLoading(true);
    if (props.auditId && props.email) {
      axios
        .get(
          `${rimaConstants.REACT_APP_API_URL}/api/v1/stakeholder_feedback_common/view_response_question/${props.auditId}/${props.email}`
        )
        .then((response) => {
          if (response.data.status === "success") {
            setLoading(false);
            let prevData = {};
            prevData["audit_id"] = props.auditId;
            //console.log(response.data.data[0]);
            let audit = response.data.data[0];
            let selection = selections_vx;
            if (audit.data_source === "Rx") {
              selection = selections_rx;
            }

            setSelections([...selection]);
            setAudit(audit);
            setGeneralRemarks(audit["general_remarks"]);
            setGeneralDescription(audit["general_description"]);
            setValidUser(response.data.validUser);

            let questions = JSON.parse(
              response.data.data[0].stakeholder_feedback_response
            );
            prevData["questions"] = questions;
            let role_inx = -1;
            let role_inx1 = -1;
            questions.map((que, index) => {
              if (typeof que.rating === "undefined") {
                que.rating = "low";
                que.selection = [];
                que.loaded = false;
              } else {
                que.loaded ? setRatingSend(true) : setRatingSend(false);
                // setRatingSend(true);
                return true;
              }
              if (typeof que.role_description !== "undefined") {
                setRole(que.role_description);
                //console.log(index);
                role_inx = index;
              }
              if (typeof que.severity !== "undefined") {
                setRatingVal1(que?.severity?.low);
                setRatingVal2(que?.severity?.high);
                role_inx1 = index;
              }
              return true;
            });
            if (role_inx !== -1) {
              //console.log(role_inx);
              if (role_inx1 !== -1) {
                questions.splice(role_inx, 2);
              } else {
                questions.splice(role_inx, 1);
              }
            } else {
              if (role_inx !== -1) questions.splice(role_inx1, 1);
            }
            let files = `[]`;
            try {
              files = JSON.parse(response?.data?.data[0]?.general_attachments);
              prevData["files"] = files;
            } catch (e) {}
            //getSections(questions,audit.data_source);
            //console.log(questions);
            setData(questions);
            setAttachments(files);

            let files1 = `[]`;
            try {
              files1 = JSON.parse(
                response?.data?.data[0]?.AFR_general_attachments
              );
            } catch (e) {}
            setAdditionalCommentAttachments(files1);
            //console.log(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setOpen(false);
    }
  };
  const getSections = async (questions, source) => {
    let promises = [];
    //console.log(questions);
    questions.map((que, i) => {
      // //console.log('addAttachment ', document);
      promises.push(processSections(que.answer, i, source));
      return true;
    });
    let secRes = await Promise.all(promises);
    let tmpArr = [];
    secRes.map((value) => {
      if (value.status === "success") {
        tmpArr.push(value);
      }
      return true;
    });

    return tmpArr;
  };
  const processSections = (ans, i, source) => {
    return new Promise((resolve) => {
      if (ans) {
        let req_obj = {
          vaccine: source === "Vx" ? true : false,
          response: ans,
        };
        axios
          .post(
            `${rimaConstants.REACT_APP_API_URL}/api/v1/site_document_upload_page_common/bind_sections`,
            req_obj
          )
          .then((response) => {
            // let res_data = JSON.parse(response.data.result);
            let res_data = response.data;
            //console.log('info.selection 3 ',res_data);
            if (res_data?.result) {
              let value = res_data.result;
              let val = typeof value === "string" ? value.split(", ") : value;
              let tmp_data = data;
              tmp_data[i]["selection"] = val;
              tmp_data[i]["loaded"] = true;
              //console.log('info.selection 1 ',tmp_data);
              setData([...tmp_data]);
            } else {
              let tmp_data = data;
              tmp_data[i]["loaded"] = true;
              //console.log("info.selection 2 ", tmp_data);
              setData([...tmp_data]);
            }
            //console.log(response.data);
          })
          .catch((error) => {
            //console.log(error);
            //console.log('info.selection error ',error);
            let tmp_data = data;
            tmp_data[i]["loaded"] = true;
            setData([...tmp_data]);
            resolve(error);
          });
      } else {
        //console.log('info.selection NoEntry []');
        resolve([]);
      }
    });
  };
  const handleSubmit = () => {
    setLoadingSend(true);
    let data1 = JSON.parse(JSON.stringify(data));
    let req_data = {
      email: audit.email,
      stakeholder_feedback_response: data1,
      general_remarks: generalRemarks,
      general_attachments: additionalCommentAttachments, //attachments,
      general_description: generalDescription,
    };
    if (role) {
      req_data.stakeholder_feedback_response.push({ role_description: role });
    }
    req_data.stakeholder_feedback_response.push({
      severity: { low: ratingVal1, high: ratingVal2 },
    });
    try{
    axios
      .put(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/stakeholder_feedback/update_question_response/${audit.audit_id}`,
        req_data
      )
      .then((response) => {
        setLoadingSend(false);
        if (response.data.status === "success") {
          props.refreshInferences();
          props.updateTableStakeholderFeedback();

          window.showMessage("Review response saved", "success");
          // setTimeout(() => {
          setOpen(false);
          props.close();
          // }, 3000);
        } else {
          window.showMessage(response.data.data[0]["msg"], "warning");
        }
      })
      .catch(function (error) {
        setLoadingSend(false);
        console.log(error);
      });
    }catch(e){console.log(e)}
  };
  const handleRating = (value, index, type) => {
    if (validUser) {
      let tmp_data = data;
      tmp_data[index]["rating"] = value;
      setData([...tmp_data]);
    }
  };
  const handleChange = (event, index) => {
    const {
      target: { value },
    } = event;
    let val = typeof value === "string" ? value.split(",") : value;
    let tmp_data = data;
    //console.log(tmp_data);
    tmp_data[index]["selection"] = val;
    //console.log(tmp_data);
    setData([...tmp_data]);
  };

  const renderHtml = (html) => {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"md"}
      onClose={handleClose}
      paperProps={{
        style: { borderRadius: 2 },
      }}
      aria-labelledby="responsive-dialog-title"
    >
      {/*<FeedBackForm 
                close={handleClose}
        />*/}
      <div className="apply-custom-btn">
        <DialogTitle>{header}</DialogTitle>
        <DialogContent>
          <div className="modalContent">
            {loading ? window.showLoader() : window.hideLoader()}
            <Grid container>
              <Grid item xs={3} sx={{ m: 0, padding: "19px 0 0 0" }}>
                <FormControl sx={{ m: 0, p: 0 }}>
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      lineHeight: "0.0em",
                      overflow: "visible",
                      top: "6px",
                    }}
                  >
                    Language
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={language}
                    label="Language"
                    onChange={(e) => setLanguage(e.target.value)}
                    sx={{
                      width: "170px",
                      height: "38px",
                    }}
                  >
                    {Object.keys(window.translatorLanguageSupport()).map(
                      (key) => {
                        return (
                          <MenuItem key={key} value={key}>
                            {window.translatorLanguageSupport()[key]}
                          </MenuItem>
                        );
                      }
                    )}
                    {/*<MenuItem value="zh-hk">Chinese (Hong Kong)</MenuItem>*/}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sx={{ m: 0, p: 0 }}>
                <Box
                  sx={{
                    m: 0,
                    padding: "22px 0 0 0",
                  }}
                >
                  <FormControlLabel
                    sx={{
                      display: "none",
                    }}
                    control={
                      <Switch
                        checked={loadingTranslate}
                        onChange={() => setLoadingTranslate(!loadingTranslate)}
                        name="loading"
                        color="primary"
                      />
                    }
                    label="Loading"
                  />
                  <Box sx={{ "& > button": { m: 0 } }}>
                    <LoadingButton
                      onClick={handleTranslate}
                      endIcon={<SubtitlesIcon />}
                      loading={loadingTranslate}
                      loadingPosition="end"
                      variant="contained"
                      size="small"
                      sx={{ textTransform: "none" }}
                    >
                      {translateText}
                    </LoadingButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <div className="wrap">
              {role !== "" ? (
                <div style={{ width: "100%" }}>
                  <div style={{ float: "left" }}>
                    <h3 style={{ margin: 0 }}>
                      <strong id="stkrole">Role : </strong>
                    </h3>
                  </div>
                  <div style={{ paddingLeft: "20px", marginLeft: "39px" }}>
                    <p className="feedback-text" id="role_des">
                      {role}
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
              {data.map((info, index) =>
                info?.role_description === undefined ? (
                  //return (
                  <>
                    <h4>
                      <Stack direction="row" spacing={1}>
                        <strong>{index + 1}.</strong>
                        <strong className="feedback-text">
                          {renderHtml(window.nl2br(info.question, true, true))}
                        </strong>
                      </Stack>
                    </h4>
                    <Box>
                      <p className="feedback-text">
                        {renderHtml(window.nl2br(info.answer, true, true))}
                      </p>
                    </Box>

                    <div className="tags-container tagsinfo">
                      <ul>
                        {info?.files?.map((file) => (
                          <li
                            key={window.getUuidv4()}
                            className="file-view"
                            onClick={(event) => onViewClick(event, file)}
                          >
                            {file
                              ? file.split("/")[file.split("/").length - 1]
                              : ""}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <Grid
                      container
                      sx={{ mt: 3, display: user.role === 3 ? "none" : "" }}
                    >
                      <Grid item xs={4}>
                        <FormControl>
                          <Box display="flex" justifyContent="flex-start">
                            <FormLabel
                              sx={{ mt: 1, display: "inline-flex" }}
                              id="rating"
                            >
                              <div
                                style={{
                                  marginRight: "10px",
                                }}
                              >
                                <Tooltips
                                  html={false}
                                  iconColor="#15717D"
                                  placement="top-end"
                                  title="Auditors to mark appropriately"
                                />
                              </div>
                              <div
                                style={{
                                  paddingTop: "2px",
                                }}
                              >
                                {ratingText}:
                              </div>
                            </FormLabel>
                            <div className="toggle_sev">
                              <a
                                className={
                                  info?.rating === ratingVal1 ? "active" : ""
                                }
                                onClick={() =>
                                  handleRating(ratingVal1, index, "rating")
                                }
                                disabled={!validUser}
                              >
                                {ratingVal1}
                              </a>
                              <a
                                className={
                                  info?.rating === ratingVal2 ? "active" : ""
                                }
                                onClick={() =>
                                  handleRating(ratingVal2, index, "rating")
                                }
                                disabled={!validUser}
                              >
                                {ratingVal2}
                              </a>
                            </div>
                          </Box>
                          {/*<InputLabel id="demo-simple-select-label">
                          Severity
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={info.rating}
                          label="Severity"
                          onChange={(e) =>
                            handleRating(e.target.value, index, "rating")
                          }
                          sx={{
                            height: "45px",
                            width: "20ch !important",
                            ml: "0px !important",
                          }}
                          disabled={ratingSend || !validUser}
                        >
                          <MenuItem value="low">Low</MenuItem>
                          <MenuItem value="medium">Medium</MenuItem>
                          <MenuItem value="high">High</MenuItem>
                        </Select>*/}
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl>
                          <Box display="flex" justifyContent="flex-start">
                            <InputLabel id="demo-multiple-checkbox-label">
                              {impactedSection}
                            </InputLabel>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              multiple
                              displayEmpty
                              value={info.selection}
                              onChange={(e) => handleChange(e, index)}
                              sx={{
                                height: "45px",
                                width: "25ch !important",
                                ml: "5px !important",
                              }}
                              input={
                                <OutlinedInput label="Impacted Sections" />
                              }
                              renderValue={(selected) => {
                                if (selected?.length === 0) {
                                  return <em></em>;
                                }

                                return selected?.join(", ");
                              }}
                              MenuProps={MenuProps}
                              inputProps={{ "aria-label": "Without label" }}
                              disabled={!validUser}
                            >
                              <MenuItem
                                style={{ padding: "0 10px" }}
                                disabled
                                value=""
                              ></MenuItem>
                              {selections.map((selection) => (
                                <MenuItem
                                  style={{ padding: "0px", height: "30px" }}
                                  key={selection}
                                  value={selection}
                                >
                                  <Checkbox
                                    checked={
                                      info?.selection?.indexOf(selection) > -1
                                    }
                                    //checked={false}
                                  />
                                  <ListItemText primary={selection} />
                                </MenuItem>
                              ))}
                            </Select>
                            {info?.loaded !== undefined && !info?.loaded ? (
                              <IconButton>
                                <CircularProgress size={25} thickness={4} />
                              </IconButton>
                            ) : (
                              ""
                            )}
                          </Box>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  ""
                )
              )}
              {typeof generalRemarks !== "undefined" ? (
                <div style={{ paddingTop: "10px" }}>
                  <h3>
                    <strong id="additionalfdlable">Additional Comments</strong>
                  </h3>
                  <p className="feedback-text">
                    {renderHtml(window.nl2br(generalRemarks, true, true))}
                  </p>
                </div>
              ) : (
                ""
              )}

              <div className="tags-container">
                <ul>
                  {additionalCommentAttachments &&
                    Array.isArray(additionalCommentAttachments) &&
                    additionalCommentAttachments.map((file) => (
                      <li
                        key={window.getUuidv4()}
                        className="file-view"
                        onClick={(event) => onViewClick(event, file)}
                      >
                        {file
                          ? file.split("/")[file.split("/").length - 1]
                          : ""}
                      </li>
                    ))}
                </ul>
              </div>

              {typeof generalDescription !== "undefined" ? (
                <div style={{ paddingTop: "10px" }}>
                  <h3>
                    <strong id="generalfdlable">General Comments</strong>
                  </h3>
                  <p className="feedback-text">
                    {renderHtml(window.nl2br(generalDescription, true, true))}
                  </p>
                </div>
              ) : (
                ""
              )}

              <div className="tags-container">
                <ul>
                  {attachments &&
                    Array.isArray(attachments) &&
                    attachments.map((file) => (
                      <li
                        key={window.getUuidv4()}
                        className="file-view"
                        onClick={(event) => onViewClick(event, file)}
                      >
                        {file
                          ? file.split("/")[file.split("/").length - 1]
                          : ""}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{ mr: 1, textTransform: "none" }}
          >
            {cancelText}
          </Button>
          <LoadingButton
            onClick={handleSubmit}
            endIcon={<SaveIcon />}
            loading={loadingSend}
            loadingPosition="end"
            variant="contained"
            sx={{ textTransform: "capitalize", mr: 4 }}
            disabled={!validUser}
          >
            {sendText}
          </LoadingButton>
        </DialogActions>
      </div>
    </Dialog>
  );
};
// export default FeedBackInfo;
export default withAITracking(reactPlugin, FeedBackInfo);
