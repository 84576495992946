// import { blue, pink } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const palette = {
  primary: {
    main: "#15717D",
    contrastText: "#fff",
    active: "#15717dad",
    disabledColor: "#595959",
    disabledBackGroundColor: "#b3b3b3",
    iconColor: "#15717D",
    buttonIconColor: "#fff",
    gridHeaderBgColor: "#fff",
  },
  secondary: {
    main: "#8A8474",
  },
};

export const appTheme = createTheme({
  // palette: {
  //   type: "light",
  //   primary: {
  //     main: "#15717D",
  //     contrastText: "#fff",
  //     active: "#15717dad",
  //   },
  //   secondary: {
  //     main: "#8A8474",
  //   },
  //   // text: {
  //   //   primary: "#15717dad",
  //   //   secondary: "#15717dad",
  //   // },
  // },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#15717D",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
      active: "#15717dad",
    },
    secondary: {
      light: "#15717dad",
      main: "#8A8474",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffcc00",
    },
    // Provide every color token (light, main, dark, and contrastText) when using
    // custom colors for props in Material UI's components.
    // Then you will be able to use it like this: `<Button color="custom">`
    // (For TypeScript, you need to add module augmentation for the `custom` value)
    custom: {
      light: "#ffa726",
      main: "#f57c00",
      dark: "#ef6c00",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    fontSize: 11,
    htmlFontSize: 17,
    fontFamily: `"Roboto Medium", "Roboto Regular", "Roboto", sans-serif !important`,
    lineHeight: "1.14286rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontVariant: "small-caps",
    letterSpacing: "normal",
    fontStyle: "normal",
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ ownerState, theme }) => {
          return {
            "&.MuiTooltip-tooltip": {
              "&.MuiTooltip-tooltipPlacementBottom": {
                marginTop: "2px",
              },
              "&.MuiTooltip-tooltipPlacementTop": {
                marginBottom: "2px",
              },
              "&.MuiTooltip-tooltipPlacementLeft": {
                marginRight: "2px",
              },
              "&.MuiTooltip-tooltipPlacementRight": {
                marginLeft: "2px",
              },
            },
          };
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            pointerEvents: "none",
            backgroundColor: "rgba(227, 227, 227, 1)",
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon": {
            color: palette.primary.buttonIconColor + " !important",
            opacity: "1 !important",
            "&:hover": {
              opacity: "1 !important",
              color: palette.primary.buttonIconColor + " !important",
            },
          },
          "& .MuiDataGrid-columnHeader .MuiSvgIcon-root": {
            color: palette.primary.buttonIconColor + " !important",
            opacity: "1 !important",
            "&:hover": {
              opacity: "1 !important",
              color: palette.primary.buttonIconColor + " !important",
            },
          },
          "& .MuiDataGrid-cell": {
            whiteSpace: "pre-line !important",
            lineHeight: "1.2em !important",
            maxHeight: "52px !important",
            display: "-webkit-box !important",
            webkitLineClamp: 2,
            webkitBoxOrient: "horizontal",
            webkitAlignIems: "center",
            alignItems: "center",
            overflow: "hidden !important",
          },
          "& .MuiDataGrid-row": {
            maxHeight: "none !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: palette.primary.main,
            color: palette.primary.gridHeaderBgColor,
          },
          // "& .MuiDataGrid-row.Mui-even": {
          //   backgroundColor: "#EFEFED",
          // },
          "& .MuiDataGrid-row:nth-child(even)": {
            backgroundColor: "#EFEFED",
          },
          "& .MuiDataGrid-row:nth-child(odd)": {
            backgroundColor: "#fff",
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: "left",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            "& .MuiSvgIcon-root": {
              color: palette.primary.buttonIconColor,
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          '&[aria-selected="true"]': {
            color: palette.primary.contrastText,
            backgroundColor: palette.primary.active + " !important",
          },

          "&:hover": {
            color: palette.primary.contrastText,
            backgroundColor: palette.primary.active + " !important",
          },
          //backgroundColor: "#fff",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: palette.primary.main,
          "&.Mui-checked": {
            color: palette.primary.main,
          },
          "&.Mui-disabled": {
            pointerEvents: "none",
            color: palette.primary.disabledColor,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: palette.primary.main,
          "&.Mui-checked": {
            color: palette.primary.main,
          },
          "&.Mui-disabled": {
            pointerEvents: "none",
            color: palette.primary.disabledColor,
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          "& .MuiAlert-standardInfo .MuiSvgIcon-root": {
            color: "#19B0F4",
          },
          "& .MuiAlert-standardError .MuiSvgIcon-root": {
            color: "#F0625F",
          },
          "& .MuiAlert-standardWarning .MuiSvgIcon-root": {
            color: "#FFA623",
          },
          "& .MuiAlert-standardSuccess .MuiSvgIcon-root": {
            color: "#64B968",
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: palette.primary.iconColor,
          "&:hover": {
            color: palette.primary.active,
          },
          "&.Mui-disabled": {
            pointerEvents: "none",
            color: palette.primary.disabledColor,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          // width:"1rem",
          // height:"1rem",
          color: palette.primary.main,
          "&:hover": {
            color: palette.primary.active,
          },
          "&.Mui-disabled": {
            pointerEvents: "none",
            color: palette.primary.disabledColor,
            "& .MuiSvgIcon-root": {
              color: palette.primary.disabledColor,
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "2rem",
          padding: "8px 18px !important",
          fontSize: "0.65714rem !important",
          fontWeight: "100 !important",
          letterSacing: "1.2px !important",
          lineHeight: "1.14286rem !important",
          boxShadow: "none !important",
          // textTransform: "capitalize !important",
          textTransform: "uppercase !important",
          "&:hover:not(.MuiButton-text)": {
            backgroundColor: palette.primary.active,
          },
          "&.Mui-disabled": {
            pointerEvents: "none",
            backgroundColor: palette.primary.disabledBackGroundColor,
            color: palette.primary.disabledColor,
            "& .MuiSvgIcon-root": {
              color: palette.primary.disabledColor,
            },
          },
          // "&:not(.MuiButton-text or .Mui-disabled) .MuiSvgIcon-root": {
          //   color: palette.primary.buttonIconColor,
          // },
          "& .MuiSvgIcon-root": {
            color: palette.primary.buttonIconColor,
          },
        },
      },
      contained: {
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        "&:hover": {
          backgroundColor: palette.primary.active,
        },
      },
      outlined: {
        color: palette.primary.main,
        "&:hover": {
          backgroundColor: palette.primary.active,
        },
      },
      standard: {
        color: palette.primary.main,
        "&:hover": {
          backgroundColor: palette.primary.active,
        },
      },
      text: {
        color: palette.primary.main,
        "&:hover": {
          color: palette.primary.active,
        },
      },
    },
  },
});
