import React, { useEffect, useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { useHistory } from "react-router-dom";
import { loginRequest } from "../../authConfigmasl";
import axios from "axios";
import * as rimaConstants from "../../rimaconstants";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import moment from "moment";
import "./commonFunctions.css";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import http from "http";
import https from "https";
import { v4 as uuidv4 } from "uuid";
import { encode, decode } from "html-entities";

const httpAgent = new http.Agent({
  keepAlive: true,
  keepAliveMsecs: 30000,
  maxFreeSockets: 10,
  maxSockets: 50,
});

const httpsAgent = new https.Agent({
  keepAlive: true,
  keepAliveMsecs: 30000,
  maxFreeSockets: 10,
  maxSockets: 50,
  unauthorisedrejection: true,
});

const axiosCreate = {
  httpAgent,
  httpsAgent,
  // transformResponse: (res) => res,
};

const CommonFunctions = () => {
  sessionStorage.setItem("userinfo", JSON.stringify({ admin_user: "0" }));
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [showLoader, setShowLoader] = useState(false);
  const [showLoaderDependency, setShowLoaderDependency] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [successMsg, setSuccessMsg] = React.useState("");
  const [alertClass, setAlertClass] = React.useState("info");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // axios.defaults.headers.common['test-x'] = 'testtocken';
  window.getUuidv4 = () => {
    return uuidv4();
  };
  window.showMessage = (message, type, isToastyfy = false) => {
    switch (type) {
      case "success":
        if (isToastyfy) {
          toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "toastyfycustom",
          });
        } else {
          setSuccessMsg(message);
          setAlertClass(type);
          setOpen(true);
        }
        break;
      case "error":
        if (isToastyfy) {
          toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "toastyfycustom",
          });
        } else {
          setSuccessMsg(message);
          setAlertClass(type);
          setOpen(true);
        }
        break;
      case "warning":
        if (isToastyfy) {
          toast.warn(message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "toastyfycustom",
          });
        } else {
          setSuccessMsg(message);
          setAlertClass(type);
          setOpen(true);
        }
        break;
      case "info":
        if (isToastyfy) {
          toast.info(message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "toastyfycustom",
          });
        } else {
          setSuccessMsg(message);
          setAlertClass(type);
          setOpen(true);
        }
        break;
      default:
        if (isToastyfy) {
          toast(message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "toastyfycustom",
          });
        } else {
          setSuccessMsg(message);
          setAlertClass("");
          setOpen(true);
        }
        break;
    }
  };

  let resetTime = true;
  const isTokenExpired = (expTimestamp) => {
    let currTimestamp = moment().utc().unix();
    let timeDiff = parseInt((expTimestamp - currTimestamp) / 60);
    if (timeDiff <= 1) {
      if (resetTime) {
        if (timeDiff < 0) {
          let tmp = Math.abs(timeDiff);
          account.idTokenClaims.exp =
            account.idTokenClaims.exp + tmp * 60 + 60 * 59;
        } else {
          account.idTokenClaims.exp = account.idTokenClaims.exp + 60 * 59;
        }
        resetTime = false;
        return true;
      } else {
        return false;
      }
    } else {
      resetTime = true;
    }
    return false;
  };

  const getCurrentModuleName = () => {
    let queryString = window.location.href;
    let currentModule = `NA`;

    if (queryString.indexOf("/asm") > 0) {
      currentModule = `ASM`;
    } else if (
      queryString.indexOf("/ara") > 0 ||
      queryString.indexOf("/feedbackresponse") > 0
    ) {
      currentModule = `ARA`;
    } else if (queryString.indexOf("/admin") > 0) {
      currentModule = `ADMIN`;
    }
    return currentModule;
  };

  window.getCurrentModuleName = getCurrentModuleName;

  window.getAccessToken = async () => {
    return new Promise((resolve, reject) => {
      return instance
        .acquireTokenSilent({
          ...loginRequest,
          account: account,
          forceRefresh: isTokenExpired(account.idTokenClaims.exp),
        })
        .then((response) => {
          resolve(response.idToken);
        })
        .catch((error) => {
          return instance
            .acquireTokenRedirect(loginRequest) //renewIdTokenRequest
            .then((response) => {
              resolve(response.idToken);
            });
        });
    });
  };

  axios.interceptors.request.use(
    (config) => {
      // const renewIdTokenRequest = {
      //   scopes: process.env.REACT_APP_AZURE_CLIENTID,
      // };
      return instance
        .acquireTokenSilent({
          ...loginRequest,
          account: account,
          forceRefresh: isTokenExpired(account.idTokenClaims.exp),
        })
        .then((response) => {
          config = { ...config, ...axiosCreate };

          if (config.url.indexOf(rimaConstants.REACT_APP_API_URL) > -1) {
            config.headers["Authorization"] = "Bearer " + response.idToken;
            config.headers["Featureid"] = window.getFeatureID();
            config.headers["Currentmodule"] = getCurrentModuleName();
            // console.log("config ", config);
          }
          return config;
        })
        .catch((error) => {
          // if it is an InteractionRequired error, send the same request in an acquireToken call
          //if (error instanceof InteractionRequiredAuthError) {
          return instance
            .acquireTokenRedirect(loginRequest) //renewIdTokenRequest
            .then((response) => {
              //console.log(response);
              if (config.url.indexOf(rimaConstants.REACT_APP_API_URL) > -1) {
                config.headers["Authorization"] = "Bearer " + response.idToken;
                config.headers["Featureid"] = window.getFeatureID();
                config.headers["Currentmodule"] = getCurrentModuleName();
              }
              return config;
            });
          // }
        });
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  let history = useHistory();

  axios.interceptors.response.use(
    (response) => {
      //console.log("response.status ===>>> " + response.status);
      //console.log(response);
      return response;
    },
    function (error) {
      try {
        //toast(error.message);
        //console.log("error.response.status ===>> " + error.response.status);
        if (axios.isCancel(error)) {
          console.error(error.message);
        } else {
          // handle HTTP error...

          let showErrorMsg = true;
          if (error.response === undefined) {
            instance.logoutRedirect().catch((e) => {
              console.error(e);
            });
            localStorage.removeItem("userDocsUploaded");
          }
          let doLogin = [403];
          let redirectToInvaliduser = [401];
          let badRequest = [502];
          let dbErrors = [503];

          if (redirectToInvaliduser.includes(error?.response?.status)) {
            history.push("/error");
          }

          if (badRequest.includes(error?.response?.status)) {
            // window.showMessage(
            //   "SQL Injection errors detected. Please check the names of the documents your are trying to process",
            //   "error"
            // );
            history.push("/error?type=1");
            showErrorMsg = false;
          }

          if (dbErrors.includes(error?.response?.status)) {
            //console.log('error.response ',error.response,);
            // window.showMessage(error.response.data.msg, "error");
            history.push("/error?type=1");
            showErrorMsg = false;
          }

          if ([400].includes(error?.response?.status)) {
            window.showMessage("Access denied", "error");
            showErrorMsg = false;
          }

          if (doLogin.includes(error?.response?.status)) {
            instance.logoutRedirect().catch((e) => {
              console.error(e);
            });
            localStorage.removeItem("userDocsUploaded");
          }

          if (showErrorMsg && error?.message) {
            window.showMessage(error?.message, "error");
          }
        }
      } catch (e) {
        console.log(e);
        // instance.logoutRedirect().catch((e) => {
        //     console.error(e);
        //   });
        localStorage.removeItem("userDocsUploaded");
      }
    }
  );

  useEffect(() => {
    axios
      .get(`${rimaConstants.REACT_APP_API_URL}/api/v1/user/configuration`)
      .then((response) => {
        if (response?.data) {
          window.BLOB_URL = response?.data?.BLOB_URL
            ? response.data.BLOB_URL
            : "";
          window.BLOB_CONATINER_SAS_READ_TOKEN = response?.data
            ?.BLOB_CONATINER_SAS_READ_TOKEN
            ? response.data.BLOB_CONATINER_SAS_READ_TOKEN
            : "";
          window.QUALITY_KPIS_DASHBOARD_URLS =
            response.data?.QUALITY_KPIS_DASHBOARD_URLS;
        } else {
          window.BLOB_URL = "";
          window.BLOB_CONATINER_SAS_READ_TOKEN = "";
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  window.nl2br = (str, is_xhtml) => {
    const breakTag =
      is_xhtml || typeof is_xhtml === "undefined" ? "<br " + "/>" : "<br>"; // Adjust comment to avoid issue on phpjs.org display

    return (str + "").replace(
      /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
      "$1" + breakTag + "$2"
    );
  };

  window.parseQueryString = (url, key = "") => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (key) {
      return params[`${key}`] ? params[`${key}`] : "";
    } else {
      return params;
    }
  };

  window.downloadFile = async (url, type, filename, download = false) => {
    // console.log("url ", url);
    // console.log("type ", type);
    // console.log("filename ", filename);
    // console.log("download ", download);
    const response = await fetch(url, {
      method: "GET",
    });
    // console.log(type);
    const blob = await response.blob();
    const newBlob = new Blob([blob], {
      type: type,
    });
    const blobUrl = window.URL.createObjectURL(newBlob);
    // console.log(blobUrl);
    if (response.status === 200) {
      if (type !== "application/pdf" || download) {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = blobUrl;
        a.download = filename.split("/")[filename.split("/").length - 1]; // you need to write the extension of file here
        a.click();
      } else {
        window.open(blobUrl);
      }
      window.URL.revokeObjectURL(blobUrl);
    } else {
      window.showMessage("Document not found", "warning");
    }
  };

  window.getDocumentTypeByFileExtention = (format) => {
    let type = "";
    switch (format.toLowerCase()) {
      case "pdf":
        type = "application/pdf";
        break;
      case "doc":
        type = "application/msword";
        break;
      case "dot":
        type = "application/msword";
        break;
      case "docx":
        type =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        break;
      case "dotx":
        type =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.template";
        break;
      case "docm":
        type = "application/vnd.ms-word.document.macroEnabled.12";
        break;
      case "dotm":
        type = "application/vnd.ms-word.template.macroEnabled.12";
        break;
      case "xls":
        type = "application/vnd.ms-excel";
        break;
      case "xlt":
        type = "application/vnd.ms-excel";
        break;
      case "xla":
        type = "application/vnd.ms-excel";
        break;
      case "xlsx":
        type =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
      case "xltx":
        type =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.template";
        break;
      case "xlsm":
        type = "application/vnd.ms-excel.sheet.macroEnabled.12";
        break;
      case "xltm":
        type = "application/vnd.ms-excel.template.macroEnabled.12";
        break;
      case "xlam":
        type = "application/vnd.ms-excel.addin.macroEnabled.12";
        break;
      case "xlsb":
        type = "application/vnd.ms-excel.sheet.binary.macroEnabled.12";
        break;
      case "ppt":
        type = "application/vnd.ms-powerpoint";
        break;
      case "pot":
        type = "application/vnd.ms-powerpoint";
        break;
      case "pps":
        type = "application/vnd.ms-powerpoint";
        break;
      case "ppa":
        type = "application/vnd.ms-powerpoint";
        break;
      case "pptx":
        type =
          "application/vnd.openxmlformats-officedocument.presentationml.presentation";
        break;
      case "potx":
        type =
          "application/vnd.openxmlformats-officedocument.presentationml.template";
        break;
      case "ppsx":
        type =
          "application/vnd.openxmlformats-officedocument.presentationml.slideshow";
        break;
      case "ppam":
        type = "application/vnd.ms-powerpoint.addin.macroEnabled.12";
        break;
      case "pptm":
        type = "application/vnd.ms-powerpoint.presentation.macroEnabled.12";
        break;
      case "potm":
        type = "application/vnd.ms-powerpoint.template.macroEnabled.12";
        break;
      case "ppsm":
        type = "application/vnd.ms-powerpoint.slideshow.macroEnabled.12";
        break;
      case "mdb":
        type = "application/vnd.ms-access";
        break;
      default:
        type = format;
        break;
    }

    return type;
  };

  window.getApplyQuickFilterFn = (value) => {
    return (params) => {
      return params.value?.toString().indexOf(value) > -1 ? true : false;
    };
  };

  window.getDateGridBinding = (ts, addTime = false) => {
    if (ts) {
      return (
        moment(window.getDate(ts)).utc().unix() +
        " " +
        window.getDate(ts, addTime)
      );
      // if(addTime){
      //   return moment(ts).format('MM/DD/YYYY h:mm:ss');
      // }else{
      //   return moment(ts).format('MM/DD/YYYY');
      // }
    } else {
      return null;
    }
  };

  window.getDate = (ts, addTime = false) => {
    const monthsAbbreviation = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (!ts) {
      return "";
    }
    try {
      if (moment(ts).isValid()) {
        let date_ob = new Date(ts);
        let date = date_ob.getDate();
        let month = date_ob.getMonth() + 1;
        let year = date_ob.getFullYear();
        month = monthsAbbreviation[month - 1];
        date = date <= 9 ? "0" + date : date;
        // prints date & time in YYYY-MM-DD format
        let cd = date + "-" + month + "-" + year;
        if (addTime) {
          cd += ` ${date_ob.getHours().toString().padStart(2, "0")}:${date_ob
            .getMinutes()
            .toString()
            .padStart(2, "0")}:${date_ob
            .getSeconds()
            .toString()
            .padStart(2, "0")}`;
        }
        return cd;
      } else {
        return ts;
      }
    } catch (e) {
      return ts;
    }
  };

  window.addMonthToDate = (dt, montsToAdd, type) => {
    if (type === "start") {
      return window.getDate(moment(dt).subtract(parseInt(montsToAdd), "M"));
    } else {
      return window.getDate(moment(dt).add(parseInt(montsToAdd), "M"));
    }
  };

  window.allowedStartDate = {
    VX: [
      "SOP Index",
      "Document Management & Control",
      "Monitoring & Reviewing of Environmental Control & Utilities",
      "Deviation Handling",
      "Deviations",
      "Corrective & Preventive Actions",
      "CAPAs",
      "Cleaning Validation & Verification",
      "Sterile Process Validation, Monitoring & Control",
      "Data Management & Control",
      "Warehouse Management",
      "Change Control",
      "Laboratory Out of Specification",
      "Repacking",
      "Quality Alerts and Bulletin Management",
      "Change Controls",
      "Maintenance & Calibration",
    ],
    RX: [
      "SOP Index",
      "Document Management & Control",
      "Monitoring & Reviewing of Environmental Control & Utilities",
      "Deviation Handling",
      "Deviations",
      "Corrective & Preventive Actions",
      "CAPAs",
      "Sterile Process Validation, Monitoring & Control",
      "Data Management & Control",
      "Change Control",
      "Cleaning Validation & Verification",
      "Warehouse Management",
      "Laboratory Out of Specification",
      "Repacking",
      "Quality Alerts and Bulletin Management",
      "Change Controls",
      "Maintenance & Calibration",
    ],
  };

  window.disableExportWithDataGridQDM = {
    VX: [
      "Document Management & Control",
      "Monitoring & Reviewing of Environmental Control & Utilities",
      "SOP Index",
      "Document Management & Control",
      "Deviation Handling",
      "Corrective & Preventive Actions",
      "Quality Plan",
      "List of Products",
      "Management Monitoring & Independent Business Monitoring",
      "Management Review By Quality Council",
      "Sterile Process Validation, Monitoring & Control",
      "Cleaning Validation & Verification",
      "Data Management & Control",
      "Quality KPIS",
      "Stability",
      "Product Lifecycle Management",
      "Regulatory and Quality Compliance",
      "Overview of Facilities Design",
      "Product & Technology Transfer",
      "Organisation, Personnel Training and Management",
      "Warehouse Management",
      "Change Control",
      "Laboratory Out of Specification",
      "Quality Alerts and Bulletin Management",
      "Maintenance & Calibration",
    ],
    RX: [
      "Quality Plan",
      "List of Products",
      "Management Monitoring & Independent Business Monitoring",
      "Management Review By Quality Council",
      "SOP Index",
      "General Information",
      "Quality KPIS",
      "PowerBI Quality Dashboard",
      "Stability",
      "Product Lifecycle Management",
      "Overview of Facilities Design",
      "Regulatory and Quality Compliance",
      "Control of Cross Contamination by Allergenic. Genotoxic and Highly Potent Materials and Products",
      "Organisation, Personnel Training and Management",
      "Deviation Handling",
      "Corrective & Preventive Actions",
      "Data Management & Control",
      "Change Control",
      "Sterile Process Validation, Monitoring & Control",
      "Cleaning Validation & Verification",
      "Monitoring & Reviewing of Environmental Control & Utilities",
      "Warehouse Management",
      "Laboratory Out of Specification",
      "Repacking",
      "Quality Alerts and Bulletin Management",
      "Maintenance & Calibration",
      "Document Management & Control",
    ],
  };

  window.allowUnstructureComponent = {
    VX: [
      "Quality Plan",
      "List of Products",
      "Management Monitoring & Independent Business Monitoring",
      "Management Review By Quality Council",
      "Stability",
      "Product Lifecycle Management",
      "Regulatory and Quality Compliance",
      "Overview of Facilities Design",
      "Product & Technology Transfer",
      "Organisation, Personnel Training and Management",
    ],
    RX: [
      "Quality Plan",
      "List of Products",
      "Management Monitoring & Independent Business Monitoring",
      "Management Review By Quality Council",
      "General Information",
      "Stability",
      "Product Lifecycle Management",
      "Overview of Facilities Design",
      "Regulatory and Quality Compliance",
      "Control of Cross Contamination by Allergenic. Genotoxic and Highly Potent Materials and Products",
      "Organisation, Personnel Training and Management",
    ],
  };

  window.fourceAllowInsightPannel = {
    VX: ["Quality KPIS"],
    RX: ["Quality KPIS", "PowerBI Quality Dashboard"],
  };

  window.allowedEndDate = {
    VX: [
      "SOP Index",
      "Document Management & Control",
      "Monitoring & Reviewing of Environmental Control & Utilities",
      "Deviation Handling",
      "Deviations",
      "Corrective & Preventive Actions",
      "CAPAs",
      "Sterile Process Validation, Monitoring & Control",
      "Cleaning Validation & Verification",
      "Data Management & Control",
      "Warehouse Management",
      "Change Control",
      "Laboratory Out of Specification",
      "Repacking",
      "Quality Alerts and Bulletin Management",
      "Change Controls",
      "Maintenance & Calibration",
    ],
    RX: [
      "Document Management & Control",
      "Monitoring & Reviewing of Environmental Control & Utilities",
      "Deviation Handling",
      "Deviations",
      "Corrective & Preventive Actions",
      "CAPAs",
      "Data Management & Control",
      "Change Control",
      "Sterile Process Validation, Monitoring & Control",
      "Cleaning Validation & Verification",
      "Warehouse Management",
      "Laboratory Out of Specification",
      "Repacking",
      "Quality Alerts and Bulletin Management",
      "Change Controls",
      "Maintenance & Calibration",
    ],
  };

  window.hideEndDate = {
    VX: [],
    RX: ["SOP Index"],
  };

  window.insights = {
    VX: [
      "Monitoring & Reviewing of Environmental Control & Utilities",
      "Deviation Handling",
      "Corrective & Preventive Actions",
      "Sterile Process Validation, Monitoring & Control",
      "Cleaning Validation & Verification",
      "Data Management & Control",
      "Warehouse Management",
      "Change Control",
      "Laboratory Out of Specification",
      "Repacking",
      "Quality Alerts and Bulletin Management",
      "Maintenance & Calibration",
    ],
    RX: [
      "Monitoring & Reviewing of Environmental Control & Utilities",
      "Deviation Handling",
      "Corrective & Preventive Actions",
      "Data Management & Control",
      "Change Control",
      "Sterile Process Validation, Monitoring & Control",
      "Cleaning Validation & Verification",
      "Warehouse Management",
      "Laboratory Out of Specification",
      "Repacking",
      "Quality Alerts and Bulletin Management",
      "Maintenance & Calibration",
    ],
  };

  window.templateSectionNames = {
    VX: {
      "Monitoring & Reviewing of Environmental Control & Utilities":
        "Environmental Monitoring for Sterile and Biological Products",
      "Document Management & Control": "Document Management and Control",
      "Cleaning Validation & Verification":
        "Cleaning Validation and Verification Lifecycle",
      "Sterile Process Validation, Monitoring & Control":
        "Sterile Production and Control",
      "Data Management & Control": "Data Management and Control",
      "Incident  Management": "Incident Management",
      "Management Monitoring & Independent Business Monitoring": "Internal Business Monitoring (IBM) (Also known as Self Inspection)",
      "Management Review By Quality Council": "Quality Council",
      "Quality KPIS": "Quality KPIs",
      "Product & Technology Transfer": "Technology Transfer",
      "Overview of Facilities Design": "Overview of Facility / Facility Design",
      "Organisation, Personnel Training and Management":
        "Organisation, Personnel Management & Training",
      "Stability": "Stability Testing",
      "Product Lifecycle Management": "Product and Process Design",
      "Regulatory and Quality Compliance":
        "Quality Regulations and Standards Compliance",
      "Laboratory Out of Specification":
        "Out of Specification / Atypical Results Handling",
      "Quality Alerts and Bulletin Management":
        "Quality Alert and Bulletin Management",
    },
    RX: {
      "Monitoring & Reviewing of Environmental Control & Utilities":
        "Environmental Monitoring for Sterile and Biological Products",
      "Document Management & Control": "Document Management and Control",
      "Cleaning Validation & Verification":
        "Cleaning Validation and Verification Lifecycle",
      "Sterile Process Validation, Monitoring & Control":
        "Sterile Production and Control",
      "Data Management & Control": "Data Management and Control",
      "Incident  Management": "Incident Management",
      "Management Monitoring & Independent Business Monitoring": "Internal Business Monitoring (IBM) (Also known as Self Inspection)",
      "Management Review By Quality Council": "Quality Council",
      "Quality KPIS": "Quality KPIs",
      "General Information":
        "General Information (from Site Master File or other miscellaneous sources)",
      "PowerBI Quality Dashboard":
        "Power BI Quality Dashboard (Sites and LOCs) Quality Dashboard",
      "Overview of Facilities Design": "Overview of Facility / Facility Design",
      "Organisation, Personnel Training and Management":
        "Organisation, Personnel Management & Training",
      "Control of Cross Contamination by Allergenic. Genotoxic and Highly Potent Materials and Products":
        "Control of Cross Contamination by Allergenic, Genotoxic and Highly Potent Materials and Products",
      "Stability": "Stability Testing",
      "Product Lifecycle Management": "Product and Process Design",
      "Regulatory and Quality Compliance":
        "Quality Regulations and Standards Compliance",
      "Laboratory Out of Specification":
        "Out of Specification / Atypical Results Handling",
      "Quality Alerts and Bulletin Management":
        "Quality Alert and Bulletin Management",
    },
  };

  window.dateFilterNotes = {
    VX: {
      "SOP Index": "Date filter applies to ‘Effective Date’ column",
      "Corrective & Preventive Actions":
        "Date filter applies to ‘Creation Date’ column",
      "Monitoring & Reviewing of Environmental Control & Utilities":
        "Date filter applies to ‘Creation Date’ column",
      "Document Management & Control":
        "Date filter applies to ‘Effective Date’ column",
      "Deviation Handling": "Date filter applies to ‘Creation Date’ column",
      "Sterile Process Validation, Monitoring & Control":
        "Date filter applies to ‘Creation Date’ column",
      "Cleaning Validation & Verification":
        "Date filter applies to ‘Creation Date’ column",
      "Data Management & Control":
        "Date filter applies to ‘Creation Date’ column",
      "Warehouse Management": "Date filter applies to ‘Creation Date’ column",
      "Change Control":
        "Date filter applies to ‘Change Control Creation Date’ column",
      "Laboratory Out of Specification":
        "Date filter applies to ‘Date Issue Occured’ column",
      "Quality Alerts and Bulletin Management":
        "Date filter applies to ‘Creation Date’ column",
      "Maintenance & Calibration":
        "Date filter applies to ‘Creation Date’ column",
    },
    RX: {
      "SOP Index": "Date filter helps to see the refreshed records based on the selected date",
      "Deviation Handling": "Date filter applies to ‘Creation Date’ column",
      "Sterile Process Validation, Monitoring & Control":
        "Date filter applies to ‘Creation Date’ column",
      "Corrective & Preventive Actions":
        "Date filter applies to ‘Creation Date’ column",
      "Data Management & Control":
        "Date filter applies to ‘Creation Date’ column",
      "Change Control":
        "Date filter applies to ‘Change Control Creation Date’ column",
      "Cleaning Validation & Verification":
        "Date filter applies to ‘Creation Date’ column",
      "Monitoring & Reviewing of Environmental Control & Utilities":
        "Date filter applies to ‘Creation Date’ column",
      "Warehouse Management": "Date filter applies to ‘Creation Date’ column",
      "Laboratory Out of Specification":
        "Date filter applies to ‘Date Issue Occured’ column",
      "Quality Alerts and Bulletin Management":
        "Date filter applies to ‘Creation Date’ column",
      Repacking: "Date filter applies to ‘Creation Date’ column",
      "Maintenance & Calibration":
        "Date filter applies to ‘Creation Date’ column",
      "Document Management & Control":
        "Date filter applies to ‘Effective Date’ column",
    },
  };

  window.PivotConfig = {
    VX: ["Deviations", "CAPAs", "Change Controls"],
    RX: ["Deviations", "CAPAs", "Change Controls"],
  };

  const enableCurrentDate = {
    VX: [],
    RX: ["SOP Index"],
  };

  window.enableCurrentDate = enableCurrentDate;

  window.validateInsightsCondition = (val) => {
    return (
      val === "No Insights Available" ||
      val === "No Data Found for the audit" ||
      val === "No Insights Found" ||
      val === ""
    );
  };

  window.translatorLanguageSupport = () => {
    return {
      en: "English",
      fr: "French",
      de: "German",
      "zh-cn": "Chinese",
      ko: "Korean",
      ja: "Japanese",
    };
  };

  window.getDateDB = (
    ts,
    addTime = false,
    childMenu = "",
    dataSource = "VX"
  ) => {
    if (!ts || !window.allowedStartDate[dataSource].includes(childMenu)) {
      return null;
    }
    let date_ob = new Date(ts);
    let date = date_ob.getDate();
    let month = date_ob.getMonth() + 1;
    let year = date_ob.getFullYear();
    // prints date & time in YYYY-MM-DD format
    let cd =
      year +
      "-" +
      month.toString().padStart(2, "0") +
      "-" +
      date.toString().padStart(2, "0");
    if (addTime) {
      cd += ` ${date_ob.getHours().toString().padStart(2, "0")}:${date_ob
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${date_ob.getSeconds().toString().padStart(2, "0")}`;
    }
    return cd;
  };

  window.getFilterInitialDate = (data_source, childMenu) => {
    if (enableCurrentDate[data_source].includes(childMenu)) {
      // console.log(
      //   "getFilterInitialDate current ",
      //   childMenu,
      //   new Date(new Date().setFullYear(new Date().getFullYear() - 1))
      // );
      return new Date();
    } else {
      // console.log(
      //   "getFilterInitialDate old ",
      //   childMenu,
      //   new Date(new Date().setFullYear(new Date().getFullYear() - 1))
      // );
      return new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    }
  };

  window.getDateDiffInMonths = (date, endDate = "") => {
    return moment(new Date(endDate)).diff(new Date(date), "months", true);
  };

  window.extratDateFromParagraph = (paragraph) => {
    if (paragraph) {
      let datesArr = [
        ...paragraph.matchAll(
          /[a-zA-Z]{3,4}\s*[0-9]{1,2}\s[0-9]{1,4}\s*[0-9]{1,2}\:[0-9]{1,2}[AM|PM]{2}/g
        ),
      ];
      if (datesArr?.length) {
        datesArr.map((datesCollection) => {
          return datesCollection.map((dateCollection) => {
            let tmpDate = window.getDate(
              new Date(
                dateCollection
                  .trim()
                  .split(/\s+/)
                  .join(" ")
                  .replace(" ", "-")
                  .replace(" ", "-")
                  .replace("AM", " AM UTC")
                  .replace("PM", " PM UTC")
              ).toLocaleString(),
              true
            );
            return (paragraph = paragraph.replace(dateCollection, tmpDate));
          });
        });
        return paragraph;
      } else {
        return paragraph;
      }
    } else {
      return paragraph;
    }
  };

  window.toCaptalizedCase = (text) => {
    return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);
  };

  window.toCamalizeCase = (str) => {
    // Lower cases the string
    str = str
      .toLowerCase()
      // Replaces any - or _ characters with a space
      .replace(/[-_]+/g, " ")
      // Removes any non alphanumeric characters
      .replace(/[^\w\s]/g, "")
      // Uppercases the first character in each group immediately following a space
      // (delimited by spaces)
      .replace(/ (.)/g, function ($1) {
        return $1.toUpperCase();
      });

    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  window.base64_encode = (text) => {
    return Buffer.from(text).toString("base64");
  };

  window.base64_decode = (data) => {
    let buff = new Buffer.from(data, "base64");
    let text = buff.toString("ascii");
    return text;
  };

  window.setFeatureID = (feature_id) => {
    sessionStorage.setItem("feature_id", feature_id);
  };

  window.getFeatureID = () => {
    return sessionStorage.getItem("feature_id")
      ? parseInt(sessionStorage.getItem("feature_id"))
      : null;
  };

  window.customEscape = (string) => {
    if (typeof string === "string") {
      return string.replace("&apos;", "'");
    }
    return string;
  };

  window.htmlEncode = (text) => {
    return encode(text);
  };

  window.decodeHtml = (text) => {
    return decode(text);
  };

  window.showLoader = () => {
    setShowLoader(true);
  };

  window.hideLoader = () => {
    setShowLoader(false);
  };

  window.showDependencyLoader = () => {
    setShowLoaderDependency(true);
  };

  window.hideDependencyLoader = () => {
    setShowLoaderDependency(false);
  };

  window.deflate = (str) => {
    return str;
  };

  window.inflate = (compressedStr) => {
    return compressedStr;
  };

  window.changeExt = (fileName, newExt) => {
    let pos = fileName.includes(".")
      ? fileName.lastIndexOf(".")
      : fileName.length;
    let fileRoot = fileName.substr(0, pos);
    let output = `${fileRoot}.${newExt}`;
    return output;
  };

  window.getExt = (fileName) => {
    let pos = fileName.includes(".") ? fileName.lastIndexOf(".") : false;
    if (pos) {
      let ext = fileName.substr(pos + 1, fileName.length);
      return ext.toString().toLowerCase();
    } else {
      return "";
    }
  };

  /**
   * This function is same as PHP's nl2br() with default parameters.
   *
   * @param {string} str Input text
   * @param {boolean} replaceMode Use replace instead of insert
   * @param {boolean} isXhtml Use XHTML
   * @return {string} Filtered text
   */
  window.nl2br = (str, replaceMode, isXhtml) => {
    let breakTag = isXhtml ? "<br />" : "<br>";
    let replaceStr = replaceMode ? "$1" + breakTag : "$1" + breakTag + "$2";
    return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
  };

  /**
   * This function inverses text from PHP's nl2br() with default parameters.
   *
   * @param {string} str Input text
   * @param {boolean} replaceMode Use replace instead of insert
   * @return {string} Filtered text
   */
  window.br2nl = (str, replaceMode) => {
    var replaceStr = replaceMode ? "\n" : "";
    // Includes <br>, <BR>, <br />, </br>
    return str.replace(/<\s*\/?br\s*[\/]?>/gi, replaceStr);
  };

  return (
    <div>
      {showLoader ? <Loader /> : ""}
      {showLoaderDependency ? <Loader /> : ""}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={7000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={alertClass}
          sx={{ width: "100%", whiteSpace: "pre-wrap" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CommonFunctions;
