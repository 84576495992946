/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from "react";
import { BreadcrumsContext } from "../context/breadcrumsContext";
import { IconButton } from "@mui/material";
import axios from "axios";
import "./menu.css";
import * as rimaConstants from "../../../rimaconstants";
import PreviewIcon from "@mui/icons-material/Preview";
import DownloadIcon from "@mui/icons-material/Download";
// import u1416 from "../../../assets/images/audit_standard_manager/u1416.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FeedBackInfo from "../main/FeedBackInfo";
// import FeedBackResponse from "../main/FeedBackResponse";
// import { setDocumentLoading } from "react-doc-viewer/build/state/actions";
import CreateMenu from "./createMenu";
// import { toast } from "react-toastify";
import { UserDataContext } from "../../Context/UserDataContext";
import TooltipsGrid from "../../Common/TooltipsGrid";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../applicationInsights/AppInsights";

const Menu = (props) => {
  //const [parentMenu, setParentMenu] = useState("General Documents");
  //const [childMenu, setChildMenu] = useState("Stakeholder Feedback");
  // const [open, setOpen] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [auditId, setAuditId] = useState("");
  const [auditNumber, setAuditNumber] = useState("");
  const [email, setEmail] = useState("");
  const [menu, setMenu] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [adminUser, setAdminUser, user, setUser] = useContext(UserDataContext);
  const { breadcrums, setBreadcrums } = useContext(BreadcrumsContext);
  // let feature_id = window.getFeatureID();
  // let feature_list = breadcrums?.featureList?.filter((list) => {
  //   return list.parent_feature_id === feature_id;
  // });

  const validateSetYTD = (insight_date, insight_end_date) => {
    let monthDifference = window.getDateDiffInMonths(
      insight_date,
      insight_end_date ? insight_end_date : ""
    );
    if (monthDifference < 3) {
      props.setYtd(13);
      return true;
    }
    monthDifference = Math.floor(monthDifference);
    let currYear = new Date().getFullYear();
    let monthDifferenceYTD = window.getDateDiffInMonths(`${currYear}-01-01`);
    if (parseInt(monthDifference) === parseInt(monthDifferenceYTD)) {
      monthDifference = "0";
    }
    if (monthDifference) {
      switch (parseInt(monthDifference)) {
        case 12:
        case 24:
        case 36:
        case 60:
        case 0:
          props.setYtd(parseInt(monthDifference));
          break;
        default:
          props.setYtd(13);
          break;
      }
    }
  };

  const setIsDateGetable = (flag) => {
    sessionStorage.setItem("isDateGetable", flag);
  };

  const getIsDateGetable = (flag) => {
    return sessionStorage.getItem("isDateGetable");
  };

  React.useEffect(() => {
    if (breadcrums.auditTitle) {
      setIsDateGetable(true);
    }
  }, [breadcrums.childMenu, breadcrums.auditTitle]);

  const updateTableStakeholderFeedback = () => {
    setBreadcrums((prevBreadcrums) => {
      prevBreadcrums.refreshStakeholderFeedback =
        prevBreadcrums.refreshStakeholderFeedback + 1;
      return { ...prevBreadcrums };
    });
  };

  let audit_id_bread = undefined;
  let audit_site_id = "";
  if (breadcrums.auditTitle) {
    // const [
    //   audit_number,
    //   country_code,
    //   audit_site_id,
    //   site_description,
    //   audit_id,
    //   audit_title,
    //   audit_type_id,
    // ] = breadcrums.auditTitle.split("@#@");
    const auditInfo = breadcrums.auditTitle.split("@#@");
    audit_id_bread = auditInfo[4];
  }
  let data_source = breadcrums.vaccine ? "VX" : "RX";
  React.useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    // if (typeof user.role !== undefined && user.role === 3) {
    if (
      breadcrums?.featureList?.length &&
      query.get("load") === "StakeholderFeedback" &&
      localStorage.getItem("isStakeholderFeedbackReff") === "true"
    ) {
      localStorage.setItem("isStakeholderFeedbackReff", false);
      setBreadcrums((prevBreadcrums) => {
        prevBreadcrums.parentMenu = "General Documentation";
        prevBreadcrums.childMenu = "Stakeholder Feedback";
        prevBreadcrums.tableColumnData = [];
        prevBreadcrums.tableRowData = [];
        return { ...prevBreadcrums };
      });
      loadStakeholderFeedback();
    }
  }, [breadcrums.featureList]); //[user]

  React.useEffect(() => {
    window.showLoader();
    let data_source = breadcrums.vaccine ? "VX" : "RX";
    getFeatureList(data_source);
    axios
      .get(`${rimaConstants.REACT_APP_API_URL}/api/v1/ara/menu/${data_source}`)
      .then((response) => {
        if (response?.data?.status === "success") {
          setMenu(response.data["data"]);
        } else {
          window.showMessage(
            response?.data?.msg
              ? response.data.msg
              : rimaConstants.ERROR_LOAD_DATA,
            "warning"
          );
        }
        window.hideLoader();
      })
      .catch((err) => {
        window.showMessage("Failed to get the menu list", "error");
      });
  }, [breadcrums.vaccine]);
  const getFeatureList = (source) => {
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/ara/featurelist/${source}`
      )
      .then((response) => {
        if (response?.data?.status === "success") {
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.featureList = response?.data?.data;
            return { ...prevBreadcrums };
          });
          //setMenu(response.data["data"]);
        } else {
          window.showMessage(
            response?.data?.msg
              ? response.data.msg
              : rimaConstants.ERROR_LOAD_DATA,
            "warning"
          );
        }
        window.hideLoader();
      })
      .catch((err) => {
        window.showMessage("Failed to get the menu list", "error");
      });
  };

  const disabledChildMenu = {
    VACCINE: [
      "Stakeholder Feedback",
      "Quality KPIs",
      "Corrective & Preventive Actions",
      "Effective QMS Implementation",
      "Quality Alert & Bulletin Mgmt",
      "Change Controls (All aspects of Manufacturing and Laboratory changes)",
      "Document Management and Control",
      "Overview of Facility Design",
      "Monitoring and Review of Environmental Controls and Utilities",
      "Maintenance & Calibration",
      "Warehouse management",
      "Cleaning Validation and Verification",
      "Sterile Process, Validation, Monitoring and Control",
      "Management of product incidents & recalls",
      "Warehouse Management",
      "Repacking",
    ],
    PHARMA: [
      "Stakeholder Feedback",
      "PowerBI Quality Dashboard",
      "Quality KPIs",
      "Corrective & Preventive Actions",
      "Quality Management System",
      "Quality Alert & Bulletin Mgmt",
      "Change Control",
      "Documents & Data Management and Control",
      "Overview of Facility / Facility Design",
      "Monitoring and Review of Environmental Controls and Utilities",
      "Maintenance & Calibration",
      "Warehouse Management",
      "Repacking",
      "Cleaning Validation & Verification",
      "Sterile Process. Validation. Monitoring & Control",
      "Deviation Handling",
      "Management of product incidents & recalls",
      "Document Management and Control",
    ],
  };

  const refreshInferences = () => {
    setBreadcrums((prevBreadcrums) => {
      prevBreadcrums.refreshInferences = prevBreadcrums.refreshInferences + 1;
      return { ...prevBreadcrums };
    });
  };

  const loadDeviations = async () => {
    if (!breadcrums.siteId.split("#")[1]) {
      window.showMessage("Site Name is required", "warning");
      window.hideLoader();
      return;
    }

    if (
      !breadcrums.startDates ||
      !breadcrums.endDate ||
      !breadcrums.siteId.split("#")[1]
    ) {
      window.hideLoader();
      return;
    }

    let insight_date = null;
    let insight_end_date = null;
    //console.log("insight_date ", insight_date);

    let isDateGatable = getIsDateGetable();
    if (isDateGatable === "true") {
      setIsDateGetable(false);
      let insightsDates = await getInsightsDate();
      insight_date = insightsDates?.insight_date;
      insight_end_date = insightsDates?.insight_end_date;
      if (insight_date) {
        insight_date = window.getDateDB(
          insight_date,
          false,
          breadcrums.childMenu,
          breadcrums.vaccine ? "VX" : "RX"
        );

        validateSetYTD(insight_date, insight_end_date);
      }
      if (!breadcrums.startDates && insight_date) {
        setBreadcrums((prevBreadcrums) => {
          prevBreadcrums.startDates = insight_date;
          if (insight_end_date) {
            prevBreadcrums.endDate = insight_end_date;
          }
          prevBreadcrums.filterActionReloadSection =
            breadcrums.filterActionReloadSection + 1;
          return { ...prevBreadcrums };
        });
        return false;
      } else if (breadcrums.startDates && insight_date) {
        if (breadcrums.startDates !== insight_date) {
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      } else {
        if (!breadcrums.startDates) {
          insight_date = window.getFilterInitialDate(
            breadcrums.vaccine ? "VX" : "RX",
            "SOP Index"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      }
    }

    let data_source = breadcrums.vaccine ? "Vx" : "Rx";
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/deviations/list/${data_source}`,
        {
          params: {
            site_id: window.base64_encode(breadcrums.siteId.split("#")[1]),
            startDate: window.getDateDB(
              insight_date ? insight_date : breadcrums.startDates,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
            endDate: window.getDateDB(
              breadcrums.endDate,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
            level: breadcrums.DeviationClassification,
          },
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status === "success") {
          response?.data?.heading.map(async (data) => {
            if (data.type && data.type === "date") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = window.getApplyQuickFilterFn;
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value);
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.getDate(params.row[data.field])}
                />
              );
            } else {
              data.renderCell = (params) => {
                return (
                  <TooltipsGrid
                    html={false}
                    placement="top"
                    title={window.customEscape(params.row[data.field])}
                  />
                );
              };
            }
          });
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableColumnData = response?.data?.heading;
            prevBreadcrums.tableRowData = response?.data?.data;
            return { ...prevBreadcrums };
          });
        } else {
          window.showMessage(
            response?.data?.msg
              ? response.data.msg
              : rimaConstants.ERROR_LOAD_DATA,
            "warning"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableRowData = [];
            return { ...prevBreadcrums };
          });
        }
      });
  };

  const loadLaboratoryList = async () => {
    if (!breadcrums.siteId.split("#")[1]) {
      window.showMessage("Site Name is required", "warning");
      window.hideLoader();
      return;
    }

    if (
      !breadcrums.startDates ||
      !breadcrums.endDate ||
      !breadcrums.siteId.split("#")[1]
    ) {
      window.hideLoader();
      return;
    }

    let insight_date = null;
    let insight_end_date = null;
    //console.log("insight_date ", insight_date);

    let isDateGatable = getIsDateGetable();
    if (isDateGatable === "true") {
      setIsDateGetable(false);
      let insightsDates = await getInsightsDate();
      insight_date = insightsDates?.insight_date;
      insight_end_date = insightsDates?.insight_end_date;
      if (insight_date) {
        insight_date = window.getDateDB(
          insight_date,
          false,
          breadcrums.childMenu,
          breadcrums.vaccine ? "VX" : "RX"
        );

        validateSetYTD(insight_date, insight_end_date);
      }
      if (!breadcrums.startDates && insight_date) {
        setBreadcrums((prevBreadcrums) => {
          prevBreadcrums.startDates = insight_date;
          if (insight_end_date) {
            prevBreadcrums.endDate = insight_end_date;
          }
          prevBreadcrums.filterActionReloadSection =
            breadcrums.filterActionReloadSection + 1;
          return { ...prevBreadcrums };
        });
        return false;
      } else if (breadcrums.startDates && insight_date) {
        if (breadcrums.startDates !== insight_date) {
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      } else {
        if (!breadcrums.startDates) {
          insight_date = window.getFilterInitialDate(
            breadcrums.vaccine ? "VX" : "RX",
            "SOP Index"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      }
    }

    let data_source = breadcrums.vaccine ? "Vx" : "Rx";
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/laboratory/list/${data_source}`,
        {
          params: {
            site_id: window.base64_encode(breadcrums.siteId.split("#")[1]),
            startDate: window.getDateDB(
              insight_date ? insight_date : breadcrums.startDates,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
            endDate: window.getDateDB(
              breadcrums.endDate,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
            level: breadcrums.DeviationClassification,
          },
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status === "success") {
          response?.data?.heading.map(async (data) => {
            if (data.type && data.type === "date") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = window.getApplyQuickFilterFn;
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value);
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.getDate(params.row[data.field])}
                />
              );
            } else {
              data.renderCell = (params) => {
                return (
                  <TooltipsGrid
                    html={false}
                    placement="top"
                    title={window.customEscape(params.row[data.field])}
                  />
                );
              };
            }
          });
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableColumnData = response?.data?.heading;
            prevBreadcrums.tableRowData = response?.data?.data;
            return { ...prevBreadcrums };
          });
        } else {
          window.showMessage(
            response?.data?.msg
              ? response.data.msg
              : rimaConstants.ERROR_LOAD_DATA,
            "warning"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableRowData = [];
            return { ...prevBreadcrums };
          });
        }
      });
  };

  const loadIncidentList = () => {
    let data_source = breadcrums.vaccine ? "Vx" : "Rx";

    if (!breadcrums.audit_id) {
      //toast.warning("Please select the audit");
      window.showMessage("Please select the audit", "warning");
      window.hideLoader();
      return;
    }
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/incident_management/list/${data_source}?audit_id=${breadcrums.audit_id}`
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status === "success") {
          response?.data?.heading.map(async (data) => {
            if (data.type1 && data.type1 === "date") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = window.getApplyQuickFilterFn;
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value);
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.getDate(params.row[data.field])}
                />
              );
            } else {
              data.renderCell = (params) => {
                return (
                  <TooltipsGrid
                    html={false}
                    placement="top"
                    title={window.customEscape(params.row[data.field])}
                  />
                );
              };
            }
          });

          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableColumnData = response?.data?.heading;
            prevBreadcrums.tableRowData = response?.data?.data;
            return { ...prevBreadcrums };
          });
        } else {
          window.showMessage(
            response?.data?.msg
              ? response.data.msg
              : rimaConstants.ERROR_LOAD_DATA,
            "warning"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableRowData = [];
            return { ...prevBreadcrums };
          });
        }
      });
  };

  const loadProductList = async (addFilter) => {
    let data_source = breadcrums.vaccine ? "VX" : "RX";

    if (!breadcrums.audit_id) {
      //toast.warning("Please select the audit");
      window.showMessage("Please select the audit", "warning");
      window.hideLoader();
      return;
    }

    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/listproduct/${data_source}?audit_id=${breadcrums.audit_id}`
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status === "success") {
          response?.data?.heading.map(async (data) => {
            if (data.type && data.type === "date") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = window.getApplyQuickFilterFn;
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value);
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.getDate(params.row[data.field])}
                />
              );
            } else {
              data.renderCell = (params) => {
                if (typeof params.row[data.field] === "object") {
                  return (
                    <TooltipsGrid
                      html={false}
                      placement="top"
                      title={window.customEscape(params.row[data.field].value)}
                    />
                  );
                }
                return (
                  <TooltipsGrid
                    html={false}
                    placement="top"
                    title={window.customEscape(params.row[data.field])}
                  />
                );
              };
            }
          });
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableColumnData = response.data.heading;
            prevBreadcrums.tableRowData = response.data.data;
            return { ...prevBreadcrums };
          });
        } else {
          window.showMessage(
            response?.data?.msg
              ? response.data.msg
              : rimaConstants.ERROR_LOAD_DATA,
            "warning"
          );
        }
      });
  };

  const loadSqpData = async (audit_id) => {
    let data_source = breadcrums.vaccine ? "VX" : "RX";
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/sqp/${data_source}/${audit_id}`
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status === "success") {
          response?.data?.heading.map(async (data) => {
            data.renderCell = (params) => (
              <TooltipsGrid
                html={false}
                placement="top"
                title={window.customEscape(params.row[data.field])}
              />
            );
          });
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableColumnData = response?.data?.heading;
            prevBreadcrums.tableRowData = response?.data?.data;
            return { ...prevBreadcrums };
          });
        } else {
          window.showMessage(
            response?.data?.msg
              ? response.data.msg
              : rimaConstants.ERROR_LOAD_DATA,
            "warning"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableRowData = [];
            return { ...prevBreadcrums };
          });
        }
      });
  };

  const loadCapaList = async () => {
    //console.log("breadcrums.startDate ", breadcrums.startDates);
    //console.log("breadcrums.startDate endDate ", breadcrums.endDate);
    if (!breadcrums.siteId.split("#")[1]) {
      window.showMessage("Site Name is required", "warning");
    }
    if (
      !breadcrums.startDates ||
      !breadcrums.endDate ||
      !breadcrums.siteId.split("#")[1]
    ) {
      window.hideLoader();
      return;
    }

    let insight_date = null;
    let insight_end_date = null;
    //console.log("insight_date ", insight_date);

    let isDateGatable = getIsDateGetable();
    if (isDateGatable === "true") {
      setIsDateGetable(false);
      let insightsDates = await getInsightsDate();
      insight_date = insightsDates?.insight_date;
      insight_end_date = insightsDates?.insight_end_date;
      if (insight_date) {
        insight_date = window.getDateDB(
          insight_date,
          false,
          breadcrums.childMenu,
          breadcrums.vaccine ? "VX" : "RX"
        );

        validateSetYTD(insight_date, insight_end_date);
      }
      if (!breadcrums.startDates && insight_date) {
        setBreadcrums((prevBreadcrums) => {
          prevBreadcrums.startDates = insight_date;
          if (insight_end_date) {
            prevBreadcrums.endDate = insight_end_date;
          }
          prevBreadcrums.filterActionReloadSection =
            breadcrums.filterActionReloadSection + 1;
          return { ...prevBreadcrums };
        });
        return false;
      } else if (breadcrums.startDates && insight_date) {
        if (breadcrums.startDates !== insight_date) {
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      } else {
        if (!breadcrums.startDates) {
          insight_date = window.getFilterInitialDate(
            breadcrums.vaccine ? "VX" : "RX",
            "SOP Index"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      }
    }

    let data_source = breadcrums.vaccine ? "Vx" : "Rx";
    axios
      .get(`${rimaConstants.REACT_APP_API_URL}/api/v1/capa/${data_source}`, {
        params: {
          site_id: window.base64_encode(breadcrums.siteId.split("#")[1]),
          startDate: window.getDateDB(
            insight_date ? insight_date : breadcrums.startDates,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
          endDate: window.getDateDB(
            breadcrums.endDate,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
        },
      })
      .then((response) => {
        window.hideLoader();
        if (response?.data.status === "success") {
          response?.data?.heading.map((data) => {
            if (data.type && data.type === "date") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = window.getApplyQuickFilterFn;
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value);
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.getDate(params.row[data.field])}
                />
              );
            } else {
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.customEscape(params.row[data.field])}
                />
              );
            }
            return true;
          });
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableColumnData = response?.data?.heading;
            prevBreadcrums.tableRowData = response?.data?.data;
            return { ...prevBreadcrums };
          });
        } else {
          window.showMessage(
            response?.data?.msg
              ? response.data.msg
              : rimaConstants.ERROR_LOAD_DATA,
            "warning"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableRowData = [];
            return { ...prevBreadcrums };
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const loadChangeControlList = async () => {
    //console.log("breadcrums.startDate ", breadcrums.startDates);
    //console.log("breadcrums.startDate endDate ", breadcrums.endDate);
    if (!breadcrums.siteId.split("#")[1]) {
      window.showMessage("Site Name is required", "warning");
    }
    if (
      !breadcrums.startDates ||
      !breadcrums.endDate ||
      !breadcrums.siteId.split("#")[1]
    ) {
      window.hideLoader();
      return;
    }

    let insight_date = null;
    let insight_end_date = null;
    //console.log("insight_date ", insight_date);

    let isDateGatable = getIsDateGetable();
    if (isDateGatable === "true") {
      setIsDateGetable(false);
      let insightsDates = await getInsightsDate();
      insight_date = insightsDates?.insight_date;
      insight_end_date = insightsDates?.insight_end_date;
      if (insight_date) {
        insight_date = window.getDateDB(
          insight_date,
          false,
          breadcrums.childMenu,
          breadcrums.vaccine ? "VX" : "RX"
        );

        validateSetYTD(insight_date, insight_end_date);
      }
      if (!breadcrums.startDates && insight_date) {
        setBreadcrums((prevBreadcrums) => {
          prevBreadcrums.startDates = insight_date;
          if (insight_end_date) {
            prevBreadcrums.endDate = insight_end_date;
          }
          prevBreadcrums.filterActionReloadSection =
            breadcrums.filterActionReloadSection + 1;
          return { ...prevBreadcrums };
        });
        return false;
      } else if (breadcrums.startDates && insight_date) {
        if (breadcrums.startDates !== insight_date) {
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      } else {
        if (!breadcrums.startDates) {
          insight_date = window.getFilterInitialDate(
            breadcrums.vaccine ? "VX" : "RX",
            "SOP Index"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      }
    }

    let data_source = breadcrums.vaccine ? "Vx" : "Rx";
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/change_control/${data_source}`,
        {
          params: {
            site_id: window.base64_encode(breadcrums.siteId.split("#")[1]),
            startDate: window.getDateDB(
              insight_date ? insight_date : breadcrums.startDates,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
            endDate: window.getDateDB(
              breadcrums.endDate,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
          },
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data.status === "success") {
          response?.data?.heading.map((data) => {
            if (data.field === "change_control_open") {
              data.valueGetter = ({ value }) => parseInt(value);
            }
            if (data.type && data.type === "date") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = window.getApplyQuickFilterFn;
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value);
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.getDate(params.row[data.field])}
                />
              );
            } else {
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.customEscape(params.row[data.field])}
                />
              );
            }
            return true;
          });
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableColumnData = response?.data?.heading;
            prevBreadcrums.tableRowData = response?.data?.data;
            return { ...prevBreadcrums };
          });
        } else {
          window.showMessage(
            response?.data?.msg
              ? response.data.msg
              : rimaConstants.ERROR_LOAD_DATA,
            "warning"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableRowData = [];
            return { ...prevBreadcrums };
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const loadQualityAlertsandBulletinManagementList = async () => {
    //console.log("breadcrums.startDate ", breadcrums.startDates);
    //console.log("breadcrums.startDate endDate ", breadcrums.endDate);
    if (!breadcrums.siteId.split("#")[1]) {
      window.showMessage("Site Name is required", "warning");
    }
    if (
      !breadcrums.startDates ||
      !breadcrums.endDate ||
      !breadcrums.siteId.split("#")[1]
    ) {
      window.hideLoader();
      return;
    }

    let insight_date = null;
    let insight_end_date = null;
    //console.log("insight_date ", insight_date);

    let isDateGatable = getIsDateGetable();
    if (isDateGatable === "true") {
      setIsDateGetable(false);
      let insightsDates = await getInsightsDate();
      insight_date = insightsDates?.insight_date;
      insight_end_date = insightsDates?.insight_end_date;
      if (insight_date) {
        insight_date = window.getDateDB(
          insight_date,
          false,
          breadcrums.childMenu,
          breadcrums.vaccine ? "VX" : "RX"
        );

        validateSetYTD(insight_date, insight_end_date);
      }
      if (!breadcrums.startDates && insight_date) {
        setBreadcrums((prevBreadcrums) => {
          prevBreadcrums.startDates = insight_date;
          if (insight_end_date) {
            prevBreadcrums.endDate = insight_end_date;
          }
          prevBreadcrums.filterActionReloadSection =
            breadcrums.filterActionReloadSection + 1;
          return { ...prevBreadcrums };
        });
        return false;
      } else if (breadcrums.startDates && insight_date) {
        if (breadcrums.startDates !== insight_date) {
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      } else {
        if (!breadcrums.startDates) {
          insight_date = window.getFilterInitialDate(
            breadcrums.vaccine ? "VX" : "RX",
            "SOP Index"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      }
    }

    let data_source = breadcrums.vaccine ? "Vx" : "Rx";
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/quality_alert_bulletin_mgmt/${data_source}`,
        {
          params: {
            site_id: window.base64_encode(breadcrums.siteId.split("#")[1]),
            startDate: window.getDateDB(
              insight_date ? insight_date : breadcrums.startDates,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
            endDate: window.getDateDB(
              breadcrums.endDate,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
          },
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data.status === "success") {
          response?.data?.heading.map((data) => {
            if (data.type && data.type === "date") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = window.getApplyQuickFilterFn;
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value);
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.getDate(params.row[data.field])}
                />
              );
            } else {
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.customEscape(params.row[data.field])}
                />
              );
            }
            return true;
          });
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableColumnData = response?.data?.heading;
            prevBreadcrums.tableRowData = response?.data?.data;
            return { ...prevBreadcrums };
          });
        } else {
          window.showMessage(
            response?.data?.msg
              ? response.data.msg
              : rimaConstants.ERROR_LOAD_DATA,
            "warning"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableRowData = [];
            return { ...prevBreadcrums };
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const loadMaintenanceAndCalibrationList = async () => {
    //console.log("breadcrums.startDate ", breadcrums.startDates);
    //console.log("breadcrums.startDate endDate ", breadcrums.endDate);
    if (!breadcrums.siteId.split("#")[1]) {
      window.showMessage("Site Name is required", "warning");
    }
    if (
      !breadcrums.startDates ||
      !breadcrums.endDate ||
      !breadcrums.siteId.split("#")[1]
    ) {
      window.hideLoader();
      return;
    }

    let insight_date = null;
    let insight_end_date = null;
    //console.log("insight_date ", insight_date);

    let isDateGatable = getIsDateGetable();
    if (isDateGatable === "true") {
      setIsDateGetable(false);
      let insightsDates = await getInsightsDate();
      insight_date = insightsDates?.insight_date;
      insight_end_date = insightsDates?.insight_end_date;
      if (insight_date) {
        insight_date = window.getDateDB(
          insight_date,
          false,
          breadcrums.childMenu,
          breadcrums.vaccine ? "VX" : "RX"
        );

        validateSetYTD(insight_date, insight_end_date);
      }
      if (!breadcrums.startDates && insight_date) {
        setBreadcrums((prevBreadcrums) => {
          prevBreadcrums.startDates = insight_date;
          if (insight_end_date) {
            prevBreadcrums.endDate = insight_end_date;
          }
          prevBreadcrums.filterActionReloadSection =
            breadcrums.filterActionReloadSection + 1;
          return { ...prevBreadcrums };
        });
        return false;
      } else if (breadcrums.startDates && insight_date) {
        if (breadcrums.startDates !== insight_date) {
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      } else {
        if (!breadcrums.startDates) {
          insight_date = window.getFilterInitialDate(
            breadcrums.vaccine ? "VX" : "RX",
            "SOP Index"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      }
    }

    let data_source = breadcrums.vaccine ? "Vx" : "Rx";
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/maintenanceandcalibration/${data_source}`,
        {
          params: {
            site_id: window.base64_encode(breadcrums.siteId.split("#")[1]),
            startDate: window.getDateDB(
              insight_date ? insight_date : breadcrums.startDates,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
            endDate: window.getDateDB(
              breadcrums.endDate,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
          },
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data.status === "success") {
          response?.data?.heading.map((data) => {
            if (data.type && data.type === "date") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = window.getApplyQuickFilterFn;
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value);
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.getDate(params.row[data.field])}
                />
              );
            } else {
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.customEscape(params.row[data.field])}
                />
              );
            }
            return true;
          });
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableColumnData = response?.data?.heading;
            prevBreadcrums.tableRowData = response?.data?.data;
            return { ...prevBreadcrums };
          });
        } else {
          window.showMessage(
            response?.data?.msg
              ? response.data.msg
              : rimaConstants.ERROR_LOAD_DATA,
            "warning"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableRowData = [];
            return { ...prevBreadcrums };
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const loadPivot = async (pivot_type) => {
    if (!breadcrums.siteId.split("#")[1]) {
      window.showMessage("Site Name is required", "warning");
    }
    if (
      !breadcrums.startDates ||
      !breadcrums.endDate ||
      !breadcrums.siteId.split("#")[1]
    ) {
      window.hideLoader();
      return;
    }

    let insight_date = null;
    let insight_end_date = null;

    let isDateGatable = getIsDateGetable();
    if (isDateGatable === "true") {
      setIsDateGetable(false);
      let insightsDates = await getInsightsDate();
      insight_date = insightsDates?.insight_date;
      insight_end_date = insightsDates?.insight_end_date;
      if (insight_date) {
        insight_date = window.getDateDB(
          insight_date,
          false,
          breadcrums.childMenu,
          breadcrums.vaccine ? "VX" : "RX"
        );

        validateSetYTD(insight_date, insight_end_date);
      }
      if (!breadcrums.startDates && insight_date) {
        setBreadcrums((prevBreadcrums) => {
          prevBreadcrums.startDates = insight_date;
          if (insight_end_date) {
            prevBreadcrums.endDate = insight_end_date;
          }
          prevBreadcrums.filterActionReloadSection =
            breadcrums.filterActionReloadSection + 1;
          return { ...prevBreadcrums };
        });
        return false;
      } else if (breadcrums.startDates && insight_date) {
        if (breadcrums.startDates !== insight_date) {
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      } else {
        if (!breadcrums.startDates) {
          insight_date = window.getFilterInitialDate(
            breadcrums.vaccine ? "VX" : "RX",
            breadcrums.childMenu
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      }
    }

    let data_source = breadcrums.vaccine ? "Vx" : "Rx";
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/pivot/${pivot_type}/${data_source}`,
        {
          params: {
            site_id: window.base64_encode(breadcrums.siteId.split("#")[1]),
            startDate: window.getDateDB(
              insight_date ? insight_date : breadcrums.startDates,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
            endDate: window.getDateDB(
              breadcrums.endDate,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
          },
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data.status === "success") {
          response?.data?.heading.map((data) => {
            if (data.type && data.type === "date") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = window.getApplyQuickFilterFn;
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value);
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.getDate(params.row[data.field])}
                />
              );
            } else {
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.customEscape(params.row[data.field])}
                />
              );
            }
            return true;
          });
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableColumnData = response?.data?.heading;
            prevBreadcrums.tableRowData = response?.data?.data;
            return { ...prevBreadcrums };
          });
        } else {
          window.showMessage(
            response?.data?.msg
              ? response.data.msg
              : rimaConstants.ERROR_LOAD_DATA,
            "warning"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableRowData = [];
            return { ...prevBreadcrums };
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const loadMonitoringList = async () => {
    //console.log("breadcrums.startDate ", breadcrums.startDates);
    //console.log("breadcrums.startDate endDate ", breadcrums.endDate);
    if (!breadcrums.siteId.split("#")[1]) {
      window.showMessage("Site Name is required", "warning");
      window.hideLoader();
      return;
    }
    if (!breadcrums.startDates || !breadcrums.endDate) {
      window.hideLoader();
      return;
    }

    let insight_date = null;
    let insight_end_date = null;
    //console.log("insight_date ", insight_date);

    let isDateGatable = getIsDateGetable();
    if (isDateGatable === "true") {
      setIsDateGetable(false);
      let insightsDates = await getInsightsDate();
      insight_date = insightsDates?.insight_date;
      insight_end_date = insightsDates?.insight_end_date;
      if (insight_date) {
        insight_date = window.getDateDB(
          insight_date,
          false,
          breadcrums.childMenu,
          breadcrums.vaccine ? "VX" : "RX"
        );

        validateSetYTD(insight_date, insight_end_date);
      }
      if (!breadcrums.startDates && insight_date) {
        setBreadcrums((prevBreadcrums) => {
          prevBreadcrums.startDates = insight_date;
          if (insight_end_date) {
            prevBreadcrums.endDate = insight_end_date;
          }
          prevBreadcrums.filterActionReloadSection =
            breadcrums.filterActionReloadSection + 1;
          return { ...prevBreadcrums };
        });
        return false;
      } else if (breadcrums.startDates && insight_date) {
        if (breadcrums.startDates !== insight_date) {
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      } else {
        if (!breadcrums.startDates) {
          insight_date = window.getFilterInitialDate(
            breadcrums.vaccine ? "VX" : "RX",
            "SOP Index"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      }
    }

    let data_source = breadcrums.vaccine ? "Vx" : "Rx";
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/monitoring/${data_source}`,
        {
          params: {
            site_id: window.base64_encode(breadcrums.siteId.split("#")[1]),
            startDate: window.getDateDB(
              insight_date ? insight_date : breadcrums.startDates,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
            endDate: window.getDateDB(
              breadcrums.endDate,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
          },
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data.status === "success") {
          response?.data?.heading.map((data) => {
            if (data.type && data.type === "date") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = window.getApplyQuickFilterFn;
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value);
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.getDate(params.row[data.field])}
                />
              );
            } else {
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.customEscape(params.row[data.field])}
                />
              );
            }
            return true;
          });
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableColumnData = response?.data?.heading;
            prevBreadcrums.tableRowData = JSON.parse(response?.data?.data);
            return { ...prevBreadcrums };
          });
        } else {
          window.showMessage(
            response?.data?.msg
              ? response.data.msg
              : rimaConstants.ERROR_LOAD_DATA,
            "warning"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableRowData = [];
            return { ...prevBreadcrums };
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const loadSterileProcessingList = async () => {
    if (!breadcrums.siteId.split("#")[1]) {
      window.showMessage("Site Name is required", "warning");
      window.hideLoader();
      return;
    }
    if (!breadcrums.startDates || !breadcrums.endDate) {
      window.hideLoader();
      return;
    }

    let insight_date = null;
    let insight_end_date = null;
    //console.log("insight_date ", insight_date);

    let isDateGatable = getIsDateGetable();
    if (isDateGatable === "true") {
      setIsDateGetable(false);
      let insightsDates = await getInsightsDate();
      insight_date = insightsDates?.insight_date;
      insight_end_date = insightsDates?.insight_end_date;
      if (insight_date) {
        insight_date = window.getDateDB(
          insight_date,
          false,
          breadcrums.childMenu,
          breadcrums.vaccine ? "VX" : "RX"
        );

        validateSetYTD(insight_date, insight_end_date);
      }
      if (!breadcrums.startDates && insight_date) {
        setBreadcrums((prevBreadcrums) => {
          prevBreadcrums.startDates = insight_date;
          if (insight_end_date) {
            prevBreadcrums.endDate = insight_end_date;
          }
          prevBreadcrums.filterActionReloadSection =
            breadcrums.filterActionReloadSection + 1;
          return { ...prevBreadcrums };
        });
        return false;
      } else if (breadcrums.startDates && insight_date) {
        if (breadcrums.startDates !== insight_date) {
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      } else {
        if (!breadcrums.startDates) {
          insight_date = window.getFilterInitialDate(
            breadcrums.vaccine ? "VX" : "RX",
            "SOP Index"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      }
    }

    let data_source = breadcrums.vaccine ? "Vx" : "Rx";
    axios
      .get(`${rimaConstants.REACT_APP_API_URL}/api/v1/sterile/${data_source}`, {
        params: {
          site_id: window.base64_encode(breadcrums.siteId.split("#")[1]),
          startDate: window.getDateDB(
            insight_date ? insight_date : breadcrums.startDates,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
          endDate: window.getDateDB(
            breadcrums.endDate,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
        },
      })
      .then((response) => {
        window.hideLoader();
        if (response?.data.status === "success") {
          response?.data?.heading.map((data) => {
            if (data.type && data.type === "date") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = window.getApplyQuickFilterFn;
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value);
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.getDate(params.row[data.field])}
                />
              );
            } else {
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.customEscape(params.row[data.field])}
                />
              );
            }
            return true;
          });
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableColumnData = response?.data?.heading;
            prevBreadcrums.tableRowData = JSON.parse(response?.data?.data);
            return { ...prevBreadcrums };
          });
        } else {
          window.showMessage(
            response?.data?.msg
              ? response.data.msg
              : rimaConstants.ERROR_LOAD_DATA,
            "warning"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableRowData = [];
            return { ...prevBreadcrums };
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const loadDataManagementControlList = async () => {
    if (!breadcrums.siteId.split("#")[1]) {
      window.showMessage("Site Name is required", "warning");
      window.hideLoader();
      return;
    }
    if (!breadcrums.startDates || !breadcrums.endDate) {
      window.hideLoader();
      return;
    }

    let insight_date = null;
    let insight_end_date = null;
    //console.log("insight_date ", insight_date);

    let isDateGatable = getIsDateGetable();
    if (isDateGatable === "true") {
      setIsDateGetable(false);
      let insightsDates = await getInsightsDate();
      insight_date = insightsDates?.insight_date;
      insight_end_date = insightsDates?.insight_end_date;
      if (insight_date) {
        insight_date = window.getDateDB(
          insight_date,
          false,
          breadcrums.childMenu,
          breadcrums.vaccine ? "VX" : "RX"
        );

        validateSetYTD(insight_date, insight_end_date);
      }
      if (!breadcrums.startDates && insight_date) {
        setBreadcrums((prevBreadcrums) => {
          prevBreadcrums.startDates = insight_date;
          if (insight_end_date) {
            prevBreadcrums.endDate = insight_end_date;
          }
          prevBreadcrums.filterActionReloadSection =
            breadcrums.filterActionReloadSection + 1;
          return { ...prevBreadcrums };
        });
        return false;
      } else if (breadcrums.startDates && insight_date) {
        if (breadcrums.startDates !== insight_date) {
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      } else {
        if (!breadcrums.startDates) {
          insight_date = window.getFilterInitialDate(
            breadcrums.vaccine ? "VX" : "RX",
            "SOP Index"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      }
    }

    let data_source = breadcrums.vaccine ? "Vx" : "Rx";
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/datamanagementcontrol/${data_source}`,
        {
          params: {
            site_id: window.base64_encode(breadcrums.siteId.split("#")[1]),
            startDate: window.getDateDB(
              insight_date ? insight_date : breadcrums.startDates,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
            endDate: window.getDateDB(
              breadcrums.endDate,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
          },
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data.status === "success") {
          response?.data?.heading.map((data) => {
            if (data.type && data.type === "date") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = window.getApplyQuickFilterFn;
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value);
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.getDate(params.row[data.field])}
                />
              );
            } else {
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.customEscape(params.row[data.field])}
                />
              );
            }
            return true;
          });
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableColumnData = response?.data?.heading;
            prevBreadcrums.tableRowData = JSON.parse(response?.data?.data);
            return { ...prevBreadcrums };
          });
        } else {
          window.showMessage(
            response?.data?.msg
              ? response.data.msg
              : rimaConstants.ERROR_LOAD_DATA,
            "warning"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableRowData = [];
            return { ...prevBreadcrums };
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const loadCleaningValidationVerificationList = async () => {
    if (!breadcrums.siteId.split("#")[1]) {
      window.showMessage("Site Name is required", "warning");
      window.hideLoader();
      return;
    }
    if (!breadcrums.startDates || !breadcrums.endDate) {
      window.hideLoader();
      return;
    }

    let insight_date = null;
    let insight_end_date = null;
    //console.log("insight_date ", insight_date);

    let isDateGatable = getIsDateGetable();
    if (isDateGatable === "true") {
      setIsDateGetable(false);
      let insightsDates = await getInsightsDate();
      insight_date = insightsDates?.insight_date;
      insight_end_date = insightsDates?.insight_end_date;
      if (insight_date) {
        insight_date = window.getDateDB(
          insight_date,
          false,
          breadcrums.childMenu,
          breadcrums.vaccine ? "VX" : "RX"
        );

        validateSetYTD(insight_date, insight_end_date);
      }
      if (!breadcrums.startDates && insight_date) {
        setBreadcrums((prevBreadcrums) => {
          prevBreadcrums.startDates = insight_date;
          if (insight_end_date) {
            prevBreadcrums.endDate = insight_end_date;
          }
          prevBreadcrums.filterActionReloadSection =
            breadcrums.filterActionReloadSection + 1;
          return { ...prevBreadcrums };
        });
        return false;
      } else if (breadcrums.startDates && insight_date) {
        if (breadcrums.startDates !== insight_date) {
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      } else {
        if (!breadcrums.startDates) {
          insight_date = window.getFilterInitialDate(
            breadcrums.vaccine ? "VX" : "RX",
            "SOP Index"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      }
    }

    let data_source = breadcrums.vaccine ? "Vx" : "Rx";
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/cleaningvalidationverification/${data_source}`,
        {
          params: {
            site_id: window.base64_encode(breadcrums.siteId.split("#")[1]),
            startDate: window.getDateDB(
              insight_date ? insight_date : breadcrums.startDates,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
            endDate: window.getDateDB(
              breadcrums.endDate,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
          },
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data.status === "success") {
          response?.data?.heading.map((data) => {
            if (data.type && data.type === "date") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = window.getApplyQuickFilterFn;
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value);
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.getDate(params.row[data.field])}
                />
              );
            } else {
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.customEscape(params.row[data.field])}
                />
              );
            }
            return true;
          });
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableColumnData = response?.data?.heading;
            prevBreadcrums.tableRowData = JSON.parse(response?.data?.data);
            return { ...prevBreadcrums };
          });
        } else {
          window.showMessage(
            response?.data?.msg
              ? response.data.msg
              : rimaConstants.ERROR_LOAD_DATA,
            "warning"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableRowData = [];
            return { ...prevBreadcrums };
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const loadRepacking = async () => {
    if (!breadcrums.siteId.split("#")[1]) {
      window.showMessage("Site Name is required", "warning");
      window.hideLoader();
      return;
    }
    if (!breadcrums.startDates || !breadcrums.endDate) {
      window.hideLoader();
      return;
    }

    let insight_date = null;
    let insight_end_date = null;
    //console.log("insight_date ", insight_date);

    let isDateGatable = getIsDateGetable();
    if (isDateGatable === "true") {
      setIsDateGetable(false);
      let insightsDates = await getInsightsDate();
      insight_date = insightsDates?.insight_date;
      insight_end_date = insightsDates?.insight_end_date;
      if (insight_date) {
        insight_date = window.getDateDB(
          insight_date,
          false,
          breadcrums.childMenu,
          breadcrums.vaccine ? "VX" : "RX"
        );

        validateSetYTD(insight_date, insight_end_date);
      }
      if (!breadcrums.startDates && insight_date) {
        setBreadcrums((prevBreadcrums) => {
          prevBreadcrums.startDates = insight_date;
          if (insight_end_date) {
            prevBreadcrums.endDate = insight_end_date;
          }
          prevBreadcrums.filterActionReloadSection =
            breadcrums.filterActionReloadSection + 1;
          return { ...prevBreadcrums };
        });
        return false;
      } else if (breadcrums.startDates && insight_date) {
        if (breadcrums.startDates !== insight_date) {
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      } else {
        if (!breadcrums.startDates) {
          insight_date = window.getFilterInitialDate(
            breadcrums.vaccine ? "VX" : "RX",
            "SOP Index"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      }
    }

    let data_source = breadcrums.vaccine ? "Vx" : "Rx";
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/repackinglist/${data_source}`,
        {
          params: {
            site_id: window.base64_encode(breadcrums.siteId.split("#")[1]),
            startDate: window.getDateDB(
              insight_date ? insight_date : breadcrums.startDates,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
            endDate: window.getDateDB(
              breadcrums.endDate,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
          },
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data.status === "success") {
          response?.data?.heading.map((data) => {
            if (data.type && data.type === "date") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = window.getApplyQuickFilterFn;
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value);
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.getDate(params.row[data.field])}
                />
              );
            } else {
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.customEscape(params.row[data.field])}
                />
              );
            }
            return true;
          });
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableColumnData = response?.data?.heading;
            prevBreadcrums.tableRowData = JSON.parse(response?.data?.data);
            return { ...prevBreadcrums };
          });
        } else {
          window.showMessage(
            response?.data?.msg
              ? response.data.msg
              : rimaConstants.ERROR_LOAD_DATA,
            "warning"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableRowData = [];
            return { ...prevBreadcrums };
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const loadWarehouseManagement = async () => {
    if (!breadcrums.siteId.split("#")[1]) {
      window.showMessage("Site Name is required", "warning");
      window.hideLoader();
      return;
    }
    if (!breadcrums.startDates || !breadcrums.endDate) {
      window.hideLoader();
      return;
    }

    let insight_date = null;
    let insight_end_date = null;
    //console.log("insight_date ", insight_date);

    let isDateGatable = getIsDateGetable();
    if (isDateGatable === "true") {
      setIsDateGetable(false);
      let insightsDates = await getInsightsDate();
      insight_date = insightsDates?.insight_date;
      insight_end_date = insightsDates?.insight_end_date;
      if (insight_date) {
        insight_date = window.getDateDB(
          insight_date,
          false,
          breadcrums.childMenu,
          breadcrums.vaccine ? "VX" : "RX"
        );

        validateSetYTD(insight_date, insight_end_date);
      }
      if (!breadcrums.startDates && insight_date) {
        setBreadcrums((prevBreadcrums) => {
          prevBreadcrums.startDates = insight_date;
          if (insight_end_date) {
            prevBreadcrums.endDate = insight_end_date;
          }
          prevBreadcrums.filterActionReloadSection =
            breadcrums.filterActionReloadSection + 1;
          return { ...prevBreadcrums };
        });
        return false;
      } else if (breadcrums.startDates && insight_date) {
        if (breadcrums.startDates !== insight_date) {
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      } else {
        if (!breadcrums.startDates) {
          insight_date = window.getFilterInitialDate(
            breadcrums.vaccine ? "VX" : "RX",
            "SOP Index"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      }
    }

    let data_source = breadcrums.vaccine ? "Vx" : "Rx";
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/warehousemanagement/${data_source}`,
        {
          params: {
            site_id: window.base64_encode(breadcrums.siteId.split("#")[1]),
            startDate: window.getDateDB(
              insight_date ? insight_date : breadcrums.startDates,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
            endDate: window.getDateDB(
              breadcrums.endDate,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
          },
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data.status === "success") {
          response?.data?.heading.map((data) => {
            if (data.type && data.type === "date") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = window.getApplyQuickFilterFn;
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value);
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.getDate(params.row[data.field])}
                />
              );
            } else {
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.customEscape(params.row[data.field])}
                />
              );
            }
            return true;
          });
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableColumnData = response?.data?.heading;
            prevBreadcrums.tableRowData = JSON.parse(response?.data?.data);
            return { ...prevBreadcrums };
          });
        } else {
          window.showMessage(
            response?.data?.msg
              ? response.data.msg
              : rimaConstants.ERROR_LOAD_DATA,
            "warning"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableRowData = [];
            return { ...prevBreadcrums };
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const getInsightsDate = async () => {
    // window.showLoader();
    return new Promise(async (resolve) => {
      let auditID = undefined;
      if (breadcrums.auditTitle) {
        const auditInfo = breadcrums.auditTitle.split("@#@");
        auditID = auditInfo[4];
        sessionStorage.setItem("auditID", auditID);
        return await axios
          .get(
            `${
              rimaConstants.REACT_APP_API_URL
            }/api/v1/inferences/date/${window.getFeatureID()}/${auditID}`
          )
          .then((response) => {
            // window.hideLoader();
            if (response?.data?.status === "success") {
              if (response?.data?.data?.length) {
                resolve(response?.data?.data[0]);
              } else {
                resolve(null);
              }
            } else {
              resolve(null);
            }
          });
      } else {
        // window.hideLoader();
        resolve(null);
      }
    }).catch((error) => {
      console.log("insight_date ", error);
      return null;
    });
  };

  const loadSopIndexData = async (cm, showMsg = true) => {
    let siteIDVal = "";
    if (breadcrums.siteId && breadcrums.siteId !== "")
      siteIDVal = breadcrums.siteId.split("#")[1];
    let insight_date = null;
    let insight_end_date = null;
    //console.log("insight_date ", insight_date);

    let isDateGatable = getIsDateGetable();
    if (isDateGatable === "true") {
      setIsDateGetable(false);
      let insightsDates = await getInsightsDate();
      insight_date = insightsDates?.insight_date;
      insight_end_date = insightsDates?.insight_end_date;
      if (insight_date) {
        insight_date = window.getDateDB(
          insight_date,
          false,
          breadcrums.childMenu,
          breadcrums.vaccine ? "VX" : "RX"
        );

        validateSetYTD(insight_date, insight_end_date);
      }
      if (!breadcrums.startDates && insight_date) {
        setBreadcrums((prevBreadcrums) => {
          prevBreadcrums.startDates = insight_date;
          if (insight_end_date) {
            prevBreadcrums.endDate = insight_end_date;
          }
          prevBreadcrums.filterActionReloadSection =
            breadcrums.filterActionReloadSection + 1;
          return { ...prevBreadcrums };
        });
        return false;
      } else if (breadcrums.startDates && insight_date) {
        if (breadcrums.startDates !== insight_date) {
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      } else {
        if (!breadcrums.startDates) {
          insight_date = window.getFilterInitialDate(
            breadcrums.vaccine ? "VX" : "RX",
            "SOP Index"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      }
    }
    let data_source = breadcrums.vaccine ? "VX" : "RX";
    //if (!addFilter && siteIDVal == "") siteIDVal = "NA.....................";
    if (siteIDVal === "") {
      if (showMsg) {
        window.showMessage("Site Name is required", "warning");
      } else {
        setBreadcrums((prevBreadcrums) => {
          // prevBreadcrums.tableColumnData = [];
          prevBreadcrums.tableRowData = [];
          return { ...prevBreadcrums };
        });
      }
      return false;
    }
    if (!breadcrums.startDates && !insight_date) return false;
    // updateInsights && refreshInferences();
    window.showLoader();
    siteIDVal = siteIDVal === "" ? "NA....................." : siteIDVal;
    axios
      .get(
        `${
          rimaConstants.REACT_APP_API_URL
        }/api/v1/sopindex/${data_source}?owning_site_description=${siteIDVal}&snapshot_date=${window.getDateDB(
          insight_date ? insight_date : breadcrums.startDates,
          false,
          cm
        )}&endDate=${window.getDateDB(
          breadcrums.endDate,
          false,
          breadcrums.childMenu,
          breadcrums.vaccine ? "VX" : "RX"
        )}`
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status === "success") {
          response?.data?.heading.map(async (data) => {
            if (data.type && data.type === "date") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = window.getApplyQuickFilterFn;
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value);
              // data.valueFormatter = ({ value }) => window.getDate(value);
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.getDate(params.row[data.field])}
                />
              );
            } else if (data.field && data.field === "is_review_overdue") {
              // data.type = "string";
              // data.type1 = "date";
              data.valueGetter = ({ value }) => parseInt(value);
              data.renderCell = (params) => params.row[data.field];
            } else {
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.customEscape(params.row[data.field])}
                />
              );
            }
          });
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableColumnData = response?.data?.heading;
            prevBreadcrums.tableRowData = JSON.parse(response?.data?.data);
            return { ...prevBreadcrums };
          });
        } else {
          window.showMessage(
            response?.data?.msg
              ? response.data.msg
              : rimaConstants.ERROR_LOAD_DATA,
            "warning"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableRowData = [];
            return { ...prevBreadcrums };
          });
        }
      });
  };
  const loadInternalAudit = async () => {
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/internalaudit/${data_source}/${audit_id_bread}?audit_site_id=${audit_site_id}`
      )
      .then((response) => {
        window.hideLoader();
        let audit_data = JSON.parse(response.data);
        if (audit_data?.status === "success") {
          audit_data?.heading?.grid1.map(async (data) => {
            if (data.type && data.type === "date") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = window.getApplyQuickFilterFn;
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value);
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.getDate(params.row[data.field])}
                />
              );
            } else {
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.customEscape(params.row[data.field])}
                />
              );
            }
          });
          audit_data?.heading.grid2.map(async (data) => {
            if (data.type && data.type === "date") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = window.getApplyQuickFilterFn;
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value);
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.getDate(params.row[data.field])}
                />
              );
            } else {
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.customEscape(params.row[data.field])}
                />
              );
            }
          });
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableColumnData = audit_data?.heading?.grid1;
            prevBreadcrums.tableRowData = audit_data?.data?.grid1;
            prevBreadcrums.internalAuditHeadings = audit_data?.heading?.grid2;
            prevBreadcrums.internalAuditData = audit_data?.data?.grid2;
            return { ...prevBreadcrums };
          });
        }
      });
  };
  const loadQualityCouncilMeeting = async () => {
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/sqcm/${data_source}/${audit_id_bread}`
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status === "success") {
          response?.data?.heading.map(async (data) => {
            if (data.type && data.type === "date") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = window.getApplyQuickFilterFn;
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value);
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.getDate(params.row[data.field])}
                />
              );
            } else {
              if (data.field === "document_storage_url") {
                data.renderCell = (params) => {
                  const onClickDownload = async () => {
                    let filename = params.row.document_storage_url
                      .split("/")
                      .pop(0);
                    let filepath = params.row.document_storage_url.replace(
                      "/dbfs/mnt/",
                      ""
                    );
                    let format = filename.split(".")[1];
                    let type = window.getDocumentTypeByFileExtention(format);
                    let url = `${window.BLOB_URL}/${rimaConstants.ARA_ROOT_CONTAINER}/${filepath}${window.BLOB_CONATINER_SAS_READ_TOKEN}`;
                    window.downloadFile(url, type, filename);
                  };
                  return params.row.document_storage_url !== "" ? (
                    <IconButton
                      onClick={onClickDownload}
                      title="Download"
                      size="small"
                    >
                      <DownloadIcon />
                    </IconButton>
                  ) : (
                    "-"
                  );
                };
              } else {
                data.renderCell = (params) => (
                  <TooltipsGrid
                    html={false}
                    placement="top"
                    title={window.customEscape(params.row[data.field])}
                  />
                );
              }
            }
          });
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableColumnData = response?.data?.heading;
            prevBreadcrums.tableRowData = response?.data?.data;
            return { ...prevBreadcrums };
          });
        } else {
          window.showMessage(
            response?.data?.msg
              ? response.data.msg
              : rimaConstants.ERROR_LOAD_DATA,
            "warning"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableRowData = [];
            return { ...prevBreadcrums };
          });
        }
      });
  };
  const loadStakeholderFeedback = async () => {
    let audit_status = 2;
    if (breadcrums.closedAudits) audit_status = 3;
    let feature_id = window.getFeatureID();
    let feature_list = breadcrums.featureList.filter((list) => {
      return parseInt(list.parent_feature_id) === feature_id;
    });
    // console.log('feature_list ',feature_list)
    let auditID = undefined;
    if (breadcrums.auditTitle) {
      // const [
      //   audit_number,
      //   country_code,
      //   audit_site_id,
      //   site_description,
      //   audit_id,
      //   audit_title,
      //   audit_type_id,
      // ] = breadcrums.auditTitle.split("@#@");
      const auditInfo = breadcrums.auditTitle.split("@#@");
      auditID = auditInfo[4];
    }
    let data_source = breadcrums.vaccine ? "VX" : "RX";
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/stakeholder_feedback_common/${data_source}?audit_id=${auditID}&audit_status=${audit_status}`
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status === "success") {
          response?.data?.heading.map(async (data) => {
            if (data.type && data.type === "date") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = (value) =>
                window.getApplyQuickFilterFn(value, true);
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value, true);
              //delete data.type;
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.getDate(params.row[data.field], true)}
                />
              );
            } else {
              if (data.field === "feedback") {
                data.renderCell = (params) => {
                  const onClickView = (e) => {
                    e.stopPropagation();
                    setAuditNumber(params.row.audit_number);
                    setEmail(params.row.email);
                    setAuditId(params.row.audit_id);
                    setOpenInfo(true);
                  };
                  return params.row.status === "Received" ||
                    (typeof user.role !== undefined && Array.isArray(user.role) && user.role.includes(3)) ? (
                    <IconButton
                      //variant="contained"
                      size="small"
                      // sx={{
                      //   minWidth: "30px",
                      //   maxHeight: "20px",
                      //   m: 0,
                      //   padding: "14px 2px",
                      // }}
                      onClick={onClickView}
                      title="View feedback"
                      disabled={
                        feature_list.length &&
                        feature_list.findIndex(
                          (l) => l.feature_name === "Response Review"
                        ) !== -1 &&
                        feature_list[
                          feature_list.findIndex(
                            (l) => l.feature_name === "Response Review"
                          )
                        ]["access_allowed"]
                          ? false
                          : true
                      }
                      //sx={{ minWidth: "24px", m: 0, p: 1 }}
                    >
                      <PreviewIcon />
                    </IconButton>
                  ) : (
                    "-"
                  );
                };
              } else {
                data.renderCell = (params) => (
                  <TooltipsGrid
                    html={false}
                    placement="top"
                    title={window.customEscape(params.row[data.field])}
                  />
                );
              }
            }
          });
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableColumnData = response?.data?.heading;
            prevBreadcrums.tableRowData = response?.data?.data;
            return { ...prevBreadcrums };
          });
        } else {
          window.showMessage(
            response?.data?.msg
              ? response.data.msg
              : rimaConstants.ERROR_LOAD_DATA,
            "warning"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableRowData = [];
            return { ...prevBreadcrums };
          });
        }
      });
  };

  const loadGeneralInformation = async (audit_id) => {
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/generalinformation/${audit_id}`
      )
      .then((response) => {
        window.hideLoader();
        // let response = JSON.parse(response1);
        if (response?.data?.status === "success") {
          response?.data?.heading.map(async (data) => {
            if (data.type && data.type === "date") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = window.getApplyQuickFilterFn;
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value);
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.getDate(params.row[data.field])}
                />
              );
            } else {
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.customEscape(params.row[data.field])}
                />
              );
            }
          });
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableColumnData = response?.data?.heading;
            prevBreadcrums.tableRowData = response?.data?.data;
            return { ...prevBreadcrums };
          });
        } else {
          window.showMessage(
            response?.data?.msg
              ? response.data.msg
              : rimaConstants.ERROR_LOAD_DATA,
            "warning"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableRowData = [];
            return { ...prevBreadcrums };
          });
        }
      });
  };

  const loadDocumentManagementAndControll = async () => {
    if (!breadcrums.siteId.split("#")[1]) {
      window.showMessage("Site Name is required", "warning");
      window.hideLoader();
      return;
    }
    if (!breadcrums.startDates || !breadcrums.endDate) {
      window.hideLoader();
      return;
    }

    let insight_date = null;
    let insight_end_date = null;
    //console.log("insight_date ", insight_date);

    let isDateGatable = getIsDateGetable();
    if (isDateGatable === "true") {
      setIsDateGetable(false);
      let insightsDates = await getInsightsDate();
      insight_date = insightsDates?.insight_date;
      insight_end_date = insightsDates?.insight_end_date;
      if (insight_date) {
        insight_date = window.getDateDB(
          insight_date,
          false,
          breadcrums.childMenu,
          breadcrums.vaccine ? "VX" : "RX"
        );

        validateSetYTD(insight_date, insight_end_date);
      }
      if (!breadcrums.startDates && insight_date) {
        setBreadcrums((prevBreadcrums) => {
          prevBreadcrums.startDates = insight_date;
          if (insight_end_date) {
            prevBreadcrums.endDate = insight_end_date;
          }
          prevBreadcrums.filterActionReloadSection =
            breadcrums.filterActionReloadSection + 1;
          return { ...prevBreadcrums };
        });
        return false;
      } else if (breadcrums.startDates && insight_date) {
        if (breadcrums.startDates !== insight_date) {
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      } else {
        if (!breadcrums.startDates) {
          insight_date = window.getFilterInitialDate(
            breadcrums.vaccine ? "VX" : "RX",
            "SOP Index"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.startDates = insight_date;
            if (insight_end_date) {
              prevBreadcrums.endDate = insight_end_date;
            }
            prevBreadcrums.filterActionReloadSection =
              breadcrums.filterActionReloadSection + 1;
            return { ...prevBreadcrums };
          });
          return false;
        }
      }
    }

    let data_source = breadcrums.vaccine ? "Vx" : "Rx";
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/documentmanagementandcontrol/${data_source}`,
        {
          params: {
            site_id: window.base64_encode(breadcrums.siteId.split("#")[1]),
            startDate: window.getDateDB(
              insight_date ? insight_date : breadcrums.startDates,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
            endDate: window.getDateDB(
              breadcrums.endDate,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            ),
          },
        }
      )
      .then((response) => {
        window.hideLoader();
        // let response = JSON.parse(response1);
        if (response?.data?.status === "success") {
          response?.data?.heading.map(async (data) => {
            if (data.type && data.type === "date") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = window.getApplyQuickFilterFn;
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value);
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.getDate(params.row[data.field])}
                />
              );
            } else if (data.field && data.field === "days_late") {
              data.valueGetter = ({ value }) => (value ? parseInt(value) : 0);
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={params.row[data.field]}
                />
              );
            } else {
              data.renderCell = (params) => (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={window.customEscape(params.row[data.field])}
                />
              );
            }
          });
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableColumnData = response?.data?.heading;
            prevBreadcrums.tableRowData = JSON.parse(response?.data?.data);
            return { ...prevBreadcrums };
          });
        } else {
          window.showMessage(
            response?.data?.msg
              ? response.data.msg
              : rimaConstants.ERROR_LOAD_DATA,
            "warning"
          );
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.tableRowData = [];
            return { ...prevBreadcrums };
          });
        }
      });
  };

  const reloadSection = (cm = null, showMsg = false) => {
    if (!cm) {
      cm = breadcrums.childMenu;
    }

    switch (cm) {
      // case "List of Products":
      //   window.showLoader();
      //   loadProductList();
      //   break;
      // case "Quality Plan":
      //   window.showLoader();
      //   let auditId = 0;
      //   if (breadcrums.auditTitle) {
      //     const auditInfo = breadcrums.auditTitle.split("@#@");
      //     auditId = auditInfo[4];
      //   }
      //   loadSqpData(auditId);
      //   break;
      // case "Management Review By Quality Council":
      //   if (audit_id_bread) {
      //     window.showLoader();
      //     loadQualityCouncilMeeting();
      //   }
      //   break;
      // case "Self Inspection & Internal Audit":
      //   window.showLoader();
      //   loadInternalAudit();
      //   break;
      // case "General Information":
      //   if (breadcrums.auditTitle) {
      //     window.showLoader();
      //     const auditInfo = breadcrums.auditTitle.split("@#@");
      //     let auditId = auditInfo[4];
      //     loadGeneralInformation(auditId);
      //   }
      //   break;
      case "Stakeholder Feedback":
        window.showLoader();
        loadStakeholderFeedback();
        break;
      case "Monitoring & Reviewing of Environmental Control & Utilities":
        window.showLoader();
        loadMonitoringList();
        break;
      case "Cleaning Validation & Verification":
        window.showLoader();
        loadCleaningValidationVerificationList();
        break;
      case "Repacking":
        window.showLoader();
        loadRepacking();
        break;
      case "Sterile Process Validation, Monitoring & Control":
        window.showLoader();
        loadSterileProcessingList();
        break;
      case "Data Management & Control":
        window.showLoader();
        loadDataManagementControlList();
        break;
      case "Document Management & Control":
        window.showLoader();
        loadDocumentManagementAndControll();
        break;
      case "Deviation Handling":
        window.showLoader();
        loadDeviations();
        break;
      case "Deviations":
        window.showLoader();
        //loadDeviations();
        loadPivot("Deviations");
        break;
      case "Corrective & Preventive Actions":
        window.showLoader();
        loadCapaList();
        break;
      case "CAPAs":
        window.showLoader();
        //loadCapaList();
        loadPivot("CAPAs");
        break;
      case "Change Controls":
        window.showLoader();
        loadPivot("Change Controls");
        break;
      case "SOP Index":
        loadSopIndexData(cm, true);
        break;
      case "Warehouse Management":
        window.showLoader();
        loadWarehouseManagement();
        break;
      /** Unstructured data user story */
      case "Incident  Management":
        window.showLoader();
        loadIncidentList();
        break;
      case "Change Control":
        window.showLoader();
        loadChangeControlList();
        break;
      case "Laboratory Out of Specification":
        window.showLoader();
        loadLaboratoryList();
        break;
      case "Quality Alerts and Bulletin Management":
        window.showLoader();
        loadQualityAlertsandBulletinManagementList();
        break;
      case "Maintenance & Calibration":
        window.showLoader();
        loadMaintenanceAndCalibrationList();
        break;
      default:
        setBreadcrums((prevBreadcrums) => {
          prevBreadcrums.tableColumnData = [];
          prevBreadcrums.tableRowData = [];
          return { ...prevBreadcrums };
        });
        break;
    }
  };
  useEffect(() => {
    reloadSection();
  }, [
    breadcrums.filterActionReloadSection,
    breadcrums.refreshStakeholderFeedback,
    breadcrums.laoadClosedAudits,
    breadcrums.DeviationClassification,
  ]);

  React.useEffect(() => {
    breadcrums.childMenu &&
      breadcrums.siteId &&
      setBreadcrums((prevBreadcrums) => {
        prevBreadcrums.filterActionReloadSection =
          breadcrums.filterActionReloadSection + 1;
        return { ...prevBreadcrums };
      });
  }, [breadcrums.siteId]);

  const handleClick = (pm, cm, feature_id) => {
    props.setDeviation("Major");
    localStorage.setItem("isInsightsDeleted", false);
    window.setFeatureID(feature_id);
    let startDate = "";
    if (breadcrums.vaccine && cm === "SOP Index") {
      props.setYtd(60);
      startDate = window.addMonthToDate(new Date(), 60, "start");
    } else if (!breadcrums.vaccine && cm === "SOP Index") {
      props.setYtd(13);
      startDate = window.getDate(new Date());
    } else {
      props.setYtd(12);
      startDate = window.addMonthToDate(new Date(), 12, "start");
    }

    // if (
    //   window.enableCurrentDate[breadcrums.vaccine ? "VX" : "RX"].includes(cm)
    // ) {
    //   startDate = window.getDate(new Date());
    // } else {
    //   startDate = window.addMonthToDate(new Date(), 12, "start");
    // }

    setBreadcrums((prevBreadcrums) => {
      prevBreadcrums.tableColumnData = "";
      prevBreadcrums.tableRowData = "";
      prevBreadcrums.internalAuditHeadings = [];
      prevBreadcrums.internalAuditData = [];
      //prevBreadcrums.siteId = "";
      //prevBreadcrums.resetFilter = true;
      return { ...prevBreadcrums };
    });

    setBreadcrums((prevBreadcrums) => {
      prevBreadcrums.parentMenu = pm;
      prevBreadcrums.childMenu = cm;
      if (prevBreadcrums.vaccine) {
        if (disabledChildMenu["VACCINE"].includes(cm)) {
          prevBreadcrums.disabledAuditFildWorkDate = true;
          prevBreadcrums.disabledValueDispled = true;
        } else {
          prevBreadcrums.disabledAuditFildWorkDate = false;
          prevBreadcrums.disabledValueDispled = false;
        }
      }
      if (prevBreadcrums.pharma) {
        if (disabledChildMenu["PHARMA"].includes(cm)) {
          prevBreadcrums.disabledAuditFildWorkDate = true;
          prevBreadcrums.disabledValueDispled = true;
        } else {
          prevBreadcrums.disabledAuditFildWorkDate = false;
          prevBreadcrums.disabledValueDispled = false;
        }
      }

      prevBreadcrums.startDates = startDate;
      props.setValueStartDate(startDate);
      prevBreadcrums.endDate = new Date();
      props.setValueEndDate(new Date());

      prevBreadcrums.DeviationClassification = "Major";

      prevBreadcrums.filterActionReloadSection =
        breadcrums.filterActionReloadSection + 1;

      return { ...prevBreadcrums };
    });

    // reloadSection(cm, true);
  };

  return !breadcrums.uploadClick ? (
    <div
       className={`menu`}
      // className={`menu ${
      //   typeof user.role !== undefined && Array.isArray(user.role) && user.role.includes(3) ? "stk" : "aud"
      // }`}
    >
      <nav role="navigation">
        <ul>
          {menu.map((menues, index) => {
            // return <div>{JSON.stringify(menues.parentMenu)}</div>;
            return (
              <li
                className={`${
                  menues.parentMenu[0].feature_name === breadcrums.parentMenu
                    ? "active"
                    : ""
                }`}
                key={window.getUuidv4()}
                style={{ cursor: "pointer" }}
              >
                <a className={`fontbold`}>
                  {menues.parentMenu[0].feature_name}
                </a>{" "}
                {menues.childMenu.length > 0 ? (
                  // <img src={u1416} alt=""></img>
                  <IconButton
                    size="small"
                    sx={{
                      // color: "#FFFFFF !important",
                      height: "0px",
                      width: "10px !important",
                      mt: "-2px",
                    }}
                  >
                    <ArrowDropDownIcon
                      sx={{
                        color:
                          menues.parentMenu[0].feature_name ===
                          breadcrums.parentMenu
                            ? "#15717D !important"
                            : "#FFFFFF !important",
                      }}
                    />
                  </IconButton>
                ) : (
                  ""
                )}
                <CreateMenu
                  items={menues.childMenu}
                  breadcrums={breadcrums}
                  handleClick={handleClick}
                />
              </li>
            );
          })}
        </ul>
      </nav>
      {/* {open ? (
        <FeedBackResponse
          open={true}
          close={() => {
            setOpen(false);
          }}
          dataSource={breadcrums.vaccine ? 'Vx' : 'Rx'}
          //auditTitle={breadcrums.auditTitle}
          audit_number = {auditId}
        />
      ) : (
        ""
      )} */}
      {openInfo ? (
        <FeedBackInfo
          open={true}
          close={() => {
            setOpenInfo(false);
          }}
          //auditTitle={breadcrums.auditTitle}
          auditNumber={auditNumber}
          source={breadcrums.vaccine ? "Vx" : "Rx"}
          email={email}
          auditId={auditId}
          refreshInferences={refreshInferences}
          updateTableStakeholderFeedback={updateTableStakeholderFeedback}
        />
      ) : (
        ""
      )}
    </div>
  ) : (
    ""
  );
};

// export default Menu;
export default withAITracking(reactPlugin, Menu);
