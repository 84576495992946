import React, { useState, useContext, useEffect } from "react";
import { BreadcrumsContext } from "../context/breadcrumsContext";
import DataView from "../../DataTable/DataView";
import DataList from "../../DataList/dataList";
import { Grid, FormControlLabel, FormControl, Typography } from "@mui/material";
import Switch from "@mui/material/Switch";
import Inferences from "./Inferences";
import Button from "@mui/material/Button";
import FeedBackForm from "./FeedBackForm";
import { Dialog, Box } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
// import PreviewIcon from "@mui/icons-material/Preview";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import IosShareIcon from "@mui/icons-material/IosShare";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
// import u1416 from "../../../assets/images/audit_standard_manager/u1416.svg";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import ReactTooltip from "react-tooltip";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axios from "axios";
import * as rimaConstants from "../../../rimaconstants";
import ConfirmDialog from "../../Common/confirmDailog";
// import { toast } from "react-toastify";
// import Loader from "../../Loader/Loader";
import { UserDataContext } from "../../Context/UserDataContext";
// import { useDispatch } from "react-redux";
// import {
//   insertNotification,
//   updateNoticationStatus,
// } from "../../../reducers/araReducer";
import Tooltips from "../../Common/Tooltips";
import MenuFeedBack from "../menu/MenuFeedBack";
import MenuExports from "../menu/MenuExports";
import MenuDownloadDocument from "../menu/MenuDownloadDocument";
import Analytics from "./Analytics";
// import Carousel from "../../Common/CustomCarousel";
import CustomCarousel from "../../Common/CustomCarousel";
import DeviationHandling from "../Deviation/DeviationHandling";
import AutocompleteInput from "../../Common/autocomplete";
import UnstructureDataSearch from "./UnstructuredDataSearch";
import QualityKPI from "./QualityKPI";
import PowerBIQualityDashboard from "./PowerBIQualityDashboard";

const MuiDataGrid = (props) => {
  // const dispatch = useDispatch();
  let feature_id = window.getFeatureID();

  const { breadcrums, setBreadcrums } = useContext(BreadcrumsContext);
  // eslint-disable-next-line no-unused-vars
  const [adminUser, setAdminUser, user, setUser] = useContext(UserDataContext);
  let feature_list = breadcrums.featureList.filter((list) => {
    return parseInt(list.parent_feature_id) === feature_id;
  });
  // console.log('feature_list ',feature_list)
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  // const value = 0;
  const [filterInsight, setFilterInsight] = useState({});
  const [display, setDisplay] = useState("none");
  const [leadAuditor, setLeadAuditor] = useState(false);
  const [isAuditMember, setIsAuditMember] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [auditId, setAuditId] = useState("");
  const [feedBackForms, setFeedBackForms] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showLeadMsg, setShowLeadMsg] = useState(false);
  const [template, setTemplate] = useState({});
  const [auditTypeId, setAuditTypeId] = useState("");
  const [open, setOpen] = useState(false);
  // const [insights, setInsights] = useState("");
  const [auditNumber, setAuditNumber] = useState("");
  const [exportPopup, setExportPopup] = useState(false);
  // const [auditCheckBox, setAuditCheckBox] = useState(true);
  const [feedBackButton, setFeedBackButton] = useState(false);
  const [availableFiles, setAvailableFiles] = useState([]);
  const [showRecall, setShowRecall] = useState(false);
  const [showReshareAction, setShowReshareAction] = useState(false);
  const [isReshareAction, setIsReshareAction] = useState(false);
  const [sendFromTo, setSendFromTo] = React.useState("");
  const [loadingSend, setLoadingSend] = React.useState(false);
  const [disabledExportConditionally, setDisabledExportConditionally] =
    useState(true);
  const [showInsightBlock, setShowInsightBlock] = useState("none");
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [deleteIds, setDeleteIds] = React.useState("");
  useEffect(() => {
    console.log("filterInsight ----- ", filterInsight);
  }, [filterInsight]);
  useEffect(() => {
    //console.log("selectionModel ", selectionModel);
    if (selectionModel?.length) {
      let ids = breadcrums.tableRowData
        .filter((obj) => {
          return selectionModel.includes(obj.id) ? true : false;
        })
        .map((obj) => {
          return obj.email;
        });
      setDeleteIds(JSON.stringify(ids));
    } else {
      setDeleteIds("");
    }
  }, [selectionModel]);

  const changeDisplay = (isAuditMember1) => {
    // display === "block" &&
    //   setDisplay(
    //     isAuditMember1 &&
    //       breadcrums.childMenu !== "" &&
    //       feature_list.length &&
    //       feature_list.findIndex(
    //         (l) =>
    //           l.feature_name === "Initiate Insights" &&
    //           l.parent_feature_name === breadcrums.childMenu
    //       ) !== -1 &&
    //       feature_list[
    //         feature_list.findIndex(
    //           (l) =>
    //             l.feature_name === "Initiate Insights" &&
    //             l.parent_feature_name === breadcrums.childMenu
    //         )
    //       ]["access_allowed"]
    //       ? "block"
    //       : "none"
    //   );
  };

  const updateShowInsightBlock = () => {
    let display =
      (window.allowUnstructureComponent[
        breadcrums.vaccine ? "VX" : "RX"
      ].includes(breadcrums.childMenu) ||
        window.fourceAllowInsightPannel[
          breadcrums.vaccine ? "VX" : "RX"
        ].includes(breadcrums.childMenu)) &&
      breadcrums.auditTitle
        ? "block"
        : breadcrums.auditTitle && breadcrums?.tableColumnData.length > 0
        ? "block"
        : "none";
    setShowInsightBlock(display);
  };

  React.useEffect(() => {
    updateShowInsightBlock();
  }, [breadcrums.childMenu, breadcrums.auditTitle, breadcrums.tableColumnData]);
  React.useEffect(() => {
    setDisplay("none");
  }, [breadcrums.startDates]);

  const formatDate = (date, delayed = 0) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear() - delayed;

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const datesHandler = (type, value) => {
    if (type === "start") {
      props.setValueStartDate(formatDate(value));
      setBreadcrums((prevBreadcrums) => {
        prevBreadcrums.startDates = formatDate(value);
        return { ...prevBreadcrums };
      });
    } else {
      props.setValueEndDate(formatDate(value));
      setBreadcrums((prevBreadcrums) => {
        // prevBreadcrums.startDates = formatDate(value, 1);
        prevBreadcrums.endDate = formatDate(value);
        return { ...prevBreadcrums };
      });
    }
  };

  useEffect(() => {
    setDisplay("none");
    if (breadcrums.auditTitle) {
      // const [
      //   audit_number,
      //   country_code,
      //   audit_site_id,
      //   site_description,
      //   audit_id,
      //   audit_title,
      //   audit_type_id,
      // ] = breadcrums.auditTitle.split("@#@");
      let audit_number = breadcrums.auditTitle.split("@#@")[0];
      let audit_id = breadcrums.auditTitle.split("@#@")[4];
      let audit_type_id = breadcrums.auditTitle.split("@#@")[6];
      setAuditId(audit_id);
      if (typeof audit_type_id !== "undefined" && audit_type_id !== "") {
        setAuditTypeId(audit_type_id);
        //auditValidate(audit_id);
        //getFeedBackTemplates(audit_type_id);
        setAuditNumber(audit_number);
        //checkValidUser(audit_id);
        auditChange(audit_id, audit_type_id);
      }
    }
  }, [breadcrums.auditTitle]);
  useEffect(() => {
    setDisplay("none");
    if (
      breadcrums.childMenu === "Stakeholder Feedback" &&
      auditId !== "" &&
      auditTypeId !== ""
    ) {
      auditChange(auditId, auditTypeId);
    }
  }, [breadcrums.childMenu]);

  const getfileNames = async (audit_id) => {
    axios
      .get(
        `${
          rimaConstants.REACT_APP_API_URL
        }/api/v1/ara/filename/${audit_id}?type=${window.base64_encode(
          breadcrums.childMenu
        )}`
      )
      .then((response) => {
        if (response?.data.status === "success") {
          setAvailableFiles(response?.data?.data);
        } else {
          setAvailableFiles([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (breadcrums.auditTitle && breadcrums.childMenu) {
      const audit_id = breadcrums.auditTitle.split("@#@")[4];
      getfileNames(audit_id);
    } else {
      setAvailableFiles([]);
    }
  }, [breadcrums.auditTitle, breadcrums.childMenu]);

  const auditChange = async (audit_id, audit_type_id) => {
    // //setShowLoader(true);
    setDisplay("none");
    setFeedBackButton(false);
    let promises = [
      checkValidUser(audit_id),
      checkIsValidMember(audit_id),
      getFeedBackTemplates(audit_type_id),
      auditValidate(audit_id),
    ];
    await Promise.all(promises);
    setFeedBackButton(true);
    // if (auditRes.length) {
    //   setShowLoader(false);
    // }
  };
  useEffect(() => {
    if (
      typeof template.stakeholder_feedback_template_id != "undefined" &&
      feedBackForms.length === 0
    ) {
      setOpen(true);
    } else if (
      typeof template.stakeholder_feedback_template_id != "undefined"
    ) {
      // setShowAlert(true);
      setIsReshareAction(true); // new changes
      setOpen(true); //new changes
    }
  }, [template]);

  const checkIsValidMember = async (audit_id) => {
    return new Promise((resolve) => {
      axios
        .get(
          `${rimaConstants.REACT_APP_API_URL}/api/v1/ara/memmberofaudit/${audit_id}`
        )
        .then((response) => {
          resolve(response);
          if (response.data.status === "success") {
            if (response.data.validUser) {
              setIsAuditMember(true);
              changeDisplay(true);
            } else {
              setIsAuditMember(false);
              changeDisplay(false);
            }
            //setFeedBackForms(response.data.data);
          } else {
            setIsAuditMember(false);
            changeDisplay(false);
          }

          //console.log(response.data);
        })
        .catch((error) => {
          resolve(error);
          console.log(error);
        });
    });
  };

  const checkValidUser = async (audit_id) => {
    return new Promise((resolve) => {
      axios
        .get(
          `${rimaConstants.REACT_APP_API_URL}/api/v1/ara/lead_auditor/${audit_id}`
        )
        .then((response) => {
          resolve(response);
          if (response.data.status === "success") {
            if (response.data.validUser) {
              setLeadAuditor(true);
            } else {
              setLeadAuditor(false);
            }
            //setFeedBackForms(response.data.data);
          } else {
            setLeadAuditor(false);
          }

          //console.log(response.data);
        })
        .catch((error) => {
          resolve(error);
          console.log(error);
        });
    });
  };
  const auditValidate = async (audit_id) => {
    audit_id = typeof audit_id === "undefined" ? auditId : audit_id;
    return new Promise((resolve) => {
      if (audit_id !== "" && breadcrums.childMenu === "Stakeholder Feedback") {
        axios
          .get(
            `${rimaConstants.REACT_APP_API_URL}/api/v1/stakeholder_feedback/question_exists/${audit_id}`
          )
          .then((response) => {
            resolve(response);
            if (response.data.status === "success") {
              setFeedBackForms(response.data.data);
            }

            //console.log(response.data);
          })
          .catch((error) => {
            resolve(error);
            console.log(error);
          });
      } else {
        resolve([]);
      }
    });
  };

  const updateTable = () => {
    setBreadcrums((prevBreadcrums) => {
      prevBreadcrums.refreshStakeholderFeedback =
        prevBreadcrums.refreshStakeholderFeedback + 1;
      return { ...prevBreadcrums };
    });
  };

  const getFeedBackTemplates = async (audit_id) => {
    let data_source = breadcrums.vaccine ? "Vx" : "Rx";
    audit_id = typeof audit_id === "undefined" ? auditTypeId : audit_id;
    return new Promise((resolve) => {
      if (audit_id !== "" && breadcrums.childMenu === "Stakeholder Feedback") {
        axios
          .get(
            `${rimaConstants.REACT_APP_API_URL}/api/v1/stakeholder_feedback/stakeholder_feedback_template/${audit_id}/${data_source}`
          )
          .then((response) => {
            if (response.data.status === "success") {
              setTemplates(response.data.data);
              resolve(response);
            }

            //console.log(response.data);
          })
          .catch((error) => {
            resolve(error);
            console.log(error);
          });
      } else {
        resolve([]);
      }
    });
  };

  useEffect(() => {
    if (breadcrums.loadingButtonIniteateInsights) {
      setBreadcrums((prevBreadcrums) => {
        prevBreadcrums.inferencesData = "No Insights Available";
        return { ...prevBreadcrums };
      });
      setDisplay("block");
    }
  }, [breadcrums.loadingButtonIniteateInsights]);

  // useEffect(() => {
  //   setDisplay("none");
  // }, [breadcrums.siteId]);

  const recallQuestoinnair = async () => {
    if (!deleteIds?.length) {
      window.showMessage(
        "Please choose the response(s) for cancellation!",
        "warning"
      );
      return false;
    }
    window.showLoader();
    axios
      .put(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/stakeholder_feedback/recallquestionnaire/${auditId}`,
        {
          ids: deleteIds,
          total: breadcrums?.tableRowData.length,
        }
      )
      .then(async (res) => {
        if (res?.data?.status === "fail") {
          window.hideLoader();
          window.showMessage(res.data.msg, "warning");
        } else {
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.refreshInferences =
              prevBreadcrums.refreshInferences + 1;
            return { ...prevBreadcrums };
          });
          await auditValidate(auditId);
          window.hideLoader();
          window.showMessage(
            "Questionnaire cancelled successfully.",
            "success"
          );
          updateTable();
        }
      })
      .catch((res) => {
        window.hideLoader();
        //console.log(res);
      });
  };

  const reShareQuestionnaier = () => {
    if (!sendFromTo) {
      window.showMessage("Send Form To is required", "warning");
      return false;
    }
    setLoadingSend(true);
    window.showLoader();
    axios
      .put(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/stakeholder_feedback/resend_questionnaire/${auditId}`,
        { send_to: sendFromTo }
      )
      .then(async (res) => {
        setLoadingSend(false);
        if (res?.data?.status === "fail") {
          window.hideLoader();
          window.showMessage(res.data.msg, "warning");
        } else {
          setShowReshareAction(false);
          window.hideLoader();
          window.showMessage("Questionnaire reshared successfully.", "success");
          updateTable();
          setSendFromTo("");
        }
      })
      .catch((res) => {
        setLoadingSend(false);
        window.hideLoader();
        //console.log(res);
      });
  };

  const handleCloseReshare = (event, reason) => {
    if (reason !== "backdropClick") {
      setSendFromTo("");
      setShowReshareAction(false);
    }
  };

  const buttons = () => {
    const handleClickOpen = (type) => {
      if (type === "recall") {
        setIsReshareAction(false);
        setShowRecall(true);
      } else if (type === "reshare") {
        setIsReshareAction(true);
        setOpen(true);
        // setShowReshareAction(true);
      } else {
        if (!leadAuditor) {
          setIsReshareAction(false);
          setShowLeadMsg(true);
        } else if (feedBackForms.length) {
          // setShowAlert(true);
          setIsReshareAction(true); // new changes
          setOpen(true); //new changes
        } else {
          setIsReshareAction(false);
          setOpen(true);
        }
      }
    };
    const handleTemplateOpen = (temp) => {
      if (!leadAuditor) setShowLeadMsg(true);
      else setTemplate(temp);
    };
    const handleClose = (event, reason) => {
      if (reason !== "backdropClick") {
        setOpen(false);
      }
    };
    const handleInferences = () => {
      //if (display === "none") {
      setBreadcrums((prevBreadcrums) => {
        prevBreadcrums.loadingButtonIniteateInsights = true;
        return { ...prevBreadcrums };
      });
      axios
        .post(
          `${
            rimaConstants.REACT_APP_API_URL
          }/api/v1/ara/insight_initeated/${window.getFeatureID()}/${auditId}`
        )
        .then((res) => {
          if (res?.data?.status === "fail") {
            window.showMessage(res.data.msg, "warning");
          } else {
            setBreadcrums((prevBreadcrums) => {
              prevBreadcrums.refreshInferences =
                prevBreadcrums.refreshInferences + 1;
              return { ...prevBreadcrums };
            });
          }
        })
        .catch((res) => {
          //console.log(res);
        });
      //}
      //setDisplay("none");
      //setDisplay(display === "block" ? "none" : "block");
    };

    const convertToCsv = (columns, rows) => {
      let mergedCols = [].concat(
        ...columns.map((col) => {
          return col.headerName;
        })
      );
      mergedCols = mergedCols.splice(1, mergedCols.length - 1);
      let csvContent = mergedCols.join(",") + "\n";
      let csvContent1 = "";
      let tmpData = "";
      rows.map((row) => {
        tmpData = "";
        columns.map((col, index) => {
          if (col.headerName !== "#" && col.headerName !== "S.No.") {
            if (index + 1 === columns.length) {
              //Last column case
              tmpData =
                tmpData +
                (row[col.field]
                  ? '"' + row[col.field].toString().replace(/"/g, '""') + '"'
                  : " ");
            } else {
              // Seperate with comma
              tmpData =
                tmpData +
                (row[col.field]
                  ? '"' + row[col.field].toString().replace(/"/g, '""') + '"'
                  : " ") +
                ",";
            }
          }
          return true;
        });
        csvContent1 = csvContent1 + tmpData + "\n";
        return true;
      });
      csvContent = csvContent + csvContent1;

      csvContent = new File([csvContent], "export.csv", {
        type: "application/vnd.ms-excel",
      });

      return csvContent;
    };

    const exportWithoutDataGrid = (html) => {
      window.showLoader();
      let tableColumnData = breadcrums.tableColumnData;

      const filterDateFiled = tableColumnData.filter((obj) => {
        return (obj.type && obj.type === "date") ||
          (obj.type1 && obj.type1 === "date")
          ? true
          : false;
      });
      //console.log('breadcrums filterDateFiled ', filterDateFiled);
      let tableRow = breadcrums.tableRowData;
      if (filterDateFiled.length > 0) {
        tableRow.map((obj) => {
          filterDateFiled.map((dateObj) => {
            if (
              new RegExp(
                "^[0-9]{4}-(1[0-2]|[0-9])-(1[0-9]|2[0-9]|3[0-1]|0[0-9])$",
                "i"
              ).test(obj[`${dateObj.field}`])
            ) {
              return (obj[`${dateObj.field}`] = window.getDate(
                obj[`${dateObj.field}`],
                false
              ));
            } else {
              return (obj[`${dateObj.field}`] = window.getDate(
                obj[`${dateObj.field}`],
                true
              ));
            }
          });
          return true;
        });
      }

      if (breadcrums.childMenu === "Stakeholder Feedback") {
        tableColumnData = tableColumnData.filter((obj) => {
          return obj.headerName !== "Feedback";
        });

        tableRow = tableRow.map(({ feedback, ...rest }) => {
          return rest;
        });
      }

      tableRow = JSON.parse(
        JSON.stringify(tableRow).split(new RegExp(/null/i)).join('""')
      );
      //console.log("breadcrums tableRow ", tableRow);

      let file = convertToCsv(tableColumnData, tableRow);
      let foldername = "ara";
      let subfolder = "tmp";
      let name = "csv_" + new Date().getTime() + ".csv";
      var req_obj = {};
      req_obj = new FormData();
      req_obj.append("upfile", file);
      axios
        .post(
          `${rimaConstants.REACT_APP_API_URL}/api/v1/upload?folderName=` +
            foldername +
            `&subFolderName=` +
            subfolder +
            `&fileName=` +
            encodeURIComponent(name),
          req_obj
        )
        .then((response) => {
          if (response.status === 200) {
            exportWithDataGrid(html, subfolder + "/" + name);
          } else {
            window.hideLoader();
          }
        })
        .catch((err) => {
          window.hideLoader();
          console.log(err);
        });
    };

    const updateInferences = async (html) => {
      return new Promise(async (resolve, reject) => {
        let req_data = {
          insight_text: await window.deflate(html),
          data_source: breadcrums.vaccine ? "Vx" : "Rx",
          snapshot_date: window.getDateDB(
            breadcrums.startDates,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
        };
        //console.log("updateInferences req_data ", req_data.snapshot_date);
        axios
          .put(
            `${
              rimaConstants.REACT_APP_API_URL
            }/api/v1/inferences/manipulate/${window.getFeatureID()}/${auditId}?txt=Insights Saved`,
            req_data
          )
          .then((res) => {
            if (res?.data?.status === "fail") {
              window.showMessage(res.data.msg, "warning");
              reject(false);
            } else {
              window.showMessage("Insights updated successfully.", "success");
              resolve(true);
            }
          })
          .catch((res) => {
            //console.log(res);
            reject(false);
          });
      });
    };

    const exportWithDataGrid = (html, csvfilepath = "") => {
      window.showLoader();
      updateInferences(html)
        .then((res) => {
          if (breadcrums.inferencesData !== html) {
            setBreadcrums((prevBreadcrums) => {
              prevBreadcrums.inferencesData = html;
              return { ...prevBreadcrums };
            });
          }
          if (
            window.insights[breadcrums.vaccine ? "VX" : "RX"].includes(
              breadcrums.childMenu
            )
          ) {
            try {
              let data = JSON.parse(html);
              if (data && !window.validateInsightsCondition(res)) {
                data.map(async (obj, index) => {
                  obj?.rows.map((obj1, ind) => {
                    if (
                      filterInsight.hasOwnProperty(`${index}${ind}`) &&
                      filterInsight[`${index}${ind}`].length
                    ) {
                      // console.log('filterInsight[ ] ',filterInsight[`${index}${ind}`],filterInsight);
                      let tmpData = obj1.filter((d) => {
                        if (filterInsight[`${index}${ind}`].includes(d.id))
                          return true;
                        else return false;
                      });
                      // console.log("tmpData ",tmpData);
                      // console.log('data[index]["rows"][ind] ',data[index]["rows"][ind])
                      data[index]["rows"][ind] = tmpData;
                    }                     
                  });
                });
                // console.log("tmpData data ",data);
                html = JSON.stringify(data);
                setFilterInsight({});
              }
            } catch (e) {}
          }

          // let notificationId = new Date().getMilliseconds();
          // dispatch(insertNotification({ id: notificationId, fileType: 'ara_export', status: 'RUNNING', exTime: (new Date().toLocaleTimeString()), fileName: 'ARA_export.pdf' }));
          let insights = window.insights[
            breadcrums.vaccine ? "VX" : "RX"
          ].includes(breadcrums.childMenu)
            ? html.replace(new RegExp(`\n`, `g`), ``)
            : "<html>" + html.replace(new RegExp(`\n`, `g`), ``) + "</html>";
          // let insights = html.replace(new RegExp(`\n`, `g`), ``);
          let sectionName = breadcrums.childMenu;
          if (
            window.templateSectionNames[breadcrums.vaccine ? "VX" : "RX"] &&
            window.templateSectionNames[breadcrums.vaccine ? "VX" : "RX"][
              breadcrums.childMenu
            ]
          ) {
            sectionName =
              window.templateSectionNames[breadcrums.vaccine ? "VX" : "RX"][
                breadcrums.childMenu
              ];
          }
          axios
            .post(`${rimaConstants.REACT_APP_API_URL}/api/v1/export`, {
              type: window.insights[breadcrums.vaccine ? "VX" : "RX"].includes(
                breadcrums.childMenu
              )
                ? 2
                : 1,
              vaccine: breadcrums.vaccine,
              audit_number: breadcrums.audit_number,
              section_name: sectionName,
              insights: insights,
              csvfilepath: csvfilepath ? csvfilepath : "",
            })
            .then((res) => {
              window.hideLoader();
              if (res.data?.data?.status === "fail") {
                window.showMessage(res.data.data.msg, "warning");
              } else {
                // if (res?.data?.status === 'success') {
                //   dispatch(updateNoticationStatus({ id: notificationId, fileType: 'ara_export', status: 'SUCCESS' }));
                // } else {
                //   dispatch(updateNoticationStatus({ id: notificationId, fileType: 'ara_export', status: 'FAILED' }));
                // }
              }
            })
            .catch((err) => {
              window.hideLoader();
            });
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const downloadAra = async () => {
      let source = breadcrums.vaccine ? "Vx" : "Rx";
      let filename = auditNumber + "-Audit Risk Analysis.docx";
      if (auditNumber !== "") {
        let format = filename.split("/").pop(0).split(".")[1];
        let type = window.getDocumentTypeByFileExtention(format);
        let url = `${window.BLOB_URL}/${rimaConstants.ARA_ROOT_CONTAINER}/${source}/${auditNumber}/${filename}${window.BLOB_CONATINER_SAS_READ_TOKEN}`;
        window.downloadFile(url, type, filename);
      }
    };

    const getSQPfileName = async (auditNumber) => {
      return new Promise((resolve) => {
        axios
          .get(
            `${
              rimaConstants.REACT_APP_API_URL
            }/api/v1/sqp/filename/${window.base64_encode(auditNumber)}`
          )
          .then((response) => {
            if (response?.data.status === "success") {
              resolve(response?.data?.data[0]["document_storage_url"]);
            } else {
              resolve("");
            }
          })
          .catch((error) => {
            resolve(error);
            console.log(error);
          });
      });
    };

    const downloadAudit = async (file_name = null) => {
      let filename = "";
      if (file_name) {
        filename = file_name;
      } else {
        switch (breadcrums.childMenu) {
          case "Quality Plan":
            filename = await getSQPfileName(auditNumber);
            break;
          case "Management Review By Quality Council":
            filename = "SQCM.pdf";
            break;
          case "Management Monitoring & Independent Business Monitoring":
            filename = "IAU.pdf";
            break;
          default:
            filename = "";
            break;
        }
      }
      if (auditNumber !== "" && filename !== "") {
        // let format = filename.split(".")[1];
        // let type = window.getDocumentTypeByFileExtention(format);
        let url = `${window.BLOB_URL}/${rimaConstants.ARA_ROOT_CONTAINER}/${filename}${window.BLOB_CONATINER_SAS_READ_TOKEN}`;
        // window.downloadFile(url, type, filename);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.target = "_blank";
        a.href = url;
        a.click();
      }
    };
    const updateInsights = () => {
      setBreadcrums((prevBreadcrums) => {
        prevBreadcrums.inferencesData = "";
        prevBreadcrums.refreshInferences = prevBreadcrums.refreshInferences + 1;
        return { ...prevBreadcrums };
      });
    };

    return (
      <div style={{ paddingTop: "20px" }}>
        {breadcrums.childMenu !== "" &&
        typeof breadcrums.childMenu !== "undefined" &&
        breadcrums.auditTitle !== "" ? (
          <div
            // style={{ padding: "20px 0px 21px 0px" }}
            className="flex-container"
          >
            {window.allowUnstructureComponent[
              breadcrums.vaccine ? "VX" : "RX"
            ].includes(breadcrums.childMenu) ||
            ["Incident  Management"].includes(breadcrums.childMenu) ? (
              // <div className="menubtn">
              <div
                style={{
                  display: ["Incident  Management"].includes(
                    breadcrums.childMenu
                  )
                    ? "show"
                    : showInsightBlock,
                }}
              >
                <Box display="flex" justifyContent="flex-end">
                  <div className="flex-child">
                    <MenuDownloadDocument
                      menuText="Site "
                      value={""}
                      downloadAudit={downloadAudit}
                      availableFiles={availableFiles}
                      disabled={
                        breadcrums.childMenu !== "" &&
                        feature_list.length &&
                        feature_list.findIndex(
                          (l) =>
                            l.feature_name === "Download Site Document" &&
                            l.parent_feature_name === breadcrums.childMenu
                        ) !== -1 &&
                        feature_list[
                          feature_list.findIndex(
                            (l) =>
                              l.feature_name === "Download Site Document" &&
                              l.parent_feature_name === breadcrums.childMenu
                          )
                        ]["access_allowed"]
                          ? false
                          : true
                      }
                    />
                  </div>
                </Box>
              </div>
            ) : (
              ""
            )}
            {breadcrums.childMenu === "Stakeholder Feedback" &&
            feedBackButton ? (
              <>
                {/* <div className="menubtn flex-child"> */}
                <div
                  style={{
                    display: showInsightBlock,
                  }}
                >
                  <nav role="navigation">
                    <MenuFeedBack
                      menuText="Feedback Form"
                      value={""}
                      handleClickOpen={handleClickOpen}
                      handleTemplateOpen={handleTemplateOpen}
                      disabled={
                        !isAuditMember || breadcrums.closedAudits
                          ? true
                          : breadcrums.childMenu !== "" &&
                            feature_list.length &&
                            feature_list.findIndex(
                              (l) => l.feature_name === "Feedback Form"
                            ) !== -1 &&
                            feature_list[
                              feature_list.findIndex(
                                (l) => l.feature_name === "Feedback Form"
                              )
                            ]["access_allowed"]
                          ? false
                          : true
                      }
                      templates={templates}
                      tableRowData={breadcrums.tableRowData}
                    />
                  </nav>
                </div>
              </>
            ) : (
              ""
            )}
            <div
              className="flex-child"
              style={{
                display: showInsightBlock,
              }}
            >
              {breadcrums.parentMenu !== "Upload Documents" &&
              breadcrums.parentMenu !== "Pivot Analysis" ? (
                <LoadingButton
                  loading={breadcrums.loadingButtonIniteateInsights}
                  loadingPosition="end"
                  endIcon={<RestartAltIcon />}
                  type="submit"
                  variant="contained"
                  onClick={handleInferences}
                  disabled={
                    isAuditMember &&
                    breadcrums.childMenu !== "" &&
                    feature_list.length &&
                    feature_list.findIndex(
                      (l) =>
                        l.feature_name === "Initiate Insights" &&
                        l.parent_feature_name === breadcrums.childMenu
                    ) !== -1 &&
                    feature_list[
                      feature_list.findIndex(
                        (l) =>
                          l.feature_name === "Initiate Insights" &&
                          l.parent_feature_name === breadcrums.childMenu
                      )
                    ]["access_allowed"]
                      ? false
                      : true
                  }
                  sx={{
                    ml: 2,
                    visibility: breadcrums.showInsights ? "visible" : "hidden",
                  }}
                >
                  Initiate Insights
                </LoadingButton>
              ) : (
                ""
              )}
            </div>
            <div
              className="flex-download"
              style={{
                display: showInsightBlock,
              }}
            >
              <Box display="flex" justifyContent="flex-end">
                <div className="flex-child">
                  {breadcrums.parentMenu !== "Pivot Analysis" && (
                    <Button
                      variant="contained"
                      onClick={downloadAra}
                      //sx={{ display: leadAuditor ? "block" : "none" }}
                      disabled={
                        breadcrums.childMenu !== "" &&
                        feature_list.length &&
                        feature_list.findIndex(
                          (l) =>
                            l.feature_name === "Download ARA document" &&
                            l.parent_feature_name === breadcrums.childMenu
                        ) !== -1 &&
                        feature_list[
                          feature_list.findIndex(
                            (l) =>
                              l.feature_name === "Download ARA document" &&
                              l.parent_feature_name === breadcrums.childMenu
                          )
                        ]["access_allowed"]
                          ? false
                          : true
                      }
                      sx={{
                        visibility: breadcrums.showInsights
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      Download ARA Document
                    </Button>
                  )}
                </div>
              </Box>
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          style={{ padding: "0 0 11px 23px" /*backgroundColor: "#f2f2f2"*/ }}
        >
          <Dialog
            open={open}
            fullWidth={true}
            maxWidth={"md"}
            onClose={handleClose}
            paperProps={{
              style: { borderRadius: 2 },
            }}
            aria-labelledby="responsive-dialog-title"
          >
            <FeedBackForm
              close={handleClose}
              setTemplate={setTemplate}
              template_id={template}
              onSave={() => {
                setTemplate([]);
              }}
              updateTable={updateTable}
              loadTemplate={getFeedBackTemplates}
              feedBackForms={feedBackForms}
              setFeedBackForms={setFeedBackForms}
              auditValidate={auditValidate}
              isReshareAction={isReshareAction}
            />
          </Dialog>
          <ConfirmDialog
            title=""
            open={showAlert}
            setOpen={setShowAlert}
            isAlert={true}
            //onConfirm={() => handleSaveAsTemplete()}
          >
            Feedback request has been sent for the selected audit
          </ConfirmDialog>
          <ConfirmDialog
            title=""
            open={showLeadMsg}
            setOpen={setShowLeadMsg}
            isAlert={true}
          >
            You are not authorize to add questionnaire
          </ConfirmDialog>
          <ConfirmDialog
            title=""
            open={showRecall}
            setOpen={setShowRecall}
            onConfirm={() => {
              recallQuestoinnair();
              setShowRecall(false);
            }}
            handleNo={() => {
              setShowRecall(false);
            }}
          >
            Do you want to Cancel Feedback Request?
          </ConfirmDialog>
          <Dialog
            open={showReshareAction}
            fullWidth={true}
            maxWidth={"sm"}
            onClose={handleCloseReshare}
            paperProps={{
              style: { borderRadius: 2 },
            }}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="alert-dialog-title">
              <Typography sx={{ fontSize: "1.2rem", fontWeight: 700 }}>
                Reshare
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl sx={{ width: "100%", pt: 2 }}>
                      <AutocompleteInput
                        lable="Send form to"
                        value={sendFromTo}
                        setParentValues={setSendFromTo}
                        multiple={true}
                        role={3}
                        placeholder="Send form to"
                        width="100%"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {/* <Box sx={{ m: 5 }}>
                  <Tooltips
                    html={false}
                    iconColor="#2D2D69"
                    placement="top-end"
                    title="Questionnaire can't be edited after submission"
                  />
                </Box> */}
              <Button
                variant="contained"
                onClick={handleCloseReshare}
                sx={{ "& > button": { m: 2 } }}
              >
                Cancel
              </Button>

              <Box>
                <FormControlLabel
                  sx={{
                    display: "none",
                  }}
                  control={
                    <Switch
                      checked={loadingSend}
                      onChange={() => setLoadingSend(!loadingSend)}
                      name="loading"
                      color="primary"
                    />
                  }
                  label="Loading"
                />
                <Box sx={{ "& > button": { m: 2 } }}>
                  <LoadingButton
                    onClick={reShareQuestionnaier}
                    endIcon={<IosShareIcon />}
                    loading={loadingSend}
                    loadingPosition="end"
                    variant="contained"
                    sx={{ textTransform: "capitalize" }}
                    disabled={loadingSend}
                  >
                    Reshare
                  </LoadingButton>
                </Box>
              </Box>
            </DialogActions>
          </Dialog>
        </div>
        {breadcrums.auditTitle !== "" && breadcrums.showInsights ? (
          <Inferences
            display={display}
            updateInsights={updateInsights}
            onSubmit={(html) => {
              // setInsights(html);
              if (breadcrums.inferencesData !== html) {
                setBreadcrums((prevBreadcrums) => {
                  prevBreadcrums.inferencesData = html;
                  return { ...prevBreadcrums };
                });
              }
            }}
            setDisabledExportConditionally={setDisabledExportConditionally}
            menu={breadcrums.childMenu}
            audit_id={auditId}
            setFilterInsight={setFilterInsight}
          />
        ) : (
          ""
        )}

        {display !== "none" &&
        breadcrums.auditTitle !== "" &&
        breadcrums.inferencesData !== "" &&
        breadcrums.inferencesData !== "No Insights Available" &&
        isAuditMember ? (
          <Grid container>
            <Grid item xs={12} sx={{ mt: 2 }} justifyContent="flex-end">
              <Box display="flex" justifyContent="flex-end">
                <div
                  className="flex-export"
                  style={{
                    visibility:
                      showInsightBlock === "block" ? "visible" : "hidden",
                  }}
                >
                  {breadcrums.childMenu !== "" &&
                  feature_list.length &&
                  feature_list.findIndex((l) => l.feature_name === "Export") !==
                    -1 &&
                  feature_list[
                    feature_list.findIndex((l) => l.feature_name === "Export")
                  ]["access_allowed"] ? (
                    <MenuExports
                      menuText="Export"
                      value={""}
                      exportWithoutDataGrid={exportWithoutDataGrid}
                      exportWithDataGrid={exportWithDataGrid}
                      inferencesData={breadcrums.inferencesData}
                      dataSource={breadcrums.vaccine ? "VX" : "RX"}
                      menu={
                        breadcrums.childMenu !== undefined
                          ? breadcrums.childMenu
                          : ""
                      }
                      disableConditonally={disabledExportConditionally}
                      disabled={
                        breadcrums.closedAudits
                          ? true
                          : breadcrums.childMenu !== "" &&
                            feature_list.length &&
                            feature_list.findIndex(
                              (l) => l.feature_name === "Export"
                            ) !== -1 &&
                            feature_list[
                              feature_list.findIndex(
                                (l) => l.feature_name === "Export"
                              )
                            ]["access_allowed"]
                          ? false
                          : true
                      }
                      sx={{
                        visibility: breadcrums.showInsights
                          ? "visible"
                          : "hidden",
                      }}
                    />
                  ) : (
                    true
                  )}

                  <Box sx={{ mt: 1, ml: 1 }}>
                    <Tooltips
                      html={false}
                      iconColor="#2D2D69"
                      placement="top-end"
                      title="Append Insights/Grid data in ARA document"
                    />
                  </Box>
                </div>
              </Box>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        <Dialog
          open={exportPopup}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Data saved on{" "}
              {breadcrums.audit_number + "-Audit Risk Analysis.docx"} document
              successfully.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setExportPopup(false);
              }}
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
  return (
    <div>
      {window.allowUnstructureComponent[
        breadcrums.vaccine ? "VX" : "RX"
      ].includes(breadcrums.childMenu) && (
        <UnstructureDataSearch breadcrums={breadcrums} availableFiles={availableFiles} />
      )}
      {/* {breadcrums.childMenu === "General Information" && <CustomCarousel />} */}

      {breadcrums.childMenu === "Quality KPIS" && (
        <QualityKPI
          type={breadcrums.vaccine ? "VX" : "RX"}
          breadcrums={breadcrums}
        />
      )}
      {breadcrums.childMenu === "PowerBI Quality Dashboard" && (
        <PowerBIQualityDashboard
          type={breadcrums.vaccine ? "VX" : "RX"}
          breadcrums={breadcrums}
        />
      )}
      {/*   {breadcrums.childMenu === "Quality KPIS" && <Analytics></Analytics>}
      {breadcrums.childMenu === "List of Products" &&
      breadcrums.tableColumnData.length ? (
        <DataList
          columns={breadcrums.tableColumnData}
          rows={breadcrums.tableRowData}
          menuName={
            breadcrums.childMenu !== undefined ? breadcrums.childMenu : ""
          }
          checkboxSelection={true}
        />
      ) : (
        ""
      )} */}
      {breadcrums.childMenu === "Deviation Handling" && (
        <DeviationHandling
          deviation={props.deviation}
          setDeviation={props.setDeviation}
          user={user}
          valueStartDate={props.valueStartDate}
          setValueStartDate={props.setValueStartDate}
          valueEndDate={props.valueEndDate}
          setValueEndDate={props.setValueEndDate}
          datesHandler={datesHandler}
          ytd={props.ytd}
          setYtd={props.setYtd}
          deviationClassificationList={props.deviationClassificationList}
        ></DeviationHandling>
      )}
      {!window.allowUnstructureComponent[
        breadcrums.vaccine ? "VX" : "RX"
      ].includes(breadcrums.childMenu) &&
      breadcrums.childMenu !== "Deviation Handling" &&
      breadcrums.tableColumnData.length ? (
        <DataView
          columns={breadcrums.tableColumnData}
          rows={breadcrums.tableRowData}
          menuName={
            breadcrums.childMenu !== undefined ? breadcrums.childMenu : ""
          }
          checkboxSelection={true}
          auditCheckBox={
            breadcrums.childMenu === "Stakeholder Feedback"
              ? breadcrums.closedAudits
                ? false
                : breadcrums.auditTitle
                ? false
                : true
              : undefined
          }
          auditCheckCall={(val) => {
            // setAuditCheckBox(val);
          }}
          setBreadcrums={setBreadcrums}
          breadcrums={breadcrums}
          user={user}
          valueStartDate={props.valueStartDate}
          setValueStartDate={props.setValueStartDate}
          valueEndDate={props.valueEndDate}
          setValueEndDate={props.setValueEndDate}
          datesHandler={datesHandler}
          ytd={props.ytd}
          setYtd={props.setYtd}
          deviation={props.deviation}
          setDeviation={props.setDeviation}
          deviationClassificationList={props.deviationClassificationList}
          setSelectionModel={setSelectionModel}
          selectionModel={selectionModel}
        />
      ) : (
        ""
      )}
      {/* {breadcrums.childMenu === "Management Monitoring" &&
      breadcrums.internalAuditHeadings.length ? (
        <Box sx={{ mt: 4 }}>
          <DataView
            columns={breadcrums.internalAuditHeadings}
            rows={breadcrums.internalAuditData}
            menuName={
              breadcrums.childMenu !== undefined ? breadcrums.childMenu : ""
            }
            checkboxSelection={true}
            auditCheckBox={
              breadcrums.childMenu === "Stakeholder Feedback"
                ? breadcrums.closedAudits
                  ? false
                  : breadcrums.auditTitle
                  ? false
                  : true
                : undefined
            }
            auditCheckCall={(val) => {
              // setAuditCheckBox(val);
            }}
            setBreadcrums={setBreadcrums}
            breadcrums={breadcrums}
            valueStartDate={props.valueStartDate}
            setValueStartDate={props.setValueStartDate}
            valueEndDate={props.valueEndDate}
            setValueEndDate={props.setValueEndDate}
            ytd={props.ytd}
            setYtd={props.setYtd}
            datesHandler={datesHandler}
            deviation={props.deviation}
            setDeviation={props.setDeviation}
          />
        </Box>
      ) : (
        ""
      )} */}
      {breadcrums.parentMenu !== "Upload Documents" ? buttons() : ""}
    </div>
  );
};
export default MuiDataGrid;
