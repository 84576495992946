/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, memo } from "react";
import FeedBackResponse from "./FeedBackResponse";
// import * as rimaConstants from "../../../rimaconstants";
import { useHistory } from "react-router-dom";
const FeedBack = () => {
  let history = useHistory();
  const [auditId, setAuditId] = useState("");
  const [open, setOpen] = useState(false);
  const [displayText, setDisplayText] = useState("");
  useEffect(() => {
    let queryString = window.location.href;
    if (queryString.indexOf("feedbackresponse/") !== -1) {
      let audit = queryString.split("feedbackresponse/")[1];
      //console.log(audit);
      setAuditId(audit);
      setOpen(true);
    }
  }, []);
  const goHomePage = () => {
    localStorage.setItem("isStakeholderFeedbackReff", true);
    history.push("/ara?load=StakeholderFeedback");
  };

  return open && auditId !== "" ? (
    <FeedBackResponse
      open={true}
      close={() => {
        setOpen(false);
        // setTimeout(()=>{
        //   history.push("/")
        // },2000);
      }}
      //auditTitle={breadcrums.auditTitle}
      audit_number={auditId}
      text={(data) => {
        setDisplayText(data);
      }}
    />
  ) : (
    <div className="no-data">
      <h4>
        {auditId === "" ? (
          "Loading..."
        ) : displayText === "form closed" ? (
          <p>
            Form closed{" "}
            <a className="linkOpen" onClick={() => setOpen(true)}>
              [click here to open feedback response form]
            </a>
          </p>
        ) : displayText === "Feedback sent" ||
          displayText ===
            "Feedback has already been submitted for the audit" ? (
          <p>
            {displayText}{" "}
            <a className="linkOpen" onClick={() => goHomePage()}>
              [click here to go feedback page]
            </a>
          </p>
        ) : (
          displayText
        )}
      </h4>
    </div>
  );
};
export default memo(FeedBack);
