
import * as rimaConstants from '../../../src/rimaconstants';
import axios from "axios";

const containerName ="rima-uploads";

const filePath = async (file, storageAccountName, user_id, sasToken) => {
  return `https://${storageAccountName}.blob.core.windows.net/${containerName}/${user_id}/${file.name}?${sasToken}`;
};

const uploadFileToBlob = async (file, storageAccountName, sasToken, user) => {
  if (!file) return [];
  var req_obj = {};
  req_obj = new FormData();
  req_obj.append("upfile", file);
  await axios
            .post(
              `${rimaConstants.REACT_APP_API_URL}/api/v1/upload?folderName=${containerName}&subFolderName=${user.user_id}&fileName=${encodeURIComponent(file.name)}`,req_obj
            )
            .then((res) => {
              console.log(res)
            })
  
  console.log("uploaded file completed");

  return filePath(file, storageAccountName,  user.user_id, sasToken);
};

// </snippet_uploadFileToBlob>

export default uploadFileToBlob;
