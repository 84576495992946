import React from 'react';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import './Header2.css';


import HomeIcon from '../../assets/images/u1060.svg';
// import AdminIcon from '../../assets/images/u1048.svg';
// import ReportsIcon from '../../assets/images/u1052.svg';
// import MenuDivider from '../../assets/images/u1061.svg';
// function handleClick(event) {
//   event.preventDefault();

// }

export default function Header2(props) {
  const breadcrumbs = [
    <Link key="home" underline="hover" color="inherit" to="/" >
      <img src={HomeIcon} alt="Home" style={{ height: "12px" }} />
    </Link>,
    <Link key="asm" style={{ fontSize: '12px', color: '#169BD5' }}
      underline="hover"

      color="inherit"
      to="/asm"
    // onClick={handleClick}
    >
      ASM
        </Link>,
    <Typography style={{ fontSize: '11px', marginTop: "2px" }} color="text.primary">
      {props.location}
    </Typography>,
  ];

  return (

    <div>
      <Box style={{ padding: '20px 0px' }} sx={{ display: 'flex', flexDirection: 'row', p: 1 }}>

        <Breadcrumbs style={{ backgroundColor: '#fff', width: '100%', padding: '0 10px' }}
          separator={<NavigateNextIcon style={{ color: '#000' }} fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>

      </Box>
    </div>

  );
}