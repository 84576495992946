import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useMsal, useAccount } from "@azure/msal-react";
import { loginRequest } from "../../authConfigmasl";
import * as rimaConstants from "../../rimaconstants";

const RegulationDataContext = React.createContext([{}, () => { }]);


const RegulationDataContextProvider = (props) => {
  const [dsources, setDsources] = useState([]);
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});

  useEffect(() => {


    if (account) {
      instance.acquireTokenSilent({
        ...loginRequest,
        account: account,
      })
        .then((response) => {
          // console.log(response)
          let axiosConfig = {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              "Access-Control-Allow-Origin": "*",
              'Authorization': "Bearer " + response.idToken
              // access_token: response.accessToken,
              // id_token: response.idToken,
              // oid: response.uniqueId,
            }
          };
          axios.get(`${rimaConstants.REACT_APP_API_URL}/api/v1/user/sources`, axiosConfig).then((response) => {
            if (response && response.data && response.data.document_sources) {
              setDsources(response.data.document_sources);
            }
          })
        })
    }

  }, [accounts, instance, account]);


  return (
    <RegulationDataContext.Provider value={[dsources, setDsources]}>
      {props.children}
    </RegulationDataContext.Provider>
  );
}

export { RegulationDataContext, RegulationDataContextProvider };