import { useState, createContext } from 'react';

export const AuditContext = createContext();

export const AuditContextProvider = props => {
    const [auditValue, setAudit] = useState({
        addForm: false,
        addFormButton: true,
        editForm: false,
        showErrorMsg: "",
        msgType: "success",
        auditStatus: "2",
        loadAuditList: 0
    });

    return <AuditContext.Provider value={{ auditValue, setAudit }}>
        {props.children}
    </AuditContext.Provider>
}