import React from "react";
import "./aramain.css";
import Header2 from "../Header/Header";
import Footer from "../../Footer/Footer";
import Breadcrums from "../breadcrums/breadcrums";
import Filters from "../filters/filters";
import Menu from "../menu/menu";
// import DataView from "../../DataTable/DataView";
// import Button from "@mui/material/Button";
// import gskLogo from "../../../assets/images/u50.svg";
// import u152 from "../../../assets/images/audit_standard_manager/u152.svg";
// import u73 from "../../../assets/images/audit_standard_manager/u73.svg";
// import u74 from "../../../assets/images/audit_standard_manager/u74.svg";
// import u75 from "../../../assets/images/audit_standard_manager/u75.svg";
// import u76 from "../../../assets/images/audit_standard_manager/u76.svg";
import { BreadcrumsContextProvider } from "../context/breadcrumsContext";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
import MuiDataGrid from "./MuiDataGrid";

import axios from "axios";
import * as rimaConstants from "../../../rimaconstants";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../applicationInsights/AppInsights';

const Aramain = () => {
  // const tabnames = ["Vaccines", "Pharma"];
  // const [open, setOpen] = useState(false);
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  // const value = 0;
  const [valueStartDate, setValueStartDate] = React.useState(null);
  const [valueEndDate, setValueEndDate] = React.useState(null);
  const [ytd, setYtd] = React.useState(12);
  const [deviation, setDeviation] = React.useState("Major");
  const [deviationClassificationList, setDeviationClassificationList] =
    React.useState([]);
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef(null);
  const [dataSource, setDataSource] = React.useState("VX");
  React.useEffect(() => {
    setHeight(window.innerHeight - 158);
    let queryString = window.location.href;
    if (
      queryString.indexOf("/ara") > 0 ||
      queryString.indexOf("/feedbackresponse") > 0
    ) {
      axios
        .get(
          `${rimaConstants.REACT_APP_API_URL}/api/v1/ara/deviationclassification`
        )
        .then((response) => {
          if (response && response?.data && response?.data?.length > 0) {
            setDeviationClassificationList(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dataSource]);

  return (
    <div ref={ref}>
      <Header2 />
      <div className="container-fluid" style={{ minHeight: height }}>
        <BreadcrumsContextProvider>
          <Breadcrums></Breadcrums>
          <div className="container-main">
            <Filters
              valueStartDate={valueStartDate}
              setValueStartDate={setValueStartDate}
              valueEndDate={valueEndDate}
              setValueEndDate={setValueEndDate}
              ytd={ytd}
              setYtd={setYtd}
              setDeviation={setDeviation}
              setDataSource={setDataSource}
            ></Filters>
          </div>
          <div className="container-main">
            <Menu
              valueStartDate={valueStartDate}
              setValueStartDate={setValueStartDate}
              valueEndDate={valueEndDate}
              setValueEndDate={setValueEndDate}
              setYtd={setYtd}
              setDeviation={setDeviation}
            ></Menu>
          </div>
          <div className="container-main" style={{ minHeight: "330px" }}>
            <MuiDataGrid
              valueStartDate={valueStartDate}
              setValueStartDate={setValueStartDate}
              valueEndDate={valueEndDate}
              setValueEndDate={setValueEndDate}
              ytd={ytd}
              setYtd={setYtd}
              deviation={deviation}
              setDeviation={setDeviation}
              deviationClassificationList={deviationClassificationList}
            />
          </div>
        </BreadcrumsContextProvider>
      </div>
      <Footer />
    </div>
  );
};

// export default Aramain;
export default withAITracking(reactPlugin, Aramain);
