import React, { useContext, useCallback, useMemo } from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";

import UploadFileTwoToneIcon from "@mui/icons-material/UploadFileTwoTone";

import { useDropzone } from "react-dropzone";

// Contexts
import { ReviewDataContext } from "../Context/ReviewDataContext";
import {toast} from 'react-toastify'
//"application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/rtf,application/vnd.oasis.opendocument.presentation,application/vnd.oasis.opendocument.spreadsheet,application/vnd.oasis.opendocument.text"

const allowedFileTypes = "application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document";
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#999999",
  borderStyle: "dashed",
  backgroundColor: "#f5f5f5",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const FileUpload_DOCX = () => {
  const {
    onFileUpload,
    // eslint-disable-next-line no-unused-vars
    selectedFile,
    // eslint-disable-next-line no-unused-vars
    setSelectedFile,
    // eslint-disable-next-line no-unused-vars
    selectedFiles,
    setSelectedFiles,
    isFilesReadyToUpload,
    setIsFilesReadyToUpload,
    reviewFileUploadStatus,
    reviewFileUploadStatusMsg,
  } = useContext(ReviewDataContext);

  const onDrop = useCallback((acceptedFiles) => {
    
    if (acceptedFiles.length > 0 )
    {
          setIsFilesReadyToUpload(true)
//          setSelectedFile("")
          setSelectedFiles(acceptedFiles);
    }  
    else {
      toast.warn("Unsupported file format. Only MS-Word (.docx) is supported.")
      setIsFilesReadyToUpload(false);
      return false;
    }

    // console.log("Selected files--->",selectedFiles);
  }, []);

  const {
    // eslint-disable-next-line no-unused-vars
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept:allowedFileTypes,
    // application/pdf means .pdf
    // application/msword means .doc
    // application/vnd.openxmlformats-officedocument.wordprocessingml.document means .docx
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  // const acceptedFileItems = acceptedFiles.map((file) => (
  //   <li key={file.path}>
  //     {file.path} - {file.size} bytes
  //   </li>
  // ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <Alert severity="error">
      <li key={file.path}>
        <Typography component="p" variant="p">
          {file.path}
        </Typography>
        <hr />
        <Typography component="p" variant="p">
          The files could not be uploaded because of Invalid file format. You
          can upload only extention *.docx files
        </Typography>
      </li>
    </Alert>
  ));

  return (
    <div className="fileUpload-container">
      {reviewFileUploadStatus ? (
        reviewFileUploadStatusMsg ? (
          <Alert severity="success">Files are uploaded succesfuly.</Alert>
        ) : (
          <Alert severity="error">
            Sorry, Something went wrong, Please try upload again.
          </Alert>
        )
      ) : (
        ""
      )}
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />

        <Typography
          sx={{ textAlign: "center" }}
          variant="body1"
          component="div"
        >
          <IconButton >
              <UploadFileTwoToneIcon sx={{ fontSize: "20px", color:"#15717d"}} />
          </IconButton>
  
          &nbsp;Drag &amp; drop your files here (or) &nbsp;
          <Link className="gsk-link" href="#">
            click here to choose
          </Link>
          &nbsp;, then click on <strong>Upload.</strong>
        </Typography>
        {/* <Button className="gsk-btn primary pill">Browse Files</Button> */}
        <Typography component="div">
          <Box sx={{ fontStyle: "italic", m: 1 }}>
             NOTE1: Previously uploaded files with same name will be overwritten.<br></br>
             NOTE2: Only one audit finding document can be uploaded per audit.<br></br>
             NOTE3: Use Apply button to save recommendations for a document.<br></br>
             NOTE4: Only *.docx files will be accepted<br></br>
          </Box>
        </Typography>
      </div>
      <Box sx={{ textAlign: "center", p: "15px" }}>
        <Button
          variant="contained"
          startIcon={<UploadFileTwoToneIcon />}
          className="gsk-btn primary pill"
          disabled={!isFilesReadyToUpload}
          onClick={onFileUpload}
        >
          Upload
        </Button>
      </Box>
      <Box>
        {/* <h4>Accepted files</h4>
        <ul>{acceptedFileItems}</ul> */}
        {/* <h4>Rejected files</h4> */}

        <ul className="rejected-files-list">{fileRejectionItems}</ul>
      </Box>
    </div>
  );
};

export default FileUpload_DOCX;
