import * as React from "react";
import { Box } from "@mui/material";

const CommonHeading = (props) => {
  return (
    <Box
      sx={{
        // m: 1,
        // p: 1,
        fontSize: "12px",
        fontWeight: "550",
        // textTransform: "capitalize",
        color:"#2D2D69",
      }}
      display="flex"
      justifyContent="flex-center"
    >
      {props.heading}
    </Box>
  );
};

export default CommonHeading;
