import  {useState, createContext} from 'react';

export const BreadcrumbsContext = createContext();

export const BreadcrumbsContextProvider = props => {
    const [breadcrumbs, setBreadcrumbs] = useState({
        parentMenu: "Dashboard",
        childMenu: ""
    });

    return <BreadcrumbsContext.Provider value={{breadcrumbs, setBreadcrumbs}}>
        {props.children}
        </BreadcrumbsContext.Provider>
}