import React, { useState, useEffect } from "react";
import { Grid, Button } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import * as rimaConstants from "../../../rimaconstants";
import { useHistory } from "react-router-dom";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Tooltips from "../../Common/Tooltips";
import ConfirmDialog from "../../Common/confirmDailog";
import CommonHeading from "../../Common/CommonHeading";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../applicationInsights/AppInsights';

const EmailTemplate = () => {
  let history = useHistory();
  const [editorHtml, setEditorHtml] = useState("");
  const [template, setTemplate] = useState("stakeholder");
  const [loadingSend, setLoadingSend] = useState(false);
  const [nevigareToDashboard, setNevigareToDashboard] = useState(false);
  const [saveButton, setSaveButton] = useState(true);
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef(null);

  useEffect(() => {
    setHeight(window.innerHeight - 210);
  }, []);
  useEffect(() => {
    getMailTemplates();
  }, [template]);
  // useEffect(() => {
  //   console.log("state updated");
  //   console.log(editorHtml);
  // }, [editorHtml]);

  const getTemplateFileName = () => {
    let file_name = ``;
    switch (template) {
      case "auditor":
        file_name = "AuditorNotificationTemplate.txt";
        break;
      case "stakeholderRecallQuestionnaire":
        file_name = "StakeholderRecallQuestionnaireTemplate.txt";
        break;
      case "AuditManagementNotificationTemplate":
        file_name = "AuditManagementNotificationTemplate.txt";
        break;
      case "StakeholderRemainder":
        file_name = "StakeholderRemainder.txt";
        break;
      case "DocumentUploadStatus":
        file_name = "DocumentUploadStatus.txt";
        break;
      default:
        file_name = "StakeholderNotificationTemplate.txt";
        break;
    }
    return file_name;
  };

  const getMsg = () => {
    let msg = ``;
    switch (template) {
      case "auditor":
        msg = "Intimation to Auditor";
        break;
      case "stakeholderRecallQuestionnaire":
        msg = "Stakeholder Recall Questionnaire";
        break;
      case "AuditManagementNotificationTemplate":
        msg = "Audit Initiated Intimation";
        break;
      case "StakeholderRemainder":
        msg = "Stakeholder Reminder";
        break;
      case "DocumentUploadStatus":
        msg = "Document(s) Upload Intimation";
        break;
      default:
        msg = "Stakeholder Notification";
        break;
    }
    return msg;
  };

  const handleSubmit = () => {
    let file_name = getTemplateFileName();
    //console.log(editorHtml);
    let text = editorHtml;
    text = text.replace(/<p[^>]*>(&nbsp;|\s+|<br\s*\/?>)*<\/p>/g, "");
    let req_data = {
      content: window.base64_encode(text),
    };
    if (editorHtml?.length) {
      setLoadingSend(true);
      axios
        .put(
          `${
            rimaConstants.REACT_APP_API_URL
          }/api/v1/upload/updatedocument?folderName=ara&subFolderName=Template&fileName=${file_name}&txt=${getMsg()} Email Template Updated`,
          req_data
        )
        .then((response) => {
          setLoadingSend(false);
          if (response?.data?.status === "success") {
            setSaveButton(true);
            window.showMessage("Updated", "success");
          }
        })
        .catch(function (error) {
          setLoadingSend(false);
          console.log(error);
        });
    }
  };
  const handleEditorChange = (html) => {
    setEditorHtml(html);
  };
  const handleTmpChange = (e) => {
    setSaveButton(true);
    setTemplate(e.target.value);
  };
  const handleClose = (isRedirect = false) => {
    if (isRedirect) {
      setNevigareToDashboard(false);
      history.push("/admin/dashboard");
    } else {
      setNevigareToDashboard(true);
    }
  };
  const getMailTemplates = async () => {
    let file = getTemplateFileName();
    let url =
      window.BLOB_URL +
      "/" +
      rimaConstants.ARA_ROOT_CONTAINER +
      "/Template/" +
      file +
      window.BLOB_CONATINER_SAS_READ_TOKEN +
      "&time=" +
      Date.now();
    const response = await fetch(url, {
      method: "GET",
    });
    //console.log(response);
    const blob = await response.blob();
    var newBlob = new Blob([blob], {
      type: "text/plain",
    });
    var myReader = new FileReader();
    //handler executed once reading(blob content referenced to a variable) from blob is finished.
    myReader.addEventListener("loadend", function (e) {
      let result = e.srcElement.result;

      //result = result.replace()
      //console.log(result);
      result = result.replace(
        /<\/p[^>]*>(&nbsp;|\s+|<br\s*\/?>)*<p>/g,
        "</p><br><p>"
      );
      //console.log(result);
      setEditorHtml(result);
      //console.log(e);
      URL.revokeObjectURL(newBlob);
    });
    //start the reading process.
    //const blobUrl =URL.createObjectURL(newBlob);
    myReader.readAsText(newBlob);
    //myReader.readAsDataURL(blobUrl);
  };
  return (
    <div ref={ref} style={{ minHeight: height }}>
      <Grid container>
        <Grid item xs={12}>
          <CommonHeading heading="Mail Template" />
        </Grid>
      </Grid>
      <Box
        sx={{
          pt: 3,
          pb: 3,
          pl: 2,
          backgroundColor: "#fff",
          border: "2px dashed hsla(0,0%,50.2%,.2)!important",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-start">
              <FormControl sx={{ width: "45ch", mb: 3, mt: 1 }}>
                <InputLabel id="demo-simple-select-label">Template</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={template}
                  label="Template"
                  onChange={handleTmpChange}
                  sx={{ height: "50px" }}
                >
                  <MenuItem
                    key={"AuditManagementNotificationTemplate"}
                    value="AuditManagementNotificationTemplate"
                  >
                    Audit Initiated Notification
                  </MenuItem>
                  <MenuItem key={"auditor"} value="auditor">
                  Notification to Auditor [Response Submitted]
                  </MenuItem>
                  <MenuItem key={"stakeholder"} value="stakeholder">
                    Stakeholder Notification [Questionnaire Sent]
                  </MenuItem>
                  <MenuItem
                    key={"stakeholderRecallQuestionnaire"}
                    value="stakeholderRecallQuestionnaire"
                  >
                    Stakeholder Recall Questionnaire
                  </MenuItem>
                  {/* <MenuItem
                    key={"StakeholderRemainder"}
                    value="StakeholderRemainder"
                  >
                    Stakeholder Reminder
                  </MenuItem>

                  <MenuItem
                    key={"DocumentUploadStatus"}
                    value="DocumentUploadStatus"
                  >
                    Document(s) Upload Intimation
                  </MenuItem> */}
                </Select>
              </FormControl>

              <Box sx={{ mt: 2, ml: 1 }}>
                <Tooltips
                  html={true}
                  iconColor="#000000"
                  placement="top"
                  title="Please do not change information in {} braces <br /> This will be replaced by their relevant values based on a audit selection"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <FormControl sx={{ width: "98%" }}>
              <ReactQuill
                theme={"snow"}
                onKeyPress={() => setSaveButton(false)}
                onKeyDown={() => setSaveButton(false)}
                onChange={handleEditorChange}
                value={editorHtml}
                modules={EmailTemplate.modules}
                formats={EmailTemplate.formats}
                bounds={".app"}
                placeholder={"editor"}
              />
            </FormControl>
          </Grid>
          <Grid container>
            <Grid item xs={12} sx={{ mt: 2 }} justifyContent="flex-end">
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  onClick={() => handleClose(false)}
                  sx={{ mr: 1, textTransform: "none" }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  onClick={handleSubmit}
                  endIcon={<SaveIcon />}
                  loading={loadingSend}
                  loadingPosition="end"
                  variant="contained"
                  sx={{ textTransform: "capitalize", mr: 4 }}
                  disabled={saveButton}
                >
                  Save
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ConfirmDialog
        title={""}
        open={nevigareToDashboard}
        setOpen={setNevigareToDashboard}
        onConfirm={() => handleClose(true)}
        handleNo={() => setNevigareToDashboard(false)}
      >
        Do you want to navigate Admin Dashboard?
      </ConfirmDialog>
    </div>
  );
};
// export default EmailTemplate;
export default withAITracking(reactPlugin, EmailTemplate);

EmailTemplate.modules = {
  toolbar: [["bold", "italic", "underline"]],
  clipboard: {
    // matchVisual: false,
  },
};

EmailTemplate.formats = ["bold", "italic", "underline"];
