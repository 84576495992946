import React, { useEffect, useState } from "react";
import { Badge } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FileUploadProgress from "../FileUploadProgress/FileUploadProgress";
import { useSelector } from "react-redux";
import { araSelector } from "../../../reducers/araReducer";

export default function Notifications() {
  const { runningFileUploadIds } = useSelector(araSelector);
  const [notificationCount, setNotificationCount] = useState(0);
  const [isOpenNotificationPopup, setIsOpenNotificationPopup] = useState(false);
  const [anchorEl2, setAnchorEl2] = useState(null);

  useEffect(() => {
    if (runningFileUploadIds?.length) {
      setNotificationCount(runningFileUploadIds.length);
    } else {
      if (notificationCount) {
        setNotificationCount(0);
      }
    }
  }, [JSON.stringify(runningFileUploadIds)]);

  const notificationHandler = (event) => {
    if (!notificationCount) {
      return;
    }
    if (!isOpenNotificationPopup) {
      setAnchorEl2(event?.currentTarget);
    }
    setIsOpenNotificationPopup(!isOpenNotificationPopup);
  };

  const notificationClose = (event) => {
    if (!isOpenNotificationPopup) {
      setAnchorEl2(event?.currentTarget);
    }
    setIsOpenNotificationPopup(false);
  };

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          onClick={notificationHandler}
          size="small"
          sx={{ ml: 2, mt: 0.8 }}
        >
          <Badge badgeContent={notificationCount} color="primary">
            <NotificationsIcon
              style={{ cursor: "pointer" }}
              color="action"
              aria-describedby={
                isOpenNotificationPopup ? "simple-popover" : undefined
              }
              variant="contained"
            />
          </Badge>
        </IconButton>
      </Tooltip>

      <FileUploadProgress
        anchorEl={anchorEl2}
        onClose={notificationClose}
        isOpen={isOpenNotificationPopup}
      />
    </>
  );
}
