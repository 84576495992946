import axios from "axios";
import * as rimaConstants from "../../rimaconstants";

export default async function getHiddenDocList()
{
    try{
        return await axios
        .get(
          `${rimaConstants.REACT_APP_API_URL}/api/v1/user/hiddendocs`
        )
        .then((res) => {
            return res.data;
        } ) 
    }
    catch (e)
    {
        return []
    }
 
}