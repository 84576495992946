import React from "react";
import ReactExport from "react-export-excel";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DownloadExportExcel = (props) => {
  let tableRow = props.rows;
  if (props.gridSelectionModel?.length) {
    tableRow = tableRow.filter((obj) => {
      return props.gridSelectionModel.includes(obj.id) ? true : false;
    });
  }

  // tableRow = JSON.parse(window.htmlEncode(JSON.stringify(tableRow)));
  // try {
  //   console.log("tableRow ", tableRow);
  //   tableRow = tableRow.map((obj) => {
  //     return {
  //       ...obj,
  //       ...Object.entries(obj).map(([key, val], i) => {
  //         obj[key] = window.decodeHtml(val.toString().replace(/&(amp;)*amp;/g,"&"));//window.htmlEncode(val);
  //         return obj;
  //         // return { key: window.htmlEncode(val) };
  //       }),
  //     };
  //   });
  // } catch (e) {
  //   console.log("tableRow e ", e);
  // }
  // console.log("tableRow ", tableRow);

  return (
    <ExcelFile
      element={
        <button className="button-grid-export-excel">
          <FileDownloadIcon sx={{ color: "#15717d !important" }} /> Export
        </button>
      }
      filename={`RIMA-${props.heading}`}
    >
      <ExcelSheet data={tableRow} name="RIMA">
        {props.columns.map((heading) => {
          return (
            <ExcelColumn
              label={heading.headerName}
              value={heading.field}
              // value={(col) => window.htmlEncode(col[heading.field])}
              // style={{
              //   fill: { patternType: "solid", bgColor: { rgb: "#15717d" } },
              // }}
            />
          );
        })}
      </ExcelSheet>
    </ExcelFile>
  );
};

export default DownloadExportExcel;
