import $ from "jquery";
import { memo, useState, useEffect, useRef } from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import Grid from "@mui/material/Box";
import { green } from "@mui/material/colors";
import "./DocumentViewer.css";
import * as state from "react-doc-viewer/build/plugins/pdf/state/reducer";

const DocumentViewer = () => {
  // const myHeaders = new Headers(); // Currently empty
  // myHeaders.set("Content-Type", "application/pdf");
  // myHeaders.set("Content-Disposition", "inline; filename=sample.pdf");
  // myHeaders.append("Content-Type", "text/html");
  // Content-Type: application/pdf
  // Content-Disposition: inline; filename="sample.pdf"
  const [url, setUrl] = useState("");
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  useEffect(() => {
    setHeight(window.innerHeight - 10);
    setTimeout(() => {
      console.log(`jquery `, $("#myframe").height());
    }, 10000);
  }, []);
  useEffect(async () => {
    let queryString = window.location.href;
    if (queryString.indexOf("documentviewer/") !== -1) {
      let url = queryString.split("documentviewer/")[1];
      url = url ? window.base64_decode(url) : "";
      // if (url?.indexOf("#page=") !== -1) {
      //   let pageNo = url.split("#page=")[1];
      //   state.initialPDFState.currentPage = parseInt(pageNo);
      // }
      // const response = await fetch(url, {
      //   method: "GET",
      // });
      // const blob = await response.blob();
      // const newBlob = new Blob([blob], {
      //   type: "application/pdf",
      // });
      // const blobUrl = window.URL.createObjectURL(newBlob);
      // // console.log(blobUrl);
      // if (response.status === 200) {
      //   setUrl(blobUrl);
      // }
      setUrl(url);
    }
  }, []);

  if (url === "") {
    return (
      <Grid
        container
        spacing={0}
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh", textAlign: "center" }}
      >
        <p xs={6} style={{ fontSize: "18px", color: green }}>
          Document not available for preview
        </p>
      </Grid>
    );
  } else {
    return (
      <div ref={ref}>
        <iframe 
          id="myframe"
          src={`${url}`}
          width="100%"
          height={height}
        />
      </div>
      // <DocViewer
      //   className="myDocViewerStyle"
      //   pluginRenderers={DocViewerRenderers}
      //   documents={[
      //     {
      //       uri: url,
      //     },
      //   ]}
      //   style={{ width: "100%", height: "100vh" }}
      //   key={url}
      //   config={{
      //     header: {
      //       disableHeader: false,
      //       disableFileName: false,
      //       retainURLParams: false,
      //     },
      //   }}
      // />
    );
  }
};

export default memo(DocumentViewer);
