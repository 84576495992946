import React from "react";
import Addaudit from "./add/addaudit";
import List from "./list/list";
import { AuditContextProvider } from "../context/auditContext";
import AuditManagementMain from "../../ara/main/AuditManagementMain";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../applicationInsights/AppInsights';

const GetInnerComponents = () => {
  return (
    <div>
      <AuditContextProvider>
        <div className="container-main">
          <Addaudit />
        </div>
        <div className="container-main">
          <List />
        </div>
      </AuditContextProvider>
    </div>
  );
};

const Manageaudit = () => {
  return <AuditManagementMain content={GetInnerComponents()} />;
};

// export default Manageaudit;
export default withAITracking(reactPlugin, Manageaudit);
