import React, { useState, useEffect } from "react";
//import Box from '@mui/material/Box';
import { Box, Button, FormControlLabel, Grid, Switch } from "@mui/material";
// import csvIcon from "../../assets/images/csv-icon.png";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
// import ConfirmDialog from "../Common/confirmDailog";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomDateRangeInputs from "../ara/filters/daterange";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";

import CustomizedDialogs from "../CommonRowModel/CommonRowModel";
import Pivot from "../ara/Pivot/Pivot";

import {
  DataGrid,
  GridPagination,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import CommonHeading from "../Common/CommonHeading";
import Notes from "../Common/Notes";
// import DownloadExportExcel from "./DownloadExportExcel";
import DownloadExportExcel from "./DownloadExportExcel";
import GenExcel from "./GenExcel";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import NotesTemplateSectionManagement from "../Common/NotesTemplateSectionManagement";

export function CustomFooterComponent(props) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} justify="flex-end" alignItems="center">
        <Grid item xs={8} sx={{ mt: 1, mb: 1 }}>
          {!!props.templatesectionmanagement && (
            <NotesTemplateSectionManagement
              note="Use this screen to map ARA
                    template sections to RIMA application sections so that
                    insights are added under correct sections in the generated
                    ARA document."
            />
          )}
          {!props.templatesectionmanagement &&
            !props.hidefilter &&
            props?.breadcrums?.childMenu &&
            window.allowedStartDate["VX"].includes(
              props?.breadcrums?.childMenu
            ) && (
              <Notes
                note={
                  <ol style={{ margin: "5px 0 0 15px", padding: 0 }}>
                    <li>
                      {window.dateFilterNotes[
                        props.breadcrums.vaccine ? "VX" : "RX"
                      ][props.breadcrums.childMenu]
                        ? window.dateFilterNotes[
                            props.breadcrums.vaccine ? "VX" : "RX"
                          ][props.breadcrums.childMenu]
                        : ""}
                    </li>
                    <li>
                      Insights will be uniquely generated based on record IDs
                      (for SAP and VQMS Data).
                    </li>
                  </ol>
                }
              />
            )}
        </Grid>
        <Grid item xs={4}>
          <GridPagination />
        </Grid>
      </Grid>
    </Box>
  );
}

export function QuickSearchToolbar(props) {
  const handleDeviationStatus = (e) => {
    e.preventDefault();
    if (props.deviation !== e.target.value) {
      props.setDeviation(e.target.value);
      props.setBreadcrums((prevBreadcrums) => {
        prevBreadcrums.DeviationClassification = e.target.value;
        return { ...prevBreadcrums };
      });
    }
  };
  const ytdManipulation = (ytdVal) => {
    let startDate;
    let endDate;
    switch (parseInt(ytdVal)) {
      case 12:
      case 36:
      case 24:
      case 60:
        startDate = window.addMonthToDate(
          new Date(),
          parseInt(ytdVal),
          "start"
        );
        endDate = window.addMonthToDate(startDate, parseInt(ytdVal), "end");
        props.setBreadcrums((prevBreadcrums) => {
          prevBreadcrums.startDates = startDate;
          prevBreadcrums.endDate = endDate;
          prevBreadcrums.filterActionReloadSection =
            props.breadcrums.filterActionReloadSection + 1;
          return { ...prevBreadcrums };
        });

        break;
      case 0: //YTD
        let currYear = new Date().getFullYear();
        startDate = `${currYear}-01-01`;
        endDate = new Date();
        props.setBreadcrums((prevBreadcrums) => {
          prevBreadcrums.startDates = startDate;
          prevBreadcrums.endDate = endDate;
          prevBreadcrums.filterActionReloadSection =
            props.breadcrums.filterActionReloadSection + 1;

          return { ...prevBreadcrums };
        });

        break;

      default:
        break;
    }
  };
  const handleOnClickYTD = (e) => {
    props.setYtd(e.target.value);
    ytdManipulation(e.target.value);
  };
  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const reloadData = () => {
    props.setBreadcrums((prevBreadcrums) => {
      if (
        !window.hideEndDate[props.breadcrums.vaccine ? "VX" : "RX"].includes(
          props.breadcrums.childMenu
        ) &&
        formatDate(prevBreadcrums.startDates) >
          formatDate(prevBreadcrums.endDate)
      ) {
        window.showMessage(
          "End date should be greater than start date",
          "warning"
        );
      } else {
        prevBreadcrums.filterActionReloadSection =
          props.breadcrums.filterActionReloadSection + 1;
        props.setLoading(true);
      }

      return { ...prevBreadcrums };
    });
  };
  const label = { inputProps: { "aria-label": "On Going Audits" } };
  return (
    <GridToolbarContainer sx={{ position: "relative" }}>
      {/* <Box
        sx={{ m: 1, p: 1, fontSize: "1.2rem", fontWeight: "700" }}
        display="flex"
        justifyContent="flex-center"
      >
        {props.menu}
      </Box> */}
      <Box
        // width="300px"
        display="flex"
        justifyContent="flex-end"
        // sx={{ right: "0", position: "absolute" }}
        sx={{ minHeight: "55px" }}
      >
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Grid
            item
            xs={
              props?.hidefilter
                ? 6
                : props?.breadcrums?.childMenu === "Deviation Handling"
                ? 2
                : 4
            }
            sx={{ left: "0", position: "absolute" }}
          >
            <CommonHeading heading={props.menu} sx={{ mr: 0 }} />
          </Grid>
          <Grid
            item
            xs={props?.breadcrums?.childMenu === "Deviation Handling" ? 10 : 8}
            sx={{ right: "0", position: "absolute" }}
          >
            <Grid
              container
              spacing={2}
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              {props.deleteAllButton && (
                <Grid
                  item
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  sx={{ pl: "35%", zIndex: 999 }}
                >
                  <FormControl required sx={{ m: 1, width: "100%" }}>
                    <Button
                      sx={{
                        height: "37px",
                        width: "180px",
                        backgroundColor: "transparent !important",
                      }}
                      size="small"
                      startIcon={<DeleteIcon />}
                      disabled={!props.selectionModel.length}
                      onClick={props.handleDeleteAll}
                    >
                      Delete Selected
                    </Button>
                  </FormControl>
                </Grid>
              )}
              {props?.handleDownloadDocumet && (
                <Grid
                  item
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  sx={{ pl: "35%", zIndex: 999 }}
                >
                  <Box>
                    <FormControlLabel
                      sx={{
                        display: "none",
                      }}
                      control={
                        <Switch
                          checked={props.downloadLoading}
                          onChange={() =>
                            props.setDownloadLoading(!props.downloadLoading)
                          }
                          name="loading"
                          color="primary"
                        />
                      }
                      label="Loading"
                    />
                    <Box
                      sx={{
                        "& > button": { m: 1 },
                        "& .MuiSvgIcon-root": {
                          color: "#15717D !important",
                        },
                        "& .Mui-disabled": {
                          background: "none !important",
                        },
                      }}
                    >
                      <LoadingButton
                        loading={props.downloadLoading}
                        loadingPosition="start"
                        startIcon={<FileDownloadIcon />}
                        type="submit"
                        sx={{ textTransform: "capitalize" }}
                        variant="text"
                        size="small"
                        disabled={!props.selectionModel.length}
                        onClick={props.handleDownloadDocumet}
                      >
                        {props.downloadLoading ? "Downloading..." : "Download"}
                      </LoadingButton>
                    </Box>
                  </Box>
                </Grid>
              )}
              {props.enableAuditListFilter && (
                <Grid
                  item
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  sx={{ pl: "40%", zIndex: 999 }}
                >
                  <FormControl required sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="demo-simple-select-required-label">
                      Audit Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-required-label"
                      id="data_source"
                      value={props.auditStatus ? props.auditStatus : 2}
                      label="Audit Status *"
                      onChange={props.handleChangeAuditStatus}
                      sx={{ height: "37px", width: "150px" }}
                      name="data_source"
                    >
                      <MenuItem key={1} value="1">
                        Created
                      </MenuItem>
                      <MenuItem key={2} value="2" selected>
                        Initiated
                      </MenuItem>
                      <MenuItem key={3} value="3">
                        Closed
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {props.menu === "Site Management" && (
                <Grid
                  item
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  sx={{ pl: "40%", zIndex: 999 }}
                >
                  <FormControl required sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="demo-simple-select-required-label">
                      Data Source
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-required-label"
                      id="data_source"
                      value={
                        props.sitemanagementDataSource
                          ? props.sitemanagementDataSource
                          : "vx"
                      }
                      label="Data Source *"
                      onChange={props.siteManagementHandleChangeDataSource}
                      sx={{ height: "37px", width: "150px" }}
                      name="data_source"
                    >
                      <MenuItem key={1} value="vx" selected>
                        Vx
                      </MenuItem>
                      <MenuItem key={2} value="rx">
                        Rx
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {props.auditCheckBox !== undefined &&
              typeof props.user.role !== undefined && Array.isArray(props.user.role) && !props.user.role.includes(3) &&
              !props.hidefilter ? (
                <Grid item display="flex" sx={{ padding: "0 0 0 7px" }}>
                  <Checkbox
                    {...label}
                    checked={props.auditCheckBox}
                    onChange={
                      (() => props.auditCheckCall(!props.auditCheckBox),
                      () => {
                        props.auditCheckBox
                          ? props.setShowAlert(true)
                          : props.setBreadcrums((prevBreadcrums) => {
                              prevBreadcrums.auditTitle = "";
                              prevBreadcrums.closedAudits = false;
                              prevBreadcrums.resetCountryFilter = true;
                              prevBreadcrums.laoadClosedAudits = Math.random();
                              return { ...prevBreadcrums };
                            });
                      })
                    }
                  />
                  <label
                    style={{
                      marginTop: "12px",
                      fontWeight: 550,
                      fontStyle: "normal",
                      // fontSize: "13px",
                    }}
                  >
                    Display All Initiated Audits
                  </label>
                </Grid>
              ) : (
                ""
              )}
              {!props.hidefilter &&
                props?.breadcrums?.childMenu &&
                window.allowedStartDate["VX"].includes(
                  props?.breadcrums?.childMenu
                ) && (
                  <Grid
                    item
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    sx={{
                      padding: "0 0 0 0",
                      margin: "0 0 0 0",
                      zIndex: 3,
                      // width: "62%",
                    }}
                  >
                    {props?.breadcrums?.childMenu === "Deviation Handling" && (
                      <div className="form_control">
                        <FormControl required>
                          <InputLabel id="demo-simple-select-required-label1">
                            Deviation Classification
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-required-label1"
                            id="data_source1"
                            value={props.deviation}
                            label="Deviation Classification"
                            onChange={handleDeviationStatus}
                            sx={{ height: "34px", width: "140px" }}
                            name="data_source1"
                          >
                            {props.deviationClassificationList &&
                              props.deviationClassificationList.map(
                                (deviationClassification) => {
                                  return (
                                    <MenuItem
                                      key={
                                        deviationClassification?.DeviationClassification
                                      }
                                      value={
                                        deviationClassification?.DeviationClassification
                                      }
                                    >
                                      {
                                        deviationClassification?.DeviationClassification
                                      }
                                    </MenuItem>
                                  );
                                }
                              )}
                            <MenuItem key={"ALL"} value={"ALL"}>
                              All
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    )}
                    <div className="form_control">
                      <FormControl required>
                        <InputLabel id="demo-simple-select-required-label">
                          Period
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-required-label"
                          id="data_source"
                          value={props.ytd}
                          label="Period"
                          onChange={handleOnClickYTD}
                          sx={{ height: "34px", width: "95px" }}
                          name="data_source"
                          disabled={false}
                        >
                          <MenuItem
                            key={12}
                            value="12"
                            disabled={
                              window.hideEndDate[
                                props.breadcrums.vaccine ? "VX" : "RX"
                              ].includes(props.breadcrums.childMenu)
                                ? true
                                : false
                            }
                          >
                            1 Year
                          </MenuItem>
                          <MenuItem
                            key={24}
                            value="24"
                            disabled={
                              window.hideEndDate[
                                props.breadcrums.vaccine ? "VX" : "RX"
                              ].includes(props.breadcrums.childMenu)
                                ? true
                                : false
                            }
                          >
                            2 Years
                          </MenuItem>
                          <MenuItem
                            key={36}
                            value="36"
                            disabled={
                              window.hideEndDate[
                                props.breadcrums.vaccine ? "VX" : "RX"
                              ].includes(props.breadcrums.childMenu)
                                ? true
                                : false
                            }
                          >
                            3 Years
                          </MenuItem>
                          <MenuItem
                            key={0}
                            value="0"
                            disabled={
                              window.hideEndDate[
                                props.breadcrums.vaccine ? "VX" : "RX"
                              ].includes(props.breadcrums.childMenu)
                                ? true
                                : false
                            }
                          >
                            YTD
                          </MenuItem>
                          <MenuItem key={13} value="13">
                            Custom
                          </MenuItem>
                          <MenuItem
                            key={60}
                            value="60"
                            disabled={
                              window.hideEndDate[
                                props.breadcrums.vaccine ? "VX" : "RX"
                              ].includes(props.breadcrums.childMenu)
                                ? true
                                : false
                            }
                          >
                            All (max. 5 yr)
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div
                      className="form_control"
                      style={{ marginRight: "0px" }}
                    >
                      <CustomDateRangeInputs
                        dataSource={props.breadcrums.vaccine ? "VX" : "RX"}
                        disabledInput={
                          // props.breadcrums?.disabledAuditFildWorkDate ||
                          parseInt(props.ytd) !== 13
                        }
                        menu={
                          props.breadcrums.childMenu !== undefined
                            ? props.breadcrums.childMenu
                            : ""
                        }
                        onChange={props.datesHandler}
                        valueStartDate={props.breadcrums.startDates}
                        valueEndDate={props.breadcrums.endDate}
                        breadcrums={props.breadcrums}
                      />
                    </div>

                    {!props.loading && (
                      <IconButton
                        onClick={reloadData}
                        title="Refresh"
                        size="small"
                        sx={{ margin: "0px" }}
                        disabled={parseInt(props.ytd) !== 13 ? true : false}
                      >
                        <RestartAltIcon />
                      </IconButton>
                    )}
                    {props.loading && (
                      <IconButton sx={{ margin: "0px" }}>
                        <CircularProgress size={15} thickness={4} />
                      </IconButton>
                    )}
                  </Grid>
                )}
              {props.menu !== "Upload Document" &&
              props.menu1 !== "Upload Document" ? (
                <Grid
                  item
                  style={{
                    paddingLeft: "0px",
                    margin: "0px",
                    width: "145px",
                  }}
                >
                  {props?.hidefilter ? (
                    <DownloadExportExcel
                      rows={props.rows}
                      columns={props.columns}
                      heading={props.menu}
                    />
                  ) : (
                    <GenExcel
                      columns={props.columns}
                      heading={props.menu}
                      gridSelectionModel={props.gridSelectionModel}
                      breadcrums={props.breadcrums}
                      auditID={props?.auditID}
                      auditStatus={props.auditStatus}
                      auditTrailSource={props?.auditTrailSource}
                      auditNumber={props?.auditNumber}
                      featureName={props?.featureName}
                    />
                  )}

                  {/* <Button
                    variant="text"
                    startIcon={<FileDownloadIcon />}
                    onClick={downloadExcel}
                  >
                    Excel
                  </Button> */}
                  {/* <GridToolbarExport
                    printOptions={props.printOptions}
                    style={{ paddingLeft: "0px" }}
                  /> */}
                </Grid>
              ) : (
                ""
              )}
              <Grid item style={{ width: "150px" }}>
                <GridToolbarQuickFilter />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </GridToolbarContainer>
  );
}
const DataView = (props) => {
  const [pageSize, setPageSize] = useState(5);
  const [showAlert, setShowAlert] = React.useState(false);
  const [page, setPage] = useState(0);
  const [rowEffected, setRowsEffected] = useState(true);
  const [loading, setLoading] = React.useState(false);

  const [dialogRowData, setDialogRowData] = React.useState(false);
  const [dialogData, setDialogData] = React.useState(null);
  const [sortModel, setSortModel] = React.useState([]);

  const [gridSelectionModel, setGridSelectionModel] = React.useState([]);

  const handleRowClick = (childData) => {
    setDialogRowData(true);
    setDialogData(childData.row);
  };

  // const [selectionModel, setSelectionModel] = React.useState([]);
  useEffect(() => {
    setRowsEffected(false);
    setTimeout(() => {
      setRowsEffected(true);
    });
    setLoading(false);
  }, [props.rows]);
  useEffect(() => {
    if (showAlert) {
      window.showMessage(
        auditCheckBox
          ? "To view specific audit select audit from Audit list present above."
          : "Please de-select Audit",
        "info"
      );
      setShowAlert(false);
    }
  }, [showAlert]);

  let tableRow = props.rows;
  if (tableRow && tableRow.length > 0) {
    const filterDateFiled = props.columns.filter((obj) => {
      return (obj.type && obj.type === "date") ||
        (obj.type1 && obj.type1 === "date")
        ? true
        : false;
    });
    let addTime = false;
    if (filterDateFiled.length > 0) {
      tableRow.map((obj) => {
        filterDateFiled.map((dateObj) => {
          // console.log('addTime ',dateObj.headerName)
          addTime = false;
          if (dateObj.headerName && dateObj.headerName === "Date & Time")
            addTime = true;
          // console.log('addTime ',addTime);
          //dateObj.type = "string";
          return (obj[`${dateObj.field}`] = window.getDate(
            obj[`${dateObj.field}`],
            addTime
          ));
        });
        return true;
      });
    }
  }
  // console.log("tableRow ", tableRow);
  let data = tableRow; //props.rows;
  let columns = props.columns;
  let headername = props.menuName;
  let headername1 = props.menuName1 !== undefined ? props.menuName1 : "";
  let auditCheckBox = props.auditCheckBox;
  useEffect(() => {
    setPage(0);
  }, [props.rows]);

  const prop = props.getRowId ? { getRowId: props.getRowId } : {};

  useEffect(() => {
    switch (props?.breadcrums?.childMenu) {
      case "Corrective & Preventive Actions":
        if (props.breadcrums.vaccine) {
          props?.hidefilter
            ? setSortModel([{ field: "Overdue (in Days)", sort: "desc" }])
            : setSortModel([{ field: "dayslate", sort: "desc" }]);
        } else {
          props?.hidefilter
            ? setSortModel([{ field: "Overdue (in Days)", sort: "desc" }])
            : setSortModel([{ field: "days_late", sort: "desc" }]);
        }
        break;
      case "Deviation Handling":
        if (props.breadcrums.vaccine) {
          props?.hidefilter
            ? setSortModel([{ field: "Overdue(In Days)", sort: "desc" }])
            : setSortModel([{ field: "Overdue", sort: "desc" }]);
        } else {
          props?.hidefilter
            ? setSortModel([{ field: "Overdue(In Days)", sort: "desc" }])
            : setSortModel([{ field: "overdue", sort: "desc" }]);
        }
        break;

      case "Warehouse Management":
        props?.hidefilter
          ? setSortModel([{ field: "Overdue(In Days)", sort: "desc" }])
          : setSortModel([{ field: "overdue", sort: "desc" }]);
        break;
      case "Laboratory Out of Specification":
        props?.hidefilter
          ? setSortModel([{ field: "Overdue(In Days)", sort: "desc" }])
          : setSortModel([{ field: "overdue", sort: "desc" }]);
        break;
      case "Document Management & Control":
        if (props.breadcrums.vaccine) {
          props?.hidefilter
            ? setSortModel([{ field: "Overdue (in Days)", sort: "desc" }])
            : setSortModel([{ field: "overdue", sort: "desc" }]);
        } else {
          !props?.hidefilter &&
            setSortModel([{ field: "days_late", sort: "desc" }]);
        }
        break;
      case "SOP Index":
        if (props.breadcrums.vaccine) {
          props?.hidefilter
            ? setSortModel([{ field: "Overdue (in Days)", sort: "desc" }])
            : setSortModel([{ field: "overdue", sort: "desc" }]);
        } else {
          !props?.hidefilter &&
            setSortModel([{ field: "is_review_overdue", sort: "desc" }]);
        }
        break;
      case "Monitoring & Reviewing of Environmental Control & Utilities":
        if (props.breadcrums.vaccine) {
          props?.hidefilter
            ? setSortModel([{ field: "Overdue(in Days)", sort: "desc" }])
            : setSortModel([{ field: "Overdue", sort: "desc" }]);
        } else {
          props?.hidefilter
            ? setSortModel([{ field: "Overdue(In Days)", sort: "desc" }])
            : setSortModel([{ field: "overdue", sort: "desc" }]);
        }
        break;
      case "Data Management & Control":
        if (props.breadcrums.vaccine) {
          props?.hidefilter
            ? setSortModel([{ field: "Overdue(In Days)", sort: "desc" }])
            : setSortModel([{ field: "Overdue", sort: "desc" }]);
        } else {
          props?.hidefilter
            ? setSortModel([{ field: "Overdue(In Days)", sort: "desc" }])
            : setSortModel([{ field: "overdue", sort: "desc" }]);
        }
        break;
      case "Cleaning Validation & Verification":
        if (props.breadcrums.vaccine) {
          props?.hidefilter
            ? setSortModel([{ field: "Overdue(In Days)", sort: "desc" }])
            : setSortModel([{ field: "Overdue", sort: "desc" }]);
        } else {
          props?.hidefilter
            ? setSortModel([{ field: "Overdue(In Days)", sort: "desc" }])
            : setSortModel([{ field: "overdue", sort: "desc" }]);
        }
        break;
      case "Repacking":
        props?.hidefilter
          ? setSortModel([{ field: "Overdue(In Days)", sort: "desc" }])
          : setSortModel([{ field: "overdue", sort: "desc" }]);
        break;
      case "Sterile Process Validation, Monitoring & Control":
        if (props.breadcrums.vaccine) {
          props?.hidefilter
            ? setSortModel([{ field: "Overdue(In Days)", sort: "desc" }])
            : setSortModel([{ field: "Overdue", sort: "desc" }]);
        } else {
          props?.hidefilter
            ? setSortModel([{ field: "Overdue(In Days)", sort: "desc" }])
            : setSortModel([{ field: "overdue", sort: "desc" }]);
        }
        break;
      case "Change Control":
        props?.hidefilter
          ? setSortModel([{ field: "Change Control Open", sort: "desc" }])
          : setSortModel([{ field: "change_control_open", sort: "desc" }]);
        break;
      case "Maintenance & Calibration":
        props?.hidefilter
          ? setSortModel([{ field: "Overdue(In Days)", sort: "desc" }])
          : setSortModel([{ field: "overdue", sort: "desc" }]);
        break;

      default:
        setSortModel([]);
        break;
    }
  }, [props.menuName]);

  return (
    <div style={{ width: "100%" }}>
      {rowEffected && (
        <>
          <CustomizedDialogs
            dialogRowData={dialogRowData}
            setDialogRowData={setDialogRowData}
            data={dialogData}
            columnsData={columns}
            menu={props.menuName}
          />
          {props.breadcrums?.parentMenu &&
          props.breadcrums?.parentMenu === "Pivot Analysis" &&
          window.PivotConfig[props.breadcrums.vaccine ? "VX" : "RX"].includes(
            props.breadcrums.childMenu
          ) ? (
            <Pivot
              data={props.breadcrums.tableRowData}
              filename={props.breadcrums.childMenu}
              columns={props.breadcrums.tableColumnData}
              setBreadcrums={props.setBreadcrums}
              breadcrums={props.breadcrums}
              valueStartDate={props.valueStartDate}
              setValueStartDate={props.setValueStartDate}
              valueEndDate={props.valueEndDate}
              setValueEndDate={props.setValueEndDate}
              datesHandler={props.datesHandler}
              ytd={props.ytd}
              setYtd={props.setYtd}
              deviation={props.deviation}
              setDeviation={props.setDeviation}
              deviationClassificationList={props.deviationClassificationList}
              loading={loading}
              setLoading={setLoading}
              gridSelectionModel={gridSelectionModel}
            />
          ) : (
            <DataGrid
              initialState={{
                sorting: {
                  sortModel: sortModel,
                },
              }}
              rows={data}
              columns={columns}
              // sortModel={sortModel}
              pageSize={pageSize}
              autoHeight={true}
              page={page}
              onPageChange={(newPage) => setPage(newPage)}
              rowsPerPageOptions={[5, 10, 20]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              checkboxSelection={!props.checkboxSelection ? false : true}
              disableSelectionOnClick
              // onRowClick={props.rowDisable ? null : handleRowClick}
              onCellDoubleClick={props.rowDisable ? null : handleRowClick}
              isRowSelectable={(params) => {
                let flag = true;
                if (props.isRowSelectable && props.isRowSelectableSection) {
                  switch (props.isRowSelectableSection) {
                    case "Document_Management":
                      flag = params.row.is_latest_document <= 0;
                      break;
                    default:
                      flag = true;
                      break;
                  }
                }
                return flag;
              }}
              components={{
                Toolbar: QuickSearchToolbar,
                ExportIcon: FileDownloadIcon,
                Footer: CustomFooterComponent,
              }}
              componentsProps={{
                toolbar: {
                  menu: headername,
                  menu1: headername1,
                  printOptions: { disableToolbarButton: true },
                  auditCheckBox: auditCheckBox,
                  auditCheckCall: props.auditCheckCall,
                  setShowAlert: setShowAlert,
                  setShowAlertMsg: setShowAlert,
                  setBreadcrums: props.setBreadcrums,
                  user: props.user,
                  hidefilter: props.hidefilter,
                  deleteSelected: props.deleteSelected,
                  enableAuditListFilter: props.enableAuditListFilter
                    ? true
                    : false,
                  handleChangeAuditStatus: props.handleChangeAuditStatus
                    ? props.handleChangeAuditStatus
                    : false,
                  auditStatus: props.auditStatus,
                  siteManagementHandleChangeDataSource:
                    props.siteManagementHandleChangeDataSource
                      ? props.siteManagementHandleChangeDataSource
                      : false,
                  sitemanagementDataSource: props?.sitemanagementDataSource,
                  deleteAllButton: props.deleteAllButton ? true : false,
                  selectionModel: props.selectionModel
                    ? props.selectionModel
                    : false,
                  handleDeleteAll: props.handleDeleteAll
                    ? props.handleDeleteAll
                    : () => {},
                  handleDownloadDocumet: props.handleDownloadDocumet
                    ? props.handleDownloadDocumet
                    : false,
                  setDownloadLoading: props?.setDownloadLoading,
                  downloadLoading: props?.downloadLoading,
                  //auditCheckCall:{(val)=>props.auditCheckCall(val)}
                  valueStartDate: props.startDates,
                  valueEndDate: props.endDate,
                  datesHandler: props.datesHandler,
                  breadcrums: props.breadcrums,
                  ytd: props.ytd,
                  setYtd: props.setYtd,
                  loading: loading,
                  setLoading: setLoading,
                  deviation: props.deviation,
                  setDeviation: props.setDeviation,
                  deviationClassificationList:
                    props.deviationClassificationList,
                  rows: data,
                  columns: columns,
                  gridSelectionModel: gridSelectionModel,
                  auditID: props?.auditID,
                  auditTrailSource: props?.auditTrailSource,
                  auditNumber: props?.auditNumber,
                  featureName: props?.featureName,
                },
                footer: {
                  breadcrums: props.breadcrums,
                  hidefilter: props.hidefilter,
                  templatesectionmanagement: props.templatesectionmanagement,
                },
              }}
              onSelectionModelChange={(selectionModel) => {
                // eslint-disable-next-line no-lone-blocks
                {
                  setGridSelectionModel(selectionModel);
                  if (props?.isInsights) {
                    if (props?.outerPos && props?.innerPos) {
                      props.handleMulSelection &&
                        props.handleMulSelection(
                          props.outerPos,
                          props.innerPos,
                          selectionModel
                        );
                      props.setSelectionModel &&
                        props.setSelectionModel(selectionModel);
                    }
                  } else {
                    props.setSelectionModel &&
                      props.setSelectionModel(selectionModel);
                  }
                }
              }}
              resizable={true}
              {...prop}
              sx={{ width: "100%" }}
            />
          )}
        </>
      )}
      {/* <ConfirmDialog
        title=""
        open={showAlert}
        setOpen={setShowAlert}
        isAlert={true}
      >
        {auditCheckBox ? "Please select Audit" : "Please de-select Audit"}
      </ConfirmDialog> */}
    </div>
  );
};
export default DataView;
