/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import gskLogo from "../../../src/assets/images/u50.svg";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useHistory } from "react-router-dom";
// import axios from "axios";
// import * as rimaConstants from "../../rimaconstants";
import { UserDataContext } from "../Context/UserDataContext";

function Header(props) {
  const { instance, accounts } = useMsal();
  function handleLogout(instance) {
    localStorage.removeItem("userDocsUploaded");
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
    //localStorage.clear()
  }
  function handleLogin(instance) {
    instance.loginRedirect().catch((e) => {
      console.error(e);
    });
  }
  function LoggedInUser() {
    let history = useHistory();
    function handleRedirectAdminDashboard() {
      history.push("/admin/dashboard");
    }

    // eslint-disable-next-line no-unused-vars
    const [adminUser, setAdminUser, user, setUser] = useContext(
      UserDataContext
    );

    return (
      <Box sx={{ p: 1, flexGrow: 1, textAlign: "right", width: "33.3%" }}>
        <span style={{ color: "#f36633" }}>
          {" "}
          Hi <span style={{ color: "#02709f" }}> {accounts[0].name}</span>{" "}
        </span>
        <Box sx={{ flexDirection: "row", marginTop: "10px" }}>
          {adminUser === "1" ? (
            <Typography variant="h8" component="h9" mt={1.5}>
              <a href="JavaScript:;" onClick={handleRedirectAdminDashboard}>
                Admin
              </a>
            </Typography>
          ) : (
            ""
          )}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Typography variant="h8" component="h9" mt={1.5}>
            <a href="JavaScript:;" onClick={() => handleLogout(instance)}>
              Sign Out
            </a>
          </Typography>
        </Box>
      </Box>
    );
  }
  function RequestLogin() {
    return (
      <Box sx={{ p: 1, flexGrow: 1, textAlign: "right", width: "33.3%" }}>
        <br />
        <br />
        <a onClick={() => handleLogin(instance)}>Sign In</a>
      </Box>
    );
  }
  function Greeting() {
    if (useIsAuthenticated()) return <LoggedInUser />;
    else return <RequestLogin />;
  }

  return (
    <div style={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          bgcolor: "#ffffff",
          borderBottom: "3px solid rgb(243, 102, 51)",
        }}
      >
        <Box sx={{ p: 1, flexGrow: 1, width: "33.3%" }}>
          <img src={gskLogo} alt="Logo" />
        </Box>
        <Box sx={{ p: 1, flexGrow: 1, textAlign: "center", width: "33.3%" }}>
          <Typography
            style={{ fontWeight: 700, fontSize: "18px" }}
            variant="h5"
            component="h5"
            mt={1.5}
          >
            Reimagine Audit
          </Typography>
        </Box>
        {props && props?.hideUserInfo ? <Box sx={{ p: 1, flexGrow: 1, textAlign: "right", width: "33.3%" }}></Box> : <Greeting></Greeting>}
      </Box>
    </div>
  );
}

export default Header;
