import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ResultContent from "./ReviewSuggetionsFile";
import SuggestionsContent from "./ReviewSuggetsionsText";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;



export default function ASMReviewContent() {
  const [activeContent, setActiveContent] = useState("resultTab");

  const ASMReviewContentHandleChange = (event, activeTab) => {
    setActiveContent(activeTab);
    // console.log(activeTab);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box
            sx={{ mb: "15px", textAlign: "center", minWidth: "800px" }}
            className="ASMToggleBtns"
          >
            <ToggleButtonGroup
              fullWidth
              //   color="#15717d"
              value={activeContent}
              exclusive
              size="large"
              onChange={ASMReviewContentHandleChange}
            >
              <ToggleButton
                className={
                  activeContent === "resultTab" || activeContent === null
                    ? "gsk-btn primary"
                    : "gsk-btn"
                }
                value="resultTab"
                sx={{ minWidth: "400px" }}
                // onClick="resultHandlerChange"
              >
                Audit Draft Findings Documents
              </ToggleButton>
              <ToggleButton
                className={
                  activeContent === "suggestionsTab​" || activeContent === null
                    ? "gsk-btn primary"
                    : "gsk-btn"
                }
                value="suggestionsTab​"
                sx={{ minWidth: "400px" }}
              >
                Audit Draft Text
                {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Audit Draft Text&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Grid>
      </Grid>
      {activeContent === "resultTab" || activeContent === null ? (
        <ResultContent key="resultsContainer" />
      ) : (
        <SuggestionsContent key="suggetionsContainer" />
      )}
    </Box>
  );
}
