import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  IconButton,
  Dialog,
  DialogContentText,
  DialogActions,
  DialogContent,
  Box,
  TextareaAutosize,
  Switch,
} from "@mui/material";
import * as rimaConstants from "../../../rimaconstants";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import CommonHeading from "../../Common/CommonHeading";
import DataView from "../../DataTable/DataView";
import ButtonGroup from "@mui/material/ButtonGroup";
import EditIcon from "@mui/icons-material/Edit";
import styled from "@emotion/styled";

const SiteManagement = () => {
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const [dataSource, setDataSource] = useState("vx");
  const [open, setOpen] = useState(false);
  const [siteDescription, setSiteDescription] = useState("");
  const [heading, setHeading] = useState([]);
  const [siteDetails, setSiteDetails] = useState([]);
  const [siteName, setSiteName] = useState("");
  const [auditSiteID, setAuditSiteID] = useState(0);
  const [loadingSend, setLoadingSend] = useState(false);

  const [height, setHeight] = React.useState(0);
  const ref = React.useRef(null);

  useEffect(() => {
    setHeight(window.innerHeight - 210);
  }, []);

  const handleChangeDataSource = (e) => {
    setDataSource(e.target.value);
  };

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  const handleChangeSiteDesc = (event) => {
    setSiteName(event.target.value);
  };

  const handleIsEnabled = (audit_site_id, is_enabled) => {
    window.showLoader();
    axios
      .put(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/siteManagement/isEnabled/${audit_site_id}`
      )
      .then((response) => {
        is_enabled
          ? window.showMessage("Site disabled successfully", "info")
          : window.showMessage("Site enabled successfully", "info");
        getAuditSites();
      });
  };
  const getAuditSites = () => {
    window.showLoader();
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/siteManagement/list/${dataSource}`
      )
      .then((response) => {
        let head = {
          field: "action",
          headerName: "Action",
          width: 150,
          sortable: false,
          renderCell: (params) => {
            const onClickEdit = (e, id = params.row.audit_site_id) => {
              e.stopPropagation();
              setAuditSiteID(parseInt(id));
              let data = response.data.data.filter((obj) => {
                return parseInt(obj.audit_site_id) === parseInt(id);
              });
              setSiteDescription(
                data[0] && data[0].site_description
                  ? data[0].site_description
                  : ""
              );
              setOpen(true);
            };
            return (
              <React.Fragment>
                <ButtonGroup
                  size="small"
                  variant="contained"
                  aria-label="outlined primary button group"
                  // sx={{ width: "10px" }}
                >
                  {
                    <IconButton onClick={onClickEdit} title="Edit" size="small">
                      <EditIcon />
                    </IconButton>
                  }
                </ButtonGroup>
              </React.Fragment>
            );
          },
        };
        response?.data?.heading.map((data) => {
          if (data.field && data.field === "is_enabled") {
            data.renderCell = (params) => {
              return (
                <AntSwitch
                  checked={params.row.is_enabled === "YES" ? true : false}
                  inputProps={{ "aria-label": "ant design" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleIsEnabled(
                      params.row.audit_site_id,
                      params.row.is_enabled === "YES" ? true : false
                    );
                  }}
                ></AntSwitch>
              );
            };
          }
        });

        response.data.heading.push(head);
        setHeading(response.data.heading);
        setSiteDetails(response.data.data);
        window.hideLoader();
      });
  };

  useEffect(() => {
    getAuditSites();
  }, []);
  useEffect(() => {
    getAuditSites();
  }, [dataSource]);

  const handleSubmit = () => {
    if (siteName) {
      setLoadingSend(true);
      let req_data = {
        site_name: siteName,
      };

      axios
        .put(
          `${rimaConstants.REACT_APP_API_URL}/api/v1/siteManagement/update/${auditSiteID}`,
          req_data
        )
        .then((response) => {
          setLoadingSend(false);
          if (response.data.status === "success") {
            getAuditSites();
            window.showMessage("Site name updated successfully", "success");
            setOpen(false);
          } else {
            window.showMessage(response.data["msg"], "warning");
          }
        })
        .catch(function (error) {
          setLoadingSend(false);
          console.log(error);
        });
    } else {
      window.showMessage("Please enter site name", "warning");
    }
  };

  return (
    <div ref={ref} style={{ minHeight: height }}>
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <DataView
            columns={heading}
            rows={siteDetails}
            menuName="Site Management"
            siteManagementHandleChangeDataSource={handleChangeDataSource}
            sitemanagementDataSource={dataSource}
          />
        </Grid>
      </Grid>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"sm"}
        fullScreen={false}
        onClose={handleClose}
        paperProps={{
          style: { borderRadius: 2 },
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box
              sx={{
                "& .MuiTextField-root": { m: 1, width: "100%" },
              }}
            >
              <div
                style={{
                  borderBottom: "1px solid #ddd",
                  padding: "10px",
                  width: "100%",
                }}
              >
                <CommonHeading heading="Modify Site" />
              </div>
              <div style={{ width: "100%" }}>
                <table border="0" style={{ borderSpacing: "0 15px" }}>
                  <tr>
                    <td>
                      <strong>Site Name: </strong>
                    </td>
                    <td>
                      <TextareaAutosize
                        rowsMin={8}
                        minRows={6}
                        style={{ width: "440px" }}
                        onChange={handleChangeSiteDesc}
                        // value={keywords}
                        defaultValue={siteDescription}
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{ mr: 1, textTransform: "none" }}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSubmit}
            endIcon={<SaveIcon />}
            loading={loadingSend}
            loadingPosition="end"
            variant="contained"
            sx={{ textTransform: "capitalize", mr: 4 }}
            // disabled={saveButton}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default SiteManagement;
