import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment";
import * as rimaConstants from "../../rimaconstants";
import axios from "axios";

const LoadDocument = () => {
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);
  const progressRef = React.useRef(() => {});
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);
  React.useEffect(() => {
    let url = window.location.href;
    if (url.indexOf("loaddocument/") !== -1) {
      let queryString = url?.split("loaddocument/")[1]?.split("?txt=");
      let nIntervId;
      const loadUrl = () => {
        if (window.BLOB_URL) {
          clearInterval(nIntervId);
          nIntervId = null;
          axios
            .get(`${rimaConstants.REACT_APP_API_URL}/api/v1/ara/loaddocument`)
            .then((response) => {
              if (response?.data?.status === "success") {
                window.location.href = `${
                  window.BLOB_URL +
                  decodeURIComponent(queryString[1]) +
                  window.BLOB_CONATINER_SAS_READ_TOKEN
                }&ts=${moment().utc().unix()}#page=${queryString[0]}`;
              }
            });
        }
      };
      if (!nIntervId) {
        nIntervId = setInterval(loadUrl, 1000);
      }
    }
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
    </Box>
  );
};

export default React.memo(LoadDocument);
