import { Typography, Grid, Paper } from "@mui/material";
import React from "react";
import CommonHeading from "../../Common/CommonHeading";

export default function PowerBIQualityDashboard(props) {
  const [dashboardUrl, setDashboardUrl] = React.useState();
  React.useEffect(() => {
    setDashboardUrl(window.QUALITY_KPIS_DASHBOARD_URLS[props.type]);
  }, []);
  return (
    <>
    <Grid
      container
      spacing={2}
      direction="row"
      justify="flex-end"
      alignItems="center"
    >
      <Grid item xs={4} sx={{ mt: 1, mb: 2 }}>
        <CommonHeading heading={props.breadcrums.childMenu} sx={{ mr: 0 }} />
      </Grid>
    </Grid>
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Grid container spacing={10}>
        <Grid item xs={12}>
          <Typography sx={{ fontStyle: "italic" }}>
            To access the {props.breadcrums.childMenu} please use the below link -{" "}
          </Typography>
          <a href={dashboardUrl} target="_blank">
            {dashboardUrl}
          </a>
        </Grid>
      </Grid>
    </Paper>
    </>
  );
}
