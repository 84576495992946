import React, { useState, useContext } from "react";
import Admintemplate from "../admintemplate/admintemplate";
import DocumentManagement from "../documentmanagement/documentmanagement";
import { BreadcrumbsContext } from "../context/breadcrumsContext";
import { Grid } from "@mui/material";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../applicationInsights/AppInsights';

const Content = () => {
  const { breadcrumbs, setBreadcrumbs } = useContext(BreadcrumbsContext);
  React.useEffect(() => {
    setBreadcrumbs((prevBreadcrumbs) => {
      prevBreadcrumbs.parentMenu = "Document Management";
      prevBreadcrumbs.childMenu = "";
      return { ...prevBreadcrumbs };
    });
  }, []);
  return (
    <div className="container-admindashboard">
      {/* <Grid container sx={{paddingBottom:"20px"}} >
        <Grid item xs={2}>
          <div className="menuBox">Document Management</div>
        </Grid>
      </Grid> */}
      <DocumentManagement></DocumentManagement>
    </div>
  );
};

const GetContent = () => {
  return <Content />;
};

const DocManagerMain = () => {
  return <Admintemplate content={GetContent()} />;
};

// export default DocManagerMain;
export default withAITracking(reactPlugin, DocManagerMain);

