import * as React from "react";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import * as rimaConstants from "../../../src/rimaconstants";
import { CircularProgress, IconButton, Skeleton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// import ConfirmDialog from "./confirmDailog";

const xhr = new XMLHttpRequest();

export default function FixedTags(props) {
  const [showAlert, setShowAlert] = React.useState(false);
  const [showAlertUnique, setShowAlertUnique] = React.useState(false);
  const [emails, setEmails] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [searchStart, setSearchStart] = React.useState("");
  const inputRef = React.useRef();

  React.useEffect(async () => {
    if (searchStart && search.length >= 3) {
      let token = await window.getAccessToken();
      xhr.abort();
      const method = "GET";
      const url = `${rimaConstants.REACT_APP_API_URL}/api/v1/user/emails/${props.role}?search=${search}`;
      xhr.onreadystatechange = function (e) {
        if (xhr.readyState === 4 && xhr.status === 200) {
          setSearchStart(false);
          let data = JSON.parse(JSON.parse(this.responseText));
          if (Array.isArray(data)) {
            setEmails(data);
            inputRef.current.querySelector("input").focus();
          } else {
            window.showMessage(
              "Unable to fetch user(s) from AD Group",
              "error"
            );
          }
        }
      };
      xhr.open(method, url);
      xhr.setRequestHeader("Content-type", "application/json; charset=utf-8");
      xhr.setRequestHeader("Accept", "application/json");
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      xhr.setRequestHeader("Currentmodule", `${window.getCurrentModuleName()}`);
      xhr.send();
    } else {
      if (searchStart) {
        window.showMessage("Atleast 3 characters are required", "warning");
        setSearchStart(false);
      }
    }
    // axios
    //   .get(
    //     `${rimaConstants.REACT_APP_API_URL}/api/v1/user/emails/${props.role}?search=${search}`
    //   )
    //   .then((response) => {
    //     let data = JSON.parse(response.data);
    //     if (Array.isArray(data)) {
    //       setEmails(data);
    //     } else {
    //       window.showMessage("Unable to fetch user(s) from AD Group", "error");
    //     }
    //   })
    //   .catch(function (e) {
    //     console.error(e.message);
    //   });
  }, [searchStart]);

  const fixedOptions = [];
  const [value, setValue] = React.useState([...fixedOptions]);
  React.useEffect(() => {
    if (props.value) {
      let arr = props.value.split(";");
      let arr1 = [];
      arr.map((val) => {
        arr1.push({ email_address: `${val}` });
        return true;
      });
      setValue([...fixedOptions, ...arr1]);
    } else {
      setValue([...fixedOptions]);
    }
  }, [props.value]);

  //   React.useEffect(() => {
  //       console.log('value ',value);
  //       props.setParentValues(value);
  //   }, [value]);
  const blurEvent = (e) => {
    e.target.value = e.target.value.trim();
    // setSearch(e.target.value);
    if (e.target.value !== "") {
      let regX = new RegExp(/^\w+([-+.']\w+)*@?(gsk.com)$/i);
      let index_mail = value.findIndex(
        (x) => x.email_address.toLowerCase() === e.target.value.toLowerCase()
      );
      if (regX.test(e.target.value) && index_mail === -1) {
        let mailids = value;
        mailids.push({ email_address: e.target.value });
        setValue(mailids);
        //console.log(mailids);
        props.setParentValues(
          [
            ...fixedOptions,
            ...mailids.filter((option) => fixedOptions.indexOf(option) === -1),
          ]
            .map((obj) => {
              return obj.email_address;
            })
            .join(";")
        );
      } else if (index_mail !== -1) {
        setShowAlertUnique(true);
      } else {
        //alert("Please enter valid gsk email");
        setShowAlert(true);
      }
    } else {
      console.log("empty");
    }
  };
  const funOnKeyUp = (e) => {
    // console.log(e.target.value);
    // e.target.value = e.target.value.trim();
    // console.log(e.target.value);
    setSearch(e.target.value);
  };
  const keyPressedCheck1 = (e) => {
    // e.target.value = e.target.value.trim();
    if (e.key === "Enter" && e.target.value !== "") {
      setSearchStart(true);
    }
  };

  const keyPressedCheck = (e) => {
    //console.log(e.code)
    // console.log(e.target.value)
    e.target.value = e.target.value.trim();
    // console.log(e.target.value)
    // setSearch(e.target.value);
    if ((e.key === "Enter" || e.code === "Space") && e.target.value !== "") {
      let regX = new RegExp(/^\w+([-+.']\w+)*@?(gsk.com)$/i);
      let index_mail = value.findIndex(
        (x) => x.email_address.toLowerCase() === e.target.value.toLowerCase()
      );
      if (regX.test(e.target.value) && index_mail === -1) {
        let mailids = value;
        mailids.push({ email_address: e.target.value });
        setValue(mailids);
        props.setParentValues(
          [
            ...fixedOptions,
            ...mailids.filter((option) => fixedOptions.indexOf(option) === -1),
          ]
            .map((obj) => {
              return obj.email_address;
            })
            .join(";")
        );
      } else if (index_mail !== -1) {
        setShowAlertUnique(true);
      } else {
        //alert("Please enter valid gsk email");
        setShowAlert(true);
      }
    }
  };

  const changeEmail = (event, newValue) => {
    if (props.multiple) {
      let emails = newValue;
      let index_mail = "";
      if (emails.length) {
        let last_email = emails[emails.length - 1]["email_address"];
        index_mail = emails.findIndex(
          (x) => x.email_address.toLowerCase() === last_email.toLowerCase()
        );
      }
      if (index_mail === -1 || index_mail === emails.length - 1) {
        setValue([
          ...fixedOptions,
          ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
        ]);
        props.setParentValues(
          [
            ...fixedOptions,
            ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
          ]
            .map((obj) => {
              return obj.email_address;
            })
            .join(";")
        );
      } else {
        emails.pop();
        setValue(emails);
        if (emails.length) {
          setShowAlertUnique(true);
        } else {
          props.setParentValues("");
        }
      }
    }
  };

  React.useEffect(() => {
    if (showAlert) {
      window.showMessage("Please enter valid gsk email", "warning");
    }
  }, [showAlert]);
  React.useEffect(() => {
    if (showAlertUnique) {
      window.showMessage("Please enter unique email id", "warning");
    }
  }, [showAlertUnique]);

  return (
    <>
      <Autocomplete
        noOptionsText={"Enter Name/Email and search"}
        limitTags={1}
        openOnFocus
        // multiple={props.multiple}
        multiple={typeof props.multiple === "boolean" ? props.multiple : false}
        id="fixed-tags-demo"
        value={value}
        onChange={changeEmail}
        onKeyPress={keyPressedCheck1}
        // onBlur={blurEvent}
        onKeyUp={funOnKeyUp}
        options={emails}
        // clearOnBlur={true}
        //fullWidth={true}
        //sx={{ width: 100 }}
        getOptionLabel={(option) => option.email_address}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.email_address}
              {...getTagProps({ index })}
              disabled={fixedOptions.indexOf(option) !== -1}
            />
          ))
        }
        //   style={{ width: '392px !important' }}
        // style={{ display: searchStart ? "none" : "block" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.lable}
            placeholder={props.placeholder}
            ref={inputRef}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {searchStart ? (
                    <CircularProgress
                      sx={{ position: "absolute", right: "45px" }}
                      size={15}
                    />
                  ) : (
                    <IconButton
                      color="primary"
                      aria-label="Search"
                      onClick={() => setSearchStart(true)}
                      disabled={searchStart ? true : false}
                      sx={{ position: "absolute", right: "40px" }}
                    >
                      <SearchIcon color="inherit" size={20} />
                    </IconButton>
                  )}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            //sx={{ width: "50ch !important" }}
          />
        )}
      />
      {/* <div
        style={{
          paddingTop: "10px",
          marginLeft: "10px",
          display: searchStart ? "block" : "none",
        }}
      >
        <Skeleton variant="rounded" height={50} />
      </div> */}
      {/* <ConfirmDialog
        title=""
        open={showAlert}
        setOpen={setShowAlert}
        isAlert={true}
      >
        Please enter valid gsk email
      </ConfirmDialog>
      <ConfirmDialog
        title=""
        open={showAlertUnique}
        setOpen={setShowAlertUnique}
        isAlert={true}
      >
        Please enter unique email id
      </ConfirmDialog> */}
    </>
  );
}
