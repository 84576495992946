import * as React from "react";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

export default function CustomDateRangeInputs(props) {
  // const [value, setValue] = React.useState(null);
  const minDate = dayjs(props.valueEndDate).subtract(5, "year");
  const maxDate = dayjs(props.valueEndDate).add(0, "year");

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
      <DatePicker
        // mask="__-__-___"
        mask=""
        clearable
        disabled={
          props.disabledInput
            ? true
            : props.menu !== undefined &&
              window.allowedStartDate[props.dataSource].includes(props.menu)
            ? false
            : props.menu !== undefined && props.menu === ""
            ? true
            : true
        }
        label={
          window.hideEndDate[props.breadcrums.vaccine ? "VX" : "RX"].includes(
            props.breadcrums.childMenu
          )
            ? "Date"
            : "Start Date"
        }
        // views={["day", "month", "year"]}
        inputFormat="DD-MMM-YYYY"
        value={props.valueStartDate}
        onChange={(newValue) => {
          props.onChange("start", newValue);
        }}
        renderInput={(params) => (
          <TextField
            size="small"
            sx={{
              paddingLeft: "0px",
              width: "120px",
              "& .MuiOutlinedInput-root": { padding: "0px" },
              "& .MuiInputLabel-root": { lineHeight: "1.9em" },
              "& .MuiIconButton-root": { margin: "0px" },
              "& .MuiInputAdornment-root": { ml: 0 },
            }}
            variant="outlined"
            {...params}
          />
        )}
        minDate={minDate}
        maxDate={maxDate}
      />
      <DatePicker
        // mask="__-__-___"
        mask=""
        //disabled={props.menu !== "Document Management & Control"}
        disabled={
          props.disabledInput
            ? true
            : props.menu !== undefined &&
              window.allowedEndDate[props.dataSource].includes(props.menu)
            ? false
            : props.menu !== undefined && props.menu === ""
            ? true
            : true
        }
        label="End Date"
        // views={["day", "month", "year"]}
        // inputFormat="DD-MM-yyyy"
        inputFormat="DD-MMM-YYYY"
        value={props.valueEndDate}
        onChange={(newValue) => {
          // setValue(newValue);
          props.onChange("end", newValue);
        }}
        renderInput={(params) => (
          <TextField
            size="small"
            sx={{
              paddingLeft: "6px",
              width: "123px",
              "& .MuiOutlinedInput-root": { padding: "0px" },
              "& .MuiInputLabel-root": { lineHeight: "1.9em" },
              "& .MuiIconButton-root": { margin: "0px" },
              "& .MuiInputAdornment-root": { ml: 0 },
              display: window.hideEndDate[
                props.breadcrums.vaccine ? "VX" : "RX"
              ].includes(props.breadcrums.childMenu)
                ? "none"
                : "block",
            }}
            variant="outlined"
            {...params}
          />
        )}
        maxDate={dayjs().add(0, "year")}
        minDate={minDate}
      />
    </LocalizationProvider>
  );
}
