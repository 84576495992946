import { memo } from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import Grid from "@mui/material/Box";
import { green } from '@mui/material/colors';
import "./PDFViewer.css";
const PDFViewer = (props) => {
  const { url } = props

  if (url === "")  {
          return (<Grid container
          spacing={0}
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" , textAlign:"center" }}>
            <p xs={6} style  = {{fontSize: "18px", color:green}}>Document not available for preview</p>            
            </Grid>)
        }else {
          return (<DocViewer
            className="myDocViewerStyle"
            pluginRenderers={DocViewerRenderers}
            documents={[
              {
                uri: url+"#"+(new Date()).getMilliseconds().toString(),
                
              },
            ]}
            style={{ width: "100%", height: "100vh" }}
            key={url+"#"+(new Date()).getMilliseconds().toString()}
            config={{
              header: {
               disableHeader: false,
               disableFileName: false,
               retainURLParams: false
              }
             }}
          />)
        }
      
  
  }
  // function areEqual(prevProps, nextProps) {
  //   /*
  //   return true if passing nextProps to render would return
  //   the same result as passing prevProps to render,
  //   otherwise return false
  //   */
  //  console.log(prevProps, nextProps)
  //  if (prevProps.url === nextProps.url) return true
  //  return false
  // }

  export default memo(PDFViewer);