import React, { useState,useContext } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PersonIcon from "@mui/icons-material/Person";

import gskLogo from "../../assets/images/u50.svg";
// import settingsIcon from "../../assets/images/u1058.png";
import LogoutIcon from "../../assets/images/u1059.png";

// import { authContext } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
// import { loginRequest } from "../../authConfigmasl";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { useHistory } from "react-router-dom";
// import axios from 'axios';
// import * as rimaConstants from "../../rimaconstants";
import { UserDataContext } from '../Context/UserDataContext';

function Header() {
  let history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { instance, accounts } = useMsal();

  //console.log(accounts[0])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleLogout(instance) {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
    localStorage.removeItem("userDocsUploaded")
    //localStorage.clear()
  }

  function handleRedirectAdminDashboard() {
    history.push("/admin/dashboard");
  }

  const [adminUser] = useContext(UserDataContext);


  return (
    <div style={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          bgcolor: "#ffffff",
          borderBottom: "3px solid rgb(243, 102, 51)",
        }}
      >
        <Box sx={{ p: 1, flexGrow: 1, width: "33.3%" }}>
          <img src={gskLogo} alt="Logo" />
        </Box>
        <Box sx={{ p: 1, flexGrow: 1, textAlign: "center", width: "33.3%" }}>
          <Typography
            style={{ fontWeight: 700, fontSize: "18px" }}
            variant="h5"
            component="h5"
            mt={1.5}
          >
            Audit Standard Manager
          </Typography>
        </Box>
        <Box sx={{ p: 1, flexGrow: 1, textAlign: "right", width: "33.3%" }}>
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2, mt: 0.8 }}
            >
              <Avatar sx={{ width: 32, height: 32 }}>
                <PersonIcon />
              </Avatar>
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>{accounts[0].name}</MenuItem>

        <hr />
        {/* <MenuItem enabled={false}>
                Admin
              
                </MenuItem>
                <hr/> */}
        {adminUser === "1" && (
          <MenuItem key={window.getUuidv4()} onClick={handleRedirectAdminDashboard}>
            <ListItemIcon>
              {/* <Settings fontSize="small" /> */}
              <SupervisorAccountIcon />
            </ListItemIcon>
          Admin
          </MenuItem>
        )}
        {/* <MenuItem>
          <ListItemIcon>
            <Settings fontSize="small" /> 
            <img src={settingsIcon} alt="settings" width={"20px"} />
          </ListItemIcon>
          Settings
        </MenuItem> */}
        <MenuItem key={window.getUuidv4()} onClick={() => handleLogout(instance)}>
          <ListItemIcon>
            {/* <Logout fontSize="small" /> */}
            <img src={LogoutIcon} alt="logout" width={"20px"} />
          </ListItemIcon>
          Sign Out
        </MenuItem>
      </Menu>
    </div>
  );
}

export default Header;
