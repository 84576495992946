import React, { useEffect, useState } from "react";
import "./FileUploadProgress.css";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
// import axios from 'axios';
// import * as rimaConstants from './../../../rimaconstants';
import { List, ListItem, ListSubheader, Popover } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as rimaConstants from "../../../rimaconstants";
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import {
  araSelector,
  insertNotifications,
  // eslint-disable-next-line no-unused-vars
  updateNoticationStatus,
} from "./../../../reducers/araReducer";
import CancelRounded from "@mui/icons-material/CancelRounded";
import Insights from "@mui/icons-material/Insights";
import Upload from "@mui/icons-material/UploadFile";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../applicationInsights/AppInsights";

const FileUploadProgress = (props) => {
  const dispatch = useDispatch();
  const { runningFileUploadIds } = useSelector(araSelector);
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [auditTypeDesc, setAuditTypeDesc] = useState({});
  const [auditType, setAuditType] = useState({});
  //   let timer;

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [JSON.stringify(props.isOpen)]);

  useEffect(() => {
    if (runningFileUploadIds?.length) {
      setNotifications(runningFileUploadIds);
    }
    // return () => {
    //     if (timer) clearInterval(timer);
    // }
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/site_document_upload_page_common/audit_document_type `
      )
      .then((response) => {
        //console.log(response.data);
        if (response?.data?.data) {
          let tmpObj = {};
          response?.data?.data.map((obj) => {
            tmpObj[`${obj.audit_document_type}`] = obj.processing_required;
            return true;
          });
          setAuditType(tmpObj);

          let tmpObj1 = {};
          response?.data?.data.map((obj) => {
            tmpObj1[`${obj.audit_document_type}`] = obj.audit_document_type_description;
            return true;
          });
          setAuditTypeDesc(tmpObj1);
        }
      });
  }, []);

  useEffect(() => {
    if (runningFileUploadIds?.length) {
      // let arr = runningFileUploadIds.filter(item => item.status === "RUNNING");
      setNotifications(runningFileUploadIds);
      // if (!timer && arr.length) {
      //     timer = setInterval(() => {
      //         getStatus(arr);
      //     }, 20000);
      // }
    } else {
      // if (timer) clearInterval(timer);
      setNotifications([]);
      props.onClose();
      setIsOpen(false);
    }
  }, [JSON.stringify(runningFileUploadIds)]);

  const getTextByType = (type) => {
    if (type && auditTypeDesc && auditTypeDesc[type]) {
      return auditType[type] === "Yes"
        ? `Processing document for ${auditTypeDesc[type]}`
        : `Processing not required for ${auditTypeDesc[type]}`;
    } else {
      return "Processing document.";
    }
    // switch (type) {
    //   case "SQCM":
    //     return "Extracting insights from sqcm.";
    //   case "sqp_insights":
    //     return "Extracting insights from site quality plan.";
    //   case "SQP":
    //     return "Processing site quality plan document.";
    //   case "ara_export":
    //     return "Export insights to ARA document.";
    //   case "LIC":
    //     return "Processing document for incident management.";
    //   case "lic_extraction":
    //     return "Extracting insights from incident management.";
    //   case "self_Insepction_audit_insertion":
    //     return "Processing document for self inspection audit insertion";
    //   case "upload_sqcm":
    //     return "Processing document for sqcm.";
    //   default:
    //     return "Processing document.";
    // }
  };

  // const getStatus = (ids) => {
  //     axios
  //         .post(`${rimaConstants.REACT_APP_API_URL}/api/v1/export/upload-status`, { run_ids: ids })
  //         .then(res => {
  //             if (res?.data?.data) {
  //                 //{ failed: failedArr, done: successArr, running: runningArr}
  //                 if (res.data.data.length === 0) {
  //                     if (timer)
  //                         clearInterval(timer);
  //                     dispatch(insertNotifications([]));
  //                 } else {
  //                     if (JSON.stringify(runningFileUploadIds) !== JSON.stringify(res.data.data)) {
  //                         dispatch(updateNoticationStatus(res.data.data));
  //                     }
  //                 }
  //             } else {
  //                 if (timer) {
  //                     clearInterval(timer);
  //                 }
  //                 dispatch(insertNotifications([]));
  //             }
  //         }).catch((error) => {
  //             console.log('Catch block---', error);
  //             return error;
  //         });
  // }

  const removeFromList = (id) => {
    let arr = notifications.filter((item) => item.id !== id);
    dispatch(insertNotifications(arr));
  };

  return (
    <>
      <Popover
        id={"simple-popover"}
        open={isOpen}
        anchorEl={props.anchorEl}
        onClose={() => {
          props.onClose();
          setIsOpen(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List
          sx={{ width: "350px", padding: "5px", minHeight: "200px" }}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Notifications
            </ListSubheader>
          }
        >
          {notifications?.map((item) => (
            <ListItem
              sx={{
                flexDirection: "column",
                alignItems: "flex-start",
                background: "#eaeaea",
                mb: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.2,
                }}
              >
                <Box sx={{ width: "10%" }}>
                  {item.actionType === "extract" ? (
                    <Insights sx={{ height: "20px" }} />
                  ) : (
                    <Upload sx={{ height: "20px" }} />
                  )}
                </Box>
                <Box sx={{ width: "85%" }}>
                  <Typography>
                    <Box sx={{ fontSize: 14 }}>
                      {getTextByType(item.fileType)}
                    </Box>
                  </Typography>
                  <Typography>
                    <Box sx={{ fontSize: 12 }}>File Name: {item.fileName}</Box>
                  </Typography>
                  {item.status === "RUNNING" ? (
                    <LinearProgress sx={{ width: "100%" }} />
                  ) : (
                    <Typography
                      variant={"p"}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box sx={{ fontSize: 12 }}>Status: {item.status}</Box>{" "}
                      <Box sx={{ fontSize: 12 }}>Start Time: {item.exTime}</Box>
                    </Typography>
                  )}
                </Box>
                <Box sx={{ width: "5%" }}>
                  {item.status !== "RUNNING" && (
                    <CancelRounded
                      onClick={() => removeFromList(item.id)}
                      sx={{ height: "16px", cursor: "pointer" }}
                    />
                  )}
                </Box>
              </Box>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};

// export default FileUploadProgress;
export default withAITracking(reactPlugin, FileUploadProgress);
