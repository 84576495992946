import React from "react";
import Header from "../HomePage/Header";
import Footer from "../Footer/Footer";
import "./PageNotFound.css";
import Stack from "@mui/material/Stack";
// import MuiAlert from "@mui/material/Alert";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";

function PageNotFound() {
  let history = useHistory();
  const { instance, accounts } = useMsal();
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef(null);
  React.useEffect(() => {
    setHeight(window.innerHeight - 95);
  }, []);

  return (
    <>
      <div ref={ref} style={{ minHeight: height }}>
        <Header hideUserInfo={true} />
        <div className="container-fluid-invaliduser">
          <div className="container-main-invaliduser">
            <div className="css-1pqbxu3-invaliduser">
              <Typography variant="h6" gutterBottom align="center">
                Hi <span style={{ color: "#02709f" }}> {accounts[0].name}</span>
                ,
              </Typography>
              <Stack severity="info" spacing={2} sx={{ maxWidth: 800, mt: 3 }}>
                <Alert
                  severity="warning"
                  sx={{
                    "& .MuiSvgIcon-root": { color: "#FFA623" },
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    The page you are looking for does not exist{" "}
                  </Typography>
                </Alert>
              </Stack>
              <br />
              <br />
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  history.push("/");
                }}
              >
                Go To Home
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PageNotFound;
