import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
export default function Autocompleteud(props) {
  const [defaultVal, setDefaultVal] = React.useState([]);

  React.useEffect(() => {
    setDefaultVal(props.value ? props.value : []);
    props.value.map((obj) => {
      return obj.keyword;
    });
    props.setParentValues(
      props.value
        .map((obj) => {
          return obj.keyword;
        })
        .join(" | ")
    );
  }, [props.value]);

  const blurEvent = (e) => {    
    // e.target.value = e.target.value.trim();
    //e.key === "Enter" || e.code === "Space"
    if (e.target.value.trim() !== "") {
      let option = [...defaultVal];
      let add = true;
      option.map((obj) => {
        if (obj.keyword === e.target.value) {
          add = false;
        }
      });
      if (add) {
        option = [...defaultVal, { keyword: e.target.value }];
      }
      setDefaultVal(option);
      props.setParentValues(
        option
          .map((obj) => {
            return obj.keyword;
          })
          .join(" | ")
      );
    }
  };

  const keyPressedCheck = (e) => {
    // e.target.value = e.target.value.trim();
    //e.key === "Enter" || e.code === "Space"
    if (parseInt(e.which) === 13 && e.target.value.trim() !== "") {
      let option = [...defaultVal];
      let add = true;
      option.map((obj) => {
        if (obj.keyword === e.target.value) {
          add = false;
        }
      });
      if (add) {
        option = [...defaultVal, { keyword: e.target.value }];
      }
      setDefaultVal(option);
      props.setParentValues(
        option
          .map((obj) => {
            return obj.keyword;
          })
          .join(" | ")
      );
    }
  };

  const handleChange = (e, newValue) => {
    if (props.multiple) {
      if (newValue.length) {
        setDefaultVal(newValue);
        props.setParentValues(
          newValue
            .map((obj) => {
              return obj.keyword;
            })
            .join(" | ")
        );
      } else {
        setDefaultVal([]);
        props.setParentValues("");
      }
    }
  };

  return (
    <Autocomplete
      id="tags-outlined"
      // disableClearable={true}
      multiple={true}
      options={defaultVal}
      getOptionLabel={(option) => option.keyword}
      value={defaultVal}
      onChange={handleChange}
      onKeyPress={keyPressedCheck}
      onBlur={blurEvent}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField {...params} label="Search" placeholder="Search" />
      )}
      // sx={{ width: "400px" }}
    />
  );
}
