import React, { useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import Moment from 'react-moment';
import { toast } from "react-toastify";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import UploadFileTwoToneIcon from "@mui/icons-material/UploadFileTwoTone";
import axios from "axios";
import * as rimaConstants from "../../../src/rimaconstants";
// Contexts
import { ReviewDataContext } from "../Context/ReviewDataContext";

const FileUploadInput = styled("input")({
  display: "none",
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#15717d",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const label = { inputProps: { "aria-label": "Document Checkbox" } };

export default function UploadedFilesList(props) {
  const [rerender, setRerender] = useState(false);
  const {
    sessionFilesData,
    setSessionFilesData,
    selectedFile,
    selectedFileType,
    selectedFileAuditNumber,
    setSelectedFile,
    setSelectedFileType,
    setSelectedFileAuditNumber,
    selectedFileRow,
    setSelectedFileRow,
    fileRecoReceived,
    setfileRecoReceived,
    onDelete
  } = useContext(ReviewDataContext);

  const [selectedValue, setSelectedValue] = useState("a");
  const [loading, setLoading] = useState(false)
  
  function createData(
    name,
    auditTypeInternal,
    auditTypeExternal,
    auditNumber,
    lastModified
  ) {
    return {
      name,
      auditTypeInternal,
      auditTypeExternal,
      auditNumber,
      lastModified,
    };
  }


  const setInternal = (event, row, data, index) => {
    //row.isInternal = true
    sessionFilesData[index].isInternal = true
    sessionFilesData[index].saved = false
    if (selectedFileRow === index) {
      setSelectedFileType("internal")
    }
    setRerender(!rerender)
  };

  const setExternal = (event, row, data, index) => {
    //row.isInternal = false
    sessionFilesData[index].isInternal = false
    sessionFilesData[index].saved = false
    if (selectedFileRow === index) {
      setSelectedFileType("external")
    }
    setRerender(!rerender)
  };


  
  const handleChange = (event,  row, data, index) => {
    for(var r in sessionFilesData)
    {
      sessionFilesData[r].selected = (sessionFilesData[r] === row)
      if (sessionFilesData[r].selected) {
        setSelectedFile(sessionFilesData[r].reviewFileUploadedPath)
        setSelectedFileAuditNumber(sessionFilesData[r].auditId)
        setSelectedFileType(sessionFilesData[r].isInternal?"internal":"external")
        setSelectedFileRow(index)
        setfileRecoReceived(0)
        props.onFileSelectionChange(sessionFilesData[r])  
      }
    } 
    //setSessionFilesData(sessionFilesData)
    setRerender(!rerender)
  }

 
  const onAuditIdChange = (event, row, data, index) =>
  {
    sessionFilesData[index].saved = false
    sessionFilesData[index].auditId = event.target.value
    setSessionFilesData([...sessionFilesData])
    if (selectedFileRow === index) setSelectedFileAuditNumber(event.target.value)
    setRerender(!rerender)
  }

  // const setAuditId = (event, row, data, index) => {
  //   //row.isInternal = true
  //   sessionFilesData[index].auditId = event.target.value
  //   if (selectedFileRow === index)
  //     setSelectedFileAuditNumber(event.target.value)
  //   setRerender(!rerender)
  // };

  const handleDelete=async (row)=>
  {
    if (row.saved){
       await axios
      .delete(`${rimaConstants.REACT_APP_API_URL}/api/v1/review/deletereviewdoc`,{data:{auditId: row.auditId}})
      .then((response) => {
          if (response.data.status === "success")
          {
            toast("Review document removed.")
            props.onDelete(row)
          }
          else toast.warn("Failed to remove document.")

      })
    }
    if (!row.saved)
    {
        toast("Review document removed.")
        props.onDelete(row)
    }
    sessionFilesData.splice(sessionFilesData.indexOf(row),1)
    setSessionFilesData([...sessionFilesData])
  }
  return (
    <TableContainer component={Paper} sx={{ maxHeight: "350px" }}>
      <Table
        stickyHeader
        sx={{ minWidth: 650 }}
        aria-label="simple table"
        size="small"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell>Document Name</StyledTableCell>
            <StyledTableCell align="center">
              Audit Type - Internal
            </StyledTableCell>
            <StyledTableCell align="center">
              Audit Type - External
            </StyledTableCell>
            <StyledTableCell align="center">Audit Number</StyledTableCell>
            <StyledTableCell align="center">Uploaded Date</StyledTableCell>
            <StyledTableCell align="right">Uploaded By</StyledTableCell>
            <StyledTableCell align="right">Status</StyledTableCell>
            <StyledTableCell align="right">Operations</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sessionFilesData.map((row, index) => (
            <StyledTableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Checkbox
                  checked={row.selected}
                  className="gsk-checkbox"
                  {...label}
                  onChange={(e) => handleChange(e, row, sessionFilesData, index)}
                />
                {row.reviewFileUploadedName}
              </TableCell>
              <TableCell align="center">
                <Radio
                  className="gsk-radio"
                  checked={row.isInternal}
                  onChange={(e) => setInternal(e, row, sessionFilesData, index)}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
              </TableCell>
              <TableCell align="center">
                <Radio
                  className="gsk-radio"
                  checked={!row.isInternal}
                  onChange={(e) => setExternal(e, row, sessionFilesData, index) }
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
              </TableCell>
              <TableCell align="center">
                <TextField
                  className="gsk-input"
                  id="filled-basic"
                  variant="filled"
                  size="small"
                  value = {row.auditId}
                  onChange = {(e)=>onAuditIdChange(e, row, sessionFilesData, index)}
                  // onBlur = {(e) => setAuditId(e, row, sessionFilesData, index)}
                />
              </TableCell>
              <TableCell align="right"><Moment format="DD-MMM-YYYY">{row.reviewFileUploadedDate}</Moment></TableCell>
              <TableCell align="right">{row.reviewFileUploadedBy}</TableCell>
              <TableCell align="right">{row.saved?"Saved":"In Edit"}</TableCell>
              <TableCell align="right"><Button variant="contained" className="gsk-btn primary pill small" onClick={()=>handleDelete(row)}>Remove</Button></TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
