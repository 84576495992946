// import * as React from "react";
// import CircularProgress from "@mui/material/CircularProgress";
// import Box from "@mui/material/Box";
// import Backdrop from "@mui/material/Backdrop";

// export default function Loader(props) {
//   const [open, setOpen] = React.useState(true);
//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <Backdrop
//       sx={{ color: "#ffffff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
//       open={open}
//     >
//       <Box sx={{ display: "flex" }}>
//         <CircularProgress style={{ color: "#15717d" }} />
//       </Box>
//     </Backdrop>
//   );
// }

import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Backdrop from "@mui/material/Backdrop";

export default function Loader() {
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = React.useState(true);

  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const progressRef = React.useRef(() => {});
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          position: "fixed",
          top: 0,
          zIndex: 1302,
        }}
        open={open}
      >
        <LinearProgress
          variant="buffer"
          value={progress}
          valueBuffer={buffer}
        />
      </Box>
      <Backdrop
        sx={{
          background: "transparent",
          zIndex: 1301,
        }}
        open={open}
      ></Backdrop>
    </>
  );
}
