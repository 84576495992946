import React, { useState, useContext } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PersonIcon from "@mui/icons-material/Person";

import gskLogo from "../../../assets/images/u50.svg";
import LogoutIcon from "../../../assets/images/u1059.png";
import { useMsal } from "@azure/msal-react";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as rimaConstants from "../../../rimaconstants";
import { UserDataContext } from "../../Context/UserDataContext";
import Notifications from "./Notifications";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../applicationInsights/AppInsights";
import { Grid, Paper } from "@mui/material";
import styled from "@emotion/styled";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Header() {
  let history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { instance, accounts } = useMsal();

  //console.log(accounts[0])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleLogout(instance) {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
    localStorage.removeItem("userDocsUploaded");
    //localStorage.clear()
  }

  function handleRedirectAdminDashboard() {
    history.push("/admin/dashboard");
  }

  // eslint-disable-next-line no-unused-vars
  const [adminUser, setAdminUser, user, setUser] = useContext(UserDataContext);
  const [menu, setMenu] = useState([]);

  React.useEffect(() => {
    window.showLoader();
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/ara/arageneraltoprightcornermenu`
      )
      .then((response) => {
        if (response.data.status === "success") {
          setMenu(response.data["data"]);
        }
        window.hideLoader();
      })
      .catch((err) => {});
  }, []);

  const handleClickMenu = (feature_id, feature_name) => {
    let path = "";
    window.setFeatureID(feature_id);
    switch (feature_name) {
      case "Manage Audits":
        path = "/ara/manage-audits";
        break;
      case "Upload Documents":
        path = "/ara/upload-document";
        break;
      case "Audit Trails":
        path = "/ara/audit-trails";
        break;
      default:
        path = "";
        break;
    }
    history.push(path);
  };

  return (
    <div style={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          bgcolor: "#ffffff",
          borderBottom: "3px solid rgb(243, 102, 51)",
        }}
      >
        <Box sx={{ p: 1, flexGrow: 1, width: "33.3%" }}>
          <img src={gskLogo} alt="Logo" />
        </Box>
        <Box sx={{ p: 1, flexGrow: 1, textAlign: "center", width: "33.3%" }}>
          <Typography
            style={{ fontWeight: 700, fontSize: "18px" }}
            variant="h5"
            component="h5"
            mt={1.5}
          >
            Audit Preparation & Risk Analysis
          </Typography>
        </Box>
        <Box sx={{ p: 1, flexGrow: 1, textAlign: "right", width: "33.3%" }}>
          <Grid container spacing={2} sx={{ flexDirection: "row-reverse" }}>
            <Grid>
              <Item sx={{ mt: 1,}}>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    // sx={{ ml: 2,}}
                  >
                    <Avatar sx={{ width: 32, height: 32 }}>
                      <PersonIcon />
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Item>
            </Grid>
            <Grid sx={{ mt: 1 }}>
              <Item>
                <Notifications />
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem key={window.getUuidv4()}>{accounts[0].name}</MenuItem>

        <hr />
        {/* <MenuItem enabled={false}>
                Admin
              
                </MenuItem>
                <hr/> */}
        {adminUser === "1" ? (
          <MenuItem
            key={window.getUuidv4()}
            onClick={handleRedirectAdminDashboard}
          >
            <ListItemIcon>
              {/* <Settings fontSize="small" /> */}
              <SupervisorAccountIcon />
            </ListItemIcon>
            Admin
          </MenuItem>
        ) : (
          ""
        )}
        {menu.map((menuItem) => {
          return (
            <MenuItem
              key={window.getUuidv4()}
              onClick={() => {
                handleClickMenu(menuItem.feature_id, menuItem.feature_name);
              }}
              disabled={menuItem.access_allowed ? false : true}
            >
              <ListItemIcon>
                {menuItem.feature_name === "Manage Audits" && (
                  <ManageAccountsIcon />
                )}
                {menuItem.feature_name === "Upload Documents" && (
                  <UploadFileIcon />
                )}
                {menuItem.feature_name === "Audit Trails" && (
                  <AccessibilityIcon />
                )}
              </ListItemIcon>
              {menuItem.feature_name}
            </MenuItem>
          );
        })}

        <MenuItem
          key={window.getUuidv4()}
          onClick={() => handleLogout(instance)}
        >
          <ListItemIcon>
            {/* <Logout fontSize="small" /> */}
            <img src={LogoutIcon} alt="logout" width={"20px"} />
          </ListItemIcon>
          Sign Out
        </MenuItem>
      </Menu>
    </div>
  );
}

// export default Header;
export default withAITracking(reactPlugin, Header);
