import * as React from "react";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import * as rimaConstants from "../../../src/rimaconstants";
// import ConfirmDialog from "./confirmDailog";

export default function FixedTags(props) {
  const [showAlert, setShowAlert] = React.useState(false);
  const [showAlertUnique, setShowAlertUnique] = React.useState(false);
  const [emails, setEmails] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/user/emails/${props.role}`
      )
      .then((response) => {
        let data = JSON.parse(response.data);
        if (Array.isArray(data)) {
          setEmails(data);
        } else {
          window.showMessage("Unable to fetch user(s) from AD Group", "error");
        }
      });
  }, []);
  const fixedOptions = [];
  const [value, setValue] = React.useState([...fixedOptions]);
  React.useEffect(() => {
    if (props.value) {
      let arr = props.value.split(";");
      let arr1 = [];
      arr.map((val) => {
        arr1.push({ email_address: `${val}` });
        return true;
      });
      setValue([...fixedOptions, ...arr1]);
    } else {
      setValue([...fixedOptions]);
    }
  }, [props.value]);

  //   React.useEffect(() => {
  //       console.log('value ',value);
  //       props.setParentValues(value);
  //   }, [value]);
  const blurEvent = (e) => {
    e.target.value = e.target.value.trim();
    if (e.target.value !== "") {
      let regX = new RegExp(/^\w+([-+.']\w+)*@?(gsk.com)$/i);
      let index_mail = value.findIndex(
        (x) => x.email_address.toLowerCase() === e.target.value.toLowerCase()
      );
      if (regX.test(e.target.value) && index_mail === -1) {
        let mailids = value;
        mailids.push({ email_address: e.target.value });
        setValue(mailids);
        //console.log(mailids);
        props.setParentValues(
          [
            ...fixedOptions,
            ...mailids.filter((option) => fixedOptions.indexOf(option) === -1),
          ]
            .map((obj) => {
              return obj.email_address;
            })
            .join(";")
        );
      } else if (index_mail !== -1) {
        setShowAlertUnique(true);
      } else {
        //alert("Please enter valid gsk email");
        setShowAlert(true);
      }
    } else {
      console.log("empty");
    }
  };
  const keyPressedCheck = (e) => {
    //console.log(e.code)
    e.target.value = e.target.value.trim();
    if ((e.key === "Enter" || e.code === "Space") && e.target.value !== "") {
      let regX = new RegExp(/^\w+([-+.']\w+)*@?(gsk.com)$/i);
      let index_mail = value.findIndex(
        (x) => x.email_address.toLowerCase() === e.target.value.toLowerCase()
      );
      if (regX.test(e.target.value) && index_mail === -1) {
        let mailids = value;
        mailids.push({ email_address: e.target.value });
        setValue(mailids);
        props.setParentValues(
          [
            ...fixedOptions,
            ...mailids.filter((option) => fixedOptions.indexOf(option) === -1),
          ]
            .map((obj) => {
              return obj.email_address;
            })
            .join(";")
        );
      } else if (index_mail !== -1) {
        setShowAlertUnique(true);
      } else {
        //alert("Please enter valid gsk email");
        setShowAlert(true);
      }
    }
  };

  const changeEmail = (event, newValue) => {
    if (props.multiple) {
      let emails = newValue;
      let index_mail = "";
      if (emails.length) {
        let last_email = emails[emails.length - 1]["email_address"];
        index_mail = emails.findIndex(
          (x) => x.email_address.toLowerCase() === last_email.toLowerCase()
        );
      }
      if (index_mail === -1 || index_mail === emails.length - 1) {
        setValue([
          ...fixedOptions,
          ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
        ]);
        props.setParentValues(
          [
            ...fixedOptions,
            ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
          ]
            .map((obj) => {
              return obj.email_address;
            })
            .join(";")
        );
      } else {
        emails.pop();
        setValue(emails);
        if (emails.length) {
          setShowAlertUnique(true);
        } else {
          props.setParentValues("");
        }
      }
    }
  };

  React.useEffect(() => {
    if (showAlert) {
      window.showMessage("Please enter valid gsk email", "warning");
    }
  }, [showAlert]);
  React.useEffect(() => {
    if (showAlertUnique) {
      window.showMessage("Please enter unique email id", "warning");
    }
  }, [showAlertUnique]);

  return (
    <>
      <Autocomplete
        limitTags={1}
        // multiple={props.multiple}
        multiple={typeof props.multiple === "boolean" ? props.multiple : false}
        id="fixed-tags-demo"
        value={value}
        onChange={changeEmail}
        onKeyPress={keyPressedCheck}
        onBlur={blurEvent}
        options={emails}
        clearOnBlur={true}
        //fullWidth={true}
        //sx={{ width: 100 }}
        getOptionLabel={(option) => option.email_address}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.email_address}
              {...getTagProps({ index })}
              disabled={fixedOptions.indexOf(option) !== -1}
            />
          ))
        }
        //   style={{ width: '392px !important' }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.lable}
            placeholder={props.placeholder}
            //sx={{ width: "50ch !important" }}
          />
        )}
      />
      {/* <ConfirmDialog
        title=""
        open={showAlert}
        setOpen={setShowAlert}
        isAlert={true}
      >
        Please enter valid gsk email
      </ConfirmDialog>
      <ConfirmDialog
        title=""
        open={showAlertUnique}
        setOpen={setShowAlertUnique}
        isAlert={true}
      >
        Please enter unique email id
      </ConfirmDialog> */}
    </>
  );
}
