import LoadingButton from "@mui/lab/LoadingButton";
import { Box, FormControlLabel, Switch } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import React from "react";
import * as rimaConstants from "../../rimaconstants";
import axios from "axios";
import { UserDataContext } from "../../components/Context/UserDataContext";

const GenExcel = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [adminUser, setAdminUser, user, setUser] =
    React.useContext(UserDataContext);

  const initiateExport = () => {
    setLoading(true);
    const ds = props?.breadcrums?.vaccine ? "Vx" : "Rx";
    let menuName = props?.breadcrums?.childMenu;
    let auditID = 0;
    if (props?.auditID) {
      auditID = props.auditID;
    } else if (props?.breadcrums?.auditTitle) {
      const auditInfo = props.breadcrums.auditTitle.split("@#@");
      auditID = auditInfo[4];
    }
    let audit_status = 2;
    if (props?.breadcrums?.closedAudits) audit_status = 3;
    if (props?.auditStatus) {
      audit_status = props.auditStatus;
    }
    let siteIDVal = "";
    if (props?.breadcrums?.siteId && props.breadcrums.siteId !== "")
      siteIDVal = props.breadcrums.siteId.split("#")[1];

    if (
      props?.auditID ||
      props?.auditStatus ||
      props?.auditTrailSource ||
      props?.heading === "Unstructured Keywords Settings" ||
      props?.heading === "Site Management" || 
      props?.heading === "Template Section Management"
    ) {
      menuName = props.heading;
    }

    axios
      .post(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/ara/excel/download?audit_status=${audit_status}`,
        {
          sectionName: menuName,
          columns: props?.columns ? props.columns : [],
          audit_id: auditID,
          dataSource: ds,
          selected: JSON.stringify(
            props?.gridSelectionModel ? props.gridSelectionModel : []
          ),
          auditNiteName: window.base64_encode(siteIDVal),
          startDate: window.getDateDB(
            props?.breadcrums?.startDates,
            false,
            props?.breadcrums?.childMenu
          ),
          endDate: window.getDateDB(
            props?.breadcrums?.endDate,
            false,
            props?.breadcrums?.childMenu,
            props?.breadcrums?.vaccine ? "VX" : "RX"
          ),
          level: props?.breadcrums?.DeviationClassification,
          auditTrailSource: props?.auditTrailSource,
          auditNumber: props?.auditNumber,
          featureName: props?.featureName,
        }
      )
      .then(async (response) => {
        setLoading(false);
        if (response?.data?.status === "success") {
          window.showMessage("Data exported successfully", "success");
          // let url = response?.data?.fileName.replace(
          //   "./tmpdownloadexcel/",
          //   `${rimaConstants.REACT_APP_API_URL}/downloads/`
          // );
          // let a = document.createElement("a");
          // a.target = "_blank";
          // let token = await window.getAccessToken();
          // a.href = `${url}?token=${token}`;
          // a.click();
          let filename = response?.data?.fileName;
          let format = filename.split("/").pop(0).split(".")[1];
          let type = window.getDocumentTypeByFileExtention(format);
          let url = `${window.BLOB_URL}/${rimaConstants.ARA_ROOT_CONTAINER}/${
            filename + window.BLOB_CONATINER_SAS_READ_TOKEN
          }`;
          window.downloadFile(url, type, filename);
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };
  return (
    <Box>
      <FormControlLabel
        sx={{
          display: "none",
        }}
        control={
          <Switch
            checked={loading}
            onChange={() => setLoading(!loading)}
            name="loading"
            color="primary"
          />
        }
        label="Loading"
      />
      <Box
        sx={{
          "& > button": { m: 1 },
          "& .MuiSvgIcon-root": {
            color: "#15717D !important",
          },
          "& .Mui-disabled": {
            background: "none !important",
          },
        }}
      >
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          startIcon={<GetAppIcon />}
          type="submit"
          sx={{ textTransform: "capitalize" }}
          variant="text"
          size="small"
          onClick={initiateExport}
          disabled={false}
        >
          {loading ? "Exporting..." : "Export"}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default GenExcel;
