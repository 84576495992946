import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import SearchBar from "../../SearchBar/SearchBar";
import Tooltips from "../../Common/Tooltips";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import * as rimaConstants from "../../../rimaconstants";
import ReactHtmlParser, { htmlparser2 } from "react-html-parser";
import getHiddenDocList from "../../Common/hiddenDocs";
import { UserDataContext } from "../../Context/UserDataContext";
import DataTable from "../../DataTable/DataTableARA";
import { FormControl, Grid } from "@mui/material";
import SearchableDropdown from "../../Common/searchableDropdown";
import AutocompleteInput from "./autocompleteud";
import CommonHeading from "../../Common/CommonHeading";
import moment from "moment";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../applicationInsights/AppInsights";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const renderHtml = (html) => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

function UnstructureDataSearch(props) {
  const [value, setValue] = React.useState(0);
  const [final, setFinal] = useState("");
  const [top, setTop] = useState(1000);
  const [skip, setSkip] = useState(0);
  const [hideDuplicates, setHideDuplicates] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [searchCollection, setSearchCollection] = useState([]);
  const [documentTypesDesc, setDocumentTypesDesc] = useState([]);

  const [
    adminUser,
    setAdminUser,
    user,
    setUser,
    userSASToken,
    setUserSASToken,
  ] = useContext(UserDataContext);
  const searchInputHandler = (e) => {
    setFinal(e.target.value);
  };

  const getAuditDocumentTypes = () => {
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/unstructureDataUserStories/audit_document_type`
      )
      .then((response) => {
        //console.log(response.data);
        // response?.data?.data && setDocumentTypes(response.data.data);
        if (response?.data?.data) {
          let option = [];
          let documentTypes = [];
          let documentTypesDesc = [];
          response?.data?.data.map((val) => {
            val.audit_document_type &&
              documentTypes.push(val.audit_document_type);
            documentTypesDesc.push(val.audit_document_type_description);
            if (val.keyword) {
              let arr = val.keyword.split(",");
              if (arr.length) {
                arr.map((v) => {
                  option.push({ keyword: v });
                });
              }
            }
            return true;
          });
          setSearchCollection(option);
          setDocumentTypes(documentTypes);
          setDocumentTypesDesc(documentTypesDesc.join("/"));
          localStorage.setItem("documentTypes", JSON.stringify(documentTypes));
        }
      });
  };

  useEffect(() => {
    setFinalData([]);
    setDocumentTypes([]);
    getAuditDocumentTypes();
  }, [props.breadcrums.childMenu]);
  useEffect(() => {
    setFinalData([]);
  }, [props.breadcrums.auditTitle]);
  useEffect((e) => {
    searchAsmresult(e);
  }, []);
  useEffect(
    (e) => {
      localStorage.setItem("searchKeywords", final);
    },
    [final]
  );
  const searchAsmresult = (e) => {
    if (e != null) e.preventDefault();
    let auditInfo = props?.breadcrums?.auditTitle.split("@#@");
    if (!auditInfo[0]) {
      window.showMessage("Please select the audit", "warning");
      window.hideLoader();
      return;
    }
    if (
      final === undefined ||
      final === null ||
      final.trim() === "" ||
      e === null
    ) {
      return;
    }

    window.showLoader();
    let newSearch = final.split(" | ");
    for (const key in newSearch) {
      if (newSearch[key].match(new RegExp('"', "g"))) {
        newSearch[key] = newSearch[key];
      } else {
        newSearch[key] = '"' + newSearch[key] + '"';
      }
    }
    var qryParams = {
      speller: "lexicon",
      queryLanguage: "en-us",
      search: newSearch.join(" | "),
      $count: "true",
      "api-version": "2021-04-30-Preview",
      highlight: "content,file_name",
      $top: top,
      $skip: skip,
      highlightPreTag: "<strong>",
      highlightPostTag: "</strong>",
    };
    var filterStr = "";
    var filters = [];

    filterStr = "";
    for (var i = 0; i < filters.length; i++) {
      if (i === 0) filterStr = filters[i];
      else filterStr = filterStr + " and " + filters[i];
    }

    if (filterStr !== "") qryParams["$filter"] = filterStr;
    const [
      audit_number,
      country_code,
      audit_site_id,
      site_description,
      audit_id,
      audit_title,
      audit_type_id,
    ] = props.breadcrums.auditTitle.split("@#@");

    axios
      .post(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/unstructureDataUserStories/search`,
        {
          payload: {
            search: qryParams["search"],
            $filter: `audit_number eq '${audit_number}' and is_archived eq 0 and search.in(audit_document_type,'${documentTypes.join(
              " | "
            )}')`,
            $orderby: `is_latest_document desc`,
            speller: "lexicon",
            queryLanguage: "en-us",
            $count: "true",
            "api-version": "2021-04-30-Preview",
            $top: top,
            $skip: skip,
            highlight: "content",
            highlightPreTag: "<strong>",
            highlightPostTag: "</strong>",
          },
        }
      )
      .then(async (res) => {
        let resJSONData = res;
        if (!resJSONData?.data?.value?.length) {
          // toast("No search results retrieved.");
          setFinalData([]);
          window.showMessage(
            !props?.availableFiles?.length
              ? `Please ensure that atleast one document is uploaded against the ${documentTypesDesc}.`
              : `Keyword(s) not present in the document.`,
            "warning"
          );
          window.hideLoader();
          return;
        }
        const extractData = extractValues(
          resJSONData?.data?.value,
          "content",
          "file_name",
          "file_path",
          "page_number"
        );

        //Get hidden documents
        var hiddendoclist = []; //await getHiddenDocList();
        var hiddendocs = [
          ...new Set(
            hiddendoclist.map((x) => {
              return x.document_storage_url.split(".")[0];
            })
          ),
        ];

        var searchResult = [];
        if (hideDuplicates) {
          //Remove duplicate from search result
          searchResult = extractData.filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  t.filepath === value.filepath &&
                  !hiddendocs.includes(t.filepath.split(".")[0], 0)
              )
          );
        } else {
          searchResult = extractData.filter(
            (value, index, self) =>
              !hiddendocs.includes(value.filepath.split(".")[0], 0)
          );
        }

        searchResult = searchResult.map((x) => {
          delete x["filepath"];
          return x;
        });

        setFinalData(searchResult);
        window.hideLoader();
      });
  };

  function extractValues(arr, prop, prop2, prop3, prop4) {
    let extractedValue = arr.map((item) => {
      let content = Array.isArray(item["@search.highlights"][prop])
        ? window.htmlEncode(item["@search.highlights"][prop].join("<br/>"))
        : window.htmlEncode(item["@search.highlights"][prop]);
      let obj = {
        filepath: item[prop3],
        document_title: (
          <p>
            <strong>Page No.</strong> {item[prop4]} &nbsp;{" "}
          </p>
        ),
        file_name: (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              let url = `${
                window.BLOB_URL +
                item[`file_path`] +
                window.BLOB_CONATINER_SAS_READ_TOKEN
              }&ts=${moment().utc().unix()}#page=${item[prop4]}`;
              //setDocs(url);
              let a = document.createElement("a");
              a.target = "_blank";
              a.href = url;
              // a.href = `/documentviewer/${window.base64_encode(url)}`;
              a.click();
            }}
          >
            {item[prop2]}
          </a>
        ),

        page_content: ReactHtmlParser(content === undefined ? "" : content),
      };

      return obj;
    });
    return extractedValue;
  }
  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justify="flex-end"
        alignItems="center"
      >
        <Grid item xs={8} sx={{ mt: 1, mb: 2 }}>
          <CommonHeading heading={props.breadcrums.childMenu} sx={{ mr: 0 }} />
        </Grid>
      </Grid>
      <div
        className="container"
        style={{
          border: "1px solid rgba(224, 224, 224, 1)",
          borderRadius: "0.5rem",
          display: `${props.display}`,
          maxHeight: "500px",
          overflowY: "auto",
        }}
      >
        <TabPanel value={value} index={0}>
          <div style={{ display: "flex" }} className="auditForm">
            <FormControl required sx={{ width: "100%", mr: 2 }}>
              <AutocompleteInput
                lable="Search"
                value={searchCollection}
                setParentValues={setFinal}
                role={5}
                multiple={true}
                placeholder="Search"
              />
              {/* <SearchBar final={final} searchInputHandler={searchInputHandler} /> */}
            </FormControl>
            <FormControl required sx={{ width: "100%", mr: 2, mt: 1 }}>
              <IconButton
                style={{
                  position: "absolute",
                  // right: "213px",
                  color: "rgb(22, 155, 213)",
                }}
                onClick={searchAsmresult}
                disabled={final === ""}
              >
                <SearchIcon />
              </IconButton>
              {/* <IconButton>
              <Tooltips
                html={true}
                iconColor="#2D2D69"
                placement="top"
                title='<h3>Sample queries:</h3> <hr/> <li><b>water</b> -> <i>Word search</i></li> <li><b>"Water for injection"</b> -> <i>Specific text</i></li> <li><b>water + injection</b> -> <i>AND operation</i></li> <li><b>water | injection</b> -> <i>OR operation</i></li> <li><b>"data management" + "vaccine"</b> -> <i>Complex search</i></li>'
              />
            </IconButton> */}
            </FormControl>
          </div>
        </TabPanel>
        <div style={{ margin: "0px 25px" }}>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <DataTable
                final={final}
                data={finalData}
                setTop={setTop}
                skip={setSkip}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

// export default UnstructureDataSearch;
export default withAITracking(reactPlugin, UnstructureDataSearch);
