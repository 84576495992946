import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import SearchDocs from "./searchdocs";
import UploadedDocList from "./uploadeddoclist";
import ArchiveddDocList from "./archiveddoclist";
import DeletedDocList from "./deleteddoclist";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function DocumentManagement() {
  const [activeContent, setActiveContent] = useState("searchTab");

  const ContentHandleChange = (event, activeTab) => {
    setActiveContent(activeTab);
    // console.log(activeTab);
  };

  return (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{ paddingTop: "20px", paddingBottom: "20px" }}
      >
        <Box
          sx={{
            m: "15px",
            textAlign: "center",
            width: "80%",
            margin: "0 auto",
          }}
        >
          <ToggleButtonGroup
            fullWidth
            //   color="#15717d"
            value={activeContent}
            exclusive
            size="small"
            onChange={ContentHandleChange}
          >
            <ToggleButton
              className={
                activeContent === "searchTab" || activeContent === null
                  ? "gsk-btn primary"
                  : "gsk-btn"
              }
              value="searchTab"
              // sx={{ minWidth: "400px" }}
              // onClick="resultHandlerChange"
            >
              Search &amp; Delete
            </ToggleButton>
            <ToggleButton
              className={
                activeContent === "docListTab​" || activeContent === null
                  ? "gsk-btn primary"
                  : "gsk-btn"
              }
              value="docListTab​"
              // sx={{ minWidth: "400px" }}
            >
              Uploaded Documents
              {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Audit Draft Text&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
            </ToggleButton>
            <ToggleButton
              className={
                activeContent === "archivedListTab" || activeContent === null
                  ? "gsk-btn primary"
                  : "gsk-btn"
              }
              value="archivedListTab"
              // sx={{ minWidth: "400px" }}
            >
              Archived Documents
              {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Audit Draft Text&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
            </ToggleButton>
            <ToggleButton
              className={
                activeContent === "deletedListTab" || activeContent === null
                  ? "gsk-btn primary"
                  : "gsk-btn"
              }
              value="deletedListTab"
              // sx={{ minWidth: "400px" }}
            >
              Deleted Documents
              {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Audit Draft Text&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Grid>
      {/* <br/> */}
      {(activeContent === "searchTab" || activeContent === null) && (
        <SearchDocs key="resultsContainer" />
      )}
      {activeContent === "docListTab​" && (
        <UploadedDocList key="suggetionsContainer" />
      )}
      {activeContent === "archivedListTab" && (
        <ArchiveddDocList key="archivedContainer" />
      )}
      {activeContent === "deletedListTab" && (
        <DeletedDocList key="deletedListContainer" />
      )}
    </Box>
  );
}
