import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import ToggleButton from '@mui/material/ToggleButton';

import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "./AdvanceSearch.css";
import { Button } from "@mui/material";
import MultipleSelect from "./../MultiselectComponent2/MultipleSelect2";
import axios from "axios";
import { TagDataContext } from "./../Context/TagDataContext";
import { RegulationDataContext } from "./../Context/RegulationDataContext";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 250,
    },
  },
};

const drpDownStyle = {
  width: "140px",
  marginBottom: "-4px",
};

const Internal = 0;
const External = 1;
const Both = -1;
function getStyles(sources, documentSource, theme) {
  return {
    fontWeight: "400",
    fontSize: "13px",
  };
}

function AdvanceSearch(props) {
  const theme = useTheme();
  const [documentSource, setDocumentSource] = useState([]);

  const [tags, setTags] = useContext(TagDataContext);
  const [dsources, setDsources] = useContext(RegulationDataContext);

  const [regulationValues, setRegulationValues] = useState("");

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setDocumentSource(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      <div
        className={`advanceCont ${props.isShowing ? "active" : "disactive"}`}
      >
        <CloseIcon onClick={props.toggle} className="crossIcon"></CloseIcon>
        <Box>
          <Box className="AdvanceSbx" noValidate sx={{ mt: 1 }}>
            <Box sx={{ display: "flex" }}>
              <form onSubmit={props.searchAsmresult}>
                <div
                  style={{ margin: "0px 20px 0 0", display: "inline-grid" }}
                  sx={{ m: 3 }}
                >
                  <FormLabel
                    style={{ paddingBottom: "5px", fontSize: "13px" }}
                    component=""
                  >
                    Document Types:
                  </FormLabel>
                  <RadioGroup
                    onChange={props.searchInputHandlerDocType}
                    aria-label="Document Types"
                    defaultValue={Both}
                    value={props.docType}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      onChange={props.internalExternalReset}
                      style={{ fontSize: "12px" }}
                      value={Internal}
                      control={<Radio style={{ padding: "0px 5px 6px 9px" }} />}
                      label={
                        <Typography variant="h6" style={{ fontSize: "12px" }}>
                          Internal
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      onChange={props.internalExternalReset}
                      style={{ fontSize: "12px" }}
                      value={External}
                      control={<Radio style={{ padding: "0px 5px 6px 9px" }} />}
                      label={
                        <Typography variant="h6" style={{ fontSize: "12px" }}>
                          External
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      style={{ fontSize: "12px" }}
                      value={Both}
                      control={<Radio style={{ padding: "0px 5px 6px 9px" }} />}
                      label={
                        <Typography variant="h6" style={{ fontSize: "12px" }}>
                          Both
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </div>

                <div
                  style={{ margin: "0px 20px 0 0", display: "inline-grid" }}
                  sx={{ m: 3 }}
                >
                  <FormLabel
                    style={{ paddingBottom: "5px", fontSize: "13px" }}
                    component=""
                  >
                    Regulation:
                  </FormLabel>

                  <Select
                    onChange={props.searchInputHandlerDocSource}
                    style={{
                      width: "146px",
                      height: "30px",
                      fontSize: "13px",
                      marginBottom: "5px",
                    }}
                    multiple
                    displayEmpty
                    value={props.docSource}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>Select</em>;
                      }
                      console.log(props.docSource);
                      console.log(selected);
                      return selected.join(", ");
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem disabled value="">
                      <em>Select</em>
                    </MenuItem>
                    {props.docType == Internal &&
                      dsources.map(
                        (dsource, idx) =>
                          dsource.is_external == "0" && (
                            <MenuItem
                              key={idx}
                              value={dsource.document_source}
                              style={getStyles(
                                dsource.document_source,
                                documentSource,
                                theme
                              )}
                            >
                              {dsource.document_source}
                            </MenuItem>
                          )
                      )}
                    {props.docType == External &&
                      dsources.map(
                        (dsource,idx) =>
                          dsource.is_external == "1" && (
                            <MenuItem
                              key={idx}
                              value={dsource.document_source}
                              style={getStyles(
                                dsource.document_source,
                                documentSource,
                                theme
                              )}
                            >
                              {dsource.document_source}
                            </MenuItem>
                          )
                      )}
                    {props.docType == Both &&
                      dsources.map((dsource, idx) => (
                        <MenuItem
                          key={idx}
                          value={dsource.document_source}
                          style={getStyles(
                            dsource.document_source,
                            documentSource,
                            theme
                          )}
                        >
                          {dsource.document_source}
                        </MenuItem>
                      ))}
                  </Select>

                  {tags.map(
                    (tag) =>
                      tag.tag_type_id == "2" && (
                        <React.Fragment>
                          <FormLabel
                            style={{ paddingBottom: "5px", fontSize: "13px" }}
                            component=""
                          >
                            {tag.tag_type}:
                          </FormLabel>
                          <Select
                            onChange={props.searchInputHandlerProductTag}
                            style={{
                              width: "146px",
                              height: "30px",
                              fontSize: "13px",
                              marginBottom: "5px",
                            }}
                            multiple
                            displayEmpty
                            value={props.productTag}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <em>Select </em>;
                              }

                              return selected.join(", ");
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem disabled value="">
                              <em>Select</em>
                            </MenuItem>
                            {tag.tags.map((t,idx) => (
                              <MenuItem
                                key={idx}
                                value={t}
                                style={getStyles(t, theme)}
                              >
                                {t}
                              </MenuItem>
                            ))}
                          </Select>
                        </React.Fragment>
                      )
                  )}
                </div>

                <div
                  style={{ margin: "0px 20px 0 0", display: "inline-grid" }}
                  sx={{ m: 3 }}
                >
                  {tags.map(
                    (tag) =>
                      tag.tag_type_id == "3" && (
                        <React.Fragment>
                          <FormLabel
                            style={{ paddingBottom: "5px", fontSize: "13px" }}
                            component=""
                          >
                            {tag.tag_type}:
                          </FormLabel>
                          <Select
                            onChange={props.searchInputHandlerLocationTag}
                            style={{
                              width: "146px",
                              height: "30px",
                              fontSize: "13px",
                              marginBottom: "5px",
                            }}
                            multiple
                            displayEmpty
                            value={props.locationTag}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <em>Select </em>;
                              }

                              return selected.join(", ");
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem disabled value="">
                              <em>Select</em>
                            </MenuItem>
                            {tag.tags.map((t,idx) => (
                              <MenuItem
                                key={idx}
                                value={t}
                                style={getStyles(t, theme)}
                              >
                                {t}
                              </MenuItem>
                            ))}
                          </Select>
                        </React.Fragment>
                      )
                  )}

                  {tags.map(
                    (tag) =>
                      tag.tag_type_id == "4" && (
                        <React.Fragment>
                          <FormLabel
                            style={{ paddingBottom: "5px", fontSize: "13px" }}
                            component=""
                          >
                            {tag.tag_type}:
                          </FormLabel>
                          <Select
                            onChange={props.searchInputHandlerAgreementTag}
                            style={{
                              width: "146px",
                              height: "30px",
                              fontSize: "13px",
                              marginBottom: "5px",
                            }}
                            multiple
                            displayEmpty
                            value={props.agreementTag}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <em>Select </em>;
                              }

                              return selected.join(", ");
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem disabled value="">
                              <em>Select</em>
                            </MenuItem>
                            {tag.tags.map((t,idx) => (
                              <MenuItem
                                key={idx}
                                value={t}
                                style={getStyles(t, theme)}
                              >
                                {t}
                              </MenuItem>
                            ))}
                          </Select>
                        </React.Fragment>
                      )
                  )}
                </div>

                <div
                  style={{ margin: "0px 20px 0 0", display: "inline-grid" }}
                  sx={{ m: 3 }}
                >
                  {tags.map(
                    (tag) =>
                      tag.tag_type_id == "7" && (
                        <React.Fragment>
                          <FormLabel
                            style={{ paddingBottom: "5px", fontSize: "13px" }}
                            component=""
                          >
                            {tag.tag_type}:
                          </FormLabel>
                          <Select
                            onChange={props.searchInputHandlerAuditeeTag}
                            style={{
                              width: "146px",
                              height: "30px",
                              fontSize: "13px",
                              marginBottom: "5px",
                            }}
                            multiple
                            displayEmpty
                            value={props.auditeeTag}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <em>Select </em>;
                              }

                              return selected.join(", ");
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem disabled value="">
                              <em>Select</em>
                            </MenuItem>
                            {tag.tags.map((t,idx) => (
                              <MenuItem
                                key={idx}
                                value={t}
                                style={getStyles(t, theme)}
                              >
                                {t}
                              </MenuItem>
                            ))}
                          </Select>
                        </React.Fragment>
                      )
                  )}

                  {tags.map(
                    (tag) =>
                      tag.tag_type_id == "8" && (
                        <React.Fragment>
                          <FormLabel
                            style={{ paddingBottom: "5px", fontSize: "13px" }}
                            component=""
                          >
                            {tag.tag_type}:
                          </FormLabel>
                          <Select
                            onChange={props.searchInputHandlerDoseTag}
                            style={{
                              width: "146px",
                              height: "30px",
                              fontSize: "13px",
                              marginBottom: "5px",
                            }}
                            multiple
                            displayEmpty
                            value={props.doseTag}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <em>Select </em>;
                              }

                              return selected.join(", ");
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem disabled value="">
                              <em>Select</em>
                            </MenuItem>
                            {tag.tags.map((t,idx) => (
                              <MenuItem
                                key={idx}
                                value={t}
                                style={getStyles(t, theme)}
                              >
                                {t}
                              </MenuItem>
                            ))}
                          </Select>
                        </React.Fragment>
                      )
                  )}
                </div>

                <div
                  style={{ margin: "0px 20px 0 0", display: "inline-grid" }}
                  sx={{ m: 3 }}
                >
                  {tags.map(
                    (tag) =>
                      tag.tag_type_id == "9" && (
                        <React.Fragment>
                          <FormLabel
                            style={{ paddingBottom: "5px", fontSize: "13px" }}
                            component=""
                          >
                            {tag.tag_type}:
                          </FormLabel>
                          <Select
                            onChange={props.searchInputHandlerServiceTag}
                            style={{
                              width: "146px",
                              height: "30px",
                              fontSize: "13px",
                              marginBottom: "5px",
                            }}
                            multiple
                            displayEmpty
                            value={props.serviceTag}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <em>Select </em>;
                              }

                              return selected.join(", ");
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem disabled value="">
                              <em>Select</em>
                            </MenuItem>
                            {tag.tags.map((t,idx) => (
                              <MenuItem
                                key={idx}
                                value={t}
                                style={getStyles(t, theme)}
                              >
                                {t}
                              </MenuItem>
                            ))}
                          </Select>
                        </React.Fragment>
                      )
                  )}

                  {tags.map(
                    (tag) =>
                      tag.tag_type_id == "10" && (
                        <React.Fragment>
                          <FormLabel
                            style={{ paddingBottom: "5px", fontSize: "13px" }}
                            component=""
                          >
                            {tag.tag_type}:
                          </FormLabel>
                          <Select
                            onChange={props.searchInputHandlerApplicableTag}
                            style={{
                              width: "146px",
                              height: "30px",
                              fontSize: "13px",
                              marginBottom: "5px",
                            }}
                            multiple
                            displayEmpty
                            value={props.applicableTag}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <em>Select </em>;
                              }

                              return selected.join(", ");
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem disabled value="">
                              <em>Select</em>
                            </MenuItem>
                            {tag.tags.map((t,idx) => (
                              <MenuItem
                                key={idx}
                                value={t}
                                style={getStyles(t, theme)}
                              >
                                {t}
                              </MenuItem>
                            ))}
                          </Select>
                        </React.Fragment>
                      )
                  )}
                </div>
                <div
                  style={{ margin: "0px 20px 0 0", display: "inline-grid" }}
                  sx={{ m: 3 }}
                >
                  {tags.map(
                    (tag) =>
                      tag.tag_type_id == "5" && (
                        <React.Fragment>
                          <FormLabel
                            style={{ paddingBottom: "5px", fontSize: "13px" }}
                            component=""
                          >
                            {tag.tag_type}:
                          </FormLabel>
                          <Select
                            onChange={props.searchInputHandlerQmsTag}
                            style={{
                              width: "146px",
                              height: "30px",
                              fontSize: "13px",
                              marginBottom: "5px",
                            }}
                            multiple
                            displayEmpty
                            value={props.qmsTag}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <em>Select </em>;
                              }

                              return selected.join(", ");
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem disabled value="">
                              <em>Select</em>
                            </MenuItem>
                            {tag.tags.map((t,idx) => (
                              <MenuItem
                                key={idx}
                                value={t}
                                style={getStyles(t, theme)}
                              >
                                {t}
                              </MenuItem>
                            ))}
                          </Select>
                        </React.Fragment>
                      )
                  )}
                  {/* <ToggleButton size="small" sx={{ paddingBottom: "5px", fontSize: "13px" , marginTop: "25px", height:"25px"}}
                      onClick={()=>props.setHideDuplicates(!props.hideDuplicates)}
                    >
                      {props.hideDuplicates?"ALL":"LIMIT"}
                  </ToggleButton> */}

                  {props.hideDuplicates!==undefined?
                  (<Box><FormControlLabel  size="small" sx={{ paddingBottom: "5px", fontSize: "13px!important" , marginTop: "25px", height:"25px"}}
                      label="Show unique"
                      control={<Checkbox checked={props.hideDuplicates} size="small" color="secondary" onChange={(event)=>{
                        props.setHideDuplicates(event.target.checked) 
                      }}  />
                      }
                    />
                    <InfoOutlinedIcon
                      fontSize="tiny"
                      data-html={true}
                      data-tip='<h3>Show unique documents:</h3> <hr/> <pre>Filters and displays the first entry <br/> for each of the documents from the search results<pre/>'
                    >
                      
                    </InfoOutlinedIcon></Box>):""}

                  <div style={{ display: "none" }}>
                    {tags.map(
                      (tag) =>
                        tag.tag_type_id == "6" && (
                          <React.Fragment>
                            <FormLabel
                              style={{  
                              width: "146px",
                              height: "30px",
                              fontSize: "13px",
                              marginBottom: "5px",
                              marginTop:"25px",

                             }}
                              component=""
                            >
                              {tag.tag_type}:
                            </FormLabel>
                            <Select
                              onChange={props.searchInputHandlerRegulationTag}
                              style={{
                                width: "146px",
                                height: "30px",
                                fontSize: "13px",
                                marginBottom: "5px",
                              }}
                              multiple
                              displayEmpty
                              value={props.regulationTag}
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return <em>Select </em>;
                                }

                                return selected.join(", ");
                              }}
                              MenuProps={MenuProps}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem disabled value="">
                                <em>Select</em>
                              </MenuItem>
                              {tag.tags.map((t,idx) => (
                                <MenuItem
                                  key={idx}
                                  value={t}
                                  style={getStyles(t, theme)}
                                >
                                  {t}
                                </MenuItem>
                              ))}
                            </Select>
                          </React.Fragment>
                        )
                    )}
                  </div>
                </div>
               

                <FormControl
                  style={{ margin: "25px 5px 0 0" }}
                  sx={{ m: 3 }}
                  component="fieldset"
                  variant="standard"
                >
                  <Stack spacing={2}>
                    <Button
                      variant="contained"
                      type="submit"
                      className="gsk-btn primary pill"
                      onClick={(e) => props.searchAsmresult(e)}
                    >
                      Apply & Search
                    </Button>

                    <Button
                      variant="contained"
                      type="submit"
                      className="gsk-btn primary pill"
                      onClick={props.reSet}
                    >
                      Reset
                    </Button>
                    
                  </Stack>
                </FormControl>
              </form>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
}

export default AdvanceSearch;
