import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Button,
  styled,
  CircularProgress,
  Typography,
  Switch,
  Box,
  FormControl,
  IconButton,
  ButtonGroup,
  TextField,
  Stack,
  InputLabel,
  MenuItem,
  Select,
  Divider,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import DataView from "../../DataTable/DataView";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { Tooltip } from "@mui/material";
import { BreadcrumsContext } from "../context/breadcrumsContext";
import { UserDataContext } from "../../Context/UserDataContext";
import axios from "axios";
import moment from "moment";
import * as rimaConstants from "../../../rimaconstants";
import ConfirmDialog from "../../Common/confirmDailog";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../applicationInsights/AppInsights";

import { useDispatch } from "react-redux";
import {
  insertNotification,
  updateNoticationStatus,
} from "../../../reducers/araReducer";
import Alert from "@mui/material/Alert";

const Input = styled("input")({
  display: "none",
});
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
//TODO: Support other file types
//"application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/rtf,application/vnd.oasis.opendocument.presentation,application/vnd.oasis.opendocument.spreadsheet,application/vnd.oasis.opendocument.text"
const allowedFileTypes = `application/pdf,
                          application/msword,
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                          application/vnd.ms-excel,
                          application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                          application/vnd.ms-powerpoint,
                          application/vnd.openxmlformats-officedocument.presentationml.presentation
                          `;

const SiteDocumentUpload = () => {
  const dispatch = useDispatch();
  const { setBreadcrums } = useContext(BreadcrumsContext);
  // eslint-disable-next-line no-unused-vars
  const [adminUser, setAdminUser, user, setUser] = useContext(UserDataContext);

  const [siteData, setSiteData] = useState({
    source: "Vx",
    number: "",
    documents: [
      { docType: "", docTypeId: "", docs: [], processing_required: "" },
    ],
  });
  const [defaultDoc, setDefaultDoc] = useState({});
  const [loading, setLoading] = useState(false);
  const [audit, setAudit] = useState({});
  // const [auditList, setAuditList] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [editView, setEditView] = useState(false);
  const [uploadCount, setUploadCount] = useState(0);
  const [startUpload, setStartUpload] = useState(0);
  const [finishedUpload, setFinishedUpload] = useState(0);
  const [auditDocuments, setAuditDocuments] = useState([]);
  // const [docView, setDocView] = useState(false);
  // const [viewList, setViewList] = useState([]);
  // const [doc, setDoc] = useState({});
  const [totalDocs, setTotalDocs] = useState(0);
  // const [timer, setTimer] = useState(null);
  const [auditFetching, setAuditFetching] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [loaderImg, setLoaderImg] = useState("");
  const [auditNumber, setAuditNumber] = React.useState("");
  const [auditNumber1, setAuditNumber1] = React.useState("");
  const [isDocExists, setIsDocExists] = useState({});
  const [overrideDoc, setOverrideDoc] = useState(false);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [deleteIds, setDeleteIds] = React.useState("");
  const [deleteSelectedPopup, setDeleteSelectedPopup] = useState(false);
  const [typeExists, setTypeExists] = useState([]);
  const [markLatestOpen, setMarkLatestOpen] = useState(false);
  const [auditTypeDesc, setAuditTypeDesc] = useState({});
  const [auditTypeInfo, setAuditTypeInfo] = useState({});
  const [downloadLoading, setDownloadLoading] = useState(false);

  const [height, setHeight] = React.useState(0);
  const ref = React.useRef(null);
  React.useEffect(() => {
    setHeight(window.innerHeight - 200);
  }, []);

  let allowFileOverride = false;

  const onDelete = async (audit_reference_document_id, is_latest_document) => {
    if (is_latest_document) {
      setMarkLatestOpen(true);
    } else {
      setDeleteId(audit_reference_document_id);
      setDeletePopup(true);
    }
  };

  const headers = [
    {
      field: "id",
      headerName: "#",
      width: 50,
      //"flex":1,
    },
    {
      field: "action",
      headerName: "Action",
      //"flex":1,
      width: 150,
      sortable: false,
      renderCell: (params) => {
        const onClickView = async (
          e,
          filename = params.row.document_storage_url
        ) => {
          e.stopPropagation();
          let format = filename.split("/").pop(0).split(".")[1];
          let type = window.getDocumentTypeByFileExtention(format);
          let url = `${window.BLOB_URL}/${rimaConstants.ARA_ROOT_CONTAINER}/${
            filename + window.BLOB_CONATINER_SAS_READ_TOKEN
          }`;
          window.downloadFile(url, type, filename);
        };
        return (
          <ButtonGroup
            size="small"
            variant="contained"
            aria-label="outlined primary button group"
            //sx={{ width: "10px" }}
          >
            <IconButton size="small" onClick={onClickView} title="View">
              <PreviewIcon />
            </IconButton>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(
                  params.row.audit_reference_document_id,
                  params.row.is_latest_document
                );
              }}
              title="Delete"
            >
              <DeleteIcon />
            </IconButton>
            {loaderImg === params.row.audit_reference_document_id && (
              <IconButton>
                <CircularProgress size={17} thickness={4} />
              </IconButton>
            )}
          </ButtonGroup>
        );
      },
    },
    {
      field: "audit_document_type_description",
      headerName: "Document Type",
      width: 260,
      //"flex":1,
    },
    {
      field: "document_title",
      headerName: "File Name",
      width: 260,
      renderCell: (params) => {
        return <>{params.value.split("/").pop(0)}</>;
      },
      //"flex":1,
    },
    {
      field: "is_latest_document_text",
      headerName: "Latest",
      width: 150,
      renderCell: (params) => {
        return (
          <AntSwitch
            checked={params.row.is_latest_document}
            inputProps={{ "aria-label": "ant design" }}
            onClick={(e) => {
              e.stopPropagation();
              params.row.is_latest_document
                ? window.showMessage(
                    "Document already marked as latest",
                    "warning"
                  )
                : onClickMarkDocumentLatest(
                    params.row.audit_reference_document_id,
                    params.row.audit_document_type_id,
                    params.row.document_storage_url.split("/").pop(0)
                  );
            }}
          ></AntSwitch>
        );
      },
      //"flex":1,
    },
    {
      field: "status_text",
      headerName: "Status",
      width: 150,
      // renderCell: (params) => {
      //   let status = ["Not Processed", "Processed", "Failed", "Deleted"];
      //   return <>{status[params.value] ? status[params.value] : "Unknown"}</>;
      // },
      //"flex":1,
    },
    {
      field: "created_date_time",
      headerName: "Date & Time",
      width: 200,
      type1: "date",
      type: "string",
      getApplyQuickFilterFn: (value) =>
        window.getApplyQuickFilterFn(value, true),
      valueGetter: ({ value }) => window.getDateGridBinding(value, true),
      renderCell: (params) => {
        return (
          <>
            {params.value ? (
              <Tooltip
                title={window.getDate(params.row.created_date_time, true)}
              >
                <span>
                  {window.getDate(params.row.created_date_time, true)}
                </span>
              </Tooltip>
            ) : (
              ""
            )}
          </>
        );
      },
      //"flex":1,
    },
  ];

  useEffect(() => {
    setBreadcrums((prevBreadcrums) => {
      prevBreadcrums.vaccine = false;
      prevBreadcrums.pharma = false;
      prevBreadcrums.parentMenu = "Upload Documents";
      prevBreadcrums.childMenu = "";
      prevBreadcrums.tableColumnData = [];
      prevBreadcrums.tableRowData = [];
      return { ...prevBreadcrums };
    });
    getAuditDocumentTypes();
  }, []);

  useEffect(() => {
    if (
      loading &&
      (uploadCount === totalDocs || startUpload === finishedUpload)
    ) {
      updateDocuments();
      setLoading(false);
      setTotalDocs(0);
      setUploadCount(0);
    }
  }, [uploadCount, finishedUpload]);

  // Notification handlers
  const addToNotifications = (
    notificationId,
    filetype,
    action,
    status,
    document_name
  ) => {
    dispatch(
      insertNotification({
        id: notificationId,
        fileType: filetype,
        exTime: new Date().toLocaleTimeString(),
        actionType: "upload",
        fileName: document_name,
        status: "RUNNING",
      })
    );
  };

  const trackNotificationForUpload = (upload_data, response) => {
    let tracker_docset = [];
    for (const doc of upload_data.document_storage_url) {
      //(notificationId, filetype, action, status, document_name)
      let notificationId = new Date().getTime();
      let doctype = documentTypes.filter(
        (item) => item.audit_document_type_id === doc.audit_document_type_id
      );

      addToNotifications(
        notificationId,
        doctype[0].audit_document_type,
        "upload",
        "RUNNING",
        doc.document_name
      );

      //Create tracker data
      let dbinfo = response.filter((item) => item.document === doc.document)[0];
      let trackerdata = {
        notificationId: notificationId,
        audit_document_user_request_control_id:
          dbinfo.audit_document_user_request_control_id,
        audit_reference_document_id: dbinfo.audit_reference_document_id,
        document: dbinfo.document,
        audit_document_type: doctype[0].audit_document_type,
      };
      tracker_docset.push(trackerdata);
    }
    //Spawn tracking worker thread to check status periodically  tracker_docset
    new Promise((resolve, reject) => {
      var status_received = false;
      async function checkStatus() {
        //Get status for the timer
        await axios
          .post(
            `${rimaConstants.REACT_APP_API_URL}/api/v1/site_document_upload_page/processing_status`,
            tracker_docset
          )
          .then((response) => {
            if (response?.data?.status === "success") {
              let processed_status = response?.data?.data;
              const status = [
                "PROCESSING",
                "SUCCESS",
                "FAILED",
                "SUCCESS", //"DUPLICATE",
                "FAILED",
                "Not Processed",
              ];
              let all_processed = true;

              for (let obj of processed_status) {
                all_processed =
                  all_processed && parseInt(obj.process_status) !== 0;
                dispatch(
                  updateNoticationStatus({
                    id: obj.notificationId,
                    fileType: obj.audit_document_type,
                    status: status[parseInt(obj.process_status)],
                  })
                );
              }

              if (all_processed) {
                resolve(processed_status);
                getAuditDocuments(audit.audit_id);
                status_received = true;
              }
            }

            if (!status_received) {
              setTimeout(checkStatus, 5000);
            }
          })
          .catch((e) => {
            console.log(e);
            reject("not processed");
          });
      }
      checkStatus();
    })
      .then((val) => {
        //console.log(val);
      })
      .catch((err) => console.log(err));
  };

  const updateDocuments = () => {
    let req_data = {
      audit_id: audit.audit_id,
      audit_site_id: audit.audit_site_id,
      data_source: siteData.source,
      document_storage_url: [],
    };
    siteData.documents.map((doc) => {
      if (doc.docTypeId !== "") {
        doc.docs.map((docu) => {
          let document = {
            document: docu.filePath,
            audit_document_type_id: doc.docTypeId,
            document_name: docu.name,
            is_latest_document: docu.name === defaultDoc[doc.docType] ? 1 : 0,
          };
          req_data.document_storage_url.push(document);
          return true;
        });
      }
      return true;
    });
    let req_type = "put";
    window.showLoader();
    axios({
      method: req_type,
      url: `${rimaConstants.REACT_APP_API_URL}/api/v1/site_document_upload_page/update`,
      data: req_data,
    })
      .then((response) => {
        if (response.data.status === "success") {
          //console.log(response);
          //Add and track notifications for all the files uploaded
          trackNotificationForUpload(req_data, response.data.data);
          getAuditDocuments(req_data.audit_id);
          // toast.success("Uploaded");
          window.showMessage(
            `Documents are uploaded for further processing...`,
            "success"
          );
          setEditView(false);
          setSiteData((prev) => ({
            ...siteData,
            documents: [
              {
                docType: "",
                doc: null,
                name: "",
                docTypeId: "",
                uploaded: false,
                processing_required: "",
              },
            ],
          }));
          window.hideLoader();
          setDefaultDoc({});
        } else {
          console.log("failed");
          window.hideLoader();
          setDefaultDoc({});
        }
      })
      .catch((error) => {
        console.log(error);
        window.hideLoader();
        setDefaultDoc({});
      });
    //console.log(req_data);
  };

  const getAuditDocumentTypes = () => {
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/site_document_upload_page/audit_document_type`
      )
      .then((response) => {
        //console.log(response.data);
        response?.data?.data && setDocumentTypes(response.data.data);
        if (response?.data?.data) {
          let tmpObj = {};
          response?.data?.data.map((obj) => {
            tmpObj[`${obj.audit_document_type}`] =
              obj.audit_document_type_description;
            return true;
          });
          setAuditTypeDesc(tmpObj);

          let tmpObj1 = {};
          response?.data?.data.map((obj) => {
            tmpObj1[`${obj.audit_document_type}`] = obj.description;
            return true;
          });
          setAuditTypeInfo(tmpObj1);
        }
      });
  };

  const getAuditDetails = async (value) => {
    if (!value || !siteData.source) {
      return false;
    }
    setAuditFetching(true);
    return await axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/site_document_upload_page/active_audit/` +
          siteData.source +
          "?audit_number=" +
          window.base64_encode(value)
      )
      .then((response) => {
        setAuditFetching(false);
        if (response?.data?.data?.length) {
          let audit_id = response.data.data[0].audit_id;
          setSiteData({
            ...siteData,
            number: response.data.data[0].audit_number,
          });
          setAuditNumber1(response.data.data[0].audit_number);
          setAudit(response.data.data[0]);
          window.showMessage(
            `You can upload documents now.\r\nAudit number:${value}`,
            "success"
          );
          getAuditDocuments(audit_id);
          return true;
        } else if (response?.data?.msg) {
          resetAudit();
          window.showMessage(
            `Invalid audit number or you do not have access to audit.\r\nAudit number entered :${value}`,
            "warning"
          );
          return false;
        } else {
          resetAudit();
          window.showMessage(
            `Invalid audit number entered :${value}`,
            "warning"
          );
          return false;
        }
      });
  };

  const getAuditDocuments = (audit_id) => {
    window.showLoader();
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/site_document_upload_page/list/` +
          audit_id
      )
      .then((response) => {
        setTypeExists([]);
        if (response?.data?.data) {
          let tmpObj1 = {};
          response?.data?.data.map((obj) => {
            if (!tmpObj1[`${obj.audit_document_type_id}`]) {
              let tmp1 = response.data.data.filter(
                (tmpObj) =>
                  tmpObj.audit_document_type_id === obj.audit_document_type_id
              );

              tmpObj1[`${obj.audit_document_type_id}`] = {
                is_latest_document: 0,
                files: [],
                rows: tmp1.length,
                fileName:
                  tmp1.length === 1
                    ? tmp1[0].document_storage_url.split("/").pop(0)
                    : "",
              };
            }
            if (parseInt(obj.is_latest_document)) {
              tmpObj1[`${obj.audit_document_type_id}`][`is_latest_document`] =
                parseInt(obj.is_latest_document);
              tmpObj1[`${obj.audit_document_type_id}`]["files"].push(
                obj.document_storage_url.split("/")[
                  obj.document_storage_url.split("/").length - 1
                ]
              );
              tmpObj1[`${obj.audit_document_type_id}`]["fileName"] =
                obj.document_storage_url.split("/").pop(0);
            } else {
              tmpObj1[`${obj.audit_document_type_id}`]["files"].push(
                obj.document_storage_url.split("/")[
                  obj.document_storage_url.split("/").length - 1
                ]
              );
            }
            return true;
          });
          setIsDocExists(tmpObj1);
          setAuditDocuments(response.data.data);
        }
        window.hideLoader();
      });
  };

  const resetAudit = () => {
    setAuditDocuments([]);
    setEditView(false);
    setAudit({});
    setSiteData((prev) => ({
      ...siteData,
      number: "",
      documents: [
        {
          docType: "",
          doc: null,
          name: "",
          docTypeId: "",
          uploaded: false,
          processing_required: "",
        },
      ],
    }));
  };

  const retrieveAudit = (e) => {
    setAuditFetching(true);
    handleChange("number", auditNumber);
  };

  const handleChange = async (field, value) => {
    switch (field) {
      case "source":
        resetAudit();
        document.getElementById("audit_number").value = "";
        setSiteData({
          ...siteData,
          [field]: value,
          number: "",
          documents: [
            {
              docType: "",
              doc: null,
              name: "",
              docTypeId: "",
              uploaded: false,
              processing_required: "",
            },
          ],
        });
        break;
      case "number":
        if (await getAuditDetails(value))
          setSiteData({
            ...siteData,
            [field]: value,
            documents: [
              {
                docType: "",
                docTypeId: "",
                docs: [],
                processing_required: "",
              },
            ],
          });
        break;
      default:
        break;
    }
  };

  const addDocument = () => {
    let doc = {
      docType: "",
      docTypeId: "",
      docs: [],
    };
    let length = siteData.documents.length;
    if (
      siteData.documents[length - 1].docType !== "" &&
      siteData.documents[length - 1].docs.length
    ) {
      setSiteData((prev) => ({
        ...siteData,
        documents: [...prev.documents, doc],
      }));
    }
  };

  React.useEffect(() => {
    // console.log("totalDocs siteData ", siteData.documents);
    // console.log("totalDocs siteData typeExists ", typeExists);
    // console.log("totalDocs siteData isDocExists ", isDocExists);
    let dataDoc = siteData.documents.filter((item) => item.docType !== "");
    dataDoc?.map((doc) => {
      doc?.docs?.map((docu) => {
        if (
          isDocExists[doc.docTypeId] &&
          isDocExists[doc.docTypeId]["fileName"] &&
          isDocExists[doc.docTypeId]["fileName"] === docu.name &&
          isDocExists[doc.docTypeId]["is_latest_document"]
        ) {
          if (!defaultDoc[doc.docType]) {
            let tmpObj = {};
            tmpObj[`${doc.docType}`] = docu.name;
            setDefaultDoc({
              ...defaultDoc,
              ...tmpObj,
            });
          }
        }
        return true;
      });
      return true;
    });
  }, [siteData.documents]);

  const handleDocChange = (value, index) => {
    // console.log("totalDocs siteData ", siteData.documents);
    // console.log("totalDocs siteData typeExists ", typeExists);

    let dataDoc = siteData.documents.filter((item) => item.docType === value);

    if (dataDoc.length) {
      window.showMessage("Select different document type", "warning");
      return false;
    }

    // index === 0 &&
    // siteData.documents.filter((item) => item.docType !== "").length === 1
    //   ? setTypeExists([value])
    //   : setTypeExists([...typeExists, value]);

    let doc = documentTypes.filter(
      (item) => item.audit_document_type === value
    );
    let newState = [...siteData.documents];
    newState[index]["docType"] = value;
    newState[index]["docTypeId"] = doc[0].audit_document_type_id;
    newState[index]["processing_required"] = doc[0].processing_required;
    // newState[index]["doc"] = null;
    // newState[index]["name"] = "";
    newState[index]["docs"] = [];

    let documentTypeExists = [];
    newState.map((item) => {
      if (item?.docType) {
        documentTypeExists = [...documentTypeExists, item.docType];
      }
      return true;
    });
    setTypeExists(documentTypeExists);

    setSiteData((prev) => ({ ...siteData, documents: newState }));
  };

  const getUniqueID = (() => {
    let i = 0;
    return () => {
      return ++i;
    };
  })();

  const handleSubmit = async () => {
    if (totalDocs) {
      let is_latest_document = {};
      let is_latest_document1 = {};
      let isFileExists = [];
      let errs = [];
      let showConfirmPopUp = true;

      siteData.documents.map((doc) => {
        if (doc.docTypeId !== "") {
          if (isDocExists[doc.docTypeId]) {
            if (isDocExists[doc.docTypeId]["is_latest_document"]) {
              if (!is_latest_document[`${doc.docType}`]) {
                is_latest_document[`${doc.docType}`] = [];
              }
              if (
                ![0, 1].includes(
                  isDocExists[doc.docTypeId] &&
                    isDocExists[doc.docTypeId]["rows"]
                    ? isDocExists[doc.docTypeId]["rows"]
                    : -1
                )
              ) {
                is_latest_document[`${doc.docType}`].push(
                  `Latest document already present for ${
                    auditTypeDesc[doc.docType]
                  }`
                );
              } else if (
                [1].includes(
                  isDocExists[doc.docTypeId] &&
                    isDocExists[doc.docTypeId]["rows"]
                    ? isDocExists[doc.docTypeId]["rows"]
                    : -1
                ) &&
                defaultDoc[doc.docType]
              ) {
                is_latest_document[`${doc.docType}`].push(
                  `Latest document already present for ${
                    auditTypeDesc[doc.docType]
                  }`
                );
              } else {
                if (!is_latest_document1[`${doc.docType}`]) {
                  is_latest_document1[`${doc.docType}`] = [];
                }
                is_latest_document1[`${doc.docType}`].push(1);
              }
            } else {
              if (!is_latest_document[`${doc.docType}`]) {
                is_latest_document[`${doc.docType}`] = [];
              }
              is_latest_document[`${doc.docType}`].push(0);
            }
          }

          doc.docs.map((docu) => {
            if (doc.docTypeId !== "") {
              if (isDocExists[doc.docTypeId]) {
                if (
                  isDocExists[doc.docTypeId]["files"] &&
                  isDocExists[doc.docTypeId]["files"].length &&
                  isDocExists[doc.docTypeId]["files"].includes(docu.name)
                ) {
                  if (
                    isDocExists[doc.docTypeId]["rows"] &&
                    isDocExists[doc.docTypeId]["rows"] === 1 &&
                    isDocExists[doc.docTypeId]["fileName"] === docu.name
                  ) {
                    isFileExists.push(
                      `${docu.name} already exists in ${
                        auditTypeDesc[doc.docType]
                      }`
                    );

                    if (!defaultDoc[doc.docType]) {
                      showConfirmPopUp = false;
                      isFileExists.push(
                        `Use switch to set latest document for ${
                          auditTypeDesc[doc.docType]
                        }`
                      );
                    }
                    if (!is_latest_document1[`${doc.docType}`]) {
                      if (!is_latest_document1[`${doc.docType}`]) {
                        is_latest_document1[`${doc.docType}`] = [];
                      }
                      is_latest_document1[`${doc.docType}`].push(1);
                    }
                  } else {
                    if (isDocExists[doc.docTypeId]["fileName"] === docu.name) {
                      // if (
                      //   !defaultDoc[doc.docType] ||
                      //   defaultDoc[doc.docType] !== docu.name
                      // )
                      if (!defaultDoc[doc.docType]) {
                        showConfirmPopUp = false;
                        isFileExists.push(
                          `Use switch to set latest document for ${
                            auditTypeDesc[doc.docType]
                          }`
                        );
                      }
                    }
                    isFileExists.push(
                      `${docu.name} already exists in ${
                        auditTypeDesc[doc.docType]
                      }`
                    );
                  }
                }
              }
            }
            if (
              defaultDoc[doc.docType] &&
              defaultDoc[doc.docType] === docu.name
            ) {
              if (!is_latest_document1[`${doc.docType}`]) {
                is_latest_document1[`${doc.docType}`] = [];
              }
              is_latest_document1[`${doc.docType}`].push(1);
            }
            return true;
          });
        }
        return true;
      });

      siteData.documents.map((doc) => {
        if (doc.docTypeId !== "" && doc.docType !== "") {
          if (doc.docs.length) {
            if (
              !isDocExists[doc.docTypeId] &&
              !is_latest_document1[doc.docType]
            ) {
              showConfirmPopUp = false;
              errs.push(
                `Use switch to set latest document for ${
                  auditTypeDesc[doc.docType]
                }`
              );
            }
          }
        }
        return true;
      });

      for (let key in is_latest_document) {
        if (
          (!is_latest_document[key] ||
            (is_latest_document[key] && !is_latest_document[key][0])) &&
          (!is_latest_document1[key] ||
            (is_latest_document1[key] && !is_latest_document1[key][0]))
        ) {
          errs = [...errs, `${key}: Use switch to set latest document`];
        }

        if (
          is_latest_document[key] &&
          is_latest_document[key][0] &&
          is_latest_document1[key] &&
          is_latest_document1[key][0] &&
          !allowFileOverride
        ) {
          errs = [...errs, is_latest_document[key]];
          if (showConfirmPopUp) setOverrideDoc(true);
        }
      }

      if (is_latest_document.length) {
        errs = [...errs, ...is_latest_document];
      }
      if (isFileExists.length && !allowFileOverride) {
        errs = [...errs, ...isFileExists];
        if (showConfirmPopUp) setOverrideDoc(true);
      }
      allowFileOverride = false;
      if (errs.length) {
        window.showMessage("* " + errs.join("\n\r* "), "warning");
        return false;
      }

      let foldername = "ara";
      let subfolder = `${siteData.source}/${auditNumber1}/AuditDocuments/${
        user.user_id
      }/${moment().utc().unix()}`;
      setLoading(true);
      window.showLoader();
      siteData.documents.map((document) => {
        if (document.docType !== "") {
          document.docs.map((doc) => {
            var req_obj = {};
            var file_obj = doc;
            req_obj = new FormData();
            for (var key in file_obj) {
              if (key === "doc") {
                req_obj.append("upfile", file_obj[key]);
              }
            }
            setStartUpload((prev) => prev + 1);
            axios
              .post(
                `${
                  rimaConstants.REACT_APP_API_URL
                }/api/v1/upload?folderName=${foldername}&subFolderName=${subfolder}/${getUniqueID()}&fileName=${encodeURIComponent(
                  doc.name
                )}&docType=${document.docType}`,
                req_obj
              )
              .then((response) => {
                if (response.data.status === "success") {
                  //console.log(response.data.fileName);
                  let type = response.data.docType;
                  let par_index = siteData.documents.findIndex(
                    (item) => item.docType.toLowerCase() === type.toLowerCase()
                  );
                  let doc_index = siteData.documents[par_index][
                    "docs"
                  ].findIndex((item) => item.name === response.data.fileName);
                  //console.log(doc_index);
                  let newState = [...siteData.documents];
                  newState[par_index]["docs"][doc_index]["filePath"] =
                    response.data.filePath;
                  newState[par_index]["docs"][doc_index]["rootFolder"] =
                    response.data.folderName;
                  newState[par_index]["docs"][doc_index]["uploaded"] = true;
                  setSiteData((prev) => ({ ...siteData, documents: newState }));
                  setUploadCount((prev) => prev + 1);
                  setFinishedUpload((prev) => prev + 1);
                } else {
                  setUploadCount((prev) => prev + 1);
                  setFinishedUpload((prev) => prev + 1);
                  console.log("failed");
                }
              })
              .catch((error) => {
                setUploadCount((prev) => prev + 1);
                setFinishedUpload((prev) => prev + 1);
                console.log(error);
              });
            return true;
          });
        } else {
          console.log("executed here else");
        }
        return true;
      });
    }
  };

  const removeFile = (file, parentindex, index, docType) => {
    if (defaultDoc[`${docType}`] && defaultDoc[`${docType}`] === file) {
      defaultDoc[`${docType}`] = 0;
    }
    let newState = [...siteData.documents];
    newState[parentindex]["docs"] = siteData.documents[parentindex][
      "docs"
    ].filter((item) => item.name !== file);
    setSiteData((prev) => ({ ...siteData, documents: newState }));
    setTotalDocs((prev) => prev - 1);
  };

  const onClickDelete = () => {
    window.showLoader();
    setLoaderImg(deleteId);
    try {
      let collections = JSON.stringify([deleteId]);
      axios
        .delete(
          `${
            rimaConstants.REACT_APP_API_URL
          }/api/v1/site_document_upload_page/document/${window.base64_encode(
            collections
          )}`
        )
        .then((response) => {
          setLoaderImg("");
          if (response?.data?.status === "success") {
            setDeleteId("");
            getAuditDocuments(audit.audit_id);
            window.showMessage("Document(s) deleted successfully", "success");
          } else {
            if (response?.data?.msg) {
              window.showMessage(response.data.msg, "warning");
            } else {
              window.showMessage(
                "There is some issue while deleting the document(s)",
                "warning"
              );
            }
          }
          window.hideLoader();
        })
        .catch(function (error) {
          console.log(error);
          window.showMessage(
            "There is some issue while deleting the document",
            "error"
          );
          window.hideLoader();
        });
    } catch (e) {
      window.hideLoader();
      console.log(e.message);
    }
  };

  const onClickMarkDocumentLatest = (
    audit_reference_document_id,
    audit_document_type_id,
    document_name
  ) => {
    window.showLoader();
    try {
      axios
        .put(
          `${
            rimaConstants.REACT_APP_API_URL
          }/api/v1/site_document_upload_page/mark_latest_document/${audit_reference_document_id}/${
            audit.audit_id
          }/${audit_document_type_id}?document_name=${encodeURIComponent(
            document_name
          )}`
        )
        .then((response) => {
          if (response?.data?.status === "success") {
            getAuditDocuments(audit.audit_id);
            window.showMessage("Document mark latest successfully", "success");
          } else {
            window.showMessage(
              "There is some issue while deleting the document(s)",
              "warning"
            );
          }
          window.hideLoader();
        })
        .catch(function (error) {
          console.log(error);
          window.showMessage(
            "There is some issue while deleting the document",
            "error"
          );
          window.hideLoader();
        });
    } catch (e) {
      window.hideLoader();
      console.log(e.message);
    }
  };

  const handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  const renderHtml = (html) => {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };

  const onFileChange = (event, index) => {
    // console.log("totalDocs value ", event.target.files[0]);
    // console.log(
    //   "totalDocs siteData.documents[index] ",
    //   siteData.documents[index]
    // );
    let rtn = false;
    //console.log(siteData);
    // if (siteData.documents[index]["docType"] === "LIC") {
    //   for (let i = 0; i < event.target.files.length; i++) {
    //     if (
    //       ![
    //         "application/vnd.ms-excel",
    //         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //       ].includes(event.target.files[i].type)
    //     ) {
    //       window.showMessage(
    //         renderHtml(
    //           `Only Excel is supported for <strong>${
    //             auditTypeDesc[siteData.documents[index]["docType"]]
    //           }</strong> document(s)`
    //         ),
    //         "warning"
    //       );
    //       rtn = true;
    //     }
    //   }
    // } else if (siteData.documents[index]["processing_required"] === "No") {
    //   /*else if (siteData.documents[index]["docType"] === "PAQR") {*/
    //   for (let i = 0; i < event.target.files.length; i++) {
    //     if (
    //       ![
    //         "application/vnd.ms-excel",
    //         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //         "application/pdf",
    //         "application/msword",
    //         "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //         "application/vnd.ms-powerpoint",
    //         "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    //       ].includes(event.target.files[i].type)
    //     ) {
    //       window.showMessage(
    //         renderHtml(
    //           `Only Excel,Word,PowerPoint and PDF are supported for <strong>${
    //             auditTypeDesc[siteData.documents[index]["docType"]]
    //           }</strong> document(s)`
    //         ),
    //         "warning"
    //       );
    //       rtn = true;
    //     }
    //   }
    // } else {
    //   for (let i = 0; i < event.target.files.length; i++) {
    //     if (
    //       ![
    //         "application/pdf",
    //         "application/msword",
    //         "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //       ].includes(event.target.files[i].type)
    //     ) {
    //       window.showMessage(
    //         renderHtml(
    //           `Only Word and PDF are supported for <strong>${
    //             auditTypeDesc[siteData.documents[index]["docType"]]
    //           }</strong> document(s)`
    //         ),
    //         "warning"
    //       );
    //       rtn = true;
    //     }
    //   }
    // }

    for (let i = 0; i < event.target.files.length; i++) {
      if (
        ![
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.ms-powerpoint",
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        ].includes(event.target.files[i].type)
      ) {
        window.showMessage(
          renderHtml(
            `Only Excel,Word,PowerPoint and PDF are supported for <strong>${
              auditTypeDesc[siteData.documents[index]["docType"]]
            }</strong> document(s)`
          ),
          "warning"
        );
        rtn = true;
      }
    }

    if (rtn) {
      return true;
    }

    // if (!allowedFileTypes.split(",").includes(event.target.files[0].type)) {
    //   window.showMessage(
    //     "Unsupported file format. Only MS-Excel docx & pdf are supported."
    //   );
    // }

    for (var i = 0; i < event.target.files.length; i++) {
      //console.log(i);
      // let timestamp = moment().utc().unix() + i;
      let supported = ["pdf", "xls", "docx", "doc", "ppt", "pptx", "xlsx"];
      let type = event.target.files[i]["type"].split("/")[1];
      if (type === "msword") {
        type = "doc";
      } else if (
        type === "vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        type = "docx";
      } else if (type === "vnd.ms-excel") {
        type = "xls";
      } else if (type === "vnd.ms-powerpoint") {
        type = "ppt";
      } else if (
        type === "vnd.openxmlformats-officedocument.presentationml.presentation"
      ) {
        type = "pptx";
      } else if (
        type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        type = "xlsx";
      }
      //console.log(type);
      if (supported.includes(type)) {
        let newState = [...siteData.documents];
        let file_name = event.target.files[i]["name"]; //newState[index]["docType"] ;
        //console.log(event.target.files[0]);
        let filedata = {
          doc: event.target.files[i],
          name: file_name,
          uploaded: false,
        };
        let filterData = newState[index]["docs"].filter(
          (obj) => obj.name === filedata.name
        );
        // console.log("filterData ",filterData)
        if (filterData.length) {
          window.showMessage(`Duplicate file ${filedata.name}`, "warning");
        } else {
          newState[index]["docs"].push(filedata);
          setSiteData((prev) => ({ ...siteData, documents: newState }));
          setTotalDocs((prev) => prev + 1);
        }
      } else {
        //toast.warning("Invalid file type");
        window.showMessage("Invalid file type", "warning");
      }
    }
    document.getElementById(`contained-button-file-${index}`).value = null;
    addDocument();
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const onClickDeleteAll = () => {
    try {
      window.showLoader();
      axios
        .delete(
          `${
            rimaConstants.REACT_APP_API_URL
          }/api/v1/site_document_upload_page/document/${window.base64_encode(
            deleteIds
          )}`
        )
        .then((response) => {
          if (response?.data?.status === "success") {
            setDeleteIds("");
            window.showMessage("Document(s) deleted successfully", "success");
            getAuditDocuments(audit.audit_id);
          } else {
            if (response?.data?.msg) {
              window.showMessage(response.data.msg, "warning");
            } else {
              window.showMessage(
                "There is some issue while deleting the document(s)",
                "warning"
              );
            }
          }
          window.hideLoader();
          setDeleteIds("");
        })
        .catch(function (error) {
          console.log(error);
          window.showMessage(
            "There is some issue while deleting the document",
            "error"
          );
          window.hideLoader();
          setDeleteIds("");
        });
    } catch (e) {
      console.log(e.message);
      window.hideLoader();
      setDeleteIds("");
    }
  };

  const handleDeleteAll = () => {
    if (selectionModel?.length) {
      setDeleteSelectedPopup(true);
    } else {
      setDeleteSelectedPopup(false);
    }
  };

  const downloadDocumet = () => {
    try {
      // window.showLoader();
      axios
        .post(
          `${
            rimaConstants.REACT_APP_API_URL
          }/api/v1/site_document_upload_page/document_download/${window.base64_encode(
            deleteIds
          )}`
        )
        .then(async (response) => {
          setDownloadLoading(false);
          if (response?.data?.status === "success") {
            // setDeleteIds("");
            window.showMessage(
              "Document(s) downloaded successfully",
              "success"
            );
            // let url = response?.data?.path.replace(
            //   "./tmpdownloadexcel/",
            //   `${rimaConstants.REACT_APP_API_URL}/downloads/`
            // );
            // let a = document.createElement("a");
            // a.target = "_blank";
            // let token = await window.getAccessToken();
            // a.href = `${url}?token=${token}`;
            // a.click();
            let filename = response?.data?.path.replace(
              "./tmpdownloadexcel/",
              `downloadexcel/`
            );
            let url = `${window.BLOB_URL}/${rimaConstants.ARA_ROOT_CONTAINER}/${
              filename + window.BLOB_CONATINER_SAS_READ_TOKEN
            }`;
            let a = document.createElement("a");
            a.target = "_blank";
            a.href = url;
            a.click();
          } else {
            if (response?.data?.msg) {
              window.showMessage(response.data.msg, "warning");
            } else {
              window.showMessage(
                "There is some issue while downloading the document(s)",
                "warning"
              );
            }
          }
          // window.hideLoader();
          // setDeleteIds("");
        })
        .catch(function (error) {
          setDownloadLoading(false);
          console.log(error);
          window.showMessage(
            "There is some issue while downloading the document",
            "error"
          );
          // window.hideLoader();
          // setDeleteIds("");
        });
    } catch (e) {
      console.log(e.message);
      // window.hideLoader();
      setDownloadLoading(false);
      // setDeleteIds("");
    }
  };

  const handleDownloadDocumet = () => {
    if (selectionModel?.length) {
      setDownloadLoading(true);
      downloadDocumet();
    } else {
      window.showMessage("Please choose document(s) to delete.", "warning");
    }
  };

  useEffect(() => {
    //console.log("selectionModel ", selectionModel);
    if (selectionModel?.length) {
      let ids = auditDocuments
        .filter((obj) => {
          return selectionModel.includes(obj.id) ? true : false;
        })
        .map((obj) => {
          return obj.audit_reference_document_id;
        });
      setDeleteIds(JSON.stringify(ids));
    } else {
      setDeleteIds("");
    }
  }, [selectionModel]);

  return (
    <div className="container-main" ref={ref} style={{ minHeight: height }}>
      <Grid
        container
        sx={{
          pt: 3,
          pb: 3,
          backgroundColor: "#fff",
          border: "2px dashed hsla(0,0%,50.2%,.2)!important",
          maxWidth: "ml",
        }}
      >
        <Grid item xs={12} sx={{ pl: 2, mb: 2 }}>
          <Typography sx={{ fontSize: "1.2rem", fontWeight: 700 }}>
            Audit Details
          </Typography>
        </Grid>
        <Grid item xs={6} alignItems="center" sx={{ pl: 7 }}>
          <FormControl sx={{ m: 1, width: "40ch", height: "5ch" }}>
            <InputLabel id="source-select-label">Source</InputLabel>
            <Select
              labelId="source-select-label"
              id="source-select"
              value={siteData.source}
              label="Source"
              onChange={(e) => handleChange("source", e.target.value)}
              sx={{ height: "50px" }}
            >
              <MenuItem key={"Vx"} value="Vx">
                Vx
              </MenuItem>
              <MenuItem key={"Rx"} value="Rx">
                Rx
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={6}
          alignItems="baseline"
          sx={{ pl: 4, display: "flex", pr: 7 }}
        >
          <FormControl
            alignItems="center"
            sx={{ m: 1, width: "40ch", height: "5ch" }}
          >
            <TextField
              id="audit_number"
              label="Audit Number *"
              // inputType="text"
              name="audit_number"
              autoComplete="off"
              onInput={(e) => {
                setAuditNumber(e.target.value);
              }}
              size="small"
              value={auditNumber}
            />
          </FormControl>
          <FormControl
            alignItems="center"
            sx={{ m: 1, width: "25ch", height: "5ch" }}
          >
            <Button
              variant="contained"
              component="span"
              style={{ textTransform: "none" }}
              onClick={retrieveAudit}
              disabled={auditFetching || !auditNumber}
            >
              {!auditFetching ? "Retrieve" : "Retrieving... "}
              &nbsp;
              {auditFetching && (
                <CircularProgress
                  size={17}
                  color="info"
                  sx={{ color: "#595959" }}
                />
              )}
            </Button>
          </FormControl>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sx={{ m: 2 }}>
            <Item>
              <Divider />
            </Item>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center" sx={{ pl: 8, pt: 1 }}>
          <Grid item xs={6}>
            <Item sx={{ gridColumn: "1", fontSize: "0.875rem" }}>
              <strong>Audit Title : </strong>
              {window.customEscape(audit.audit_title)}
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item sx={{ gridColumn: "1", fontSize: "0.875rem" }}>
              <strong>Site Name : </strong>
              {window.customEscape(audit.site_description)}
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item sx={{ gridColumn: "1", fontSize: "0.875rem" }}>
              <strong>Audit Start Date : </strong>
              {audit.planned_fieldwork_start_date !== undefined
                ? window.getDate(audit.planned_fieldwork_start_date)
                : ""}
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item sx={{ gridColumn: "1", fontSize: "0.875rem" }}>
              <strong>Audit End Date : </strong>
              {audit.planned_fieldwork_end_date !== undefined
                ? window.getDate(audit.planned_fieldwork_end_date)
                : ""}
            </Item>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          mt: 5,
          pt: 3,
          pb: 3,
          backgroundColor: "#fff",
          border: "2px dashed hsla(0,0%,50.2%,.2)!important",
        }}
        visibility={audit?.audit_title ? "visible" : "hidden"}
      >
        <Grid item xs={12} sx={{ pl: 2, mb: 2 }}>
          <Typography sx={{ fontSize: "1.2rem", fontWeight: 700 }}>
            Upload Documents
          </Typography>
        </Grid>
        {audit?.audit_title &&
          siteData.documents.map(
            (doc, index) =>
              index !== documentTypes.length && (
                <>
                  <Grid item xs={5} sx={{ pl: 7 }}>
                    <FormControl sx={{ m: 2, width: "40ch" }}>
                      <InputLabel id="doctype-select-label">
                        Document type
                      </InputLabel>
                      <Select
                        labelId="doctype-select-label"
                        id="doctype-select"
                        value={doc.docType}
                        label="Document type"
                        onChange={(event) =>
                          handleDocChange(event.target.value, index)
                        }
                        inputProps={{ style: { textAlign: 0 } }}
                        sx={{ height: "50px" }}
                        disabled={editView ? true : false}
                      >
                        {documentTypes.map((doc1) => {
                          return doc.docType === doc1.audit_document_type ? (
                            <MenuItem
                              key={doc1.audit_document_type}
                              value={doc1.audit_document_type}
                            >
                              {doc1.audit_document_type_description}
                            </MenuItem>
                          ) : (
                            !typeExists.includes(doc1.audit_document_type) && (
                              <MenuItem
                                key={doc1.audit_document_type}
                                value={doc1.audit_document_type}
                              >
                                {doc1.audit_document_type_description}
                              </MenuItem>
                            )
                          );
                        })}
                      </Select>
                    </FormControl>
                    {doc.docType && (
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        * {auditTypeInfo[doc.docType]}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      p: 2,
                      ml: 3,
                    }}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        gridAutoFlow: "row",
                      }}
                    >
                      <Item sx={{ gridColumn: "1" }}>
                        <label htmlFor={`contained-button-file-${index}`}>
                          <Input
                            multiple
                            className="file-input"
                            id={`contained-button-file-${index}`}
                            type="file"
                            onChange={(event) => onFileChange(event, index)}
                            disabled={doc.docType === "" ? true : false}
                            accept={allowedFileTypes}
                            onClick={handleClick}
                          />
                          <Button
                            variant="contained"
                            component="span"
                            style={{ textTransform: "none" }}
                            disabled={doc.docType === "" ? true : false}
                          >
                            Choose File
                          </Button>
                        </label>
                      </Item>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      p: 2,
                    }}
                  >
                    {doc.docs !== undefined && doc.docs.length > 0 ? (
                      <Item
                        sx={{ fontSize: "0.875rem", fontWeight: "500", mt: 1 }}
                      >
                        <Typography variant="subtitle1" color="primary">
                          Use switch to set latest document{" "}
                        </Typography>
                      </Item>
                    ) : (
                      ""
                    )}
                    {doc.docs !== undefined &&
                      doc.docs.map((docu, i) => {
                        //console.log("docu ", doc.docType, defaultDoc);
                        let tmpObj = {};
                        return (
                          <Item
                            sx={{
                              fontSize: "0.875rem",
                              fontWeight: "500",
                              mt: 0.5,
                            }}
                          >
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <AntSwitch
                                checked={
                                  docu.name === defaultDoc[`${doc.docType}`]
                                    ? defaultDoc[`${doc.docType}`]
                                    : 0
                                }
                                onClick={(e) => {
                                  if (
                                    defaultDoc[`${doc.docType}`] &&
                                    defaultDoc[`${doc.docType}`] === docu.name
                                  ) {
                                    tmpObj[`${doc.docType}`] = 0;
                                  } else {
                                    tmpObj[`${doc.docType}`] = docu.name;
                                  }
                                  setDefaultDoc({
                                    ...defaultDoc,
                                    ...tmpObj,
                                  });
                                }}
                                inputProps={{ "aria-label": "ant design" }}
                              />{" "}
                              &nbsp;
                              {docu.name}
                              <span
                                sx={{ ml: 3 }}
                                onClick={() =>
                                  removeFile(docu.name, index, i, doc.docType)
                                }
                              >
                                <IconButton
                                  component="span"
                                  variant="contained"
                                  aria-label="close"
                                  size="small"
                                >
                                  <CancelRoundedIcon />
                                </IconButton>
                              </span>
                            </Stack>
                          </Item>
                        );
                      })}
                  </Grid>

                  {doc.uploaded ? (
                    <Grid
                      item
                      xs={1}
                      sx={{
                        p: 2,
                      }}
                    >
                      <IconButton aria-label="success" size="large">
                        <DoneOutlinedIcon color="success" />
                      </IconButton>
                    </Grid>
                  ) : (
                    ""
                  )}
                </>
              )
          )}
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{ display: "flex", gap: "1rem", justifyContent: "right" }}
        >
          {siteData.number ? (
            <Box sx={{ textAlign: "right", mt: "15px", mb: "15px", pr: 8 }}>
              <LoadingButton
                loading={loading}
                loadingPosition="end"
                style={{ minWidth: "120px" }}
                className="gsk-btn primary pill"
                variant="contained"
                fullWidth
                type="submit"
                disabled={totalDocs === 0}
                onClick={handleSubmit}
                // disabled={
                //   !(
                //     regulation.length > 0 &&
                //     auditee.length > 0 &&
                //     fileUploaded
                //   )
                // }
              >
                {loading ? "Uploading..." : "Upload"}
              </LoadingButton>
            </Box>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      {auditDocuments.length ? (
        <DataView
          columns={headers}
          rows={auditDocuments}
          menuName="Uploaded Documents"
          checkboxSelection={true}
          deleteAllButton={true}
          handleDeleteAll={handleDeleteAll}
          setSelectionModel={setSelectionModel}
          selectionModel={selectionModel}
          handleDownloadDocumet={handleDownloadDocumet}
          downloadLoading={downloadLoading}
          setDownloadLoading={setDownloadLoading}
          isRowSelectable={false}
          isRowSelectableSection={"Document_Management"}
          auditID={audit.audit_id}
        />
      ) : (
        ""
      )}

      <ConfirmDialog
        title={""}
        open={deletePopup}
        setOpen={setDeletePopup}
        onConfirm={() => onClickDelete()}
        handleNo={() => setDeletePopup(false)}
      >
        Do you want to delete the document(s)?
      </ConfirmDialog>
      <ConfirmDialog
        title={""}
        open={deleteSelectedPopup}
        setOpen={setDeleteSelectedPopup}
        onConfirm={() => onClickDeleteAll()}
        handleNo={() => setDeleteSelectedPopup(false)}
      >
        You are trying to delete the selected documents. If the{" "}
        <strong>latest document(s)</strong> are in the current selection, they
        will also be deleted.Do you want to delete the document(s)?
      </ConfirmDialog>
      <ConfirmDialog
        title={""}
        open={overrideDoc}
        setOpen={setOverrideDoc}
        onConfirm={() => {
          allowFileOverride = true;
          handleSubmit();
        }}
        handleNo={() => {
          allowFileOverride = false;
          setOverrideDoc(false);
        }}
      >
        Do you want to overwrite the document(s) or document(s) property?
      </ConfirmDialog>
      <ConfirmDialog
        title={""}
        open={markLatestOpen}
        setOpen={setMarkLatestOpen}
        onConfirm={() => {
          setMarkLatestOpen(false);
        }}
        isAlert={true}
      >
        You are just trying to delete the latest document. <br /> Please mark
        other document to latest and delete this one.
      </ConfirmDialog>
    </div>
  );
};
// export default SiteDocumentUpload;
export default withAITracking(reactPlugin, SiteDocumentUpload);
