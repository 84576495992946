import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: `InstrumentationKey=${process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY}`,
    extensions: [reactPlugin],
    extensionConfig:{
      [reactPlugin.identifier]: { history: browserHistory }
    },
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true    
  }
});
appInsights.loadAppInsights();

export { reactPlugin, appInsights };