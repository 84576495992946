import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { memo } from "react";
const ConfirmDialog = (props) => {
  // console.log(props);
  const {
    title,
    children,
    open,
    setOpen,
    onConfirm,
    isAlert,
    handleNo,
  } = props;
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            minWidth: "300px",
          },
        },
      }}
    >
      <div className="apply-custom-btn">
        <DialogTitle id="confirm-dialog">{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              if (handleNo !== undefined) handleNo();
            }}
          >
            {isAlert !== undefined && isAlert ? "Ok" : "No"}
          </Button>
          <Button
            variant="contained"
            sx={{
              display: isAlert !== undefined && isAlert ? "none" : "block",
            }}
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
export default memo(ConfirmDialog);
