import * as React from "react";
import { Box } from "@mui/material";

const Notes = (props) => {
  return (
    <div
      style={{
        // marginLeft: "8px",
        // padding: "8px",
        backgroundColor: "#EFEFED",
        borderLeft: "6px solid #15717D",
        // display: "inline",
        // color: "#2D2D69",
        borderRadius: "0.1rem",
        margin: "0 0 0 7px",
        padding: "0 5px 0 5px",
        display: "inline-block",
        float: "left",
      }}
    >
      {/* <p
        style={{
          // display: "inline",
        }}
      >
        <strong>Note: </strong>
        {props.note}
      </p> */}
      <div
        style={{
          margin: "0 auto",
          display: "inline-block",
          position: "relative",
          top: "-9px",
        }}
      >
        <strong>Note: </strong>
      </div>
      <div
        style={{
          display: "inline-block",
        }}
      >
        {props.note}
      </div>
    </div>
  );
};

export default Notes;
