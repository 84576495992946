import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  IconButton,
  Dialog,
  DialogContentText,
  Typography,
  DialogActions,
  DialogContent,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import * as rimaConstants from "../../../rimaconstants";
import { useHistory } from "react-router-dom";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Tooltips from "../../Common/Tooltips";
import ConfirmDialog from "../../Common/confirmDailog";
import CommonHeading from "../../Common/CommonHeading";
import DataView from "../../DataTable/DataView";
import ButtonGroup from "@mui/material/ButtonGroup";
import EditIcon from "@mui/icons-material/Edit";

const UnstructuredKewods = () => {
  const [documentTypes, setDocumentTypes] = useState([]);
  const [heading, setHeading] = useState([]);
  const [open, setOpen] = useState(false);
  const [documentType, setDocumentType] = useState("");
  const [documentTypeDesc, setDocumentTypeDesc] = useState("");
  const [keywords, setKeywords] = useState("");
  const [documentTypeID, setDocumentTypeID] = useState(0);
  const [loadingSend, setLoadingSend] = useState(false);

  const [featureName, setFeatureName] = useState("");
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef(null);

  useEffect(() => {
    setHeight(window.innerHeight - 210);
  }, []);

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  const handleChangeKeywords = (event) => {
    setKeywords(event.target.value);
  };

  const getDocumentTypes = () => {
    window.showLoader();
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/unstructureDataUserStories/list/`
      )
      .then((response) => {
        let head = {
          field: "action",
          headerName: "Action",
          width: 150,
          sortable: false,
          renderCell: (params) => {
            const onClickEdit = (
              e,
              id = params.row.doc_type_feature_mapping_id
            ) => {
              e.stopPropagation();
              let data = response.data.data.filter((obj) => {
                return (
                  parseInt(obj.doc_type_feature_mapping_id) === parseInt(id)
                );
              });
              setDocumentType(
                data[0] && data[0].audit_document_type
                  ? data[0].audit_document_type
                  : ""
              );
              setDocumentTypeDesc(
                data[0] && data[0].audit_document_type_description
                  ? data[0].audit_document_type_description
                  : ""
              );
              setKeywords(data[0] && data[0].keyword ? data[0].keyword : "");
              setDocumentTypeID(id);
              setOpen(true);
              setFeatureName(
                data[0] && data[0].feature_name ? data[0].feature_name : ""
              );
            };
            return (
              <React.Fragment>
                <ButtonGroup
                  size="small"
                  variant="contained"
                  aria-label="outlined primary button group"
                  // sx={{ width: "10px" }}
                >
                  {
                    <IconButton onClick={onClickEdit} title="Edit" size="small">
                      <EditIcon />
                    </IconButton>
                  }
                </ButtonGroup>
              </React.Fragment>
            );
          },
        };

        response.data.heading.push(head);
        setHeading(response.data.heading);
        setDocumentTypes(response.data.data);
        window.hideLoader();
      });
  };

  useEffect(() => {
    getDocumentTypes();
  }, []);

  const handleSubmit = () => {
    setLoadingSend(true);
    let req_data = {
      keywords: keywords,
    };

    axios
      .put(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/unstructureDataUserStories/update/${documentTypeID}`,
        req_data
      )
      .then((response) => {
        setLoadingSend(false);
        if (response.data.status === "success") {
          getDocumentTypes();
          window.showMessage("Keywords updated successfully", "success");
          setOpen(false);
        } else {
          window.showMessage(response.data["msg"], "warning");
        }
      })
      .catch(function (error) {
        setLoadingSend(false);
        console.log(error);
      });
  };

  return (
    <div ref={ref} style={{ minHeight: height }}>
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <DataView
            columns={heading}
            rows={documentTypes}
            menuName="Unstructured Keywords Settings"
          />
        </Grid>
      </Grid>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"sm"}
        fullScreen={false}
        onClose={handleClose}
        paperProps={{
          style: { borderRadius: 2 },
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box
              sx={{
                "& .MuiTextField-root": { m: 1, width: "100%" },
              }}
            >
              <div
                style={{
                  borderBottom: "1px solid #ddd",
                  padding: "10px",
                  width: "100%",
                }}
              >
                <CommonHeading heading="Modify Unstructured Keywords" />
              </div>
              <div style={{ width: "100%" }}>
                <table border="0" style={{ borderSpacing: "0 15px" }}>
                  <tr>
                    <td>
                      <strong>Document Type: </strong>
                    </td>
                    <td>{documentType}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Document Type Description: </strong>
                    </td>
                    <td>{documentTypeDesc}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Feature Name: </strong>
                    </td>
                    <td>{featureName}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Keyword: </strong>
                    </td>
                    <td>
                      <TextareaAutosize
                        rowsMin={12}
                        minRows={8}
                        style={{ width: "440px" }}
                        onChange={handleChangeKeywords}
                        // value={keywords}
                        defaultValue={keywords}
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{ mr: 1, textTransform: "none" }}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSubmit}
            endIcon={<SaveIcon />}
            loading={loadingSend}
            loadingPosition="end"
            variant="contained"
            sx={{ textTransform: "capitalize", mr: 4 }}
            // disabled={saveButton}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default UnstructuredKewods;
