import React from 'react';
import './dataList.css';
// import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';

export default class DataList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 5,
            pageStartIndex: 0,
            pageEndIndex: 5,
            isCheckAll: false
        }
        // this.filterRows(0, 5);
        this.addDefaultChecks();
    }

    rows = [];

    addDefaultChecks() {
        this.rows = this.props.rows.map((row) => { row['checked'] = false; return row; });
        this.forceUpdate();
    }

    filterRows(start, end) {
        this.rows = this.props.rows.filter((item, index) => { if (index >= start && index < end) return item; else return false; });
        // this.setState({ rows: this.props.rows.filter((item, index) =>{ if(index >= this.state.pageStartIndex && index < this.state.pageEndIndex) return item;}) });
        this.forceUpdate();
    }

    handleChangeRowsPerPage = (event) => {
        this.filterRows(0, parseInt(event.target.value, 10));
        this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
    };

    handleChangePage = (event, newPage) => {
        this.filterRows(newPage * this.state.rowsPerPage, (newPage * this.state.rowsPerPage) + this.state.rowsPerPage);
        this.setState({ page: newPage });

    };

    checkAllHandler = () => {
        this.rows = this.props.rows.map((row) => { row['checked'] = !this.state.isCheckAll; return row; });
        this.setState({ isCheckAll: !this.state.isCheckAll });
    }

    checkItemHandler = (index) => {
        this.rows[index].checked = !this.rows[index].checked;
        this.forceUpdate();
    }

    convertToCsv = (columns, rows) => {
        let mergedCols = [].concat(...columns.map((col) => { return col.headerName; }));
        let csvContent = mergedCols.join(',') + "\n";
        rows.map((row) => {
            columns.map((col, index) => {
                if ((index + 1) === columns.length) {
                    if(typeof row[col.field] === 'object'){
                        csvContent = csvContent + (row[col.field].value ? row[col.field].value : " ");
                    } else {
                        csvContent = csvContent + (row[col.field] ? row[col.field] : " ");
                    }
                } else {
                    if(typeof row[col.field] === 'object'){
                        csvContent = csvContent + (row[col.field].value ? row[col.field].value : " ") + ",";
                    } else {
                        csvContent = csvContent + (row[col.field] ? row[col.field] : " ") + ",";
                    }
                }
                return true;
            });
            csvContent = csvContent + "\n";
            return true;
        });
        csvContent = new File([csvContent], 'export.csv', { type: 'application/vnd.ms-excel' });

        return csvContent;
    }

    getCheckBox() {
        return (
            <div className='checkbox-container'>
                <input type='checkbox' className='custom-checkbox' onClick={this.checkAllHandler} />
                <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckBoxOutlineBlankIcon">
                    {!this.state.isCheckAll ? <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path> :
                        <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>}
                </svg>
            </div>
        );
    }

    donwloadCSV = () => {
        const url = window.URL.createObjectURL(this.convertToCsv(this.props.columns, this.rows));
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'List_of_products.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }

    render() {
        return (<div>
            <div className='theader'>
                <div className='tsubh'>
                    <div className='thtext'>List of Products</div>
                    <div><button variant="contained" className='export-btn' onClick={() => this.donwloadCSV()}><span className='svg-spn'>
                        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root fnt-sz" width='18px' height='18px' focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FileDownloadIcon"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path></svg>
                    </span>Export</button></div>
                </div>
            </div>
            <div style={{ width: '100%', overflowX: 'scroll', overflowY: 'hidden' }}>
                <table className='table-container'>
                    <thead className='th-container'>
                        <tr><th style={{ minWidth: 50 }}>{this.getCheckBox()}</th>{this.props.columns && this.props.columns.map((cl, index) => <th key={index} style={{ minWidth: cl.width + 'px' }} className='add-pipe'>{cl.headerName}</th>)}</tr>
                    </thead>
                    <tbody className='tbody-font'>
                        {this.rows?.length > 0 && this.rows.map((row, index) => {
                            return (<tr key={index} className='tr-row-tr'><td style={{ minWidth: 50 }} className='td-check '><Checkbox checked={row.checked} onChange={() => this.checkItemHandler(index)} /></td>
                                {
                                    this.props.columns && this.props.columns.map((cl, Key) => {
                                        if (row[cl.field] === null) {
                                            return <td key={Key} className='tr-row'></td>;
                                        }
                                        if (typeof row[cl.field] === 'string' || typeof row[cl.field] === 'number' || typeof row[cl.field] === 'boolean') {
                                            return (<td key={Key} className='tr-row'>{cl.renderCell ? cl.renderCell({ row }) : row[cl.field]}</td>)
                                        } else if (typeof row[cl.field] === 'object') {
                                            if (row[cl.field]?.value === null) {
                                                return <td key={Key} rowSpan={row[cl.field].rowSpan} className='tr-row'></td>
                                            }
                                            return <td key={Key} rowSpan={row[cl.field].rowSpan} className='tr-row'>{cl.renderCell ? cl.renderCell({ row }) : row[cl.field].value}</td>
                                        }

                                    })
                                }</tr>);
                        })}
                        {this.rows && !this.rows.length && <tr className='no-records'> <td colSpan={10} style={{ textAlign: 'center' }}>No Rows</td></tr>}
                    </tbody>
                </table>
            </div>
            {/* <div>
                <TablePagination
                    component="div"
                    count={this.props.rows.length}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    rowsPerPage={this.state.rowsPerPage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                /></div> */}
        </div>)
    }
}