import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import DeletedDocListViewer from "./deleteddoclistviewer";
import * as rimaConstants from "../../../rimaconstants";
import { memo } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import getHiddenDocList from "../../Common/hiddenDocs";
import Loader from "../../Loader/Loader";
import { toast } from "react-toastify";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../applicationInsights/AppInsights";

const DeletedDocList = () => {
  const [archivedDocs, setArchivedDocs] = useState([]);
  const [dataReady, setDataReady] = useState(false);
  const [loading, setLoading] = useState(false);

  function getDocs(postProcess = null) {
    setLoading(true);
    axios
      .get(`${rimaConstants.REACT_APP_API_URL}/api/v1/admin/deleteddocs`)
      .then(async (res) => {
        console.log("RESPONSE =====>", res);
        //Get hidden documents
        var hiddendocs = [
          ...new Set(
            (await getHiddenDocList()).map((x) => {
              return x.document_storage_url.split(".").slice(0, -1).join(".");
            })
          ),
        ];

        let doclist = res.data.map((x) => {
          return {
            document_id: x.document_id,
            document_title: x.document_title,
            title: x.document_title,
            type: x.is_external ? "External" : "Internal",
            regulation: x.regulation_tag,
            filepath: x.document_storage_url,
            file_name: x.document_name,
            created_by: x.created_by,
            created_date_time: window.getDate(
              x.uploaded_on ? x.uploaded_on : x.created_date_time,
              true
            ),
            archived_by: x.archived_by,
            archived_date: window.getDate(x.archived_date, true),
            deleted_by: x.deleted_by,
            deleted_date: window.getDate(x.deleted_date, true),
          };
        });

        //Filter hidden docs
        doclist = doclist.filter(
          (x) =>
            !hiddendocs.includes(
              x.filepath.split(".").slice(0, -1).join("."),
              0
            )
        );
        setArchivedDocs(doclist);
        setLoading(false);
        if (postProcess !== null) postProcess();
      })
      .catch((x) => {
        toast("Failed to load archived documents.");
        if (postProcess !== null) postProcess();
        setLoading(false);
      });
  }
  if (!dataReady) {
    setDataReady(true);
    getDocs();
  }
  return (
    <>
      {loading ? window.showLoader() : window.hideLoader()}
      <DeletedDocListViewer
        rows={archivedDocs}
        showDelete="true"
        showArchive="false"
        refreshFn={getDocs}
        search="true"
      ></DeletedDocListViewer>
    </>
  );
};

// export default ArchiveList;
export default withAITracking(reactPlugin, DeletedDocList);
