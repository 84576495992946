/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const CreateMenu = (props) => {
  //console.log("props.items ", props.items);
  return props?.items?.length > 0 ? (
    <ul className="dropdown" key={window.getUuidv4()}>
      {props.items.map((list) => {
        return (
          <li
            className={!list.access_allowed ? "notAllowed" : "allowed"}
            key={window.getUuidv4()}
            style={{ cursor: "pointer" }}
          >
            <a
              className={`childmenu ${
                !list.access_allowed ? "notAllowed" : "allowed"
              }${
                list.feature_name === props.breadcrums.childMenu
                  ? " active"
                  : ""
              }`}
              onClick={() =>
                props.handleClick(
                  list.parent_feature_name,
                  list.feature_name,
                  list.feature_id
                )
              }
            >
              {list.feature_name}
            </a>
          </li>
        );
      })}
    </ul>
  ) : (
    ""
  );
};

export default CreateMenu;
