import React, { useState, useContext } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";


import ASMReviewContent from "./ASMReviewContent";

// Contexts
import {
  ReviewDataContext,
  ReviewDataContextProvider,
} from "../Context/ReviewDataContext";

const ReviewPageContent = () => {
  const reviewFileUploadStatus = useContext(ReviewDataContext);

  
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const closeBtnHandleChange = () => {
    setExpanded("panel1");
  };


  return (
    <ReviewDataContextProvider>
      {reviewFileUploadStatus}
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <ASMReviewContent />
                {/* <Box sx={{ mt: "15px", textAlign: "right" }}>
                  <Button
                    variant="contained"
                    className="gsk-btn primary pill"
                    // disabled={previewBtnDisabled ? false : true}
                    onClick={closeBtnHandleChange}
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>
                </Box> */}
          </Grid>
        </Grid>
      </Box>
    </ReviewDataContextProvider>
  );
};

export default ReviewPageContent;
