import React, { useContext } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import './App.css';
// eslint-disable-next-line no-unused-vars
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated, MsalAuthenticationTemplate } from "@azure/msal-react";
import { MsalProvider } from "@azure/msal-react";
import { loginRequest } from "./authConfigmasl";
// eslint-disable-next-line no-unused-vars
import { InteractionType, InteractionStatus, EventType } from "@azure/msal-browser";
import { UserDataContextProvider, UserDataContext } from './components/Context/UserDataContext';
import { TagDataContextProvider } from './components/Context/TagDataContext';
import { RegulationDataContextProvider } from './components/Context/RegulationDataContext';

import Login from './components/Login/Login';
import SearchPage from './components/SearchComponent/SearchPage';
import Home from './components/HomePage/Home';
import EmailTemplateMain from "./components/admin/EmailTemplate/EmailTemplateMain";
import ARATemplateUpdationMain from "./components/admin/EmailTemplate/ARATemplateUpdationMain";
import ARARetentionMain from "./components/admin/EmailTemplate/ARARetentionMain";
//import admindashboard from "./components/admindashboard/admindashboard";
import adminmain from "./components/admin/index/adminmain";
import DocManagerMain from "./components/admin/documentmanagement/docmanagermain";
import InvalidUser from "./components/InvalidUser/InvalidUser";
import aramain from "./components/ara/main/aramain";
import AuditTrail from "./components/ara/main/AuditTrail";
import Manageaudit from "./components/admin/audit/manageaudit";
import CommonFunctions from "./components/commonFunctions/commonFunctions";
import FeedBack from "./components/ara/main/FeedBack";
import UserAccessManagement from "./components/admin/userAccessManagement/userAccessManagement";
import { LoaderProvider } from './components/ara/main/LoaderComponent';
import SiteDocumentUploadMain from "./components/ara/main/SiteDocumentUploadMain";
import { Provider as ReduxProvider } from 'react-redux';
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from './reducers';
import logger from 'redux-logger';
import { ThemeProvider } from '@mui/material/styles';
import { appTheme } from "./theme/theme";
import { CssBaseline } from "@mui/material";
import UnstructuredKewodsMain from "./components/admin/unstructuredKewods/UnstructuredKewodsMain";
import DocumentViewer from "./components/Pdfview/DocumentViewer";
import LoadDocument from "./components/ara/LoadDocument";
import PageNotFound from "./components/404/PageNotFound";
import SiteManagementMain from "./components/admin/SiteManagement/siteManagementMain";
import templateSectionManagementMain from "./components/admin/TemplateSectionManagement/templateSectionManagementMain";

const store = process.env.NODE_ENV === 'development' ? configureStore({ reducer: rootReducer, middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger) }) : configureStore({ reducer: rootReducer });

function AdminRoute({ component: Component, ...rest }) {
  // useAuth is some custom hook to get the current user's auth state
  // eslint-disable-next-line no-unused-vars
  const [adminUser, setAdminUser, user, setUser] = useContext(UserDataContext);
  // eslint-disable-next-line no-unused-vars
  const isAuthenticated = useIsAuthenticated();

  const isAuth = (adminUser === "1");

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
}

export default function App({ instance }) {
  // eslint-disable-next-line no-unused-vars
  const [adminUser, setAdminUser, user, setUser] = useContext(UserDataContext);
  // eslint-disable-next-line no-unused-vars
  const isAuthenticated = useIsAuthenticated();
  // action to perform on authentication

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
    <Router>
      <ReduxProvider store={store}>
        <MsalProvider instance={instance}>
          <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest} >
            <div>
              <UserDataContextProvider>
                <RegulationDataContextProvider>
                  <CommonFunctions />
                  <TagDataContextProvider>
                    <LoaderProvider>
                      <Switch>
                        <Route path="/" component={Home} exact />
                        <Route path="/login" component={Login} />
                        <Route path="/asm" component={SearchPage} />
                        <Route path="/error" component={InvalidUser} />
                        <Route path="/feedbackresponse/:audit_number" component={FeedBack} />
                        <Route path="/ara/audit-trails" component={AuditTrail} />
                        <Route path="/ara/upload-document" component={SiteDocumentUploadMain} />
                        <Route path="/ara/manage-audits" component={Manageaudit} />
                        <Route path="/ara/loaddocument/:page_number" component={LoadDocument} />
                        <Route path="/ara" component={aramain} />                        
                        <Route path="/documentviewer" component={DocumentViewer} />    
                        <AdminRoute path="/admin/dashboard" component={adminmain} />
                        <AdminRoute path="/admin/docs" component={DocManagerMain} />
                        <AdminRoute path="/admin/audit" component={Manageaudit} />
                        <AdminRoute path="/admin/mailtemplate" component={EmailTemplateMain} />
                        <AdminRoute path="/admin/template-updation" component={ARATemplateUpdationMain} />
                        <AdminRoute path="/admin/retention" component={ARARetentionMain} />
                        <AdminRoute path="/admin/unstructuredKewordsSettings" component={UnstructuredKewodsMain} />
                        {/* <AdminRoute path="/admin/sitemanagement" component={SiteManagementMain} />
                        <AdminRoute path="/admin/templatesectionmanagement" component={templateSectionManagementMain} /> */}
                        <AdminRoute path="/admin/user-management" component={UserAccessManagement} />

                        <Route path="*" component={PageNotFound} />
                        {/* <Route component={() => { return "Not found!" }} /> */}
                      </Switch>
                    </LoaderProvider>
                  </TagDataContextProvider>
                </RegulationDataContextProvider>
              </UserDataContextProvider>
            </div>
          </MsalAuthenticationTemplate>
          <UnauthenticatedTemplate>
            {/* <Link to = "/"></Link> */}
            <Home></Home>
          </UnauthenticatedTemplate>
        </MsalProvider>
        <ToastContainer className="toastyfycustom" autoClose={10000} hideProgressBar />
      </ReduxProvider>
    </Router>
    </ThemeProvider>
  );
}

