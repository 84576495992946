import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import * as rimaConstants from "./../../../rimaconstants";
// import { toast } from "react-toastify";
import { LoaderContext } from "./../../ara/main/LoaderComponent";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
// import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Checkbox from "@mui/material/Checkbox";

export default function EditData(props) {
  const [first_name, setFirstName] = useState(
    props.row?.first_name ? props.row?.first_name : ""
  );
  const [last_name, setLastName] = useState(
    props.row?.last_name ? props.row?.last_name : ""
  );
  const [role_name, setRoleName] = useState(
    props.row?.role_ids ? props.row?.role_ids : []
  );
  const [email_address, setEmail] = useState(
    props.row?.email_address ? props.row?.email_address : ""
  );
  const [userId, setUserId] = useState(
    props.row?.user_id ? props.row?.user_id : null
  );
  const [formValidation, setFormValidation] = useState("");
  // eslint-disable-next-line no-unused-vars
  const { show, setShow } = useContext(LoaderContext);
  const [userRole, setUserRole] = useState([]);
  const [isDisabledAdminRole, setIsDisabledAdminRole] = useState(false);

  useEffect(() => {
    axios
      .get(`${rimaConstants.REACT_APP_API_URL}/api/v1/user-management/userrole`)
      .then((res) => {
        if (res?.data?.data) {
          setUserRole(res.data.data);
        }
      });
  }, []);

  useEffect(() => {
    if (props.row) {
      setFirstName(props.row?.first_name);
      setLastName(props.row.last_name);
      setEmail(props.row.email_address);
      setRoleName(props.row?.role_ids);
      setUserId(props.row?.user_id);
    }
  }, [JSON.stringify(props.row)]);

  const updateUser = (e) => {
    setFormValidation("");
    let regex = new RegExp(`[a-zA-Z]*.x.[a-zA-Z]*@gsk.com`, "g");
    if (!first_name | !last_name | !role_name?.length) {
      setFormValidation("All fields are mandatory.");
    } else if (!regex.test(email_address)) {
      setFormValidation("Email is not valid.");
    } else {
      setShow(true);
      const payload = {
        first_name: first_name,
        last_name: last_name,
        role_name: role_name,
        email_address: email_address,
      };
      if (userId) {
        axios
          .put(
            `${rimaConstants.REACT_APP_API_URL}/api/v1/user-management/` +
              props.row.user_id,
            payload
          )
          .then((res) => {
            setShow(false);
            if (res?.data?.status === 200) {
              window.showMessage("User updated.", "success");
              props.onSubmit();
            }
            if (res?.data?.status === 412) {
              window.showMessage(res.data.message, "error");
            } else {
              window.showMessage("Something went wrong.", "error");
            }
          });
      } else {
        axios
          .post(
            `${rimaConstants.REACT_APP_API_URL}/api/v1/user-management`,
            payload
          )
          .then((res) => {
            setShow(false);
            if (res?.data?.status === 200) {
              window.showMessage("User created.", "success");
              props.onSubmit();
            }
            if (res?.data?.status === 402) {
              window.showMessage("User already exist.", "warning");
            }
          });
      }
    }
  };

  const roleSelectionHandler = (event) => {
    let {
      target: { value },
    } = event;
    value = typeof value === "string" ? value.split(",") : value;
    const auditorId = getRoleObjectByName("Auditor").role_id;
    const adiminId = getRoleObjectByName("Admin").role_id;
    const stakeholderId = getRoleObjectByName("Stakeholder").role_id;
    const auditeeId = getRoleObjectByName("Auditee").role_id;
    if (
      JSON.stringify(role_name) === "[2,5]" &&
      value[value.length - 1] === adiminId
    ) {
      setRoleName([]);
      return;
    }
    if (value[value.length - 1] === adiminId) {
      setRoleName([adiminId, auditorId]);
      return;
    }
    if (
      value[value.length - 1] === stakeholderId ||
      value[value.length - 1] === auditeeId
    ) {
      value = value.filter((role) => role !== adiminId);
      setIsDisabledAdminRole(true);
    } else {
      setIsDisabledAdminRole(false);
    }
    setRoleName([value[value.length - 1]]);
  };

  const getRoleObjectByName = (name) => {
    return userRole.find((role) => role.role_name === name);
  };

  return (
    <div className="edit-container">
      <div style={{ display: "flex", width: "100%" }}>
        <Typography variant="h5" gutterBottom component="div">
          {userId ? "Edit User" : "Add User"}
        </Typography>
      </div>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "51ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <div style={{ display: "flex" }}>
          <TextField
            id="first_name"
            label="First Name"
            multiline
            // inputType="text"
            name="first_name"
            maxRows={4}
            onChange={(e) => setFirstName(e.target.value)}
            value={first_name}
          />
          <TextField
            id="last_name"
            label="Last Name"
            multiline
            // inputType="text"
            name="last_name"
            maxRows={4}
            onChange={(e) => setLastName(e.target.value)}
            value={last_name}
          />
        </div>
        <div style={{ display: "flex" }}>
          <TextField
            id="email_address"
            label="Email"
            multiline
            // inputType="text"
            name="email_address"
            maxRows={4}
            onChange={(e) => setEmail(e.target.value)}
            value={email_address}
            disabled={userId}
          />
          {userRole.length ? (
            <FormControl required sx={{ m: 1, width: "51ch" }}>
              <InputLabel id="demo-simple-select-required-label">
                Role Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="role_name"
                value={role_name}
                label="Role Type *"
                onChange={roleSelectionHandler}
                sx={{ height: "55px" }}
                name="role_name"
                multiple
              >
                <MenuItem key={window.getUuidv4()} value="">--Select--</MenuItem>
                {userRole.map((role, index) => {
                  return (
                    <MenuItem 
                      value={role.role_id}
                      key={index}
                      disabled={
                        role.role_id === getRoleObjectByName("Admin").role_id &&
                        isDisabledAdminRole
                      }
                    >
                      <Checkbox
                        checked={role_name.indexOf(role.role_id) > -1}
                      />
                      {role.role_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : (
            <FormControl required sx={{ m: 1, width: "51ch" }}>
              <Skeleton
                variant="rectangular"
                sx={{ my: 0.5, mx: 0.2, borderRadius: 2 }}
                height={50}
                animation="wave"
              />
            </FormControl>
          )}
        </div>
        <div className="errMsg">
          <span style={{ color: "red" }}>{formValidation}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Box sx={{ "& > button": { m: 1 } }}>
            <Button
              variant="contained"
              sx={{ textTransform: "capitalize" }}
              onClick={props.onCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => updateUser()}
              startIcon={<SaveIcon />}
              sx={{ textTransform: "capitalize" }}
            >
              Save
            </Button>
          </Box>
        </div>
      </Box>
    </div>
  );
}
