import React from "react";
import { TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

export default function SearchableDropdown(props) {
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => (option.label ? option.label : ""),
  });

  return (
    <Autocomplete
      getOptionLabel={(option) => (option.label ? option.label : "")}
      filterOptions={filterOptions}
      value={props.value}
      options={props.options}
      // loading={props.loading}
      loading={typeof props.loading === "boolean" ? props.loading : false}
      componentsProps={{
        paper: {
          sx: {
            width: props.paperWidth ? props.paperWidth : "100%",
            whiteSpace: "nowrap",
            // fontSize: "0.8rem",
            // fontWeight: 400,
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          // variant="standard"
          // size="small"
          variant={props.variant ? props.variant : "standard"}
          size={props.size ? props.size : "small"}
          {...params}
          label={props.label}
          placeholder={props.placeholder}
          // loading={props.loading}
          loading={typeof props.loading === "boolean" ? props.loading : false}
          // loadingText={props.loadingText ? props.loadingText : "Loading..."}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {props.loading ? (
                  <CircularProgress sx={{ position: "absolute", right: "25px" }} size={15} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          sx={{ background: props.background ? "" : "#fff" }}
        />
      )}
      onChange={(event, newValue) => {
        if (newValue) {
          props.setResetOptions(newValue);
          props.handleChange(newValue.id);
        }
        if (!newValue) {
          props.setResetOptions(null);
          props.handleChange("");
          //props.setInputValue("");
        }
      }}
      //inputValue={props.inputValue}
      // onInputChange={(event, newInputValue) => {
      //   //if (newInputValue) props.setInputValue(newInputValue);
      // }}
      sx={props.customStyle ? props.customStyle : { width: "200px" }}
    />
  );
}
