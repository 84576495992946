import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import "./Home.css";
import Header from "./Header";
import Footer from "../Footer/Footer";
// import rCircle from '../../assets/images/r1.png';
import rCircle from "../../assets/images/r2.png";
import asmIcon from "../../assets/images/u30.svg";
import araIcon from "../../assets/images/cirara.png";
import * as rimaConstants from "../../rimaconstants";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../applicationInsights/AppInsights';

function Home(props) {
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef(null);
  React.useEffect(() => {
    setHeight(window.innerHeight - 170);
  }, []);
  return (
    <div ref={ref}>
      <Header />

      <Container component="main" maxWidth="xs" sx={{ minHeight: height }}>
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minHeight: "455px",
          }}
        >
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <FormControl style={{ position: "relative" }} variant="standard">
              <img className="rCircle" src={rCircle} alt="REIMAGINE Audit" />

              <Box className="bBx">
                <Link to="/asm">
                  {" "}
                  <img className="asmIcon" src={asmIcon} alt="" />{" "}
                </Link>
                <span className="asmP">Audit Standard Manager</span>
              </Box>

              <Box className="bBx_ara">
                <span className="araP">
                  Audit Preparation &amp; Risk Analysis
                </span>
                <Link
                  to={
                    rimaConstants.REACT_APP_ONLY_ASM &&
                    rimaConstants.REACT_APP_ONLY_ASM === "YES"
                      ? "/"
                      : "/ara"
                  }
                >
                  {" "}
                  <img className="araIcon" src={araIcon} alt="" />{" "}
                </Link>
              </Box>
            </FormControl>
          </Box>
        </Box>
      </Container>

      <Footer />
    </div>
  );
}

// export default Home;
export default withAITracking(reactPlugin, Home);
