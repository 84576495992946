import React, { useEffect, useState } from 'react';
import './LoaderComponent.css';
import { Box } from '@mui/material';
import '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

export const LoaderContext = React.createContext([false, () => { }]);
export const LoaderProvider = (props) => {
    const [show, setShow] = React.useState(false);
    return (
        <LoaderContext.Provider value={{ show, setShow }}>
            {props.children}
            <LoaderComponent />
        </LoaderContext.Provider>
    );
}

const LoaderComponent = () => {
    const { show } = React.useContext(LoaderContext);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if (show !== showLoader) {
            setShowLoader(show);
        }
    }, [show]);

    if (!showLoader) {
        return null;
    }

    return (<Box height={'100%'} width={'100%'} sx={{
        zIndex: 'modal', position: 'absolute',
        top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'
    }} bgcolor={'rgba(0,0,0,0.1)'}>
        <Box bgcolor={'rgba(0,0,0,0)'}>
            <CircularProgress />
        </Box>
    </Box>);
}