import React, { useState, useEffect, useContext } from "react";
import "./SearchPage.css";
import { decode } from "base64-url";
import axios from "axios";
import { Link, Route } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from "@mui/material/IconButton";
import ToggleButton from "@mui/material/ToggleButton";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import Header from "../Header/Header";
import Header2 from "../Header2/Header2";
import SearchBar from "../SearchBar/SearchBar";
import AdvanceSearch from "../SearchBar/AdvanceSearch";
import DataTable from "../DataTable/DataTable";
import Footer from "../Footer/Footer";
import Mark from "mark.js";
import ReactTooltip from "react-tooltip";
import HelpIcon from "@mui/icons-material/Help";
import HelpOutlineTwoToneIcon from "@mui/icons-material/HelpOutlineTwoTone";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import * as BlobServiceClient from "@azure/storage-blob";
import MultipleSelect from "../MultipleSelects/MultipleSelects";
import AssemblePageContent from "../Assembly/AssemblePageContent";
import ReviewPageContent from "../ReviewPageContent/ReviewPageContent";
import Classify from "./../Classify/Classify";
import Classifydoc from "./../Classify/Classifydoc";
import ReactHtmlParser from "react-html-parser";
import noData from "../../assets/images/u96.png";
import { TagDataContextProvider } from "../Context/TagDataContext";
import { RegulationDataContextProvider } from "../Context/RegulationDataContext";
import { v4 as uuidv4 } from "uuid";
import * as rimaConstants from "../../rimaconstants";
import getHiddenDocList from "../Common/hiddenDocs";
import { toast } from "react-toastify";
import { UserDataContext } from "../Context/UserDataContext";
import Tooltips from "../Common/Tooltips";
import moment from "moment";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../applicationInsights/AppInsights';
import NotesSearchPage from "../Common/NotesSearchPage";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function SearchPage() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [finalData, setFinalData] = useState([]);

  const [final, setFinal] = useState("");
  const [top, setTop] = useState(1000);
  const [skip, setSkip] = useState(0);
  const [isShowing, setIsShowing] = useState(true);
  const [docSource, setDocSource] = useState([]);
  const [docType, setDocType] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [locationTag, setLocationTag] = useState([]);
  const [productTag, setProductTag] = useState([]);
  const [serviceTag, setServiceTag] = useState([]);
  const [agreementTag, setAgreementTag] = useState([]);
  const [qmsTag, setQmsTag] = useState([]);
  const [regulationTag, setRegulationTag] = useState([]);
  const [auditeeTag, setAuditeeTag] = useState([]);
  const [doseTag, setDoseTag] = useState([]);
  const [applicableTag, setApplicableTag] = useState([]);
  const [hideDuplicates, setHideDuplicates] = useState(false);
  const tabnames = ["Search", "Assembly", "Translator", "Review"];
  const [
    adminUser,
    setAdminUser,
    user,
    setUser,
    userSASToken,
    setUserSASToken,
  ] = useContext(UserDataContext);

  const internalExternalReset = () => {
    setDocSource([]);
  };

  const reSet = () => {
    setHideDuplicates(false);
    searchAsmresult(null);
    setDocSource([]);
    setLocationTag([]);
    setProductTag([]);
    setServiceTag([]);
    setAgreementTag([]);
    setQmsTag([]);
    setRegulationTag([]);
    setAuditeeTag([]);
    setDoseTag([]);
    setApplicableTag([]);
    setDocType(-1);
  };

  function toggle() {
    setIsShowing(!isShowing);
    if (!isShowing) {
      searchAsmresult(null);
    }
    setDocSource([]);
    setDocType(-1);
  }

  const searchInputHandler = (e) => {
    setFinal(e.target.value);

    // var markInstance = new Mark(document.querySelector("#search-node"));

    // markInstance.unmark({
    //   done:() =>{
    //     markInstance.mark(final);
    //   }
    // })
  };

  const searchInputHandlerDocSource = (e) => {
    setDocSource(e.target.value);
  };

  const searchInputHandlerDocType = (e) => {
    setDocType(e.target.value);
  };
  const searchInputHandlerLocationTag = (e) => {
    setLocationTag(e.target.value);
  };
  const searchInputHandlerProductTag = (e) => {
    setProductTag(e.target.value);
  };
  const searchInputHandlerServiceTag = (e) => {
    setServiceTag(e.target.value);
  };
  const searchInputHandlerAgreementTag = (e) => {
    setAgreementTag(e.target.value);
  };
  const searchInputHandlerQmsTag = (e) => {
    setQmsTag(e.target.value);
  };
  const searchInputHandlerRegulationTag = (e) => {
    setRegulationTag(e.target.value);
  };
  const searchInputHandlerAuditeeTag = (e) => {
    setAuditeeTag(e.target.value);
  };
  const searchInputHandlerDoseTag = (e) => {
    setDoseTag(e.target.value);
  };
  const searchInputHandlerApplicableTag = (e) => {
    setApplicableTag(e.target.value);
  };

  // const finalSearchString = () => setFinal()

  useEffect((e) => {
    searchAsmresult(e);
  }, []);

  const mkQry = (field, values) => {
    //auditee_type_tag/any (t: search.in(t,'External','|'))
    return `${field}/any (t: search.in(t, '${values.join("|")}','|'))`;
  };

  const mkQry2 = (field, values) => {
    //auditee_type_tag/any (t: search.in(t,'External','|'))
    return `search.in(${field}, '${values.join("|")}','|')`;
  };
  const searchAsmresult = (e) => {
    if (e != null) e.preventDefault();

    if (
      final === undefined ||
      final === null ||
      final.trim() === "" ||
      e === null
    )
      return;

    window.showLoader();

    var qryParams = {
      speller: "lexicon",
      queryLanguage: "en-us",
      search: final,
      $count: "true",
      "api-version": "2021-04-30-Preview",
      highlight:
        "page_content,document_name,document_source_description,document_title",
      $top: top,
      $skip: skip,
      highlightPreTag: "<strong>",
      highlightPostTag: "</strong>",
    };
    var filterStr = "";
    var filters = [];
    // if (docSource.length != 0)
    // filters = `search.in(document_source, '${docSource.toString()}', '|')`
    //$filter=search.in(document_source , 'USFDA,ABC',',') and is_external eq 1
    if (docSource.length != 0)
      filters.push(mkQry2("document_source", docSource));
    if (locationTag.length != 0)
      filters.push(mkQry("market_location_tag", locationTag));
    if (productTag.length != 0)
      filters.push(mkQry("product_type_tag", productTag));
    if (serviceTag.length != 0) filters.push(mkQry("service_tag", serviceTag));
    if (agreementTag.length != 0)
      filters.push(mkQry("agreement_tag", agreementTag));
    if (qmsTag.length != 0) filters.push(mkQry("qms_policy_tag", qmsTag));
    if (regulationTag.length != 0)
      filters.push(mkQry("regulation_tag", regulationTag));
    if (auditeeTag.length != 0)
      filters.push(mkQry("auditee_type_tag", auditeeTag));
    if (doseTag.length != 0) filters.push(mkQry("dose_form_tag", doseTag));
    if (applicableTag.length != 0)
      filters.push(mkQry("applicable_tag", applicableTag));

    filterStr = "";
    for (var i = 0; i < filters.length; i++) {
      if (i == 0) filterStr = filters[i];
      else filterStr = filterStr + " and " + filters[i];
    }

    // if (filterStr != '' && docType != -1)
    //   filterStr = filterStr + ' and '

    if (docType != -1)
      filterStr =
        filterStr +
        (filterStr.trim() !== "" ? "and" : "") +
        ` is_external eq ${docType}`;

    if (filterStr != "") qryParams["$filter"] = filterStr;

    axios
      .post(`${rimaConstants.REACT_APP_API_URL}/api/v1/docprops/search`, {
        queryString: qryParams,
      })
      .then(async (res) => {
        let resJSONData = res;
        if (!resJSONData) {
          toast("No search results retrieved.");
          return;
        }
        const extractData = extractValues(
          resJSONData?.data?.value,
          "page_content",
          "document_name",
          "document_storage_url",
          "page_number",
          "document_source",
          "document_source_description",
          "is_external",
          "document_title"
        );

        //Get hidden documents
        var hiddendoclist = await getHiddenDocList();
        var hiddendocs = [
          ...new Set(
            hiddendoclist.map((x) => {
              return x.document_storage_url.split(".")[0];
            })
          ),
        ];

        var searchResult = [];
        if (hideDuplicates) {
          //Remove duplicate from search result
          searchResult = extractData.filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  t.filepath === value.filepath &&
                  !hiddendocs.includes(t.filepath.split(".")[0], 0)
              )
          );
        } else {
          searchResult = extractData.filter(
            (value, index, self) =>
              !hiddendocs.includes(value.filepath.split(".")[0], 0)
          );
        }

        searchResult = searchResult.map((x) => {
          delete x["filepath"];
          return x;
        });

        setFinalData(searchResult);
        setLoading(false);
        window.hideLoader();
      });
  };

  function extractValues(
    arr,
    prop,
    prop2,
    prop3,
    prop4,
    prop5,
    prop6,
    prop7,
    prop8
  ) {
    let extractedValue = arr.map((item) => {
      const File_Path = item[prop3];
      const sourceStore = userSASToken.storageUri;

      const sasKey = "?" + userSASToken.storageAccessToken;
      const secureInternal = item?.document_download_url?.includes("veevavault")
        ? true
        : false;

      //TODO: Remove Quickfix on filepath for .docx files.
      //
      var filePath = "raw/" + File_Path;//.replace(".docx", ".pdf"); // /dbfs/mnt/testpdf1/ASM/eSOP - Vaccines/SOP000000000000000900000274503000.pdf 
      if (["docx", "doc"].includes( window.getExt(filePath).toLowerCase())) {
        filePath = window.changeExt(filePath, "pdf");
      }

      var str2 = sourceStore + encodeURI(filePath) + sasKey;
      var referrerid = uuidv4();

      var content = Array.isArray(item["@search.highlights"][prop])
        ? item["@search.highlights"][prop].join("")
        : item["@search.highlights"][prop];
      var obj = {
        //metadata_storage_name: <Link target="_blank" to={{pathname: "/pdf", state: {urlpath: str2}}}>{item[prop2]}</Link>,
        filepath: item[prop3],
        document_title: (
          <p>
            {item[prop8]}, <strong>Page No.</strong> {item[prop4]} &nbsp;{" "}
            {item[prop5]} &nbsp; {item[prop7] === 1 ? "External" : "Internal"}
          </p>
        ),
        file_name: (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              let a = document.createElement("a");
              a.target = "_blank";
              if (secureInternal) {
                a.href = `${item.document_download_url}?anQS=page${item[prop4]}&annotate=false`;
              } else {
                a.href = `${str2}&ts=${moment().utc().unix()}#page=${
                  item[prop4]
                }`;
                // a.href = `/documentviewer/${window.base64_encode(
                //   `${str2}&ts=${moment().utc().unix()}#page=${item[prop4]}`
                // )}`;
              }
              a.click();
            }}
          >
            {item[prop2]}
          </a>
        ),

        page_content: ReactHtmlParser(content === undefined ? "" : content),

        // page_content: Array.isArray(item['@search.highlights'][prop]) ? item['@search.highlights'][prop].join('') : item['@search.highlights'][prop]
      };

      return obj;
    });
    return extractedValue;
  }

  return (
    <div className="container">
      <RegulationDataContextProvider>
        <TagDataContextProvider>
          <Header />
          <Header2 location={tabnames[value]} />

          <Box
            style={{
              marginLeft: "10px",
              borderTop: "1px solid #d7d7d7",
              borderRadius: "7px",
            }}
            sx={{ flexGrow: 1, display: "flex" }}
          >
            {adminUser === "0" && (
              <>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  TabIndicatorProps={{
                    style: {
                      display: "none",
                    },
                  }}
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs"
                  sx={{
                    borderRight: 1,
                    borderColor: "divider",
                    "& .MuiButtonBase-root": {
                      color: "black",
                      background: "#ddd",
                      marginBottom: "15px",
                    },
                    "& .Mui-selected": {
                      background: "#fff",
                      marginBottom: "15px",
                      borderRight: "none",
                    },
                  }}
                >
                  <Tab className="mTab" label="Search" {...a11yProps(0)} />

                  <Tab className="mTab" label="Translator" {...a11yProps(1)} />
                  {/* <Tab label="Alerts" {...a11yProps(3)} /> */}
                  <Tab className="mTab" label="Review" {...a11yProps(2)} />
                </Tabs>
                <Box
                  style={{ paddingTop: "5px" }}
                  sx={{ width: 1, bgcolor: "background.paper" }}
                >
                  <TabPanel
                    style={{ minHeight: "430px" }}
                    value={value}
                    index={0}
                  >
                    <div className="wrapper">
                      <div className="searchBar">
                        <ReactTooltip />
                        <form
                          className="searchFoorm"
                          onSubmit={searchAsmresult}
                        >
                          <SearchBar
                            final={final}
                            searchInputHandler={searchInputHandler}
                          />
                          <IconButton
                            style={{
                              position: "absolute",
                              right: "213px",
                              color: "rgb(22, 155, 213)",
                            }}
                            onClick={searchAsmresult}
                            disabled={final === ""}
                          >
                            <SearchIcon />
                            {/* <Typography variant="caption"  sx={{ display: 'inline-flex', color:"#15717d" }}><sup>*{hideDuplicates?"":""}</sup></Typography> */}
                          </IconButton>

                          {/* <InfoOutlinedIcon
                      fontSize="tiny"
                      data-html={true}
                      data-tip='<h3>Sample queries:</h3> <hr/> <li><b>water</b> -> <i>Word search</i></li> <li><b>"Water for injection"</b> -> <i>Specific text</i></li> <li><b>water + injection</b> -> <i>AND operation</i></li> <li><b>water | injection</b> -> <i>OR operation</i></li> <li><b>"data management" + "vaccine"</b> -> <i>Complex search</i></li>'
                    >
                      
                    </InfoOutlinedIcon> */}
                          <IconButton>
                            <Tooltips
                              html={true}
                              iconColor="#2D2D69"
                              placement="top"
                              title='<h3>Sample queries:</h3> <hr/> <li><b>water</b> -> <i>Word search</i></li> <li><b>"Water for injection"</b> -> <i>Specific text</i></li> <li><b>water + injection</b> -> <i>AND operation</i></li> <li><b>water | injection</b> -> <i>OR operation</i></li> <li><b>"data management" + "vaccine"</b> -> <i>Complex search</i></li>'
                            />
                          </IconButton>

                          <Button
                            variant="contained"
                            onClick={toggle}
                            className="gsk-btn primary pill"
                          >
                            Advanced Search
                          </Button>
                        </form>
                        <AdvanceSearch
                          finalData={finalData}
                          final={final}
                          docSource={docSource}
                          locationTag={locationTag}
                          productTag={productTag}
                          serviceTag={serviceTag}
                          agreementTag={agreementTag}
                          qmsTag={qmsTag}
                          regulationTag={regulationTag}
                          auditeeTag={auditeeTag}
                          doseTag={doseTag}
                          applicableTag={applicableTag}
                          docType={docType}
                          searchInputHandlerDocSource={
                            searchInputHandlerDocSource
                          }
                          searchInputHandlerLocationTag={
                            searchInputHandlerLocationTag
                          }
                          searchInputHandlerProductTag={
                            searchInputHandlerProductTag
                          }
                          searchInputHandlerServiceTag={
                            searchInputHandlerServiceTag
                          }
                          searchInputHandlerAgreementTag={
                            searchInputHandlerAgreementTag
                          }
                          searchInputHandlerQmsTag={searchInputHandlerQmsTag}
                          searchInputHandlerRegulationTag={
                            searchInputHandlerRegulationTag
                          }
                          searchInputHandlerAuditeeTag={
                            searchInputHandlerAuditeeTag
                          }
                          searchInputHandlerDoseTag={searchInputHandlerDoseTag}
                          searchInputHandlerApplicableTag={
                            searchInputHandlerApplicableTag
                          }
                          searchInputHandlerDocType={searchInputHandlerDocType}
                          searchAsmresult={searchAsmresult}
                          searchInputHandler={searchInputHandler}
                          isShowing={isShowing}
                          toggle={toggle}
                          reSet={reSet}
                          hideDuplicates={hideDuplicates}
                          setHideDuplicates={setHideDuplicates}
                          internalExternalReset={internalExternalReset}
                        />
                      </div>
                      {/* <AdvanceSearch isShowing={isShowing} toggle={toggle} /> */}
                      {
                        <div
                          className={`nodata ${
                            loading === true ? "disactive" : "active"
                          }`}
                        >
                          {(finalData === undefined || finalData == 0) && (
                            <p className="noDatap">
                              <img className="nodataIcon" src={noData} /> No
                              Data found
                            </p>
                          )}
                        </div>
                      }
                      <DataTable
                        final={final}
                        data={finalData}
                        setTop={setTop}
                        skip={setSkip}
                      />
                    </div>
                  </TabPanel>

                  <TabPanel value={value} index={1}>
                    <Typography component="h5" variant="h5">
                      <Classify />
                    </Typography>
                    {/* <Classifydoc /> */}
                  </TabPanel>

                  <TabPanel value={value} index={2}>
                    <ReviewPageContent />
                  </TabPanel>
                  {value === 0 && (<div style={{marginTop:"20px"}}><NotesSearchPage note="ASM is a non-regulated tool. User is responsible to ensure the most current, original source is referenced from results generated with this tool." /></div>)}
                </Box>
              </>
            )}

            {adminUser === "1" && (
              <>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  TabIndicatorProps={{
                    style: {
                      display: "none",
                    },
                  }}
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs"
                  sx={{
                    borderRight: 1,
                    borderColor: "divider",
                    "& .MuiButtonBase-root": {
                      color: "black",
                      background: "#ddd",
                      marginBottom: "15px",
                    },
                    "& .Mui-selected": {
                      background: "#fff",
                      marginBottom: "15px",
                      borderRight: "none",
                    },
                  }}
                >
                  <Tab className="mTab" label="Search" {...a11yProps(0)} />
                  <Tab className="mTab" label="Assembly" {...a11yProps(1)} />
                  <Tab className="mTab" label="Translator" {...a11yProps(2)} />
                  {/* <Tab label="Alerts" {...a11yProps(3)} /> */}
                  <Tab className="mTab" label="Review" {...a11yProps(3)} />
                </Tabs>
                <Box
                  style={{ paddingTop: "5px" }}
                  sx={{ width: 1, bgcolor: "background.paper" }}
                >
                  <TabPanel
                    style={{ minHeight: "430px" }}
                    value={value}
                    index={0}
                  >
                    <div className="wrapper">
                      <div className="searchBar">
                        <ReactTooltip />
                        <form
                          className="searchFoorm"
                          onSubmit={searchAsmresult}
                        >
                          <SearchBar
                            final={final}
                            searchInputHandler={searchInputHandler}
                          />
                          <IconButton
                            style={{
                              position: "absolute",
                              right: "213px",
                              color: "rgb(22, 155, 213)",
                            }}
                            onClick={searchAsmresult}
                            disabled={final === ""}
                          >
                            <SearchIcon />
                            {/* <Typography variant="caption"  sx={{ display: 'inline-flex', color:"#15717d" }}><sup>*{hideDuplicates?"":""}</sup></Typography> */}
                          </IconButton>

                          {/* <InfoOutlinedIcon
                      fontSize="tiny"
                      data-html={true}
                      data-tip='<h3>Sample queries:</h3> <hr/> <li><b>water</b> -> <i>Word search</i></li> <li><b>"Water for injection"</b> -> <i>Specific text</i></li> <li><b>water + injection</b> -> <i>AND operation</i></li> <li><b>water | injection</b> -> <i>OR operation</i></li> <li><b>"data management" + "vaccine"</b> -> <i>Complex search</i></li>'
                    >
                      
                    </InfoOutlinedIcon> */}
                          <IconButton>
                            <Tooltips
                              html={true}
                              iconColor="#2D2D69"
                              placement="top"
                              title='<h3>Sample queries:</h3> <hr/> <li><b>water</b> -> <i>Word search</i></li> <li><b>"Water for injection"</b> -> <i>Specific text</i></li> <li><b>water + injection</b> -> <i>AND operation</i></li> <li><b>water | injection</b> -> <i>OR operation</i></li> <li><b>"data management" + "vaccine"</b> -> <i>Complex search</i></li>'
                            />
                          </IconButton>

                          <Button
                            variant="contained"
                            onClick={toggle}
                            className="gsk-btn primary pill"
                          >
                            Advanced Search
                          </Button>
                        </form>
                        <AdvanceSearch
                          finalData={finalData}
                          final={final}
                          docSource={docSource}
                          locationTag={locationTag}
                          productTag={productTag}
                          serviceTag={serviceTag}
                          agreementTag={agreementTag}
                          qmsTag={qmsTag}
                          regulationTag={regulationTag}
                          auditeeTag={auditeeTag}
                          doseTag={doseTag}
                          applicableTag={applicableTag}
                          docType={docType}
                          searchInputHandlerDocSource={
                            searchInputHandlerDocSource
                          }
                          searchInputHandlerLocationTag={
                            searchInputHandlerLocationTag
                          }
                          searchInputHandlerProductTag={
                            searchInputHandlerProductTag
                          }
                          searchInputHandlerServiceTag={
                            searchInputHandlerServiceTag
                          }
                          searchInputHandlerAgreementTag={
                            searchInputHandlerAgreementTag
                          }
                          searchInputHandlerQmsTag={searchInputHandlerQmsTag}
                          searchInputHandlerRegulationTag={
                            searchInputHandlerRegulationTag
                          }
                          searchInputHandlerAuditeeTag={
                            searchInputHandlerAuditeeTag
                          }
                          searchInputHandlerDoseTag={searchInputHandlerDoseTag}
                          searchInputHandlerApplicableTag={
                            searchInputHandlerApplicableTag
                          }
                          searchInputHandlerDocType={searchInputHandlerDocType}
                          searchAsmresult={searchAsmresult}
                          searchInputHandler={searchInputHandler}
                          isShowing={isShowing}
                          toggle={toggle}
                          reSet={reSet}
                          hideDuplicates={hideDuplicates}
                          setHideDuplicates={setHideDuplicates}
                          internalExternalReset={internalExternalReset}
                        />
                      </div>
                      {/* <AdvanceSearch isShowing={isShowing} toggle={toggle} /> */}
                      {
                        <div
                          className={`nodata ${
                            loading === true ? "disactive" : "active"
                          }`}
                        >
                          {(finalData === undefined || finalData == 0) && (
                            <p className="noDatap">
                              <img className="nodataIcon" src={noData} /> No
                              Data found
                            </p>
                          )}
                        </div>
                      }
                      <DataTable
                        final={final}
                        data={finalData}
                        setTop={setTop}
                        skip={setSkip}
                    
                      />
                    </div>
                    
                  </TabPanel>

                  <TabPanel value={value} index={1}>
                    <AssemblePageContent />
                    {/* <Typography component="h5" variant="h5">Assembly</Typography>
            <MultipleSelect/> */}
                  </TabPanel>

                  <TabPanel value={value} index={2}>
                    <Typography component="h5" variant="h5">
                      <Classify />
                    </Typography>
                    {/* <Classifydoc /> */}
                  </TabPanel>

                  <TabPanel value={value} index={3}>
                    <ReviewPageContent />
                  </TabPanel>
                  {value === 0 && (<div style={{marginTop:"20px"}}><NotesSearchPage note="ASM is a non-regulated tool. User is responsible to ensure the most current, original source is referenced from results generated with this tool." /></div>)}
                </Box>
              </>
            )}
          </Box>
          <Footer />
        </TagDataContextProvider>
      </RegulationDataContextProvider>
    </div>
  );
}
// export default SearchPage;
export default withAITracking(reactPlugin, SearchPage);

