import * as React from "react";
import { Box } from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

const NotesSearchPage = (props) => {
  return (
    <div
      style={{
        marginLeft: "8px",
        padding: "8px",
        backgroundColor: "#EFEFED",
        borderLeft: "6px solid #15717D",
        display: "inline",
        // color: "#2D2D69",
        borderRadius: "0.1rem",
      }}
    >
      <p
        style={{
          display: "inline",
        }}
      >
        
          <ReportProblemIcon sx={{ color: "orange", marginBottom:"-3px" }} />{" "}
        
        {props.note}
      </p>
    </div>
  );
};

export default NotesSearchPage;
