import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import { BreadcrumsContext } from "../context/breadcrumsContext";
// import EditLogo from "./../../../assets/images/edit.png";
import TextEditor from "./../../TextEditor/textArea";
// import TextEditor from "./../../TextEditor/TextEditorReactQuill";
import { IconButton } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";
import axios from "axios";
import * as rimaConstants from "../../../rimaconstants";
import DataView from "../../DataTable/DataView";
import TooltipsGrid from "../../Common/TooltipsGrid";
// import UnstructureDataSearch from "./UnstructuredDataSearch";
// import { toast } from "react-toastify";
export default function Inferences(props) {
  const [loading, setLoading] = React.useState(false);
  const [insightEditLoading, setInsightEditLoading] = React.useState(false);
  let feature_id = window.getFeatureID();
  let audit_id = props.audit_id;
  const { breadcrums, setBreadcrums } = React.useContext(BreadcrumsContext);
  const [isEditPopupOpen, setIsEditPopupOpen] = React.useState(false);
  React.useEffect(() => {
    isEditPopupOpen && setIsEditPopupOpen(false);
  }, [breadcrums.childMenu]);
  const [richText, setRichText] = React.useState(
    breadcrums.inferencesData
      ? window.customEscape(breadcrums.inferencesData)
      : ""
  );
  const [richText1, setRichText1] = React.useState("");
  const [tmpRichText, setTmpRichText] = React.useState("");
  const [selectionModel, setSelectionModel] = React.useState([]);

  const handleMulSelection = (outerPos, innerPos, selection) => {
    // console.log("outerPos ", outerPos);
    // console.log("innerPos ", innerPos);
    // console.log("selection ", selection);
    outerPos = outerPos - 1;
    innerPos = innerPos - 1;
    props.setFilterInsight &&
      props.setFilterInsight((prevSelection) => {
        let obj = {};
        obj[`${outerPos}${innerPos}`] = selection;
        return { ...prevSelection, ...obj };
      });
  };

  React.useEffect(() => {
    props.setDisabledExportConditionally(isEditPopupOpen);
  }, [isEditPopupOpen]);
  React.useEffect(() => {
    try {
      let res = richText ? JSON.parse(richText) : [];
      console.log("richText ", res);
      if (res.length)
        if (res) {
          setTmpRichText(res);
          setRichText1(res);
        } else {
          setRichText1([]);
        }
    } catch (e) {
      setRichText1([]);
    }
  }, [richText]);

  const getHeading = (res) => {
    try {
      if (res && window.validateInsightsCondition(res)) {
        return [];
      }

      res.map(async (data) => {
        // if (
        //   ["Overdue (in Days)", "Overdue(In Days)"].includes(data.headerName)
        // ) {
        //   data.type = "number";
        // }

        if (
          [
            "Change Control Open",
            "Overdue (in Days)",
            "Overdue(In Days)",
          ].includes(data.headerName)
        ) {
          data.valueGetter = ({ value }) => (value ? parseInt(value) : "");
        }

        if (data.type && data.type === "date") {
          data.type = "string";
          data.type1 = "date";
          data.getApplyQuickFilterFn = window.getApplyQuickFilterFn;
          data.valueGetter = ({ value }) => window.getDateGridBinding(value);
          data.renderCell = (params) => (
            <TooltipsGrid
              html={false}
              placement="top"
              title={window.getDate(params.row[data.field])}
            />
          );
        } else {
          data.renderCell = (params) => {
            if (typeof params.row[data.field] === "object") {
              return (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={params.row[data.field].value}
                />
              );
            } else {
              return (
                <TooltipsGrid
                  html={false}
                  placement="top"
                  title={params.row[data.field]}
                />
              );
            }
          };
        }
      });

      if (res) {
        return res;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  };

  const getRows = (res) => {
    try {
      if (res && window.validateInsightsCondition(res)) {
        return [];
      }

      if (res) {
        return res;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  };

  const parseDataUpdate = (data, key, parentPos, childPos) => {
    try {
      let res = tmpRichText;
      res.map((obj, index) => {
        if (!obj.hasOwnProperty("editableInsights") || obj.editableInsights) {
          if (key === obj.key && index === parentPos) {
            // res[index]["editableText"] = data;
            if (Array.isArray(res[index]["editableText"])) {
              res[index]["editableText"].map((editData, ind) => {
                if (ind === childPos) {
                  res[index]["editableText"][ind] = data;
                }
              });
            }
          }
        }
        return true;
      });
      //console.log("parseDataUpdate ", res);
      setTmpRichText(res);
      res = JSON.stringify(res);
      return res;
    } catch (e) {
      return "";
    }
  };

  const setUpdatedData = (data, key) => {
    try {
      let res = tmpRichText;
      res.map((obj, index) => {
        if (key === obj.key) {
          res[index]["editableText"] = data;
        }
        return true;
      });

      setRichText1(res);
      res = JSON.stringify(res);
      setRichText(res);
      return res;
    } catch (e) {
      return "";
    }
  };

  const label = { inputProps: { "aria-label": "Inferences" } };
  //console.log(feature_id);
  let feature_list = breadcrums.featureList.filter((list) => {
    return parseInt(list.parent_feature_id) === feature_id;
  });
  // console.log(feature_list);
  // React.useEffect(() => {
  //   loadInferences();
  // }, []);

  React.useEffect(() => {
    //console.log(breadcrums.inferencesData);
    if (breadcrums.inferencesData && breadcrums.inferencesData !== richText) {
      setRichText(window.customEscape(breadcrums.inferencesData));
      // if (
      //   window.insights[breadcrums.vaccine ? "VX" : "RX"].includes(
      //     breadcrums.childMenu
      //   )
      // ) {
      //   try {
      //     let res = breadcrums.inferencesData
      //       ? JSON.parse(breadcrums.inferencesData)
      //       : null;
      //     if (res) {
      //       setRichText1(res ? res : []);
      //     }
      //   } catch (e) {}
      // }
    }
  }, [breadcrums.inferencesData]);

  // const loadInferences = () => {
  //   if (breadcrums.childMenu === "Management Review By Quality Council") {
  //     if (audit_id && feature_id) {
  //       window.showLoader();
  //       axios
  //         .get(
  //           `${rimaConstants.REACT_APP_API_URL}/api/v1/inferences/sqcm/${feature_id}/${audit_id}?audit_site_id=${undefined}`
  //         )
  //         .then((response) => {
  //           if (response?.data?.status === "success") {
  //             if (response?.data?.data?.length) {
  //               setRichText(response.data["data"][0]);
  //             }
  //           }
  //           window.hideLoader();
  //         })
  //         .catch((e) => console.log(e));
  //     }
  //   }
  // };

  const updateInferences = async (
    html,
    key = null,
    parentPos = null,
    childPos = null
  ) => {
    setInsightEditLoading(true);
    let htmlVal = html;
    if (key) {
      htmlVal = parseDataUpdate(html, key, parentPos, childPos);
    }
    let req_data = {
      insight_text: await window.deflate(htmlVal),
      data_source: breadcrums.vaccine ? "Vx" : "Rx",
      snapshot_date: window.getDateDB(
        breadcrums.startDates,
        false,
        breadcrums.childMenu,
        breadcrums.vaccine ? "VX" : "RX"
      ),
    };
    //console.log("req_data ", req_data);
    axios
      .put(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/inferences/manipulate/${feature_id}/${audit_id}`,
        req_data
      )
      .then((res) => {
        //toast.success("Updated..");
        setInsightEditLoading(false);
        if (res?.data?.status === "fail") {
          window.showMessage(res.data.msg, "warning");
        } else {
          if (key) {
            setUpdatedData(htmlVal, key);
          } else {
            setRichText(htmlVal);
          }
          window.showMessage("Insights updated successfully.", "success");
          setBreadcrums((prevBreadcrums) => {
            prevBreadcrums.inferencesData = htmlVal;
            return { ...prevBreadcrums };
          });
          //props.updateInsights()
        }
        editPopupHandler();
      })
      .catch((res) => {
        //console.log(res);
        editPopupHandler();
      });
  };

  const renderHtml = (html) => {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };

  const editPopupHandler = () => {
    if (
      breadcrums.inferencesData !== "No Insights Available" &&
      breadcrums.inferencesData !== "No Data Found for the audit"
    ) {
      setIsEditPopupOpen(!isEditPopupOpen);
    }
  };
  const reinitiate = () => {
    localStorage.setItem("isInsightsDeleted", false);
    setLoading(true);
    //toast("Initiating the contents..");
    //window.showMessage("Initiating the contents..", "info");
    axios
      .delete(
        `${
          rimaConstants.REACT_APP_API_URL
        }/api/v1/inferences/delete/${feature_id}/${audit_id}?cm=${
          breadcrums.childMenu
        }&snapshot_date=${window.getDateDB(
          breadcrums.startDates,
          false,
          breadcrums.childMenu,
          breadcrums.vaccine ? "VX" : "RX"
        )}`
      )
      .then((res) => {
        if (res?.data?.status === "fail") {
          window.showMessage(res.data.msg, "warning");
        } else {
          localStorage.setItem("isInsightsDeleted", true);
          window.showMessage("Initiating the contents..", "info");
          props.updateInsights();
        }
        setLoading(false);
      })
      .catch((res) => {
        //console.log(res);
      });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        className="insights-main-container"
        maxWidth={true}
        sx={{ padding: "0px !important" }}
      >
        <Box
          sx={{
            // bgcolor: "#FFFFFF",
            paddingTop: "10px",
            border: "1px solid rgba(224, 224, 224, 1)",
            borderRadius: "0.5rem",
            display: `${props.display}`,
          }}
        >
          <Typography variant="h6" gutterBottom component="div">
            <Checkbox {...label} defaultChecked disabled={true} />
            Insights
            <span style={{ float: "right" }}>
              <Box>
                <FormControlLabel
                  sx={{
                    display: "none",
                  }}
                  control={
                    <Switch
                      checked={loading}
                      onChange={() => setLoading(!loading)}
                      name="loading"
                      color="primary"
                    />
                  }
                  label="Loading"
                />
                <Box sx={{ "& > button": { m: 1 } }}>
                  <LoadingButton
                    loading={loading}
                    loadingPosition="end"
                    endIcon={<RestartAltIcon />}
                    type="submit"
                    sx={{ textTransform: "capitalize" }}
                    variant="contained"
                    size="small"
                    onClick={reinitiate}
                    disabled={
                      breadcrums.closedAudits
                        ? true
                        : breadcrums.inferencesData ===
                            "No Insights Available" ||
                          breadcrums.inferencesData ===
                            "No Data Found for the audit" ||
                          (breadcrums.childMenu !== "" &&
                            feature_list.length &&
                            feature_list.findIndex(
                              (l) => l.feature_name === "Re-Initiate Insights"
                            ) !== -1 &&
                            feature_list[
                              feature_list.findIndex(
                                (l) => l.feature_name === "Re-Initiate Insights"
                              )
                            ]["access_allowed"] === 0)
                        ? true
                        : false
                    }
                  >
                    Re-Initiate Insights
                  </LoadingButton>
                  <IconButton
                    size="small"
                    onClick={editPopupHandler}
                    title="Insights Edit"
                    disabled={
                      breadcrums.closedAudits
                        ? true
                        : breadcrums.inferencesData ===
                            "No Insights Available" ||
                          breadcrums.inferencesData ===
                            "No Data Found for the audit" ||
                          (breadcrums.childMenu !== "" &&
                            feature_list.length &&
                            feature_list.findIndex(
                              (l) => l.feature_name === "Insights Edit"
                            ) !== -1 &&
                            feature_list[
                              feature_list.findIndex(
                                (l) => l.feature_name === "Insights Edit"
                              )
                            ]["access_allowed"] === 0)
                        ? true
                        : false
                    }
                    id={
                      breadcrums.inferencesData === "No Insights Available" ||
                      breadcrums.inferencesData ===
                        "No Data Found for the audit"
                        ? "noEdit"
                        : "editIns"
                    }
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              </Box>
            </span>
          </Typography>
          <div style={{ width: "100%", padding: "15px" }}>
            {/* <>
              {window.allowUnstructureComponent[
                breadcrums.vaccine ? "VX" : "RX"
              ].includes(breadcrums.childMenu) && <UnstructureDataSearch auditInfo={breadcrums.auditTitle} />}
            </> */}
            {window.insights[breadcrums.vaccine ? "VX" : "RX"].includes(
              breadcrums.childMenu
            ) ? (
              <>
                {breadcrums.tableColumnData.length && richText1.length
                  ? richText1.map((obj, index) => {
                      return (
                        <>
                          <div
                            style={{
                              paddingBottom: "20px",
                              overflow: "hidden",
                            }}
                          >
                            <fieldset
                              style={{
                                borderTop: "double #15717D",
                                borderLeft: "none",
                                borderRight: "none",
                                borderBottom: "none",
                              }}
                            >
                              {obj.type &&
                                obj.type === "TABLE" &&
                                obj?.title && (
                                  <legend>
                                    <strong>
                                      {obj.key.replace(/_/g, " ")}
                                    </strong>
                                  </legend>
                                )}
                              {obj.type &&
                              obj.type === "TABLE" &&
                              obj?.heading?.length > 0 ? (
                                obj?.heading.map((data, ind) => {
                                  return (
                                    <>
                                      {obj?.heading[ind].length > 1 ? (
                                        <div
                                          style={{ padding: "10px 0 20px 0" }}
                                        >
                                          <DataView
                                            columns={getHeading(
                                              obj?.heading[ind]
                                            )}
                                            rows={getRows(obj?.rows[ind])}
                                            menuName={obj?.title[ind]}
                                            checkboxSelection={true}
                                            auditCheckBox={false}
                                            hidefilter={true}
                                            // auditCheckCall={(val) => {
                                            //   setAuditCheckBox(val);
                                            // }}
                                            setBreadcrums={setBreadcrums}
                                            breadcrums={breadcrums}
                                            user={[]}
                                            isInsights={true}
                                            setSelectionModel={
                                              setSelectionModel
                                            }
                                            selectionModel={selectionModel}
                                            outerPos={index + 1}
                                            innerPos={ind + 1}
                                            handleMulSelection={
                                              handleMulSelection
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                      {obj.editableText[ind] &&
                                      isEditPopupOpen &&
                                      (!obj.hasOwnProperty(
                                        "editableInsights"
                                      ) ||
                                        obj.editableInsights) ? (
                                        <>
                                          <TextEditor
                                            menu={props.menu}
                                            textValue={
                                              richText1[index]["editableText"][
                                                ind
                                              ]
                                            }
                                            onClose={() => {
                                              editPopupHandler();
                                              setRichText1(
                                                richText
                                                  ? JSON.parse(richText)
                                                  : ""
                                              );
                                            }}
                                            insightEditLoading={
                                              insightEditLoading
                                            }
                                            onSubmit={(html) => {
                                              // setRichText(html);
                                              //props.onSubmit(html);
                                              updateInferences(
                                                html,
                                                obj.key,
                                                index,
                                                ind
                                              );
                                              // editPopupHandler();
                                            }}
                                            onTextChange={(html) => {
                                              parseDataUpdate(
                                                html,
                                                obj.key,
                                                index,
                                                ind
                                              );
                                            }}
                                            showButtons={
                                              Object.keys(richText1).length ===
                                                index + 1 &&
                                              richText1[index]["editableText"]
                                                .length ===
                                                ind + 1
                                                ? true
                                                : false
                                            }
                                          />
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      <div style={{ whiteSpace: "pre-wrap" }}>
                                        {Array.isArray(obj.editableText) &&
                                        obj.editableText &&
                                        !isEditPopupOpen ? (
                                          <>
                                            {renderHtml(
                                              window.customEscape(
                                                obj.editableText[ind]
                                              )
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </>
                                  );
                                })
                              ) : (
                                <>
                                  {Array.isArray(obj.editableText) &&
                                    obj.editableText.map((_, ind1) => {
                                      return (
                                        <>
                                          <div
                                            style={{ whiteSpace: "pre-wrap" }}
                                          >
                                            {!isEditPopupOpen ? (
                                              <>
                                                {renderHtml(
                                                  window.customEscape(
                                                    obj.editableText[ind1]
                                                  )
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                <TextEditor
                                                  menu={props.menu}
                                                  textValue={
                                                    richText1[index][
                                                      "editableText"
                                                    ][ind1]
                                                  }
                                                  onClose={() => {
                                                    editPopupHandler();
                                                    setRichText1(
                                                      richText
                                                        ? JSON.parse(richText)
                                                        : ""
                                                    );
                                                  }}
                                                  insightEditLoading={
                                                    insightEditLoading
                                                  }
                                                  onSubmit={(html) => {
                                                    // setRichText(html);
                                                    //props.onSubmit(html);
                                                    updateInferences(
                                                      html,
                                                      obj.key,
                                                      index,
                                                      ind1
                                                    );
                                                    // editPopupHandler();
                                                  }}
                                                  onTextChange={(html) => {
                                                    parseDataUpdate(
                                                      html,
                                                      obj.key,
                                                      index,
                                                      ind1
                                                    );
                                                  }}
                                                  showButtons={
                                                    Object.keys(richText1)
                                                      .length ===
                                                      index + 1 &&
                                                    richText1[index][
                                                      "editableText"
                                                    ].length ===
                                                      ind1 + 1
                                                      ? true
                                                      : false
                                                  }
                                                />
                                              </>
                                            )}
                                          </div>
                                        </>
                                      );
                                    })}
                                </>
                              )}
                            </fieldset>
                          </div>
                        </>
                      );
                    })
                  : "No Insights Available"}
              </>
            ) : (
              <>
                <div style={{ whiteSpace: "pre-wrap" }}>
                  {!isEditPopupOpen
                    ? renderHtml(breadcrums.inferencesData)
                    : ""}
                </div>
                <>
                  {isEditPopupOpen && (
                    <TextEditor
                      menu={props.menu}
                      textValue={richText}
                      onClose={editPopupHandler}
                      insightEditLoading={insightEditLoading}
                      onSubmit={(html) => {
                        //console.log("onSubmit ", html);
                        // setRichText(html);
                        //props.onSubmit(html);
                        updateInferences(html);
                        // editPopupHandler();
                      }}
                      showButtons={true}
                    />
                  )}
                </>
              </>
            )}
          </div>
        </Box>
      </Container>
    </React.Fragment>
  );
}
