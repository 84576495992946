 //export const REACT_APP_API_URL="https://gskus6vxdagioqradevapp03-dev.azurewebsites.net"

export const REACT_APP_API_URL= process.env.REACT_APP_API_URL

export const CLIENTID = process.env.REACT_APP_AZURE_CLIENTID //"8fd70f6d-631a-4649-88d6-cf382842cc3a"
export const AUTHORITY = process.env.REACT_APP_AZURE_AUTHORITY //"https://login.microsoftonline.com/63982aff-fb6c-4c22-973b-70e4acfb63e6"
export const REACT_APP_ONLY_ASM =  process.env.REACT_APP_ONLY_ASM
export const TEXT_TRANSLATOR_URI = process.env.REACT_APP_TEXT_TRANSLATOR_URI
export const DOC_TRANSLATOR_URI = process.env.REACT_APP_DOC_TRANSLATOR_URI
export const TRANSLATOR_TEXT_RESOURCE_KEY = process.env.REACT_APP_TRANSLATOR_TEXT_RESOURCE_KEY
export const TRANSLATOR_FILE_RESOURCE_KEY = process.env.REACT_APP_TRANSLATOR_FILE_RESOURCE_KEY
export const ASM_ROOT_CONTAINER =	 "raw"
export const ASM_ROOT_CONATINER_SAS_WRITE_TOKEN =	"?sv=2020-08-04&ss=bf&srt=co&sp=rwdlacitfx&se=2026-10-30T18:49:02Z&st=2022-06-07T10:49:02Z&spr=https&sig=p5lqbSRtUZlaqD6Gk5ih1%2FI24bjZRojXjA4FBsCdTww%3D"//added

export const ARA_ROOT_CONTAINER="ara"	


export const ERROR_LOAD_DATA = `we can't load any data. try again.`;
