import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useMsal, useAccount } from "@azure/msal-react";
import { loginRequest } from "../../authConfigmasl";
import * as rimaConstants from "../../rimaconstants";

  
const UserDataContext = React.createContext([{}, () => { }]);
const sasEmptyObj = {
  storageUri:"",
  storageAccessToken:"",
  storageAccount:""
}
const UserDataContextProvider = (props) => {
    const [adminUser, setAdminUser] = useState("0");
    const [user, setUser] = useState({});
    const [userSASToken, setUserSASToken] = useState(sasEmptyObj);
    useEffect(()=>{
      axios
      .get(`${rimaConstants.REACT_APP_API_URL}/api/v1/user`)
      .then((response) => {
        setUser(response.data)
        setAdminUser(response.data.admin_user);
        if(response.data.sastoken.status === "success") {
          response.data.sastoken.token.storageAccount =  response.data.sastoken.token.storageUri.split("//")[1].split(".")[0]
          setUserSASToken(response.data.sastoken.token)
        }
        
      })
      .catch(function (error) {
        setAdminUser("0")
        setUser({})
        setUserSASToken(sasEmptyObj)
        console.log(error);
      })
    },[])
    return (
        <UserDataContext.Provider value={[adminUser,setAdminUser, user, setUser, userSASToken, setUserSASToken]}>
          {props.children}
        </UserDataContext.Provider>
      );
    }


    
export  { UserDataContext, UserDataContextProvider };    