import React, { useState, useEffect } from "react";
import { Grid, Button } from "@mui/material";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Typography,
  IconButton,
} from "@mui/material";
import * as rimaConstants from "../../../rimaconstants";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ConfirmDialog from "../../Common/confirmDailog";
import PDFViewer from "../../Pdfview/PDFViewer";
import axios from "axios";
import CommonHeading from "../../Common/CommonHeading";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../applicationInsights/AppInsights';

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
const ARATemplateUpdation = () => {
  const [template, setTemplate] = useState("Vx");
  const [docs, setDocs] = useState("");
  const [updateNew, setUpdateNew] = useState(false);
  const [newFile, setNewFile] = useState({ file: "", name: "" });
  const [showAlert, setShowAlert] = useState(false);
  const [view, setView] = useState(false);
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef(null);

  React.useEffect(() => {
    setHeight(window.innerHeight - 210);
  }, []);

  const handleChange = (event) => {
    setView(false);
    setTemplate(event.target.value);
    setUpdateNew(false);
    let url =
      window.BLOB_URL +
      "/" +
      rimaConstants.ARA_ROOT_CONTAINER +
      "/" +
      event.target.value +
      "/Template/" +
      event.target.value +
      "-Audit Risk Analysis Template.docx" +
      window.BLOB_CONATINER_SAS_READ_TOKEN;
    setDocs(url);
  };
  useEffect(() => {
    let url =
      window.BLOB_URL +
      "/" +
      rimaConstants.ARA_ROOT_CONTAINER +
      "/" +
      template +
      "/Template/" +
      template +
      "-Audit Risk Analysis Template.docx" +
      window.BLOB_CONATINER_SAS_READ_TOKEN;
    setDocs(url);
  }, []);

  const handleSave = () => {
    setShowAlert(true);
  };
  const handleUpload = () => {
    let foldername = "ara";
    let subfolder = template + "/Template";
    let name = template + "-Audit Risk Analysis Template.docx";
    var req_obj = {};
    req_obj = new FormData();
    req_obj.append("upfile", newFile.file);
    axios
      .post(
        `${
          rimaConstants.REACT_APP_API_URL
        }/api/v1/upload?folderName=${foldername}&subFolderName=${subfolder}&fileName=${encodeURIComponent(
          name
        )}&txt=ARA Template Update Updated`,
        req_obj
      )
      .then((response) => {
        if (response?.data?.status === "success") {
          window.showMessage("Saved", "success");
          setNewFile({ file: "", name: "" });
          setUpdateNew(false);
          setDocs("");
          let url =
            window.BLOB_URL +
            "/" +
            rimaConstants.ARA_ROOT_CONTAINER +
            "/" +
            template +
            "/Template/" +
            template +
            "-Audit Risk Analysis Template.docx" +
            window.BLOB_CONATINER_SAS_READ_TOKEN;
          setDocs(url);
        } else {
          window.showMessage("Failed to save", "warning");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onFileChange = (event) => {
    let type = event.target.files[0]["type"].split("/")[1];
    if (
      type === "vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      type = "doc";
    }
    // console.log(type);
    if (type === "doc") {
      setNewFile({
        file: event.target.files[0],
        name: event.target.files[0]["name"],
      });
      document.getElementById(`contained-button-file`).value = null;
    } else {
      window.showMessage("Invalid file type", "warning");
    }
  };
  const handleNewFile = () => {
    setUpdateNew(true);
  };

  // const handleView = async () => {
  //   let type = "vnd.openxmlformats-officedocument.wordprocessingml.document";
  //   let url =
  //     window.BLOB_URL +
  //     "/" +
  //     rimaConstants.ARA_ROOT_CONTAINER +
  //     "/" +
  //     template +
  //     "/Template/" +
  //     template +
  //     "-Audit Risk Analysis Template.docx" +
  //     window.BLOB_CONATINER_SAS_READ_TOKEN;
  //   const response = await fetch(url, {
  //     method: "GET",
  //   });
  //   console.log(response);
  //   const blob = await response.blob();
  //   const newBlob = new Blob([blob], {
  //     type: "application/" + type,
  //   });
  //   const blobUrl = window.URL.createObjectURL(newBlob);
  //   const a = document.createElement("a");
  //   document.body.appendChild(a);
  //   a.href = blobUrl;
  //   a.download = template + "-Audit Risk Analysis Template.docx"; // you need to write the extension of file here
  //   a.click();
  //   window.URL.revokeObjectURL(blobUrl);
  //   //setDocs(blobUrl);
  // };

  return (
    <div ref={ref} style={{ minHeight: height }}>
      <Grid container>
        <Grid item xs={12}>
          <CommonHeading heading="ARA Template Update" />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <FormControl sx={{ m: 1, width: "40ch", mb: 5 }}>
              <InputLabel id="demo-simple-select-label">
                ARA Template For
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={template}
                label="ARA Template For"
                onChange={handleChange}
                sx={{ height: "50px" }}
              >
                <MenuItem key={"Vx"} value="Vx">Vx</MenuItem>
                <MenuItem key={"Rx"} value="Rx">Rx</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <Typography variant="h6" gutterBottom component="div" ml={25}>
              {template}-Audit Risk Analysis Template.docx
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                setView(true);
              }}
              sx={{ mr: 1, textTransform: "none", ml: 2, minHeight: 0 }}
            >
              View
            </Button>
            <Button
              variant="contained"
              onClick={handleNewFile}
              sx={{ mr: 1, textTransform: "none" }}
            >
              Update
            </Button>
          </Box>
        </Grid>
      </Grid>
      {updateNew ? (
        <Grid
          container
          sx={{
            mt: 3,
            pt: 3,
            pb: 3,
            backgroundColor: "#fff",
            border: "2px dashed hsla(0,0%,50.2%,.2)!important",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              p: 2,
              //ml: 58
            }}
          >
            <Box
              className="file-selection"
              display="flex"
              //justifyContent="center"
            >
              <Item sx={{ gridColumn: "1" }}>
                <label htmlFor={`contained-button-file`}>
                  <Input
                    className="file-input"
                    id={`contained-button-file`}
                    type="file"
                    onChange={(event) => onFileChange(event)}
                  />
                  <Button
                    variant="contained"
                    component="span"
                    style={{ textTransform: "none" }}
                  >
                    Choose File
                  </Button>
                </label>
              </Item>
              {newFile.name !== "" ? (
                <>
                  <Item sx={{ fontSize: "0.875rem", fontWeight: "500", ml: 3 }}>
                    {newFile.name}
                    <span
                      sx={{ ml: 2 }}
                      onClick={() => setNewFile({ file: "", name: "" })}
                    >
                      <IconButton
                        component="span"
                        variant="contained"
                        aria-label="close"
                        size="small"
                      >
                        <CancelRoundedIcon />
                      </IconButton>
                    </span>
                  </Item>
                  <Item>
                    <Button
                      variant="contained"
                      onClick={handleSave}
                      sx={{ mr: 1, textTransform: "none", ml: 3 }}
                      disabled={newFile.name === "" ? true : false}
                    >
                      Save
                    </Button>
                  </Item>
                </>
              ) : (
                ""
              )}
            </Box>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      <ConfirmDialog
        title=""
        open={showAlert}
        setOpen={setShowAlert}
        onConfirm={() => handleUpload()}
      >
        Existing template will get replaced with new one. Do you want to save
        it?
      </ConfirmDialog>
      {view ? (
        <Grid container sx={{ mt: 5 }}>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <Box display="flex" justifyContent="center">
              <PDFViewer url={docs} />
            </Box>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      ) : (
        ""
      )}
    </div>
  );
};
// export default ARATemplateUpdation;
export default withAITracking(reactPlugin, ARATemplateUpdation);
