import React from "react";
import styles from './DataTable.module.css';

import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
// import TableHead from "@mui/material/TableHead";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
// import PDFViewer from 'pdf-viewer-reactjs';


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange, setTop, setSkip } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    // setTop(count);
    // setSkip(count);
  };

  return (
    <Box sx={{ justifyContent: 'flex-end', flexShrink: 0, ml: 2.5 }}>

      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


const DataTable = ({ data }) => {
  const columns = data[0] && Object.keys(data[0]);
  // console.log(data);


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    setPage(0);
    setRowsPerPage(10);
}, [data])

  return (
    // <table cellPadding={0} cellSpacing={0} border={1}>
    //   <thead>
    //     <tr>{data[0] && columns.map((heading) => <th>{heading}</th>)}</tr>
    //   </thead>
    //   <tbody>
    //     {data.map((row) => (
    //       <tr>
    //         {columns.map((column) => (
    //           <td>{row[column]}</td>
    //         ))}
    //       </tr>
    //     ))}
    //   </tbody>
    // </table>


    <Paper style={{ boxShadow: 'none' }} sx={{ width: '100%', overflow: 'hidden' }}>

      {/* {q? <div>Ram</div> : null} */}

      <TableContainer id='search-node' style={{ boxShadow: 'none' }} component={Paper}>
        <Table  sx={{ width: 1, minWidth: 400 }} size="small" aria-label="Records">
          {/* <TableHead>
            <TableRow>{data[0] && columns.map((heading) => <TableCell>{heading}</TableCell>)}
            </TableRow>
          </TableHead> */}
          <TableBody   className={styles.searchTbl} style={{ display: 'grid' }}>
         
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data

            ).map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {
                columns.map((column) => (
                  <TableCell className="dataRowRecords"> 
                    {/* {Array.isArray(row[column]) ? row[column].join('') : row[column]} */}
                    {row[column]}
                  </TableCell>
                ))}
              </TableRow>
            ))}

           
          </TableBody>

        </Table>
      </TableContainer>
    
      <br />
      {data.length == 0 ? null : <TablePagination style={{ borderBottom: 'none' }}

        rowsPerPageOptions={[10, 25, { label: 'All', value: -1 }]}
        colSpan={3}
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            'aria-label': 'rows per page',
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />}


    </Paper>
  );
};

export default DataTable;
