import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import DocListViewer from "./doclistviewer";

import { useMsal, useAccount } from "@azure/msal-react";
import { loginRequest } from "../../../authConfigmasl";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Loader from "../../Loader/Loader";
import { toast } from "react-toastify";

import * as rimaConstants from "../../../rimaconstants";
import { memo } from "react";
import getHiddenDocList from "../../Common/hiddenDocs";

const UploadedDocList = () => {
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [dataReady, setDataReady] = useState(false);
  const [loading, setLoading] = useState(false);

  function getDocs(postProcess = null) {
    setLoading(true);
    axios
      .get(`${rimaConstants.REACT_APP_API_URL}/api/v1/admin/uploadeddocs`)
      .then(async (res) => {
        //Get hidden documents
        var hiddendocs = [
          ...new Set(
            (await getHiddenDocList()).map((x) => {
              return x.document_storage_url.split(".").slice(0, -1).join(".");
            })
          ),
        ];

        var doclist = res.data.map((x) => {
          return {
            document_id: x.document_id,
            document_title: x.document_title,
            title: x.document_title,
            type: x.is_external ? "External" : "Internal",
            regulation: x.regulation_tag,
            filepath: x.document_storage_url,
            file_name: x.document_name,
            created_by: x.created_by,
            created_date_time: window.getDate(
              x.uploaded_on ? x.uploaded_on : x.created_date_time,
              true
            ),
          };
        });

        //Filter hidden docs
        doclist = doclist.filter(
          (x) =>
            !hiddendocs.includes(
              x.filepath.split(".").slice(0, -1).join("."),
              0
            )
        );

        if (postProcess !== null) postProcess();
        setUploadedDocs(doclist);
        setLoading(false);
      })
      .catch((x) => {
        toast("Failed to load user uploaded documents.");
        if (postProcess !== null) postProcess();
        setLoading(false);
      });
  }

  if (!dataReady) {
    setDataReady(true);
    getDocs();
  }
  return (
    <>
      {loading ? window.showLoader() : window.hideLoader()}
      <DocListViewer
        rows={uploadedDocs}
        refreshFn={getDocs}
        search="true"
        isUploadedList={true}
      ></DocListViewer>
      <h6>
        NOTE: The above document list contains only successfully processed
        documents.
      </h6>
    </>
  );
};

export default memo(UploadedDocList);
