import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";

import MenuItem from "@mui/material/MenuItem";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import AttachmentIcon from "@mui/icons-material/Attachment";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import {
  Button,
  InputLabel,
  Select,
  Stack,
  Grid,
  IconButton,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
  Dialog,
  FormHelperText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Switch from "@mui/material/Switch";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import { BreadcrumsContext } from "../context/breadcrumsContext";
//import AutocompleteInput from "./autocomplete";
import AutocompleteInput1 from "../../Common/autocomplete1";
import ConfirmDialog from "../../Common/confirmDailog";
import axios from "axios";
import * as rimaConstants from "../../../rimaconstants";
import ReactTooltip from "react-tooltip";
import Tooltips from "../../Common/Tooltips";
import moment from "moment";
import { UserDataContext } from "../../Context/UserDataContext";

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}

export default function FeedBackForm(props) {
  // eslint-disable-next-line no-unused-vars
  const ref = React.useRef(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [titleOpen, setTitleOpen] = useState(false);
  const [feedBackTemplateTitle, setFeedBackTemplateTitle] = useState("");
  const [confirmSaveAsTemplete, setConfirmSaveAsTemplete] = useState(false);
  const [confirmExistingTemplete, setConfirmExistingTemplete] = useState(false);
  const [loadingSend, setLoadingSend] = React.useState(false);
  const [loadingTranslate, setLoadingTranslate] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const { breadcrums, setBreadcrums } = React.useContext(BreadcrumsContext);
  // eslint-disable-next-line no-unused-vars
  const [adminUser, setAdminUser, user, setUser] =
    React.useContext(UserDataContext);
  let data_source = breadcrums.vaccine ? "Vx" : "Rx";
  // eslint-disable-next-line no-unused-vars
  const [dataSource, setDataSource] = useState(data_source);
  const [auditTitle, setAuditTitle] = useState("");
  const [auditId, setAuditId] = useState("");
  const [auditTypeId, setAuditTypeId] = useState("");
  const [language, setLanguage] = useState("");
  //const [translations, setTranslations] = useState([]);
  const [sendAutoReminder, setSendAutoReminder] = useState("7");
  const [maxReminder, setMaxReminder] = useState("4");
  const [sendFromTo, setSendFromTo] = React.useState("");

  const [formData, setformData] = useState([]);
  const [buttonParam, setButtonParam] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [queForm, setQueForm] = useState(true);
  const [addMoreRow, setAddMoreRow] = useState([
    {
      question1: "",
    },
    {
      question2: "",
    },
    {
      question3: "",
    },
  ]);
  useEffect(() => {
    if (
      typeof props.template_id !== "undefined" &&
      typeof props.template_id.stakeholder_feedback_template_id !== "undefined"
    ) {
      getTemplate(props.template_id.stakeholder_feedback_template_id);
    }
  }, [JSON.stringify(props.template_id)]);

  const [filesCollection, setFilesCollection] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFilesCopy, setUploadedFilesCopy] = useState([]);
  const [draftAttachment, setDraftAttachment] = useState([]);
  const [edit, setEdit] = useState(false);
  const [additionalComment, setAdditionalComment] = useState("");
  const [isTemplateLoaded, setIsTemplateLoaded] = useState(false);
  const [stakeholderFeedbackTemplateId, setStakeholderFeedbackTemplateId] =
    useState(0);
  const [isDraftAvailable, setIsDraftAvailable] = useState(false);

  const getTemplate = (temp_id) => {
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/stakeholder_feedback/load_stakeholder_feedback_template/${temp_id}`
      )
      .then((response) => {
        if (response.data.status === "success") {
          setIsTemplateLoaded(true);
          setStakeholderFeedbackTemplateId(temp_id);
          let prevData = {};
          if (response.data && response.data["data"].length > 0) {
            let data = response.data["data"][0];
            if (data["feedback_questions"]) {
              setAddMoreRow(JSON.parse(data["feedback_questions"]));
              prevData["feedback_questions"] = JSON.parse(
                data["feedback_questions"]
              );
            }
            if (data["feedback_attachments"]) {
              setUploadedFiles(JSON.parse(data["feedback_attachments"]));
              setUploadedFilesCopy(JSON.parse(data["feedback_attachments"]));
              prevData["feedback_attachments"] = JSON.parse(
                data["feedback_attachments"]
              );
            }
            setAdditionalComment(data["feedback_description"]);
            prevData["feedback_description"] = data["feedback_description"];
            setFeedBackTemplateTitle(data["feedback_template_title"]);
            prevData["feedback_template_title"] =
              data["feedback_template_title"];
          }
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const loadFeedbackData = (audit_id) => {
    //setTranslations([]);
    window.showLoader();
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/stakeholder_feedback/question/${audit_id}`
      )
      .then((response) => {
        if (response.data.status === "success") {
          if (response.data && response.data["data"].length > 0) {
            let prevData = {};
            let data = response.data["data"][0];
            if (
              data["feedback_status"] &&
              parseInt(data["feedback_status"]) === 1
            ) {
              setIsDraftAvailable(true);
            } else {
              setIsDraftAvailable(false);
            }
            if (Object.keys(props.template_id).length !== 0) {
              setEdit(true);
              setformData(response.data["data"]);
              let data = response.data["data"][0];
              setSendFromTo(data["emails"] ? data["emails"].slice(0, -1) : "");
              //setConfirmExistingTemplete(true);
              // alert("A draft questionnaire is already saved, do you want to discard the draft and proceed with the template?")
            } else {
              setIsTemplateLoaded(false);
              setEdit(true);
              // let prevData = {};
              // let data = response.data["data"][0];
              //console.log('loadFeedbackDataloaded ', data['emails']);
              if (data["feedback_questions"]) {
                setAddMoreRow(JSON.parse(data["feedback_questions"]));
                prevData["feedback_questions"] = JSON.parse(
                  data["feedback_questions"]
                );
              }
              if (data["general_attachments"]) {
                setUploadedFiles(JSON.parse(data["general_attachments"]));
                setUploadedFilesCopy(JSON.parse(data["general_attachments"]));
                prevData["general_attachments"] = JSON.parse(
                  data["general_attachments"]
                );
              }
              if (data["general_description"] !== "undefined") {
                setAdditionalComment(data["general_description"]);
                prevData["general_description"] = data["general_description"];
              }
              setSendFromTo(data["emails"] ? data["emails"].slice(0, -1) : "");
              prevData["general_description"] = data["emails"]
                ? data["emails"].slice(0, -1)
                : "";
              setMaxReminder(data["maximum_reminders"].toString());
              prevData["general_description"] =
                data["maximum_reminders"].toString();
              setSendAutoReminder(data["reminder_days"].toString());
              prevData["general_description"] =
                data["reminder_days"].toString();
            }
          } else {
            setIsDraftAvailable(false);
          }
        }
        window.hideLoader();
      })
      .catch(function (error) {
        //console.log(error);
      });
  };
  React.useEffect(() => {
    //console.log("file collection useeffect", filesCollection)
  }, [filesCollection]);
  React.useEffect(() => {
    if (breadcrums.auditTitle) {
      const [
        audit_number,
        // eslint-disable-next-line no-unused-vars
        country_code,
        // eslint-disable-next-line no-unused-vars
        audit_site_id,
        // eslint-disable-next-line no-unused-vars
        site_description,
        audit_id,
        audit_title,
        audit_type_id,
      ] = breadcrums.auditTitle.split("@#@");
      setAuditTitle(audit_number + " - " + audit_title);
      setAuditId(audit_id);
      setAuditTypeId(audit_type_id);
      loadFeedbackData(audit_id);
    }
  }, [breadcrums.auditTitle]);

  const addMoreRows = (index) => {
    ////console.log("handleFormChange add ", index);
    let tmpObj = {};
    let key = `question${addMoreRow.length + 1}`;
    ////console.log("handleFormChange add ", key);
    tmpObj[key] = "";
    setAddMoreRow([...addMoreRow, tmpObj]);
    // //console.log("handleFormChange add ", addMoreRow)
  };
  const deleteMoreRows = (index) => {
    // //console.log('handleFormChange Delete ', index);
    // //console.log('handleFormChange Delete ', addMoreRow);
    let confirm = window.confirm("Do you want to delete the question ?");
    if (!confirm) return false;
    let data = [...addMoreRow];
    data.splice(index, 1);
    let tmpArr = [];
    data.map((val, i) => {
      let tempObj = {};
      for (let key in val) {
        tempObj = {};
        let key1 = "question" + (i + 1);
        tempObj[key1] = val[key];
        tmpArr.push(tempObj);
      }

      return tempObj;
    });
    // //console.log('handleFormChange Delete ', tmpArr);
    setAddMoreRow(tmpArr);
  };
  const handleFormChange = (index, event) => {
    // //console.log('handleFormChange hc ', addMoreRow);
    // //console.log(index)
    // console.log(event.keyCode);
    if (event.target.value.length > 200) {
      event.target.value = event.target.value.slice(0, 200);
    }
    let data = [...addMoreRow];
    for (let key in data[index]) {
      data[index][key] = event.target.value;
    }
    setAddMoreRow([...data]);
  };
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      props.close();
      props.setTemplate({});
    }
  };
  const addAttachment = (event) => {
    let allowedMimeTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/pdf",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ];
    let fileExists = [];
    let InvalidFiles = [];
    //console.log('addAttachment ', event.target.files);
    let collections = filesCollection;
    // event.target.files['FileList'].map((f,i)=>{
    //   //console.log('addAttachment f ', f);
    // //console.log('addAttachment i ',i);
    // });
    for (let k in event.target.files) {
      if (
        event.target.files[k] &&
        event.target.files[k]["name"] &&
        event.target.files[k]["name"] !== "item"
      ) {
        if (uploadedFiles.includes(event.target.files[k]["name"])) {
          //console.log('addAttachment name 1 ', event.target.files[k]['name']);
          fileExists.push(event.target.files[k]["name"]);
        } else {
          if (filesCollection.length > 0) {
            //console.log('addAttachment type ', event.target.files[k]['type'])
            if (!allowedMimeTypes.includes(event.target.files[k]["type"])) {
              InvalidFiles.push(event.target.files[k]["name"]);
              continue;
            }

            let filter = filesCollection.filter((val) => {
              //console.log('addAttachment name ', val['name']);
              if (val["name"] === event.target.files[k]["name"]) {
                //console.log('addAttachment name 1 ', val['name']);
                fileExists.push(val["name"]);
                return true;
              } else {
                return false;
              }
            });

            if (filter.length <= 0) {
              let attachment = {
                file: event.target.files[k],
                name: event.target.files[k]["name"],
              };
              //collections.push(event.target.files[k]);
              collections.push(attachment);
            } //collections.push(event.target.files[k]);
          } else {
            if (!allowedMimeTypes.includes(event.target.files[k]["type"])) {
              InvalidFiles.push(event.target.files[k]["name"]);
              continue;
            }
            let attachment = {
              file: event.target.files[k],
              name: event.target.files[k]["name"],
            };
            //collections.push(event.target.files[k]);
            collections.push(attachment);
          }
        }
      }
    }

    if (fileExists.length > 0 && InvalidFiles.length > 0) {
      window.showMessage(
        fileExists.join("\n\r") +
          " already exists! \n\r" +
          fileExists.join("\n\r") +
          " file type(s) are not allowed!",
        "warning"
      );
    } else if (fileExists.length > 0) {
      window.showMessage(
        fileExists.join("\n\r") + " already exists!",
        "warning"
      );
    } else if (InvalidFiles.length > 0) {
      window.showMessage(
        InvalidFiles.join("\n\r") + " file type(s) are not allowed!",
        "warning"
      );
    }

    //setFilesCollection(collections);
    setFilesCollection([...collections]);
    document.getElementById(`contained-button-file`).value = null;
    //console.log('addAttachment ccc ', collections);
    //console.log('addAttachment ', filesCollection);
  };
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };
  const handleTranslate = () => {
    if (language) {
      setLoadingTranslate(true);
      let tmparr = [...addMoreRow, { additionalfeedback: additionalComment }];
      let payload = { text: tmparr, lang: language };
      // console.log(payload);
      axios
        .post(
          `${rimaConstants.REACT_APP_API_URL}/api/v1/stakeholder_feedback_common/translate`,
          payload
        )
        .then((response) => {
          //setTranslations(response.data);
          setLoadingTranslate(false);
          response.data.map((input, index) => {
            let inputVal = "";
            for (let key in input) {
              inputVal = input[key];
              if (key === "additionalfeedback") {
                document.getElementById(`additionalfeedback`).innerHTML =
                  inputVal;
              } else {
                document.getElementById(`question${index}`).innerHTML =
                  inputVal;
              }
            }
            return true;
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      window.showMessage("* Please select language", "warning");
      return false;
    }
  };
  const handleRadio = (event) => {
    setSendAutoReminder(event.target.value);
    ////console.log('handleRadio ',event.target.value);
  };
  const handleMaxReminder = (event) => {
    setMaxReminder(event.target.value);
  };

  const handleAdditionalComment = (event) => {
    if (event.target.value.length > 500) {
      event.target.value = event.target.value.slice(0, 500);
    }
    setAdditionalComment(event.target.value);
  };

  const capitalize = ([first, ...rest], lowerRest = false) =>
    first.toUpperCase() +
    (lowerRest ? rest.join("").toLowerCase() : rest.join(""));
  const saveData = (general_attachments, response_status) => {
    let errCount = 0;
    let errMsg = [];
    //setLoading(true);
    if (!auditTitle) {
      errMsg.push("Please choose Audit Name");
      errCount++;
    }
    if (!dataSource) {
      errMsg.push("Data source required");
      errCount++;
    }

    addMoreRow.map((obj) => {
      for (let key in obj) {
        if (!obj[key]) {
          errMsg.push(
            `${capitalize(key, true).replace(/(\d+)/, " $1")} is required`
          );
          errCount++;
        }
      }
      return true;
    });

    if (!sendFromTo) {
      errMsg.push("Send Form To is required");
      errCount++;
    }

    if (!sendAutoReminder) {
      errMsg.push("Send Auto Reminder is required");
      errCount++;
    }

    if (!maxReminder) {
      errMsg.push("Max Reminder is required");
      errCount++;
    }

    if (errCount > 0 && response_status === 2) {
      window.showMessage("* " + errMsg.join("\n\r* "), "warning");
      setLoading(false);
      setLoadingSend(false);
      return false;
    } else {
      if (edit) {
        let tmpAttachments = [
          ...uploadedFiles,
          ...general_attachments.map((x) => encodeURIComponent(x)),
        ];
        let payload = {
          feedback_title: auditTitle,
          general_description: additionalComment,
          feedback_questions: addMoreRow,
          general_attachments: tmpAttachments,
          reminder_days: sendAutoReminder,
          maximum_reminders: maxReminder,
          send_to: sendFromTo,
          response_status: response_status,
          isReshareAction: props.isReshareAction,
        };
        window.showLoader();
        axios
          .put(
            `${rimaConstants.REACT_APP_API_URL}/api/v1/stakeholder_feedback/update_question/${auditId}`,
            payload
          )
          .then((response) => {
            if (response.data.status === "success") {
              if (response_status === 2) {
                props.updateTable();
                props.auditValidate();
                window.showMessage(
                  "Questionnaire sent successfully",
                  "success"
                );
              } else {
                window.showMessage(
                  "Questionnaire draft updated successfully.\n\rThe same has been available on Create Feedback Form section.",
                  "success"
                );
              }

              setLoadingSend(false);
              if (response_status === 2) {
                setConfirmSaveAsTemplete(true);
              } else {
                //setTimeout(() => {
                props.close();
                //}, 1000);
              }
            } else {
              if (response.data.msg) {
                window.showMessage(response.data.msg, "warning");
              } else {
                let err = [];
                response.data["data"].map((v) => {
                  for (const property in v) {
                    err.push(v[property]);
                  }
                  return true;
                });
                //console.log("err ", err);
                window.showMessage("* " + err.join("\n\r* "), "warning");
              }
            }

            setLoading(false);
            setLoadingSend(false);
            window.hideLoader();
          })
          .catch(function (error) {
            window.hideLoader();
            console.log(error);
          });
      } else {
        let tmpAttachments = [
          ...uploadedFiles,
          ...general_attachments.map((x) => encodeURIComponent(x)),
        ];
        let payload = {
          audit_id: auditId,
          feedback_title: auditTitle,
          general_description: additionalComment,
          feedback_questions: addMoreRow,
          general_attachments: tmpAttachments,
          reminder_days: sendAutoReminder,
          maximum_reminders: maxReminder,
          send_to: sendFromTo,
          response_status: response_status,
          isReshareAction: props.isReshareAction,
        };
        window.showLoader();
        axios
          .post(
            `${rimaConstants.REACT_APP_API_URL}/api/v1/stakeholder_feedback/add_question`,
            payload
          )
          .then((response) => {
            if (response.data.status === "success") {
              setEdit(true);

              if (response_status === 2) {
                props.updateTable();
                props.auditValidate();
                window.showMessage(
                  "Questionnaire sent successfully",
                  "success"
                );
              } else {
                window.showMessage(
                  "Questionnaire draft saved successfully.\n\rThe same has been available on Create Feedback Form section.",
                  "success"
                );
              }
              setLoading(false);
              setLoadingSend(false);

              if (response_status === 2) {
                setConfirmSaveAsTemplete(true);
              } else {
                //setTimeout(() => {
                props.close();
                //}, 1000);
              }
            } else {
              if (response.data.msg) {
                window.showMessage(response.data.msg, "warning");
              } else {
                let err = [];
                response.data["data"].map((v) => {
                  for (const property in v) {
                    err.push(v[property]);
                  }
                  return true;
                });
                //console.log("err ", err);
                window.showMessage("* " + err.join("\n\r* "), "warning");
              }
            }

            setLoading(false);
            setLoadingSend(false);
            window.hideLoader();
          })
          .catch(function (error) {
            window.hideLoader();
            console.log(error);
          });
      }
    }
  };
  const removeFile = (file, index) => {
    //let files = filesCollection;
    //console.log(files);
    let files = filesCollection.filter((item) => item.name !== file);
    // console.log(files);
    setFilesCollection([...files]);
  };
  const removeUploadedFile = (file, index) => {
    if (Object.keys(props.template_id).length !== 0) {
      let files = uploadedFiles.filter((item) => item !== file);
      setUploadedFiles([...files]);
      return true;
    } else {
      // console.log("removeUploadedFile ", file);
      if (
        props.isReshareAction &&
        uploadedFilesCopy.filter((item) => item === file).length
      ) {
        let files = uploadedFiles.filter((item) => item !== file);
        setUploadedFiles([...files]);
        return true;
      } else {
        let folderName = "ara";
        let subFolderName =
          dataSource +
          "/" +
          auditTitle.split("-")[0].trim() +
          "/AuditSections/StakeHolderFeedback/AuditQuestionnaire";
        subFolderName = "";
        axios
          .delete(
            `${
              rimaConstants.REACT_APP_API_URL
            }/api/v1/upload?folderName=${folderName}&subFolderName=${subFolderName}&fileName=${encodeURIComponent(
              file
            )}`
          )
          .then((response) => {
            let files = uploadedFiles.filter((item) => item !== file);
            setUploadedFiles([...files]);

            window.showMessage("Removed from the storage", "success");
          })
          .catch((error) => {
            //console.log(error);
          });
      }
    }
  };
  const processUpload = async (
    folderName,
    subFolderName,
    fileName,
    fileObject
  ) => {
    return new Promise((resolve) => {
      if (folderName && subFolderName && fileName && fileObject) {
        let req_obj = {};
        req_obj = new FormData();
        req_obj.append("upfile", fileObject);
        axios
          .post(
            `${
              rimaConstants.REACT_APP_API_URL
            }/api/v1/upload?folderName=${folderName}&subFolderName=${subFolderName}&fileName=${encodeURIComponent(
              fileName
            )}`,
            req_obj
          )
          .then((response) => {
            //console.log(response.data);
            resolve(response.data);
          })
          .catch((error) => {
            //console.log(error);
            resolve(error);
          });
      } else {
        resolve([]);
      }
    });
  };

  const doUpload = async () => {
    // //console.log('addAttachment doUpload');
    let folderName = "ara";
    let subFolderName = `${dataSource}/${auditTitle
      .split("-")[0]
      .trim()}/AuditSections/StakeHolderFeedback/AuditQuestionnaire/${
      user.user_id
    }/${moment().utc().unix()}`;
    //console.log('addAttachment ', subFolderName);
    let promises = [];
    filesCollection.map((document) => {
      // //console.log('addAttachment ', document);
      if (!uploadedFiles.includes(document["name"])) {
        promises.push(
          processUpload(
            folderName,
            subFolderName,
            document["name"],
            document["file"]
          )
        );
      }
      return true;
    });
    let upRes = await Promise.all(promises);
    let tmpArr = [];
    upRes.map((value) => {
      //console.log('tks ', value, value.status)
      if (value.status === "success") {
        tmpArr.push(`${subFolderName}/${value.fileName}`);
      }

      return true;
    });

    return tmpArr;
  };

  const handleOnSave = async () => {
    if (
      formData.length &&
      Object.keys(props.template_id).length !== 0 &&
      isDraftAvailable
    ) {
      setButtonParam("onsave");
      setConfirmExistingTemplete(true);
    } else {
      props.onSave();
      setLoading(true);
      //setLoadingSend(true);
      //console.log('handleOnSave ');
      let data = await doUpload();
      //console.log('handleOnSave doUpload');
      //console.log(data);
      saveData(data, 1);
    }
  };

  const handleSendClick = async () => {
    if (
      formData.length &&
      Object.keys(props.template_id).length !== 0 &&
      isDraftAvailable
    ) {
      setButtonParam("send");
      setConfirmExistingTemplete(true);
    } else {
      setLoadingSend(true);
      //setLoading(true);
      //console.log('handleSendClick ');
      let data = await doUpload();
      //console.log('handleSendClick doUpload');
      //console.log(data);
      setDraftAttachment(data);
      saveData(data, 2);
    }
  };

  const saveAsTemplete = async () => {
    let errCount = 0;
    if (!auditTitle) {
      errCount++;
    }
    if (!dataSource) {
      errCount++;
    }

    addMoreRow.map((obj) => {
      for (let key in obj) {
        if (!obj[key]) {
          errCount++;
        }
      }
      return true;
    });

    if (!sendFromTo) {
      errCount++;
    }

    if (!sendAutoReminder) {
      errCount++;
    }

    if (!maxReminder) {
      errCount++;
    }

    if (errCount <= 0) {
      setLoading(true);
      setLoadingSend(true);
      let tmpAttachments = [
        ...uploadedFiles,
        ...draftAttachment.map((x) => encodeURIComponent(x)),
      ];
      let data_source = breadcrums.vaccine ? "Vx" : "Rx";
      let payload = {
        audit_type_id: auditTypeId,
        is_enabled: 1,
        feedback_template_title: feedBackTemplateTitle,
        feedback_description: additionalComment,
        feedback_questions: addMoreRow,
        feedback_attachments: tmpAttachments,
        data_source: data_source,
        // using_feedbackform:
      };
      axios
        .post(
          `${rimaConstants.REACT_APP_API_URL}/api/v1/stakeholder_feedback/add_stakeholder_feedback_template`,
          payload
        )
        .then((response) => {
          if (response.data.status === "success") {
            window.showMessage(
              "Feedback template added successfully!",
              "success"
            );
            setLoading(false);

            props.loadTemplate();
            //setTimeout(() => {
            props.close();
            //}, 1000);
          } else {
            if (response.data.msg) {
              window.showMessage(response.data.msg, "warning");
            }
          }

          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const handleSaveAsTemplete = async () => {
    props.onSave();
    setTitleOpen(true);
  };

  const handleExistingTemplete = async () => {
    //setEdit(true);
    props.onSave();
    if (buttonParam === "save") saveAsTemplete();
    else if (buttonParam === "send") {
      setLoadingSend(true);

      let data = await doUpload();
      setDraftAttachment(data);
      saveData(data, 2);
    } else if (buttonParam === "onsave") {
      setLoading(true);

      let data = await doUpload();
      saveData(data, 1);
    }
  };

  const handleCancle = () => {
    setTitleOpen(false);
  };

  const updateExistingTemplate = async () => {
    let errCount = 0;
    if (!auditTitle) {
      errCount++;
    }
    if (!dataSource) {
      errCount++;
    }

    addMoreRow.map((obj) => {
      for (let key in obj) {
        if (!obj[key]) {
          errCount++;
        }
      }
      return true;
    });

    if (!sendFromTo) {
      errCount++;
    }

    if (!sendAutoReminder) {
      errCount++;
    }

    if (!maxReminder) {
      errCount++;
    }

    if (errCount <= 0) {
      setLoading(true);
      setLoadingSend(true);
      let tmpAttachments = [
        ...uploadedFiles,
        ...draftAttachment.map((x) => encodeURIComponent(x)),
      ];
      let data_source = breadcrums.vaccine ? "Vx" : "Rx";
      let payload = {
        audit_type_id: auditTypeId,
        is_enabled: 1,
        feedback_template_title: feedBackTemplateTitle,
        feedback_description: additionalComment,
        feedback_questions: addMoreRow,
        feedback_attachments: tmpAttachments,
        data_source: data_source,
      };
      axios
        .put(
          `${rimaConstants.REACT_APP_API_URL}/api/v1/stakeholder_feedback/update_stakeholder_feedback_template/${stakeholderFeedbackTemplateId}`,
          payload
        )
        .then((response) => {
          if (response.data.status === "success") {
            window.showMessage(
              "Feedback template updated successfully!",
              "success"
            );
            setLoading(false);

            props.loadTemplate();
            //setTimeout(() => {
            props.close();
            // }, 1000);
          } else {
            if (response.data.msg) {
              window.showMessage(response.data.msg, "warning");
            }
          }

          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const handleSave = () => {
    if (feedBackTemplateTitle.trim()) {
      if (
        formData.length &&
        Object.keys(props.template_id).length !== 0 &&
        isDraftAvailable
      ) {
        setButtonParam("save");
        setConfirmExistingTemplete(true);
      }
      if (isTemplateLoaded) {
        updateExistingTemplate();
      } else {
        saveAsTemplete();
      }
    } else {
      window.showMessage("Please enter feedback template title", "warning");
    }
  };

  const onkeyupHandler = (e) => {
    e.target.style.height = "1px";
    e.target.style.height = 5 + e.target.scrollHeight + "px";
  };

  const isEnabledAddNewQuestion = () => {
    let count = 0;
    addMoreRow.forEach((obj) => {
      if (obj[Object.keys(obj)[0]]) count++;
    });
    return count === addMoreRow.length;
  };

  return (
    <div className="apply-custom-btn">
      <DialogTitle sx={{ fontWeight: "bold", fontSize: "1.4rem" }}>
        {props.isReshareAction
          ? "Reshare Feedback Form"
          : "Create Feedback Form"}
        {props.template_id.feedback_template_title !== undefined
          ? "- " + props.template_id.feedback_template_title
          : ""}
      </DialogTitle>
      <DialogContent>
        <div className="modalContent">
          <Grid container>
            <Grid item xs={5}>
              <Box
                sx={{
                  height: 15,
                  p: 2,
                  mb: 2,
                  display: "flex",
                  // gridAutoFlow: "row",
                  // gridTemplateColumns: "repeat(2, 1fr)",
                  // gridTemplateRows: "repeat(2, 50px)",
                  // gap: 0,
                }}
              >
                <Item
                  sx={{
                    gridColumn: "1",
                    fontSize: "0.875rem",
                    fontWeight: "600",
                  }}
                >
                  Data Source :
                </Item>
                <Item
                  sx={{ fontSize: "0.875rem", fontWeight: "500", ml: "10px" }}
                >
                  {dataSource}
                </Item>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box
                sx={{
                  height: 15,
                  p: 2,
                  display: "flex",
                  //display: "grid",
                  // gridAutoFlow: "row",
                  // gridTemplateColumns: "repeat(2, 1fr)",
                  // gridTemplateRows: "none",
                  // gap: 2,
                }}
              >
                <Item
                  sx={{
                    gridColumn: "1",
                    fontSize: "0.875rem",
                    fontWeight: "600",
                  }}
                >
                  Audit :
                </Item>
                <Item
                  sx={{ fontSize: "0.875rem", fontWeight: "500", ml: "10px" }}
                >
                  {window.customEscape(auditTitle)}
                </Item>
              </Box>
            </Grid>
          </Grid>
          <Grid container style={{ display: "none" }}>
            <Grid item xs={6}>
              <FormControl sx={{ mt: 3 }}>
                <InputLabel id="demo-simple-select-label">Language</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={language}
                  label="Language"
                  onChange={handleLanguageChange}
                  sx={{
                    height: "55px",
                    width: "29ch !important",
                    ml: "0px !important",
                  }}
                >
                  {Object.keys(window.translatorLanguageSupport()).map(
                    (key) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {window.translatorLanguageSupport()[key]}
                        </MenuItem>
                      );
                    }
                  )}
                  {/*<MenuItem value="zh-hk">Chinese (Hong Kong)</MenuItem>*/}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  paddingTop: "25px",
                }}
              >
                <FormControlLabel
                  sx={{
                    display: "none",
                  }}
                  control={
                    <Switch
                      checked={loadingTranslate}
                      onChange={() => setLoadingTranslate(!loadingTranslate)}
                      name="loading"
                      color="primary"
                    />
                  }
                  label="Loading"
                />
                <Box sx={{ "& > button": { m: 1 } }}>
                  <LoadingButton
                    onClick={handleTranslate}
                    endIcon={<SubtitlesIcon />}
                    loading={loadingTranslate}
                    loadingPosition="end"
                    variant="contained"
                    sx={{ textTransform: "none" }}
                  >
                    Translate
                  </LoadingButton>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <FormControl sx={{ width: "100%" }}>
            {addMoreRow.map((input, index) => {
              let inputVal = "";
              for (let key in input) inputVal = input[key];

              return (
                <div>
                  <Stack direction="row" spacing={2}>
                    <TextField
                      fullWidth
                      multiline
                      label={"Question " + (index + 1)}
                      id="fullWidth"
                      margin="normal"
                      onChange={(event) => handleFormChange(index, event)}
                      value={inputVal}
                      InputProps={{
                        style: { width: index > 1 ? "98%" : "90%" },
                        maxLength: 80,
                      }}
                      onKeyUp={onkeyupHandler}
                    />

                    {index === 2 && addMoreRow.length === 3 ? (
                      <IconButton
                        aria-label="add"
                        size="large"
                        sx={{ pt: 3, mr: "-25px" }}
                        onClick={() => addMoreRows(index + 1)}
                        disabled={!isEnabledAddNewQuestion()}
                      >
                        <AddCircleRoundedIcon />
                      </IconButton>
                    ) : addMoreRow.length === index + 1 ? (
                      <div>
                        <IconButton
                          aria-label="add"
                          size="large"
                          //sx={{ pt: 3 }}
                          disabled={!isEnabledAddNewQuestion()}
                          ref={(node) => {
                            if (node) {
                              node.style.setProperty(
                                "margin",
                                "5px 0px 0px 0px",
                                "important"
                              );
                            }
                          }}
                          onClick={() => addMoreRows(index + 1)}
                        >
                          <AddCircleRoundedIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          size="large"
                          //sx={{ pt: 1, mr: "-42px" }}
                          ref={(node) => {
                            if (node) {
                              node.style.setProperty(
                                "margin",
                                "-21px -41px 0px 0px",
                                "important"
                              );
                            }
                          }}
                          onClick={() => deleteMoreRows(index)}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </div>
                    ) : index > 1 ? (
                      <IconButton
                        aria-label="delete"
                        size="large"
                        sx={{
                          pt: 1,
                          mr: "-25px",
                          visibility: index === 2 ? "hidden" : "visible",
                        }}
                        ref={(node) => {
                          if (node) {
                            node.style.setProperty(
                              "background",
                              "transparent",
                              "important"
                            );
                          }
                        }}
                        onClick={() => deleteMoreRows(index)}
                        disabled={index === 2 ? true : false}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <span id={`question${index}`}></span>
                  </Stack>
                  <FormHelperText
                    id="outlined-que-helper-text"
                    className="help-text"
                  >
                    {inputVal.length} characters (max characters: 200)
                  </FormHelperText>
                </div>
              );
            })}
          </FormControl>

          <FormControl sx={{ width: "96%" }}>
            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                multiline
                label="General comments"
                id="fullWidth"
                margin="normal"
                style={{ display: queForm ? "block" : "none" }}
                value={additionalComment}
                onChange={handleAdditionalComment}
                onKeyUp={onkeyupHandler}
              />
              <input
                style={{ display: "none" }}
                id={"contained-button-file"}
                type="file"
                multiple
                onChange={(event) => addAttachment(event)}
              />
              <label htmlFor={"contained-button-file"}>
                <IconButton
                  component="span"
                  variant="contained"
                  aria-label="upload"
                  size="large"
                  sx={{ pt: 5, mr: "-30px", ml: "12px" }}
                >
                  <AttachmentIcon />
                </IconButton>
              </label>
            </Stack>
          </FormControl>
          <FormHelperText
            id="outlined-comment-helper-text"
            className="help-text"
          >
            {additionalComment !== undefined ? additionalComment.length : "0"}{" "}
            characters (max characters: 500)
          </FormHelperText>
          <div className="tags-container">
            <ul>
              {uploadedFiles.map((file, i) => {
                return (
                  <li key={window.getUuidv4()}>
                    {file ? file.split("/")[file.split("/").length - 1] : ""}
                    <span
                      sx={{ ml: 2 }}
                      onClick={() => removeUploadedFile(file, i)}
                    >
                      <IconButton
                        component="span"
                        variant="contained"
                        aria-label="close"
                        size="small"
                      >
                        <CancelRoundedIcon />
                      </IconButton>
                    </span>
                  </li>
                );
              })}
              {filesCollection.map((file, i) => {
                return (
                  <li key={window.getUuidv4()}>
                    {file.name
                      ? file.name.split("/")[file.name.split("/").length - 1]
                      : ""}
                    <span
                      sx={{ ml: 2 }}
                      onClick={() => removeFile(file.name, i)}
                    >
                      <IconButton
                        component="span"
                        variant="contained"
                        aria-label="close"
                        size="small"
                      >
                        <CancelRoundedIcon />
                      </IconButton>
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* {
              filesCollection.map((filedata) => {
                //console.log(filedata);
                //console.log("------------------------")
                return (<div><span>{filedata.name}, </span></div>)
              })
            } */}

          <Grid container>
            <Grid item xs={12}>
              <FormControl sx={{ width: "90%", pt: 2 }}>
                {/* <TextField
                  fullWidth
                  label="Send form to"
                  id="fullWidth"
                  margin="normal"
                  placeholder="abc@mail.com"
                /> */}
                <AutocompleteInput1
                  lable="Send form to"
                  value={sendFromTo}
                  setParentValues={setSendFromTo}
                  multiple={true}
                  role={30}
                  placeholder="Send form to"
                  width="97%"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container sx={{ pt: 2 }}>
            <Grid item xs={6}>
              <FormControl sx={{ mt: 2 }}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Send auto reminder
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="7"
                    control={<Radio />}
                    label="7 days"
                    checked={sendAutoReminder === "7" ? true : false}
                    onChange={handleRadio}
                  />
                  <FormControlLabel
                    value="14"
                    control={<Radio />}
                    label="14 days"
                    onChange={handleRadio}
                    checked={sendAutoReminder === "14" ? true : false}
                  />
                  <FormControlLabel
                    value="21"
                    control={<Radio />}
                    label="21 days"
                    onChange={handleRadio}
                    checked={sendAutoReminder === "21" ? true : false}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Off"
                    onChange={handleRadio}
                    checked={sendAutoReminder === "0" ? true : false}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                sx={{
                  mt: 3,
                  visibility: sendAutoReminder === "0" ? "hidden" : "visible",
                }}
              >
                <InputLabel id="demo-simple-select-label">
                  Max Reminders
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={maxReminder}
                  label="Max Reminders"
                  onChange={handleMaxReminder}
                  sx={{
                    height: "55px",
                    width: "25ch !important",
                    ml: "0px !important",
                  }}
                  // disabled={sendAutoReminder === "0" ? true : false}
                >
                  <MenuItem key={2} value="2">
                    2
                  </MenuItem>
                  <MenuItem key={4} value="4">
                    4
                  </MenuItem>
                  <MenuItem key={6} value="6">
                    6
                  </MenuItem>
                  <MenuItem key={8} value="8">
                    8
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Box sx={{ mt: 1, mb: 2, ml: 1, mr: 5 }}>
          {/* <ReactTooltip place="right" /> */}
          <Tooltips
            html={false}
            iconColor="#15717D"
            placement="top-end"
            title="Questionnaire can't be edited after submission"
          />
        </Box>
        <Button
          variant="contained"
          onClick={handleClose}
          sx={{ mr: 1, textTransform: "none" }}
        >
          Close
        </Button>
        {/* <Button
          variant="contained"
          sx={{ textTransform: "capitalize" }}
          onClick={handleSaveAsTemplete}
        >
          Save As Templete
        </Button> */}
        <Box>
          <FormControlLabel
            sx={{
              display: "none",
            }}
            control={
              <Switch
                checked={loadingSend}
                onChange={() => setLoadingSend(!loadingSend)}
                name="loading"
                color="primary"
              />
            }
            label="Loading"
          />
          <Box sx={{ "& > button": { m: 1 } }}>
            <LoadingButton
              onClick={handleSendClick}
              endIcon={<SendIcon />}
              loading={loadingSend}
              loadingPosition="end"
              variant="contained"
              sx={{ textTransform: "capitalize" }}
              disabled={loading}
            >
              Send
            </LoadingButton>
          </Box>
        </Box>
        {!props.isReshareAction && (
          <Box>
            <FormControlLabel
              sx={{
                display: "none",
              }}
              control={
                <Switch
                  checked={loading}
                  onChange={() => setLoading(!loading)}
                  name="loading"
                  color="primary"
                />
              }
              label="Loading"
            />
            <Box sx={{ "& > button": { m: 1 } }}>
              <LoadingButton
                // color="secondary"
                onClick={handleOnSave}
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                type="submit"
                sx={{ textTransform: "capitalize" }}
                disabled={loadingSend}
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
        )}
      </DialogActions>
      <div>
        <ConfirmDialog
          title="Save Feedback Template"
          open={confirmSaveAsTemplete}
          setOpen={setConfirmSaveAsTemplete}
          onConfirm={() => handleSaveAsTemplete()}
          handleNo={() => handleClose()}
        >
          {isTemplateLoaded
            ? "Do you want to overwrite the Template?"
            : "Are you sure you want to save Template?"}
        </ConfirmDialog>
        <ConfirmDialog
          title="Feedback Template"
          open={confirmExistingTemplete}
          setOpen={setConfirmExistingTemplete}
          onConfirm={() => handleExistingTemplete()}
          handleNo={() => handleClose()}
        >
          A draft questionnaire is already present, do you want to discard the
          draft and proceed with the template?
        </ConfirmDialog>
        <Dialog fullScreen={fullScreen} open={titleOpen} onClose={handleClose}>
          <DialogTitle>Save Feedback Template</DialogTitle>
          <DialogContent sx={{ width: "500px" }}>
            {/* <DialogContentText>Please enter remarks</DialogContentText> */}
            <TextField
              rows={2}
              maxRows={4}
              autoFocus
              margin="dense"
              id="name"
              label="Please enter feedback template title (40 characters)"
              fullWidth
              variant="standard"
              value={feedBackTemplateTitle}
              inputProps={{ maxLength: 40 }}
              onChange={(event) => {
                setFeedBackTemplateTitle(event.target.value);
              }}
            />
            {/* <span style={{ fontWeight: "bold", fontSize: "11px" }}>
              (Character limit 200)
            </span> */}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{ textTransform: "capitalize" }}
              onClick={handleCancle}
            >
              Cancel
            </Button>

            {/* <Button sx={{ textTransform: "capitalize" }} onClick={handleSave}>
              Save
            </Button> */}
            <Box>
              <FormControlLabel
                sx={{
                  display: "none",
                }}
                control={
                  <Switch
                    checked={loading}
                    onChange={() => setLoading(!loading)}
                    name="loading"
                    color="primary"
                  />
                }
                label="Loading"
              />
              <Box sx={{ "& > button": { m: 1 } }}>
                <LoadingButton
                  // color="secondary"
                  onClick={handleSave}
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  type="submit"
                  sx={{ textTransform: "capitalize" }}
                >
                  Save
                </LoadingButton>
              </Box>
            </Box>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
