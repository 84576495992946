import React from "react";
import Header from "../HomePage/Header";
import Footer from "../Footer/Footer";
import "./invalidUser.css";
import Stack from "@mui/material/Stack";
// import MuiAlert from "@mui/material/Alert";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import { useMsal } from "@azure/msal-react";

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

function InvalidUser() {
  const { instance, accounts } = useMsal();
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef(null);
  React.useEffect(() => {
    setHeight(window.innerHeight - 95);
  }, []);
  const headUnauthorized = () => {
    return (
      <>
        <Typography variant="h6" gutterBottom align="center">
          Hi <span style={{ color: "#02709f" }}> {accounts[0].name}</span>,
        </Typography>
      </>
    );
  };

  const msgUnauthorized = () => {
    return (
      <>
        <Typography variant="h7" gutterBottom>
          The system identified you are unregistered user to access RIMA
          features. <br /> Please contact{" "}
          <strong style={{ color: "rgb(243, 102, 51)" }}>
            rima-admin@gsk.com
          </strong>{" "}
          {/* or raise request using{" "}
          <strong style={{ color: "rgb(243, 102, 51)" }}>
            (myapps.gsk.com/rima)
          </strong>{" "}
          to get access. */}
        </Typography>
      </>
    );
  };

  const headMaintainanceMode = () => {
    return (
      <>
        <Typography variant="h6" gutterBottom align="center">
          System in maintenance mode, please visit after sometime.
        </Typography>
      </>
    );
  };

  const msgMaintainanceMode = () => {
    return (
      <>
        <Typography variant="h7" gutterBottom>
          You can also share your feedback to{" "}
          <strong style={{ color: "rgb(243, 102, 51)" }}>
            rima-support@gsk.com
          </strong>{" "}
          if you see this problem persisting.
        </Typography>
      </>
    );
  };

  const [heading, setHeading] = React.useState("");
  const [msg, setMsg] = React.useState("");

  React.useEffect(() => {
    const type = window.parseQueryString(window.location.href, "type");
    switch (type) {
      case 1:
      case "1":
        setHeading(headMaintainanceMode());
        setMsg(msgMaintainanceMode());
        break;
      default:
        setHeading(headUnauthorized());
        setMsg(msgUnauthorized());
        break;
    }
  }, []);

  return (
    <>
      <div ref={ref} style={{ minHeight: height }}>
        <Header hideUserInfo={true} />
        <div className="container-fluid-invaliduser">
          <div className="container-main-invaliduser">
            <div className="css-1pqbxu3-invaliduser">
              {/* <Typography variant="h6" gutterBottom align="center">
              Unauthorized Access
            </Typography> */}
              {heading}
              <Stack severity="info" spacing={2} sx={{ maxWidth: 800, mt: 3 }}>
                <Alert
                  severity="warning"
                  sx={{
                    "& .MuiSvgIcon-root": { color: "#FFA623" },
                  }}
                >
                  {/* <Typography variant="h7" gutterBottom>                  
                  You are not authorized to access RIMA. For assistance, please
                  contact RIMA Administrator.
                </Typography> */}
                  {msg}
                </Alert>
              </Stack>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default InvalidUser;
