import React, { useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import { UserDataContext } from "../Context/UserDataContext";
import moment from "moment";
import "./Classify.css";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
// import uniDirectionalArrow from '../../assets/images/u915.png';
import AddtaggsModal from "./AddtaggsModal";

import Path from "path";
import uploadFileToBlob from "./azureBlob";
import PDFViewer from "../Pdfview/PDFViewer";
import { saveAs } from "file-saver";

import SortingSelectingTable from "./../SortingSelectingTable/SortingSelectingTable";
import {
  BlobServiceClient,
  BlobItem,
  StorageSharedKeyCredential,
  BlobClient,
  BlockBlobClient ,
} from "@azure/storage-blob";
import * as rimaConstants from "../../../src/rimaconstants";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../applicationInsights/AppInsights';

const axios = require("axios").default;
const mime = require("mime-types");

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const storageConfigured = true;

const allowedFileTypes =
  "application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation"; //application/rtf,application/vnd.oasis.opendocument.presentation,application/vnd.oasis.opendocument.spreadsheet,application/vnd.oasis.opendocument.text

const Classify = () => {
  // all blobs in container
  const [blobList, setBlobList] = useState([]);
  const [docs, setDocs] = useState("");
  const [disableTranslateBtn, setDisableTranslateBtn] = React.useState(true);

  // current file to upload into container
  const [fileSelected, setFileSelected] = useState(null);

  // UI/form management
  const [uploading, setUploading] = useState(false);
  const [inputKey, setInputKey] = useState(Math.random().toString(36));

  // Translation Msg
  const [translateMsg, setTranslateMsg] = useState("");
  const [translatedDocs, setTranslatedDocs] = useState([]);
  const [translateCaption, setTranslateCaption] = useState("Translate");
  const [translateLoading, setTranslateLoading] = useState(false);
  const [readyForTranslation, setReadyForTranslation] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(true);
  const [
    adminUser,
    setAdminUser,
    user,
    setUser,
    userSASToken,
    setUserSASToken,
  ] = useContext(UserDataContext);
  const [activeContent, setActiveContent] = useState("translateFile");

  useEffect(() => {
    if (uploading) setDocs("");
  }, [uploading]);

  const onFileChange = (event) => {
    // capture file into state
    if (allowedFileTypes.split(",").includes(event.target.files[0].type)) {
      setFileSelected(event.target.files[0]);
      setFileUploaded(false);
      setTranslatedDocs([]);
    } else {
      toast.warn(
        "Unsupported file format. Only MS-Word, PDF, MS-Excel and MS-Powerpoint are supported."
      );
      setFileSelected("");
      setFileUploaded(true);
      setTranslatedDocs([]);
      return false;
    }
  };

  const onFileUpload = async () => {
    // prepare UI
    setUploading(true);

    // *** UPLOAD TO AZURE STORAGE ***
    const filepath = await uploadFileToBlob(
      fileSelected,
      userSASToken.storageAccount,
      userSASToken.storageAccessToken,
      user
    );

    setFileUploaded(true);
    setReadyForTranslation(true);

    setDocs(filepath);

    // prepare UI for results
    //setBlobList(blobsInContainer);
    //console.log(blobList, docs);
    // reset state/form
    // setFileSelected(null);
    setUploading(false);
    setInputKey(Math.random().toString(36));
  };

  // display form
  const DisplayForm = () => (
    <div>
      <input
        type="file"
        onChange={onFileChange}
        key={inputKey || ""}
        accept={allowedFileTypes}
      />
      <button
        variant="contained"
        className="gsk-btn primary pill"
        type="submit"
        onClick={onFileUpload}
        disabled={fileUploaded}
      >
        Upload
      </button>
    </div>
  );

  
  // translate functionality
  const tanslateHandler = async () => {
    window.showLoader();
    setReadyForTranslation(false);
    //setTranslatedDocs([{url:""}]);
    setTranslateCaption("Translating...");
    let translatedFileName = `${fileSelected.name}`;

    const tdata = {
      fileName: encodeURIComponent(docs.split("rima-uploads")[1].split("?")[0].substring(1)),
      lang: destLanguageFile,
    };

    await axios
      .post(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/translator/translatedocument`,
        tdata
      )
      .then(async (res) => {
        //console.log(res);
        const blobClient = new BlobClient(res.data.URL);
        //const blobClientProps = await blobClient.getProperties()
        var blobExists = false;
        var retryCounter = 0;
        const retryCounterLimit = 60;
        console.log("Blob existing check - waiting");
        do {
          await new Promise((resolve) => setTimeout(resolve, 5000));
          blobExists = await blobClient.exists();
          retryCounter = retryCounter + 1;
          console.log(
            !blobExists,
            retryCounter < retryCounterLimit,
            !blobExists && retryCounter < retryCounterLimit,
            retryCounter,
            " - Retry"
          );
        } while (!blobExists && retryCounter < retryCounterLimit);

        if (retryCounter >= retryCounterLimit) {
          console.log(
            "Translation taking too long. Check source file.\nTranslation aborted! "
          );
          alert(
            "Translation taking too long. Check source file.\nTranslation aborted! "
          );
        }

        if (blobExists) {
          console.log("Blob created...");

          setTranslateCaption("Translate");
          const blobProps = await blobClient.getProperties();
          const blobheaders = {};
          const contentType = mime.lookup(translatedFileName);
          // console.log(
          //   "File : " +
          //     translatedFileName +
          //     " ====== File cotent type: " +
          //     contentType
          // );
          blobheaders.blobContentType = contentType;
          blobheaders.blobContentLanguage = blobProps.ContentLanguage;
          blobheaders.blobCacheControl = "no-cache, no-store"; //blobProps.CacheControl;
          blobheaders.blobContentDisposition = blobProps.ContentDisposition;
          blobheaders.blobContentEncoding = blobProps.ContentEncoding;
          blobheaders.blobContentMD5 = blobProps.ContentHash;
          var result = await blobClient.setHTTPHeaders(blobheaders);
          //console.log("Result : ", result);

          //console.log(blobClient, blobProps);
          //Rename the blob
          const filename = res.data.URL.split("?")[0].split("/").pop();//24/filename
          const targetfile = `${decodeURIComponent(filename).split("/")[0]}/${languagesList?.translation[destLanguageFile].name}-${decodeURIComponent(filename).split("/")[1].replace(".",`[${moment().format('DD-MMM-YY hh:mm:ss A')}].`)}`
          const renamedFileURI = res.data.URL.replace(filename, targetfile) 
          const newBlobClient = new BlobClient(renamedFileURI);
          var properties  = null;
          // Copy the file to the new name
          do{
            await newBlobClient.syncCopyFromURL(res.data.URL,
              {
                blobAccessConditions: {
                  httpAccessConditions: {
                    protocol: "https",
                    ifModifiedSince: new Date(Date.UTC(2019, 0, 1))
                  }
                }
              })
            // Get the blob properties to retrieve the size
            properties = await newBlobClient.getProperties();
            console.log(`The size of copy is ${properties.contentLength} bytes. If 0 retry!`);
          }while (properties.contentLength === 0)
          await newBlobClient.setHTTPHeaders(blobheaders);
            
          //Once file copy is done, delete the original
          blobClient.deleteIfExists().then(async () => {
            console.log(`File "${filename}" renamed to "${renamedFileURI}" successfully.`);
            

            setTranslatedDocs([
              {
                uri: renamedFileURI,
              },
            ]);
          }).catch((error) => {
            console.log(error.message);
            toast.warn(
               "Failed during tanslation process. Please retry."
            );
          });

        } else{
          toast.warn(
             "Failed during tanslation process. Please retry."
          );
          console.log("Blob not created...");
        } 
        setTranslateCaption("Translate");
        window.hideLoader();
      })
      .catch(function (error) {
        window.hideLoader();
        console.log(error);
        toast.warn(
          "Failed during tanslation process. Please retry."
        );
      });
    setTranslateCaption("Translate");
    setReadyForTranslation(true);
  };

  const handleExport = () => {
    if (translatedDocs.length === 0) return;

    const url = translatedDocs[0].uri;
    const filename = fileSelected.name;

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, filename);

        // const link = document.createElement("a");
        // link.href = URL.createObjectURL(blob);
        // link.download = filename;
        // link.click();
      })
      .catch(console.error);
  };

  const ContentHandleChange = (event, activeTab) => {
    if (activeTab)
    setActiveContent(activeTab);
  };

  const [inputText, setInputText] = useState("");
  const [targetText, setTargetText] = useState("");

  const [detectLanguageKey, setdetectedLanguageKey] = useState("");
  const [languagesList, setLanguagesList] = useState({});
  const [sourceLanguageList, setSourceLanguageList] = useState([]);
  const [
    destinationLanguageTextList,
    setDestinationLanguageTextList,
  ] = useState([]);
  const [
    destinationLanguageFileList,
    setDestinationLanguageFileList,
  ] = useState([]);
  const [sourceLanguageText, setSourceLanguageText] = useState("en");
  const [sourceLanguageFile, setSourceLanguageFile] = useState("en");
  const [destLanguageText, setDestLanguageText] = useState("en");
  const [destLanguageFile, setDestLanguageFile] = useState("en");

  const [useAzureList, setUseAzureList] = useState(true);
  useEffect(() => {
    //console.log(`${rimaConstants}`);
    axios
      .get(`${rimaConstants.TEXT_TRANSLATOR_URI}/Languages?api-version=3.0`)
      .then((response) => {
        setLanguagesList(response.data);
        //console.log(response);
        const topLangs = [
          "en",
          "fr",
          "de",
          "lzh",
          "zh-Hans",
          "ja",
          "it",
          "id",
          "ms",
        ];
        let langlist = Object.entries(response.data.translation).map(
          ([id, values]) => ({
            id: id,
            label: values.name,
            top: topLangs.includes(id),
          })
        );

        setSourceLanguageList(langlist);
        setDestinationLanguageTextList(langlist);
        setDestinationLanguageFileList(langlist);
        //setSourceLanguageText("en");
        //setSourceLanguageFile("en");
      });
  }, [useAzureList]);

  // useEffect(() => {
  //   if (languagesList.dictionary && sourceLanguageText.length == 2) {
  //     console.log(
  //       sourceLanguageText,
  //       languagesList.dictionary[sourceLanguageText]?.translations
  //     );
  //     setDestinationLanguageTextList(
  //       languagesList.dictionary[sourceLanguageText]?.translations?languagesList.dictionary[sourceLanguageText]?.translations:[]
  //     );
  //   }
  // }, [sourceLanguageText]);

  // useEffect(() => {
  //   if (languagesList.dictionary)
  //     setDestinationLanguageFileList(
  //       languagesList.dictionary[sourceLanguageFile].translations
  //     );
  // }, [sourceLanguageFile]);

  const detectLanguage = (text) => {
    const body = [
      {"Text":text}
  ];
    const header  = {
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': rimaConstants.TRANSLATOR_TEXT_RESOURCE_KEY,
        'Ocp-Apim-Subscription-Region': 'eastus2'
      },
      
    }
    const url = `${rimaConstants.TEXT_TRANSLATOR_URI}detect?api-version=3.0`
    const uninterceptedAxiosInstance = axios.create();
    uninterceptedAxiosInstance
    .post(url,body, header)
    .then(async (res) => {
     // console.log(res)
      if (res && res.data && res.data.length > 0)
        setSourceLanguageText(res.data[0].language)
      else console.log("Detection failed.")
    })
    .catch(e=>{
      console.log(e)
    })

  };

  const tanslateTextHandler = (text, from, to) => {
    window.showLoader();
    const body = [
      {"Text":text}
  ];
    const header  = {
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': rimaConstants.TRANSLATOR_TEXT_RESOURCE_KEY,
        'Ocp-Apim-Subscription-Region': 'eastus2'
      },
      
    }
    const url = `${rimaConstants.TEXT_TRANSLATOR_URI}translate?api-version=3.0&from=${from}&to=${to}`
    const uninterceptedAxiosInstance = axios.create();
    uninterceptedAxiosInstance
    .post(url,body, header)
    .then(async (res) => {
      window.hideLoader();
      console.log(res)
      if (res && res.data && res.data.length > 0)
        setTargetText(res.data[0].translations[0].text)
      else console.log("Translation failed.")
    })
    .catch(e=>{
      window.hideLoader();
      console.log(e)
    })

  };

  
  let inputRef;
  return (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{ paddingTop: "20px", paddingBottom: "20px" }}
      >
        <Box
          sx={{
            m: "15px",
            textAlign: "center",
            minWidth: "800px",
            margin: "0 auto",
          }}
        >
          <ToggleButtonGroup
            fullWidth
            //   color="#15717d"
            value={activeContent}
            exclusive
            size="small"
            onChange={ContentHandleChange}
          >
            <ToggleButton
              className={
                activeContent === "translateFile" 
                  ? "gsk-btn primary"
                  : "gsk-btn"
              }
              value="translateFile"
              sx={{ minWidth: "400px" }}
              // onClick="resultHandlerChange"
            >
              Translate Documents
            </ToggleButton>
            <ToggleButton
              className={
                activeContent === "translateText" 
                  ? "gsk-btn primary"
                  : "gsk-btn"
              }
              value="translateText"
              sx={{ minWidth: "400px" }}
            >
              Translate Text
              {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Audit Draft Text&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Grid>

      {activeContent === "translateFile" || activeContent === null ? (
        <div>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              style={{
                borderBottom: "1px solid gray",
                borderBottomStyle: "dashed",
              }}
              container
              spacing={2}
            >
              <Grid
                style={{
                  borderBottom: "1px solid gray",
                  borderBottomStyle: "dashed",
                  paddingBottom: "20px",
                }}
                item
                xs={12}
              >
                <h2 className="clsy-title">
                  <span className="round-bx">1</span>Scan Document
                </h2>
                <Item className="sdocBx">
                  <Typography variant="p" component="div" mt="10px">
                    Select a document for translation.
                    <br />
                    <br />
                  </Typography>
                  {/* <input type="file" onChange={onFileChange} key={""}/> */}

                  {storageConfigured && !uploading && DisplayForm()}
                  {storageConfigured && uploading && <div>Uploading</div>}
                </Item>
              </Grid>
              <Grid className="tdocBx" item xs={12}>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <h2 className="clsy-title" style={{ width: "100%" }}>
                    <span className="round-bx">2</span>Translate Document
                    <ReactTooltip />
                    <InfoOutlinedIcon
                      fontSize="tiny"
                      data-html={true}
                      data-tip="Source document language is auto-detected."
                    ></InfoOutlinedIcon>
                  </h2>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h6" component="div" mt="4px" mr="4px">Target:</Typography> 
                    <Autocomplete
                      size="small"
                      disablePortal
                      groupBy={(option) => option.top}
                      value = {(languagesList?.translation && languagesList?.translation[destLanguageFile])?languagesList?.translation[destLanguageFile].name:"English"}
                      //multiple
                      id="file-target-language-combo"
                      //freeSolo
                      clearOnEscape="false"
                      options={sourceLanguageList}
                      sx={{ minWidth: 250, width: "100%", mr: 2 }}
                      onChange={(e, item) => {
                        if (item) setDestLanguageFile(item.id);
                      }}
                      // onInputChange={(event, item) => {
                      //   if ( item.length > 0 && event &&
                      //     (event.type === "change" || event.type === "click")
                      //   ) {
                      //     setSourceLanguageText(item?.id);
                      //   }
                      // }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select target language from drop down list or type text"
                          inputRef={(input) => {
                            inputRef = input;
                          }}
                        />
                      )}
                    />
                  <LoadingButton
                    sx={{ alignSelf: "flex-end" }}
                    loading={translateLoading}
                    loadingPosition="end"
                    id="tanslateHandler"
                    className="gsk-btn primary pill"
                    onClick={tanslateHandler}
                    disabled={!readyForTranslation}
                  >
                    {translateCaption}
                  </LoadingButton>

                  </Box>


                </Box>

                <Grid
                  style={{ marginTop: "0px", marginBottom: "20px" }}
                  container
                  spacing={2}
                >
                  <Grid item xs={6}>
                    <PDFViewer url={docs}></PDFViewer>
                  </Grid>
                  <Grid item xs={6}>
                    {/* <textarea rows="10" cols="50">
                    
                      </textarea> */}
                    <Box
                      sx={{
                        backgroundColor: "#eeeeee",
                        minHeight: "200px",
                      }}
                    >
                      <Typography variant="h6">{translateMsg}</Typography>

                      <PDFViewer
                        url={
                          translatedDocs[0] && translatedDocs[0].uri
                            ? translatedDocs[0].uri
                            : ""
                        }
                      ></PDFViewer>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Button
            style={{ width: "100px", float: "right", marginTop: "10px" }}
            className="gsk-btn primary pill"
            variant="contained"
            fullWidth
            onClick={handleExport}
            disabled={translatedDocs.length === 0}
          >
            Export
          </Button>
        </div>
      ) : (
        <div>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              style={{ marginTop: "0px", marginBottom: "20px" }}
              container
              spacing={2}
            >
              <Grid item xs={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Autocomplete
                    size="small"
                    disablePortal
                    groupBy={(option) => option.top}
                    //multiple
                    id="source-language-combo"
                    value = {languagesList?.translation[sourceLanguageText].name}
                    //freeSolo
                    clearOnEscape="false"
                    options={sourceLanguageList}
                    sx={{ minWidth: 250, width: "100%", mr: 2 }}
                    onChange={(e, item) => {
                      if (item) setSourceLanguageText(item.id);
                    }}
                    // onInputChange={(event, item) => {
                    //   if ( item.length > 0 && event &&
                    //     (event.type === "change" || event.type === "click")
                    //   ) {
                    //     setSourceLanguageText(item?.id);
                    //   }
                    // }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select source language from drop down list or type text"
                        inputRef={(input) => {
                          inputRef = input;
                        }}
                      />
                    )}
                  />
                  <LoadingButton
                    sx={{ alignSelf: "flex-end" }}
                    loading={translateLoading}
                    loadingPosition="end"
                    id="tanslateHandler"
                    className="gsk-btn primary pill"
                    onClick={()=>{detectLanguage(inputText)}}
                  >
                    DETECT
                  </LoadingButton>
                </Box>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  id="outlined-textarea"
                  label="Source Text"
                  multiline
                  minRows={10}
                  maxRows={10}
                  value={inputText}
                  onChange={(event) => {
                    setInputText(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    backgroundColor: "#eeeeee",
                    minHeight: "200px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Autocomplete
                      size="small"
                      disablePortal
                      groupBy={(option) => option.top}
                      value = {languagesList?.translation[destLanguageText].name}
                      //multiple
                      id="target-language-combo"
                      //freeSolo
                      clearOnEscape="false"
                      options={sourceLanguageList}
                      sx={{ minWidth: 250, width: "100%", mr: 2 }}
                      onChange={(e, item) => {
                        if (item) setDestLanguageText(item.id);
                      }}
                      // onInputChange={(event, item) => {
                      //   if ( item.length > 0 && event &&
                      //     (event.type === "change" || event.type === "click")
                      //   ) {
                      //     setSourceLanguageText(item?.id);
                      //   }
                      // }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select target language from drop down list or type text"
                          inputRef={(input) => {
                            inputRef = input;
                          }}
                        />
                      )}
                    />

                    <LoadingButton
                      sx={{ alignSelf: "flex-end" }}
                      loading={translateLoading}
                      loadingPosition="end"
                      id="tanslateHandler"
                      className="gsk-btn primary pill"
                      onClick={()=>{tanslateTextHandler(inputText,sourceLanguageText,destLanguageText )}}
                    >
                      Translate
                    </LoadingButton>
                  </Box>

                  <Typography variant="h6">{translateMsg}</Typography>
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    id="outlined-textarea"
                    label="Translated Text"
                    multiline
                    minRows={10}
                    maxRows={10}
                    editable={false}
                    value={targetText}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
      )}
    </Box>
  );
};

// export default Classify;
export default withAITracking(reactPlugin, Classify);

