import React, { useState, useEffect } from "react";
// import tips from './tips';
// import {sortAs} from '../src/Utilities';
import TableRenderers from "./TableRenderers";
import FormControl from "@mui/material/FormControl";
import CustomDateRangeInputs from "../../ara/filters/daterange";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import createPlotlyComponent from "react-plotly.js/factory";
import createPlotlyRenderers from "./PlotlyRenderers";
import PivotTableUI from "./PivotTableUI";
// import { PivotData } from './Utilities';
import "./pivottable.css";
import Papa from "papaparse";
import GenExcel from "../../DataTable/GenExcel";

const Plot = createPlotlyComponent(window.Plotly);

class PivotTableUISmartWrapper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { pivotState: props, pivotTableUIConfig: props };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ pivotState: nextProps });
  }

  render() {
    return (
      <div>
        <PivotTableUI
          renderers={Object.assign(
            {},
            TableRenderers,
            createPlotlyRenderers(Plot)
          )}
          {...this.state.pivotState}
          onChange={(s) => this.setState({ pivotState: s })}
          unusedOrientationCutoff={Infinity}
        />
      </div>
    );
  }
}

export default class Pivot extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pivotState: props.data, filename: props.filename };
  }

  componentWillMount() {
    this.setState({
      mode: "demo",
      filename: this.props.filename,
      pivotState: {
        data: this.props.data,
      },
    });
  }

  // componentDidUpdate(prevProps) {
  //     if(prevProps.data !== this.props.data)
  //     this.setState({
  //         mode: 'demo',
  //         filename: this.props.filename ,
  //         pivotState: {
  //           data : this.props.data
  //         },
  //     });

  // }

  onDrop(files) {
    this.setState(
      {
        mode: "thinking",
        filename: "(Parsing CSV...)",
        textarea: "",
        pivotState: { data: [] },
      },
      () =>
        Papa.parse(files[0], {
          skipEmptyLines: true,
          error: (e) => alert(e),
          complete: (parsed) =>
            this.setState({
              mode: "file",
              filename: files[0].name,
              pivotState: { data: parsed.data },
            }),
        })
    );
  }

  onType(event) {
    Papa.parse(event.target.value, {
      skipEmptyLines: true,
      error: (e) => alert(e),
      complete: (parsed) =>
        this.setState({
          mode: "text",
          filename: "Data from <textarea>",
          textarea: event.target.value,
          pivotState: { data: parsed.data },
        }),
    });
  }
  ytdManipulation = (ytdVal) => {
    let startDate;
    let endDate;
    switch (parseInt(ytdVal)) {
      case 12:
      case 36:
      case 24:
      case 60:
        startDate = window.addMonthToDate(
          new Date(),
          parseInt(ytdVal),
          "start"
        );
        endDate = window.addMonthToDate(startDate, parseInt(ytdVal), "end");
        this.props.setBreadcrums((prevBreadcrums) => {
          prevBreadcrums.startDates = startDate;
          prevBreadcrums.endDate = endDate;
          prevBreadcrums.filterActionReloadSection =
            this.props.breadcrums.filterActionReloadSection + 1;
          return { ...prevBreadcrums };
        });

        break;
      case 0: //YTD
        let currYear = new Date().getFullYear();
        startDate = `${currYear}-01-01`;
        endDate = new Date();
        this.props.setBreadcrums((prevBreadcrums) => {
          prevBreadcrums.startDates = startDate;
          prevBreadcrums.endDate = endDate;
          prevBreadcrums.filterActionReloadSection =
            this.props.breadcrums.filterActionReloadSection + 1;

          return { ...prevBreadcrums };
        });

        break;

      default:
        break;
    }
  };

  handleOnClickYTD = (e) => {
    this.props.setYtd(e.target.value);
    this.ytdManipulation(e.target.value);
  };

  formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  handleDeviationStatus = (e) => {
    e.preventDefault();
    if (this.props.deviation !== e.target.value) {
      this.props.setDeviation(e.target.value);
      this.props.setBreadcrums((prevBreadcrums) => {
        prevBreadcrums.DeviationClassification = e.target.value;
        return { ...prevBreadcrums };
      });
    }
  };
  reloadData = () => {
    this.props.setBreadcrums((prevBreadcrums) => {
      if (
        !window.hideEndDate[
          this.props.breadcrums.vaccine ? "VX" : "RX"
        ].includes(this.props.breadcrums.childMenu) &&
        this.formatDate(prevBreadcrums.startDates) >
          this.formatDate(prevBreadcrums.endDate)
      ) {
        window.showMessage(
          "End date should be greater than start date",
          "warning"
        );
      } else {
        prevBreadcrums.filterActionReloadSection =
          this.props.breadcrums.filterActionReloadSection + 1;
        this.props.setLoading(true);
      }

      return { ...prevBreadcrums };
    });
  };

  render() {
    return (
      <div>
        <div className="row">
          <table>
            <tbody>
              <tr>
                <td style={{ paddingLeft: "250px" }}></td>
                <td>
                  <h2 className="text-center">{this.state.filename} :</h2>
                </td>

                <td>
                  {this.props?.breadcrums?.childMenu === "Deviations" && (
                    <div className="form_control">
                      <FormControl required>
                        <InputLabel id="demo-simple-select-required-label1">
                          Deviation Classification
                        </InputLabel>
                        <Select
                          style={{
                            display: "none",
                          }}
                          labelId="demo-simple-select-required-label1"
                          id="data_source1"
                          value={this.props.deviation}
                          label="Deviation Classification"
                          onChange={this.handleDeviationStatus}
                          sx={{ height: "34px", width: "140px" }}
                          name="data_source1"
                        >
                          {this.props.deviationClassificationList &&
                            this.props.deviationClassificationList.map(
                              (deviationClassification) => {
                                return (
                                  <MenuItem
                                    key={
                                      deviationClassification?.DeviationClassification
                                    }
                                    value={
                                      deviationClassification?.DeviationClassification
                                    }
                                  >
                                    {
                                      deviationClassification?.DeviationClassification
                                    }
                                  </MenuItem>
                                );
                              }
                            )}
                          <MenuItem key={"ALL"} value={"ALL"}>
                            All
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </td>
                <td>
                  <div className="form_control">
                    <FormControl required>
                      <InputLabel id="demo-simple-select-required-label">
                        Period
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-required-label"
                        id="data_source"
                        value={this.props.ytd}
                        label="Period"
                        onChange={this.handleOnClickYTD}
                        sx={{ height: "34px", width: "95px" }}
                        name="data_source"
                        disabled={false}
                      >
                        <MenuItem
                          key={12}
                          value="12"
                          disabled={
                            window.hideEndDate[
                              this.props.breadcrums.vaccine ? "VX" : "RX"
                            ].includes(this.props.breadcrums.childMenu)
                              ? true
                              : false
                          }
                        >
                          1 Year
                        </MenuItem>
                        <MenuItem
                          key={24}
                          value="24"
                          disabled={
                            window.hideEndDate[
                              this.props.breadcrums.vaccine ? "VX" : "RX"
                            ].includes(this.props.breadcrums.childMenu)
                              ? true
                              : false
                          }
                        >
                          2 Years
                        </MenuItem>
                        <MenuItem
                          key={36}
                          value="36"
                          disabled={
                            window.hideEndDate[
                              this.props.breadcrums.vaccine ? "VX" : "RX"
                            ].includes(this.props.breadcrums.childMenu)
                              ? true
                              : false
                          }
                        >
                          3 Years
                        </MenuItem>
                        <MenuItem
                          key={0}
                          value="0"
                          disabled={
                            window.hideEndDate[
                              this.props.breadcrums.vaccine ? "VX" : "RX"
                            ].includes(this.props.breadcrums.childMenu)
                              ? true
                              : false
                          }
                        >
                          YTD
                        </MenuItem>
                        <MenuItem key={13} value="13">
                          Custom
                        </MenuItem>
                        <MenuItem key={60} value="60">
                          All (max. 5 yr)
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </td>
                <td>
                  <div className="form_control" style={{ marginRight: "0px" }}>
                    <CustomDateRangeInputs
                      dataSource={this.props.breadcrums.vaccine ? "VX" : "RX"}
                      disabledInput={
                        // props.breadcrums?.disabledAuditFildWorkDate ||
                        parseInt(this.props.ytd) !== 13
                      }
                      menu={
                        this.props.breadcrums.childMenu !== undefined
                          ? this.props.breadcrums.childMenu
                          : ""
                      }
                      onChange={this.props.datesHandler}
                      valueStartDate={this.props.breadcrums.startDates}
                      valueEndDate={this.props.breadcrums.endDate}
                      breadcrums={this.props.breadcrums}
                    />
                  </div>
                </td>
                <td>
                  {!this.props.loading && (
                    <IconButton
                      onClick={this.reloadData}
                      title="Refresh"
                      size="small"
                      sx={{ margin: "0px" }}
                      disabled={parseInt(this.props.ytd) !== 13 ? true : false}
                    >
                      <RestartAltIcon />
                    </IconButton>
                  )}
                  {this.props.loading && (
                    <IconButton sx={{ margin: "0px" }}>
                      <CircularProgress size={15} thickness={4} />
                    </IconButton>
                  )}
                </td>
                <td>
                  <GenExcel
                    columns={this.props.columns}
                    heading={
                      this.props.breadcrums.childMenu !== undefined
                        ? this.props.breadcrums.childMenu
                        : ""
                    }
                    gridSelectionModel={this.props.gridSelectionModel}
                    breadcrums={this.props.breadcrums}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <PivotTableUISmartWrapper {...this.state.pivotState} />
        </div>
      </div>
    );
  }
}
