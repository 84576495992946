import React from 'react';
import {Link} from "react-router-dom";
import validate from './validateInfo';
import useForm from './useForm';
import Header from '../Header/Header';
import './login.css';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import userLogo from '../../assets/images/u12.png';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
// import InputAdornment from '@mui/material/InputAdornment';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import IconButton from '@mui/material/IconButton';
import Footer from '../Footer/Footer';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../applicationInsights/AppInsights';



const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      width:'100%',
      backgroundColor: 'rgba(242, 242, 242, 1)',
      border: 'inherit',
      fontSize: 16,
      textAlign:'center',
      marginBottom:'20px',
      color:'rgb(121, 121, 121)',
      padding: '8px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: 'inherit',
        borderColor: 'inherit',
      },
    },
  }));
  

function Login({ submitForm }) {
 
  const { handleChange, handleSubmit, values, errors } = useForm(
    submitForm,
    validate
  );

    // const [values, setValues] = React.useState({
    //     password: '',
    //     showPassword: false,
    //   });
    
    //   const handleChange = (prop) => (event) => {
    //     setValues({ ...values, [prop]: event.target.value });
    //   };
    
    //   const handleClickShowPassword = () => {
    //     setValues({
    //       ...values,
    //       showPassword: !values.showPassword,
    //     });
    //   };
    
    //   const handleMouseDownPassword = (event) => {
    //     event.preventDefault();
    //   };





    return (
        <div>
            <Header/>
            <Container component="main" maxWidth="xs">
            <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
            <Box className="frmBx"   noValidate sx={{ mt: 1 }}>
              <form onSubmit={handleSubmit} noValidate>
            <Box>
                    <img className="usrLogo" src={userLogo} alt="Logo" />
                </Box>
            <FormControl className="frmCtr" variant="standard">
        <BootstrapInput  
      
      type='text'
      name='username'
      placeholder='User Name'
      value={values.username}
      onChange={handleChange}

       
        />
        {errors.username && <p style={{color:'red',padding:'0px',marginTop:'0px'}} className={`error ${errors.username ? null : "disable"}`}>{errors.username}</p>}
           
      </FormControl>
      <FormControl className="frmCtr" variant="standard">
        <BootstrapInput
     
     type='password'
     name='password'
     placeholder='Enter your password'
     value={values.password}
     onChange={handleChange}
     
        // type={values.showPassword ? 'text' : 'password'}
        // value={values.password}
        // onChange={handleChange('password')}
        // endAdornment={
        //   <InputAdornment className="inputAdorment" position="end">
        //     <IconButton 
        //       aria-label="toggle password visibility"
        //       onClick={handleClickShowPassword}
        //       onMouseDown={handleMouseDownPassword}
        //       edge="end"
        //     >
        //       {values.showPassword ? <VisibilityOff style={{fontSize:'16px'}} /> : <Visibility style={{fontSize:'16px'}} />}
        //     </IconButton>
        //   </InputAdornment>
        // }
        />
              {errors.password && <p style={{color:'red',padding:'0px',marginTop:'0px'}}>{errors.password}</p>}
      </FormControl>
      <Link to="/asm" style={{textDecoration:'none'}}><Button  className="loginBtn"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button></Link>
            </form>

            <Grid container className="fpBX">
              <Grid item xs>
                <Link href="#" variant="body2" style={{color:'#169BD5', textDecoration:'none'}}>
                  Forgot password?
                </Link>
              </Grid>
            
            </Grid>
      </Box>

              </Box>
                </Container>
                <Footer/>
        </div>
    );
}

// export default Login;
export default withAITracking(reactPlugin, Login);
