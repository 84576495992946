import React, { useState, createContext , useEffect, useContext} from "react";
import { useMsal } from "@azure/msal-react";
import * as rimaConstants from "../../../src/rimaconstants";
import axios from "axios";
import { UserDataContext } from '../Context/UserDataContext';

const ReviewDataContext = createContext();

const ReviewDataContextProvider = (props) => {
  const fileDetails = [];
  // eslint-disable-next-line no-unused-vars
  const [adminUser,setAdminUser, user, setUser, userSASToken, setUserSASToken] = useContext(UserDataContext);
  // const [fileDetails, setFileDetails] = useState([]);

  const today = new Date();
  const uploadedDate = today.toLocaleDateString();
  const { instance, accounts } = useMsal();
  const [isFilesReadyToUpload, setIsFilesReadyToUpload] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState("");
  const [reviewFileUploadStatus, setReviewFileUploadStatus] = useState(false);
  const [reviewFileUploadStatusMsg, setReviewFileUploadStatusMsg] = useState(
    false
  );
  const [readyForPreview, setReadyForPreview] = useState(false)
  const [sessionFilesData, setSessionFilesData] = useState([]);

  const [selectedFile, setSelectedFile] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("");
  const [selectedFileAuditNumber, setSelectedFileAuditNumber] = useState("");
  const [selectedFileRow, setSelectedFileRow] = useState(0);

  //Suggetions Text fields
  const [diFindingText, setDIFindingText] = useState("");
  const [
    userSelectedTitlesFileReview,
    setUserSelectedTitlesFileReview,
  ] = useState([]);
  const [
    userSelectedTitleFileReview,
    setUserSelectedTitleFileReview,
  ] = useState("");
  const [
    resultDocumentSourceTypeSelected,
    setResultDocumentSourceTypeSelected,
  ] = useState("");
  const [suggetionsInputText, setSuggetionsInputText] = useState("");
  const [
    suggestionsPredicted_internalValues,
    setSuggestionsPredicted_internalValues,
  ] = useState([]);
  const [
    suggestionsPredicted_internalKeys,
    setSuggestionsPredicted_internalKeys,
  ] = useState([]);
  const [
    suggestionsPredicted_externalDocumentTitles,
    setSuggestionsPredicted_externalDocumentTitles,
  ] = useState([]);
  const [selectedRegulationFilter, setSelectedRegulationFilter] = useState([]);

  //Suggetions File fields
  const [reviewResultsData, setReviewResultsData] = useState({});
  const [finding, setFinding] = useState("");
  const [fileRecoReceived, setfileRecoReceived] = useState(0);

  const [findingIndex, setFindingIndex] = useState("");
  const [findingTitle, setFindingTitle] = useState("");
  const [predicted_internalValues, setPredicted_internalValues] = useState([]);

  const [predicted_internalKeys, setPredicted_internalKeys] = useState([]);

  const [diFindings, setDIFindings] = useState([]);
  const [selectedDIFinding, setSelectedDIFinding] = useState("");
  const [
    selectedRegulationFilterFiles,
    setSelectedRegulationFilterFiles,
  ] = useState([]);
  const [
    resultDocumentSourceTypeSelectedFiles,
    setResultDocumentSourceTypeSelectedFiles,
  ] = useState("");
  const [
    predicted_externalDocumentTitles,
    setPredicted_externalDocumentTitles,
  ] = useState([]);
  const [titlesChecked, setTitlesChecked] = useState([[]]);
  const [qmsPolicyChecked, setQmsPolicyChecked] = useState([[]]);
  const [policyDocChecked, setPolicyDocChecked] = useState([[]]);
  const [
    userSelectedTitleTextReview,
    setUserSelectedTitleTextReview,
  ] = useState("");
  const [selectedDoc, setSelectedDoc] = useState({})
  const [irDocList, setIRDocList] = useState([])
  const [isGetRegulationsActive, setIsGetRegulationsActive] = useState(false)

  const [recoReceived, setRecoReceived] = useState(0);
  const [isGettingRegulations, setIsGettingRegulations] = useState(false);
  const [isGetRegulationsActiveText, setIsGetRegulationsActiveText] = useState(false)
  const [titlesCheckedText, settitlesCheckedText] = useState([]);
  const [policyDocCheckedText, setPolicyDocCheckedText] = useState([]);

  const containerName = rimaConstants.ASM_ROOT_CONTAINER;
  const targetedFolderName = "Review_Findings";

  
  useEffect(() => {
    //Get user uploaded docs
    loadFiletoSessionFromDB()
  }, []);
  

  const filePath = async (file, targetedFolderName, storageAccountName, user_id, sasToken) => {
    return `https://${storageAccountName}.blob.core.windows.net/${containerName}/${targetedFolderName}/${user_id}/${file.name}?${sasToken}`;
  };

  const loadFiletoSessionFromDB = async () => {
    try {
      return await axios
        .get(`${rimaConstants.REACT_APP_API_URL}/api/v1/review/documentlist/1`)
        .then((response) => {
          if (response && response.data) {
            // console.log(response);
            for (var row in response.data.data[0])
              {
                var obj = response.data.data[0][row]
                var filepath = obj.document_storage_url.split("?")[0]+"?"+userSASToken.storageAccessToken

                var storeageData = 
                {
                  "auditId":obj.audit_number,
                  "isInternal": obj.audit_type===0?true:false,
                  "reviewFileUploadedBy": accounts[0].name, //obj.uploaded_by, //Get Current user Name
                  "reviewFileUploadedDate": obj.uploaded_on,
                  "reviewFileUploadedName": obj.document_name,
                  "reviewFileUploadedPath": filepath,
                  "selected": false,
                  "saved":true,
                  "findings": obj.audit_findings_details?JSON.parse(obj.audit_findings_details):undefined
                }
                fileDetails.push(storeageData)
              }
 
             setSessionFilesData(fileDetails);
          }
        });
        
    } catch (e) {
      return [];
    }
    
  };

  const uploadFileToReviewBlob = async (file, storageAccountName, sasToken, user) => {
    if (!file) return [];
    var req_obj = {};
    req_obj = new FormData();
    req_obj.append("upfile", file);
    await axios
              .post(
                `${rimaConstants.REACT_APP_API_URL}/api/v1/upload?folderName=${containerName}&subFolderName=${targetedFolderName+"/"+user.user_id}&fileName=${encodeURIComponent(file.name)}`,req_obj
              )
              .then((res) => {
                // console.log(res)
              })
    
  // console.log("uploaded file completed");

  return filePath(file, targetedFolderName, storageAccountName,  user.user_id, sasToken);
};

  const onFileUpload = async () => {
    setIsFilesReadyToUpload(false);

    var i = 0,
    len = selectedFiles.length;
    var fileDetails = sessionFilesData;
    var newFileDetails = []
    
    while (i < len) {
      // your code
      let filePath = await uploadFileToReviewBlob(selectedFiles[i], userSASToken.storageAccount, userSASToken.storageAccessToken,  user);
      let storeageData = 
      {
        "auditId":"",
        "isInternal": true,
        "reviewFileUploadedBy": accounts[0].name, //obj.uploaded_by, //Get Current user Name
        "reviewFileUploadedDate": (new Date()).toUTCString(),
        "reviewFileUploadedName": selectedFiles[i].name,
        "reviewFileUploadedPath": filePath,
        "selected": false,
        "saved":false,
        "findings": undefined
      }
      let index = fileDetails.findIndex(o=>(o.reviewFileUploadedPath === storeageData.reviewFileUploadedPath))
      if (index !== -1)
      {
        let s =  selectedFile;
        setSelectedFile("")
        fileDetails[index].reviewFileUploadedDate = storeageData.reviewFileUploadedDate;
        fileDetails[index].reviewFileUploadedBy = storeageData.reviewFileUploadedBy;
        fileDetails[index].saved = false;
        fileDetails[index].findings = storeageData.findings;
        setSelectedFile(s)
      }
      else newFileDetails.push(storeageData)
      i++;
    }

    newFileDetails = newFileDetails.concat(fileDetails);
    setReviewFileUploadStatus(true);
    setReviewFileUploadStatusMsg(true);
    setSessionFilesData(newFileDetails);
  };

  const setSelectedAuditFileFindings = (findings) =>
  {
    sessionFilesData[selectedFileRow].findings = findings
  }



  const contextValues = {
    uploadFileToReviewBlob,
    onFileUpload,
    selectedFiles,
    setSelectedFiles,
    isFilesReadyToUpload,
    setIsFilesReadyToUpload,
    reviewFileUploadStatus,
    reviewFileUploadStatusMsg,
    sessionFilesData,
    setSessionFilesData,
    selectedDoc, setSelectedDoc,
    selectedFile,
    setSelectedFile,
    selectedFileType,
    setSelectedFileType,
    selectedFileAuditNumber,
    setSelectedFileAuditNumber,
    selectedFileRow,
    setSelectedFileRow,

    //Review - Text
    diFindingText,
    setDIFindingText,
    resultDocumentSourceTypeSelected,
    setResultDocumentSourceTypeSelected,
    suggetionsInputText,
    setSuggetionsInputText,
    suggestionsPredicted_internalValues,
    setSuggestionsPredicted_internalValues,
    suggestionsPredicted_internalKeys,
    setSuggestionsPredicted_internalKeys,
    suggestionsPredicted_externalDocumentTitles,
    setSuggestionsPredicted_externalDocumentTitles,
    selectedRegulationFilter,
    setSelectedRegulationFilter,

    //Review - File
    reviewResultsData,
    setReviewResultsData,
    findingIndex,
    setFindingIndex,
    findingTitle,
    setFindingTitle,
    predicted_internalValues,
    setPredicted_internalValues,
    predicted_internalKeys,
    setPredicted_internalKeys,
    diFindings,
    setDIFindings,
    selectedDIFinding,
    setSelectedDIFinding,
    selectedRegulationFilterFiles,
    setSelectedRegulationFilterFiles,
    resultDocumentSourceTypeSelectedFiles,
    setResultDocumentSourceTypeSelectedFiles,
    predicted_externalDocumentTitles,
    setPredicted_externalDocumentTitles,
    titlesChecked,
    setTitlesChecked,
    qmsPolicyChecked,
    setQmsPolicyChecked,
    policyDocChecked,
    setPolicyDocChecked,
    fileRecoReceived,
    setfileRecoReceived,
    userSelectedTitlesFileReview,
    setUserSelectedTitlesFileReview,
    userSelectedTitleFileReview,
    setUserSelectedTitleFileReview,

    userSelectedTitleTextReview,
    setUserSelectedTitleTextReview,
    setSelectedAuditFileFindings,
    readyForPreview, setReadyForPreview,
    irDocList, setIRDocList,
    isGetRegulationsActive, setIsGetRegulationsActive,
    recoReceived, setRecoReceived,
    isGettingRegulations, setIsGettingRegulations,
    isGetRegulationsActiveText, setIsGetRegulationsActiveText,
    titlesCheckedText, settitlesCheckedText,
    policyDocCheckedText, setPolicyDocCheckedText

  };
  return (
    <ReviewDataContext.Provider value={contextValues}>
      {props.children}
    </ReviewDataContext.Provider>
  );
};

export { ReviewDataContext, ReviewDataContextProvider };
