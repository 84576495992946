import React from "react";
import { NestedMenuItem } from "mui-nested-menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiMenuItem-root: hover": {
    backgroundColor: theme.palette.primary.active + " !important",
    color: theme.palette.primary.contrastText + " !important",
  },
  // "& .MuiButtonBase-root .MuiMenuItem-root:hove":{
  //   backgroundColor: theme.palette.primary.active,
  //   color: theme.palette.primary.contrastText,
  // },
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
  },
}));

export default function MenuFeedBack(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <div>
      <Button
        sx={{ padding: "7px 18px!important" }}
        variant="contained"
        // onMouseOver={handleClick}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        disabled={props.disabled}
      >
        {props.menuText}
      </Button>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {/* <NestedMenuItem
          leftIcon={false}
          rightIcon={false}
          // rightIcon={<KeyboardArrowDownIcon />}
          label="Create Feedback Form"
          parentMenuOpen={false}
          onClick={() => {
            props.handleClickOpen("create");
            handleClose();
          }}
        ></NestedMenuItem> */}
        <NestedMenuItem
          leftIcon={false}
          label="Feedback Form"
          parentMenuOpen={open}
        >
          <MenuItem
            onClick={() => {
              props.handleClickOpen("create");
              handleClose();
            }}
            disableRipple
            disabled={
              props.tableRowData && props.tableRowData.length ? true : false
            }
          >
            Create Feedback Form
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.handleClickOpen("recall");
              handleClose();
            }}
            disableRipple
            disabled={
              props.tableRowData && props.tableRowData.length ? false : true
            }
          >
            Cancel Feedback Request
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.handleClickOpen("reshare");
              handleClose();
            }}
            disableRipple
            disabled={
              props.tableRowData && props.tableRowData.length ? false : true
            }
          >
            Reshare Feedback Form
          </MenuItem>
        </NestedMenuItem>
        <NestedMenuItem
          leftIcon={false}
          label="Use Existing Feedback Form"
          parentMenuOpen={
            open // props.tableRowData && props.tableRowData.length ? false : open
          }
          disabled={
            false // props.tableRowData && props.tableRowData.length ? true : false
          }
        >
          {props.templates.map((temp, index) => {
            return (
              <MenuItem
                onClick={() => {
                  props.handleTemplateOpen(temp);
                  handleClose();
                }}
                disableRipple
              >
                {temp.feedback_template_title}
              </MenuItem>
            );
          })}
        </NestedMenuItem>
      </StyledMenu>
    </div>
  );
}
