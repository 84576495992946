// import React from "react";
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    runningFileUploadIds: []
}

const slice = createSlice({
    name: 'araReducer',
    initialState: initialState,
    reducers: {
        insertNotification: (state, action) => {
            state.runningFileUploadIds.push(action.payload);
        },
        insertNotifications: (state, action) => {
            state.runningFileUploadIds = action.payload;
        },
        updateNoticationStatus: (state, action) => {
            let ids = state.runningFileUploadIds;
            let updatedItem = ids.findIndex((t) => t.id === action.payload.id);
            if (updatedItem !== -1) {
                state.runningFileUploadIds[updatedItem].status = action.payload.status;
            }
        }
    },
});

export const araSelector = state => state.araReducer;

export const { insertNotification, insertNotifications, updateNoticationStatus } = slice.actions;

export default slice.reducer;
