// import { LogLevel } from "@azure/msal-browser";
import * as rimaConstants from '../src/rimaconstants';
export const msalConfig = {
    auth: {
        clientId: rimaConstants.CLIENTID, // This is the ONLY mandatory field that you need to supply.
        authority: rimaConstants.AUTHORITY, // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback(loglevel, message, containsPii) {
                console.log(message);
            },
            piiLoggingEnabled: false,
            logLevel:1,
        }
        // loggerOptions: {
        //     loggerCallback: (level, message, containsPii) => {
        //         if (containsPii) {
        //             return;
        //         }
        //         switch (level) {
        //             case LogLevel.Error:
        //                 console.error(message);
        //                 return;
        //             case LogLevel.Info:
        //                 console.info(message);
        //                 return;
        //             case LogLevel.Verbose:
        //                 console.debug(message);
        //                 return;
        //             case LogLevel.Warning:
        //                 console.warn(message);
        //                 return;
        //         }
        //     }
        // }
    }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: ["User.Read", "Group.Read.All", "offline_access"] //, "GroupMember.Read.All"
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
    graphMe: {
        endpoint: "https://graph.microsoft.com/v1.0/me",
        scopes: ["User.Read"],
    }
}