import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    disableScrollLock={false}
    disableInteractive={false}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#554F41", //theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#554F41", //theme.palette.common.black,
    fontSize: theme.typography.pxToRem(12),
    // border: "1px solid rgba(224, 224, 224, 1)",
    maxWidth: 800,
    maxHeight: 200,
    // padding: "15px",
    overflow: "inherit",
    // overflowY: "auto",
  },
  // [`& .${tooltipClasses.tooltip}::-webkit-scrollbar`]: {
  //   width: "0.6em",
  // },
  // [`& .${tooltipClasses.tooltip}::-webkit-scrollbar-track`]: {
  //   boxShadow: "inset 0 0 6px #15717dad",
  // },
  // [`& .${tooltipClasses.tooltip}::-webkit-scrollbar-thumb`]: {
  //   backgroundColor: "#15717D",
  //   outline: "1px solid #8A8474",
  // },
}));

// const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: "#EFEFED",
//     color: "#000000",
//     fontSize: theme.typography.pxToRem(12),
//     border: "1px solid rgba(224, 224, 224, 1)",
//     maxWidth: 800,
//     maxHeight: 200,
//     padding: "15px",
//     overflow: "inherit",
//     // overflowY: "auto",
//   },
// }));

const renderHtml = (html) => {
  return (
    <div
      // style={{ overflowY: "auto", maxWidth: 800, maxHeight: 200, padding: "15px", }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

const TooltipsGrid = (props) => {
  return <>{props.html ? renderHtml(props.title) : props.title}</>;
  // return (
  //   <div>
  //     <BootstrapTooltip
  //       placement={props.placement ? props.placement : "left-start"}
  //       title={renderHtml(props.title)}
  //       sx={{ overflowY: props?.title?.length > 300 ? "auto" : "" }}
  //     >
  //       <span>{props.html ? renderHtml(props.title) : props.title}</span>
  //     </BootstrapTooltip>
  //   </div>
  // );

  // return (
  //   <div>
  //     <HtmlTooltip
  //       placement={props.placement ? props.placement : "bottom-start"}
  //       title={props.html ? renderHtml(props.title) : props.title}
  //     >
  //       <span>{props.html ? renderHtml(props.title) : props.title}</span>
  //     </HtmlTooltip>
  //   </div>
  // );
};

export default TooltipsGrid;

// import * as React from "react";
// import Box from "@mui/material/Box";
// import Popper from "@mui/material/Popper";
// import Fade from "@mui/material/Fade";

// export default function TooltipsGrid(props) {
//   const [open, setOpen] = React.useState(false);
//   const [anchorEl, setAnchorEl] = React.useState(null);

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//     setOpen((previousOpen) => !previousOpen);
//   };

//   const canBeOpen = open && Boolean(anchorEl);
//   const id = canBeOpen ? "transition-popper" : undefined;

//   return (
//     <div>
//       <span
//         aria-describedby={id}
//         type="button"
//         onMouseEnter={() => setOpen(true)}
//       >
//         {props.title}
//       </span>
//       <Popper id={id} open={open} anchorEl={anchorEl} transition>
//         {({ TransitionProps }) => (
//           <Fade {...TransitionProps} timeout={350}>
//             <Box
//               sx={{
//                 // border: 1,
//                 p: 1,
//                 backgroundColor: "#554F41", //theme.palette.common.black,
//                 fontSize: 12,
//                 maxWidth: 800,
//                 maxHeight: 200,
//                 whiteSpace: "pre-wrap",
//                 color: "#fff",
//                 overflow: "inherit",
//                 overflowY: "auto",
//               }}
//               onMouseLeave={() => setOpen(false)}
//             >
//               {props.title}
//             </Box>
//           </Fade>
//         )}
//       </Popper>
//     </div>
//   );
// }
