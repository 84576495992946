import React, { useContext } from "react";
import Admintemplate from "../admintemplate/admintemplate";
import { BreadcrumbsContext } from "../context/breadcrumsContext";
import SiteManagement from "./siteManagement";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../applicationInsights/AppInsights';

const Content = () => {
  // eslint-disable-next-line no-unused-vars
  const { breadcrumbs, setBreadcrumbs } = useContext(BreadcrumbsContext);
  React.useEffect(() => {
    setBreadcrumbs((prevBreadcrumbs) => {
      prevBreadcrumbs.parentMenu = "Site Management";
      prevBreadcrumbs.childMenu = "";
      return { ...prevBreadcrumbs };
    });
  }, []);
  return (
    <div className="container-admindashboard">
      <SiteManagement />
    </div>
  );
};

const GetContent = () => {
  return <Content />;
};

const SiteManagementMain = () => {
  return <Admintemplate content={GetContent()} />;
};

// export default UnstructuredKewodsMain;
export default withAITracking(reactPlugin, SiteManagementMain);

