import { useState, createContext } from 'react';

export const BreadcrumsContext = createContext();

export const BreadcrumsContextProvider = props => {
    const [breadcrums, setBreadcrums] = useState({
        vaccine: true,
        pharma: false,
        parentMenu: '',
        childMenu: '',
        disabledAuditFildWorkDate: false,
        disabledValueDispled: false,
        uploadDocuments: false,
        tableColumnData: [],
        tableRowData: [],
        internalAuditHeadings: [],
        internalAuditData: [],
        inferencesData: "",
        siteId: "",
        resetFilter: false,
        auditTitle: "",
        refreshInferences: 0,
        refreshStakeholderFeedback: 0,
        runningFileUploadIds: [],
        closedAudits: false,
        laoadClosedAudits: 0,
        featureList: [],
        startDates: null,
        endDate: null,
        loadingButtonIniteateInsights: false,
        DeviationClassification: "Major",
        showInsights:true,
        filterActionReloadSection:0
    });

    return <BreadcrumsContext.Provider value={{ breadcrums, setBreadcrums }}>
        {props.children}
    </BreadcrumsContext.Provider>
}