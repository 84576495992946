import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";

export default function MaterialUIPickers(props) {
  // eslint-disable-next-line no-unused-vars
  const [minDate, setMinDate] = React.useState(new Date());
  React.useEffect(() => {
    // console.log('minDate 1 '+props.minDate)
    props?.minDate
      ? setMinDate(new Date(props.minDate))
      : setMinDate(new Date());
  }, [props?.minDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale="en">
      <Stack spacing={3}>
        <DesktopDatePicker
          // mask="__-__-___"
          mask=""
          minDate={minDate}
          label={props.label}
          inputFormat="dd-MMM-yyyy"
          value={props.dpval}
          onChange={props.onChangeFn}
          renderInput={(params) => (
            <TextField
              sx={
                props.deviationDate
                  ? {
                      paddingLeft: "0px",
                      width: "125px",
                      "& .MuiOutlinedInput-root": { padding: "1px" },
                      "& .MuiInputLabel-root": { lineHeight: "1.9em" },
                      "& .MuiIconButton-root": { mr: 1 },
                    }
                  : {}
              }
              variant="outlined"
              {...params}
              {...params}
              error={false}
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
}
