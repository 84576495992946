import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import RefreshIcon from "@mui/icons-material/Refresh";
import { toast } from "react-toastify";
import { useMsal, useAccount } from "@azure/msal-react";
import * as rimaConstants from "../../../rimaconstants";
import ConfirmDialog from "../../Common/confirmDailog";
import axios from "axios";
import Loader from "../../Loader/Loader";
import NotesSearchPage from "../../Common/NotesSearchPage";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#15717d", //theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SearchBar = ({ searchHandler }) => (
  <>
    <TextField
      id="search-bar"
      onChange={(e) => {
        searchHandler(e.target.value);
      }}
      label="Enter search string"
      variant="outlined"
      placeholder="Search..."
      size="small"
      style={{ borderColor: "#15717d", width: "200px" }}
    />
    <IconButton size="small" type="submit" aria-label="search">
      <SearchIcon style={{ fill: "#15717d" }} />
    </IconButton>
  </>
);

export default function DeletedDocListViewer(props) {
  const { rows, showDelete, showArchive, refreshFn, search } = props;
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [confirmArchive, setConfirmArchive] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredRows, setFilteredRows] = useState(rows);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [opCtrl, setOpCtrl] = useState({});
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setOpCtrl({});
    searchHandler("");
  }, [rows]);

  const handleChangeRowsPerPage = (event) => {
    let rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage !== -1 ? rowsPerPage : rows.length);
    setPage(0);
  };

  const searchHandler = (qry) => {
    if (qry) {
      setFilteredRows(
        rows.filter((x) => x.title.toLowerCase().includes(qry.toLowerCase()))
      );
    } else setFilteredRows(rows);
  };

  return (
    <>
      {loading ? window.showLoader() : window.hideLoader()}
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-center"
        sx={{ paddingBottom: "20px" }}
      >
        {search ? (
          <SearchBar searchQuery={searchQuery} searchHandler={searchHandler} />
        ) : (
          ""
        )}
        {refreshFn !== undefined && refreshFn !== null ? (
          <IconButton
            onClick={(e) => {
              refreshFn();
            }}
          >
            <RefreshIcon style={{ fill: "#15717d" }} />
          </IconButton>
        ) : null}
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Title</StyledTableCell>
              <StyledTableCell align="right">Type</StyledTableCell>
              <StyledTableCell align="right">Regulation</StyledTableCell>
              <StyledTableCell align="right">File Name</StyledTableCell>
              <StyledTableCell align="right">Uploaded By</StyledTableCell>
              <StyledTableCell align="right">Uploaded Date</StyledTableCell>
              <StyledTableCell align="right">Archived By</StyledTableCell>
              <StyledTableCell align="right">Archived Date</StyledTableCell>
              <StyledTableCell align="right">Deleted By</StyledTableCell>
              <StyledTableCell align="right">Deleted Date</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <StyledTableRow key={row.document_id}>
                  <StyledTableCell component="th" scope="row">
                    {row.title}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.type}</StyledTableCell>
                  <StyledTableCell align="right">
                    {row.regulation}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.filepath}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.created_by}
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{whiteSpace:"nowrap"}}>
                    {row.created_date_time}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.archived_by}
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{whiteSpace:"nowrap"}}>
                    {row.archived_date}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.deleted_by}
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{whiteSpace:"nowrap"}}>
                    {row.deleted_date}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              {props?.showFooterNote && <div style={{marginTop:"20px"}}><NotesSearchPage note={props?.noteText} /></div>}
              <TablePagination
                rowsPerPageOptions={[
                  10,
                  25,
                  { value: filteredRows.length, label: "All" },
                ]}
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ width: "300px", justify: "flex-right" }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
  
    </>
  );
}
