import React from "react";
import "./admintemplate.css";
import Header2 from "../Header/Header";
import Footer from "../../Footer/Footer";
import Breadcrums from "../breadcrums/breadcrums";

import { BreadcrumbsContextProvider } from "../context/breadcrumsContext";
// import TemporaryDrawer from "../sidebar/sidebar";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../applicationInsights/AppInsights';

const Admintemplate = (props) => {
  return (
    <>
      <Header2 />
      <div className="container-fluid">
        <BreadcrumbsContextProvider>
          <div className="sidenavc">
            <div className="rightbreadcrumbs">
              <Breadcrums></Breadcrums>
            </div>
          </div>
          <div className="container-main" style={{ minHeight: "435px" }}>{props.content}</div>
        </BreadcrumbsContextProvider>
      </div>
      <Footer />
    </>
  );
};

// export default Admintemplate;
export default withAITracking(reactPlugin, Admintemplate);
