import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./breadcrums.css";

import { BreadcrumsContext } from "../context/breadcrumsContext";
import { UserDataContext } from "../../Context/UserDataContext";
import HomeIcon from "../../../assets/images/u1060.svg";
import axios from "axios";
import * as rimaConstants from "../../../rimaconstants";

const Breadcrumbs1 = () => {
  const { breadcrums, setBreadcrums } = useContext(BreadcrumsContext);
  // eslint-disable-next-line no-unused-vars
  const [adminUser, setAdminUser, user, setUser] = useContext(UserDataContext);
  const [menu, setMenu] = React.useState([]);

  React.useEffect(() => {
    // window.showLoader();
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/ara/arageneraltoprightcornermenu`
      )
      .then((response) => {
        if (response.data.status === "success") {
          setMenu(response.data["data"]);
        }
        // window.hideLoader();
      })
      .catch((err) => {});
  }, []);

  React.useEffect(() => {
    if (breadcrums.parentMenu && menu?.length) {
      let feature = menu.filter(
        (menuItem) => menuItem.feature_name === breadcrums.parentMenu
      );
      feature[0] &&
        feature[0]?.feature_id &&
        window.setFeatureID(feature[0].feature_id);
    }
  }, [menu, breadcrums.parentMenu]);

  return (
    <Breadcrumbs
      style={{ width: "80%", padding: "10px 0 0px 0" }}
      separator={
        <NavigateNextIcon
          style={{ color: "#000", fontSize: "unset" }}
          fontSize="small"
        />
      }
      aria-label="breadcrumb"
    >
      <Link underline="hover" color="inherit" to="/">
        <img src={HomeIcon} alt="Home" style={{ height: "12px" }} />
      </Link>
      <Link
        style={{ fontSize: "12px", color: "#169BD5" }}
        underline="hover"
        color="inherit"
        to="/ara"
        onClick={() => {
          if (typeof user.role !== undefined && Array.isArray(user.role) && !user.role.includes(3)) {
            setBreadcrums((prevBreadcrums) => {
              prevBreadcrums.uploadClick = false;
              prevBreadcrums.childMenu = "";
              prevBreadcrums.parentMenu = "";
              prevBreadcrums.tableColumnData = [];
              prevBreadcrums.tableRowData = [];
              prevBreadcrums.auditTitle = "";
              prevBreadcrums.resetCountryFilter = true;
              return { ...prevBreadcrums };
            });
          }
        }}
      >
        ARA
      </Link>
      {breadcrums.vaccine || breadcrums.pharma ? (
        <Typography
          style={{
            fontWeight: "400",
            fontStyle: "normal",
            fontSize: "12px",
            textAlign: "left",
          }}
          color="text.primary"
        >
          {breadcrums.vaccine ? "Vaccine" : "Pharma"}
        </Typography>
      ) : (
        ""
      )}

      {breadcrums.parentMenu !== "" && (
        <Typography
          style={{
            fontWeight: "400",
            fontStyle: "normal",
            fontSize: "12px",
            textAlign: "left",
          }}
          color="text.primary"
        >
          {breadcrums.parentMenu}
        </Typography>
      )}

      {breadcrums.childMenu !== "" && (
        <Typography
          style={{
            fontWeight: "400",
            fontStyle: "normal",
            fontSize: "12px",
            textAlign: "left",
          }}
          color="text.primary"
        >
          {breadcrums.childMenu}
        </Typography>
      )}
    </Breadcrumbs>
  );
};

export default function Breadcrums(props) {
  // const { breadcrums, setBreadcrums } = useContext(BreadcrumsContext);
  // eslint-disable-next-line no-unused-vars
  // const [adminUser, setAdminUser, user, setUser] = useContext(UserDataContext);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          p: 1,
          backgroundColor: "#fff",
        }}
      >
        <Breadcrumbs1 />
      </Box>
    </div>
  );
}
