/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Tooltip,
  Dialog,
  Button,
  DialogContentText,
  Typography,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";

import { BreadcrumsContext } from "../context/breadcrumsContext";
import axios from "axios";
import * as rimaConstants from "../../../rimaconstants";
import DataView from "../../DataTable/DataView";
import SearchableDropdown from "../../Common/searchableDropdown";
import FiberNewOutlinedIcon from "@mui/icons-material/FiberNewOutlined";
import RemoveDoneOutlinedIcon from "@mui/icons-material/RemoveDoneOutlined";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../applicationInsights/AppInsights";

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
const AuditTrailContent = () => {
  // eslint-disable-next-line no-unused-vars
  const { breadcrums, setBreadcrums } = useContext(BreadcrumsContext);
  const [audit, setAudit] = useState({
    source: "Vx",
    number: "",
  });
  const [auditList, setAuditList] = useState([]);
  // const [auditTrails, setAuditTrails] = useState([]);
  const [details, setDetails] = useState({});
  const [headers, setHeaders] = useState([]);
  const [data, setData] = useState([]);

  const [loading, setLoading] = React.useState(false);
  const [resetOptions, setResetOptions] = useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [showDiff, setShowDiff] = useState(false);
  const [oldValues, setOldValues] = useState("");
  const [newValues, setNewValues] = useState("");
  const [commonDiff, setCommonDiff] = useState(false);
  const [modalMaxWidth, setModalMaxWidth] = useState("sm");
  const [WORDSWITHSPACE, setWORDSWITHSPACE] = useState(true);
  const [featureName, setFeatureName] = useState("");
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef(null);
  React.useEffect(() => {
    setHeight(window.innerHeight - 200);
  }, []);

  const handleCloseDiff = (event, reason) => {
    setShowDiff(false);
    setOldValues("");
    setNewValues("");
  };

  useEffect(() => {
    setBreadcrums((prevBreadcrums) => {
      prevBreadcrums.vaccine = false;
      prevBreadcrums.pharma = false;
      prevBreadcrums.parentMenu = "Audit Trails";
      prevBreadcrums.childMenu = "";
      prevBreadcrums.tableColumnData = [];
      prevBreadcrums.tableRowData = [];
      return { ...prevBreadcrums };
    });
    getAuditNumbers(audit.source);
  }, []);

  const fieldsNames = {
    Auditor: "Lead Auditor",
    Co_Auditor: "Audit Team",
    Auditee: "Auditee Contact",
    audit_number: "Audit Number",
    audit_title: "Audit Title",
    planned_fieldwork_start_date: "Start Date",
    planned_fieldwork_end_date: "End Date",
    site: "Site",
    audit_type: "Audit Type",
    data_source: "Data Source",
    data: "Content",
  };
  const getAuditNumbers = (value) => {
    setAuditList([]);
    setLoading(true);
    axios
      .get(`${rimaConstants.REACT_APP_API_URL}/api/v1/arauser/audits/` + value)
      .then((response) => {
        // response.data.length ? setAuditList(response.data) : setAuditList([]);
        let options = [];
        if (response.data.length) {
          response.data.map((val) => {
            options.push({
              label:
                window.customEscape(val.audit_number) +
                " - " +
                window.customEscape(val.audit_title),
              id: val.audit_number,
            });
            return true;
          });
        }
        setAuditList(options);
        setLoading(false);
      });
  };
  const resetAudit = () => {
    // setAuditTrails([]);
  };
  const getAuditTrails = (value) => {
    window.showLoader();
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/arauser/audit_trail/` + value
      )
      .then((response) => {
        window.hideLoader();
        //console.log(response);
        const dateOfEntryField = response.data.heading.find(
          (header) => header.field === "date_of_entry"
        );
        dateOfEntryField.type = "string";
        response.data.heading.map((data, index) => {
          if (data.field === "date_of_entry") {
            data.type = "string";
            data.type1 = "date";
          }
          if (data.type === "date") {
            data.type = "string";
            data.type1 = "date";
            data.getApplyQuickFilterFn = (value) =>
              window.getApplyQuickFilterFn(value, true);
            data.valueGetter = ({ value }) =>
              window.getDateGridBinding(value, true);
          }
          data.type = data.field === "date_of_entry" ? "string" : data.type;
          data.renderCell = (params) => {
            if (data.field === "access_remarks") {
              const onClickView = (oldValues, newValues) => {
                setCommonDiff(false);
                setModalMaxWidth("sm");
                try {
                  let oldData = oldValues ? JSON.parse(oldValues) : "";
                  let newData = newValues ? JSON.parse(newValues) : "";
                  oldData = oldData ? window.base64_decode(oldData.data) : "";
                  newData = newData ? window.base64_decode(newData.data) : "";
                  setOldValues(oldData ? JSON.parse(oldData) : "");
                  setNewValues(newData ? JSON.parse(newData) : "");
                  setShowDiff(true);
                } catch (e) {
                  window.showMessage(
                    "Data is not appropriate for preview!",
                    "warning"
                  );
                }
              };
              return params.row["access_remarks"] === "Audit Updated" ? (
                <>
                  <Tooltip title={params.row[data.field]}>
                    <a
                      href="javascript:void(0);"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickView(
                          params.row["old_values"],
                          params.row["new_values"]
                        );
                      }}
                    >
                      {params.row[data.field]}
                    </a>
                  </Tooltip>
                </>
              ) : (
                <Tooltip title={params.row[data.field]}>
                  <span>{params.row[data.field]}</span>
                </Tooltip>
              );
            } else if (data.field === "date_of_entry") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = (value) =>
                window.getApplyQuickFilterFn(value, true);
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value, true);
              return (
                <Tooltip title={window.getDate(params.row[data.field], true)}>
                  <span>{window.getDate(params.row[data.field], true)}</span>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip title={params.row[data.field]}>
                  <span>{params.row[data.field]}</span>
                </Tooltip>
              );
            }
          };
        });
        setHeaders(response.data.heading);
        setData(response.data.audit_trail);
        setDetails(response.data.audit_details);
        //console.log(response.data);
        //let audit_id = response.data.data[0].audit_id;
        //setDetails(response.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getFeatureData = (value) => {
    window.showLoader();
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/arauser/feature_audit_trail/` +
          value
      )
      .then((response) => {
        window.hideLoader();
        //console.log(response);
        response.data.heading.map((data, index) => {
          if (data.field === "date_of_entry") {
            data.type = "string";
            data.type1 = "date";
          }
          if (data.type === "date") {
            data.type = "string";
            data.type1 = "date";
            data.getApplyQuickFilterFn = (value) =>
              window.getApplyQuickFilterFn(value, true);
            data.valueGetter = ({ value }) =>
              window.getDateGridBinding(value, true);
          }
          data.type = data.field === "date_of_entry" ? "string" : data.type;
          data.renderCell = (params) => {
            if (data.field === "access_remarks") {
              const onClickView = (oldValues, newValues) => {
                setCommonDiff(true);
                setModalMaxWidth("md");
                try {
                  let oldData = oldValues ? JSON.parse(oldValues) : "";
                  let newData = newValues ? JSON.parse(newValues) : "";
                  oldData = oldData ? window.base64_decode(oldData.data) : "";
                  newData = newData ? window.base64_decode(newData.data) : "";
                  if (
                    ["Unstructured Keywords Settings"].includes(
                      params.row.feature_name
                    )
                  ) {
                    if (oldData) {
                      oldData = JSON.parse(oldData);
                      oldData.audit_document_type =
                        oldData.audit_document_type + " ";

                      oldData = JSON.stringify(oldData);
                    }
                  }
                  if (["Site Management"].includes(params.row.feature_name)) {
                    if (oldData) {
                      oldData = JSON.parse(oldData);
                      oldData.data_source = oldData.data_source + " ";
                      oldData.country_code = oldData.country_code + " ";
                      oldData.country_name = oldData.country_name + " ";
                      oldData.site_code = oldData.site_code + " ";
                      oldData.site_description = oldData.site_description + " ";
                      oldData.is_enabled = oldData.is_enabled + " ";

                      oldData = JSON.stringify(oldData);
                    }
                  }
                  if (["Template Section Management"].includes(params.row.feature_name)) {
                    if (oldData) {
                      oldData = JSON.parse(oldData);
                      oldData.feature_name = oldData.feature_name + " ";
                      oldData.templete_section_name = oldData.templete_section_name + " ";
                      oldData = JSON.stringify(oldData);
                    }
                  }
                  if (
                    ["Retention Period Settings"].includes(
                      params.row.feature_name
                    )
                  ) {
                    setWORDSWITHSPACE(false);
                    oldData = oldData
                      .replace(/Years/i, "ARA Documents Retention Period")
                      .replace(
                        /Months/i,
                        "Site Specific Documents Retention Period"
                      )
                      .replace(/Date/i, "Updated on")
                      .replace(/Email/i, "Updated by");
                    newData = newData
                      .replace(/Years/i, "ARA Documents Retention Period")
                      .replace(
                        /Months/i,
                        "Site Specific Documents Retention Period"
                      )
                      .replace(/Date/i, "Updated on")
                      .replace(/Email/i, "Updated by");

                    oldData = oldData
                      ? JSON.parse(JSON.parse(oldData)["data"])[0]
                      : "";
                    if (oldData["ARA Documents Retention Period"]) {
                      oldData["ARA Documents Retention Period"] =
                        oldData["ARA Documents Retention Period"] + " Year(s)";
                    }
                    if (oldData["Site Specific Documents Retention Period"]) {
                      oldData["Site Specific Documents Retention Period"] =
                        oldData["Site Specific Documents Retention Period"] +
                        " Month(s)";
                      oldData = Object.assign(
                        {
                          "Data Source": params.row.access_remarks.substring(
                            0,
                            2
                          ),
                        },
                        oldData
                      );
                      // oldData[`Data Source`] = params.row.access_remarks.substring(0,2);
                    }
                    setOldValues(oldData);
                    newData = newData
                      ? JSON.parse(JSON.parse(newData)["data"])[0]
                      : "";
                    if (newData["ARA Documents Retention Period"]) {
                      newData["ARA Documents Retention Period"] =
                        newData["ARA Documents Retention Period"] + " Year(s)";
                    }
                    if (newData["Site Specific Documents Retention Period"]) {
                      newData["Site Specific Documents Retention Period"] =
                        newData["Site Specific Documents Retention Period"] +
                        " Month(s)";
                      newData = Object.assign(
                        {
                          "Data Source":
                            params.row.access_remarks.substring(0, 2) + " ",
                        },
                        newData
                      );
                      // newData[`Data Source`] = params.row.access_remarks.substring(0,2)+' ';
                    }
                    setNewValues(newData);
                  } else {
                    setWORDSWITHSPACE(true);
                    setOldValues(oldData ? JSON.parse(oldData) : "");
                    setNewValues(newData ? JSON.parse(newData) : "");
                  }
                  setShowDiff(true);
                } catch (e) {
                  console.log(
                    "ARA Documents Retention Period ",
                    params.row.access_remarks,
                    e
                  );
                  window.showMessage(
                    "Data is not appropriate for preview!",
                    "warning"
                  );
                }
              };
              return [
                "Vx - ARA Retention Period Settings Updated",
                "Rx - ARA Retention Period Settings Updated",
                "Vx/Rx - Audit Initiated Intimation Email Template Updated",
                "Vx/Rx - Stakeholder Notification Email Template Updated",
                "Vx/Rx - Intimation to Auditor Email Template Updated",
                "Vx/Rx - Stakeholder Recall Questionnaire Email Template Updated",
                "Vx/Rx - Stakeholder Reminder Email Template Updated",
                "Vx/Rx - Document(s) Upload Intimation Email Template Updated",
                "Keywords Settings Updated",
                "Site Name Updated",
                "Site enabled",
                "Site disabled",
                "Template Section Name Updated",
              ].includes(params.row["access_remarks"]) ? (
                <>
                  <Tooltip title={params.row[data.field]}>
                    <a
                      href="javascript:void(0);"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickView(
                          params.row["old_values"],
                          params.row["new_values"]
                        );
                      }}
                    >
                      {params.row[data.field]}
                    </a>
                  </Tooltip>
                </>
              ) : (
                <Tooltip title={params.row[data.field]}>
                  <span>{params.row[data.field]}</span>
                </Tooltip>
              );
            } else if (data.field === "date_of_entry") {
              data.type = "string";
              data.type1 = "date";
              data.getApplyQuickFilterFn = (value) =>
                window.getApplyQuickFilterFn(value, true);
              data.valueGetter = ({ value }) =>
                window.getDateGridBinding(value, true);
              return (
                <Tooltip title={window.getDate(params.row[data.field], true)}>
                  <span>{window.getDate(params.row[data.field], true)}</span>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip title={params.row[data.field]}>
                  <span>{params.row[data.field]}</span>
                </Tooltip>
              );
            }
          };
          // data.renderCell = (params) => (
          //   <Tooltip title={params.row[data.field]}>
          //     <span>{params.row[data.field]}</span>
          //   </Tooltip>
          // );
          // return true;
          // });
          setHeaders(response.data.heading);
          setData(response.data.audit_trail);
          // setDetails(response.data.audit_details);
          //console.log(response.data);
          //let audit_id = response.data.data[0].audit_id;
          //setDetails(response.data.data[0]);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangeNumber = (value) => {
    if (value) {
      setDetails({});
      setHeaders([]);
      setData([]);
      if (audit.source === "common") {
        setFeatureName(value);
        getFeatureData(value);
      } else {
        getAuditTrails(value);
      }
      setAudit({
        ...audit,
        number: value,
      });
    }
  };

  const handleChange = (field, value) => {
    setResetOptions(null);
    switch (field) {
      case "source":
        resetAudit();
        getAuditNumbers(value);
        setAudit({
          ...audit,
          [field]: value,
          number: "",
        });
        setHeaders([]);
        setData([]);
        setDetails({});
        break;
      case "number":
        setDetails({});
        setHeaders([]);
        setData([]);
        if (audit.source === "common") {
          setFeatureName(value);
          getFeatureData(value);
        } else {
          getAuditTrails(value);
        }
        setAudit({
          ...audit,
          [field]: value,
        });
        break;
      default:
        break;
    }
  };

  const getDiff = (oldD, newD, key) => {
    let arr1 = oldD.split(",");
    let arr2 = newD.split(",");
    arr1 = arr1.filter((n) => (n.trim() ? true : false));
    arr2 = arr2.filter((n) => (n.trim() ? true : false));
    let difference = arr1.filter((x) => !arr2.includes(x));
    let resArr = [];
    if (difference && Array.isArray(difference)) {
      resArr = arr1.filter((x) => !difference.includes(x));
      return difference?.length ? (
        <div
          style={{
            // width: "100%",
            padding: "3px",
            minHeight: "20px",
            display: ["Auditor", "Co_Auditor", "Auditee"].includes(key)
              ? "table-caption"
              : "table",
            // color: "#000",
            // textDecoration: "line-through",
          }}
        >
          <span>
            {resArr.join(", ").toString()}
            {resArr.length ? ", " : ""}
          </span>
          <span
            style={{
              backgroundColor: "rgb(228 0 48 / 72%)",
              // padding: "3px",
              color: "#fff",
            }}
          >
            {difference.join(", ").toString()}
          </span>
        </div>
      ) : (
        <span
          style={{
            padding: "3px",
            display: ["Auditor", "Co_Auditor", "Auditee"].includes(key)
              ? "table-caption"
              : "table",
          }}
        >
          {arr1.join(", ").toString()}
        </span>
      );
    } else {
      return (
        <span
          style={{
            padding: "3px",
            display: ["Auditor", "Co_Auditor", "Auditee"].includes(key)
              ? "table-caption"
              : "table",
          }}
        >
          {arr1.join(", ").toString()}
        </span>
      );
    }
  };

  const getDiff1 = (oldD, newD, key) => {
    let arr1 = oldD.split(",");
    let arr2 = newD.split(",");
    arr1 = arr1.filter((n) => (n.trim() ? true : false));
    arr2 = arr2.filter((n) => (n.trim() ? true : false));
    let difference = arr2.filter((x) => !arr1.includes(x));
    let resArr = [];
    if (difference && Array.isArray(difference)) {
      resArr = arr2.filter((x) => !difference.includes(x));
      return difference?.length ? (
        <div
          style={{
            // width: "100%",
            padding: "3px",
            minHeight: "20px",
            display: ["Auditor", "Co_Auditor", "Auditee"].includes(key)
              ? "table-caption"
              : "table",
            // color: "#000",
          }}
        >
          <span>
            {resArr.join(", ").toString()}
            {resArr.length ? ", " : ""}
          </span>
          <span
            style={{
              backgroundColor: "hwb(152deg 0% 47% / 66%)",
              // padding: "3px",
              color: "#fff",
            }}
          >
            {difference.join(", ").toString()}
          </span>
        </div>
      ) : (
        <span
          style={{
            padding: "3px",
            display: ["Auditor", "Co_Auditor", "Auditee"].includes(key)
              ? "table-caption"
              : "table",
          }}
        >
          {arr2.join(", ").toString()}
        </span>
      );
    } else {
      return (
        <span
          style={{
            padding: "3px",
            display: ["Auditor", "Co_Auditor", "Auditee"].includes(key)
              ? "table-caption"
              : "table",
          }}
        >
          {arr2.join(", ").toString()}
        </span>
      );
    }
  };

  const showDifference = (oldD, newD, key) => {
    oldD = oldD.replace(/;/g, ",");
    newD = newD.replace(/;/g, ",");

    return oldD !== newD ? (
      <>
        <>
          {oldD && (
            <div>
              <Grid
                container
                spacing={2}
                justify="flex-end"
                alignItems="center"
              >
                <Grid item>
                  {getDiff(
                    [
                      "planned_fieldwork_start_date",
                      "planned_fieldwork_end_date",
                    ].includes(key)
                      ? window.getDate(oldD)
                      : oldD,
                    [
                      "planned_fieldwork_start_date",
                      "planned_fieldwork_end_date",
                    ].includes(key)
                      ? window.getDate(newD)
                      : newD,
                    key
                  )}
                </Grid>
                {/* <Grid item>
                  <IconButton title="Old Data" size="small">
                    <RemoveDoneOutlinedIcon fontSize="inherit" sx={{ marginLeft: "-10px" }}/>
                  </IconButton>
                </Grid> */}
              </Grid>
            </div>
          )}
        </>
        {oldD && newD && (
          <div
            style={{
              borderTop: "2px solid #15717D",
              // marginTop: "6px"
            }}
          ></div>
        )}
        <>
          {newD && (
            <div>
              <Grid
                container
                spacing={2}
                justify="flex-end"
                alignItems="center"
              >
                <Grid item sx={{ maxWidth: "90%" }}>
                  {getDiff1(
                    [
                      "planned_fieldwork_start_date",
                      "planned_fieldwork_end_date",
                    ].includes(key)
                      ? window.getDate(oldD)
                      : oldD,
                    [
                      "planned_fieldwork_start_date",
                      "planned_fieldwork_end_date",
                    ].includes(key)
                      ? window.getDate(newD)
                      : newD,
                    key
                  )}
                </Grid>
                <Grid item>
                  {/* <IconButton
                    title="New Data"
                    size="medium"
                    sx={{
                      padding: "3px 0 0 0",
                      cursor: "default",
                      backgroundColor: "none",
                      "&:hover": { backgroundColor: "none" },
                    }}
                    disabled={false}
                  > */}
                  <FiberNewOutlinedIcon
                    fontSize="medium"
                    sx={{
                      // marginLeft: "-15px",
                      "&:hover": { color: "#15717D" },
                    }}
                  />
                  {/* </IconButton> */}
                </Grid>
              </Grid>
            </div>
          )}
        </>
      </>
    ) : (
      <></>
    );
  };

  const isValidTableRow = (oldD, newD, key) => {
    oldD = oldD ? oldD.toString().replace(/;/g, ",") : "";
    newD = newD ? newD.toString().replace(/;/g, ",") : "";
    let difference = [1];
    const keyNames = ["Auditor", "Co_Auditor", "Auditee"];
    if (keyNames.includes(key)) {
      let arr1 = oldD.split(",");
      let arr2 = newD.split(",");
      arr1 = arr1.filter((n) => (n.trim() ? true : false));
      arr2 = arr2.filter((n) => (n.trim() ? true : false));
      difference = arr2.filter((x) => !arr1.includes(x));
      if (!difference.length) {
        difference = arr1.filter((x) => !arr2.includes(x));
      }
    }
    return difference.length ? true : false;
  };

  const renderHtml = (html) => {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };

  const newStyles = {
    variables: {
      light: {
        highlightBackground: "#fefed5",
        highlightGutterBackground: "#ffcd3c",
      },
    },
    line: {
      padding: "10px 2px",
      "&:hover": {
        background: "#a26ea1",
      },
    },
  };

  return (
    <div ref={ref} className="container-main" style={{ minHeight: height }}>
      <Grid container>
        <Grid item xs={2} sx={{ mt: 2 }}>
          <div className="menuBox">Audit Trail</div>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          pt: 3,
          pb: 3,
          mb: 3,
          backgroundColor: "#fff",
          border: "2px dashed hsla(0,0%,50.2%,.2)!important",
        }}
      >
        <Grid item xs={6} sx={{ pl: 7 }}>
          <FormControl sx={{ m: 1, width: "40ch" }}>
            <InputLabel id="demo-simple-select-label">Source</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={audit.source}
              label="Source"
              onChange={(e) => handleChange("source", e.target.value)}
              sx={{ height: "55px", width: "300px" }}
            >
              <MenuItem key={"Vx"} value="Vx">
                Vx
              </MenuItem>
              <MenuItem key={"Rx"} value="Rx">
                Rx
              </MenuItem>
              <MenuItem key={"common"} value="common">
                Common
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ pl: 7 }}>
          {audit.source !== "common" ? (
            <FormControl sx={{ m: 1, width: "40ch" }}>
              <SearchableDropdown
                value={resetOptions}
                options={auditList}
                setResetOptions={setResetOptions}
                inputValue={inputValue}
                setInputValue={setInputValue}
                loading={loading}
                handleChange={handleChangeNumber}
                variant="outlined"
                customStyle={{
                  width: "300px",
                  // "& .MuiOutlinedInput-root": { padding: "2px" },
                  // "& .MuiInputLabel-root": { lineHeight: "0.9em" },
                }}
                paperWidth="100%"
                label="Audit Number"
                placeholder="Audit Number"
                background="NO"
              />
              {/* <InputLabel id="demo-simple-select-label">
                Audit Number
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={audit.number}
                label="Audit Number"
                onChange={(event) => handleChange("number", event.target.value)}
                inputProps={{ style: { textAlign: 0 } }}
                sx={{ height: "50px" }}
              >
                {auditList.map((audit) => {
                  return (
                    <MenuItem value={audit.audit_number}>
                      {audit.audit_number}
                    </MenuItem>
                  );
                })}
              </Select> */}
            </FormControl>
          ) : (
            <FormControl sx={{ m: 1, width: "40ch" }}>
              <InputLabel id="demo-simple-select-label">Feature</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={audit.number}
                label="Feature"
                onChange={(event) => handleChange("number", event.target.value)}
                inputProps={{ style: { textAlign: 0 } }}
                sx={{ height: "55px", width: "300px" }}
              >
                <MenuItem key={"Email Template"} value="Email Template">
                  Email Template Update
                </MenuItem>
                <MenuItem key={"Feedback Template"} value="Feedback Template">
                  ARA Template Update
                </MenuItem>
                <MenuItem
                  key={"Retention Period Setting"}
                  value="Retention Period Setting"
                >
                  ARA Retention Period Settings
                </MenuItem>
                <MenuItem
                  key={"Unstructured Keywords Settings"}
                  value="Unstructured Keywords Settings"
                >
                  Unstructured Keywords Settings
                </MenuItem>
                {/* <MenuItem key={"Site Management"} value="Site Management">
                  Site Management
                </MenuItem>
                <MenuItem key={"Template Section Management"} value="Template Section Management">
                Template Section Management
                </MenuItem> */}
              </Select>
            </FormControl>
          )}
        </Grid>
        {audit.source !== "common" ? (
          <>
            <Grid item xs={6} sx={{ pl: 7 }}>
              <Box
                sx={{
                  height: 15,
                  p: 2,
                  display: "flex",
                  gridAutoFlow: "row",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gridTemplateRows: "repeat(2, 50px)",
                  gap: 1,
                }}
              >
                <Item
                  sx={{
                    gridColumn: "1",
                    fontSize: "0.875rem",
                    fontWeight: "600",
                  }}
                >
                  Audit Title :
                </Item>
                <Item sx={{ fontSize: "0.875rem", fontWeight: "500" }}>
                  {window.customEscape(details.audit_title)}
                </Item>
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ pl: 7 }}>
              <Box
                sx={{
                  height: 15,
                  p: 2,
                  display: "flex",
                  gridAutoFlow: "row",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gridTemplateRows: "repeat(2, 50px)",
                  gap: 1,
                }}
              >
                <Item
                  sx={{
                    gridColumn: "1",
                    fontSize: "0.875rem",
                    fontWeight: "600",
                  }}
                >
                  Site Name :
                </Item>
                <Item sx={{ fontSize: "0.875rem", fontWeight: "500" }}>
                  {details.site_description}
                </Item>
              </Box>
            </Grid>

            <Grid item xs={6} sx={{ pl: 7 }}>
              <Box
                sx={{
                  height: 50,
                  p: 2,
                  display: "flex",
                  gridAutoFlow: "row",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gridTemplateRows: "repeat(2, 30px)",
                  gap: 1,
                }}
              >
                <Item
                  sx={{
                    gridColumn: "1",
                    fontSize: "0.875rem",
                    fontWeight: "600",
                    ml: "0px",
                  }}
                >
                  Audit Start Date :
                </Item>
                <Item sx={{ fontSize: "0.875rem", fontWeight: "500" }}>
                  {details.planned_fieldwork_start_date !== undefined
                    ? window.getDate(details.planned_fieldwork_start_date)
                    : ""}
                </Item>
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ pl: 7 }}>
              <Box
                sx={{
                  height: 50,
                  p: 2,
                  display: "flex",
                  gridAutoFlow: "row",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gridTemplateRows: "repeat(2, 50px)",
                  gap: 1,
                }}
              >
                <Item
                  sx={{
                    gridColumn: "1",
                    fontSize: "0.875rem",
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  Audit End Date :
                </Item>
                <Item sx={{ fontSize: "0.875rem", fontWeight: "500" }}>
                  {details.planned_fieldwork_end_date !== undefined
                    ? window.getDate(details.planned_fieldwork_end_date)
                    : ""}
                </Item>
              </Box>
            </Grid>
          </>
        ) : (
          ""
        )}
      </Grid>

      {headers.length ? (
        <DataView
          columns={headers}
          rows={data}
          menuName="Audit Trails"
          checkboxSelection={false}
          auditTrailSource={audit.source}
          auditNumber={audit?.number}
          featureName={featureName}
        />
      ) : (
        ""
      )}
      <Dialog
        open={showDiff}
        fullWidth={true}
        maxWidth={modalMaxWidth}
        fullScreen={false}
        onClose={handleCloseDiff}
        paperProps={{
          style: { borderRadius: 2 },
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container>
              <Grid item xs={12}>
                <FormControl sx={{ width: "100%", pt: 2 }}>
                  <table
                    style={{
                      border: "2px solid #15717D",
                      borderCollapse: "collapse",
                    }}
                  >
                    <tr>
                      <th
                        colspan="2"
                        style={{
                          border: "2px solid #15717D",
                          borderCollapse: "collapse",
                          padding: "5px",
                          color: "#fff",
                          backgroundColor: "#15717D",
                        }}
                      >
                        <strong>Changed Info</strong>
                      </th>
                    </tr>
                    {/* //{Object.keys(oldValues).map((key) => ( key !== "audit_id" &&
                          oldValues[key] !== newValues[key] &&*/}
                    {Object.entries(oldValues).map(([key, value], i) => (
                      <>
                        {!["audit_id", "Updated on", "Updated by"].includes(
                          key
                        ) &&
                          oldValues[key] !== newValues[key] &&
                          isValidTableRow(
                            oldValues[key],
                            newValues[key],
                            key
                          ) && (
                            <tr>
                              {featureName !== "Email Template" && (
                                <th
                                  style={{
                                    width: "30%",
                                    border: "2px solid #15717D",
                                    borderCollapse: "collapse",
                                    // padding: "3px",
                                    // border: "none",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "0.8rem", fontWeight: 700 }}
                                  >
                                    {fieldsNames[key]
                                      ? fieldsNames[key]
                                      : window
                                          .toCamalizeCase(key)
                                          .replace(/_/g, " ")}
                                  </Typography>
                                </th>
                              )}
                              <td
                                style={{
                                  border: "2px solid #15717D",
                                  borderCollapse: "collapse",
                                  paddingLeft: "3px",
                                  // border: "none",
                                }}
                              >
                                {!commonDiff &&
                                  showDifference(
                                    oldValues[key],
                                    newValues[key],
                                    key
                                  )}

                                {commonDiff && (
                                  <ReactDiffViewer
                                    // styles={newStyles}
                                    oldValue={
                                      oldValues[key]
                                        ? oldValues[key].toString()
                                        : ""
                                    }
                                    newValue={
                                      newValues[key]
                                        ? newValues[key].toString()
                                        : ""
                                    }
                                    splitView={false}
                                    hideLineNumbers={true}
                                    showDiffOnly={false}
                                    compareMethod={
                                      WORDSWITHSPACE
                                        ? DiffMethod.CHARS
                                        : DiffMethod.LINES
                                    }
                                    renderContent={renderHtml}
                                    useDarkTheme={false}
                                  />
                                )}
                              </td>
                            </tr>
                          )}
                      </>
                    ))}
                  </table>
                  <div style={{ paddingTop: "20px", display: "flex" }}>
                    <div>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            backgroundColor: "rgb(228 0 48 / 72%)",
                            width: "15px",
                            height: "15px",
                          }}
                        ></div>
                        <div
                          style={{
                            paddingLeft: "3px",
                            paddingRight: "3px",
                          }}
                        >
                          Removed{"  "}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            backgroundColor: "hwb(152deg 0% 47% / 66%)",
                            width: "15px",
                            height: "15px",
                          }}
                        ></div>
                        <div
                          style={{
                            paddingLeft: "3px",
                          }}
                        >
                          Added
                        </div>
                      </div>
                    </div>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCloseDiff}
            sx={{ "& > button": { m: 2 } }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
// export default AuditTrailContent;
export default withAITracking(reactPlugin, AuditTrailContent);
