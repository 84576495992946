import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Autocomplete from "@mui/material/Autocomplete";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SaveIcon from "@mui/icons-material/Save";
import OutlinedInput from "@mui/material/OutlinedInput";
// import FormHelperText from "@mui/material/FormHelperText";

import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Link from "@mui/material/Link";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import CopyAllTwoToneIcon from "@mui/icons-material/CopyAllTwoTone";
import SearchIcon from "@mui/icons-material/Search";

import { SpinnerCircular } from "spinners-react";
import * as https from "https";
import { v4 as uuidv4 } from "uuid";
import { saveAs } from "file-saver";
import getHiddenDocList from "../Common/hiddenDocs";
// Components

import { useMsal, useAccount } from "@azure/msal-react";
import { loginRequest } from "../../authConfigmasl";
import P from "react-pagimagic";
import PageArrow from "../PageArrow/PageArrow";
import "./ASMReviewContent.css";
import { RegulationDataContext } from "./../Context/RegulationDataContext";
// Components
import FileUpload_DOCX from "../FileUpload/FileUpload_DOCX";
// import ReviewFilesUploadedList from "../ReviewFilesUploadedList/ReviewFilesUploadedList";
import UploadedFilesList from "../SortingSelectingTableUploadFile/SortingSelectingTableUploadFile";
import PDFViewer from "../Pdfview/PDFViewer";
import { ReviewDataContext } from "../Context/ReviewDataContext";
import { UserDataContext } from "../Context/UserDataContext";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { toast } from "react-toastify";
import * as rimaConstants from "../../rimaconstants";
import Loader from "../Loader/Loader";
import moment from "moment";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

console.log(rimaConstants);

const CURRENT_PAGE_INDEX = 0;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
function getStyles() {
  return {
    fontWeight: "400",
    fontSize: "13px",
  };
}
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 250,
    },
  },
};

const drpDownStyle = {
  width: "140px",
  marginBottom: "-4px",
};

/* Component: ResultContent */
export default function ResultContent() {
  const { accounts, instance } = useMsal();

  const account = useAccount(accounts[0] || {});
  const [loading, setLoading] = useState(false);
  const [dsources, setDsources] = useContext(RegulationDataContext);
  const [
    adminUser,
    setAdminUser,
    user,
    setUser,
    userSASToken,
    setUserSASToken,
  ] = useContext(UserDataContext);
  const [rerender, setRerender] = useState(false);

  const [isExporting, setIsExporting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const [isRecomendationsVisibile, setRecomendationsVisibile] = useState(false);

  const [isGettingRegulations, setIsGettingRegulations] = useState(false);

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const {
    selectedDoc,
    setSelectedDoc,
    selectedFile,
    selectedFileType,
    selectedFileAuditNumber,
    selectedFileRow,
    sessionFilesData,
    reviewResultsData,
    setReviewResultsData,
    findingIndex,
    setFindingIndex,
    findingTitle,
    setFindingTitle,
    predicted_internalValues,
    setPredicted_internalValues,
    predicted_internalKeys,
    setPredicted_internalKeys,
    diFindings,
    setDIFindings,
    selectedDIFinding,
    setSelectedDIFinding,
    selectedRegulationFilterFiles,
    setSelectedRegulationFilterFiles,
    resultDocumentSourceTypeSelectedFiles,
    setResultDocumentSourceTypeSelectedFiles,
    predicted_externalDocumentTitles,
    setPredicted_externalDocumentTitles,
    titlesChecked,
    setTitlesChecked,
    qmsPolicyChecked,
    setQmsPolicyChecked,
    policyDocChecked,
    setPolicyDocChecked,
    fileRecoReceived,
    setfileRecoReceived,
    userSelectedTitlesFileReview,
    setUserSelectedTitlesFileReview,
    userSelectedTitleFileReview,
    setUserSelectedTitleFileReview,
    setSelectedAuditFileFindings,
    readyForPreview,
    setReadyForPreview,
    irDocList,
    setIRDocList,
    isGetRegulationsActive,
    setIsGetRegulationsActive,
  } = useContext(ReviewDataContext);

  const onDIFindingChange = (event) => {
    if (findingIndex === "" || diFindings.length === 0) return;

    diFindings[findingIndex] = event.target.value;
    setDIFindings(diFindings);
    setSelectedDIFinding(diFindings[findingIndex]);
  };

  const [newDocLoaded, setNewDocLoaded] = useState(false);
  useEffect(() => {
    auditInternalDataHelper(0);
    qmsPoliciesDataHelper(0);
    predicted_externalDocumentTitleDataHelper(0);
    setSelectedRegulationFilterFiles(
      dsources
        .filter((source) => source.is_external)
        .map((x) => x.document_source)
    );
  }, [reviewResultsData]);

  useEffect(() => {
    if (newDocLoaded) {
      setFindingIndex(0);
      setSelectedDIFinding(diFindings[0]);
      setUserSelectedTitleFileReview(userSelectedTitlesFileReview[0]);

      setFindingTitle(reviewResultsData.results[0].finding);
      if (userSelectedTitlesFileReview[0] !== "")
        getPredictedExternalDocs(userSelectedTitlesFileReview[0]);
      else getRegulationsForTitles(titlesChecked[0]);
    }
    setNewDocLoaded(false);
  }, [newDocLoaded]);

  const getDocumentSuggestions = async (
    filePath,
    fileType,
    restore = false,
    selectedItem = null
  ) => {
    if (account) {
      setIsSearching(true);
      const getRecoUrl =
        rimaConstants.REACT_APP_API_URL + "/api/v1/review/recommendations";
      const data = {
        path: filePath,
        filetype: fileType === 0 ? "internal" : "external",
      };
      setLoading(true);
      await axios
        .post(getRecoUrl, data)
        .then(async (response) => {
          // setDsources(response.data.document_sources);

          var findings = response.data;
          console.log("Response Data--->\n", response.data);
          var hiddendocs = [
            ...new Set(
              (await getHiddenDocList()).map((x) => {
                return x.document_storage_url.split(".").slice(0, -1).join(".");
              })
            ),
          ];
          for (var i = 0; i < findings.results.length; i++) {
            var doclist = findings.results[i].predicted_internal;
            findings.results[i].predicted_internal = doclist.filter(
              (x) =>
                !hiddendocs.includes(
                  x.document_storage_url.split(".").slice(0, -1).join("."),
                  0
                )
            );
          }

          if (
            findings !== null &&
            findings.length !== 0 &&
            findings.results !== undefined &&
            findings.results.length !== 0
          ) {
            setfileRecoReceived(1);
            setReviewResultsData(findings);
            var titlesChecked = new Array(findings.results.length);
            var qmsPolicyChecked = new Array(findings.results.length);
            var policyDocChecked = new Array(findings.results.length);
            var diFindings = new Array(findings.results.length);
            var userSelectedTitlesFileReview1 = new Array(
              findings.results.length
            );

            for (var i = 0; i < findings.results.length; i++) {
              titlesChecked[i] = new Array({});
              qmsPolicyChecked[i] = new Array({});
              policyDocChecked[i] = new Array({});
              diFindings[i] = "";
              userSelectedTitlesFileReview1[i] = "";
            }
            if (restore) {
              console.log(selectedItem.findings);
              if (selectedItem.findings) {
                var storedFindings = Object.keys(selectedItem.findings);

                for (var i = 0; i < storedFindings.length; i++) {
                  for (var j = 0; j < findings.results.length; j++)
                    if (findings.results[j].finding === storedFindings[i]) {
                      titlesChecked[j] =
                        selectedItem.findings[storedFindings[i]].title;
                      diFindings[j] =
                        selectedItem.findings[storedFindings[i]].difinding;
                      userSelectedTitlesFileReview1[j] =
                        selectedItem.findings[
                          storedFindings[i]
                        ].userselectedtitle;

                      policyDocChecked[j] =
                        selectedItem.findings[
                          storedFindings[i]
                        ].externalregulations;
                    }
                }
              }
            }

            setTitlesChecked([...titlesChecked]);
            setQmsPolicyChecked([...qmsPolicyChecked]);
            setPolicyDocChecked([...policyDocChecked]);
            setDIFindings([...diFindings]);
            setUserSelectedTitlesFileReview([...userSelectedTitlesFileReview1]);

            setNewDocLoaded(true);
            setIsSearching(false);
            setRecomendationsVisibile(true);
            setLoading(false);
          } else {
            console.log("No recommendations received for selected document");
            //TODO: alert.warning("No recommendations  received for selected document...")
            setfileRecoReceived(3);
            setLoading(false);
          }
        })
        .finally((e) => {
          setIsSearching(false);
          setLoading(false);
          return [];
        });
    }
  };

  const handleIntlRegFilterChange = (e) => {
    //TODO:
    setSelectedRegulationFilterFiles(e.target.value);
    setCurrentPageIndex(0);
    setItemsPerPage(5);
  };

  //Titles
  const externDocsPredictedFromSearch = (data) => {
    var noduplicates_list = data;
    noduplicates_list = noduplicates_list.filter(
      (tag, index, array) =>
        array.findIndex(
          (t) => t.document_title === tag.document_title && t.is_external === 1
        ) === index
    );
    setPredicted_externalDocumentTitles(
      noduplicates_list
        // .filter((item, index) => index < 5)
        .map((item) => ({
          document_title: item.document_title,
          page: item.page_number,
          document_name: item.document_name,
          document_source: item.document_source,
          document_storage_url: `${userSASToken.storageUri}${rimaConstants.ASM_ROOT_CONTAINER}/${item.document_storage_url}`,
        }))
    );
  };

  useEffect(() => {
    if (irDocList.length > 0) externDocsPredictedFromSearch(irDocList);
    else setPredicted_externalDocumentTitles([]);
  }, [irDocList]);

  const getPredictedExternalDocs = async (searchString) => {
    setIRDocList([]);
    if (searchString.trim() === "") {
      //externDocsPredictedFromSearch([])
      setIsGetRegulationsActive(false);
      return;
    }
    var qryParams = {
      speller: "lexicon",
      queryLanguage: "en-us",
      search: searchString,
      $count: "true",
      "api-version": "2021-04-30-Preview",
      $top: 2000,
      highlight:
        "page_content, document_name, document_source_description, document_title",
      $filter: "is_external eq 1",
    };
    setIsGettingRegulations(true);
    setIsGetRegulationsActive(false);
    setLoading(true);
    axios
      .post(`${rimaConstants.REACT_APP_API_URL}/api/v1/docprops/search`, {
        queryString: qryParams,
      })
      .then((resJSONData) => {
        //externDocsPredictedFromSearch(resJSONData.data.value);
        setIRDocList(resJSONData.data.value);
        setIsGettingRegulations(false);
        setIsGetRegulationsActive(false);
        if (
          resJSONData &&
          resJSONData.data.value &&
          resJSONData.data.value.length > 0
        )
          toast("Related international regulations data received.");
        else toast.warn("No international regulations data received.");
        setLoading(false);
        setRerender(!rerender);
      })
      .catch((e) => {
        console.log("No data received", e);
        toast.warn("No international regulations data received.");
        setIsGettingRegulations(false);
        setIsGetRegulationsActive(false);
        setLoading(false);
      });
  };
  /* *Handle titles checked */
  const overrideTitlesChecked = (event, item) => {
    var values = [item];
    console.log("overrideTitlesChecked");
    policyDocChecked[findingIndex] = [];
    // if (item !== null && values.length) {
    //   var searchString = values.map((x) => x.document_title).join(" | ");
    //   getPredictedExternalDocs(searchString);
    // }
  };

  const getRegulationsForTitles = async (list) => {
    var searchString = list
      .filter((x) => x.document_title)
      .map((x) => (x.document_title ? x.document_title : null))
      .join(" | ");
    setIRDocList([]);
    if (searchString.trim() === "") {
      //externDocsPredictedFromSearch([])
      setIsGetRegulationsActive(false);
      return;
    }

    var qryParams = {
      speller: "lexicon",
      queryLanguage: "en-us",
      search: searchString,
      $count: "true",
      "api-version": "2021-04-30-Preview",
      $top: 2000,
      highlight:
        "page_content, document_name, document_source_description, document_title",
      $filter: "is_external eq 1",
    };
    setIsGettingRegulations(true);
    setIsGetRegulationsActive(false);
    setLoading(true);
    axios
      .post(`${rimaConstants.REACT_APP_API_URL}/api/v1/docprops/search`, {
        queryString: qryParams,
      })
      .then((resJSONData) => {
        //externDocsPredictedFromSearch(resJSONData.data.value);
        setIRDocList(resJSONData.data.value);
        setIsGettingRegulations(false);
        setIsGetRegulationsActive(false);
        if (
          resJSONData &&
          resJSONData.data.value &&
          resJSONData.data.value.length > 0
        )
          toast("Related international regulations data received.");
        else toast.warn("No international regulations data received.");
        setLoading(false);
        setRerender(!rerender);
      })
      .catch((e) => {
        console.log("No data received", e);
        toast.warn("No international regulations data received.");
        setIsGettingRegulations(false);
        setIsGetRegulationsActive(false);
        setLoading(false);
      });
  };
  const handleTitlesChecked = (event, item) => {
    const { value } = event.target;
    if (userSelectedTitlesFileReview[findingIndex] === "") {
      var arr = titlesChecked[findingIndex]; //Get previouse list
      let isUnchecking =
        arr.findIndex((x) => x.document_name === item.document_name) !== -1; //Find item exists in previous list

      //Clear out any of the selected international regulations, as the results are not valid any more!
      //There is no undo for this
      policyDocChecked[findingIndex] = [];
      setIRDocList([]);
      //setPredicted_externalDocumentTitles([])

      var newlist = isUnchecking
        ? arr.filter((c) => c.document_name !== item.document_name) //If item exists remove
        : [...arr, item]; //else add to the list
      titlesChecked[findingIndex] = newlist;
      setTitlesChecked(titlesChecked);
      //getRegulationsForTitles(newlist)
      if (newlist.length > 1) setIsGetRegulationsActive(true);
      else setIsGetRegulationsActive(false);
      setRerender(!rerender);
    }
  };
  const renderRulesPredicted_internalValueList = (list) => {
    return list.map((item) => {
      //
      var filePath =
        userSASToken.storageUri +
        "raw/" +
        encodeURI(item.document_storage_url) +
        "?" +
        userSASToken.storageAccessToken;
      var referrerid = uuidv4();
      return (
        <>
          <FormControlLabel
            control={
              <Checkbox
                className="gsk-checkbox"
                onChange={(e) => handleTitlesChecked(e, item)}
                checked={
                  titlesChecked[findingIndex]
                    ? titlesChecked[findingIndex].filter(
                        (x) =>
                          x.document_title === item.document_title &&
                          x.document_name === item.document_name
                      ).length > 0
                    : false
                }
              />
            }
            label={<> {item.document_title} - </>}
          />
          {/* <Link
            href={`/pdf?docid=${referrerid}#page${1}`}
            target="_blank"
            onClick={() => {
              localStorage.setItem(referrerid, filePath);
              localStorage.setItem(`${referrerid}-page`, 1);
            }}
          >
            {" "}
            {item.document_storage_url.split("/").pop()}{" "}
          </Link> */}

          {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                let url = `${filePath}&ts=${moment().utc().unix()}#page=1`;
                let a = document.createElement("a");
                a.target = "_blank";
                let veevavault = item?.document_download_url?.includes(
                  "veevavault"
                )
                  ? true
                  : false;
                if (veevavault) {
                  a.href = `${item.document_download_url}?anQS=page1&annotate=false`;
                } else {
                  a.href = url;
                  // a.href = `/documentviewer/${window.base64_encode(url)}`;
                }
                a.click();
              }}
            >
              {" "}
              {item.document_storage_url.split("/").pop()}{" "}
            </a>
          }
          <br />
        </>
      );
    });
  };

  //Links

  const handlePolicyDocChecked = (event, item) => {
    const { value } = event.target;
    var arr = policyDocChecked[findingIndex];
    var newarr =
      arr.findIndex(
        (x) => x.document_storage_url === item.document_storage_url
      ) !== -1
        ? arr.filter(
            (c) => c.document_storage_url !== item.document_storage_url
          )
        : [...arr, item];
    policyDocChecked[findingIndex] = newarr;
    setRerender(!rerender);
    //setPolicyDocChecked(policyDocChecked);
    //setPolicyDocChecked(policyDocChecked.includes(item) ?
    //policyDocChecked[findingIndex].filter(c => c !== item) : [...policyDocChecked[findingIndex], item]);
  };

  const renderRulesInternationalRegulations = (list) => {
    return list.map((item) => {
      if (!selectedRegulationFilterFiles.includes(item.document_source))
        return "";

      var filePath =
        encodeURI(item.document_storage_url) +
        "?" +
        userSASToken.storageAccessToken;
      var referrerid = uuidv4();
      return (
        <>
          <FormControlLabel
            control={
              <Checkbox
                className="gsk-checkbox"
                onChange={(e) => handlePolicyDocChecked(e, item)}
                checked={
                  policyDocChecked[findingIndex]
                    ? policyDocChecked[findingIndex].filter(
                        (x) =>
                          x.document_title === item.document_title &&
                          x.document_name === item.document_name
                      ).length > 0
                    : false
                }
              />
            }
            label=""
          />

          {/* <Link href={filePath} target="_blank">
            {" "}
            {item.document_title}{" "}
          </Link> */}
          {/* <Link
            href={`/pdf?docid=${referrerid}#page${item.page}`}
            target="_blank"
            onClick={() => {
              localStorage.setItem(referrerid, filePath);
              localStorage.setItem(`${referrerid}-page`, item.page);
            }}
          >
            {" "}
            {item.document_title}{" "}
          </Link> */}
          {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                let url = `${filePath}&ts=${moment().utc().unix()}#page=${
                  item.page
                }`;
                let a = document.createElement("a");
                a.target = "_blank";
                a.href = url;
                // a.href = `/documentviewer/${window.base64_encode(url)}`;
                a.click();
              }}
            >
              {" "}
              {item.document_title}{" "}
            </a>
          }
          <sup>
            <small style={{ color: "#15717d" }}>{item.document_source}</small>
          </sup>
          <br />
        </>
      );
    });
  };

  //Save & Restore functionality
  const saveUserSelectedFindings = (e) => {
    var saveobj = {
      path: selectedFile,
      filetype: selectedFileType,
      findings: {},
    };

    for (var i = 0; i < reviewResultsData.results.length; i++) {
      var userselectedtitle = "";
      if (userSelectedTitlesFileReview[i] !== "") {
        userselectedtitle = userSelectedTitlesFileReview[i];
      }
      var key = reviewResultsData.results[i].finding;
      var fndobj = {
        title: titlesChecked[i],
        userselectedtitle: userselectedtitle,
        externalregulations: policyDocChecked[i],
        difinding: diFindings[i], //yes/no"
      };
      saveobj.findings[key] = fndobj;
    }

    if (account) {
      setIsSaving(true);
      const saveAPI = rimaConstants.REACT_APP_API_URL + "/api/v1/review/save";
      const saveObj = {
        audit_number: selectedFileAuditNumber,
        audit_type: selectedFileType === "internal" ? "0" : "1",
        document_name: selectedFile.split("?")[0].split("/").reverse()[0],
        document_storage_url: selectedFile,
        audit_findings_details: JSON.stringify(saveobj.findings),
      };
      setLoading(true);
      axios
        .put(saveAPI, saveObj)
        .then((response) => {
          try {
            if (response.data.data[0][0].audit_finding_document_id)
              toast("Audit finding details saved.");
            setSelectedAuditFileFindings(saveobj.findings);
            sessionFilesData[
              sessionFilesData.findIndex(
                (x) =>
                  x.auditId === selectedFileAuditNumber &&
                  x.reviewFileUploadedPath === selectedFile
              )
            ].saved = true;
            setLoading(false);
          } catch (e) {
            toast.error("Failed to save finding details.");
            setLoading(false);
          }
          setIsSaving(false);
        })
        .finally((e) => {
          setIsSaving(false);
        });
    }
  };

  //Export API call
  const exportSelectedItems = (e, save = false) => {
    var exportobj = {
      path: selectedFile,
      filetype: selectedFileType,
      findings: {},
    };

    for (var i = 0; i < reviewResultsData.results.length; i++) {
      if (userSelectedTitlesFileReview[i] !== "") {
        var title = userSelectedTitlesFileReview[i].split(" - [")[0];
        var regulation =
          userSelectedTitlesFileReview[i].split(" - [").length > 1
            ? userSelectedTitlesFileReview[i]
                .split("[")
                .reverse()[0]
                .split("]")[0] + "\r\n"
            : "";
        var key = reviewResultsData.results[i].finding;
        for (k = 0; k < policyDocChecked[i].length; k++) {
          if (policyDocChecked[i][k]) {
            if (policyDocChecked[i][k].document_name)
              regulation =
                regulation + policyDocChecked[i][k].document_title + "\r\n";
          }
        }
        var fndobj = {
          title: title,
          regulation: regulation,
          difinding: diFindings[i], //yes/no"
        };
        exportobj.findings[key] = fndobj;
      } else {
        var title = "";
        var regulation = "";
        for (var k = 0; k < titlesChecked[i].length; k++) {
          if (titlesChecked[i][k]) {
            if (titlesChecked[i][k].document_title)
              title = title + titlesChecked[i][k].document_title + "\r\n";
            if (titlesChecked[i][k].document_name)
              regulation =
                regulation + titlesChecked[i][k].document_name + "\r\n";
          }
        }
        for (k = 0; k < policyDocChecked[i].length; k++) {
          if (policyDocChecked[i][k]) {
            if (policyDocChecked[i][k].document_name)
              regulation =
                regulation + policyDocChecked[i][k].document_title + "\r\n";
          }
        }
        var key = reviewResultsData.results[i].finding;
        var fndobj = {
          title: title,
          regulation: regulation,
          difinding: diFindings[i], //yes/no"
        };
        exportobj.findings[key] = fndobj;
      }
    }

    if (account) {
      setIsExporting(true);
      const exportAPI = `${rimaConstants.REACT_APP_API_URL}/api/v1/review/export`;
      let axiosConfig = {
        timeout: 5000 * 60,
        responseType: "blob",
      };
      setLoading(true);
      axios
        .post(exportAPI, exportobj, axiosConfig)
        .then((response) => {
          if (response) {
            let b = new Blob([response.data], {
              type: response.headers["content-type"] + ";char-type:UTF-8",
            });
            saveAs(
              b,
              "modified_" + selectedFile.split("?")[0].split("/").reverse()[0]
            );
          }
          setIsExporting(false);
          setLoading(false);
        })
        .finally((e) => {
          setIsExporting(false);
          setLoading(false);
        });
    }
  };

  const findingHandleChange = (event) => {
    if (event.target.value === "") return;
    setFindingIndex(event.target.value);
    setSelectedDIFinding(diFindings[event.target.value]);
    setUserSelectedTitleFileReview(
      userSelectedTitlesFileReview[event.target.value]
    );
    setSelectedRegulationFilterFiles(
      dsources
        .filter((source) => source.is_external)
        .map((x) => x.document_source)
    );
    if (event.target.value !== "") {
      setFindingTitle(reviewResultsData.results[event.target.value].finding);
      auditInternalDataHelper(event.target.value);
      qmsPoliciesDataHelper(event.target.value);
      predicted_externalDocumentTitleDataHelper(event.target.value);
      if (userSelectedTitlesFileReview[event.target.value] !== "")
        getPredictedExternalDocs(
          userSelectedTitlesFileReview[event.target.value]
        );
      else getRegulationsForTitles(titlesChecked[event.target.value]);
    } else {
      setFindingTitle("");
      auditInternalDataHelper("");
      qmsPoliciesDataHelper("");
      predicted_externalDocumentTitleDataHelper("");
    }
  };

  const auditInternalDataHelper = (fIndex) => {
    if (fIndex !== "" && reviewResultsData && reviewResultsData.results) {
      setPredicted_internalValues(
        reviewResultsData.results[fIndex].predicted_internal
      );
    } else {
      setPredicted_internalValues("");
    }
  };
  // suggestions

  const qmsPoliciesDataHelper = (fIndex) => {
    if (fIndex !== "" && reviewResultsData && reviewResultsData.results) {
      setPredicted_internalKeys(
        reviewResultsData.results[fIndex].predicted_internal
      );
    } else {
      setPredicted_internalKeys("");
    }
  };

  const predicted_externalDocumentTitleDataHelper = (fIndex) => {
    // const predicted_externalDocumentTitlesData = await ;
    if (fIndex !== "" && reviewResultsData && reviewResultsData.results) {
      var noduplicates_list =
        reviewResultsData.results[fIndex].predicted_external;
      noduplicates_list = noduplicates_list.filter(
        (tag, index, array) =>
          array.findIndex(
            (t) =>
              t.document_title === tag.document_title && t.is_external === 1
          ) === index
      );
      setPredicted_externalDocumentTitles(
        noduplicates_list.map((item) => ({
          document_title: item.document_title,
          page: item.page_number,
          document_name: item.document_name,
          document_source: item.document_source,
          document_storage_url: `${userSASToken.storageUri}${rimaConstants.ASM_ROOT_CONTAINER}/${item.document_storage_url}`,
        }))
      );
    } else {
      setPredicted_externalDocumentTitles("");
    }
  };

  const documentSourceTypeHandler = (e) => {
    setResultDocumentSourceTypeSelectedFiles(e.target.value);
    // reviewResultsData.results.filter(function (item) {
    //
    //     item.predicted_external.document_source ===
    //       resultDocumentSourceTypeSelectedFiles
    //   );
    // });
  };
  const previewBtnHandleChange = async (selectedDoc, restore = false) => {
    if (selectedDoc.auditId.trim() === "" && selectedDoc.saved !== true) {
      toast.error("Audit number is mandatory.");
      return;
    }

    if (selectedDoc) {
      setfileRecoReceived(0);
      if (restore && selectedDoc.saved === true) {
        if (selectedDoc.findings)
          await getDocumentSuggestions(
            selectedDoc.reviewFileUploadedPath,
            selectedDoc.isInternal ? 0 : 1,
            restore,
            selectedDoc
          );
        else {
          toast.warn("No associated findings saved.");
        }
      } else {
        await getDocumentSuggestions(
          selectedDoc.reviewFileUploadedPath,
          selectedDoc.isInternal ? 0 : 1
        );
      }
    }
  };
  const onDeleteHandler = async (deletedItem) => {
    if (selectedDoc.auditId === deletedItem.auditId) {
      setfileRecoReceived(0);
      setReadyForPreview(false);
    }
  };

  const onFileSelectionChange = async (selectedItem) => {
    //clearAll
    // setFindingIndex(0)
    // setFindingTitle("")
    // setPredicted_internalValues([])
    // setPredicted_internalKeys([])
    // setSelectedDIFinding([])
    // setSelectedDIFinding("")
    // setPredicted_externalDocumentTitles([])

    setSelectedDoc(selectedItem);
    setRecomendationsVisibile(false);
    setReadyForPreview(true);
    //restoreFindingForSelection(selectedItem)
    // setTitlesChecked([[]]);
    // setQmsPolicyChecked([[]]);
    // setPolicyDocChecked([[]]);
    // setUserSelectedTitlesFileReview([]);

    await previewBtnHandleChange(selectedItem, true);
  };
  let inputRef;
  return (
    <Grid container spacing={2}>
      {/* {loading ? <Loader /> : ""} */}
      {loading ? window.showLoader() : window.hideLoader()}
      <Grid item xs={12}>
        <Box>
          <FileUpload_DOCX />

          <UploadedFilesList
            onFileSelectionChange={onFileSelectionChange}
            onDelete={onDeleteHandler}
          />

          <Box sx={{ mt: "15px", textAlign: "right" }}>
            <Button
              variant="contained"
              className="gsk-btn primary pill"
              // disabled={previewBtnDisabled ? false : true}
              disabled={
                isSearching ||
                selectedFile === "" ||
                selectedFile === null ||
                selectedFile === undefined ||
                readyForPreview === false
              } //|| selectedDocument === ""
              onClick={() => previewBtnHandleChange(selectedDoc)}
              startIcon={<VisibilityIcon />}
            >
              {!isSearching
                ? "Get Recommendations"
                : "Getting Recommendations ...  "}
              &nbsp;
              <SpinnerCircular
                enabled={isSearching}
                size="18"
                сolor="#ffffff"
              />
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={5}>
        {readyForPreview ? (
          <PDFViewer url={selectedFile}></PDFViewer>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="20vh"
          >
            <strong>Document preview area.</strong>
          </Box>
        )}
      </Grid>
      <Grid item xs={12} md={7}>
        {!isRecomendationsVisibile ? (
          <div
            style={{
              visibility: isRecomendationsVisibile ? "hidden" : "visible",
            }}
          >
            <span style={{ whiteSpace: "pre-line" }}>
              After Uploading and Selecting a document, click on{" "}
              <b>Get Recommendations</b> to get recommendations
            </span>
          </div>
        ) : (
          ""
        )}

        {fileRecoReceived === 3 ? (
          <div style={{ margin: "50px 0px " }}>
            <span>No recommendations found.</span>
          </div>
        ) : fileRecoReceived === 1 ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Grid
                  container
                  direction="column"
                  sx={{
                    background: "#f5f5f5",
                    padding: "15px",
                    mb: "15px",
                    minHeight: "70px",
                  }}
                >
                  <Typography gutterBottom variant="title">
                    Select findings:
                  </Typography>
                  <FormControl sx={{ m: 1, minWidth: 200 }}>
                    <Select
                      sx={{ minWidth: 200 }}
                      value={findingIndex}
                      onChange={findingHandleChange}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                    >
                      <MenuItem value="">
                        <em>Select Finding</em>
                      </MenuItem>

                      {reviewResultsData.results?.map((finding, index) => (
                        <MenuItem key={finding.finding} value={index}>
                          {finding.finding}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Box sx={{ background: "#f5f5f5", padding: "15px", mb: "15px" }}>
              <Typography gutterBottom variant="h6" component="h6">
                {/* Findings 01: */}
                {findingTitle}
              </Typography>
              <Typography gutterBottom variant="caption" component="p">
                (Select a title to get corresponding international regulations)
              </Typography>
              {predicted_internalValues.length > 0 ? (
                <FormGroup>
                  <P
                    list={predicted_internalValues.slice(0, 10)}
                    itemsPerPage={5}
                    currentPageIndex={CURRENT_PAGE_INDEX}
                    maximumVisiblePaginators={5}
                    renderChildren={renderRulesPredicted_internalValueList}
                    useDefaultStyles
                    showCounter
                    arrow={PageArrow}
                  />
                  <hr />
                  <label>
                    Search and select a title from below list in case you do not
                    have what you are looking in above list.
                    <br />{" "}
                    <small>
                      <b>
                        <sup>*</sup>NOTE: this will override the above
                        selection.
                      </b>
                    </small>
                  </label>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={8}>
                      <Autocomplete
                        disablePortal
                        //multiple
                        freeSolo
                        size="small"
                        id="titles-combo"
                        clearOnEscape="false"
                        options={
                          predicted_internalValues.length > 0
                            ? predicted_internalValues
                            : null
                        }
                        getOptionLabel={(option) =>
                          option.document_title
                            ? `${option.document_title} - [${option.document_name}]`
                            : ""
                        }
                        sx={{ minWidth: 300 }}
                        defaultValue={[]}
                        onChange={overrideTitlesChecked}
                        inputValue={userSelectedTitleFileReview}
                        onInputChange={(event, item) => {
                          if (
                            event &&
                            (event.type === "change" || event.type === "click")
                          ) {
                            if (
                              findingIndex === undefined ||
                              userSelectedTitlesFileReview.length === 0
                            )
                              return;
                            if (item === "") {
                              setPredicted_externalDocumentTitles([]);
                              if (titlesChecked[findingIndex].length === 1) {
                                //1 because initialized dummy record
                                setIsGetRegulationsActive(false);
                              } else setIsGetRegulationsActive(true);
                            } else setIsGetRegulationsActive(true);

                            if (
                              event.type === "click" &&
                              userSelectedTitlesFileReview[findingIndex] !== ""
                            ) {
                              policyDocChecked[findingIndex] = [];
                            }
                            userSelectedTitlesFileReview[findingIndex] = item;
                            setUserSelectedTitlesFileReview([
                              ...userSelectedTitlesFileReview,
                            ]);
                            setUserSelectedTitleFileReview(
                              userSelectedTitlesFileReview[findingIndex]
                            );
                          }
                        }}
                        onBlur={(e) => {
                          // if (e.target === inputRef)
                          //   if (userSelectedTitlesFileReview[findingIndex] !== undefined && userSelectedTitlesFileReview[findingIndex] !== "") {
                          //     getPredictedExternalDocs(userSelectedTitlesFileReview[findingIndex]);
                          //   }
                          //   else {
                          //     getRegulationsForTitles(titlesChecked[findingIndex])
                          //   }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select a title from drop down list or type text"
                            inputRef={(input) => {
                              inputRef = input;
                            }}
                            // label="Search and select a title in case you do not have what you are looking in above list "
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Button
                        variant="contained"
                        className="gsk-btn primary pill"
                        disabled={
                          isGettingRegulations || !isGetRegulationsActive
                        }
                        onClick={(e) => {
                          if (
                            userSelectedTitlesFileReview[findingIndex] !==
                              undefined &&
                            userSelectedTitlesFileReview[findingIndex] !== ""
                          ) {
                            policyDocChecked[findingIndex] = [];
                            getPredictedExternalDocs(
                              userSelectedTitlesFileReview[findingIndex]
                            );
                          } else {
                            policyDocChecked[findingIndex] = [];
                            getRegulationsForTitles(
                              titlesChecked[findingIndex]
                            );
                          }
                          setCurrentPageIndex(0);
                          setItemsPerPage(5);
                        }}
                      >
                        {!isGettingRegulations
                          ? "Get Regulations"
                          : "Getting Regulations"}
                      </Button>
                    </Grid>
                  </Grid>
                </FormGroup>
              ) : (
                ""
              )}
              <hr />
              <Grid container justify="space-between">
                <Grid item xs={6}>
                  <Typography gutterBottom variant="subtitle1" component="p">
                    International Regulations
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-end"
                  align="right"
                >
                  <Grid item align="right">
                    <Typography
                      gutterBottom
                      variant="subtitle2"
                      component="p"
                      sx={{ paddingTop: "4px" }}
                    >
                      Filter : &nbsp;&nbsp;
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Select
                      onChange={handleIntlRegFilterChange}
                      style={{
                        width: "200px",
                        height: "30px",
                        fontSize: "13px",
                        marginBottom: "5px",
                      }}
                      multiple
                      displayEmpty
                      value={selectedRegulationFilterFiles}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Select</em>;
                        }

                        setSelectedRegulationFilterFiles(selected);
                        return selected.join(", ");
                      }}
                      MenuProps={MenuProps}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>Select</em>
                      </MenuItem>
                      {dsources.map(
                        (dsource) =>
                          dsource.is_external === 1 && (
                            <MenuItem
                              key={dsource.document_source}
                              value={dsource.document_source}
                              style={getStyles()}
                            >
                              {dsource.document_source}
                            </MenuItem>
                          )
                      )}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              {predicted_externalDocumentTitles.length > 0 ? (
                <Box sx={{ overflowX: "scroll" }}>
                  <P
                    list={predicted_externalDocumentTitles.filter((e) =>
                      selectedRegulationFilterFiles.includes(e.document_source)
                    )}
                    itemsPerPage={itemsPerPage}
                    currentPageIndex={currentPageIndex}
                    maximumVisiblePaginators={5}
                    renderChildren={renderRulesInternationalRegulations}
                    changePageIndex={(e) => {
                      setCurrentPageIndex(e);
                    }}
                    useDefaultStyles
                    showCounter
                    arrow={PageArrow}
                  />
                </Box>
              ) : (
                ""
              )}
              <hr />
              <Typography gutterBottom variant="subtitle1" component="p">
                DI Findings:
              </Typography>
              <TextField
                disableUnderline={true}
                className="gsk-input"
                id="filled-basic"
                variant="filled"
                size="small"
                value={selectedDIFinding}
                onChange={onDIFindingChange}
              />
              <Box sx={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  className="gsk-btn primary pill"
                  endIcon={<SaveIcon />}
                  disabled={isExporting}
                  onClick={(e) => saveUserSelectedFindings(e)}
                >
                  {!isSaving ? "Apply" : "Applying..."}
                </Button>
                &nbsp;
                <Button
                  variant="contained"
                  className="gsk-btn primary pill"
                  onClick={(e) => exportSelectedItems(e)}
                  disabled={isExporting}
                  endIcon={<CopyAllTwoToneIcon />}
                >
                  {!isExporting ? "Export" : "Exporting to a file ...  "}
                </Button>
              </Box>
            </Box>
          </>
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
}
