import React, { useState, useContext, createContext, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Card, CardContent } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import LoadingButton from "@mui/lab/LoadingButton";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import { useTheme } from "@mui/material/styles";

import axios from "axios";
import * as rimaConstants from "../../rimaconstants";
import { UserDataContext } from '../Context/UserDataContext';

//Components
import {
  uploadFileToBlob,
  uploadFileToRegulationBlob,
} from "./azureBlob";
import PDFViewer from "../Pdfview/PDFViewer"
import SortingSelectingTable from "./../SortingSelectingTable/SortingSelectingTable";

import { TagDataContext } from "./../Context/TagDataContext";
import { RegulationDataContext } from "./../Context/RegulationDataContext";
import "./AssemblePageContent.css";

import DocumentsListComp from "../DocumentsListComp/DocumentsListComp";
import { useMsal, useAccount } from "@azure/msal-react";
import { loginRequest } from "../../authConfigmasl";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../applicationInsights/AppInsights';

//TODO: Support other file types
//"application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/rtf,application/vnd.oasis.opendocument.presentation,application/vnd.oasis.opendocument.spreadsheet,application/vnd.oasis.opendocument.text"
const allowedFileTypes = "application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 250,
    },
  },
};

function getStyles(sources, documentSource, theme) {
  return {
    fontWeight: "400",
    fontSize: "13px",
  };
}

const pt0 = {
  paddingTop: "0px",
};
const Internal = parseInt(0);
const External = parseInt(1);
const Both = parseInt(-1);

const storageConfigured = true;

function AssemblePageContent() {
  const theme = useTheme();
  const [docs, setDocs] = useState("")
  const [tags, setTags] = useContext(TagDataContext);
  const [dsources, setDsources] = useContext(RegulationDataContext);
  // // all blobs in container
  const [blobList, setBlobList] = useState([]);

  // current file to upload into container
  const [fileSelected, setFileSelected] = useState("");

  // UI/form management
  const [uploading, setUploading] = useState(false);
  const [inputKey, setInputKey] = useState(Math.random().toString(36));
  const [sourceid, setSourceid] = useState(parseInt(1));
  const [documenturl, setDocumenturl] = useState("ASM");
  const [documenttitle, setDocumenttitle] = useState("");
  const [documentname, setDocumentname] = useState("");
  const [docstorageurl, setDocstorageurl] = useState("");
  const [doctype, setDoctype] = useState(parseInt(0));
  const [product, setProduct] = useState([]);
  const [location, setLocation] = useState([]);
  const [agreements, setAgreements] = useState([]);
  const [qms, setQms] = useState([]);
  const [regulation, setRegulation] = useState([]);
  const [auditee, setAuditee] = useState([]);
  const [dose, setDose] = useState([]);
  const [services, setServices] = useState([]);
  const [applicable, setApplicable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(true);

  const [savedMsg, setSavedMsg] = useState(false);
  const [enableSaveBtn, setEnableSaveBtn] = useState(true);

  const [ssFileUploadStatus, setSsFileUploadStatus] = useState(false);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [userDocs, setUserDocs] = useState([])
  const [refreshUserDocs, setRefreshUserDocs] = useState(true)
  const [adminUser,setAdminUser, user, setUser, userSASToken, setUserSASToken] = useContext(UserDataContext);

  useEffect(()=>
  {
    axios
    .get(
      `${rimaConstants.REACT_APP_API_URL}/api/v1/user/assmbleddocs`
    )
    .then((res) => {
      setUserDocs(res.data.data[0])
    })
    .catch((err) => {
      toast.warn("Failed to get user uploaded document list")
    });
  },[refreshUserDocs])

  const uploadedFileDetails = function (filepath) {
    setSsFileUploadStatus(false);

    //Set file upload session storage
    const today = new Date();
    const uploadedDate = today.toLocaleDateString();

    const docObject = {
      fileName: fileSelected.name,
      fileTitle: documenttitle,
      fileType: fileSelected.type,
      fileUploadedBy: accounts[0].name,
      fileUploadedDate: uploadedDate,
      documentPath: filepath
    }
    var index = userDocs.findIndex(el  => el.fileName ===  fileSelected.name  )

    if (index === -1) 
    {
      userDocs.push(docObject)
      setUserDocs( userDocs )
    }
    else {
      userDocs.splice(index,1,docObject)
      setUserDocs(userDocs)
    }
    localStorage.setItem("userDocsUploaded", JSON.stringify(userDocs));
  };

  const handleSubmit = async () => {
    setSsFileUploadStatus(true);
    setLoading(true);
    setIsError(false);

  
    if (account) {
      var filepath = null;

      if (regulation.length > 0) {
        console.log("Upload file to regulation blob...", regulation);
        filepath = await uploadFileToRegulationBlob(fileSelected, regulation, userSASToken.storageAccount, userSASToken.storageAccessToken,  user);
        console.log("File path in regulation blob: ", filepath);
      }
      let record_date = (new Date()).toISOString()

      const data = {
        document_source_id: sourceid,
        document_download_url: filepath,
        document_title: documenttitle,
        document_name: fileSelected.name,
        document_storage_url: filepath,
        published_on: record_date,
        created_by: 1, //TODO: Use correct user id
        created_date_time: record_date,
        row_source_session_id: account.localAccountId,

        is_external: doctype,
        product_type_tag: product.length===0?[""]:product,
        market_location_tag: location.length===0?[""]:location,
        agreement_tag: agreements.length===0?[""]:agreements,
        qms_policy_tag: qms.length===0?[""]:qms,
        regulation_tag: regulation,
        auditee_type_tag: auditee,
        dose_form_tag: dose.length===0?[""]:dose,
        service_tag: services.length===0?[""]:services,
        applicable_tag: applicable.length===0?[""]:applicable,
      };

      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: account,
        })
        .then((response) => {
          console.log(response);
          let axiosConfig = {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Authorization: "Bearer " + response.idToken,
              // access_token: response.accessToken,
              // id_token: response.idToken,
              // oid: response.uniqueId,
            },
          };
          setSavedMsg(false);
          axios
            .post(
              `${rimaConstants.REACT_APP_API_URL}/api/v1/docprops/assemble`,
              data,
              axiosConfig
            )
            .then((res) => {
              uploadedFileDetails(filepath);
              setData(res.data);
              setLoading(false);
              if (res.data.status === "failed") {
                
                toast("Failed to save document.")

              }
              else if (res.data.status === "exists")
              {
                let msgs=[
                      "Document with same file name already exists under selected regulation, re-upload is not allowed.",
                      "Document with file name under same regulation is already submitted for processing, re-upload is not allowed.", //to be processed
                      "Document with file name under same regulation is already being retried for processing, re-upload is not allowed." //retry
                  ]
                toast.error(msgs[res.data.processing_state])
                
              }
                
              else {
                setSavedMsg(true);
                setRefreshUserDocs(!refreshUserDocs) //Just trigger refresh
                toast("Document has been saved successfully!")
              }
              
            })
            .catch((err) => {
              setLoading(false);
              setIsError(true);
            });
        });
    }
  };


  const onFileChange = (event) => {
    // capture file into state
    if (allowedFileTypes.split(",").includes(event.target.files[0].type))
    {
      setFileSelected(event.target.files[0]);
      setFileUploaded(false)
      setSavedMsg(false)
    }
    else {
      toast.warn("Unsupported file format. Only MS-Word docx & pdf are supported.")
      setFileUploaded(true)
    }
  };

  useEffect(()=>{
    if(uploading) setDocs("")
  },[uploading])
  const onFileUpload = async () => {
    // prepare UI
    setUploading(true);

    // *** UPLOAD TO AZURE STORAGE ***
    const filepath = await uploadFileToBlob(fileSelected, userSASToken.storageAccount, userSASToken.storageAccessToken,  user);

    setFileUploaded(true);

    setSourceid(parseInt(1));
    setDocumenturl("");
    setDocumenttitle("");
    setDocumentname("");
    setDocstorageurl("");
    setDoctype(parseInt(0));
    setProduct([]);
    setLocation([]);
    setAgreements([]);
    setQms([]);
    setRegulation([]);
    setAuditee([]);
    setDose([]);
    setServices([]);
    setApplicable([]);

    setDocumenturl(filepath);
    setDocumentname(fileSelected.name);
    setDocumenttitle(fileSelected.name.substr(0, fileSelected.name.lastIndexOf(".")));
    console.log({ fileup: filepath });

    setDocs(filepath);
    // prepare UI for results
    //setBlobList(blobsInContainer);

    // reset state/form
    //setFileSelected(null);
    setUploading(false);
    setInputKey(Math.random().toString(36));
  };

  // display form
  const DisplayForm = () => (
    <div>
      <input
        className="fileIpt"
        files={fileSelected}
        type="file"
        onChange={onFileChange}
        key={inputKey || ""}
        accept={allowedFileTypes}
      />

      <button
        variant="contained"
        className="gsk-btn primary pill"
        type="submit"
        onClick={onFileUpload}
        disabled={fileUploaded}
      >
        Upload
      </button>
    </div>
  );
  const refreshUserUploadedDocs=()=>{
    setRefreshUserDocs(!refreshUserDocs)
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <Card style={{ boxShadow: "none" }} sx={{ minWidth: 275 }}>
            <CardContent style={{ padding: "0px 0 0 0px" }}>
              <Typography
                sx={{ mb: 1.5 }}
                variant="h6"
                gutterBottom
                component="div"
              >
                <h2 className="clsy-title">
                  <span className="round-bx">1</span> Upload Document
                </h2>
              </Typography>
              <div className="fileBx">
                <Box fullWidth>
                  {/* <FileUploadPDF /> */}

                  {storageConfigured && !uploading && DisplayForm()}
                  {storageConfigured && uploading && <div>Uploading</div>}
                </Box>
                <p style={{ fontSize: "9.6px" }}>
                  *This page supports documents only in English
                </p>
              </div>
            </CardContent>
          </Card>
          <br />
          <Card style={{ boxShadow: "none" }} sx={{ minWidth: 275 }}>
            <CardContent style={{ padding: "0px 0 0 0px" }}>
              <Typography
                sx={{ mb: 1.5 }}
                variant="h6"
                gutterBottom
                component="div"
              >
                <h2 className="clsy-title">
                  <span className="round-bx">2</span> View Document
                </h2>
              </Typography>
              <PDFViewer url = {docs} ></PDFViewer>
              {/* <Box
                sx={{
                  height: 218,
                  backgroundColor: "#eee",
                }}
                fullWidth
              /> */}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} md={6}>
          <Card style={{ boxShadow: "none" }}>
            <CardContent style={{ padding: "0px 0 0 10px" }}>
              <h2 className="clsy-title">
                <span className="round-bx">3</span>Tag Document
              </h2>
              <Box style={{ marginTop: "20px" }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <div
                      style={{ margin: "0px 20px 0 0", display: "inline-grid" }}
                      sx={{ m: 4 }}
                    >
                      <FormLabel
                        style={{ paddingBottom: "2px", fontSize: "13px" }}
                        component=""
                      >
                        Document Title:
                      </FormLabel>
                    </div>
                    <div style={{ display: "inline-grid", width: "100%" }}>
                      <input
                        value={documenttitle}
                        onChange={(e) => setDocumenttitle(e.target.value)}
                        className="simpleInpt"
                        type="text"
                        style={{ width: "80% !important" }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {/* <div style={{ margin: '0px 20px 0px 0px', display: 'inline-grid' }}> */}
                    <React.Fragment>
                      <FormLabel
                        style={{ paddingBottom: "2px", fontSize: "13px" }}
                        component=""
                      >
                        Regulation<sup style={{ color: "#ff0000" }}>*</sup>:
                      </FormLabel>
                      <br />
                      <Select
                        onChange={(e) => setRegulation(e.target.value)}
                        style={{
                          width: "169px",
                          height: "30px",
                          fontSize: "13px",
                          marginBottom: "5px",
                        }}
                        displayEmpty
                        value={regulation}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em>Select </em>;
                          }

                          //return selected.join(', ');
                          dsources.forEach((e) => {
                            if (e.document_source === selected) {
                              setDoctype(e.is_external);
                              setSourceid(e.document_source_id);
                            }
                          });
                          console.log(selected, dsources);
                          return selected;
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem disabled value="">
                          <em>Select</em>
                        </MenuItem>
                        {dsources.map(
                          (dsource) =>
                            dsource.is_external === 0 && (
                              <MenuItem
                                key={dsource.document_source}
                                value={dsource.document_source}
                                style={getStyles(
                                  dsource.document_source,
                                  theme
                                )}
                              >
                                {dsource.document_source}
                              </MenuItem>
                            )
                        )}
                        {dsources.map(
                          (dsource) =>
                            dsource.is_external === 1 && (
                              <MenuItem
                                key={dsource.document_source}
                                value={dsource.document_source}
                                style={getStyles(
                                  dsource.document_source,
                                  theme
                                )}
                              >
                                {dsource.document_source}
                              </MenuItem>
                            )
                        )}
                      </Select>
                    </React.Fragment>
                    {/* </div> */}
                  </Grid>

                  {/* <Grid item xs={12} md={4} >
                    {
                      tags.map((tag) => (
                        (tag.tag_type_id == '6') &&
                        <React.Fragment>
                          <FormLabel style={{ paddingBottom: '5px', fontSize: '13px' }} component="">{tag.tag_type}<sup style={{color:'#ff0000'}}>*</sup>:</FormLabel>
                          <Select onChange={e => setRegulation(e.target.value)} style={{ width:'169px', height: '30px', fontSize: '13px', marginBottom: '5px' }}
                            multiple
                            displayEmpty
                            value={regulation}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <em>Select </em>;
                              }

                              return selected.join(', ');
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ 'aria-label': 'Without label' }}
                          >
                            <MenuItem disabled value="">
                              <em>Select</em>
                            </MenuItem>
                            {tag.tags.map((t) => (
                              <MenuItem
                                key={t}
                                value={t}
                                style={getStyles(t, theme)}
                              >
                                {t}
                              </MenuItem>
                            ))}


                          </Select>
                        </React.Fragment>
                      ))
                    }
                  </Grid>
       */}

                  <Grid item xs={12} md={4}>
                    {tags.map(
                      (tag) =>
                        tag.tag_type_id == "2" && (
                          <React.Fragment>
                            <FormLabel
                              style={{ paddingBottom: "5px", fontSize: "13px" }}
                              component=""
                            >
                              {tag.tag_type}:
                            </FormLabel>
                            <br />
                            <Select
                              onChange={(e) => setProduct(e.target.value)}
                              style={{
                                width: "169px",
                                height: "30px",
                                fontSize: "13px",
                                marginBottom: "5px",
                              }}
                              multiple
                              displayEmpty
                              value={product}
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return <em>Select </em>;
                                }

                                return selected.join(", ");
                              }}
                              MenuProps={MenuProps}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem disabled value="">
                                <em>Select</em>
                              </MenuItem>
                              {tag.tags.map((t) => (
                                <MenuItem
                                  key={t}
                                  value={t}
                                  style={getStyles(t, theme)}
                                >
                                  {t}
                                </MenuItem>
                              ))}
                            </Select>
                          </React.Fragment>
                        )
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {tags.map(
                      (tag) =>
                        tag.tag_type_id == "3" && (
                          <React.Fragment>
                            <FormLabel
                              style={{ paddingBottom: "5px", fontSize: "13px" }}
                              component=""
                            >
                              {tag.tag_type}:
                            </FormLabel>
                            <br />
                            <Select
                              onChange={(e) => setLocation(e.target.value)}
                              style={{
                                width: "169px",
                                height: "30px",
                                fontSize: "13px",
                                marginBottom: "5px",
                              }}
                              multiple
                              displayEmpty
                              value={location}
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return <em>Select </em>;
                                }

                                return selected.join(", ");
                              }}
                              MenuProps={MenuProps}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem disabled value="">
                                <em>Select</em>
                              </MenuItem>
                              {tag.tags.map((t) => (
                                <MenuItem
                                  key={t}
                                  value={t}
                                  style={getStyles(t, theme)}
                                >
                                  {t}
                                </MenuItem>
                              ))}
                            </Select>
                          </React.Fragment>
                        )
                    )}
                  </Grid>

                  <Grid item xs={12} md={4} style={pt0}>
                    {tags.map(
                      (tag) =>
                        tag.tag_type_id == "4" && (
                          <React.Fragment>
                            <FormLabel
                              style={{ paddingBottom: "5px", fontSize: "13px" }}
                              component=""
                            >
                              {tag.tag_type}:
                            </FormLabel>
                            <br />
                            <Select
                              onChange={(e) => setAgreements(e.target.value)}
                              style={{
                                width: "169px",
                                height: "30px",
                                fontSize: "13px",
                                marginBottom: "5px",
                              }}
                              multiple
                              displayEmpty
                              value={agreements}
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return <em>Select </em>;
                                }

                                return selected.join(", ");
                              }}
                              MenuProps={MenuProps}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem disabled value="">
                                <em>Select</em>
                              </MenuItem>
                              {tag.tags.map((t) => (
                                <MenuItem
                                  key={t}
                                  value={t}
                                  style={getStyles(t, theme)}
                                >
                                  {t}
                                </MenuItem>
                              ))}
                            </Select>
                          </React.Fragment>
                        )
                    )}
                  </Grid>
                  <Grid item xs={12} md={4} style={pt0}>
                    {tags.map(
                      (tag) =>
                        tag.tag_type_id == "5" && (
                          <React.Fragment>
                            <FormLabel
                              style={{ paddingBottom: "5px", fontSize: "13px" }}
                              component=""
                            >
                              {tag.tag_type}:
                            </FormLabel>
                            <br />
                            <Select
                              onChange={(e) => setQms(e.target.value)}
                              style={{
                                width: "169px",
                                height: "30px",
                                fontSize: "13px",
                                marginBottom: "5px",
                              }}
                              multiple
                              displayEmpty
                              value={qms}
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return <em>Select </em>;
                                }

                                return selected.join(", ");
                              }}
                              MenuProps={MenuProps}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem disabled value="">
                                <em>Select</em>
                              </MenuItem>
                              {tag.tags.map((t) => (
                                <MenuItem
                                  key={t}
                                  value={t}
                                  style={getStyles(t, theme)}
                                >
                                  {t}
                                </MenuItem>
                              ))}
                            </Select>
                          </React.Fragment>
                        )
                    )}
                  </Grid>

                  <Grid item xs={12} md={4} style={pt0}>
                    {tags.map(
                      (tag) =>
                        tag.tag_type_id == "7" && (
                          <React.Fragment>
                            <FormLabel
                              style={{ paddingBottom: "5px", fontSize: "13px" }}
                              component=""
                            >
                              {tag.tag_type}
                              <sup style={{ color: "#ff0000" }}>*</sup>:
                            </FormLabel>
                            <br />
                            <Select
                              onChange={(e) => setAuditee(e.target.value)}
                              style={{
                                width: "169px",
                                height: "30px",
                                fontSize: "13px",
                                marginBottom: "5px",
                              }}
                              displayEmpty
                              value={auditee}
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return <em>Select </em>;
                                }

                                return selected;
                              }}
                              MenuProps={MenuProps}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem disabled value="">
                                <em>Select</em>
                              </MenuItem>
                              {tag.tags.map((t) => (
                                <MenuItem
                                  key={t}
                                  value={t}
                                  style={getStyles(t, theme)}
                                >
                                  {t}
                                </MenuItem>
                              ))}
                            </Select>
                          </React.Fragment>
                        )
                    )}
                  </Grid>
                  <Grid item xs={12} md={4} style={pt0}>
                    {tags.map(
                      (tag) =>
                        tag.tag_type_id == "8" && (
                          <React.Fragment>
                            <FormLabel
                              style={{ paddingBottom: "5px", fontSize: "13px" }}
                              component=""
                            >
                              {tag.tag_type}:
                            </FormLabel>
                            <br />
                            <Select
                              onChange={(e) => setDose(e.target.value)}
                              style={{
                                width: "169px",
                                height: "30px",
                                fontSize: "13px",
                                marginBottom: "5px",
                              }}
                              multiple
                              displayEmpty
                              value={dose}
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return <em>Select </em>;
                                }

                                return selected.join(", ");
                              }}
                              MenuProps={MenuProps}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem disabled value="">
                                <em>Select</em>
                              </MenuItem>
                              {tag.tags.map((t) => (
                                <MenuItem
                                  key={t}
                                  value={t}
                                  style={getStyles(t, theme)}
                                >
                                  {t}
                                </MenuItem>
                              ))}
                            </Select>
                          </React.Fragment>
                        )
                    )}
                  </Grid>
                  <Grid item xs={12} md={4} style={pt0}>
                    {tags.map(
                      (tag) =>
                        tag.tag_type_id == "9" && (
                          <React.Fragment>
                            <FormLabel
                              style={{ paddingBottom: "5px", fontSize: "13px" }}
                              component=""
                            >
                              {tag.tag_type}:
                            </FormLabel>
                            <br />
                            <Select
                              onChange={(e) => setServices(e.target.value)}
                              style={{
                                width: "169px",
                                height: "30px",
                                fontSize: "13px",
                                marginBottom: "5px",
                              }}
                              multiple
                              displayEmpty
                              value={services}
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return <em>Select </em>;
                                }

                                return selected.join(", ");
                              }}
                              MenuProps={MenuProps}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem disabled value="">
                                <em>Select</em>
                              </MenuItem>
                              {tag.tags.map((t) => (
                                <MenuItem
                                  key={t}
                                  value={t}
                                  style={getStyles(t, theme)}
                                >
                                  {t}
                                </MenuItem>
                              ))}
                            </Select>
                          </React.Fragment>
                        )
                    )}
                  </Grid>
                  <Grid item xs={12} md={4} style={pt0}>
                    {tags.map(
                      (tag) =>
                        tag.tag_type_id == "10" && (
                          <React.Fragment>
                            <FormLabel
                              style={{ paddingBottom: "5px", fontSize: "13px" }}
                              component=""
                            >
                              {tag.tag_type}:
                            </FormLabel>
                            <br />
                            <Select
                              onChange={(e) => setApplicable(e.target.value)}
                              style={{
                                width: "169px",
                                height: "30px",
                                fontSize: "13px",
                                marginBottom: "5px",
                              }}
                              multiple
                              displayEmpty
                              value={applicable}
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return <em>Select </em>;
                                }

                                return selected.join(", ");
                              }}
                              MenuProps={MenuProps}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem disabled value="">
                                <em>Select</em>
                              </MenuItem>
                              {tag.tags.map((t) => (
                                <MenuItem
                                  key={t}
                                  value={t}
                                  style={getStyles(t, theme)}
                                >
                                  {t}
                                </MenuItem>
                              ))}
                            </Select>
                          </React.Fragment>
                        )
                    )}
                  </Grid>
                  <Grid item xs={12} md={12} style={pt0}>
                    <Box sx={{ textAlign: "right", mt: "15px" }}>
                      <Box component="span" sx={{ pr: "15px" }}>
                        {isError && (
                          <FormHelperText
                            component="span"
                            sx={{ color: "error.main" }}
                          >
                            Something went wrong. Please try again later.
                          </FormHelperText>
                        )}
                        {savedMsg && (
                          <FormHelperText
                            component="span"
                            sx={{ color: "success.main" }}
                          >
                            Document has been saved successfully!
                          </FormHelperText>
                        )}
                      </Box>

                      {/* 
                  <Button style={{ width: '100px',marginTop: '10px' }} className="blueBtn" variant="contained" fullWidth
                    type="submit" 
                      onClick={handleSubmit}
                      disabled={!(regulation.length > 0 && auditee.length > 0 && fileUploaded) }
                    >{loading ? 'Saving...' : 'Save'}</Button> */}
                      <LoadingButton
                        loading={loading}
                        loadingPosition="end"
                        style={{ width: "120px" }}
                        className="gsk-btn primary pill"
                        variant="contained"
                        fullWidth
                        type="submit"
                        onClick={handleSubmit}
                        disabled={
                          !(
                            regulation.length > 0 &&
                            auditee.length > 0 &&
                            fileUploaded
                          )
                        }
                      >
                        {loading ? "Saving..." : "Save"}
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={12}>
          {userDocs.length > 0 ? (
            <Box sx={{ mt: "15px" }}>
              <Typography
                sx={{ mb: 1.5 }}
                variant="h6"
                gutterBottom
                component="div"
              >
                <h2 className="clsy-title">Documents List</h2>
              </Typography>
              <DocumentsListComp userDocs = {userDocs} refreshFn = {refreshUserUploadedDocs} />
              <h6>NOTE: Please contact RIMA support team if a document is not processed within a business day.</h6>
            </Box>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      
    </div>
  );
}

export default withAITracking(reactPlugin, AssemblePageContent);

