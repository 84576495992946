import React, { useContext } from "react";
import Admintemplate from "../admintemplate/admintemplate";
import EmailTemplate from "./EmailTemplate";
import { BreadcrumbsContext } from "../context/breadcrumsContext";
const Content = () => {
  // eslint-disable-next-line no-unused-vars
  const { breadcrumbs, setBreadcrumbs } = useContext(BreadcrumbsContext);
  React.useEffect(() => {
    setBreadcrumbs((prevBreadcrumbs) => {
      prevBreadcrumbs.parentMenu = "Email Template";
      prevBreadcrumbs.childMenu = "";
      return { ...prevBreadcrumbs };
    });
  }, []);
  return (
    <div className="container-admindashboard">
      <EmailTemplate />
    </div>
  );
};

const GetContent = () => {
  return <Content />;
};

const EmailTemplateMain = () => {
  return <Admintemplate content={GetContent()} />;
};

export default EmailTemplateMain;
