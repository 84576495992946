import React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import SaveIcon from "@mui/icons-material/Save";
import { Button, Box, Switch, FormControlLabel } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import "./textarea.css";
import JoditEditor from "jodit-react";

export default class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorHtml: "",
      theme: "snow",
      showButtons: props.showButtons,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    //this.setState({ editorHtml: this.props.textValue ? this.props.textValue.replace(new RegExp(`"`, "g"), `'`) : '' })
    this.setState({
      editorHtml: this.props.textValue ? this.props.textValue : "",
    });
  }

  handleChange(html) {
    this.setState({ editorHtml: html });
    if (this.props.onTextChange) this.props.onTextChange(html);
  }

  config = {
    useSearch: true,
    spellcheck: false,
    enter: "P",
    defaultMode: "1",
    toolbarAdaptive: false,
    toolbarSticky: false,
    showCharsCounter: false,
    showWordsCounter: false,
    toolbarInline: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    // minHeight: 200,
    maxHeight: "100%",
    minWidth: null,
    // buttons: "bold,underline,italic",
    // buttons: [
    //   "undo",
    //   "redo",
    //   "|",
    //   "bold",
    //   "strikethrough",
    //   "underline",
    //   "italic",
    //   "|",
    //   "superscript",
    //   "subscript",
    //   "|",
    //   "align",
    //   "|",
    //   "ul",
    //   "ol",
    //   "outdent",
    //   "indent",
    //   "|",
    //   "font",
    //   "fontsize",
    //   "brush",
    //   "paragraph",
    //   "|",
    //   "image",
    //   "link",
    //   "table",
    //   "|",
    //   "hr",
    //   "eraser",
    //   "copyformat",
    //   "|",
    //   "fullsize",
    //   "selectall",
    //   "print",
    //   "|",
    //   "source",
    //   "|",
    //   "search",
    //   "|",
    // ],
    removeButtons: ["video", "file", "about"],
    editorCssClass: "alic",
    placeHolder: "Start typings...",
    readOnly: false,
    events: {
      paste: (e) => {},
    },
  };

  render() {
    return (
      <div className="apply-custom-btn" style={{ width: "100%" }}>
        <DialogContent sx={{ width: "100%" }}>
          <JoditEditor
            ref={React.createRef()}
            value={this.state.editorHtml}
            config={this.config}
            tabIndex={1} // tabIndex of textarea
            onBlur={this.handleChange} // preferred to use only this option to update the content for performance reasons
            onChange={(newContent) => {}}
            sx={{ wordBreak: "break-all" }}
          />
        </DialogContent>
        <DialogActions>
          {this.state.showButtons && (
            <>
              <Button
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  marginRight: "7px",
                }}
                onClick={this.props.onClose}
              >
                Cancel
              </Button>
              <Box>
                <FormControlLabel
                  sx={{
                    display: "none",
                  }}
                  control={
                    <Switch
                      checked={this.props.insightEditLoading}
                      onChange={() =>
                        this.props.this.props.setInsightEditLoading(
                          !this.props.insightEditLoading
                        )
                      }
                      name="loading"
                      color="primary"
                    />
                  }
                  label="Loading"
                />
                <Box sx={{ "& > button": { m: 1 } }}>
                  <LoadingButton
                    onClick={() => {
                      this.props.onSubmit(this.state.editorHtml);
                    }}
                    loading={this.props.insightEditLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    type="submit"
                    sx={{ textTransform: "capitalize" }}
                    disabled={this.props.insightEditLoading}
                  >
                    Save
                  </LoadingButton>
                </Box>
              </Box>
            </>
          )}
        </DialogActions>
      </div>
    );
  }
}

// Editor.modules = {
//     toolbar: [
//         ['bold', 'italic', 'underline'],
//     ],
//     clipboard: {
//         matchVisual: false,
//     }
// }

// Editor.formats = [
//     'bold', 'italic', 'underline'
// ]
