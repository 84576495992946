import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// import { runWithAdal } from 'react-adal';
import { PublicClientApplication } from "@azure/msal-browser";

import { msalConfig } from "./authConfigmasl";

// import axios from 'axios';

// import { useHistory, Redirect } from "react-router-dom";

// import { authContext } from './authConfig';

// axios.interceptors.response.use(null, function(err) {
//   alert(err.status);
//   if ( err.status === 403 ) {
//     alert('get 403');
//   }

//   return Promise.reject(err);
// });

// axios.interceptors.response.use(response => {
//   console.log('response.status ' + response.status);
//   console.log(response);
//   return response;
// }, function (error) {
//   console.log('error.response.status ' + error.response.status);
//   console.log(error);
//   const params = new URL(window.location.href).searchParams;
//   console.log('params ', params.get('isRedirected'));
//   let statusCode = ['401','403'];
//   if (statusCode.includes(error.response.status) && !params.get('isRedirected')) {
//     window.location.href = "/invaliduser?isRedirected=yes";
//   }
// });

// axios.interceptors.request.use(
//   (config) => {
//     const token = 'testtoken';//JSON.parse(localStorage.getItem());//JSON.parse(localStorage.getItem(Object.keys(localStorage)[5])).secret;   
//     console.log('token ', token);
//     if (token) {
//       config.headers["x-access-token"] = token;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// const DO_NOT_LOGIN = false;

// runWithAdal(authContext, () => {
//   ReactDOM.render(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>,
//     document.getElementById('root')
//   );
// },DO_NOT_LOGIN);

export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <App instance={msalInstance} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
