import React from "react";
import Header2 from "../Header/Header";
import { BreadcrumsContextProvider } from "../context/breadcrumsContext";
import Breadcrums from "../breadcrums/breadcrums";
import AuditTrailContent from "./AuditTrailContent";
import Footer from "../../Footer/Footer";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../applicationInsights/AppInsights';

const AuditTrail = () => {
  return (
    <>
      <Header2 />
      <div className="container-fluid">
        <BreadcrumsContextProvider>
          <Breadcrums></Breadcrums>
          <AuditTrailContent />
        </BreadcrumsContextProvider>
      </div>
      <Footer />
    </>
  );
};
// export default AuditTrail;
export default withAITracking(reactPlugin, AuditTrail);
