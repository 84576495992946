import React from "react";
import Header2 from "../Header/Header";
import { BreadcrumsContextProvider } from "../context/breadcrumsContext";
import Breadcrums from "../breadcrums/breadcrums";
//import Manageaudit from "../../admin/audit/manageaudit";
import Footer from "../../Footer/Footer";
//import "../../admintemplate.css";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../applicationInsights/AppInsights';

const AuditManagementMain = (props) => {
  return (
    <>
      <Header2 />
      <div className="container-fluid">
        <BreadcrumsContextProvider>
          <div className="sidenavc">
            {/* <div className="leftsidebar">
            <TemporaryDrawer></TemporaryDrawer>
          </div> */}
            <div className="rightbreadcrumbs">
              <Breadcrums></Breadcrums>
            </div>
          </div>
          <div className="container-main">{props.content}</div>
        </BreadcrumsContextProvider>
      </div>
      <Footer />
    </>
  );
};
// export default AuditManagementMain;
export default withAITracking(reactPlugin, AuditManagementMain);
