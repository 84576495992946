import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Autocomplete from "@mui/material/Autocomplete";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";

import OutlinedInput from "@mui/material/OutlinedInput";
// import FormHelperText from "@mui/material/FormHelperText";

import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Link from "@mui/material/Link";

import CopyAllTwoToneIcon from "@mui/icons-material/CopyAllTwoTone";
import SearchIcon from "@mui/icons-material/Search";

import { SpinnerCircular } from "spinners-react";
import * as https from "https";
import { v4 as uuidv4 } from "uuid";
// Components
import { useMsal, useAccount } from "@azure/msal-react";
import { loginRequest } from "../../authConfigmasl";
import P from "react-pagimagic";
import PageArrow from "../PageArrow/PageArrow";
import "./ASMReviewContent.css";
// Data
import { RegulationDataContext } from "./../Context/RegulationDataContext";
import { ReviewDataContext } from "./../Context/ReviewDataContext";
import { toast } from "react-toastify";
import * as rimaConstants from "../../rimaconstants";
import { UserDataContext } from "../Context/UserDataContext";
import getHiddenDocList from "../Common/hiddenDocs";
import moment from "moment";
// Components

const CURRENT_PAGE_INDEX = 0;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
function getStyles() {
  return {
    fontWeight: "400",
    fontSize: "13px",
  };
}
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 250,
    },
  },
};

const drpDownStyle = {
  width: "140px",
  marginBottom: "-4px",
};

function copyToClipboard(arr, key) {
  var str = "";
  for (var i = 0; i < arr.length; i++) str = str + arr[i][key] + "\n";
  navigator.clipboard.writeText(str);
}

function copyToClipboardTitle(arr) {
  var str = "";
  for (var i = 0; i < arr.length; i++)
    str =
      str +
      arr[i]["document_title"] +
      " - [" +
      arr[i]["document_storage_url"].split("/").pop() +
      "]\n";

  navigator.clipboard.writeText(str);
}

/* Component: SuggestionsContent */
export default function SuggestionsContent() {
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [dsources, setDsources] = useContext(RegulationDataContext);
  const [
    adminUser,
    setAdminUser,
    user,
    setUser,
    userSASToken,
    setUserSASToken,
  ] = useContext(UserDataContext);

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const {
    diFindingText,
    setDIFindingText,
    resultDocumentSourceTypeSelected,
    setResultDocumentSourceTypeSelected,
    suggetionsInputText,
    setSuggetionsInputText,
    suggestionsPredicted_internalValues,
    setSuggestionsPredicted_internalValues,
    suggestionsPredicted_internalKeys,
    setSuggestionsPredicted_internalKeys,
    suggestionsPredicted_externalDocumentTitles,
    setSuggestionsPredicted_externalDocumentTitles,
    selectedRegulationFilter,
    setSelectedRegulationFilter,
    userSelectedTitleTextReview,
    setUserSelectedTitleTextReview,
    recoReceived,
    setRecoReceived,
    isGettingRegulations,
    setIsGettingRegulations,
    isGetRegulationsActiveText,
    setIsGetRegulationsActiveText,
    titlesCheckedText,
    settitlesCheckedText,
    policyDocCheckedText,
    setPolicyDocCheckedText,
  } = useContext(ReviewDataContext);

  const documentSourceTypes = dsources
    .filter((source) => source.is_external)
    .map((x) => x.document_source);

  const [isSearching, setIsSearching] = useState(false);

  const documentSourceTypeHandler = (e) => {
    setResultDocumentSourceTypeSelected(e.target.value);
  };

  //const [suggestionsFindingsData, setSuggestionsFindingsData] = useState(null);
  var suggestionsFindingsData = [];
  const getPredictedExternalDocs = (searchString) => {
    externDocsPredictedFromSearch([]);
    if (searchString === "") {
      setIsGetRegulationsActiveText(false);
      return;
    }
    setPolicyDocCheckedText([]);
    var qryParams = {
      speller: "lexicon",
      queryLanguage: "en-us",
      search: searchString,
      $count: "true",
      "api-version": "2021-04-30-Preview",
      $top: 2000,
      highlight:
        "page_content, document_name, document_source_description, document_title",
      $filter: "is_external eq 1",
    };
    setIsGettingRegulations(true);
    setIsGetRegulationsActiveText(false);
    axios
      .post(`${rimaConstants.REACT_APP_API_URL}/api/v1/docprops/search`, {
        queryString: qryParams,
      })
      .then((resJSONData) => {
        externDocsPredictedFromSearch(resJSONData.data.value);
        setIsGettingRegulations(false);
        if (
          resJSONData &&
          resJSONData.data.value &&
          resJSONData.data.value.length > 0
        )
          toast("Related international regulations data received.");
        else toast.warn("No international regulations data received.");
      })
      .catch((e) => {
        console.log("No data received", e);
        toast.warn("No international regulations data received.");
        setIsGettingRegulations(false);
      });
  };

  //Titles rendering

  const overridetitlesCheckedText = (event, item) => {
    setSuggestionsPredicted_externalDocumentTitles([]);
    // var values = [item];
    // if (values.length && item !== null) {
    //   var searchString = values.map((x) => x.document_title).join(" | ");
    //   getPredictedExternalDocs(searchString);
    // }
  };

  const handletitlesCheckedText = (event, item) => {
    const { value } = event.target;
    setSuggestionsPredicted_externalDocumentTitles([]);
    setPolicyDocCheckedText([]);
    var newlist = titlesCheckedText.includes(item)
      ? titlesCheckedText.filter((c) => c !== item)
      : [...titlesCheckedText, item];
    if (newlist.length > 0) setIsGetRegulationsActiveText(true);
    else setIsGetRegulationsActiveText(false);
    settitlesCheckedText(newlist);
  };
  const renderSuggestionsPredicted_internalValueList = (list) => {
    return list.map((item) => {
      var filePath =
        userSASToken.storageUri +
        "raw/" +
        encodeURI(item.document_storage_url) +
        "?" +
        userSASToken.storageAccessToken;
      var referrerid = uuidv4();
      return (
        <>
          <FormControlLabel
            control={
              <Checkbox
                className="gsk-checkbox"
                key={item.document_storage_url}
                onChange={(e) => {
                  if (userSelectedTitleTextReview === "")
                    handletitlesCheckedText(e, item);
                }}
                checked={titlesCheckedText.includes(item)}
              />
            }
            label={<> {item.document_title} - </>}
          />
          {/* <Link
            href={`/pdf?docid=${referrerid}#page${1}`}
            target="_blank"
            onClick={() => {
              localStorage.setItem(referrerid, filePath);
              localStorage.setItem(`${referrerid}-page`, 1);
            }}
          >
            {" "}
            {item.document_storage_url.split("/").pop()}{" "}
          </Link> */}
          {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                let url = `${filePath}&ts=${moment().utc().unix()}#page=1`;
                let a = document.createElement("a");
                a.target = "_blank";
                let veevavault = item?.document_download_url?.includes(
                  "veevavault"
                )
                  ? true
                  : false;
                if (veevavault) {
                  a.href = `${item.document_download_url}?anQS=page1&annotate=false`;
                } else {
                  a.href = url;
                  // a.href = `/documentviewer/${window.base64_encode(url)}`;
                }
                a.click();
              }}
            >
              {" "}
              {item.document_storage_url.split("/").pop()}{" "}
            </a>
          }
          <br />
        </>
      );
    });
  };

  // Polocy doc links

  const handlePolicyDocChecked = (event, item) => {
    const { value } = event.target;
    setPolicyDocCheckedText(
      policyDocCheckedText.includes(item)
        ? policyDocCheckedText.filter((c) => c !== item)
        : [...policyDocCheckedText, item]
    );
  };
  const renderSuggestionsInternationalRegulations = (list) => {
    return list.map((item) => {
      if (!selectedRegulationFilter.includes(item.document_source)) return "";

      var filePath =
        encodeURI(item.document_storage_url) +
        "?" +
        userSASToken.storageAccessToken;
      var referrerid = uuidv4();
      return (
        <>
          <FormControlLabel
            control={
              <Checkbox
                className="gsk-checkbox"
                onChange={(e) => handlePolicyDocChecked(e, item)}
                checked={policyDocCheckedText.includes(item)}
              />
            }
            label=""
          />
          {/* <Link
            href={`/pdf?docid=${referrerid}#page${item.page}`}
            target="_blank"
            onClick={() => {
              localStorage.setItem(referrerid, filePath);
              localStorage.setItem(`${referrerid}-page`, item.page);
            }}
          >
            {" "}
            {item.document_title}{" "}
          </Link> */}
          {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                let url = `${filePath}&ts=${moment().utc().unix()}#page=${
                  item.page
                }`;
                let a = document.createElement("a");
                a.target = "_blank";
                a.href = url;
                //a.href = `/documentviewer/${window.base64_encode(url)}`;
                a.click();
              }}
            >
              {" "}
              {item.document_title}{" "}
            </a>
          }
          <sup>
            <small style={{ color: "#15717d" }}>{item.document_source}</small>
          </sup>
          <br />
        </>
      );
    });
  };

  const getSuggestionsFindingsData = () => {
    setDIFindingText("");
    setRecoReceived(0);
    setIsSearching(true);
    if (suggetionsInputText.trim() === "") {
      toast.error("No finding text is added to get recommendations.");
      setIsSearching(false);
      return;
    }
    const getRecoUrl =
      rimaConstants.REACT_APP_API_URL + "/api/v1/review/recommendations";
    axios
      .post(getRecoUrl, {
        path: suggetionsInputText,
        filetype: "text",
      })
      .then(async (response) => {
        //setDsources(response.data.document_sources);
        //setSuggestionsFindingsData(response.data);
        suggestionsFindingsData = response.data;
        if (
          suggestionsFindingsData === null ||
          suggestionsFindingsData === undefined ||
          suggestionsFindingsData.results === null ||
          suggestionsFindingsData.results === undefined ||
          suggestionsFindingsData.results.length === 0 ||
          suggestionsFindingsData.results[0].predicted_internal === undefined ||
          suggestionsFindingsData.results[0].predicted_internal.length === 0
        ) {
          setRecoReceived(2);
          return;
        }

        var hiddendocs = [
          ...new Set(
            (await getHiddenDocList()).map((x) => {
              return x.document_storage_url.split(".").slice(0, -1).join(".");
            })
          ),
        ];
        var doclist = suggestionsFindingsData.results[0].predicted_internal;
        suggestionsFindingsData.results[0].predicted_internal = doclist.filter(
          (x) =>
            !hiddendocs.includes(
              x.document_storage_url.split(".").slice(0, -1).join("."),
              0
            )
        );

        setRecoReceived(1);
        setSelectedRegulationFilter(
          dsources
            .filter((source) => source.is_external)
            .map((x) => x.document_source)
        );
        suggestionsAuditInternalDataHelper(0);
        suggestionsQMSPoliciesDataHelper(0);
        suggestionsPredicted_externalDocumentTitleDataHelper(0);
        settitlesCheckedText([]);
        setPolicyDocCheckedText([]);

        setIsSearching(false);
      })
      .finally((e) => {
        setIsSearching(false);
      });
  };
  const suggestionsAuditInternalDataHelper = (fIndex) => {
    if (
      suggestionsFindingsData === null ||
      suggestionsFindingsData === undefined ||
      suggestionsFindingsData.results === null ||
      suggestionsFindingsData.results === undefined ||
      suggestionsFindingsData.results.length === 0
    ) {
      return;
    }
    setSuggestionsPredicted_internalValues(
      suggestionsFindingsData.results[fIndex].predicted_internal
    );
  };

  const suggestionsPredicted_externalDocumentTitleDataHelper = (fIndex) => {
    if (
      suggestionsFindingsData === null ||
      suggestionsFindingsData === undefined ||
      suggestionsFindingsData.results === null ||
      suggestionsFindingsData.results === undefined
    )
      return;
    var noduplicates_list =
      suggestionsFindingsData.results[fIndex].predicted_external;
    noduplicates_list = noduplicates_list.filter(
      (tag, index, array) =>
        array.findIndex(
          (t) => t.document_title === tag.document_title && t.is_external === 1
        ) === index
    );
    setSuggestionsPredicted_externalDocumentTitles(
      noduplicates_list
        // .filter((item, index) => index < 5)
        .map((item) => ({
          document_title: item.document_title,
          page: item.page_number,
          document_name: item.document_name,
          document_source: item.document_source,
          document_storage_url: `${userSASToken.storageUri}${rimaConstants.ASM_ROOT_CONTAINER}/${item.document_storage_url}`,
        }))
    );
  };

  const externDocsPredictedFromSearch = (data) => {
    var noduplicates_list = data;
    noduplicates_list = noduplicates_list.filter(
      (tag, index, array) =>
        array.findIndex(
          (t) => t.document_title === tag.document_title && t.is_external === 1
        ) === index
    );
    setSuggestionsPredicted_externalDocumentTitles(
      noduplicates_list
        // .filter((item, index) => index < 5)
        .map((item) => ({
          document_title: item.document_title,
          page: item.page_number,
          document_name: item.document_name,
          document_source: item.document_source,
          document_storage_url: `${userSASToken.storageUri}${rimaConstants.ASM_ROOT_CONTAINER}/${item.document_storage_url}`,
        }))
    );
  };

  const suggestionsQMSPoliciesDataHelper = (fIndex) => {
    if (
      suggestionsFindingsData === null ||
      suggestionsFindingsData === undefined ||
      suggestionsFindingsData.results === null ||
      suggestionsFindingsData.results === undefined
    )
      return;
    setSuggestionsPredicted_internalKeys(
      suggestionsFindingsData.results[fIndex].predicted_internal
    );
  };
  const handleInputText = (event) => {
    setSuggetionsInputText(event.target.value);
  };
  const handleIntlRegFilterChange = (e) => {
    //TODO:
    setSelectedRegulationFilter(e.target.value);
    setCurrentPageIndex(0);
    setItemsPerPage(5);
  };
  let inputRef;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            background: "#f5f5f5",
            padding: "15px",
            mb: "15px",
            minHeight: "135px",
          }}
        >
          <TextField
            sx={{ mb: "15px", color: "#15717d" }}
            label="Text for search - 'Ex. Finding Summary/Findings'​"
            multiline
            rows={25}
            variant="filled"
            className="gsk-textarea"
            fullWidth
            value={suggetionsInputText}
            onChange={handleInputText}
            disabled={isSearching}
          />

          {/* <UserInputField /> */}

          <Box sx={{ textAlign: "right" }}>
            <Button
              variant="contained"
              className="gsk-btn primary pill"
              startIcon={<SearchIcon />}
              // onClick={addUserFindingsTextTosuggestionsFindingsText}
              onClick={getSuggestionsFindingsData}
              disabled={isSearching}
            >
              {!isSearching
                ? "Get Recommendations"
                : "Getting Recommendations ...  "}
              &nbsp;
              <SpinnerCircular
                enabled={isSearching}
                size="18"
                сolor="#ffffff"
              />
            </Button>
          </Box>
        </Box>
      </Grid>

      {recoReceived === 2 ? (
        <Box>No recommendations found</Box>
      ) : recoReceived === 1 ? (
        <Grid item xs={12} md={6}>
          <Box sx={{ background: "#f5f5f5", padding: "15px", mb: "15px" }}>
            <Typography gutterBottom variant="h4" component="p">
              Recommendations:​
            </Typography>
          </Box>
          <Box sx={{ background: "#f5f5f5", padding: "15px", mb: "15px" }}>
            <Typography gutterBottom variant="subtitle1" component="p">
              TITLE: (Select a title to get corresponding international
              regulations)​
            </Typography>
            {suggestionsPredicted_internalValues.length > 0 ? (
              <>
                <FormGroup>
                  <P
                    list={suggestionsPredicted_internalValues.slice(0, 10)}
                    itemsPerPage={5}
                    currentPageIndex={CURRENT_PAGE_INDEX}
                    maximumVisiblePaginators={5}
                    renderChildren={
                      renderSuggestionsPredicted_internalValueList
                    }
                    useDefaultStyles
                    showCounter
                    arrow={PageArrow}
                  />
                </FormGroup>
                <label>
                  <sup>*</sup>Search and select a title from below list in case
                  you do not have what you are looking in above list.
                  <br />
                  <b>NOTE: this will override the above selection.</b>
                </label>
                <Grid container spacing={2}>
                  <Grid item xs={4} sm={4} md={5} lg={6}>
                    <Autocomplete
                      size="small"
                      disablePortal
                      //multiple
                      id="titles-combo"
                      freeSolo
                      clearOnEscape="false"
                      options={
                        suggestionsPredicted_internalValues.length > 0
                          ? suggestionsPredicted_internalValues
                          : []
                      }
                      getOptionLabel={(option) =>
                        option.document_title
                          ? `${option.document_title} - [${option.document_name}]`
                          : ""
                      }
                      sx={{ minWidth: 150 }}
                      defaultValue={[]}
                      onChange={overridetitlesCheckedText}
                      inputValue={
                        userSelectedTitleTextReview.length > 0
                          ? userSelectedTitleTextReview
                          : ""
                      }
                      onInputChange={(event, item) => {
                        if (
                          event &&
                          (event.type === "change" || event.type === "click")
                        ) {
                          if (
                            event.type === "click" &&
                            userSelectedTitleTextReview !== ""
                          ) {
                            setSuggestionsPredicted_externalDocumentTitles([]);
                            setPolicyDocCheckedText([]);
                          }
                          if (item === "" && titlesCheckedText.length === 0) {
                            setSuggestionsPredicted_externalDocumentTitles([]);
                            setIsGetRegulationsActiveText(false);
                          } else setIsGetRegulationsActiveText(true);
                          setUserSelectedTitleTextReview(item);
                        }
                      }}
                      onBlur={(e) => {
                        // if (e.target === inputRef)
                        //   if (userSelectedTitleTextReview !== undefined && userSelectedTitleTextReview !== "") {
                        //     getPredictedExternalDocs(userSelectedTitleTextReview);
                        //   }
                        //   else {
                        //     var searchString = titlesCheckedText.map((x) => x.document_title).join(" | ");
                        //     getPredictedExternalDocs(searchString)
                        //   }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select a title from drop down list or type text"
                          inputRef={(input) => {
                            inputRef = input;
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sm={8}
                    md={7}
                    lg={6}
                    sx={{ textAlign: "right" }}
                  >
                    <Button
                      variant="contained"
                      className="gsk-btn primary pill"
                      disabled={
                        isGettingRegulations || !isGetRegulationsActiveText
                      }
                      onClick={(e) => {
                        if (
                          userSelectedTitleTextReview !== undefined &&
                          userSelectedTitleTextReview !== ""
                        ) {
                          getPredictedExternalDocs(userSelectedTitleTextReview);
                        } else {
                          var searchString = titlesCheckedText
                            .map((x) => x.document_title)
                            .join(" | ");
                          getPredictedExternalDocs(searchString);
                        }
                        setCurrentPageIndex(0);
                        setItemsPerPage(5);
                      }}
                    >
                      {!isGettingRegulations
                        ? "Get Regulations"
                        : "Getting Regulations"}
                    </Button>
                    <Button
                      variant="contained"
                      className="gsk-btn primary pill"
                      endIcon={<CopyAllTwoToneIcon />}
                      onClick={() => {
                        userSelectedTitleTextReview === ""
                          ? copyToClipboardTitle(titlesCheckedText)
                          : navigator.clipboard.writeText(
                              userSelectedTitleTextReview
                            );
                      }} //TODO:
                    >
                      Copy
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              ""
            )}
            <hr />
            <Grid container justify="space-between">
              <Grid item xs={6}>
                <Typography gutterBottom variant="subtitle1" component="p">
                  International Regulations
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                display="flex"
                justifyContent="flex-end"
                align="right"
              >
                <Grid item align="right">
                  <Typography
                    gutterBottom
                    variant="subtitle2"
                    component="p"
                    sx={{ paddingTop: "4px" }}
                  >
                    Filter : &nbsp;&nbsp;
                  </Typography>
                </Grid>
                <Grid item>
                  <Select
                    onChange={handleIntlRegFilterChange}
                    style={{
                      width: "200px",
                      height: "30px",
                      fontSize: "13px",
                      marginBottom: "5px",
                    }}
                    multiple
                    displayEmpty
                    value={selectedRegulationFilter}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>Select</em>;
                      }

                      setSelectedRegulationFilter(selected);
                      return selected.join(", ");
                    }}
                    MenuProps={MenuProps}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                  >
                    <MenuItem disabled value="">
                      <em>Select</em>
                    </MenuItem>
                    {dsources.map(
                      (dsource) =>
                        dsource.is_external === 1 && (
                          <MenuItem
                            key={dsource.document_source}
                            value={dsource.document_source}
                            style={getStyles()}
                          >
                            {dsource.document_source}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
            {suggestionsPredicted_externalDocumentTitles.length > 0 ? (
              <>
                <Box sx={{ overflowX: "scroll" }}>
                  <P
                    list={suggestionsPredicted_externalDocumentTitles.filter(
                      (e) =>
                        selectedRegulationFilter.includes(e.document_source)
                    )}
                    itemsPerPage={itemsPerPage}
                    currentPageIndex={currentPageIndex}
                    maximumVisiblePaginators={5}
                    renderChildren={renderSuggestionsInternationalRegulations}
                    useDefaultStyles
                    showCounter
                    changePageIndex={(e) => {
                      setCurrentPageIndex(e);
                    }}
                    arrow={PageArrow}
                  />
                </Box>

                <Box sx={{ textAlign: "right" }}>
                  <Button
                    variant="contained"
                    className="gsk-btn primary pill"
                    endIcon={<CopyAllTwoToneIcon />}
                    onClick={() => {
                      copyToClipboard(policyDocCheckedText, "document_title");
                    }} //TODO:
                  >
                    Copy
                  </Button>
                </Box>
              </>
            ) : (
              ""
            )}
            <hr />
            <Typography gutterBottom variant="subtitle1" component="p">
              DI Findings:
            </Typography>
            <TextField
              className="gsk-input"
              id="filled-basic"
              variant="filled"
              size="small"
              value={diFindingText}
              onChange={(e) => {
                setDIFindingText(e.target.value);
              }}
            />
            <Box sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                className="gsk-btn primary pill"
                endIcon={<CopyAllTwoToneIcon />}
                onClick={(e) => {
                  navigator.clipboard.writeText(diFindingText);
                }} //TODO:
              >
                Copy
              </Button>
            </Box>
          </Box>
        </Grid>
      ) : (
        <span style={{ margin: "20px" }}>
          Tip: Enter audit findings text in the text area on the left side and
          click on Get Recommendations.
        </span>
      )}
    </Grid>
  );
}
