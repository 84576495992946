import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    ArcElement
} from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

export default function Chart({ type }) {
    let data = {
        labels: [],
        datasets: []
    };

    let options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Bar Chart',
            },
        },
        scales: {
            x: {
                stacked: false,
            },
            y: {
                stacked: false,
            },
        }
    };

    if (type === 'Bar') {
        options.plugins.title.text = 'Rec Dev (All Dev) - Sub Department -Sep 22 - YTD';
        data.labels = ['GIO/Q', 'Vaccines R&D', 'Global Industrial Operations', 'Vx Technical R&D', 'Vx Clinical Sciences', 'Belgium Operations', 'Analytical R&D', 'Secondary Operations', 'R&D GMP Manufacturing & Clinical Supply', 'Manufacturing Science & Technology', 'Primary Operations', 'Global Vaccines Supply Chain', 'Dev - Global ClinOps-Clinical Laboratory', 'R&D Drug Substance'];
        data.datasets.push({
            label: '% rec deviation',
            data: [11.90, 7.60, 2.30, 4.80, 12.45, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00],
            backgroundColor: 'rgba(53, 162, 235, 0.5)'
        });
    }
    if (type === 'Stacked Bar') {
        options.plugins.title.text = 'Overdue CAPA plan -GxP-Details by sub-department - Sep 22';
        options.scales.x.stacked = true;
        options.scales.y.stacked = true;
        data.labels = ['Vaccines R&D', 'Global Industrial Operations', 'Vx Technical R&D', 'Vx Clinical Sciences', 'Belgium Operations', 'Analytical R&D', 'Secondary Operations', 'R&D GMP Manufacturing & Clinical Supply', 'Manufacturing Science & Technology'];
        // data.datasets.push({
        //     label: 'Others',
        //     data: [138,151,118,173,11,192,112,100,105],
        //     backgroundColor: 'rgba(53, 162, 235, 0.5)'
        // });
        data.datasets.push({
            label: 'GQA Audit',
            data: [4, 7, 3, 6, 5, 9, 2, 0, 0],
            backgroundColor: 'rgba(75, 192, 192, 0.2)'
        });
        data.datasets.push({
            label: 'L1',
            data: [1, 2, 3, 0, 1, 3, 1, 1, 0],
            backgroundColor: 'rgba(54, 162, 235, 1)'
        });
        data.datasets.push({
            label: 'L2',
            data: [2, 0, 1, 2, 4, 0, 0, 0, 0],
            backgroundColor: 'rgba(75, 192, 192, 0.2)'
        });
        data.datasets.push({
            label: 'L3',
            data: [5, 1, 1, 0, 2, 3, 5, 1, 1],
            backgroundColor: 'rgba(153, 102, 255, 0.2)'
        });
        data.datasets.push({
            label: 'L4',
            data: [2, 1, 2, 0, 1, 3, 0, 1, 0],
            backgroundColor: 'rgba(255, 159, 64, 0.2)'
        });

        return <Bar options={options} data={data} />;
    }
    if (type === 'Clustered Bar') {
        options.plugins.title.text = 'Overdue CAPA plan -GxP-Details by sub-department - Sep 22';
        data.labels = ['Vaccines R&D', 'Global Industrial Operations', 'Vx Technical R&D', 'Vx Clinical Sciences', 'Belgium Operations', 'Analytical R&D', 'Secondary Operations', 'R&D GMP Manufacturing & Clinical Supply', 'Manufacturing Science & Technology']
        // data.datasets.push({
        //     label: 'Others',
        //     data: [138,151,118,173,11,192,112,100,105],
        //     backgroundColor: 'rgba(53, 162, 235, 0.5)'
        // });
        data.datasets.push({
            label: 'GQA Audit',
            data: [4, 7, 3, 6, 5, 9, 2, 0, 0],
            backgroundColor: 'rgba(75, 192, 192, 0.2)'
        });
        data.datasets.push({
            label: 'L1',
            data: [1, 2, 3, 0, 1, 3, 1, 1, 0],
            backgroundColor: 'rgba(54, 162, 235, 1)'
        });
        data.datasets.push({
            label: 'L2',
            data: [2, 0, 1, 2, 4, 0, 0, 0, 0],
            backgroundColor: 'rgba(75, 192, 192, 0.2)'
        });
        data.datasets.push({
            label: 'L3',
            data: [5, 1, 1, 0, 2, 3, 5, 1, 1],
            backgroundColor: 'rgba(153, 102, 255, 0.2)'
        });
        data.datasets.push({
            label: 'L4',
            data: [2, 1, 2, 0, 1, 3, 0, 1, 0],
            backgroundColor: 'rgba(255, 159, 64, 0.2)'
        });

        return <Bar options={options} data={data} />;
    }
    if (type === 'Line') {
        options.plugins.title.text = 'Rec. Dev. (All Dev) - YTD';
        data.labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        data.datasets.push({
            label: 'Total Closed Deviation',
            data: [1113, 2476, 4098, 5576, 7047, 7934, 8567, 9321, 10234, 10236, 11234, 12564],
            backgroundColor: 'rgba(53, 162, 235, 0.5)'
        });
        data.datasets.push({
            label: 'Recurrent Deviation',
            data: [151, 201, 245, 289, 321, 346, 398, 436, 505, 542, 565, 587],
            backgroundColor: 'rgba(75, 192, 192, 0.2)'
        })
        return <Line options={options} data={data} />;
    }
    if (type === 'Pie') {
        options = {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: true,
                    text: 'Overall Breakdown of Quality Alert Actual and Pending response',
                },
            }
        }
        data.labels = ['No Response - Overdue', 'Accepted', 'Declined'];
        data.datasets = [
            {
                label: 'Response',
                data: [89, 257, 398],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            }]

        return <Pie data={data} options={options} />;
    }
    return <Bar options={options} data={data} />;
}
