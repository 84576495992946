/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from "react";

import "./filters.css";
import { BreadcrumsContext } from "../context/breadcrumsContext";
import CustomDateRangeInputs from "./daterange";
import axios from "axios";
import { UserDataContext } from "../../Context/UserDataContext";
import SiteDocumentUpload from "../main/SiteDocumentUpload";
import * as rimaConstants from "../../../rimaconstants";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import {
  insertNotification,
  updateNoticationStatus,
  araSelector,
} from "../../../reducers/araReducer";
import { useDispatch, useSelector } from "react-redux";
import SearchableDropdown from "../../Common/searchableDropdown";
import SearchableDropdownGroup from "../../Common/searchableDropdownGroup";
import Checkbox from "@mui/material/Checkbox";
import LoadingButton from "@mui/lab/LoadingButton";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../applicationInsights/AppInsights";

const Filters = (props) => {
  const { breadcrums, setBreadcrums } = useContext(BreadcrumsContext);
  const label = { inputProps: { "aria-label": "Closed Audits" } };
  ////const [vaccine, setVaccine] = useState(true);
  // const [valueStartDate, setValueStartDate] = React.useState(null);
  // const [valueEndDate, setValueEndDate] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [resetOptions, setResetOptions] = useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [loading1, setLoading1] = React.useState(false);
  const [resetOptions1, setResetOptions1] = useState(null);
  const [inputValue1, setInputValue1] = React.useState("");
  const [loading2, setLoading2] = React.useState(false);
  const [resetOptions2, setResetOptions2] = useState(null);
  const [inputValue2, setInputValue2] = React.useState("");
  // const [ytd, setYtd] = React.useState("0");
  const dispatch = useDispatch();
  const { runningFileUploadIds } = useSelector(araSelector);
  const [closedAudits, setClosedAudits] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [adminUser, setAdminUser, user, setUser] = useContext(UserDataContext);
  const [runId, setRunId] = useState(null);
  const [noteBookStates, setNoteBookStates] = useState({
    SQP: true,
    SQCM: true,
    LIC: true,
    DMC: true,
    MERCU: true,
    SOPINDEX: true,
    DEVIATIONHANDLING: true,
    CAPA: true,
    SterileProcessValidationMonitoringControl: true,
    CecleaningValidationVerification: true,
    dataManagementControl: true,
    UNSTRUCTUREDINSIGHTS: true,
    qualityKPIS: true,
    powerBIQualityDashboard: true,
    WarehouseManagement: true,
    CHANGE_CONTROL: true,
    laboratory: true,
    Repacking: true,
    QUALITY_ALERT_AND_BULLETIN: true,
    MAINTANCE_AND_CALIBRATION: true,
  });

  const resetNotebokeStates = () => {
    setNoteBookStates({
      SQP: true,
      SQCM: true,
      LIC: true,
      DMC: true,
      MERCU: true,
      SOPINDEX: true,
      DEVIATIONHANDLING: true,
      CAPA: true,
      SterileProcessValidationMonitoringControl: true,
      CecleaningValidationVerification: true,
      dataManagementControl: true,
      UNSTRUCTUREDINSIGHTS: true,
      qualityKPIS: true,
      powerBIQualityDashboard: true,
      WarehouseManagement: true,
      CHANGE_CONTROL: true,
      laboratory: true,
      Repacking: true,
      QUALITY_ALERT_AND_BULLETIN: true,
      MAINTANCE_AND_CALIBRATION: true,
    });
  };

  const updateNotebookStates = (key) => {
    if (noteBookStates.hasOwnProperty(`${key}`)) {
      let obj = {};
      obj[`${key}`] = false;
      setNoteBookStates({ ...noteBookStates, ...obj });
    } else {
      alert("Update Notebook States: key not found - ", key);
    }
  };

  const [dataSource, setDataSource] = useState("VX");
  // const [countryCode, setCountryCode] = useState("");
  const [siteId, setSiteId] = useState("");
  const [auditName, setAuditName] = useState("");
  const toggleActiveClass = (val) => {
    if (val === "VACCINE") {
      setDataSource("VX");
      setAuditStartDate("");
      setAuditEndDate("");
      //props.setYtd(12);
      props.setDataSource("VX");
    } else {
      setDataSource("RX");
      setAuditStartDate("");
      setAuditEndDate("");
      //props.setYtd(12);
      props.setDataSource("RX");
    }

    setAuditName("");
    // setCountryCode("");
    setSiteId("");

    setBreadcrums((prevBreadcrums) => {
      prevBreadcrums.vaccine = val === "VACCINE" ? true : false;
      prevBreadcrums.pharma = val === "PHARMA" ? true : false;
      prevBreadcrums.parentMenu = "";
      prevBreadcrums.childMenu = "";
      prevBreadcrums.disabledAuditFildWorkDate = false;
      prevBreadcrums.disabledValueDispled = false;
      prevBreadcrums.siteId = "";
      prevBreadcrums.inferencesData = "";
      prevBreadcrums.auditTitle = "";
      prevBreadcrums.resetCountryFilter = true;
      return { ...prevBreadcrums };
    });
  };

  const datesHandler = (type, value) => {
    if (type === "start") {
      props.setValueStartDate(value);
      setBreadcrums((prevBreadcrums) => {
        prevBreadcrums.startDates = formatDate(value);
        return { ...prevBreadcrums };
      });
    } else {
      props.setValueEndDate(value);
      setBreadcrums((prevBreadcrums) => {
        prevBreadcrums.endDate = formatDate(value);
        return { ...prevBreadcrums };
      });
    }
  };

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const [auditDDL, setAuditDDL] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [sites, setSites] = React.useState([]);
  const [auditStartDate, setAuditStartDate] = React.useState("");
  const [auditEndDate, setAuditEndDate] = React.useState("");

  const resetInferences = () => {
    setBreadcrums((prevBreadcrums) => {
      prevBreadcrums.inferencesData = "";
      return { ...prevBreadcrums };
    });
  };

  const updateDateFilter = () => {
    let startDate = null;
    if (breadcrums.vaccine && breadcrums.childMenu === "SOP Index") {
      props.setYtd(60);
      startDate = window.addMonthToDate(new Date(), 60, "start");
    } else if (!breadcrums.vaccine && breadcrums.childMenu === "SOP Index") {
      props.setYtd(13);
      startDate = window.getDate(new Date());
    } else {
      props.setYtd(12);
      startDate = window.addMonthToDate(new Date(), 12, "start");
    }
    return startDate;
  };

  const loadAuditDDL = () => {
    setLoading(true);
    let audit_status = 2;
    if (closedAudits) audit_status = 3;
    setResetOptions(null);
    setResetOptions1(null);
    setResetOptions2(null);
    setAuditDDL([]);

    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/ara/audit/ddl/${dataSource}/${audit_status}`
      )
      .then((response) => {
        ////console.log("response.data ", response);
        if (response?.data?.data?.length > 0) {
          let options = [];
          response.data.data.map((val) => {
            options.push({
              label:
                window.customEscape(val.audit_number) +
                " - " +
                window.customEscape(val.audit_title),
              id:
                val.audit_number +
                "@#@" +
                val.country_code +
                "@#@" +
                val.audit_site_id +
                "@#@" +
                val.site_description +
                "@#@" +
                val.audit_id +
                "@#@" +
                val.audit_title +
                "@#@" +
                val.audit_type_id,
              usersIDs: val.usersIDs
                .substring(0, val.usersIDs.length - 1)
                .split(";"),
              startDate: window.getDate(val.planned_fieldwork_start_date),
              endDate: window.getDate(val.planned_fieldwork_end_date),
            });
            return true;
          });
          setAuditDDL(options);
          //setAuditDDL(response.data["data"]);
        } else {
          setAuditDDL([]);
        }
        setLoading(false);
      });
  };

  const loadCountry = () => {
    setLoading1(true);
    setResetOptions1(null);
    setResetOptions2(null);
    setCountries([]);
    setSites([]);
    // setCountryCode("");
    setSiteId("");
    setBreadcrums((prevBreadcrums) => {
      prevBreadcrums.siteId = "";
      return { ...prevBreadcrums };
    });
    let data_source = breadcrums.vaccine ? "VX" : "RX";
    axios
      .get(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/ara/countries/${data_source}`
      )
      .then((response) => {
        if (response?.data?.length > 0) {
          let options = [];
          response.data.map((val) => {
            options.push({
              label: window.customEscape(val.country_name),
              id: val.country_code,
            });
            return true;
          });
          setCountries(options);
        }
        setLoading1(false);
      });
  };

  React.useEffect(() => {
    resetNotebokeStates();
  }, [breadcrums.childMenu]);

  React.useEffect(() => {
    if (breadcrums.vaccine || breadcrums.pharma) {
      loadAuditDDL();
      loadCountry();
      setBreadcrums((prevBreadcrums) => {
        prevBreadcrums.tableColumnData = "";
        prevBreadcrums.tableRowData = "";
        return { ...prevBreadcrums };
      });
    }
  }, [dataSource, closedAudits]);

  React.useEffect(() => {
    if (!breadcrums.closedAudits) {
      setClosedAudits(false);
    }
  }, [breadcrums.closedAudits]);

  const refreshInferences = () => {
    setBreadcrums((prevBreadcrums) => {
      prevBreadcrums.refreshInferences = prevBreadcrums.refreshInferences + 1;
      return { ...prevBreadcrums };
    });
  };

  const showFailedMessage = (data) => {
    data?.msg
      ? window.showMessage(`Function App error: ${data.msg}`, "error")
      : typeof data === "string" &&
        data?.toLowerCase() !== '"success"' &&
        data?.toLowerCase() !== "'success'" &&
        data?.toLowerCase() !== "success" &&
        window.showMessage(`Function App error: ${data}`, "error");
  };

  const loadInferenceDeviationVx = () => {
    localStorage.setItem("isInsightsDeleted", false);
    window.showLoader();
    const [
      // eslint-disable-next-line no-unused-vars
      audit_number,
      // eslint-disable-next-line no-unused-vars
      country_code,
      // eslint-disable-next-line no-unused-vars
      audit_site_id,
      site_description,
      audit_id,
      // eslint-disable-next-line no-unused-vars
      audit_title,
      // eslint-disable-next-line no-unused-vars
      audit_type_id,
    ] = auditName.split("@#@");

    axios
      .post(
        `${
          rimaConstants.REACT_APP_API_URL
        }/api/v1/inferences/DeviationInsights/${
          breadcrums.vaccine ? "Vx" : "Rx"
        }/${window.getFeatureID()}/${audit_id}`,
        {
          site_name: window.base64_encode(site_description),
          start_date: window.getDateDB(
            breadcrums.startDates,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
          end_date: window.getDateDB(
            breadcrums.endDate,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status !== "success") {
          showFailedMessage(response.data);
        }
        updateNotebookStates("DEVIATIONHANDLING");
        setRunId(response.data.data);
        refreshInferences();
      })
      .catch((error) => {
        window.hideLoader();
        console.log(error);
      });
  };

  const loadLaboratory_Insights = () => {
    localStorage.setItem("isInsightsDeleted", false);
    window.showLoader();
    const [
      // eslint-disable-next-line no-unused-vars
      audit_number,
      // eslint-disable-next-line no-unused-vars
      country_code,
      // eslint-disable-next-line no-unused-vars
      audit_site_id,
      site_description,
      audit_id,
      // eslint-disable-next-line no-unused-vars
      audit_title,
      // eslint-disable-next-line no-unused-vars
      audit_type_id,
    ] = auditName.split("@#@");

    axios
      .post(
        `${
          rimaConstants.REACT_APP_API_URL
        }/api/v1/inferences/Laboratory_Insights/${
          breadcrums.vaccine ? "Vx" : "Rx"
        }/${window.getFeatureID()}/${audit_id}`,
        {
          site_name: window.base64_encode(site_description),
          start_date: window.getDateDB(
            breadcrums.startDates,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
          end_date: window.getDateDB(
            breadcrums.endDate,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status !== "success") {
          showFailedMessage(response.data);
        }
        updateNotebookStates("laboratory");
        setRunId(response.data.data);
        refreshInferences();
      })
      .catch((error) => {
        window.hideLoader();
        console.log(error);
      });
  };

  const unstructured_Insights = () => {
    localStorage.setItem("isInsightsDeleted", false);
    window.showLoader();
    const [
      // eslint-disable-next-line no-unused-vars
      audit_number,
      // eslint-disable-next-line no-unused-vars
      country_code,
      // eslint-disable-next-line no-unused-vars
      audit_site_id,
      site_description,
      audit_id,
      // eslint-disable-next-line no-unused-vars
      audit_title,
      // eslint-disable-next-line no-unused-vars
      audit_type_id,
    ] = auditName.split("@#@");

    axios
      .post(
        `${
          rimaConstants.REACT_APP_API_URL
        }/api/v1/inferences/unstructured_Insights/${
          breadcrums.vaccine ? "Vx" : "Rx"
        }`,
        {
          feature_id: window.getFeatureID(),
          documentTypes: localStorage.getItem("documentTypes"),
          keywords: localStorage.getItem("searchKeywords"),
          audit_number: audit_number,
          audit_id: audit_id,
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status !== "success") {
          showFailedMessage(response.data);
        }
        updateNotebookStates("UNSTRUCTUREDINSIGHTS");
        setRunId(response.data.data);
        refreshInferences();
      })
      .catch((error) => {
        window.hideLoader();
        console.log(error);
      });
  };

  const loadInferenceSopIndexVx = () => {
    localStorage.setItem("isInsightsDeleted", false);
    window.showLoader();
    const [
      // eslint-disable-next-line no-unused-vars
      audit_number,
      // eslint-disable-next-line no-unused-vars
      country_code,
      // eslint-disable-next-line no-unused-vars
      audit_site_id,
      site_description,
      audit_id,
      // eslint-disable-next-line no-unused-vars
      audit_title,
      // eslint-disable-next-line no-unused-vars
      audit_type_id,
    ] = auditName.split("@#@");

    axios
      .post(
        `${
          rimaConstants.REACT_APP_API_URL
        }/api/v1/inferences/SOP_Index_Vx_Insights/${
          breadcrums.vaccine ? "Vx" : "Rx"
        }/${window.getFeatureID()}/${audit_id}`,
        {
          site_name: window.base64_encode(site_description),
          start_date: window.getDateDB(
            breadcrums.startDates,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
          end_date: window.getDateDB(
            breadcrums.endDate,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status !== "success") {
          showFailedMessage(response.data);
        }

        updateNotebookStates("SOPINDEX");
        setRunId(response.data.data);
        refreshInferences();
      })
      .catch((error) => {
        window.hideLoader();
        console.log(error);
      });
  };

  const loadInferenceMrecu = () => {
    localStorage.setItem("isInsightsDeleted", false);
    window.showLoader();
    const [
      // eslint-disable-next-line no-unused-vars
      audit_number,
      // eslint-disable-next-line no-unused-vars
      country_code,
      // eslint-disable-next-line no-unused-vars
      audit_site_id,
      site_description,
      audit_id,
      // eslint-disable-next-line no-unused-vars
      audit_title,
      // eslint-disable-next-line no-unused-vars
      audit_type_id,
    ] = auditName.split("@#@");

    axios
      .post(
        `${
          rimaConstants.REACT_APP_API_URL
        }/api/v1/inferences/Environmental_Monitoring_UE/${
          breadcrums.vaccine ? "Vx" : "Rx"
        }/${window.getFeatureID()}/${audit_id}`,
        {
          site_name: window.base64_encode(site_description),
          start_date: window.getDateDB(
            breadcrums.startDates,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
          end_date: window.getDateDB(
            breadcrums.endDate,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status !== "success") {
          showFailedMessage(response.data);
        }
        updateNotebookStates("MERCU");
        setRunId(response.data.data);
        refreshInferences();
      })
      .catch((error) => {
        window.hideLoader();
        console.log(error);
      });
  };

  const loadInferencesDmc = () => {
    localStorage.setItem("isInsightsDeleted", false);
    window.showLoader();
    const [
      // eslint-disable-next-line no-unused-vars
      audit_number,
      // eslint-disable-next-line no-unused-vars
      country_code,
      // eslint-disable-next-line no-unused-vars
      audit_site_id,
      // eslint-disable-next-line no-unused-vars
      site_description,
      audit_id,
      // eslint-disable-next-line no-unused-vars
      audit_title,
      // eslint-disable-next-line no-unused-vars
      audit_type_id,
    ] = auditName.split("@#@");

    axios
      .post(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/inferences/dmc_insights/${
          breadcrums.vaccine ? "Vx" : "Rx"
        }/${window.getFeatureID()}/${audit_id}`,
        {
          site_name: window.base64_encode(site_description),
          start_date: window.getDateDB(
            breadcrums.startDates,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
          end_date: window.getDateDB(
            breadcrums.endDate,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status !== "success") {
          showFailedMessage(response.data);
        }
        updateNotebookStates("DMC");
        setRunId(response.data.data);
        refreshInferences();
      })
      .catch((error) => {
        window.hideLoader();
        console.log(error);
      });
  };

  const loadInferencesLic = () => {
    localStorage.setItem("isInsightsDeleted", false);
    window.showLoader();
    const [
      // eslint-disable-next-line no-unused-vars
      audit_number,
      // eslint-disable-next-line no-unused-vars
      country_code,
      // eslint-disable-next-line no-unused-vars
      audit_site_id,
      // eslint-disable-next-line no-unused-vars
      site_description,
      audit_id,
      // eslint-disable-next-line no-unused-vars
      audit_title,
      // eslint-disable-next-line no-unused-vars
      audit_type_id,
    ] = auditName.split("@#@");

    axios
      .post(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/site_document_upload_page/insights_lic`,
        {
          feature_id: window.getFeatureID(),
          audit_id: audit_id,
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status !== "success") {
          showFailedMessage(response.data);
        }
        updateNotebookStates("LIC");
        setRunId(response.data.data);
        refreshInferences();
      })
      .catch((error) => {
        window.hideLoader();
        console.log(error);
      });
  };

  const loadInferencesSqcm = () => {
    localStorage.setItem("isInsightsDeleted", false);
    window.showLoader();
    const [
      // eslint-disable-next-line no-unused-vars
      audit_number,
      // eslint-disable-next-line no-unused-vars
      country_code,
      // eslint-disable-next-line no-unused-vars
      audit_site_id,
      // eslint-disable-next-line no-unused-vars
      site_description,
      audit_id,
      // eslint-disable-next-line no-unused-vars
      audit_title,
      // eslint-disable-next-line no-unused-vars
      audit_type_id,
    ] = auditName.split("@#@");

    axios
      .post(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/site_document_upload_page/insights_sqcm`,
        {
          feature_id: window.getFeatureID(),
          audit_id: audit_id,
          data_source: breadcrums.vaccine ? "Vx" : "Rx",
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status !== "success") {
          showFailedMessage(response.data);
        }
        updateNotebookStates("SQCM");
        setRunId(response.data.data);
        refreshInferences();
      })
      .catch((error) => {
        window.hideLoader();
        console.log(error);
      });
  };

  const insight_extraction_sqp = (path) => {
    localStorage.setItem("isInsightsDeleted", false);
    // window.showLoader();
    setDefaultInsights({
      status: "success",
      data: { data: ["No Insights Found"] },
    });
    return true;

    const [
      // eslint-disable-next-line no-unused-vars
      audit_number,
      // eslint-disable-next-line no-unused-vars
      country_code,
      // eslint-disable-next-line no-unused-vars
      audit_site_id,
      // eslint-disable-next-line no-unused-vars
      site_description,
      audit_id,
      // eslint-disable-next-line no-unused-vars
      audit_title,
      // eslint-disable-next-line no-unused-vars
      audit_type_id,
    ] = auditName.split("@#@");
    let data_source = breadcrums.vaccine ? "Vx" : "Rx";

    axios
      .post(
        `${
          rimaConstants.REACT_APP_API_URL
        }/api/v1/inferences/sqp_insights/${data_source}/${window.getFeatureID()}/${audit_id}`
      )
      .then((response) => {
        window.hideLoader();
        updateNotebookStates("SQP");
        refreshInferences();
        if (response?.data?.status !== "success") {
          showFailedMessage(response.data);
        }
      })
      .catch((error) => {
        window.hideLoader();
        console.log(error);
      });
  };

  const loadInferenceChangeControl = () => {
    localStorage.setItem("isInsightsDeleted", false);
    window.showLoader();
    const [
      // eslint-disable-next-line no-unused-vars
      audit_number,
      // eslint-disable-next-line no-unused-vars
      country_code,
      // eslint-disable-next-line no-unused-vars
      audit_site_id,
      site_description,
      audit_id,
      // eslint-disable-next-line no-unused-vars
      audit_title,
      // eslint-disable-next-line no-unused-vars
      audit_type_id,
    ] = auditName.split("@#@");

    axios
      .post(
        `${
          rimaConstants.REACT_APP_API_URL
        }/api/v1/inferences/change_control_insights/${
          breadcrums.vaccine ? "Vx" : "Rx"
        }/${window.getFeatureID()}/${audit_id}`,
        {
          site_name: window.base64_encode(site_description),
          start_date: window.getDateDB(
            breadcrums.startDates,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
          end_date: window.getDateDB(
            breadcrums.endDate,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status !== "success") {
          showFailedMessage(response.data);
        }
        updateNotebookStates("CHANGE_CONTROL");
        setRunId(response.data.data);
        refreshInferences();
      })
      .catch((error) => {
        window.hideLoader();
        console.log(error);
      });
  };

  const loadInferenceQualityAleartAndBulletin = () => {
    localStorage.setItem("isInsightsDeleted", false);
    window.showLoader();
    const [
      // eslint-disable-next-line no-unused-vars
      audit_number,
      // eslint-disable-next-line no-unused-vars
      country_code,
      // eslint-disable-next-line no-unused-vars
      audit_site_id,
      site_description,
      audit_id,
      // eslint-disable-next-line no-unused-vars
      audit_title,
      // eslint-disable-next-line no-unused-vars
      audit_type_id,
    ] = auditName.split("@#@");

    axios
      .post(
        `${
          rimaConstants.REACT_APP_API_URL
        }/api/v1/inferences/quality_alert_bulletin_mgmt_insights/${
          breadcrums.vaccine ? "Vx" : "Rx"
        }/${window.getFeatureID()}/${audit_id}`,
        {
          site_name: window.base64_encode(site_description),
          start_date: window.getDateDB(
            breadcrums.startDates,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
          end_date: window.getDateDB(
            breadcrums.endDate,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status !== "success") {
          showFailedMessage(response.data);
        }
        updateNotebookStates("QUALITY_ALERT_AND_BULLETIN");
        setRunId(response.data.data);
        refreshInferences();
      })
      .catch((error) => {
        window.hideLoader();
        console.log(error);
      });
  };

  const loadmaintenanceCalliberation = () => {
    localStorage.setItem("isInsightsDeleted", false);
    window.showLoader();
    const [
      // eslint-disable-next-line no-unused-vars
      audit_number,
      // eslint-disable-next-line no-unused-vars
      country_code,
      // eslint-disable-next-line no-unused-vars
      audit_site_id,
      site_description,
      audit_id,
      // eslint-disable-next-line no-unused-vars
      audit_title,
      // eslint-disable-next-line no-unused-vars
      audit_type_id,
    ] = auditName.split("@#@");

    axios
      .post(
        `${
          rimaConstants.REACT_APP_API_URL
        }/api/v1/inferences/maintenance_calliberation_insights/${
          breadcrums.vaccine ? "Vx" : "Rx"
        }/${window.getFeatureID()}/${audit_id}`,
        {
          site_name: window.base64_encode(site_description),
          start_date: window.getDateDB(
            breadcrums.startDates,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
          end_date: window.getDateDB(
            breadcrums.endDate,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status !== "success") {
          showFailedMessage(response.data);
        }
        updateNotebookStates("MAINTANCE_AND_CALIBRATION");
        setRunId(response.data.data);
        refreshInferences();
      })
      .catch((error) => {
        window.hideLoader();
        console.log(error);
      });
  };

  const loadInferenceCAPA = () => {
    localStorage.setItem("isInsightsDeleted", false);
    window.showLoader();
    const [
      // eslint-disable-next-line no-unused-vars
      audit_number,
      // eslint-disable-next-line no-unused-vars
      country_code,
      // eslint-disable-next-line no-unused-vars
      audit_site_id,
      site_description,
      audit_id,
      // eslint-disable-next-line no-unused-vars
      audit_title,
      // eslint-disable-next-line no-unused-vars
      audit_type_id,
    ] = auditName.split("@#@");

    axios
      .post(
        `${rimaConstants.REACT_APP_API_URL}/api/v1/inferences/CAPA_Insights/${
          breadcrums.vaccine ? "Vx" : "Rx"
        }/${window.getFeatureID()}/${audit_id}`,
        {
          site_name: window.base64_encode(site_description),
          start_date: window.getDateDB(
            breadcrums.startDates,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
          end_date: window.getDateDB(
            breadcrums.endDate,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status !== "success") {
          showFailedMessage(response.data);
        }
        updateNotebookStates("CAPA");
        setRunId(response.data.data);
        refreshInferences();
      })
      .catch((error) => {
        window.hideLoader();
        console.log(error);
      });
  };

  const loadInferenceWarehouseManagement = () => {
    localStorage.setItem("isInsightsDeleted", false);
    window.showLoader();
    const [
      // eslint-disable-next-line no-unused-vars
      audit_number,
      // eslint-disable-next-line no-unused-vars
      country_code,
      // eslint-disable-next-line no-unused-vars
      audit_site_id,
      site_description,
      audit_id,
      // eslint-disable-next-line no-unused-vars
      audit_title,
      // eslint-disable-next-line no-unused-vars
      audit_type_id,
    ] = auditName.split("@#@");

    axios
      .post(
        `${
          rimaConstants.REACT_APP_API_URL
        }/api/v1/inferences/WarehouseManagement_Insights/${
          breadcrums.vaccine ? "Vx" : "Rx"
        }/${window.getFeatureID()}/${audit_id}`,
        {
          site_name: window.base64_encode(site_description),
          start_date: window.getDateDB(
            breadcrums.startDates,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
          end_date: window.getDateDB(
            breadcrums.endDate,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status !== "success") {
          showFailedMessage(response.data);
        }
        updateNotebookStates("WarehouseManagement");
        setRunId(response.data.data);
        refreshInferences();
      })
      .catch((error) => {
        window.hideLoader();
        console.log(error);
      });
  };

  const loadInferenceSterileProcessValidationMonitoringControl = () => {
    localStorage.setItem("isInsightsDeleted", false);
    window.showLoader();
    const [
      // eslint-disable-next-line no-unused-vars
      audit_number,
      // eslint-disable-next-line no-unused-vars
      country_code,
      // eslint-disable-next-line no-unused-vars
      audit_site_id,
      site_description,
      audit_id,
      // eslint-disable-next-line no-unused-vars
      audit_title,
      // eslint-disable-next-line no-unused-vars
      audit_type_id,
    ] = auditName.split("@#@");

    axios
      .post(
        `${
          rimaConstants.REACT_APP_API_URL
        }/api/v1/inferences/sterileProcessValidationMonitoringControl_Insights/${
          breadcrums.vaccine ? "Vx" : "Rx"
        }/${window.getFeatureID()}/${audit_id}`,
        {
          site_name: window.base64_encode(site_description),
          start_date: window.getDateDB(
            breadcrums.startDates,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
          end_date: window.getDateDB(
            breadcrums.endDate,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status !== "success") {
          showFailedMessage(response.data);
        }
        updateNotebookStates("SterileProcessValidationMonitoringControl");
        setRunId(response.data.data);
        refreshInferences();
      })
      .catch((error) => {
        window.hideLoader();
        console.log(error);
      });
  };

  const loadInferenCecleaningValidationVerification_Insights = () => {
    localStorage.setItem("isInsightsDeleted", false);
    window.showLoader();
    const [
      // eslint-disable-next-line no-unused-vars
      audit_number,
      // eslint-disable-next-line no-unused-vars
      country_code,
      // eslint-disable-next-line no-unused-vars
      audit_site_id,
      site_description,
      audit_id,
      // eslint-disable-next-line no-unused-vars
      audit_title,
      // eslint-disable-next-line no-unused-vars
      audit_type_id,
    ] = auditName.split("@#@");

    axios
      .post(
        `${
          rimaConstants.REACT_APP_API_URL
        }/api/v1/inferences/cleaningValidationVerification_Insights/${
          breadcrums.vaccine ? "Vx" : "Rx"
        }/${window.getFeatureID()}/${audit_id}`,
        {
          site_name: window.base64_encode(site_description),
          start_date: window.getDateDB(
            breadcrums.startDates,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
          end_date: window.getDateDB(
            breadcrums.endDate,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status !== "success") {
          showFailedMessage(response.data);
        }
        updateNotebookStates("CecleaningValidationVerification");
        setRunId(response.data.data);
        refreshInferences();
      })
      .catch((error) => {
        window.hideLoader();
        console.log(error);
      });
  };

  const loadInferenRepacking_Insights = () => {
    localStorage.setItem("isInsightsDeleted", false);
    window.showLoader();
    const [
      // eslint-disable-next-line no-unused-vars
      audit_number,
      // eslint-disable-next-line no-unused-vars
      country_code,
      // eslint-disable-next-line no-unused-vars
      audit_site_id,
      site_description,
      audit_id,
      // eslint-disable-next-line no-unused-vars
      audit_title,
      // eslint-disable-next-line no-unused-vars
      audit_type_id,
    ] = auditName.split("@#@");

    axios
      .post(
        `${
          rimaConstants.REACT_APP_API_URL
        }/api/v1/inferences/Repacking_Insights/${
          breadcrums.vaccine ? "Vx" : "Rx"
        }/${window.getFeatureID()}/${audit_id}`,
        {
          site_name: window.base64_encode(site_description),
          start_date: window.getDateDB(
            breadcrums.startDates,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
          end_date: window.getDateDB(
            breadcrums.endDate,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status !== "success") {
          showFailedMessage(response.data);
        }
        updateNotebookStates("Repacking");
        setRunId(response.data.data);
        refreshInferences();
      })
      .catch((error) => {
        window.hideLoader();
        console.log(error);
      });
  };

  const loadInferenDataManagementControl_Insights = () => {
    localStorage.setItem("isInsightsDeleted", false);
    window.showLoader();
    const [
      // eslint-disable-next-line no-unused-vars
      audit_number,
      // eslint-disable-next-line no-unused-vars
      country_code,
      // eslint-disable-next-line no-unused-vars
      audit_site_id,
      site_description,
      audit_id,
      // eslint-disable-next-line no-unused-vars
      audit_title,
      // eslint-disable-next-line no-unused-vars
      audit_type_id,
    ] = auditName.split("@#@");

    axios
      .post(
        `${
          rimaConstants.REACT_APP_API_URL
        }/api/v1/inferences/dataManagementControl_Insights/${
          breadcrums.vaccine ? "Vx" : "Rx"
        }/${window.getFeatureID()}/${audit_id}`,
        {
          site_name: window.base64_encode(site_description),
          start_date: window.getDateDB(
            breadcrums.startDates,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
          end_date: window.getDateDB(
            breadcrums.endDate,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          ),
        }
      )
      .then((response) => {
        window.hideLoader();
        if (response?.data?.status !== "success") {
          showFailedMessage(response.data);
        }
        updateNotebookStates("CecleaningValidationVerification");
        setRunId(response.data.data);
        refreshInferences();
      })
      .catch((error) => {
        window.hideLoader();
        console.log(error);
      });
  };

  const extractSqp = () => {
    let data_source = breadcrums.vaccine ? "VX" : "RX";
    if (data_source === "RX") {
      if (
        noteBookStates.SQP ||
        localStorage.getItem("isInsightsDeleted") === "true"
      )
        insight_extraction_sqp("insight_extraction_sqp");
    }
    if (data_source === "VX") {
      if (
        noteBookStates.SQP ||
        localStorage.getItem("isInsightsDeleted") === "true"
      )
        insight_extraction_sqp("insight_extraction_sqp_vx");
    }
    localStorage.setItem("isInsightsDeleted", false);
  };

  useEffect(() => {
    if (runId) {
      const item = runningFileUploadIds.findIndex(
        (id) => id.id === runId && id.status === "SUCCESS"
      );
      //console.log("item", item);
      if (item !== -1) {
        setRunId(null);
        refreshInferences();
      }
    }
  }, [JSON.stringify(runningFileUploadIds)]);

  const setDefaultInsights = (responseData) => {
    setBreadcrums((prevBreadcrums) => {
      prevBreadcrums.inferencesData = responseData.data["data"][0];
      return { ...prevBreadcrums };
    });
  };

  const executeFunctionApp = (responseData, noteBook) => {
    let flag = false;
    if (responseData?.data?.data?.length) {
      if (
        (noteBook &&
          window.validateInsightsCondition(responseData.data["data"][0])) ||
        localStorage.getItem("isInsightsDeleted") === "true"
      ) {
        flag = true;
      } else {
        setDefaultInsights(responseData);
        flag = false;
      }
    } else {
      if (noteBook || localStorage.getItem("isInsightsDeleted") === "true") {
        flag = true;
      }
    }
    localStorage.setItem("isInsightsDeleted", false);
    return flag;
  };

  const setLoadingButtonIniteateInsights = () => {
    setBreadcrums((prevBreadcrums) => {
      prevBreadcrums.loadingButtonIniteateInsights = false;
      return { ...prevBreadcrums };
    });
  };

  const loadInferencesInner = (path, responseData) => {
    window.hideLoader();
    setLoadingButtonIniteateInsights();
    let data_source = breadcrums.vaccine ? "Vx" : "Rx";
    let allowToExecute =
      localStorage.getItem("isInsightsDeleted") === "true" ? true : false;
    switch (path) {
      case "environmentalmonitoringue":
        if (
          allowToExecute ||
          executeFunctionApp(responseData, noteBookStates.MERCU)
        ) {
          loadInferenceMrecu();
        }
        break;
      case "dmc":
        if (
          allowToExecute ||
          executeFunctionApp(responseData, noteBookStates.DMC)
        ) {
          loadInferencesDmc();
        }
        break;
      case "lic":
        if (
          allowToExecute ||
          executeFunctionApp(responseData, noteBookStates.LIC)
        ) {
          loadInferencesLic();
        }
        break;
      case "sqcm":
        if (
          allowToExecute ||
          executeFunctionApp(responseData, noteBookStates.SQCM)
        ) {
          loadInferencesSqcm();
        }
        break;
      case "sqp":
        if (
          allowToExecute ||
          executeFunctionApp(responseData, noteBookStates.SQP)
        ) {
          extractSqp();
        }
        break;
      case "sopindex":
        if (
          data_source === "Vx" &&
          (allowToExecute ||
            executeFunctionApp(responseData, noteBookStates.SOPINDEX))
        ) {
          loadInferenceSopIndexVx();
        } else {
          data_source === "Rx" && setDefaultInsights(responseData);
        }
        break;
      case "deviation":
        if (
          allowToExecute ||
          executeFunctionApp(responseData, noteBookStates.DEVIATIONHANDLING)
        ) {
          loadInferenceDeviationVx();
        }
        break;
      case "capa":
        if (
          allowToExecute ||
          executeFunctionApp(responseData, noteBookStates.CAPA)
        ) {
          loadInferenceCAPA();
        }
        break;
      case "WarehouseManagement":
        if (
          allowToExecute ||
          executeFunctionApp(responseData, noteBookStates.WarehouseManagement)
        ) {
          loadInferenceWarehouseManagement();
        }
        break;
      case "sterileprocessvmc":
        if (
          allowToExecute ||
          executeFunctionApp(
            responseData,
            noteBookStates.SterileProcessValidationMonitoringControl
          )
        ) {
          loadInferenceSterileProcessValidationMonitoringControl();
        }
        break;
      case "cleaningvalidationv":
        if (
          allowToExecute ||
          executeFunctionApp(
            responseData,
            noteBookStates.CecleaningValidationVerification
          )
        ) {
          loadInferenCecleaningValidationVerification_Insights();
        }
        break;
      case "Repacking":
        if (
          allowToExecute ||
          executeFunctionApp(responseData, noteBookStates.Repacking)
        ) {
          loadInferenRepacking_Insights();
        }
        break;
      case "dataManagementControl":
        if (
          allowToExecute ||
          executeFunctionApp(responseData, noteBookStates.dataManagementControl)
        ) {
          loadInferenDataManagementControl_Insights();
        }
        break;
      case "laboratory":
        if (
          allowToExecute ||
          executeFunctionApp(responseData, noteBookStates.dataManagementControl)
        ) {
          loadLaboratory_Insights();
        }
        break;
      case "unstructuredInsightsExt":
        if (allowToExecute) {
          localStorage.setItem("isInsightsDeleted", false);
          loadInferences("unstructuredInsightsExt", undefined);
        } else if (
          window.validateInsightsCondition(responseData.data["data"][0])
        ) {
          setDefaultInsights({
            status: "success",
            data: { data: ["No Insights Found"] },
          });
        } else {
          setDefaultInsights(responseData);
        }
        break;
      case "unstructuredInsights":
        if (
          allowToExecute ||
          executeFunctionApp(responseData, noteBookStates.UNSTRUCTUREDINSIGHTS)
        ) {
          unstructured_Insights();
        }
        // if (allowToExecute) {
        //   localStorage.setItem("isInsightsDeleted", false);
        //   loadInferences("unstructuredInsights", undefined);
        // } else if (
        //   window.validateInsightsCondition(responseData.data["data"][0])
        // ) {
        //   // updateNotebookStates("UNSTRUCTUREDINSIGHTS");
        //   setDefaultInsights({
        //     status: "success",
        //     data: { data: ["No Insights Found"] },
        //   });
        // } else {
        //   setDefaultInsights(responseData);
        // }
        break;
      // case "qualityKPIS":
      //   if (
      //     allowToExecute ||
      //     executeFunctionApp(responseData, noteBookStates.qualityKPIS)
      //   ) {
      //     updateNotebookStates("qualityKPIS");
      //     setDefaultInsights({
      //       status: "success",
      //       data: { data: ["No Insights Found"] },
      //     });
      //   }
      //   break;

      // case "powerBIQualityDashboard":
      //   if (
      //     allowToExecute ||
      //     executeFunctionApp(
      //       responseData,
      //       noteBookStates.powerBIQualityDashboard
      //     )
      //   ) {
      //     updateNotebookStates("powerBIQualityDashboard");
      //     setDefaultInsights({
      //       status: "success",
      //       data: { data: ["No Insights Found"] },
      //     });
      //   }
      //   break;
      case "change_control":
        if (
          allowToExecute ||
          executeFunctionApp(responseData, noteBookStates.CHANGE_CONTROL)
        ) {
          loadInferenceChangeControl();
        }
        break;
      case "quality_alert_bulletin_mgmt":
        if (
          allowToExecute ||
          executeFunctionApp(
            responseData,
            noteBookStates.QUALITY_ALERT_AND_BULLETIN
          )
        ) {
          loadInferenceQualityAleartAndBulletin();
        }
        break;
      case "maintenance_calliberation":
        if (
          allowToExecute ||
          executeFunctionApp(
            responseData,
            noteBookStates.MAINTANCE_AND_CALIBRATION
          )
        ) {
          loadmaintenanceCalliberation();
        }
        break;
      default:
        setDefaultInsights(responseData);
        break;
    }
  };

  const loadInferences = (path, value, startDate) => {
    window.showLoader();
    let data_source = breadcrums.vaccine ? "Vx" : "Rx";
    // let siteIDVal = 0;
    // if (siteId && siteId !== "") siteIDVal = siteId.split("#")[0];
    const [
      // eslint-disable-next-line no-unused-vars
      audit_number,
      // eslint-disable-next-line no-unused-vars
      country_code,
      // eslint-disable-next-line no-unused-vars
      audit_site_id,
      // eslint-disable-next-line no-unused-vars
      site_description,
      audit_id,
      // eslint-disable-next-line no-unused-vars
      audit_title,
      // eslint-disable-next-line no-unused-vars
      audit_type_id,
    ] = auditName.split("@#@");
    if (audit_id && window.getFeatureID()) {
      // alert(localStorage.getItem("isInsightsDeleted"));
      if (
        localStorage.getItem("isInsightsDeleted") === "true" &&
        path !== "sopindex" &&
        path !== "stakeholderfeedback"
      ) {
        // console.log("validateaccesstoken path ", path);
        //localStorage.setItem("isInsightsDeleted", false);
        // console.log("validateaccesstoken isInsightsDeleted ", localStorage.getItem("isInsightsDeleted"));
        loadInferencesInner(path, "");
      } else if (
        localStorage.getItem("isInsightsDeleted") === "true" &&
        path === "sopindex" &&
        data_source === "Vx"
      ) {
        //console.log("validateaccesstoken force 1 ", path);
        loadInferencesInner(path, "");
      } else {
        //console.log("validateaccesstoken normal ", path);
        axios
          .get(
            `${
              rimaConstants.REACT_APP_API_URL
            }/api/v1/inferences/${path}/${window.getFeatureID()}/${audit_id}?audit_site_id=${value}&snapshot_date=${startDate}&data_source=${data_source}`
          )
          .then((response) => {
            if (response?.data?.status === "success") {
              // console.log("/api/v1/inferences ",JSON.parse(response.data.data))
              loadInferencesInner(path, response);
            } else {
              setLoadingButtonIniteateInsights();
              response?.data?.msg &&
                window.showMessage(response.data.msg, "warning");
              window.hideLoader();
            }
          });
      }
    }
  };

  React.useEffect(() => {
    if (!breadcrums.auditTitle) {
      //alert()
      setResetOptions(null);
      if (breadcrums.resetCountryFilter) {
        setResetOptions1(null);
        // setCountryCode("");
      }
      if (!breadcrums.notResetSiteFilter) {
        setResetOptions2(null);
        setSiteId("");
      }
      // setResetOptions2(null);
      setAuditName("");
      // setSiteId("");
      // setCountries([]);
      // setSites([]);
      setBreadcrums((prevBreadcrums) => {
        prevBreadcrums.auditTitle = "";
        prevBreadcrums.resetCountryFilter = true;
        if (!breadcrums.notResetSiteFilter) {
          prevBreadcrums.siteId = "";
        }
        return { ...prevBreadcrums };
      });
    }
  }, [breadcrums.auditTitle]);

  React.useEffect(() => {
    resetNotebokeStates();
    // if (
    //   window.allowedStartDate[breadcrums.vaccine ? "VX" : "RX"].includes(
    //     breadcrums.childMenu
    //   )
    // ) {
    //   if (
    //     props.valueStartDate &&
    //     breadcrums.startDates &&
    //     props.valueStartDate !== breadcrums.startDates
    //   ) {
    //     props.setValueStartDate(breadcrums.startDates);
    //   } else if (
    //     window.allowedEndDate[breadcrums.vaccine ? "VX" : "RX"].includes(
    //       breadcrums.childMenu
    //     ) &&
    //     !props.valueEndDate &&
    //     breadcrums.endDate
    //   ) {
    //     props.setValueStartDate(breadcrums.endDate);
    //   }
    // }
  }, [breadcrums.startDates]);

  // React.useEffect(() => {
  //   if (
  //     window.allowedEndDate[breadcrums.vaccine ? "VX" : "RX"].includes(
  //       breadcrums.childMenu
  //     )
  //   ) {
  //     if (
  //       window.allowedStartDate[breadcrums.vaccine ? "VX" : "RX"].includes(
  //         breadcrums.childMenu
  //       ) &&
  //       props.valueEndDate &&
  //       breadcrums.endDates &&
  //       props.valueEndDate !== breadcrums.endDates
  //     ) {
  //       props.setValueEndDate(breadcrums.endDates);
  //     } else if (!props.valueEndDate && breadcrums.endDates) {
  //       props.setValueEndDate(breadcrums.endDates);
  //     }
  //   }
  // }, [breadcrums.endDates]);

  React.useEffect(() => {
    switch (breadcrums.childMenu) {
      case "SOP Index":
        if (breadcrums.auditTitle) {
          let siteIDVal = 0;
          if (siteId && siteId !== "") siteIDVal = siteId.split("#")[0];
          loadInferences(
            "sopindex",
            siteIDVal,
            window.getDateDB(
              breadcrums.startDates,
              false,
              breadcrums.childMenu,
              breadcrums.vaccine ? "VX" : "RX"
            )
          );
        } else {
          resetInferences();
        }
        break;
      case "Stakeholder Feedback":
        //console.log("loadInferences 1234");
        loadInferences("stakeholderfeedback", undefined);
        break;
      case "Document Management & Control":
        loadInferences("dmc", undefined);
        break;
      case "Monitoring & Reviewing of Environmental Control & Utilities":
        loadInferences("environmentalmonitoringue", undefined);
        break;
      case "Deviation Handling":
        loadInferences(
          "deviation",
          undefined,
          window.getDateDB(
            breadcrums.startDates,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          )
        );
        break;
      case "Laboratory Out of Specification":
        loadInferences(
          "laboratory",
          undefined,
          window.getDateDB(
            breadcrums.startDates,
            false,
            breadcrums.childMenu,
            breadcrums.vaccine ? "VX" : "RX"
          )
        );
        break;
      case "Corrective & Preventive Actions":
        loadInferences("capa", undefined);
        break;
      case "Sterile Process Validation, Monitoring & Control":
        loadInferences("sterileprocessvmc", undefined);
        break;
      case "Cleaning Validation & Verification":
        loadInferences("cleaningvalidationv", undefined);
        break;
      case "Repacking":
        loadInferences("Repacking", undefined);
        break;
      case "Warehouse Management":
        loadInferences("WarehouseManagement", undefined);
        break;
      case "Data Management & Control":
        loadInferences("dataManagementControl", undefined);
        break;
      case "Quality KPIS":
        loadInferences("unstructuredInsightsExt", undefined);
        break;
      case "PowerBI Quality Dashboard":
        loadInferences("unstructuredInsightsExt", undefined);
        break;
      /** Unstructured data user story */
      case "Incident  Management":
        loadInferences("lic", undefined);
        break;
      case "Change Control":
        loadInferences("change_control", undefined);
        break;
      case "Quality Alerts and Bulletin Management":
        loadInferences("quality_alert_bulletin_mgmt", undefined);
        break;
      case "Maintenance & Calibration":
        loadInferences("maintenance_calliberation", undefined);
        break;
      // case "Quality Plan":
      //   loadInferences("sqp", undefined);
      //   break;
      // case "Management Review By Quality Council":
      //   loadInferences("sqcm", undefined);
      //   break;
      default:
        /** Unstructured data user story */
        if (
          window.allowUnstructureComponent[
            breadcrums.vaccine ? "VX" : "RX"
          ].includes(breadcrums.childMenu)
        ) {
          loadInferences("unstructuredInsights", undefined);
        } else {
          resetInferences();
        }
        break;
    }
  }, [breadcrums.refreshInferences]);

  const handleOnChangeCountries = (value) => {
    window.showDependencyLoader();
    props.setDeviation("Major");
    let startDate = updateDateFilter();
    setBreadcrums((prevBreadcrums) => {
      prevBreadcrums.auditTitle = "";
      prevBreadcrums.siteId = "";
      prevBreadcrums.tableColumnData = [];
      prevBreadcrums.tableRowData = [];
      prevBreadcrums.resetCountryFilter = false;
      prevBreadcrums.startDates = startDate;
      prevBreadcrums.endDate = new Date();
      prevBreadcrums.DeviationClassification = "Major";
      return { ...prevBreadcrums };
    });
    setResetOptions(null);
    value
      ? setResetOptions1(countries?.filter((val) => val.id === value)[0])
      : setResetOptions1(null);
    setAuditName("");
    setSites([]);
    setResetOptions2(null);
    // setCountryCode(value);
    if (value !== "") {
      setLoading2(true);
      let data_source = breadcrums.vaccine ? "VX" : "RX";
      axios
        .get(
          `${rimaConstants.REACT_APP_API_URL}/api/v1/ara/sites/${data_source}/${value}`
        )
        .then((response) => {
          let options = [];
          if (response && response?.data && response?.data?.length > 0) {
            response.data.map((val) => {
              options.push({
                label: window.customEscape(val.site_description),
                id: val.audit_site_id + "#" + val.site_description,
              });
              return true;
            });
            setSites(options);
          }
          setLoading2(false);
          window.hideDependencyLoader();
        })
        .catch((error) => {
          window.hideDependencyLoader();
          console.log(error);
        });
    } else {
      window.hideDependencyLoader();
    }
  };

  const handleOnChangeSites = (value) => {
    setResetOptions(null);
    setAuditName("");
    setSiteId(value);
    const [site_id, site_desc] = value?.split("#");
    //!value ? setInputValue2("") : setInputValue2(site_desc);
    !value
      ? setResetOptions2(null)
      : setResetOptions2({
          label: window.customEscape(site_desc),
          id: site_id + "#" + site_desc,
        });
    props.setDeviation("Major");
    let startDate = updateDateFilter();
    setBreadcrums((prevBreadcrums) => {
      prevBreadcrums.siteId = value;
      prevBreadcrums.showInsights = false;
      prevBreadcrums.startDates = startDate;
      prevBreadcrums.endDate = new Date();
      prevBreadcrums.auditTitle = "";
      prevBreadcrums.notResetSiteFilter = true;
      prevBreadcrums.resetCountryFilter = false;
      prevBreadcrums.DeviationClassification = "Major";
      return { ...prevBreadcrums };
    });
    resetNotebokeStates();
  };

  const handleOnChangeAuditName = (value) => {
    resetNotebokeStates();
    if (value && value !== breadcrums.auditTitle) {
      props.setDeviation("Major");
      localStorage.setItem("isInsightsDeleted", false);
      let startDate = updateDateFilter();
      // if (breadcrums.vaccine && breadcrums.childMenu === "SOP Index") {
      //   props.setYtd(60);
      //   startDate = window.addMonthToDate(new Date(), 60, "start");
      // } else if (!breadcrums.vaccine && breadcrums.childMenu === "SOP Index") {
      //   props.setYtd(13);
      //   startDate = window.getDate(new Date());
      // } else {
      //   props.setYtd(12);
      //   startDate = window.addMonthToDate(new Date(), 12, "start");
      // }

      window.showDependencyLoader();
      const [
        audit_number,
        country_code,
        audit_site_id,
        site_description,
        audit_id,
        // eslint-disable-next-line no-unused-vars
        audit_title,
        // eslint-disable-next-line no-unused-vars
        audit_type_id,
      ] = value.split("@#@");

      setInputValue(audit_number);
      setAuditName(value);
      // setCountryCode(country_code);

      setResetOptions1(countries.filter((val) => val.id === country_code)[0]);
      setResetOptions2(null);
      setLoading2(true);
      let data_source = breadcrums.vaccine ? "VX" : "RX";
      axios
        .get(
          `${rimaConstants.REACT_APP_API_URL}/api/v1/ara/sites/${data_source}/${country_code}`
        )
        .then((response) => {
          let options = [];
          if (response?.data?.length > 0) {
            response.data.map((val) => {
              options.push({
                label: window.customEscape(val.site_description),
                id: val.audit_site_id + "#" + val.site_description,
              });
              return true;
            });
            setSites(options);
          }
          setLoading2(false);
          ////console.log("ttttt ", audit_site_id + "#" + site_description);
          setSiteId(audit_site_id + "#" + site_description);
          // setInputValue2(site_description);
          setResetOptions2({
            label: window.customEscape(site_description),
            id: audit_site_id + "#" + site_description,
          });
          setBreadcrums((prevBreadcrums) => {
            if (breadcrums.siteId === audit_site_id + "#" + site_description) {
              prevBreadcrums.filterActionReloadSection =
                breadcrums.filterActionReloadSection + 1;
            }
            prevBreadcrums.startDates = startDate;
            prevBreadcrums.endDate = new Date();
            prevBreadcrums.siteId = audit_site_id + "#" + site_description;
            prevBreadcrums.auditTitle = value;
            prevBreadcrums.audit_id = audit_id;
            prevBreadcrums.audit_number = audit_number;
            prevBreadcrums.resetCountryFilter = true;
            prevBreadcrums.showInsights = true;
            prevBreadcrums.DeviationClassification = "Major";
            prevBreadcrums.tableColumnData = [];
            prevBreadcrums.tableRowData = [];
            return { ...prevBreadcrums };
          });
          window.hideDependencyLoader();
        })
        .catch((error) => {
          window.hideDependencyLoader();
          console.log(error);
        });
    } else if (value) {
      window.showMessage("Already same Audit is selected!", "warning");
    } else {
      //alert()
      setResetOptions(null);
      if (breadcrums.resetCountryFilter) {
        setResetOptions1(null);
        // setCountryCode("");
      }
      setResetOptions2(null);
      setAuditName("");
      setSiteId("");
      setBreadcrums((prevBreadcrums) => {
        prevBreadcrums.siteId = "";
        prevBreadcrums.auditTitle = "";
        prevBreadcrums.resetCountryFilter = true;
        prevBreadcrums.tableColumnData = [];
        prevBreadcrums.tableRowData = [];
        switch (breadcrums.childMenu) {
          case "Stakeholder Feedback":
            prevBreadcrums.refreshStakeholderFeedback =
              prevBreadcrums.refreshStakeholderFeedback + 1;
            break;
          default:
            break;
        }
        return { ...prevBreadcrums };
      });
    }
    resetNotebokeStates();
  };

  const handleClosedAudits = (event) => {
    if (!closedAudits) {
      setResetOptions(null);
      setResetOptions1(null);
      setResetOptions2(null);
      setAuditName("");
      setCountries([]);
      setSites([]);
      setBreadcrums((prevBreadcrums) => {
        prevBreadcrums.auditTitle = "";
        prevBreadcrums.closedAudits = true;
        prevBreadcrums.resetCountryFilter = true;
        prevBreadcrums.laoadClosedAudits = breadcrums.laoadClosedAudits + 1;
        prevBreadcrums.tableColumnData = [];
        prevBreadcrums.tableRowData = [];
        return { ...prevBreadcrums };
      });
    } else {
      setResetOptions(null);
      setResetOptions1(null);
      setResetOptions2(null);
      setAuditName("");
      setCountries([]);
      setSites([]);
      setBreadcrums((prevBreadcrums) => {
        prevBreadcrums.auditTitle = "";
        prevBreadcrums.closedAudits = false;
        prevBreadcrums.laoadClosedAudits = breadcrums.laoadClosedAudits + 1;
        prevBreadcrums.resetCountryFilter = true;
        prevBreadcrums.tableColumnData = [];
        prevBreadcrums.tableRowData = [];
        return { ...prevBreadcrums };
      });
    }
    setClosedAudits(!closedAudits);
    resetNotebokeStates();
  };

  return breadcrums.uploadClick ? (
    <SiteDocumentUpload />
  ) : (
    <>
      <div
      className={`filter`}
        // className={`filter ${
        //   typeof user.role !== undefined && Array.isArray(user.role) && user.role.includes(3) ? "stk" : "aud"
        // }`}
      >
        <div className="toggle_vac">
          <a
            className={breadcrums.vaccine ? "active" : ""}
            onClick={() => toggleActiveClass("VACCINE")}
          >
            Vaccine
          </a>
          <a
            className={breadcrums.pharma ? "active" : ""}
            onClick={() => toggleActiveClass("PHARMA")}
          >
            Pharma
          </a>
        </div>
        <div className={`filter_form ${
          typeof user.role !== undefined && Array.isArray(user.role) && user.role.includes(3) ? "stk" : "aud"
        }`}>
          <form action="" className="inline_form">
            <div className="form_control">
              <Checkbox
                {...label}
                checked={closedAudits}
                onChange={handleClosedAudits}
              />
              <label style={{ marginLeft: "-7px", marginTop: "3px" }}>
                Closed Audits Only
              </label>
            </div>
            <div className="form_control">
              <SearchableDropdownGroup
                value={resetOptions}
                options={auditDDL}
                setResetOptions={setResetOptions}
                inputValue={inputValue}
                setInputValue={setInputValue}
                loading={loading}
                handleChange={handleOnChangeAuditName}
                variant="outlined"
                customStyle={{
                  width: "160px",
                  "& .MuiOutlinedInput-root": { padding: "2px" },
                  "& .MuiInputLabel-root": { lineHeight: "0.9em" },
                }}
                paperWidth="160%"
                label="Audit"
                placeholder="Audit"
                background="NO"
                user_id={user.user_id?.toString()}
                setAuditStartDate={setAuditStartDate}
                setAuditEndDate={setAuditEndDate}
              />
            </div>
            <div className="form_control">
              <SearchableDropdown
                value={resetOptions1}
                options={countries}
                setResetOptions={setResetOptions1}
                inputValue={inputValue1}
                setInputValue={setInputValue1}
                loading={loading1}
                handleChange={handleOnChangeCountries}
                variant="outlined"
                label="Country"
                placeholder="Country"
                background="NO"
                customStyle={{
                  width: "160px",
                  "& .MuiOutlinedInput-root": { padding: "2px" },
                  "& .MuiInputLabel-root": { lineHeight: "0.9em" },
                }}
              />
            </div>
            <div className="form_control">
              <SearchableDropdown
                value={resetOptions2}
                options={sites}
                setResetOptions={setResetOptions2}
                inputValue={inputValue2}
                setInputValue={setInputValue2}
                loading={loading2}
                handleChange={handleOnChangeSites}
                variant="outlined"
                label="Site Name"
                paperWidth="140%"
                placeholder="Site Name"
                background="NO"
                customStyle={{
                  width: "160px",
                  "& .MuiOutlinedInput-root": { padding: "2px" },
                  "& .MuiInputLabel-root": { lineHeight: "0.9em" },
                }}
              />
            </div>

            <div className="form_control">
              {auditStartDate && auditEndDate && (
                <Grid
                  container
                  direction="column"
                  justifyContent="left"
                  alignItems="left"
                  sx={{ fontSize: "10px" }}
                >
                  <Grid item>
                    <strong>Audit Start Date: </strong>
                    {auditStartDate}
                  </Grid>
                  <Grid item>
                    <strong>Audit End Date: </strong>
                    {auditEndDate}
                  </Grid>
                </Grid>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

// export default Filters;
export default withAITracking(reactPlugin, Filters);
