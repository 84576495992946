import React, { useContext } from "react";
import Admintemplate from "../admintemplate/admintemplate";
import { BreadcrumbsContext } from "../context/breadcrumsContext";
import ARATemplateUpdation from "./ARATemplateUpdation";
const Content = () => {
  // eslint-disable-next-line no-unused-vars
  const { breadcrumbs, setBreadcrumbs } = useContext(BreadcrumbsContext);
  React.useEffect(() => {
    setBreadcrumbs((prevBreadcrumbs) => {
      prevBreadcrumbs.parentMenu = "ARA Template Update";
      prevBreadcrumbs.childMenu = "";
      return { ...prevBreadcrumbs };
    });
  }, []);
  return (
    <div className="container-admindashboard">
      <ARATemplateUpdation />
    </div>
  );
};

const GetContent = () => {
  return <Content />;
};

const ARATemplateUpdationMain = () => {
  return <Admintemplate content={GetContent()} />;
};

export default ARATemplateUpdationMain;
