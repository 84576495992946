import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useHistory } from "react-router-dom";
import "./adminmain.css";
import Admintemplate from "../admintemplate/admintemplate";
import axios from "axios";
import * as rimaConstants from "../../../rimaconstants";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../applicationInsights/AppInsights";

const Adminmain = () => {
  return <Admintemplate content={Content()} />;
};

const Content = () => {
  let history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const [manageAudits, setManageAudits] = React.useState(0);
  // eslint-disable-next-line no-unused-vars
  const [userAccessManagement, setUserAccessManagement] = React.useState(0);
  const [feedbackTemplate, setFeedbackTemplate] = React.useState(0);
  const [emailTemplate, setEmailTemplate] = React.useState(0);
  const [retentionSetting, setRetentionSetting] = React.useState(0);
  const [unstructuredDataSettings, setUnstructuredDataSettings] =
    React.useState(0);
  const [siteManagementSettings, setSiteManagementSettings] = React.useState(0);
  const [templateSectionManagement, setTemplateSectionManagement] = React.useState(0);
  const [manageDocuments, setManageDocuments] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [araEnabled, setARAEnabled] = React.useState(
    rimaConstants.REACT_APP_ONLY_ASM &&
      rimaConstants.REACT_APP_ONLY_ASM === "YES"
      ? false
      : true
  );
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef(null);

  React.useEffect(() => {
    setHeight(window.innerHeight - 225);
    window.showLoader();

    const resp = {
      status: "success",
      data: [
        {
          generaladmin: {
            feature_id: 46,
            access_allowed: 1,
          },
          asmadmin: {
            feature_id: 53,
            access_allowed: 1,
          },
          managedocuments: {
            feature_id: 54,
            access_allowed: 1,
          },
        },
      ],
    };
    if (!araEnabled) {
      setManageDocuments(resp["data"][0].managedocuments);
      window.hideLoader();
      setOpen(true);
    } else {
      axios
        .get(`${rimaConstants.REACT_APP_API_URL}/api/v1/admin/menu`)
        .then((response) => {
          setManageAudits(response.data["data"][0].manageaudits);
          setUserAccessManagement(
            response.data["data"][0].useraccessmanagement
          );
          setFeedbackTemplate(response.data["data"][0].feedbacktemplate);
          setEmailTemplate(response.data["data"][0].emailtemplate);
          setRetentionSetting(response.data["data"][0].retentionperiodsettings);
          setUnstructuredDataSettings(
            response.data["data"][0].unstructuredkeywordssettings
          );
          setSiteManagementSettings(response.data["data"][0].sitemanagement);
          setTemplateSectionManagement(response.data["data"][0].templatesectionmanagement);
          setManageDocuments(response.data["data"][0].managedocuments);
          window.hideLoader();
          setOpen(true);
        });
    }
  }, []);

  return (
    <div ref={ref} style={{ minHeight: height }}>
      <div className="container-admindashboard">
        <h3>Admin Dashboard</h3>
      </div>
      {open ? (
        <>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 3, sm: 3, md: 3 }}
            sx={{ borderColor: "primary.main", padding: 2 }}
            alignItems="center"
          >
            <Grid item xs={3}>
              <Card
                sx={{ border: "1px solid rgba(224, 224, 224, 1)", height: 200 }}
                p={2}
                item
                xs={4}
              >
                <CardContent>
                  <Typography variant="h6" component="div">
                    Manage Documents
                  </Typography>
                  <hr />
                  <Typography
                    sx={{ mb: 1.5, minHeight: 60 }}
                    variant="body2"
                    color="text.secondary"
                  >
                    View, Delete or Archive documents uploaded by different
                    users or BOT.
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "end" }}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      window.setFeatureID(manageDocuments.feature_id);
                      history.push("/admin/docs");
                    }}
                    disabled={manageDocuments.access_allowed ? false : true}
                  >
                    Document Management
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            {araEnabled && (
              <Grid item xs={3}>
                <Card
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    height: 200,
                  }}
                  p={2}
                  item
                  xs={4}
                >
                  <CardContent>
                    <Typography variant="h6" component="div">
                      Email Template
                    </Typography>
                    <hr />
                    <Typography
                      sx={{ mb: 1.5, minHeight: 60 }}
                      variant="body2"
                      color="text.secondary"
                    >
                      Edit email templates
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: "end" }}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        window.setFeatureID(emailTemplate.feature_id);
                        history.push("/admin/mailtemplate");
                      }}
                      disabled={emailTemplate.access_allowed ? false : true}
                    >
                      Edit Mail Templates
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )}
            {araEnabled && (
              <Grid item xs={3}>
                <Card
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    height: 200,
                  }}
                  p={2}
                  item
                  xs={4}
                >
                  <CardContent>
                    <Typography variant="h6" component="div">
                      ARA Template Update
                    </Typography>
                    <hr />
                    <Typography
                      sx={{ mb: 1.5, minHeight: 60 }}
                      variant="body2"
                      color="text.secondary"
                    >
                      Update template for Vaccine and Pharma
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: "end" }}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        window.setFeatureID(feedbackTemplate.feature_id);
                        history.push("/admin/template-updation");
                      }}
                      disabled={feedbackTemplate.access_allowed ? false : true}
                    >
                      ARA Template Update
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )}
            {araEnabled && (
              <Grid item xs={3}>
                <Card
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    height: 200,
                  }}
                  p={2}
                  item
                  xs={4}
                >
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {/* Retention Settings */}
                      ARA Retention Settings
                    </Typography>
                    <hr />
                    <Typography
                      sx={{ mb: 1.5, minHeight: 60 }}
                      variant="body2"
                      color="text.secondary"
                    >
                      ARA Retention Period Settings
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: "end" }}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        window.setFeatureID(retentionSetting.feature_id);
                        history.push("/admin/retention");
                      }}
                      disabled={retentionSetting.access_allowed ? false : true}
                    >
                      ARA Retention Period Settings
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )}
            {araEnabled && (
              <Grid item xs={3}>
                <Card
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    height: 200,
                  }}
                  p={2}
                  item
                  xs={4}
                >
                  <CardContent>
                    <Typography variant="h6" component="div">
                      Unstructured Keywords Settings
                    </Typography>
                    <hr />
                    <Typography
                      sx={{ mb: 1.5, minHeight: 60 }}
                      variant="body2"
                      color="text.secondary"
                    >
                      Edit unstructured keywords
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: "end" }}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        //unstructureddatasettings
                        window.setFeatureID(
                          unstructuredDataSettings.feature_id
                        );
                        history.push("/admin/unstructuredKewordsSettings");
                      }}
                      disabled={
                        unstructuredDataSettings.access_allowed ? false : true
                      }
                    >
                      Manage Keywords
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )}
            {/* {araEnabled && (
              <Grid item xs={3}>
                <Card
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    height: 200,
                  }}
                  p={2}
                  item
                  xs={4}
                >
                  <CardContent>
                    <Typography variant="h6" component="div">
                      Site Management
                    </Typography>
                    <hr />
                    <Typography
                      sx={{ mb: 1.5, minHeight: 60 }}
                      variant="body2"
                      color="text.secondary"
                    >
                      Edit site management
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: "end" }}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        //unstructureddatasettings
                        window.setFeatureID(siteManagementSettings.feature_id);
                        history.push("/admin/sitemanagement");
                      }}
                      disabled={
                        siteManagementSettings.access_allowed ? false : true
                      }
                    >
                      Site Management
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )} */}

{/* {araEnabled && (
              <Grid item xs={3}>
                <Card
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    height: 200,
                  }}
                  p={2}
                  item
                  xs={4}
                >
                  <CardContent>
                    <Typography variant="h6" component="div">
                    Template Section Management
                    </Typography>
                    <hr />
                    <Typography
                      sx={{ mb: 1.5, minHeight: 60 }}
                      variant="body2"
                      color="text.secondary"
                    >
                      Edit Template Section Management
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: "end" }}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        //unstructureddatasettings
                        window.setFeatureID(templateSectionManagement.feature_id);
                        history.push("/admin/templatesectionmanagement");
                      }}
                      disabled={
                        templateSectionManagement.access_allowed ? false : true
                      }
                    >
                      Template Section Management
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )} */}

          </Grid>
          {/* <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 3, sm: 3, md: 3 }}
            sx={{ borderColor: "primary.main", padding: 2 }}
            alignItems="center"
          >
            <Grid item xs={3}>
              <Card sx={{ border: "1px solid rgba(224, 224, 224, 1)",height: 200 }} p={2} item xs={4}>
                <CardContent>
                  <Typography variant="h6" component="div">
                    User Access Management
                  </Typography>
                  <hr />
                  <Typography
                    sx={{ mb: 1.5, minHeight: 60 }}
                    variant="body2"
                    color="text.secondary"
                  >
                    Create, edit and delete
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "end" }}>
                  <Button
                    size="small"
                    onClick={() => {
                      window.setFeatureID(userAccessManagement.feature_id);
                      history.push("/admin/user-management");
                    }}
                    disabled={
                      userAccessManagement.access_allowed ? false : true
                    }
                  >
                    User Access Management
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid> */}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

// export default Adminmain;
export default withAITracking(reactPlugin, Adminmain);
